/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

export type EntityDetailChildNavigationItem = {
  id: string
  label: string
}

export const initGuidanceChildren = [
  {
    id: 'bestPractices',
    label: 'Architecture and Implementation Best Practices',
  },
]

const initialState: Record<NarrowedEntityTypes, EntityDetailChildNavigationItem[]> = {
  ['activity_configuration']: initGuidanceChildren,
  ['activity_instance']: initGuidanceChildren,
  ['asset']: [],
  ['authorization_policy']: initGuidanceChildren,
  ['detection_target']: initGuidanceChildren,
  ['bdo_detection']: [],
  ['exception_policy']: initGuidanceChildren,
  ['hostname']: initGuidanceChildren,
  ['implant']: initGuidanceChildren,
  ['ip']: initGuidanceChildren,
  ['network']: initGuidanceChildren,
  ['policy']: initGuidanceChildren,
  ['redirector']: initGuidanceChildren,
  ['runbook']: initGuidanceChildren,
  ['savedViews']: initGuidanceChildren,
  ['service']: initGuidanceChildren,
  ['social']: initGuidanceChildren,
  ['source']: initGuidanceChildren,
  ['target']: initGuidanceChildren,

  // @TODO: Carefully remove this
  //
  // @see: https://randori.atlassian.net/browse/EN-10145
  ['topLevelDetection']: initGuidanceChildren,
}

export const EntityDetailNavigationGuidanceStateReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE: {
      return {
        ...state,
        [action.payload.entityType]: [...initGuidanceChildren, ...action.payload.guidanceContent],
      }
    }

    default: {
      return state
    }
  }
}

const keyOfInitialState = t.keyof({
  activity_configuration: null,
  activity_instance: null,
  asset: null,
  authorization_policy: null,
  bdo_detection: null,
  detection_target: null,
  exception_policy: null,
  hostname: null,
  implant: null,
  ip: null,
  network: null,
  policy: null,
  redirector: null,
  runbook: null,
  savedViews: null,
  service: null,
  social: null,
  source: null,
  target: null,

  // @TODO: Carefully remove this
  //
  // @see: https://randori.atlassian.net/browse/EN-10145
  topLevelDetection: null,
})

type NarrowedEntityTypes = t.TypeOf<typeof keyOfInitialState>
