/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Loader, RenderEffect } from '@randori/rootkit'
import debug from 'debug'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import * as Store from '@/store'

import { WithSessionInnerProps } from './with-session'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:prefrences')

type HydratePrefsProps = WithSessionInnerProps & RouteComponentProps

export const HydratePrefs: React.FunctionComponent<HydratePrefsProps> = (props) => {
  const dispatch = useDispatch()

  const { render, ...restProps } = props

  const _effect = React.useCallback(() => {
    log('hydrating preferences')

    return new Promise((resolve, reject) => {
      dispatch(Store.HocActions.HOC_INIT({ success: resolve, failure: reject }))
    })
  }, [dispatch])

  return (
    <RenderEffect
      effect={_effect}
      renderLoading={() => <Loader addlClasses="page-loader" />}
      render={() => <React.Fragment>{render({ ...restProps })}</React.Fragment>}
    />
  )
}
