/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as array from 'fp-ts/Array'
import { compact } from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { map } from 'fp-ts/Option'
import * as t from 'io-ts'
import { formatValidationErrors as _plural, formatValidationError as _single } from 'io-ts-reporters'

// ---------------------------------------------------------------------------

const jsToString = (value: unknown) => (value === undefined ? 'undefined' : JSON.stringify(value))

// Source-maps are broken when consuming original library
//
// @see: https://github.com/OliverJAsh/io-ts-reporters/issues/12
export const formatValidationErrorP = (error: t.ValidationError) => {
  const path = error.context
    .map((c) => c.key)
    .filter((key) => key.length > 0)
    .join('.')

  const maybeErrorContext = array.last(error.context as Array<t.ContextEntry>)

  return pipe(
    maybeErrorContext,
    map((errorContext) => {
      const expectedType = errorContext.type.name
      return (
        // https://github.com/elm-lang/core/blob/18c9e84e975ed22649888bfad15d1efdb0128ab2/src/Native/Json.js#L199
        `Expecting ${expectedType} ${path === '' ? '' : `at ${path}`} but instead got: ${jsToString(error.value)}.`
      )
    }),
  )
}

function hasKey<K extends PropertyKey>(obj: unknown, key: K): obj is { [P in K]: unknown } {
  return typeof obj === 'object' && obj !== null && key in obj
}

export const extractIds = (errors: t.ValidationError[]) => {
  return errors
    .flatMap((err) => {
      return err.context.flatMap(({ actual }) => {
        if (hasKey(actual, 'id')) {
          return `ID of object failing validation: ${actual.id}`
        }
      })
    })
    .filter(Boolean)
}

export const formatValidationErrorsP = (errors: t.ValidationError[]) => compact(errors.map(formatValidationErrorP))

export const formatValidationError = _single
export const formatValidationErrors = _plural
