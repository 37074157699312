/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const ExternalReference = t.type({
  source_name: t.string,
  url: t.string,
  external_id: t.union([t.string, t.undefined]),
})
export type ExternalReference = t.TypeOf<typeof ExternalReference>

export const ExternalReferences = t.array(ExternalReference)

export const MitreMitigationResponse = t.type({
  type: t.literal('course-of-action'),
  id: t.string,
  created_by_ref: t.string,
  created: t.string, // time string
  modified: t.string, // time string
  name: t.string,
  description: t.string,
  external_references: ExternalReferences,
  object_marking_refs: t.array(t.string),
  x_mitre_domains: t.array(t.string),
  x_mitre_version: t.number,
  x_mitre_modified_by_ref: t.string,
  revoked: t.boolean,
})

export type MitreMitigationResponse = t.TypeOf<typeof MitreMitigationResponse>

export const MitreTacticResponse = t.type({
  type: t.literal('x-mitre-tactic'),
  id: t.string,
  created_by_ref: t.string,
  created: t.string, // time string
  modified: t.string, // time string
  name: t.string,
  description: t.string,
  external_references: ExternalReferences,
  object_marking_refs: t.array(t.string),
  x_mitre_domains: t.array(t.string),
  x_mitre_version: t.number,
  x_mitre_modified_by_ref: t.string,
  revoked: t.boolean,
  spec_version: t.string,
  x_mitre_attack_spec_version: t.string,
  x_mitre_shortname: t.string,
})

export type MitreTacticResponse = t.TypeOf<typeof MitreTacticResponse>

const KillChainPhase = t.type({
  kill_chain_name: t.string,
  phase_name: t.string,
})

export const KillChainPhases = t.array(KillChainPhase)

export const MitreTechniqueResponse = t.type({
  created_by_ref: t.string,
  created: t.string, // time string
  description: t.string,
  external_references: ExternalReferences,
  id: t.string,
  kill_chain_phases: KillChainPhases,
  modified: t.string, // time string
  name: t.string,
  object_marking_refs: t.array(t.string),
  revoked: t.boolean,
  type: t.literal('attack-pattern'),
  x_mitre_attack_spec_version: t.union([t.string, t.null]),
  x_mitre_data_sources: t.union([t.array(t.string), t.null]),
  x_mitre_deprecated: t.union([t.boolean, t.null]),
  x_mitre_detection: t.string,
  x_mitre_domains: t.array(t.string),
  x_mitre_is_subtechnique: t.boolean,
  x_mitre_modified_by_ref: t.string,
  x_mitre_permissions_required: t.union([t.array(t.string), t.null]),
  x_mitre_platforms: t.array(t.string),
  x_mitre_version: t.number,
})

export type MitreTechniqueResponse = t.TypeOf<typeof MitreTechniqueResponse>
