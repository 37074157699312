/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const ImplantStatus = t.keyof({
  '40_CHECKING_IN': null,
  '30_DELAYED': null,
  '20_GONE_DARK': null,
  '10_DELETED': null,
})
export type ImplantStatus = t.TypeOf<typeof ImplantStatus>

export const Method = t.record(t.string, t.union([t.string, t.number]))
export type Method = t.TypeOf<typeof Method>

export const Implant = t.interface({
  arch: t.string,
  bart_id: t.string,
  bits: t.union([t.number, t.null]),
  created_on: t.string, // @TODO: iso string
  host_ips: t.array(t.string),
  hostnames: t.array(t.string),
  id: t.string,
  last_checkin: t.string, // @TODO: iso string
  method: t.union([Method, t.null]), // @TODO: Method record
  next_checkin: t.string, // @TODO: iso string
  nick: t.union([t.string, t.null]),
  org_id: t.string,
  os: t.union([t.string, t.null]),
  ostype: t.union([t.string, t.null]),
  osver: t.union([t.string, t.null]),
  profile_time: t.union([t.string, t.null, t.undefined]),
  status: ImplantStatus,
  uid: t.string,
})

type _Implant = t.TypeOf<typeof Implant>
export type Implant = _Implant

export const ImplantsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Implant),
})
export type ImplantsResponse = t.TypeOf<typeof ImplantsResponse>

export const ImplantResponse = t.interface({
  data: Implant,
})
export type ImplantResponse = t.TypeOf<typeof ImplantResponse>

export const ImplantStatistic = t.interface({
  id: t.string,
  current: t.boolean,
  index: t.number,
  latest: t.boolean,
  org_id: t.string,
  row_time: t.string,
  time: t.string,
  type: t.string,
  value: t.union([t.number, t.null]),
  name: t.keyof({
    implants_delayed: null,
    implants_checking_in: null,
    implants_total: null,
    implants_total_alive: null,
  }),
})

type _ImplantStatistic = t.TypeOf<typeof ImplantStatistic>
export type ImplantStatistic = _ImplantStatistic

export const ImplantStatsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ImplantStatistic),
})
export type ImplantStatsResponse = t.TypeOf<typeof ImplantStatsResponse>

export const ImplantCallback = t.interface({
  bart_id: t.string,
  id: t.string,
  implant_id: t.string,
  last_checkin: t.string, // @TODO: iso string
  method: t.union([Method, t.null]), // @TODO: Method record
  org_id: t.string,
  src_ip: t.string,
  src_ip_str: t.string,
})
type _ImplantCallback = t.TypeOf<typeof ImplantCallback>
export type ImplantCallback = _ImplantCallback

export const ImplantCallbacksResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ImplantCallback),
})
export type ImplantCallbacksResponse = t.TypeOf<typeof ImplantCallbacksResponse>

export const ImplantInterface = t.interface({
  address: t.union([t.string, t.null]),
  bart_id: t.string,
  id: t.string,
  implant_id: t.string,
  ip_strs: t.array(t.string),
  name: t.string,
  org_id: t.string,
})
type _ImplantInterface = t.TypeOf<typeof ImplantInterface>
export type ImplantInterface = _ImplantInterface

export const ImplantInterfacesResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ImplantInterface),
})
export type ImplantInterfacesResponse = t.TypeOf<typeof ImplantInterfacesResponse>
