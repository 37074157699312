/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { format, subDays } from 'date-fns'
import { allPass, filter, find, prop, propEq, propOr } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

// @TODO: Is this dead code?
export function getTopTargets(records: Codecs.RequiredStats[]) {
  return filter(propEq('name', 'top_targets'), records)
}

// @TODO: Is this dead code?
// export function getCurrentTopTarget (records: Codecs.RequiredStats[]) {
//   return find(allPass([propEq('name', 'top_targets'), propEq('current', true)]), records)
// }

// @TODO: Is this dead code?
export function getCurrentIps(records: Codecs.RequiredStats[]) {
  return find(propEq('name', 'top_ips'), records)
}

// @TODO: Is this dead code?
export function getCurrentServices(records: Codecs.RequiredStats[]) {
  return find(propEq('name', 'top_services'), records)
}

// @TODO: Is this dead code?
export function getTopTargetsDelta(records: Codecs.RequiredStats[]) {
  const topTargets = getTopTargets(records)

  // eslint-disable-next-line no-magic-numbers
  if (topTargets.length < 2) {
    return // missing historical data
  }

  // const prev = find(propEq('current', false), topTargets)
  // const prev = find(
  //   (topTarget => (
  //     topTarget.current === false
  //   )),
  //   topTargets
  // )

  // const curr = find(propEq('current', true), topTargets)
  // const curr = find(
  //   (topTarget => (
  //     topTarget.current === true
  //   )),
  //   topTargets
  // )

  // @TODO: Investigate shitty ramda typings
  const currValue = prop('value', {} as Codecs.Statistics)
  const prevValue = prop('value', {} as Codecs.Statistics)

  if (isNotNil(currValue) && isNotNil(prevValue)) {
    return currValue - prevValue
  } else {
    return undefined
  }
}

// assumes list is sorted by time descending
export function getHighPriorityTargetStats(stats: Codecs.Statistics[]) {
  return stats.find((stat) => stat.name === 'top_prio_targets') || { value: 0 }
}

// assumes list is sorted by time descending
export function getHighPriorityIpsStats(stats: Codecs.Statistics[]) {
  return stats.find((stat) => stat.name === 'top_prio_ips') || { value: 0 }
}

// assumes list is sorted by time descending
export function getHighPriorityHostsStats(stats: Codecs.Statistics[]) {
  return stats.find((stat) => stat.name === 'top_prio_hostnames') || { value: 0 }
}

// assumes list is sorted by time descending
export function getHighPriorityNetworksStats(stats: Codecs.Statistics[]) {
  return stats.find((stat) => stat.name === 'top_prio_networks') || { value: 0 }
}

export function getCheckingInImplantsStat(implantStats: Codecs.ImplantStatistic[]) {
  return find(propEq('name', 'implants_checking_in'), implantStats)
}

export function getDelayedImplantsStat(implantStats: Codecs.ImplantStatistic[]) {
  return find(propEq('name', 'implants_delayed'), implantStats)
}

export function getTotalImplantsStat(implantStats: Codecs.ImplantStatistic[]) {
  return find(allPass([propEq('name', 'implants_total'), propEq('current', true)]), implantStats)
}

export function getTotalImplantsAliveStat(implantStats: Codecs.ImplantStatistic[]) {
  return find(allPass([propEq('name', 'implants_total_alive'), propEq('current', true)]), implantStats)
}

export function getTotalImplantsDelta(implantStats: Codecs.ImplantStatistic[]) {
  const totalImplants = filter(propEq('name', 'implants_total'), implantStats)

  if (totalImplants.length < 2) {
    return // missing historical data
  } else {
    const prev = find(propEq('current', false), totalImplants)
    const curr = find(propEq('current', true), totalImplants)

    const getValue = propOr(0, 'value')

    return (
      getValue<Codecs.ImplantStatistic | undefined, number>(curr) -
      getValue<Codecs.ImplantStatistic | undefined, number>(prev)
    )
  }
}

export function getTotalImplantsAliveDelta(implantStats: Codecs.ImplantStatistic[]) {
  const totalImplants = filter(propEq('name', 'implants_total_alive'), implantStats)

  if (totalImplants.length < 2) {
    return // missing historical data
  } else {
    const prev = find(propEq('current', false), totalImplants)
    const curr = find(propEq('current', true), totalImplants)

    const getValue = propOr(0, 'value')

    return (
      getValue<Codecs.ImplantStatistic | undefined, number>(curr) -
      getValue<Codecs.ImplantStatistic | undefined, number>(prev)
    )
  }
}

// date
// ---------------------------------------------------------------------------

export function getDateAgo(ago: number) {
  const now = subDays(new Date(), 7)

  // The /statistics endpoint has records at specific intervals. To get the
  // number of X 7 days ago, we need to actually get the number of X close to
  // 7 days ago, on the interval that actually exists. We do some clock math
  // to achieve this.

  return format(new Date(now.valueOf() - (now.valueOf() % ago)), "yyyy-MM-dd'T'HH:mm:ss.000+00")
}
