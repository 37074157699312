/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'
import { propOr } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export const AttackTarget = new schema.Entity('attackTarget')
export const AttackTargets = [AttackTarget]

export const Implant = new schema.Entity('implant')
export const Implants = [Implant]

export function NormalizeImplants(implants: Codecs.Implant[]) {
  type _Implants = {
    implant: {
      [index: string]: Codecs.Implant
    }
  }

  type NormalizedImplants = {
    entities: _Implants
    result: string[]
  }

  const { result, entities } = normalize(implants, Implants)

  const _implants = propOr({}, 'implant', entities)

  return {
    result,
    entities: {
      implant: _implants,
    },
  } as NormalizedImplants
}

// ---------------------------------------------------------------------------

export const Redirector = new schema.Entity('redirector')
export const Redirectors = [Redirector]

export function NormalizeRedirectors(redirectors: Codecs.Redirector[]) {
  type _Redirectors = {
    redirector: {
      [index: string]: Codecs.Redirector
    }
  }

  type NormalizedRedirectors = {
    entities: _Redirectors
    result: string[]
  }

  const { result, entities } = normalize(redirectors, Redirectors)

  const _redirectors = propOr({}, 'redirector', entities)

  return {
    result,
    entities: {
      redirector: _redirectors,
    },
  } as NormalizedRedirectors
}

// ---------------------------------------------------------------------------

export const ImplantCallback = new schema.Entity('callback')
export const ImplantCallbacks = [ImplantCallback]

export function NormalizeImplantCallbacks(callbacks: Codecs.ImplantCallback[]) {
  type _Callbacks = {
    callback: {
      [index: string]: Codecs.ImplantCallback
    }
  }

  type NormalizedCallbacks = {
    entities: _Callbacks
    result: string[]
  }

  const { result, entities } = normalize(callbacks, ImplantCallbacks)

  const _callbacks = propOr({}, 'callback', entities)

  return {
    result,
    entities: {
      callback: _callbacks,
    },
  } as NormalizedCallbacks
}

// ---------------------------------------------------------------------------

export const ImplantInterface = new schema.Entity('interface')
export const ImplantInterfaces = [ImplantInterface]

export function NormalizeImplantInterfaces(interfaces: Codecs.ImplantInterface[]) {
  type _Interfaces = {
    interface: {
      [index: string]: Codecs.ImplantInterface
    }
  }

  type NormalizedInterfaces = {
    entities: _Interfaces
    result: string[]
  }

  const { result, entities } = normalize(interfaces, ImplantInterfaces)

  const _interfaces = propOr({}, 'interface', entities)

  return {
    result,
    entities: {
      interface: _interfaces,
    },
  } as NormalizedInterfaces
}

// ---------------------------------------------------------------------------

export const Runbook = new schema.Entity('runbook')
export const Runbooks = [Runbook]

export function NormalizeRunbooks(runbooks: Codecs.Runbook[]) {
  type _Runbooks = {
    runbook: {
      [index: string]: Codecs.Runbook
    }
  }

  type NormalizedRunbooks = {
    entities: _Runbooks
    result: string[]
  }

  const { result, entities } = normalize(runbooks, Runbooks)

  const _runbooks = propOr({}, 'runbook', entities)

  return {
    result,
    entities: {
      runbook: _runbooks,
    },
  } as NormalizedRunbooks
}

// ---------------------------------------------------------------------------

export const AttackAction = new schema.Entity('attackAction')
export const AttackActions = [AttackAction]

type _AttackActions = {
  attackAction: {
    [index: string]: Codecs.AttackAction
  }
}

export type NormalizedAttackActions = {
  entities: _AttackActions
  result: string[]
}

export function NormalizeAttackActions(attackAction: Codecs.AttackAction[]) {
  const { result, entities } = normalize(attackAction, AttackActions)

  const _attackActions: { [index: string]: Codecs.AttackAction } = propOr({}, 'attackAction', entities)

  return {
    result,
    entities: {
      attackAction: _attackActions,
    },
  } as NormalizedAttackActions
}
