/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

// ---------------------------------------------------------------------------
import { TagCount as TagCountCodec } from '@/codecs'

// ---------------------------------------------------------------------------

export const TagCount = new schema.Entity('tag-count')
export const TagCounts = [TagCount]

export function NormalizeTagCounts(tagCounts: TagCountCodec[]) {
  type _TagCounts = {
    tagCounts: {
      [index: string]: TagCountCodec
    }
  }

  type NormalizedTagCounts = {
    entities: _TagCounts
    result: string[]
  }

  const munged: NormalizedTagCounts = normalize(tagCounts, TagCounts)

  return munged
}
