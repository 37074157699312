/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

export const _omitField = (_: unknown, key: string) => {
  switch (key) {
    case 'deleted':
    case 'details':
    case 'detection_id':
    case 'detection_relevance':
    case 'method':
    case 'perspective':
    case 'poc_email':
    case 'poc_id':
    case 'priority_impact_factor':
    case 'priority_status_factor':
    case 'priority_tags_factor':
    case 'radius':
    case 'screenshot':
    case 'target_id':
    case 'target_ip':
    case 'vendor-service-version':
      return false

    default:
      return true
  }
}

export const omitField = (key: string) => {
  return _omitField(undefined, key)
}
