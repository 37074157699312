/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import * as t from 'io-ts'
import { noop } from 'lodash/fp'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, select, takeEvery } from 'typed-redux-saga/macro'

import { AUTHORIZATION_POLICY_FETCH } from '@/store/actions/recon/authorization-policy.actions'
import { selectAuthorizationPolicyById } from '@/store/selectors/recon/recon.selectors'
import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, UUID } from '@/utilities/codec'
import { serializeQ } from '@/utilities/crud-query'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { stringify } from '@/utilities/query-string'

import * as actions from './detection.actions'
import { normalizeDetections } from './detection.schema'
import { detectionSlice } from './detection.slice'

// ---------------------------------------------------------------------------

export function* detectionSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchDetection.toString(), deferredAction(_fetchDetection, io))
  yield takeEvery(
    actions.fetchDetectionScreenshotsForTarget.toString(),
    deferredAction(_fetchDetectionScreenshotsForTarget, io),
  )
  yield takeEvery(actions.fetchDetections.toString(), deferredAction(_fetchDetections, io))
  yield takeEvery(actions.fetchDetectionsAndPolicies.toString(), deferredAction(_fetchDetectionsAndPolicies, io))
  yield takeEvery(actions.fetchDetectionsForTarget.toString(), deferredAction(_fetchDetectionsForTarget, io))
  yield takeEvery(actions.fetchTotals.toString(), deferredAction(_fetchTotals, io))
}

export function* _fetchDetections(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchDetections>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.detection.getDetections, queryString)

  if (action.meta.persist) {
    yield* put(detectionSlice.actions.replaceDetections(normalizeDetections(response.data)))

    yield* put(
      detectionSlice.actions.replaceDetectionPagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchDetection(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchDetection>) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.detection.getDetection, id)

  yield* put(detectionSlice.actions.upsertDetection(data))

  return data
}

export function* _fetchTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchTotals>) {
  const { total: unfiltered } = yield* call(
    io.api.detection.getDetections,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )

  const { total: unaffiliated } = yield* call(
    io.api.detection.getDetections,
    get(CrudQueryUtils.createUnaffiliatedQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered,
    unaffiliated,
  }

  yield* put(detectionSlice.actions.replaceDetectionTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}

export function* _fetchDetectionsAndPolicies(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchDetectionsAndPolicies>,
) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.detection.getDetections, queryString)

  if (action.meta.persist) {
    yield* put(detectionSlice.actions.replaceDetections(normalizeDetections(response.data)))

    yield* put(
      detectionSlice.actions.replaceDetectionPagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  const policies = [
    ...new Set(
      response.data.flatMap((detection) => {
        return detection.authorizing_policies
      }),
    ),
  ]
    .filter((policyId) => policyId !== 'MANUALLY-AUTHORIZED')
    .filter((policyId): policyId is UUID => {
      return isNotNil(policyId)
    })

  // conditionally fetch policies
  //
  // we need the policy store to be populated in order for detection table
  // to render the "authorization sources" cell
  for (const policyId of policies) {
    const policy = yield* select(selectAuthorizationPolicyById, { id: policyId })

    if (policy === undefined) {
      yield* put(AUTHORIZATION_POLICY_FETCH(policyId, { success: noop, failure: io.logger }))
    }
  }

  return response
}

export function* _fetchDetectionsForTarget(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchDetectionsForTarget>,
) {
  const q = serializeQ({
    condition: 'AND',
    rules: [
      {
        id: 'table.consolidated_target__ids',
        field: 'table.consolidated_target__ids',
        type: 'array',
        input: 'text',
        operator: 'contains_element',
        value: action.payload.targetId,
      },
    ],
  })

  const query = stringify(
    {
      limit: action.payload.limit,
      offset: action.payload.offset,
      q,
    },
    true,
  )

  const queryString = get(query, QueryString)

  const response = yield* call(io.api.detection.getDetections, queryString)

  if (action.meta.persist) {
    const payload = {
      nextState: normalizeDetections(response.data),
      forEntity: {
        id: get(action.payload.targetId, UUID),
        type: 'target' as const,
      },
    }

    yield* put(detectionSlice.actions.replaceDetectionsForEntity(payload))
  }

  return response
}

export function* _fetchDetectionScreenshotsForTarget(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchDetectionScreenshotsForTarget>,
) {
  const { targetId, limit, offset } = action.payload

  const q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.consolidated_target__ids',
        field: 'table.consolidated_target__ids',
        type: 'array',
        input: 'text',
        operator: 'contains_element',
        value: targetId,
      },
      {
        id: 'table.artifact__screenshot_sha',
        field: 'table.artifact__screenshot_sha',
        input: 'text',
        operator: 'is_not_null',
        value: null,
      },
    ],
  }

  const query = stringify(
    {
      limit,
      offset,
      q: CrudQueryUtils.serializeQ(q),
      sort: ['detection_criteria__ip', 'id'],
    },
    true,
  )

  const response = yield* call(io.api.detection.getDetections, get(query, QueryString))

  return response
}
