/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DetectionV2 as Detection, EntityPagination } from '@/codecs'
import { UUID } from '@/utilities/codec'

import { EntityName, initialState, State } from './detection.constants'
import { normalizeDetections } from './detection.schema'

// ---------------------------------------------------------------------------

export const detectionSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    // base
    // ------------------------------------------------------------------------

    replaceDetections: (state, action: PayloadAction<ReturnType<typeof normalizeDetections>>) => {
      state.result = action.payload.result

      state.entities = {
        bdo_detection: {
          ...state.entities.bdo_detection,
          ...action.payload.entities.bdo_detection,
        },
      }
    },

    replaceDetectionTotals: (state, action: PayloadAction<State['totals']>) => {
      state.totals = action.payload
    },

    replaceDetectionPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },

    upsertDetection: (state, action: PayloadAction<Detection>) => {
      const id = action.payload.id

      state.entities.bdo_detection[id] = action.payload
    },

    upsertDetections: (state, action: PayloadAction<ReturnType<typeof normalizeDetections>>) => {
      state.entities = {
        bdo_detection: {
          ...state.entities.bdo_detection,
          ...action.payload.entities.bdo_detection,
        },
      }
    },

    // extended
    // ------------------------------------------------------------------------

    replaceDetectionsForEntity: (
      state,
      action: PayloadAction<{
        nextState: ReturnType<typeof normalizeDetections>
        forEntity: {
          id: UUID
          type: 'target'
        }
      }>,
    ) => {
      state.entities = {
        bdo_detection: {
          ...state.entities.bdo_detection,
          ...action.payload.nextState.entities.bdo_detection,
        },
      }

      state.byEntity[action.payload.forEntity.type] = {
        ...state.byEntity[action.payload.forEntity.type],
        [action.payload.forEntity.id]: action.payload.nextState.result,
      }
    },
  },
})
