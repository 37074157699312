/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type AggregatorApi = {
  bulkPromoteUnscored: (
    payload: Codecs.ServiceSuggestionBulkPostPayload,
  ) => Promise<Codecs.ServiceSuggestionBulkPostResponse>
  postGlobalTags: (payload: Codecs.GlobalTagsPostPayload) => Promise<Codecs.GlobalTagsPostResponse>
  tryCharacteristicRule: (payload: any) => Promise<unknown>
  tryServiceRule: (payload: any) => Promise<unknown>

  getCharacteristicRules: (query: string) => Promise<Codecs.CharacteristicRuleResponse>
  getCharacteristicRulesMetadata: (query: string) => Promise<Codecs.CharacteristicRuleMetadataResponse>
  patchCharacteristicRule: (
    id: string,
    payload: Codecs.CharacteristicRulePatchPayload,
  ) => Promise<Codecs.CharacteristicRulePatchResponse>
  postCharacteristicRule: (
    payload: Codecs.CharacteristicRulePostPayload,
  ) => Promise<Codecs.CharacteristicRulePostResponse>

  postOrgKeyVal: (payload: Codecs.PostOrgKeyValPayload) => Promise<Codecs.PostOrgKeyValResponse>

  getGlobalArtifacts: (query: string) => Promise<Codecs.ArtifactsResponse>
  getGlobalIps: (query: string) => Promise<Codecs.GlobalIpsResponse>
  postGlobalIp: (body: Codecs.GlobalIpPostPayload) => Promise<Codecs.GlobalIpPostResponse>
  getGlobalPorts: (query: string) => Promise<Codecs.GlobalPortResponse>

  getGlobalNetworks: (query: string) => Promise<Codecs.GlobalNetworkResponse>
  postGlobalNetwork: (body: Codecs.GlobalNetworkPostPayload) => Promise<Codecs.GlobalNetworkPostResponse>

  getGlobalHostnames: (query: string) => Promise<Codecs.GlobalHostnameResponse>
  postGlobalHostname: (body: Codecs.GlobalHostnamePostPayload) => Promise<Codecs.GlobalHostnamePostResponse>

  getOrgTerms: (query: string) => Promise<Codecs.TermResponse>
  postOrgTerm: (body: Codecs.TermPostPayload) => Promise<Codecs.PostTermResponse>

  getOrgPrimes: (query: string) => Promise<Codecs.PrimesResponse>

  getGlobalServiceStats: (query: string) => Promise<Codecs.GlobalServiceStatsResponse>
  getGlobalServices: (query: string) => Promise<Codecs.GlobalServicesResponse>
  getGlobalServicesWithStats: (query: string) => Promise<Codecs.GlobalServicesWithStatsResponse>
  patchGlobalService: (
    id: string,
    payload: Codecs.GlobalServicePatchPayload,
  ) => Promise<Codecs.GlobalServicePatchResponse>
  postGlobalService: (payload: Codecs.GlobalServicesPostPayload) => Promise<Codecs.GlobalServicesPostResponse>

  getServiceSuggestionStats: (query: string) => Promise<Codecs.ServiceSuggestionStatsResponse>
  getServiceSuggestions: (query: string) => Promise<Codecs.ServiceSuggestionResponse>
  getServiceSuggestionsWithStats: (query: string) => Promise<Codecs.ServiceSuggestionWithStatsResponse>
  patchServiceSuggestion: (
    id: string,
    payload: Codecs.ServiceSuggestionPatchPayload,
  ) => Promise<Codecs.ServiceSuggestionPatchResponse>

  getServiceRules: (query: string) => Promise<Codecs.ServiceRuleResponse>
  getServiceRulesMetadata: (query: string) => Promise<Codecs.ServiceRuleMetadataResponse>
  patchServiceRule: (id: string, payload: Codecs.ServiceRulePatchPayload) => Promise<Codecs.ServiceRulePatchResponse>
  postServiceRule: (payload: Codecs.ServiceRulePostPayload) => Promise<Codecs.ServiceRulePostResponse>

  getPocs: (query: string) => Promise<Codecs.PocResponse>
  postPoc: (payload: Codecs.PocPostPayload) => Promise<Codecs.PocPostPayloadResponse>
}

export function aggregatorApiFactory(http: HttpClient): AggregatorApi {
  const baseURL = '/aggregator/api/v1/user'

  return {
    bulkPromoteUnscored(payload) {
      return http
        .post(`${baseURL}/suggestedservice-bulk-promote-unscored`, payload)
        .then(CodecUtils.throwValidate(Codecs.ServiceSuggestionBulkPostResponse, 'ServiceSuggestionBulkPostResponse'))
    },

    tryServiceRule(payload) {
      return http.post(`${baseURL}/try_service_rule`, payload)
      // @TODO: add codec and validator
    },

    tryCharacteristicRule(payload) {
      return http.post(`${baseURL}/try_characteristic_rule`, payload)
      // @TODO: add codec and validator
    },

    // global ips
    // -----------------------------------------------------------------------

    getGlobalIps(query) {
      return http
        .get(`${baseURL}/ips${query}`)
        .then(CodecUtils.throwValidate(Codecs.GlobalIpsResponse, 'GlobalIpsResponse'))
    },

    postGlobalIp(body) {
      return http
        .post(`${baseURL}/ips`, body)
        .then(CodecUtils.throwValidate(Codecs.GlobalIpPostResponse, 'GlobalIpPostResponse'))
    },

    // global networks
    // -----------------------------------------------------------------------

    getGlobalNetworks(query) {
      return http
        .get(`${baseURL}/networks${query}`)
        .then(CodecUtils.throwValidate(Codecs.GlobalNetworkResponse, 'GlobalNetworkResponse'))
    },

    postGlobalNetwork(body) {
      return http
        .post(`${baseURL}/networks`, body)
        .then(CodecUtils.throwValidate(Codecs.GlobalNetworkPostResponse, 'GlobalNetworkPostResponse'))
    },

    // global hostname
    // -----------------------------------------------------------------------

    postGlobalHostname(body) {
      return http
        .post(`${baseURL}/hostnames`, body)
        .then(CodecUtils.throwValidate(Codecs.GlobalHostnamePostResponse, 'GlobalHostnamePostResponse'))
    },

    getGlobalHostnames(query) {
      return http
        .get(`${baseURL}/hostnames${query}`)
        .then(CodecUtils.throwValidate(Codecs.GlobalHostnameResponse, 'GlobalHostnameResponse'))
    },

    // org term
    // -----------------------------------------------------------------------

    getOrgTerms(query) {
      return http.get(`${baseURL}/terms${query}`).then(CodecUtils.throwValidate(Codecs.TermResponse, 'TermResponse'))
    },

    postOrgTerm(body) {
      return http
        .post(`${baseURL}/terms`, body)
        .then(CodecUtils.throwValidate(Codecs.PostTermResponse, 'PostTermResponse'))
    },

    // global ports
    // -----------------------------------------------------------------------

    getGlobalPorts(query) {
      return http
        .get(`${baseURL}/ports${query}`)
        .then(CodecUtils.throwValidate(Codecs.GlobalPortResponse, 'GlobalPortResponse'))
    },

    // org primes
    // -----------------------------------------------------------------------

    getOrgPrimes(query) {
      return http
        .get(`${baseURL}/primes${query}`)
        .then(CodecUtils.throwValidate(Codecs.PrimesResponse, 'PrimesResponse'))
    },

    // global services
    // -----------------------------------------------------------------------

    getGlobalServices(query) {
      return http
        .get(`${baseURL}/services${query}`)
        .then(CodecUtils.throwValidate(Codecs.GlobalServicesResponse, 'GlobalServicesResponse'))
    },

    getGlobalServiceStats(query) {
      return http
        .get(`${baseURL}/service-statistics${query}`)
        .then(CodecUtils.throwValidate(Codecs.GlobalServiceStatsResponse, 'GlobalServiceStatsResponse'))
    },

    getGlobalServicesWithStats(query) {
      return http
        .get(`${baseURL}/servicecounts${query}`)
        .then(CodecUtils.throwValidate(Codecs.GlobalServicesWithStatsResponse, 'GlobalServicesWithStatsResponse'))
    },

    patchGlobalService(id, payload) {
      return http
        .patch(`${baseURL}/services/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.GlobalServicePatchResponse, 'GlobalServicePatchResponse'))
    },

    postGlobalService(payload) {
      return http
        .post(`${baseURL}/services`, payload)
        .then(CodecUtils.throwValidate(Codecs.GlobalServicesPostResponse, 'GlobalServicesPostResponse'))
    },

    postGlobalTags(payload) {
      return http
        .post(`${baseURL}/globaltags`, payload)
        .then(CodecUtils.throwValidate(Codecs.GlobalTagsPostResponse, 'GlobalTagsPostResponse'))
    },

    // suggestions
    // -----------------------------------------------------------------------

    getServiceSuggestions(query) {
      return http
        .get(`${baseURL}/suggestedservices${query}`)
        .then(CodecUtils.throwValidate(Codecs.ServiceSuggestionResponse, 'ServiceSuggestionResponse'))
    },

    getServiceSuggestionsWithStats(query) {
      return http
        .get(`${baseURL}/suggestedservicecounts${query}`)
        .then(CodecUtils.throwValidate(Codecs.ServiceSuggestionWithStatsResponse, 'ServiceSuggestionWithStatsResponse'))
    },

    getServiceSuggestionStats(query) {
      return http
        .get(`${baseURL}/suggestedservice-statistics${query}`)
        .then(CodecUtils.throwValidate(Codecs.ServiceSuggestionStats, 'ServiceSuggestionStats'))
    },

    patchServiceSuggestion(id, payload) {
      return http
        .patch(`${baseURL}/suggestedservices/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.ServiceSuggestionPatchResponse, 'ServiceSuggestionPatchResponse'))
    },

    // characteristic rules
    // -----------------------------------------------------------------------

    getCharacteristicRules(query) {
      return http
        .get(`${baseURL}/characteristic_rules_count${query}`)
        .then(CodecUtils.throwValidate(Codecs.CharacteristicRuleResponse, 'CharacteristicRuleResponse'))
    },

    getCharacteristicRulesMetadata(query) {
      return http
        .get(`${baseURL}/characteristic_rules_metadata${query}`)
        .then(CodecUtils.throwValidate(Codecs.CharacteristicRuleMetadataResponse, 'CharacteristicRuleMetadataResponse'))
    },

    patchCharacteristicRule(id: string, payload) {
      return http
        .patch(`${baseURL}/characteristic_rules/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.CharacteristicRulePatchResponse, 'CharacteristicRulePatchResponse'))
    },

    postCharacteristicRule(payload) {
      const mungedPayload = { data: [payload.data] }

      return http
        .post(`${baseURL}/characteristic_rules`, mungedPayload)
        .then(CodecUtils.throwValidate(Codecs.CharacteristicRulePostResponse, 'CharacteristicRulePostResponse'))
    },

    // service rules
    // -----------------------------------------------------------------------

    getServiceRules(query) {
      return http
        .get(`${baseURL}/service_rules_count${query}`)
        .then(CodecUtils.throwValidate(Codecs.ServiceRuleResponse, 'ServiceRuleResponse'))
    },

    getServiceRulesMetadata(query) {
      return http
        .get(`${baseURL}/service_rules_metadata${query}`)
        .then(CodecUtils.throwValidate(Codecs.ServiceRuleMetadataResponse, 'ServiceRuleMetadataResponse'))
    },

    patchServiceRule(id: string, payload) {
      return http
        .patch(`${baseURL}/service_rules/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.ServiceRulePatchResponse, 'ServiceRulePatchResponse'))
    },

    postServiceRule(payload) {
      const mungedPayload = { data: [payload.data] }

      return http
        .post(`${baseURL}/service_rules`, mungedPayload)
        .then(CodecUtils.throwValidate(Codecs.ServiceRulePostResponse, 'ServiceRulePostResponse'))
    },

    // artifacts
    // -----------------------------------------------------------------------

    getGlobalArtifacts(query) {
      return http
        .get(`${baseURL}/artifacts${query}`)
        .then(CodecUtils.throwValidate(Codecs.ArtifactsResponse, 'ArtifactsResponse'))
    },

    // pocs
    // -----------------------------------------------------------------------

    getPocs(query) {
      return http.get(`${baseURL}/pocs${query}`).then(CodecUtils.throwValidate(Codecs.PocResponse, 'PocResponse'))
    },

    postPoc(payload) {
      return http
        .post(`${baseURL}/pocs`, payload)
        .then(CodecUtils.throwValidate(Codecs.PocPostPayloadResponse, 'PocPostPayloadResponse'))
    },

    // org key-vals
    // -----------------------------------------------------------------------

    postOrgKeyVal(body) {
      return http
        .post(`${baseURL}/keyvals`, body)
        .then(CodecUtils.throwValidate(Codecs.PostOrgKeyValResponse, 'PostOrgKeyValResponse'))
    },
  }
}
