/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from 'reselect'

// ---------------------------------------------------------------------------
import { AppState } from '@/store'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

const _getAttackPagination = (state: AppState) => state.attack.attackPagination
const _getAttackStats = (state: AppState) => state.attack.attackStats
const _getAttackTargets = (state: AppState) => state.attack.attackTargets

// v2
const _getActionsByRunbook = (state: AppState) => state.attack.actionsByRunbook
const _getAttackActions = (state: AppState) => state.attack.attackActions
const _getAttackActionsPagination = (state: AppState) => state.attack.attackActionsPagination
const _getAttackActionsTotals = (state: AppState) => state.attack.attackActionsTotals
const _getImplantById = (state: AppState, props: { id: string }) => state.attack.implants.entities.implant[props.id]
const _getImplantCallbacks = (state: AppState) => state.attack.implantCallbacks
const _getImplantCallbacksPagination = (state: AppState) => state.attack.implantCallbacksPagination
const _getImplantInterfaces = (state: AppState) => state.attack.implantInterfaces
const _getImplantInterfacesPagination = (state: AppState) => state.attack.implantInterfacesPagination
const _getImplantStats = (state: AppState) => state.attack.implantStats
const _getImplants = (state: AppState) => state.attack.implants
const _getImplantsPagination = (state: AppState) => state.attack.implantsPagination
const _getImplantsTotals = (state: AppState) => state.attack.implantsTotals
const _getRedirectorById = (state: AppState, props: { id: string }) =>
  state.attack.redirectors.entities.redirector[props.id]
const _getRedirectors = (state: AppState) => state.attack.redirectors
const _getRedirectorsPagination = (state: AppState) => state.attack.redirectorsPagination
const _getRedirectorsTotals = (state: AppState) => state.attack.redirectorsTotals
const _getRunbooks = (state: AppState) => state.attack.runbooks
const _getRunbooksPagination = (state: AppState) => state.attack.runbooksPagination
const _getRunbooksTotals = (state: AppState) => state.attack.runbooksTotals

type GetAttackActionsByRunbookIdPassedProps = {
  id: string
}

const _getRunbookById = (state: AppState, props: { id: string }) => state.attack.runbooks.entities.runbook[props.id]

const _getActionsByRunbookResult = (state: AppState, props: GetAttackActionsByRunbookIdPassedProps) => {
  return state.attack.actionsByRunbook.result[props.id]
}

const _getActionsByRunbookPaginationResult = (state: AppState, props: GetAttackActionsByRunbookIdPassedProps) => {
  return state.attack.actionsByRunbookPagination[props.id]
}

// ---------------------------------------------------------------------------

// @TODO: dead code
export const selectPagination = createSelector([_getAttackPagination], (pagination) => pagination)

// @TODO: dead code
export const selectAttackTargets = createSelector([_getAttackTargets], (attackTargets) => {
  const targets = attackTargets.entities.attackTarget

  if (isNotNil(targets)) {
    return attackTargets.result.map((target: string) => (targets as { [index: string]: any })[target])
  } else {
    return []
  }
})

// @TODO: dead code
export const selectAttackStats = createSelector([_getAttackStats], (stats) => stats)

// v2

export const selectImplantStats = createSelector([_getImplantStats], (stats) => stats)

export const selectImplantsPagination = createSelector([_getImplantsPagination], (pagination) => pagination)

export const selectRedirectors = createSelector([_getRedirectors], (_redirectors) => {
  return (
    _redirectors.result
      .map((id: string) => _redirectors.entities.redirector[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectRedirectorsPagination = createSelector([_getRedirectorsPagination], (pagination) => pagination)

export const selectImplants = createSelector([_getImplants], (_implants) => {
  return (
    _implants.result
      .map((id: string) => _implants.entities.implant[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectImplant = createSelector([_getImplantById], (_implant) => _implant)

export const selectRedirectorById = createSelector([_getRedirectorById], (_redirector) => _redirector)

export const selectImplantCallbacks = createSelector([_getImplantCallbacks], (_callbacks) => {
  return (
    _callbacks.result
      .map((id: string) => _callbacks.entities.callback[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectImplantCallbacksPagination = createSelector(
  [_getImplantCallbacksPagination],
  (pagination) => pagination,
)

export const selectImplantInterfaces = createSelector([_getImplantInterfaces], (_interfaces) => {
  return (
    _interfaces.result
      .map((id: string) => _interfaces.entities.interface[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectImplantInterfacesPagination = createSelector(
  [_getImplantInterfacesPagination],
  (pagination) => pagination,
)

export const selectRunbooks = createSelector([_getRunbooks], (_runbooks) => {
  return (
    _runbooks.result
      .map((id: string) => _runbooks.entities.runbook[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
      .filter((action) => !Boolean(action.deleted))
  )
})

export const selectRunbooksPagination = createSelector([_getRunbooksPagination], (pagination) => pagination)

export const selectRedirectorsTotals = createSelector([_getRedirectorsTotals], (totals) => totals)

export const selectImplantTotals = createSelector([_getImplantsTotals], (totals) => totals)

export const selectRunbooksTotals = createSelector([_getRunbooksTotals], (totals) => totals)

export const selectAttackActionsTotals = createSelector([_getAttackActionsTotals], (totals) => totals)

export const selectAttackActionsPagination = createSelector([_getAttackActionsPagination], (pagination) => pagination)

export const selectAttackActions = createSelector([_getAttackActions], (_attackActions) => {
  return (
    _attackActions.result
      .map((id: string) => _attackActions.entities.attackAction[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectActionsByRunbook = createSelector(
  [_getActionsByRunbookResult, _getActionsByRunbook],
  (result, actions) => {
    if (isNotNil(result)) {
      const mapped = result.map((id) => actions.entities.attackAction[id]).filter((action) => !Boolean(action.deleted))

      return mapped
    } else {
      return []
    }
  },
)

export const selectActionsByRunbookPagination = createSelector([_getActionsByRunbookPaginationResult], (pagination) => {
  if (isNotNil(pagination)) {
    return pagination
  } else {
    return {
      count: 0,
      offset: 0,
      total: 0,
    }
  }
})

// @TODO: There's an obvious abstraction hasServiceEdit
//
// We should see if we can consolidate alot of this selector business by using
// typescript generics to parameterize the return type. Something like:
//
// const target = selectEntity('target': keyof Reducer, id): Codecs.Target
export const selectRunbookById = createSelector([_getRunbookById], (_runbook) => _runbook)
