/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { deferredAction } from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'

import * as actions from './entitlement.actions'

// ---------------------------------------------------------------------------

export function* entitlementSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchEntitlements.toString(), deferredAction(_fetchEntitlements, io))
  yield takeEvery(actions.fetchEntitlementsSources.toString(), deferredAction(_fetchEntitlementsSources, io))
  yield takeEvery(actions.fetchEntitlementsUsage.toString(), deferredAction(_fetchEntitlementsUsage, io))
}

export function* _fetchEntitlements(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchEntitlements>) {
  const orgId = action.payload.orgId

  const response = yield* call(io.api.entitlement.getEntitlementsForOrg, orgId)

  return response
}

export function* _fetchEntitlementsSources(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchEntitlementsSources>,
) {
  const orgId = action.payload.orgId

  const response = yield* call(io.api.entitlement.getEntitlementsForSources, orgId)

  return response
}

export function* _fetchEntitlementsUsage(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchEntitlementsUsage>) {
  const orgId = action.payload.orgId

  const response = yield* call(io.api.entitlement.getEntitlementsUsageForOrg, orgId)

  return response
}
