/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Affiliation } from '../affiliation.codecs'
import { Perspective, PerspectiveName } from '../perspective.codecs'
import { withPriority } from '../priority.codecs'

// ---------------------------------------------------------------------------

const Port = t.type({
  port: t.number,
  protocol: t.number,
  state: t.string,
})

export const AllPorts = t.union([t.array(Port), t.null])

export const Ip = withPriority(
  t.type({
    affiliation_state: Affiliation,
    all_ports: AllPorts,
    characteristic_tags: t.array(t.string),
    confidence: t.union([t.number, t.null]),
    country: t.union([t.string, t.null]),
    first_seen: t.union([t.string, t.null]), // @TODO: iso string
    hostname_count: t.union([t.number, t.null]),
    id: t.string,
    ip: t.union([t.string, t.null]),
    ip_str: t.union([t.string, t.null]),
    last_seen: t.union([t.string, t.null]), // @TODO: iso string
    latitude: t.union([t.number, t.null]),
    longitude: t.union([t.number, t.null]),
    network: t.union([t.string, t.undefined]),
    only_in_review_targets: t.boolean,
    open_port_count: t.union([t.number, t.null]),
    org_id: t.union([t.string, t.null]),
    perspective: Perspective,
    perspective_name: PerspectiveName,
    radius: t.union([t.number, t.null]),
    service_count: t.union([t.number, t.null]),
    target_count: t.union([t.number, t.null]),
    user_tags: t.array(t.string),
  }),
)

type _Ip = t.TypeOf<typeof Ip>
export type Ip = _Ip

// @TODO: Add tags to the below codecs
