/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import { FormValues as RequestPentestFormValues } from '@/pages/entity-detail/target/request-pen-test-modal/request-pen-test.utils'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  ACCEPT_TOS = 'ACCEPT_TOS',
  API_KEY_DELETE = 'API_KEY_DELETE',
  API_KEY_POST = 'API_KEY_POST',
  API_TOKENS_GET = 'API_TOKENS_GET',
  API_TOKENS_UPDATE = 'API_TOKENS_UPDATE',
  API_TOKEN_DELETE = 'API_TOKEN_DELETE',
  API_TOKEN_POST = 'API_TOKEN_POST',
  API_TOKEN_UPDATE = 'API_TOKEN_UPDATE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CONFIRM_SSO = 'CONFIRM_SSO',
  FETCH_USER = 'FETCH_USER',
  GET_WORKATO_JWT = 'GET_WORKATO_JWT',
  INVITE_USER = 'INVITE_USER',
  LOG_IN = 'LOG_IN',
  LOG_IN_OTP = 'LOG_IN_OTP',
  LOG_OUT = 'LOG_OUT',
  ORG_INIT = 'ORG_INIT',
  GET_PENTEST_METADATA = 'GET_PENTEST_METADATA',
  RECOVER_SSO = 'RECOVER_SSO',
  REMOVE_USER = 'REMOVE_USER',
  REQUEST_PENTEST_POST = 'REQUEST_PENTEST_POST',
  RESET_OTP_FOR_USER = 'RESET_OTP_FOR_USER',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  SET_OTP = 'SET_OTP',
  SET_PASSWORD = 'SET_PASSWORD',
  SET_USER = 'SET_USER',
  SSO_DELETE = 'SSO_DELETE',
  SSO_FORCE_RELOG = 'SSO_FORCE_RELOG',
  SSO_SEND_EMAIL = 'SSO_SEND_EMAIL',
  UPDATE_USER = 'UPDATE_USER',
  VALIDATE_JWT = 'VALIDATE_JWT',
  VALIDATE_ORG_USER = 'VALIDATE_ORG_USER',
}

export type ActionTypes =
  | ACCEPT_TOS
  | CONFIRM_SSO
  | ChangePassword
  | DeleteApiKey
  | DeleteApiToken
  | FetchUser
  | GET_WORKATO_JWT
  | InviteUser
  | LogIn
  | LogOut
  | RECOVER_SSO
  | RemoveUser
  | ResetUserPassword
  | SSO_FORCE_RELOG
  | SSO_SEND_EMAIL
  | SetOTP
  | SetPassword
  | SetUser
  | UpdateApiTokens
  | UpdateUser
  | ValidateJWT

// ---------------------------------------------------------------------------

export type ChangePasswordPayload = {
  oldPassword: string
  newPassword: string
}

export type ChangePassword = {
  type: TypeKeys.CHANGE_PASSWORD
  meta: { deferred: Deferred }
  payload: ChangePasswordPayload
}

export const changePassword = (payload: ChangePasswordPayload, deferred: Deferred): ChangePassword => {
  return {
    type: TypeKeys.CHANGE_PASSWORD,
    meta: { deferred },
    payload,
  }
}

export type FetchUserPayload = string

export type FetchUser = {
  type: TypeKeys.FETCH_USER
  meta: { deferred: Deferred }
  payload: FetchUserPayload
}

export const FETCH_USER = (payload: FetchUserPayload, deferred: Deferred): FetchUser => {
  return {
    type: TypeKeys.FETCH_USER,
    meta: { deferred },
    payload,
  }
}

export type GET_WORKATO_JWT = {
  type: TypeKeys.GET_WORKATO_JWT
  meta: { deferred: Deferred }
}

export const GET_WORKATO_JWT = (deferred: Deferred): GET_WORKATO_JWT => {
  return {
    type: TypeKeys.GET_WORKATO_JWT,
    meta: { deferred },
  }
}

export type ValidateJWT = {
  type: TypeKeys.VALIDATE_JWT
  meta: { deferred: Deferred }
}

export const validateJWT = (deferred: Deferred): ValidateJWT => {
  return {
    type: TypeKeys.VALIDATE_JWT,
    meta: { deferred },
  }
}

export const VALIDATE_JWT = validateJWT

export type LogInPayload = {
  password: string
  username: string
}

export type LogIn = {
  type: TypeKeys.LOG_IN
  meta: { deferred: Deferred }
  payload: LogInPayload
}

export const logIn = (payload: LogInPayload, deferred: Deferred): LogIn => {
  return {
    type: TypeKeys.LOG_IN,
    meta: { deferred },
    payload,
  }
}

export type LogOutPayload = {}

export type LogOut = {
  type: TypeKeys.LOG_OUT
  meta: { deferred: Deferred }
  payload: LogOutPayload
}

export const logOut = (payload: LogOutPayload, deferred: Deferred): LogOut => {
  return {
    type: TypeKeys.LOG_OUT,
    meta: { deferred },
    payload,
  }
}

export type LogInOTPPayload = {
  otp: string
}

export type LogInOTP = {
  type: TypeKeys.LOG_IN_OTP
  meta: { deferred: Deferred }
  payload: LogInOTPPayload
}

export const logInOTP = (payload: LogInOTPPayload, deferred: Deferred): LogInOTP => {
  return {
    type: TypeKeys.LOG_IN_OTP,
    meta: { deferred },
    payload,
  }
}

export type SetOTPPayload = {
  otp: string
}

export type SetOTP = {
  type: TypeKeys.SET_OTP
  meta: { deferred: Deferred }
  payload: SetOTPPayload
}

export const setOTP = (payload: SetOTPPayload, deferred: Deferred): SetOTP => {
  return {
    type: TypeKeys.SET_OTP,
    meta: { deferred },
    payload,
  }
}

export type SetPasswordPayload = {
  password: string
  token: string
}

export type SetPassword = {
  type: TypeKeys.SET_PASSWORD
  meta: { deferred: Deferred }
  payload: SetPasswordPayload
}

export const setPassword = (payload: SetPasswordPayload, deferred: Deferred): SetPassword => {
  return {
    type: TypeKeys.SET_PASSWORD,
    meta: { deferred },
    payload,
  }
}

export type SetUser = {
  type: TypeKeys.SET_USER
  payload: Codecs.UpdateUserPayload
}

export const setUser = (payload: Codecs.UpdateUserPayload): SetUser => {
  return {
    type: TypeKeys.SET_USER,
    payload,
  }
}

export type UpdateUser = {
  type: TypeKeys.UPDATE_USER
  meta: { deferred: Deferred }
  payload: Codecs.UpdateUserPayload & { renew?: boolean }
}

export const UPDATE_USER = (payload: Codecs.UpdateUserPayload, deferred: Deferred, renew?: boolean): UpdateUser => {
  return {
    type: TypeKeys.UPDATE_USER,
    meta: { deferred },
    payload: { ...payload, renew },
  }
}

export type GetApiTokens = {
  type: TypeKeys.API_TOKENS_GET
  meta: { deferred: Deferred }
}

export const API_TOKENS_GET = (deferred: Deferred): GetApiTokens => {
  return {
    type: TypeKeys.API_TOKENS_GET,
    meta: { deferred },
  }
}

export type UpdateApiTokens = {
  type: TypeKeys.API_TOKENS_UPDATE
  payload: Codecs.ApiToken[]
}

export const API_TOKENS_UPDATE = (payload: UpdateApiTokens['payload']): UpdateApiTokens => {
  return {
    type: TypeKeys.API_TOKENS_UPDATE,
    payload,
  }
}

export type PostApiToken = {
  type: TypeKeys.API_TOKEN_POST
  meta: { deferred: Deferred }
  payload: Codecs.ApiTokenPayload
}

export const API_TOKEN_POST = (payload: PostApiToken['payload'], deferred: Deferred): PostApiToken => {
  return {
    type: TypeKeys.API_TOKEN_POST,
    meta: { deferred },
    payload,
  }
}

export type PostApiKey = {
  type: TypeKeys.API_KEY_POST
  meta: { deferred: Deferred }
  payload: Codecs.ApiKeyPayload
}

export const API_KEY_POST = (payload: PostApiKey['payload'], deferred: Deferred): PostApiKey => {
  return {
    type: TypeKeys.API_KEY_POST,
    meta: { deferred },
    payload,
  }
}

export type UpdateApiToken = {
  type: TypeKeys.API_TOKEN_UPDATE
  payload: Codecs.ApiToken[]
}

export const API_TOKEN_UPDATE = (payload: UpdateApiToken['payload']): UpdateApiToken => {
  return {
    type: TypeKeys.API_TOKEN_UPDATE,
    payload,
  }
}

export type DeleteApiToken = {
  type: TypeKeys.API_TOKEN_DELETE
  meta: { deferred: Deferred }
  payload: string
}

export const API_TOKEN_DELETE = (payload: DeleteApiToken['payload'], deferred: Deferred): DeleteApiToken => {
  return {
    type: TypeKeys.API_TOKEN_DELETE,
    meta: { deferred },
    payload,
  }
}

export type DeleteApiKey = {
  type: TypeKeys.API_KEY_DELETE
  meta: { deferred: Deferred }
  payload: string
}

export const API_KEY_DELETE = (payload: DeleteApiKey['payload'], deferred: Deferred): DeleteApiKey => {
  return {
    type: TypeKeys.API_KEY_DELETE,
    meta: { deferred },
    payload,
  }
}

export type ResetOtpForUser = {
  type: TypeKeys.RESET_OTP_FOR_USER
  meta: { deferred: Deferred }
  payload: {
    password: string
    targetUserId: string
  }
}

export const RESET_OTP_FOR_USER = (payload: ResetOtpForUser['payload'], deferred: Deferred): ResetOtpForUser => {
  return {
    type: TypeKeys.RESET_OTP_FOR_USER,
    meta: { deferred },
    payload,
  }
}

export type RemoveUser = {
  type: TypeKeys.REMOVE_USER
  meta: { deferred: Deferred }
  payload: {
    offset: number
    limit: number
    q: string | undefined
    targetUserId: string
  }
}

export const REMOVE_USER = (payload: RemoveUser['payload'], deferred: Deferred): RemoveUser => {
  return {
    type: TypeKeys.REMOVE_USER,
    meta: { deferred },
    payload,
  }
}

export type InviteUser = {
  type: TypeKeys.INVITE_USER
  meta: { deferred: Deferred }
  payload: {
    inviteUserPayload: Codecs.InviteUserPayload
    usersFetchQueryData: {
      offset: number
      limit: number
      q?: string
    }
  }
}

export function INVITE_USER(payload: InviteUser['payload'], deferred: Deferred): InviteUser {
  return {
    type: TypeKeys.INVITE_USER,
    meta: { deferred },
    payload,
  }
}

export type ResetUserPassword = {
  type: TypeKeys.RESET_USER_PASSWORD
  meta: { deferred: Deferred }
  payload: Codecs.ResetUserPasswordPayload
}

export function RESET_USER_PASSWORD(payload: ResetUserPassword['payload'], deferred: Deferred): ResetUserPassword {
  return {
    type: TypeKeys.RESET_USER_PASSWORD,
    meta: { deferred },
    payload,
  }
}

export type VALIDATE_ORG_USER = {
  type: TypeKeys.VALIDATE_ORG_USER
  meta: { deferred: Deferred }
  payload: {
    currAuth: Codecs.DecodedSession
    nextOrg: string
    nextPath: string
    renew?: boolean
  }
}

export function VALIDATE_ORG_USER(payload: VALIDATE_ORG_USER['payload'], deferred: Deferred): VALIDATE_ORG_USER {
  return {
    type: TypeKeys.VALIDATE_ORG_USER,
    meta: { deferred },
    payload,
  }
}

export type ORG_INIT = {
  type: TypeKeys.ORG_INIT
  meta: { deferred: Deferred }
  payload: {
    org: string
  }
}

export function ORG_INIT(payload: ORG_INIT['payload'], deferred: Deferred): ORG_INIT {
  return {
    type: TypeKeys.ORG_INIT,
    meta: { deferred },
    payload,
  }
}

export type CONFIRM_SSO = {
  type: TypeKeys.CONFIRM_SSO
  meta: { deferred: Deferred }
  payload: Codecs.SsoConfirm
}

export function CONFIRM_SSO(payload: CONFIRM_SSO['payload'], deferred: Deferred): CONFIRM_SSO {
  return {
    type: TypeKeys.CONFIRM_SSO,
    meta: { deferred },
    payload,
  }
}

export type RECOVER_SSO = {
  type: TypeKeys.RECOVER_SSO
  meta: { deferred: Deferred }
  payload: Codecs.SsoRecover
}

export function RECOVER_SSO(payload: RECOVER_SSO['payload'], deferred: Deferred): RECOVER_SSO {
  return {
    type: TypeKeys.RECOVER_SSO,
    meta: { deferred },
    payload,
  }
}

export type SSO_FORCE_RELOG = {
  type: TypeKeys.SSO_FORCE_RELOG
  meta: { deferred: Deferred }
}

export function SSO_FORCE_RELOG(deferred: Deferred): SSO_FORCE_RELOG {
  return {
    type: TypeKeys.SSO_FORCE_RELOG,
    meta: { deferred },
  }
}

export type SSO_SEND_EMAIL = {
  type: TypeKeys.SSO_SEND_EMAIL
  meta: { deferred: Deferred }
}

export function SSO_SEND_EMAIL(deferred: Deferred): SSO_SEND_EMAIL {
  return {
    type: TypeKeys.SSO_SEND_EMAIL,
    meta: { deferred },
  }
}

export type SSO_DELETE = {
  type: TypeKeys.SSO_DELETE
  meta: { deferred: Deferred }
  payload: Codecs.SsoProvider['alias']
}

export function SSO_DELETE(payload: SSO_DELETE['payload'], deferred: Deferred): SSO_DELETE {
  return {
    type: TypeKeys.SSO_DELETE,
    meta: { deferred },
    payload,
  }
}

export type ACCEPT_TOS = {
  type: TypeKeys.ACCEPT_TOS
  meta: { deferred: Deferred }
}

export function ACCEPT_TOS(deferred: Deferred): ACCEPT_TOS {
  return {
    type: TypeKeys.ACCEPT_TOS,
    meta: { deferred },
  }
}

export type REQUEST_PENTEST_POST = {
  type: TypeKeys.REQUEST_PENTEST_POST
  meta: { deferred: Deferred }
  payload: RequestPentestFormValues
}

export function REQUEST_PENTEST_POST(
  payload: REQUEST_PENTEST_POST['payload'],
  deferred: Deferred,
): REQUEST_PENTEST_POST {
  return {
    type: TypeKeys.REQUEST_PENTEST_POST,
    meta: { deferred },
    payload,
  }
}

export type GET_PENTEST_METADATA = {
  type: TypeKeys.GET_PENTEST_METADATA
  meta: { deferred: Deferred }
}

export const GET_PENTEST_METADATA = (deferred: Deferred): GET_PENTEST_METADATA => {
  return {
    type: TypeKeys.GET_PENTEST_METADATA,
    meta: { deferred },
  }
}
