/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable  */
// @ts-nocheck
import debug from 'debug'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import * as EmbedUtils from '../embed.utils'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:pendo')

export const PendoEmbed: React.FC = () => {
  const [isAllowed] = EmbedUtils.useKlaroWatch('pendo', true)

  if (isAllowed) {
    log('enabled')

    return (
      <Helmet>
        <script type="text/javascript">
          {(function (apiKey) {
            ;(function (p, e, n, d, o) {
              let v, w, x, y, z
              o = p[d] = p[d] || {}
              o._q = o._q || []
              v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
              for (w = 0, x = v.length; w < x; ++w)
                (function (m) {
                  o[m] =
                    o[m] ||
                    function () {
                      o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
                    }
                })(v[w])
              y = e.createElement(n)
              y.async = !0
              y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
              z = e.getElementsByTagName(n)[0]
              z.parentNode.insertBefore(y, z)
            })(window, document, 'script', 'pendo')
          })('1c9d643e-d718-449a-79b7-681fb3c7c5fa')}
        </script>
      </Helmet>
    )
  } else {
    log('noop')

    return null
  }
}
