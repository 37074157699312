/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import Activate from './activate'
import Login from './login'
import LoginCheckSSO from './login-check-sso'
import LoginSSO from './login-sso'

export default {
  Activate,
  Login,
  LoginSSO,
  LoginCheckSSO,
}
