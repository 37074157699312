/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ValidationError } from 'io-ts'
import { uniq } from 'lodash/fp'
import pluralize from 'pluralize'

import { getFilteredMessages } from '@/utilities/codec/get-filtered-message'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------
//
export class RandoriValidationError extends Error {
  fieldCount: number
  rPath: string[]

  constructor(message: { errors: ValidationError[]; label: string }, ...rest: string[]) {
    super(...rest)

    this.message = `${message.label} has ${message.errors.length} validation ${pluralize(
      'error',
      message.errors.length,
    )}`
    this.name = 'RandoriValidationError'

    this.rPath = uniq(getFilteredMessages(message.errors))
    this.fieldCount = message.errors.length

    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (isNotNil(Error.captureStackTrace)) {
      Error.captureStackTrace(this, RandoriValidationError)
    } else {
      this.stack = new Error(message.label).stack
    }
  }
}
