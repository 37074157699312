/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-use-before-define */
import { ExhaustiveError } from '@randori/rootkit'
import * as Catch from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _GlobalActions from '@/store/actions/global'
import { MiddlewaresIO } from '@/store/store.utils'

import { _OPENAPI_FETCH } from '../spec'
import { _MAP_API_SPEC_TO_TABLE_COLUMNS } from '../ui'

// ---------------------------------------------------------------------------

export function* watchGlobal(io: MiddlewaresIO) {
  yield* takeEvery(Store.GlobalActions.TypeKeys.DISPATCH_API_REQUEST, Catch.deferredAction(_DISPATCH_API_REQUEST, io))
  yield* takeEvery(Store.GlobalActions.TypeKeys.INITIALIZE_APP, Catch.deferredAction(_INITIALIZE_APP, io))
}

// ---------------------------------------------------------------------------

export function* _DISPATCH_API_REQUEST(io: MiddlewaresIO, action: _GlobalActions.DISPATCH_API_REQUEST) {
  const { verb, url, data } = action.payload

  const baseUrl = window.__RANDORI__.API_URL

  switch (verb) {
    case 'DELETE': {
      return yield* call(io.http.delete, `${baseUrl}${url}`)
    }

    case 'GET': {
      return yield* call(io.http.get, `${baseUrl}${url}`)
    }

    case 'PATCH': {
      return yield* call(io.http.patch, `${baseUrl}${url}`, data)
    }

    case 'POST': {
      return yield* call(io.http.post, `${baseUrl}${url}`, data)
    }

    case 'PUT': {
      return yield* call(io.http.put, `${baseUrl}${url}`, data)
    }

    default:
      throw new ExhaustiveError(verb)
  }
}

export function* _INITIALIZE_APP(io: MiddlewaresIO) {
  yield* call(_OPENAPI_FETCH, io)
  yield* call(_MAP_API_SPEC_TO_TABLE_COLUMNS, io)

  // update table reducers
  //
  // or only do this on a table page
  //
  // fetch user preferences for stuff
}
