/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { CrudRuleGroup, isNotNil, isRuleGroup, RuleOrRuleGroup } from '@randori/rootkit'
import { isNil } from 'lodash/fp'
import qs from 'query-string'
import * as R from 'ramda'

import * as QueryFilterUtils from '@/utilities/query-filter-utils'
import { parseNumber } from '@/utilities/query-string'

import * as Consts from './constants'
import * as CrudTypes from './crud-types'
import * as Serialize from './serialize'

// ---------------------------------------------------------------------------

export * from './default-filters'
export * from './constants'
export * from './crud-types'
export * from './serialize'

// ---------------------------------------------------------------------------

export const emptyRule = {
  condition: 'AND' as const,
  rules: [],
}

export const emptyQString = Serialize.serializeQ({
  ui_id: QueryFilterUtils.UI_ID_STANDARD,
  condition: 'AND',
  rules: [],
})

export const emptyRuleGroupQString = Serialize.serializeQ({
  ui_id: QueryFilterUtils.UI_ID_TOP_LEVEL_AND,
  condition: 'AND',
  rules: [
    {
      ui_id: QueryFilterUtils.UI_ID_NVD,
      condition: 'AND',
      rules: [],
    },
    {
      ui_id: QueryFilterUtils.UI_ID_STANDARD,
      condition: 'AND',
      rules: [],
    },
    {
      ui_id: QueryFilterUtils.UI_ID_FTS,
      condition: 'OR',
      rules: [],
    },
  ],
})

const defaultQuery = {
  limit: Consts.__DEFAULT_PAGE_SIZE__,
  offset: 0,
  q: undefined,
  // @TODO: Revise existing defaults to include the right sort
  // sort: '-target_temptation'
}

export const buildQRule = function (value: string, field: string): CrudTypes.QObject {
  return {
    id: `table.${field}`,
    field: `table.${field}`,
    type: 'object',
    input: 'text',
    operator: 'equal' as const,
    value,
  }
}

// operations is something we use for tags only right now
export interface PatchManyBody {
  data?: { [index: string]: any }
  q: CrudTypes.CrudRuleGroup

  operations?: Array<{
    op: string
    path: string
    value: { [index: string]: any }
  }>
}

// ---------------------------------------------------------------------------

// Create a query string appropriate for Randori CRUD
// to return total of entity
export function createUnfilteredQuery() {
  return `?${qs.stringify({ ...defaultQuery, ...{ limit: 0 } })}`
}

export function createUnaffiliatedQuery() {
  const rule: CrudTypes.CrudRule = {
    field: 'table.affiliation_state',
    id: 'table.affiliation_state',
    input: 'text',
    operator: 'equal',
    type: 'object',
    ui_id: 'show_unaffiliated',
    value: 'Unaffiliated',
  }

  const q = {
    condition: 'OR' as const,
    rules: [rule],
  }

  const serializedQuery = qs.stringify({
    ...defaultQuery,
    q: Serialize.serializeQ(q),
    limit: 0,
  })

  return `?${serializedQuery}`
}

/**
 * Create a query-string appropriate for passing to the randori api service
 *
 * @param queryObj - ReconQuery to serialize
 *
 * @returns a query-string, e.g. "?limit=0&q=eyz..."
 */
export function createQuery(queryObj: CrudTypes.ReconQuery | CrudTypes.StatsQuery) {
  const query = { ...defaultQuery, ...R.filter(isNotNil, queryObj) }

  const _sort: string[] | string = R.propOr([], 'sort', query)
  const sort: string[] = Array.isArray(_sort) ? _sort : [_sort]

  const includeReverseNull = sort.some(
    (s) => s === 'target_temptation' || s === '-target_temptation' || s === 'priority' || s === '-priority',
  )

  return `?${qs.stringify(includeReverseNull ? { ...query, reversed_nulls: true } : query)}`
}

export const RQ = () => {
  const { q } = qs.parse(window.location.search)

  if (isNotNil(q)) {
    return Serialize.unserializeQ(q as string)
  }
}

export const RPARSE = (str: string) => {
  return Serialize.unserializeQ(str)
}

/**
 * Extract Randori CRUD query from a string, and fill in missing parameters
 *
 * @param queryStr - a query-string that may or may not contain ReconQuery values
 *
 * @returns a ReconQuery
 */
export function parseQuery(queryStr: string): CrudTypes.ReconQuery {
  // extract query parameters that we care about, and ignore any garbage
  const { limit, offset, q, sort, reversed_nulls } = qs.parse(queryStr)

  // @TODO: This needs to be revised so that there is a more generic default q
  const queryObj = {
    limit: parseNumber(limit),
    offset: parseNumber(offset),
    q,
    sort,
    reversed_nulls,
  }

  return { ...defaultQuery, ...R.filter(isNotNil, queryObj) }
}

export function getCookieByName(name: string) {
  // @TODO: Replace this match()
  //
  // const pair = new RegExp(name + '=([^;]+)').exec(document.cookie)

  const _match = new RegExp(name + '=([^;]+)')
  // eslint-ignore-next-line @typescript-eslint/prefer-regexp-exec
  const pair = document.cookie.match(_match)

  // eslint-disable-next-line no-magic-numbers
  return pair ? pair[1] : null
}

export function formatHostnameRule(hostname: string) {
  return {
    ui_id: 'hostname',
    field: 'table.hostname',
    id: 'table.hostname',
    input: 'text',
    operator: 'contains' as const,
    type: 'string',
    value: hostname,
  }
}

export const formatSpecTag = (tag: string) => `SPEC+${tag}`

export enum UNAFFILIATION_VALUES {
  NONE = 'None',
  AFFILIATED = 'Affiliated',
  UNAFFILIATED = 'Unaffiliated',
}

export enum AUTHORIZATION_VALUES {
  AUTHORIZED = 'Authorized',
  NONE = 'None',
  PROHIBITED = 'Prohibited',
}

export function buildPatchAffiliation(
  affiliationState: UNAFFILIATION_VALUES,
  q: string,
  comment?: string,
): PatchManyBody {
  return {
    data: {
      affiliation_state: affiliationState,
      ...(comment === undefined ? {} : { comment }),
    },
    q: JSON.parse(atob(q)),
  }
}

export function buildPatchAffiliationById(
  affiliationState: UNAFFILIATION_VALUES,
  entityIds: string[],
  idKey: string,
  comment?: string,
): PatchManyBody {
  return {
    data: {
      affiliation_state: affiliationState,
      ...(comment === undefined ? {} : { comment }),
    },
    q: {
      condition: 'OR',
      rules: entityIds.map((id) => buildQRule(id, idKey)),
    },
  }
}

export function buildPatchAuthorization(authorizationState: AUTHORIZATION_VALUES, q: string): PatchManyBody {
  return {
    data: {
      authorization_state: authorizationState,
    },
    q: JSON.parse(atob(q)),
  }
}

export function buildPatchAuthorizationById(
  authorizationState: AUTHORIZATION_VALUES,
  entityIds: string[],
  idKey: string,
): PatchManyBody {
  return {
    data: {
      authorization_state: authorizationState,
    },
    q: {
      condition: 'OR',
      rules: entityIds.map((id) => buildQRule(id, idKey)),
    },
  }
}

export const buildTagStatesPayload = (tags: CrudTypes.TagStates) => {
  const tagStateToOp: { [index: string]: string } = {
    checked: 'add',
    unchecked: 'remove',
  }

  return Object.keys(tags).map((tagName: string) => {
    const state = tags[tagName]
    const first = R.head(state) as string

    return {
      op: tagStateToOp[first],
      path: `/tags/${tagName}`,
      value: {},
    }
  })
}

export const buildBulkTagsQuery = function (entityIds: string[], tags: CrudTypes.TagStates): PatchManyBody {
  return {
    operations: buildTagStatesPayload(tags),
    q: {
      condition: 'OR',
      rules: entityIds.map((id) => buildQRule(id, 'id')),
    },
  }
}

export const buildBulkTagsFromQ = function (qString: string | undefined, tags: CrudTypes.TagStates): PatchManyBody {
  const emptyQ = {
    condition: 'OR',
    rules: [
      {
        id: 'table.id',
        field: 'table.id',
        type: 'object',
        input: 'text',
        operator: 'not_equal',
        value: '',
      },
    ],
  }

  const q = R.isEmpty(qString) || R.isNil(qString) ? emptyQ : JSON.parse(atob(qString))

  return {
    operations: buildTagStatesPayload(tags),
    q,
  }
}

export const buildBulkTagFromQ = function (qString: string, tag: string): PatchManyBody {
  const q = JSON.parse(atob(qString))

  return {
    operations: [
      {
        op: 'add',
        path: `/tags/${tag}`,
        value: {},
      },
    ],
    q,
  }
}

export function buildBulkPromotionFromIds(serviceIds: string[]) {
  const _rules: CrudTypes.CrudRule[] = serviceIds.map((id) => ({
    id: 'table.id',
    field: 'table.id',
    type: 'object',
    input: 'text',
    operator: 'equal',
    value: id,
  }))

  const query = {
    condition: 'OR' as const,
    rules: _rules,
  }

  return Serialize.serializeQ(query)
}

export const buildUserFilter = (nameOrEmail: string): CrudTypes.CrudRuleGroup => {
  const _rules: CrudTypes.CrudRule[] = [
    {
      field: 'table.email',
      id: 'table.email',
      input: 'text',
      operator: 'contains',
      type: 'object' as const,
      value: nameOrEmail,
    },
    {
      field: 'table.name',
      id: 'table.name',
      input: 'text',
      operator: 'contains' as const,
      type: 'object',
      value: nameOrEmail,
    },
  ]

  return {
    condition: 'OR' as const,
    rules: _rules,
  }
}

export type updateFilterStateConfig = {
  urlState: CrudTypes.ReconQuery
  newFilter: CrudTypes.CrudRuleGroup
  filterUiIds: Set<string>
  addFilter: boolean
}

/**
 * Returns a new query string with a filter added or removed.
 *
 * @remarks
 * This function is a wrapper around `QueryFilterUtils.addOrRemoveStandardRuleGroupSummaryClause`
 * that converts the output to a string.
 *
 * @param config - object of arguments
 *  config.urlState - object of toplevel keys for an rflask query (limit, offset, q, sort)
 *  config.newFilter - a rule group object - must have a `ui_id` that matches the value in `config.filterUiIds`
 *  config.filterUiIds - a set of strings that corresponds the `ui_id` value in `config.newFilter`.
 *    This set makes any additional filters added with the `OR` operator instead of `AND`
 *  config.addFilter - a boolean that determines whether the `newFilter` rule group is being added (true) or removed (false)
 *
 * @returns a new query string with a filter added or removed
 */

export const updateFilterState = (config: updateFilterStateConfig) => {
  const activeFilters: CrudTypes.CrudRuleGroup = Serialize.unserializeQ(
    R.propOr(Serialize.serializeQ({ rules: [], condition: 'AND' }), 'q', config.urlState),
  )

  const root = QueryFilterUtils.addOrRemoveStandardRuleGroupSummaryClause(
    activeFilters,
    config.newFilter,
    config.filterUiIds,
    config.addFilter,
  )

  const queryString = qs.stringify({
    limit: config.urlState.limit,
    offset: config.urlState.offset,
    sort: config.urlState.sort,
    q: Serialize.serializeQ(root),
  })

  return `?${queryString}`
}

export const isFilterActive = (filter: CrudTypes.CrudRuleGroup, activeFilters: CrudTypes.ReconQuery) => {
  const activeRules = R.pathOr(
    [] as CrudTypes.RuleOrRuleGroup[],
    ['rules'],
    Serialize.unserializeQ(R.propOr(Serialize.serializeQ({ condition: 'OR' as const, rules: [] }), 'q', activeFilters)),
  )

  const activeRuleGroup = activeRules.find((rule) => rule.ui_id === filter.ui_id)

  return R.all((a) => R.includes(a, R.propOr([], 'rules', activeRuleGroup)), filter.rules)
}

/**
 * Replaces a group in group, with replacementGroup.  There has to be a group with ui_id in group's rules, that matches
 * the ui_id in replacementGroup.
 *
 * @param {CrudRuleGroup} group
 * @param {RuleOrRuleGroup} rule
 * @return {*}  {CrudRuleGroup}
 */
export const replaceSubRuleGroup = (group: CrudRuleGroup, replacementGroup: CrudRuleGroup): CrudRuleGroup => {
  const newRules = group.rules.filter((rule) => rule.ui_id != replacementGroup.ui_id)

  const newRuleGroup = {
    ...group,
    rules: [...newRules, replacementGroup],
  }

  return newRuleGroup
}

/**
 * Takes in a RuleOrRuleGroup and validates that none of the rules or rule groups
 * are incomplete. This works recursively.
 *
 * @param {RuleOrRuleGroup} rule
 * @return {*}  {boolean}
 */
export const isValidRuleOrRuleGroup = (rule: RuleOrRuleGroup): boolean => {
  if (isRuleGroup(rule)) {
    return rule.rules.map((rule) => isValidRuleOrRuleGroup(rule)).every((x: boolean) => x === true)
  } else {
    if (
      rule.type === 'unset' ||
      // Some rules will have valid values of 0, so cannot use isNil(rule.value)
      rule.value === undefined ||
      isNil(rule.operator) ||
      rule.ui_id === '--' ||
      rule.field === '--' ||
      rule.id === '--'
    ) {
      return false
    } else {
      return true
    }
  }
}

export const Test = {
  createQuery,
}
