/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { Response, Server } from 'miragejs'

import { AffiliatedNetwork } from '@/codecs'
import { get } from '@/utilities/codec'

import { AFFILIATE_URL } from '../constants'

export const affiliateRoutes = (server: Server): void => {
  // GET single
  server.get(`${AFFILIATE_URL}/:id`, (schema, request) => {
    const id = request.params.id

    try {
      const data = get(schema.db.affiliate.find(id), AffiliatedNetwork)

      return { data }
    } catch {
      return new Response(404, {}, { errors: ['Resource not found'] })
    }
  })

  // GET many
  server.get(AFFILIATE_URL, (schema) => {
    const data = schema.db.affiliate

    const pagination = {
      count: data.length,
      offset: 0,
      total: data.length,
    }

    if (isNotNil(data)) {
      return {
        data,
        ...pagination,
      }
    }

    return new Response(500, {}, { errors: ['Something, well... something bad happened.'] })
  })

  // PATCH
  server.patch(AFFILIATE_URL, (_schema) => {
    return new Response(501, {}, { errors: ['WIP'] })
  })

  // POST
  server.post(AFFILIATE_URL, (_schema) => {
    return new Response(501, {}, { errors: ['WIP'] })
  })
}
