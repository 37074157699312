/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { EntityTypeP as _EntityTypeP, EntityTypesP as _EntityTypesP } from '@/utilities/r-entity/entity-types'

// ---------------------------------------------------------------------------

const EntityTypesProjection = _EntityTypesP.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<_EntityTypeP, void>)

export const EntityTypeP = t.keyof(EntityTypesProjection)
export type EntityTypeP = t.TypeOf<typeof EntityTypeP>
