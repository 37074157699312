/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const Term = t.interface({
  affiliation: t.union([t.number, t.null]),
  affiliation_display: t.union([t.number, t.null]),
  affiliation_override: t.boolean,
  confidence: t.number,
  confidence_display: t.union([t.number, t.null]),
  confidence_override: t.boolean,
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.string,
  deleted: t.boolean,
  first_time: t.union([t.string, t.null]),
  id: t.string,
  lens_view: t.union([t.string, t.null]),
  // next_refresh: t.union([t.string, t.null]),
  org_id: t.string,
  perspective: t.string,
  sys_period: t.union([t.string, t.null]),
  term: t.union([t.string, t.null]),
  time: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
})

type _Term = t.TypeOf<typeof Term>
export type Term = _Term

export const TermResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Term),
})

export type TermResponse = t.TypeOf<typeof TermResponse>

// ---------------------------------------------------------------------------

export const TermPostPayload = t.interface({
  data: t.array(
    t.interface({
      term: t.string,
      org_id: t.string,
      perspective: t.union([t.string, t.undefined]),
    }),
  ),
  parent_info: t.UnknownRecord, // @TODO
})

type _TermPostPayload = t.TypeOf<typeof TermPostPayload>
export type TermPostPayload = _TermPostPayload

export const PostTermResponse = t.interface({
  ids: t.array(t.string),
})

type _PostTermResponse = t.TypeOf<typeof PostTermResponse>
export type PostTermResponse = _PostTermResponse
