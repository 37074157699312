/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'

// ---------------------------------------------------------------------------

type LegendItem = {
  fill: string
  label: string
  stroke: string
}

type AttackLegendProps = {
  legend: LegendItem[]
}

export const AttackLegend: React.FunctionComponent<AttackLegendProps> = (props) => {
  return (
    <div className="attack-legend">
      {props.legend.map((item) => {
        return (
          <div className="attack-legend-item" key={item.label}>
            <svg viewBox="-1 -1 50 50">
              <rect width="45" height="45" fill={item.fill} stroke={item.stroke} strokeWidth="1" />
            </svg>

            <span>{item.label}</span>
          </div>
        )
      })}
    </div>
  )
}
