/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

// ---------------------------------------------------------------------------
import * as ArtifactReducers from './artifacts.reducers'
import * as AuthorizationPolicyReducers from './authorization-policy.reducers'
import { CategoriesReducer } from './categories.reducers'
import * as DetectionReducers from './detection.reducers'
import * as HostnameReducers from './hostname.reducers'
import * as IpReducers from './ip.reducers'
import * as NetworkReducers from './network.reducers'
import * as PeerReducers from './peer.reducers'
import * as PolicyReducers from './policy.reducers'
import * as ReportReducers from './report.reducers'
import * as SavedViewsReducers from './saved-views.reducers'
import * as ServiceReducers from './service.reducers'
import * as SocialEntitesReducer from './social.reducers'
import * as TargetReducers from './target.reducers'
import * as TargetsForEntityReducers from './targets-for-entity.reducers'

// ---------------------------------------------------------------------------

export type State = ReturnType<typeof Reducer>
export const Reducer = combineReducers({
  allSavedViews: SavedViewsReducers.AllSavedViewsReducer,
  artifacts: ArtifactReducers.ReconArtifactsReducer,
  artifactsPagination: ArtifactReducers.ReconArtifactsPaginationReducer,
  artifactsTotals: ArtifactReducers.ReconArtifactsTotalsReducer,
  authorizationPaginationPolicies: AuthorizationPolicyReducers.AuthorizationPoliciesPaginationReducer,
  authorizationPolicies: AuthorizationPolicyReducers.AuthorizationPoliciesReducer,
  authorizationPolicyTotals: AuthorizationPolicyReducers.AuthorizationPolicyTotalsReducer,
  categories: CategoriesReducer,
  detections: DetectionReducers.DetectionsReducer,
  detectionsP: DetectionReducers.DetectionsReducerP,
  detectionsPagination: DetectionReducers.DetectionsPaginationReducer,
  detectionsPaginationP: DetectionReducers.DetectionsPaginationReducerP,
  hostnames: HostnameReducers.HostnamesReducer,
  hostnamesPagination: HostnameReducers.HostnamesPaginationReducer,
  hostnamesTotals: HostnameReducers.HostnamesTotalsReducer,
  ips: IpReducers.IpsReducer,
  ipsPagination: IpReducers.IpsPaginationReducer,
  ipsTotals: IpReducers.IpsTotalsReducer,
  networks: NetworkReducers.NetworksReducer,
  networksPagination: NetworkReducers.NetworksPaginationReducer,
  networksTotals: NetworkReducers.NetworksTotalsReducer,
  peerGroupTotals: PeerReducers.PeerGroupTotalsReducer,
  peerGroups: PeerReducers.PeerGroupsReducer,
  peerGroupsPagination: PeerReducers.PeerGroupsPaginationReducer,
  peerMapTotals: PeerReducers.PeerMapTotalsReducer,
  peerMaps: PeerReducers.PeerMapsReducer,
  peerMapsPagination: PeerReducers.PeerMapsPaginationReducer,
  policies: PolicyReducers.PoliciesReducer,
  policiesPagination: PolicyReducers.PoliciesPaginationReducer,
  policyTotals: PolicyReducers.PolicyTotalsReducer,
  reports: ReportReducers.ReportsReducer,
  reportsPagination: ReportReducers.ReportsPaginationReducer,
  reportsTotals: ReportReducers.ReportTotalsReducer,
  savedViews: SavedViewsReducers.SavedViewsReducer,
  savedViewsPagination: SavedViewsReducers.SavedViewsPaginationReducer,
  savedViewsTotals: SavedViewsReducers.SavedViewsTotalsReducer,
  services: ServiceReducers.ServicesReducer,
  servicesPagination: ServiceReducers.ServicesPaginationReducer,
  servicesTotals: ServiceReducers.ServicesTotalsReducer,
  socialEntities: SocialEntitesReducer.SocialEntitesReducer,
  socialEntitiesPagination: SocialEntitesReducer.SocialEntitesPaginationReducer,
  socialEntitiesTotals: SocialEntitesReducer.SocialEntitesTotalsReducer,
  targets: TargetReducers.TargetsReducer,
  targetsForEntity: TargetsForEntityReducers.TargetsForEntityReducer,
  targetsForEntityPagination: TargetsForEntityReducers.TargetsForEntityPaginationReducer,
  targetsPagination: TargetReducers.TargetsPaginationReducer,
  targetsTotals: TargetReducers.TargetsTotalsReducer,
  topLevelDetectionTotals: DetectionReducers.TopLevelDetectionTotalsReducer,
  topLevelDetections: DetectionReducers.TopLevelDetectionsReducer,
  topLevelDetectionsPagination: DetectionReducers.TopLevelDetectionsPaginationReducer,
})

export const Test = {
  HostnameReducers,
  IpReducers,
  NetworkReducers,
  ServiceReducers,
  TargetsForEntityReducers,
}
