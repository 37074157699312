/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type PerspectiveMetadata = t.TypeOf<typeof PerspectiveMetadata>
export const PerspectiveMetadata = CodecUtils.nullable(
  t.partial({
    ip: t.string,
    mac: t.string,
    host: t.string,
  }),
)
