/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { ASAReport } from './asa-report.codecs'
import { ExecSummaryReport } from './exec-summary-report.codecs'

// ---------------------------------------------------------------------------

export const ReportsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(t.union([ASAReport, ExecSummaryReport])),
})
export type ReportsResponse = t.TypeOf<typeof ReportsResponse>

const ReportData = t.union([ASAReport, ExecSummaryReport])
export type Report = t.TypeOf<typeof ReportData>

export const ReportResponse = t.interface({
  data: ReportData,
})
export type ReportResponse = t.TypeOf<typeof ReportResponse>
