/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

export const PostCommentPayload = t.interface({
  data: t.interface({
    comment: t.string,
  }),
})
export type PostCommentPayload = t.TypeOf<typeof PostCommentPayload>

export const UpdateFieldAction = t.interface({
  type: t.string,
  field: t.string,
  value: t.string,
})
export type UpdateFieldAction = t.TypeOf<typeof UpdateFieldAction>

export const Action = t.union([UpdateFieldAction, t.null])
export type Action = t.TypeOf<typeof Action>

export const CommentBody = t.interface({
  id: t.string,
  rel_id: t.string,
  name: t.string,
  comment: t.string,
  created_at: t.string,
  status: t.string,
  is_author: t.boolean,
  is_bulk_applied: t.boolean,
  action: Action,
})
export type CommentBody = t.TypeOf<typeof CommentBody>

export const GetCommentPageResponse = t.interface({
  comments: t.array(CommentBody),
})
export type GetCommentPageResponse = t.TypeOf<typeof GetCommentPageResponse>
