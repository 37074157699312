/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

import { ActionTypes, TagsActions } from '@/store/actions'
// ---------------------------------------------------------------------------
import { TagSchemas } from '@/store/schemas'

// ---------------------------------------------------------------------------

type TagCountsReducerState = {
  count: number
  offset: number
  total: number
  data: ReturnType<typeof TagSchemas.NormalizeTagCounts>
}

const defaultTagCountsReducerState = {
  count: 0,
  offset: 0,
  total: 0,
  data: TagSchemas.NormalizeTagCounts([]),
}

export const TagCountsReducer = (state: TagCountsReducerState = defaultTagCountsReducerState, action: ActionTypes) => {
  switch (action.type) {
    case TagsActions.TypeKeys.TAG_COUNTS_STATE_UPDATE:
      return {
        ...state,
        data: TagSchemas.NormalizeTagCounts(action.payload.data),
      }

    default:
      return state
  }
}

const initialState: string[] = []

const UserTagsReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TagsActions.TypeKeys.USER_TAGS_STATE_UPDATE:
      return action.payload

    default:
      return state
  }
}

export type State = ReturnType<typeof Reducer>

export const Reducer = combineReducers({
  tagCounts: TagCountsReducer,
  userTags: UserTagsReducer,
})

export const Test = {
  TagCountsReducer,
  defaultTagCountsReducerState,
}
