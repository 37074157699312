/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export type TargetTemptationGroups = t.TypeOf<typeof TargetTemptationGroups>
export const TargetTemptationGroups = t.type({
  total_in_ranges: t.number,
  total_other: t.number,
  tt_counts: t.array(
    t.type({
      total: t.number,
      tt_range_name: t.string,
    }),
  ),
})
