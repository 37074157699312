/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import { UserActions } from '@/store'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchUser(io: MiddlewaresIO) {
  yield takeEvery(UserActions.TypeKeys.USER_FETCH, Catch.deferredAction(_USER_FETCH, io))
}

export function* _USER_FETCH(io: MiddlewaresIO) {
  yield* call(io.stdout, 'noop')
}
