/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

const RecipesReducerInitialState: Codecs.WorkatoRecipes = {
  result: [],
}

export const RecipesReducer = (state = RecipesReducerInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.WorkatoActions.TypeKeys.RECIPES_STORE_UPDATE: {
      return action.payload
    }
    default:
      return state
  }
}
