/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import qs from 'query-string'
import { filter } from 'ramda'
import { CallEffect, SagaReturnType } from 'redux-saga/effects'
import { all, call, SagaGenerator, select } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _ReconActions from '@/store/actions/recon/recon.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as CsvUtils from '@/utilities/csv'
import { isEntityTypesTableSummary } from '@/utilities/r-entity'

import * as ReconSagaUtils from './recon.sagas.utils'

// ---------------------------------------------------------------------------

export function* _CSV_DOWNLOAD(io: MiddlewaresIO, action: _ReconActions.CSV_DOWNLOAD) {
  type _Generator = SagaGenerator<SagaReturnType<typeof endpoint>, CallEffect<SagaReturnType<typeof endpoint>>>
  const PER_PAGE = isNotNil(window.__RANDORI__.CSV_RECORD_LIMIT) ? window.__RANDORI__.CSV_RECORD_LIMIT : 2000

  const {
    payload: { entityType, query: _query, total },
  } = action

  const tableId = entityType === 'topLevelDetection' ? 'detection_target' : entityType

  const _selectedColumns = isEntityTypesTableSummary(tableId)
    ? yield* select((state: Store.AppState) => {
        return Store.UISelectors.selectEntityTableColumnsForRender(state, { tableId })
      })
    : []

  const selectedColumns = _selectedColumns.map((column) => column.fieldName)

  const priorityBoundaries = yield* select(Store.PreferencesSelectors.selectPriorityPreferences)
  const temptationBoundaries = yield* select(Store.PreferencesSelectors.selectTTBoundaries)
  const confidenceBoundaries = yield* select(Store.PreferencesSelectors.selectConfidenceBoundaries)

  const endpoint = ReconSagaUtils.getApiSummaryMethod(entityType, io)

  // coerce empty query
  const query = isNotNil(_query) ? _query : ''

  // shake-out irrelevant query parameters (limit, offset)
  const { q, sort }: qs.ParsedQuery = yield* call(qs.parse, query)
  const queryParams = filter(isNotNil, { q, sort })

  // init record accumulator
  let effects: _Generator[] = []
  let offset = 0
  while (offset < total) {
    const serializedQuery = CrudQueryUtils.createQuery({
      ...queryParams,
      limit: PER_PAGE,
      offset: offset,
    })

    effects = [...effects, call(endpoint, get(serializedQuery, QueryString))]

    // iterate
    offset = offset + PER_PAGE
  }

  // dispatch concurrent effects
  const responses = yield* all(effects)
  const records = responses
    .map(({ data }) => {
      return data
    })
    .flat(1)

  // dispatch some DOM nasty to download the CSV
  yield* call(
    CsvUtils.exportCSVFile,
    entityType,
    records,
    selectedColumns,
    priorityBoundaries,
    temptationBoundaries,
    confidenceBoundaries,
  )

  return records
}
