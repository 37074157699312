/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { CrudRule } from '@randori/rootkit'
import debug from 'debug'
import { isEqual, isNil } from 'lodash/fp'

import { parseQuery, serializeQ, unserializeQ } from '@/utilities/crud-query'
import { CrudRuleGroup } from '@/utilities/crud-query'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:processMockQueryForFixtures')

/**
 * We would like to filter fixtures in an approximate way to how the query-builder functions.
 *
 * @param query - query-string, e.g. "?limit=5&offset=0&q=eyJj..."
 *
 * @returns a function to filter T[]
 */
export const processMockQueryForFixtures = (query: string) => {
  type ApiMockResponse<T> = {
    count: number
    data: T[]
    offset: number
    total: number
  }

  /**
   * @param fixtures - fixtures for activity types
   *
   * @returns a filtered set of T[]
   */
  return <T extends Record<string, unknown>>(fixtures: T[]): ApiMockResponse<T> => {
    const { q, offset: _offset, limit: _limit, sort: _sort } = parseQuery(query)

    const emptyRule: CrudRuleGroup = { condition: 'AND' as const, rules: [] }
    const limit = _limit ?? 0
    const offset = _offset ?? 0
    const sort = _sort ?? null
    const emptyQ = serializeQ(emptyRule)

    /**
     * @param fixtures - fixtures for activity types
     * @param rule - the given CrudRule to use for filtering
     *
     * @returns a filtered set of T[]
     */
    const filterFixtures = (fixtures: T[], rule: CrudRule): T[] => {
      let filtered = fixtures
      const { ui_id, value, operator, type } = rule
      if (operator && typeof value === 'string' && type === 'string' && ui_id !== undefined) {
        // Case for any filter that filters by string
        switch (operator) {
          case 'equal': {
            filtered = fixtures.filter((fixture) => {
              return fixture[ui_id] === value
            })
            break
          }
          case 'not_equal': {
            filtered = fixtures.filter((fixture) => {
              return fixture[ui_id] !== value
            })
            break
          }
          case 'icontains': {
            filtered = fixtures.filter((fixture) => {
              const fixtureValue = fixture[ui_id] as string
              if (fixtureValue) {
                return fixtureValue.toLowerCase().includes(value.toLowerCase())
              }
            })
            break
          }
          case 'not_icontains': {
            filtered = fixtures.filter((fixture) => {
              const fixtureValue = fixture[ui_id] as string
              return !fixtureValue.toLowerCase().includes(value.toLowerCase())
            })
            break
          }
        }
      } else if (operator && typeof value === 'string' && type === 'integer' && ui_id !== undefined) {
        // Case for integer filters such as Count Artifacts
        switch (operator) {
          case 'equal': {
            filtered = fixtures.filter((fixture) => {
              return fixture[ui_id] === Number(value)
            })
            break
          }
          case 'not_equal': {
            filtered = fixtures.filter((fixture) => {
              return fixture[ui_id] !== Number(value)
            })
            break
          }
          case 'less': {
            filtered = fixtures.filter((fixture) => {
              return Number(fixture[ui_id]) < Number(value)
            })
            break
          }
          case 'greater': {
            filtered = fixtures.filter((fixture) => {
              return Number(fixture[ui_id]) > Number(value)
            })
            break
          }
        }
      } else if (operator && typeof value === 'string' && type === 'array' && ui_id !== undefined) {
        // Case for mitre filters
        switch (operator) {
          case 'contains_element_ilike': {
            filtered = fixtures.filter((fixture) => {
              const mitreData = fixture[ui_id] as [string]
              return mitreData.some((item) => item.toLowerCase().includes(value.toLowerCase()))
            })
            break
          }
          case 'not_contains_element_ilike': {
            filtered = fixtures.filter((fixture) => {
              const mitreData = fixture[ui_id] as [string]
              return !mitreData.some((item) => item.toLowerCase().includes(value.toLowerCase()))
            })
            break
          }
        }
      }

      return filtered
    }

    /**
     * @param fixtures - An array of fixture arrays to be AND or OR together
     * @param operator - Whether the fixtures will be AND or OR together
     *
     * @returns the resulting array after AND/OR operations are done. Used when
     * multiple rules are being applied
     */
    const mergeFixtures = (fixtures: T[][], operator: 'AND' | 'OR'): T[] => {
      if (operator === 'AND') {
        const mergedFixture = fixtures.reduce((accumulator, currentArray) =>
          accumulator.filter((value) => currentArray.includes(value)),
        )
        return mergedFixture
      }
      const mergedFixture = fixtures.reduce((accumulator, currentArray) =>
        accumulator.concat(currentArray.filter((value) => !accumulator.includes(value))),
      )
      return mergedFixture
    }

    // if there is no query to process, just return as is
    if (isNil(q) || isEqual(q, emptyQ)) {
      const data = fixtures.slice(offset, offset + limit)
      return {
        count: data.length,
        data: data,
        offset: offset ?? 0,
        total: fixtures.length,
      }
    } else {
      let filtered = fixtures
      if (q !== undefined) {
        const ruleGroup = unserializeQ(q)
        const standardGroup = ruleGroup.rules[1] as CrudRuleGroup
        const operator = standardGroup.condition
        const userRules = standardGroup.rules as CrudRule[]
        if (userRules.length > 0) {
          const allFixtures = userRules.map((curRule) => {
            if (curRule.type === 'string' || curRule.type === 'integer' || curRule.type === 'array') {
              return filterFixtures(fixtures, curRule)
            }
            // if there are any unexpected data types return all fixtures
            return fixtures
          })
          filtered = mergeFixtures(allFixtures, operator)
        }
      }
      if (isNotNil(sort)) {
        // randomize array of activity instances
        filtered = filtered.sort(() => (Math.random() > 0.5 ? 1 : -1))
      }

      log(unserializeQ(q))

      const data = filtered.slice(offset, offset + limit)

      return {
        count: data.length,
        data: data,
        offset,
        total: filtered.length,
      }
    }
  }
}

export const processId = (id: string) => {
  return <T extends { id: string }>(fixtures: T[]) => {
    return fixtures.filter((entity) => entity.id === id)
  }
}
