/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { head, isNil } from 'lodash/fp'

import * as Store from '@/store/actions'

import * as EntityDetailInit from './initializers'

// ---------------------------------------------------------------------------

export type EntityDetailNavActiveItem = {
  parentId: EntityDetailInit.EntityDetailNavId
  childId: string | null
}

const _first = (list: EntityDetailInit.EntityDetailNavigationItem[]) => {
  const navigationGroup = head(list)

  if (isNil(navigationGroup)) {
    throw new Error('EntityDetailInit[:kind] missing member')
  }

  return navigationGroup
}

const initialState: Record<NarrowedEntityTypes, EntityDetailNavActiveItem> = {
  ['detection_target']: {
    parentId: _first(EntityDetailInit.initDetections).id,
    childId: null,
  },

  ['hostname']: {
    parentId: _first(EntityDetailInit.initHostnames).id,
    childId: null,
  },

  ['implant']: {
    parentId: _first(EntityDetailInit.initImplant).id,
    childId: null,
  },

  ['ip']: {
    parentId: _first(EntityDetailInit.initIp).id,
    childId: null,
  },

  ['network']: {
    parentId: _first(EntityDetailInit.initNetworks).id,
    childId: null,
  },

  ['policy']: {
    parentId: _first(EntityDetailInit.initPolicy).id,
    childId: null,
  },

  ['redirector']: {
    parentId: _first(EntityDetailInit.initRedirector).id,
    childId: null,
  },

  ['runbook']: {
    parentId: _first(EntityDetailInit.initRunbook).id,
    childId: null,
  },

  ['savedViews']: {
    parentId: _first(EntityDetailInit.initSavedView).id,
    childId: null,
  },

  ['service']: {
    parentId: _first(EntityDetailInit.initService).id,
    childId: null,
  },

  ['social']: {
    parentId: _first(EntityDetailInit.initSocial).id,
    childId: null,
  },

  ['target']: {
    parentId: _first(EntityDetailInit.initTarget).id,
    childId: null,
  },

  ['activity_configuration']: {
    parentId: _first(EntityDetailInit.initActivityConfiguration).id,
    childId: null,
  },

  ['activity_instance']: {
    parentId: _first(EntityDetailInit.initActivityInstance).id,
    childId: null,
  },

  ['authorization_policy']: {
    parentId: _first(EntityDetailInit.initAuthorizationPolicy).id,
    childId: null,
  },

  ['exception_policy']: {
    parentId: _first(EntityDetailInit.initExceptionPolicy).id,
    childId: null,
  },

  ['source']: {
    parentId: _first(EntityDetailInit.initSource).id,
    childId: null,
  },

  ['asset']: {
    parentId: _first(EntityDetailInit.initAsset).id,
    childId: null,
  },

  // @TODO: Carefully remove this
  //
  // @see: https://randori.atlassian.net/browse/EN-10145
  ['topLevelDetection']: {
    parentId: _first(EntityDetailInit.initActivityInstance).id,
    childId: null,
  },

  ['bdo_detection']: {
    parentId: _first(EntityDetailInit.initDetectionsP).id,
    childId: null,
  },
}

export const EntityDetailNavigationActiveStateReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE: {
      return {
        ...state,
        [action.payload.entityType]: action.payload.activeItems,
      }
    }

    default: {
      return state
    }
  }
}

export const keyOfInitialState = t.keyof({
  activity_configuration: null,
  activity_instance: null,
  authorization_policy: null,
  asset: null,
  bdo_detection: null,
  detection_target: null,
  exception_policy: null,
  hostname: null,
  implant: null,
  ip: null,
  network: null,
  policy: null,
  redirector: null,
  runbook: null,
  savedViews: null,
  service: null,
  social: null,
  source: null,
  target: null,

  // @TODO: Carefully remove this
  //
  // @see: https://randori.atlassian.net/browse/EN-10145
  topLevelDetection: null,
})

// TODO we have NarrowedEntityTypes in two places src/store/reducers/ui/entity-detail.reducers/child-nav.reducers/entity-detail--guidance-child-nav-state.reducers.ts
export type NarrowedEntityTypes = t.TypeOf<typeof keyOfInitialState>
