/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export type TagsApi = {
  getTags: () => Promise<Codecs.OrgTags>
  getTagCounts: (query?: string) => Promise<Codecs.TagCountsResponse>
}

export type TagEntityArgs = {
  tag: string
  id: string
}

export function tagsApiFactory(http: HttpClient): TagsApi {
  const baseUrl = '/recon/api/v1'

  return {
    getTags() {
      return http.get(`${baseUrl}/tag`)
    },

    getTagCounts(query) {
      const url = `${baseUrl}/tagcounts`

      return http
        .get(isNotNil(query) ? url.concat(`?${query}`) : url)
        .then(CodecUtils.throwValidate(Codecs.TagCountsResponse, 'TagCountsResponse'))
    },
  }
}
