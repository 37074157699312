/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'
import { Trans } from 'react-i18next'

import { ExternalLink } from '@/components/external-link'

// ---------------------------------------------------------------------------

export enum SpecialViewName {
  EndOfLifeSoftware = 'End-Of-Life Software',
  HighRiskPorts = 'High Risk Ports',
  Log4j = 'CVE-2021-44228 - Potential Log4j Targets',
  Log4j2 = 'Potential Log4j 2 Targets',
  UnencryptedLoginPages = 'Unencrypted login pages',
}

export const isSpecialViewName = (value: string | SpecialViewName) => {
  return Object.values(SpecialViewName).includes(value as SpecialViewName)
}

export const getSpecialViewsTooltipContent = (value: string | SpecialViewName) => {
  switch (value) {
    case SpecialViewName.Log4j:
    case SpecialViewName.Log4j2:
      return (
        <div className="special-view-tooltip">
          <p>
            A list of Targets running Services we believe could leverage a vulnerable Apache logging library - Log4j 2.
          </p>

          <p>
            Please check out our{' '}
            <ExternalLink
              href={'https://www.ibm.com/docs/en/randori?topic=2022-log4j-2-vulnerabilities'}
              noIcon={true}
              addlClasses={'special-view-tooltip-link'}
            >
              Attacker Note
            </ExternalLink>{' '}
            for the most up to date information as well as our{' '}
            <ExternalLink
              href={'https://www.ibm.com/docs/en/randori?topic=2022-log4j-2-vulnerabilities'}
              noIcon={true}
              addlClasses="special-view-tooltip-link"
            >
              Help Center
            </ExternalLink>{' '}
            article for remediation guidance.
          </p>
        </div>
      )

    default:
      return null
  }
}

export const TooltipCISAText: React.FunctionComponent = () => (
  <>
    <p>
      Targets matching products documented in Cybersecurity and Infrastructure Security Agency (CISA) alert{' '}
      <ExternalLink noIcon={true} href={'https://www.cisa.gov/uscert/ncas/alerts/aa22-011a'}>
        AA22-011a
      </ExternalLink>
      .
    </p>
  </>
)

export const TooltipInterestingHostnamesText: React.FunctionComponent = () => (
  <>
    <p>
      Avoid hostnames that describe a system’s business use. Non-descript hostnames make it more difficult for an
      adversary to identify a target system’s purpose.{' '}
    </p>

    <p>
      Prioritize changing these hostnames to increase their resilience based on our{' '}
      <ExternalLink noIcon={true} href="https://www.ibm.com/docs/en/randori?topic=technology-best-practices">
        {' '}
        best practices
      </ExternalLink>
      .
    </p>
  </>
)

export const TooltipPotentialSubdomainTakeover: React.FC = () => {
  return (
    <p>
      <Trans
        i18nKey="special-views-tooltips.potential-subdomain-takeover"
        components={{
          externalLink: (
            <ExternalLink
              noIcon={true}
              href="https://www.ibm.com/docs/en/randori?topic=guidance-potential-subdomain-takeover"
            >
              Help Center
            </ExternalLink>
          ),
        }}
      />
    </p>
  )
}

export const TooltipAttacksInTheNews: React.FC = () => {
  return (
    <p>
      <Trans
        i18nKey="special-views-tooltips.attacks-in-the-news"
        components={{
          externalLink: (
            <ExternalLink
              noIcon={true}
              href="https://www.ibm.com/docs/en/randori?topic=randori-attacks-in-news"
            >
              Attacks in the News Resource Center
            </ExternalLink>
          ),
        }}
      />
    </p>
  )
}
