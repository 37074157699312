/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Preference, PreferencePostPayload } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

const PreferencesResponse = t.interface({
  preferences: t.array(Preference),
  errors: t.unknown,
})

type PreferencesResponse = t.TypeOf<typeof PreferencesResponse>

export type PreferencesApi = {
  getPreferences: () => Promise<PreferencesResponse>
  setPreference: (preference: PreferencePostPayload) => unknown
}

export function preferencesApiFactory(http: HttpClient): PreferencesApi {
  const baseURL = '/auth/api/v1/preferences'

  return {
    getPreferences() {
      return http.get(`${baseURL}`).then(CodecUtils.throwValidate(PreferencesResponse, 'PreferencesResponse'))
    },
    setPreference(preference: PreferencePostPayload) {
      return http.post(`${baseURL}`, preference).then(CodecUtils.throwValidate(Preference, 'Preference'))
    },
  }
}
