/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import classNames from 'classnames'
import * as React from 'react'

// ---------------------------------------------------------------------------
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export type ResizeContainerProps = {
  addlClasses?: string | string[]
  defaultHeight: number
  render: (dimensions: { width: number; height: number }) => JSX.Element
}

export class ResizeContainer extends React.Component<ResizeContainerProps, { width: number; height: number }> {
  _container: React.RefObject<HTMLDivElement>

  constructor(props: ResizeContainerProps) {
    super(props)

    this.state = {
      width: 0,
      height: 0,
    }

    this._container = React.createRef<HTMLDivElement>()
  }

  componentDidMount() {
    window.addEventListener('resize', this._resize)

    this._resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize)
  }

  // ---------------------------------------------------------------------------

  render() {
    const classes = classNames('resize-container', this.props.addlClasses)

    return (
      <div className={classes} ref={this._container}>
        {this.props.render(this.state)}
      </div>
    )
  }

  // ---------------------------------------------------------------------------

  _resize = () => {
    const currentRef = this._container.current

    // @TODO: this is fucked
    //
    // If we have a scaleable box that grows with the page, this has the
    // side-effect of just endlessly growing taller. No good.
    if (isNotNil(currentRef)) {
      const boundingRect = currentRef.getBoundingClientRect()

      const width = boundingRect.width

      const height = boundingRect.height > 0 ? boundingRect.height : this.props.defaultHeight

      this.setState({ width, height })
    }
  }
}
