/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store/actions'
import { ServiceFormUtils } from '@/utilities/entity-form'

// ---------------------------------------------------------------------------

export type HocTemplateReducerState = {
  template: Partial<ServiceFormUtils.ServiceFormValues> | null
}

export const HocTemplateReducer = (
  state = { template: null as HocTemplateReducerState['template'] },
  action: Store.ActionTypes,
): HocTemplateReducerState => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SET_HOC_TEMPLATE: {
      return {
        template: action.payload,
      }
    }

    case Store.UIActions.TypeKeys.RESET_HOC_TEMPLATE: {
      return {
        template: null,
      }
    }

    default: {
      return state
    }
  }
}
