/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize } from 'normalizr'
import { combineReducers } from 'redux'

import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------
import * as AttackActionReducers from './action.reducers'
import * as ImplantReducers from './implant.reducers'
import * as RedirectorReducers from './redirector.reducers'
import * as RunbookReducers from './runbook.reducers'

// ---------------------------------------------------------------------------

export type AttackRecordMeta = Codecs.EntityPagination

const AttackStatsReducer = (state = AttackStatsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ATTACK_STATS_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export const AttackStatsInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

const AttackPaginationReducer = (state = AttackStatsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ATTACK_PAGINATION_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const AttackTargetsReducer = (
  state = normalize({}, Schemas.AttackSchemas.AttackTargets),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ATTACK_TARGETS_UPDATE:
      return normalize(action.payload, Schemas.AttackSchemas.AttackTargets)

    default:
      return state
  }
}

// ---------------------------------------------------------------------------

export type State = ReturnType<typeof Reducer>

export const Reducer = combineReducers({
  attackPagination: AttackPaginationReducer,
  attackStats: AttackStatsReducer,
  attackTargets: AttackTargetsReducer,

  // v2
  // -------------------------------------------------------------------------

  actionsByRunbook: AttackActionReducers.ActionsByRunbookReducer,
  actionsByRunbookPagination: AttackActionReducers.ActionsByRunbookPaginationReducer,
  attackActions: AttackActionReducers.ActionsReducer,
  attackActionsPagination: AttackActionReducers.ActionsPaginationReducer,
  attackActionsTotals: AttackActionReducers.ActionsTotalsReducer,
  implantCallbacks: ImplantReducers.ImplantCallbacksReducer,
  implantCallbacksPagination: ImplantReducers.ImplantCallbacksPaginationReducer,
  implantInterfaces: ImplantReducers.ImplantInterfacesReducer,
  implantInterfacesPagination: ImplantReducers.ImplantInterfacesPaginationReducer,
  implantStats: ImplantReducers.ImplantStatsReducer,
  implants: ImplantReducers.ImplantsReducer,
  implantsPagination: ImplantReducers.ImplantsPaginationReducer,
  implantsTotals: ImplantReducers.ImplantsTotalsReducer,
  redirectors: RedirectorReducers.RedirectorsReducer,
  redirectorsPagination: RedirectorReducers.RedirectorsPaginationReducer,
  redirectorsTotals: RedirectorReducers.RedirectorsTotalsReducer,
  runbooks: RunbookReducers.RunbooksReducer,
  runbooksPagination: RunbookReducers.RunbooksPaginationReducer,
  runbooksTotals: RunbookReducers.RunbooksTotalsReducer,
})

export const Test = {
  AttackPaginationReducer,
  AttackStatsReducer,
  AttackTargetsReducer,
}
