/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Button, ErrProps, Icon, Typography, withCatch } from '@randori/rootkit'
import classNames from 'classnames'
import debug from 'debug'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { AuthActions, GlobalActions } from '@/store'
import { history } from '@/utilities/history'
import { isNotNil } from '@/utilities/is-not'
import { error } from '@/utilities/logger'

// ---------------------------------------------------------------------------

const log = debug('Randori:boundary')

function errToDisplay(err: string) {
  switch (err) {
    case 'RandoriApiError':
      return 'Our apologies, Randori has encountered an API error.'
    default:
      return 'Our apologies, Randori has encountered an error.'
  }
}

export type ApiErrorHandlerProps = {
  error: {
    name: string
    message: string
    ref: string
    stack: string
  }
  addlClasses?: string
} & ErrProps

export const ApiErrorHandler: React.FunctionComponent<ApiErrorHandlerProps> = (props) => {
  const classes = classNames({ 'reset-error-boundary': true }, props.addlClasses)
  return (
    <div className={classes} data-testid="global-error">
      <Typography variant="h3" as="h2" addlClasses="reset-error-heading">
        <Icon kind="warning" addlClasses="reset-warning-icon" />
        {errToDisplay(props.error.name)}
      </Typography>

      <p>If this continues, contact Randori support at http://ibm.com/mysupport.</p>

      <p className="go-back-link" onClick={() => history.goBack()}>
        Go back
      </p>
    </div>
  )
}

type GenErrProps = {
  msg?: any
  addlClasses?: string
} & ErrProps

const GenericError: React.FunctionComponent<GenErrProps> = (props) => {
  if (isNotNil(props.msg)) {
    log(props.msg)
  }

  const classes = classNames({ 'reset-error-boundary': true }, props.addlClasses)

  return (
    <div className={classes} data-testid="global-error">
      <Typography variant="h3" as="h2" addlClasses="reset-error-heading">
        <Icon kind="warning" addlClasses="reset-warning-icon" />
        Our apologies, Randori has encountered an error.
      </Typography>

      <p>If this continues, contact Randori support at http://ibm.com/mysupport.</p>

      <p className="go-back-link" onClick={() => history.goBack()}>
        Go back
      </p>
    </div>
  )
}

type RenderEffErrProps = {
  addlClasses?: string
  errResult: unknown
} & ErrProps

// This is intended to be used with RenderEffect.
export const RenderEffErr: React.FunctionComponent<RenderEffErrProps> = (props) => {
  return <GenericError addlClasses={props.addlClasses} />
}

type ResetErrorProps = {
  handleReset: () => void
}

class ResetError extends React.Component<ResetErrorProps, { hasError: boolean }> {
  constructor(props: ResetErrorProps) {
    super(props)

    this.state = { hasError: false }
  }

  componentDidCatch(err: Error) {
    error(err)

    this.setState({
      hasError: true,
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="reset-error-boundary padding">
          <Typography variant="h3" as="h2" addlClasses="reset-error-heading">
            <Icon kind="warning" addlClasses="reset-warning-icon" />
            Our apologies, Randori has encountered an error.
          </Typography>

          <p>Please log out and try again. If this continues, contact Randori support at http://ibm.com/mysupport.</p>

          <Button modifier="secondary" render={(bProps) => <button {...bProps} onClick={this.props.handleReset} />}>
            Log Out of Randori
          </Button>
        </div>
      )
    } else if (isNotNil(this.props.children)) {
      return this.props.children
    } else {
      return null
    }
  }
}

// ---------------------------------------------------------------------------

export const ErrBoundary = withCatch({ log: error })(() => <GenericError addlClasses="padding" />)

export const ErrMessage = GenericError

export const ResetBoundary: React.FunctionComponent = (props) => {
  const dispatch = useDispatch()

  const handleReset = () => {
    dispatch({
      type: AuthActions.TypeKeys.LOG_OUT,
      meta: {
        deferred: {
          success: () => {
            dispatch(GlobalActions.reset())
            location.assign('/')
          },
          failure: (err: Error) => {
            error(err)
            dispatch(GlobalActions.reset())
          },
        },
      },
    })
  }

  return <ResetError handleReset={handleReset} {...props} />
}
