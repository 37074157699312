/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { DateString, get, UUID } from '@/utilities/codec'

// -----------------------------------------------------------------------------

export const Hostname: Codecs.HostnameP = {
  affiliation_state: 'None',
  characteristic_tags: ['DefaultPage', 'Login', 'NoCSS', 'OldCopyright'],
  confidence: 60,
  first_seen: get('2022-12-15T07:56:03.265079+00:00', DateString),
  hostname: '7d.d5.6132.ip4.static.sl-reverse.com',
  id: get('9d579d52-b739-4496-9af5-3af7cf9c5a6a', UUID),
  impact_score: Codecs.ImpactScoreEnum.none,
  ip_count: 1,
  last_seen: get('2023-04-10T03:08:27.564784+00:00', DateString),
  only_in_review_targets: true,
  org_id: get('b28f017c-c015-4c11-87e0-3e886cdf1b05', UUID),
  perspective__name: 'Default External',
  perspective_id: get('f7627d26-456c-4340-bf12-af7c123cc4ae', UUID),
  perspective_is_targeted: false,
  perspective_type: 'external',
  priority_impact_factor: 0,
  priority_score: 81,
  priority_status_factor: 0,
  priority_tags_factor: 0.18,
  status: Codecs.StatusEnum.accepted,
  target_temptation: 45,
  user_tags: [],
}
