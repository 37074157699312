/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullable, UUID } from '@/utilities/codec'

import { PerspectiveType } from './perspective-type'
import { PerspectiveV2 } from './perspective-v2'

// ---------------------------------------------------------------------------

// @TODO: response codecs should be colocated with api

// @TODO: confirm need to rename data --> perspectives?
export const PerspectivesResponse = t.type({ perspectives: t.array(PerspectiveV2) })
export type PerspectivesResponse = t.TypeOf<typeof PerspectivesResponse>

export const PerspectiveResponse = PerspectiveV2
export type PerspectiveResponse = t.TypeOf<typeof PerspectiveResponse>

export type CreatePerspectiveResponse = t.TypeOf<typeof CreatePerspectiveResponse>
export const CreatePerspectiveResponse = t.type({
  createTime: nullable(DateString),
  id: UUID,
  name: t.string,
  perspectiveType: PerspectiveType,
  properties: t.union([t.record(t.string, t.string), t.null]),
  updateTime: nullable(DateString),
})
