/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DetectionTarget } from './detection-target'

// ---------------------------------------------------------------------------

export type DetectionsForTargetsPayload = {
  id: string
  limit: number
  offset: number
  sort?: string
  updateStore?: boolean
}

export type DetectionTargetResponse = t.TypeOf<typeof DetectionTargetResponse>
export const DetectionTargetResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(DetectionTarget),
})

export const TopLevelDetectionsResponse = DetectionTargetResponse
export type TopLevelDetectionsResponse = t.TypeOf<typeof TopLevelDetectionsResponse>

export const DetectionsResponse = DetectionTargetResponse
export type DetectionsResponse = t.TypeOf<typeof DetectionsResponse>

export type AuthorizeDetectionsPayload = t.TypeOf<typeof AuthorizeDetectionsPayload>
export const AuthorizeDetectionsPayload = t.type({
  detection_ids: t.array(t.string),
  action: t.keyof({ Authorize: null, Unauthorize: null }),
})
