/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import {
  __VERSION_1__,
  __VERSION_2__,
  __VERSION_3__,
  __VERSION_4__,
  PeerData,
  ReportType,
  RiskMatrixItem,
  TopTargetCountItem,
} from './reports-codecs.utils'

// ---------------------------------------------------------------------------

export const v1ExecSummaryReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_1__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
    }),
  }),
})
export type v1ExecSummaryReport = t.TypeOf<typeof v1ExecSummaryReport>

export const v2ExecSummaryReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_2__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      peer_data: PeerData,
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
    }),
  }),
})

export type v3ExecSummaryReport = t.TypeOf<typeof v3ExecSummaryReport>

export const v3ExecSummaryReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_3__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      peer_data: PeerData,
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
    }),
  }),
})

export type v4ExecSummaryReport = t.TypeOf<typeof v4ExecSummaryReport>

export const v4ExecSummaryReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_4__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      peer_data: PeerData,
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
    }),
  }),
})

export const ExecSummaryReport = t.union([
  v1ExecSummaryReport,
  v2ExecSummaryReport,
  v3ExecSummaryReport,
  v4ExecSummaryReport,
])
export type ExecSummaryReport = t.TypeOf<typeof ExecSummaryReport>

export const ExecSummaryReportResponse = t.interface({
  data: t.union([v1ExecSummaryReport, v2ExecSummaryReport, v3ExecSummaryReport, v4ExecSummaryReport]),
})
export type ExecSummaryReportResponse = t.TypeOf<typeof ExecSummaryReportResponse>
