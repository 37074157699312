/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const GlobalIp = t.interface({
  id: t.string,
  affiliation: t.number,
  affiliation_display: t.number,
  affiliation_override: t.boolean,
  confidence: t.number,
  confidence_display: t.number,
  confidence_override: t.boolean,
  country: t.union([t.string, t.null]),
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.union([t.string, t.null]),
  deleted: t.boolean,
  first_time: t.union([t.string, t.null]),
  ip: t.string,
  ip_str: t.string,
  latitude: t.union([t.number, t.null]),
  lens_view: t.union([t.string, t.null]),
  longitude: t.union([t.number, t.null]),
  // next_refresh: t.union([t.string, t.null]),
  org_id: t.string,
  perspective: t.union([t.string, t.null]),
  radius: t.union([t.number, t.null]),
  sys_period: t.union([t.string, t.null]),
  time: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
})

type _GlobalIp = t.TypeOf<typeof GlobalIp>
export type GlobalIp = _GlobalIp

export const GlobalIpsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(GlobalIp),
})
export type GlobalIpsResponse = t.TypeOf<typeof GlobalIpsResponse>

export const GlobalIpPostPayload = t.interface({
  data: t.array(
    t.interface({
      ip: t.string,
      org_id: t.string,
      perspective: t.union([t.string, t.undefined]),
    }),
  ),
  parent_info: t.UnknownRecord, // @TODO
})

type _GlobalIpPostPayload = t.TypeOf<typeof GlobalIpPostPayload>
export type GlobalIpPostPayload = _GlobalIpPostPayload

export const GlobalIpPostResponse = t.interface({})

type _GlobalIpPostResponse = t.TypeOf<typeof GlobalIpPostResponse>
export type GlobalIpPostResponse = _GlobalIpPostResponse
