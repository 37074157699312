/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { DateString, get, IP, UUID } from '@/utilities/codec'

// -----------------------------------------------------------------------------

export const Ip: Codecs.IpP = {
  affiliation_state: 'None',
  all_ports: [{ port: 21, protocol: 6, state: 'open' }],
  characteristic_tags: ['Directory Listing', 'ExpiredSSLCert', 'Login', 'NoCSS'],
  confidence: 75,
  country: null,
  first_seen: get('2022-12-14T18:29:01.711158+00:00', DateString),
  hostname_count: 3,
  id: get('bac89c3d-d875-462d-ba27-248a958e33eb', UUID),
  impact_score: Codecs.ImpactScoreEnum.none,
  ip: get('0.0.0.0', IP),
  ip_str: get('0.0.0.0', IP),
  last_seen: get('2023-04-10T05:46:03.670041+00:00', DateString),
  latitude: null,
  longitude: null,
  network: undefined,
  only_in_review_targets: true,
  open_port_count: 12,
  org_id: get('b28f017c-c015-4c11-87e0-3e886cdf1b05', UUID),
  perspective__name: 'Default External',
  perspective_id: get('f7627d26-456c-4340-bf12-af7c123cc4ae', UUID),
  perspective_is_targeted: false,
  perspective_type: 'external',
  priority_impact_factor: 0.25125,
  priority_score: 211.05,
  priority_status_factor: 0,
  priority_tags_factor: 0.41375,
  radius: null,
  service_count: 10,
  status: Codecs.StatusEnum.none,
  target_count: 12,
  target_temptation: 67,
  user_tags: [],
}
