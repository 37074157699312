/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { IpP as Ip } from '@/codecs'

import { makeNormalizer } from '../utilities/make-normalizer'
import { EntityName } from './ip.constants'

// ---------------------------------------------------------------------------

export const normalizeIps = makeNormalizer<EntityName, Ip>(EntityName)
