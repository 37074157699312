/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import { RequestDataType } from '@/components/count'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { GroupingType } from '@/utilities/entity-groupings'
import * as EntityUtils from '@/utilities/r-entity'

import { TypeKeys } from './recon.actions.typekeys'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export type CHARACTERISTICS_BY_PRIORITY_FETCH = {
  type: TypeKeys.CHARACTERISTICS_BY_PRIORITY_FETCH
  meta: { deferred: Deferred }
  payload: {
    entityType: EntityUtils.EntityType
    tags: Codecs.TagCount[]
  }
}

export function CHARACTERISTICS_BY_PRIORITY_FETCH(
  payload: CHARACTERISTICS_BY_PRIORITY_FETCH['payload'],
  deferred: Deferred,
): CHARACTERISTICS_BY_PRIORITY_FETCH {
  return {
    type: TypeKeys.CHARACTERISTICS_BY_PRIORITY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ENTITY_TOTAL_COUNT_FETCH = {
  type: TypeKeys.ENTITY_TOTAL_COUNT_FETCH
  meta: { deferred: Deferred }
  payload: {
    entityType: EntityUtils.EntityType
    query: string
  }
}

export function ENTITY_TOTAL_COUNT_FETCH(
  payload: ENTITY_TOTAL_COUNT_FETCH['payload'],
  deferred: Deferred,
): ENTITY_TOTAL_COUNT_FETCH {
  return {
    type: TypeKeys.ENTITY_TOTAL_COUNT_FETCH,
    meta: { deferred },
    payload,
  }
}

export type FETCH_QUERY_AS_GROUPING = {
  type: TypeKeys.FETCH_QUERY_AS_GROUPING
  meta: { deferred: Deferred }
  payload: {
    groupingType: GroupingType
    entityType: EntityUtils.EntityType
    filters: { [index: string]: CrudQueryUtils.CrudRuleGroup }
    q?: string
  }
}

export function FETCH_QUERY_AS_GROUPING(
  payload: FETCH_QUERY_AS_GROUPING['payload'],
  deferred: Deferred,
): FETCH_QUERY_AS_GROUPING {
  return {
    type: TypeKeys.FETCH_QUERY_AS_GROUPING,
    meta: { deferred },
    payload,
  }
}

export interface EntityGroupingReq {
  type: GroupingType
  body: {
    entity_type: EntityUtils.EntityType
    min_first_seen: Date
    tt_ranges?: {
      tt_max: number
      tt_min: number
      tt_range_name: string
    }[]
    prio_ranges?: {
      prio_max: number
      prio_min: number
      prio_range_name: string
    }[]
  }
}

export type FETCH_ENTITY_GROUPING = {
  type: TypeKeys.ENTITY_GROUPING_FETCH
  meta: { deferred: Deferred }
  payload: EntityGroupingReq
}

export function FETCH_ENTITY_GROUPING(
  payload: FETCH_ENTITY_GROUPING['payload'],
  deferred: Deferred,
): FETCH_ENTITY_GROUPING {
  return {
    type: TypeKeys.ENTITY_GROUPING_FETCH,
    meta: { deferred },
    payload,
  }
}

export type FetchDataForEntity = {
  type: TypeKeys.RECON_DATA_FOR_ENTITY_FETCH
  meta: { deferred: Deferred }
  payload: RequestDataType
}

export function RECON_DATA_FOR_ENTITY_FETCH(
  payload: FetchDataForEntity['payload'],
  deferred: Deferred,
): FetchDataForEntity {
  return {
    type: TypeKeys.RECON_DATA_FOR_ENTITY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type FetchTargetsForEntity = {
  type: TypeKeys.RECON_TARGETS_FOR_ENTITY_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    idField: string
  } & CrudQueryUtils.ReconQuery
}

export function RECON_TARGETS_FOR_ENTITY_FETCH(
  payload: FetchTargetsForEntity['payload'],
  deferred: Deferred,
): FetchTargetsForEntity {
  return {
    type: TypeKeys.RECON_TARGETS_FOR_ENTITY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_TARGETS_FOR_ENTITY_RESET = {
  type: TypeKeys.RECON_TARGETS_FOR_ENTITY_RESET
}

export function resetTargetsForEntity(): RECON_TARGETS_FOR_ENTITY_RESET {
  return {
    type: TypeKeys.RECON_TARGETS_FOR_ENTITY_RESET,
  }
}

// ---------------------------------------------------------------------------
// Hosts

export type HOSTNAME_FETCH = {
  type: TypeKeys.HOSTNAME_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function HOSTNAME_FETCH(payload: HOSTNAME_FETCH['payload'], deferred: Deferred): HOSTNAME_FETCH {
  return {
    type: TypeKeys.HOSTNAME_FETCH,
    meta: { deferred },
    payload,
  }
}

export type HOSTNAME_STORE_UPDATE = {
  type: TypeKeys.HOSTNAME_STORE_UPDATE
  payload: Codecs.Hostname
}

export function HOSTNAME_STORE_UPDATE(payload: HOSTNAME_STORE_UPDATE['payload']): HOSTNAME_STORE_UPDATE {
  return {
    type: TypeKeys.HOSTNAME_STORE_UPDATE,
    payload,
  }
}

export type RECON_HOSTNAMES_FETCH = {
  type: TypeKeys.RECON_HOSTNAMES_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

export function RECON_HOSTNAMES_FETCH(
  payload: RECON_HOSTNAMES_FETCH['payload'],
  deferred: Deferred,
): RECON_HOSTNAMES_FETCH {
  return {
    type: TypeKeys.RECON_HOSTNAMES_FETCH,
    meta: { deferred },
    payload,
  }
}

// IPs

export type IP_FETCH = {
  type: TypeKeys.IP_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function IP_FETCH(payload: IP_FETCH['payload'], deferred: Deferred): IP_FETCH {
  return {
    type: TypeKeys.IP_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_IPS_FETCH = {
  type: TypeKeys.RECON_IPS_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

export function RECON_IPS_FETCH(payload: RECON_IPS_FETCH['payload'], deferred: Deferred): RECON_IPS_FETCH {
  return {
    type: TypeKeys.RECON_IPS_FETCH,
    meta: { deferred },
    payload,
  }
}

// Networks

export type NETWORK_FETCH = {
  type: TypeKeys.NETWORK_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function NETWORK_FETCH(payload: NETWORK_FETCH['payload'], deferred: Deferred): NETWORK_FETCH {
  return {
    type: TypeKeys.NETWORK_FETCH,
    meta: { deferred },
    payload,
  }
}

export type NETWORK_STORE_UPDATE = {
  type: TypeKeys.NETWORK_STORE_UPDATE
  payload: Codecs.Network
}

export function NETWORK_STORE_UPDATE(payload: NETWORK_STORE_UPDATE['payload']): NETWORK_STORE_UPDATE {
  return {
    type: TypeKeys.NETWORK_STORE_UPDATE,
    payload,
  }
}

export type RECON_NETWORK_FETCH = {
  type: TypeKeys.RECON_NETWORK_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function fetchNetwork(payload: RECON_NETWORK_FETCH['payload'], deferred: Deferred): RECON_NETWORK_FETCH {
  return {
    type: TypeKeys.RECON_NETWORK_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_NETWORKS_FETCH = {
  type: TypeKeys.RECON_NETWORKS_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

export function RECON_NETWORKS_FETCH(
  payload: RECON_NETWORKS_FETCH['payload'],
  deferred: Deferred,
): RECON_NETWORKS_FETCH {
  return {
    type: TypeKeys.RECON_NETWORKS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEWS_FETCH = {
  type: TypeKeys.SAVED_VIEWS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SAVED_VIEWS_FETCH(payload: SAVED_VIEWS_FETCH['payload'], deferred: Deferred): SAVED_VIEWS_FETCH {
  return {
    type: TypeKeys.SAVED_VIEWS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEWS_FETCH_ALL = {
  type: TypeKeys.SAVED_VIEWS_FETCH_ALL
  meta: { deferred: Deferred }
  payload: Partial<{
    limit: number
    offset: number
    sort: string | string[]
  }>
}

export function SAVED_VIEWS_FETCH_ALL(
  payload: SAVED_VIEWS_FETCH_ALL['payload'],
  deferred: Deferred,
): SAVED_VIEWS_FETCH_ALL {
  return {
    type: TypeKeys.SAVED_VIEWS_FETCH_ALL,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEWS_POST = {
  type: TypeKeys.SAVED_VIEWS_POST
  meta: { deferred: Deferred }
  payload: Codecs.SavedViewsPostPayload
}

export function SAVED_VIEWS_POST(payload: SAVED_VIEWS_POST['payload'], deferred: Deferred): SAVED_VIEWS_POST {
  return {
    type: TypeKeys.SAVED_VIEWS_POST,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEWS_DELETE = {
  type: TypeKeys.SAVED_VIEWS_DELETE
  meta: { deferred: Deferred }
  payload: string
}

export function SAVED_VIEWS_DELETE(payload: SAVED_VIEWS_DELETE['payload'], deferred: Deferred): SAVED_VIEWS_DELETE {
  return {
    type: TypeKeys.SAVED_VIEWS_DELETE,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEWS_PATCH = {
  type: TypeKeys.SAVED_VIEWS_PATCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    body: {
      data: {
        filter_data: Record<string, unknown>
        is_favorite: boolean
        is_global: boolean
        name: string
        sort_data: Record<string, unknown>
      }
    }
  }
}

export function SAVED_VIEWS_PATCH(payload: SAVED_VIEWS_PATCH['payload'], deferred: Deferred): SAVED_VIEWS_PATCH {
  return {
    type: TypeKeys.SAVED_VIEWS_PATCH,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEWS_TOTALS_FETCH = {
  type: TypeKeys.SAVED_VIEWS_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SAVED_VIEWS_TOTALS_FETCH(
  payload: SAVED_VIEWS_FETCH['payload'],
  deferred: Deferred,
): SAVED_VIEWS_TOTALS_FETCH {
  return {
    type: TypeKeys.SAVED_VIEWS_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

// Services

export type RECON_SERVICE_FETCH = {
  type: TypeKeys.RECON_SERVICE_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function RECON_SERVICE_FETCH(payload: RECON_SERVICE_FETCH['payload'], deferred: Deferred): RECON_SERVICE_FETCH {
  return {
    type: TypeKeys.RECON_SERVICE_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_SERVICES_FETCH = {
  type: TypeKeys.RECON_SERVICES_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

export function RECON_SERVICES_FETCH(
  payload: RECON_SERVICES_FETCH['payload'],
  deferred: Deferred,
): RECON_SERVICES_FETCH {
  return {
    type: TypeKeys.RECON_SERVICES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEWS_STORE_UPDATE = {
  type: TypeKeys.SAVED_VIEWS_STORE_UPDATE
  payload: Codecs.SavedViewsResponse
}

export function SAVED_VIEWS_STORE_UPDATE(payload: SAVED_VIEWS_STORE_UPDATE['payload']): SAVED_VIEWS_STORE_UPDATE {
  return {
    type: TypeKeys.SAVED_VIEWS_STORE_UPDATE,
    payload,
  }
}

export type ALL_SAVED_VIEWS_STORE_UPDATE = {
  type: TypeKeys.ALL_SAVED_VIEWS_STORE_UPDATE
  payload: Codecs.SavedViewsResponse
}

export function ALL_SAVED_VIEWS_STORE_UPDATE(
  payload: ALL_SAVED_VIEWS_STORE_UPDATE['payload'],
): ALL_SAVED_VIEWS_STORE_UPDATE {
  return {
    type: TypeKeys.ALL_SAVED_VIEWS_STORE_UPDATE,
    payload,
  }
}

export type ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE = {
  type: TypeKeys.ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE
  payload: Codecs.SavedViews
}

export function ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE(
  payload: ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE['payload'],
): ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE {
  return {
    type: TypeKeys.ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE,
    payload,
  }
}

export type SAVED_VIEWS_TOTALS_STORE_UPDATE = {
  type: TypeKeys.SAVED_VIEWS_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function SAVED_VIEWS_TOTALS_STORE_UPDATE(
  payload: SAVED_VIEWS_TOTALS_STORE_UPDATE['payload'],
): SAVED_VIEWS_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.SAVED_VIEWS_TOTALS_STORE_UPDATE,
    payload,
  }
}

// TT

export type RECON_TT_FETCH = {
  type: TypeKeys.RECON_TT_FETCH
  meta: { deferred: Deferred }
}

export function RECON_TT_FETCH(deferred: Deferred): RECON_TT_FETCH {
  return {
    type: TypeKeys.RECON_TT_FETCH,
    meta: { deferred },
  }
}

// Priority Stats
export type RECON_STATS_PRIO_FETCH = {
  type: TypeKeys.RECON_STATS_PRIO_FETCH
  meta: { deferred: Deferred }
}

export function RECON_STATS_PRIO_FETCH(deferred: Deferred): RECON_STATS_PRIO_FETCH {
  return {
    type: TypeKeys.RECON_STATS_PRIO_FETCH,
    meta: { deferred },
  }
}

// Social Entities
export type SOCIAL_ENTITY_FETCH = {
  type: TypeKeys.SOCIAL_ENTITY_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

export function SOCIAL_ENTITY_FETCH(payload: SOCIAL_ENTITY_FETCH['payload'], deferred: Deferred): SOCIAL_ENTITY_FETCH {
  return {
    type: TypeKeys.SOCIAL_ENTITY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SOCIAL_ENTITY_TOTALS_FETCH = {
  type: TypeKeys.SOCIAL_ENTITY_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SOCIAL_ENTITY_TOTALS_FETCH(
  payload: SOCIAL_ENTITY_TOTALS_FETCH['payload'],
  deferred: Deferred,
): SOCIAL_ENTITY_TOTALS_FETCH {
  return {
    type: TypeKeys.SOCIAL_ENTITY_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SOCIAL_ENTITIES_FETCH = {
  type: TypeKeys.SOCIAL_ENTITIES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SOCIAL_ENTITIES_FETCH(
  payload: SOCIAL_ENTITIES_FETCH['payload'],
  deferred: Deferred,
): SOCIAL_ENTITIES_FETCH {
  return {
    type: TypeKeys.SOCIAL_ENTITIES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SOCIAL_ENTITIES_STORE_UPDATE = {
  type: TypeKeys.SOCIAL_ENTITIES_STORE_UPDATE
  payload: Codecs.SocialEntityResponse
}

export function SOCIAL_ENTITIES_STORE_UPDATE(
  payload: SOCIAL_ENTITIES_STORE_UPDATE['payload'],
): SOCIAL_ENTITIES_STORE_UPDATE {
  return {
    type: TypeKeys.SOCIAL_ENTITIES_STORE_UPDATE,
    payload,
  }
}

export type SOCIAL_ENTITIES_TOTALS_STORE_UPDATE = {
  type: TypeKeys.SOCIAL_ENTITIES_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function SOCIAL_ENTITIES_TOTALS_STORE_UPDATE(
  payload: SOCIAL_ENTITIES_TOTALS_STORE_UPDATE['payload'],
): SOCIAL_ENTITIES_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.SOCIAL_ENTITIES_TOTALS_STORE_UPDATE,
    payload,
  }
}

// Statistics

export type RECON_ATTACK_STATISTICS_FETCH = {
  type: TypeKeys.RECON_ATTACK_STATISTICS_FETCH
  meta: { deferred: Deferred }
  payload: { interval: number; entity: string; isHighQuery: boolean; isPrioQuery: boolean; limit: number }
}

export function RECON_ATTACK_STATISTICS_FETCH(
  payload: RECON_ATTACK_STATISTICS_FETCH['payload'],
  deferred: Deferred,
): RECON_ATTACK_STATISTICS_FETCH {
  return {
    type: TypeKeys.RECON_ATTACK_STATISTICS_FETCH,
    meta: { deferred },
    payload,
  }
}

// Data for hostname

// @TODO dead code
type FetchTTForHostname = {
  type: TypeKeys.RECON_TT_FOR_HOSTNAME_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

// @TODO dead code
export function RECON_TT_FOR_HOSTNAME_FETCH(
  payload: FetchTTForHostname['payload'],
  deferred: Deferred,
): FetchTTForHostname {
  return {
    type: TypeKeys.RECON_TT_FOR_HOSTNAME_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_DATA_FOR_HOSTNAME_FETCH = {
  type: TypeKeys.RECON_DATA_FOR_HOSTNAME_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    options: CrudQueryUtils.ReconQuery
  }
}

export function RECON_DATA_FOR_HOSTNAME_FETCH(
  payload: RECON_DATA_FOR_HOSTNAME_FETCH['payload'],
  deferred: Deferred,
): RECON_DATA_FOR_HOSTNAME_FETCH {
  return {
    type: TypeKeys.RECON_DATA_FOR_HOSTNAME_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_HOSTNAME_FETCH = {
  type: TypeKeys.RECON_HOSTNAME_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function RECON_HOSTNAME_FETCH(
  payload: RECON_HOSTNAME_FETCH['payload'],
  deferred: Deferred,
): RECON_HOSTNAME_FETCH {
  return {
    type: TypeKeys.RECON_HOSTNAME_FETCH,
    meta: { deferred },
    payload,
  }
}

// @TODO dead code
type FetchTargets = {
  type: TypeKeys.RECON_TARGETS_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

// @TODO dead code
export function RECON_TARGETS_FETCH(payload: FetchTargets['payload'], deferred: Deferred): FetchTargets {
  return {
    type: TypeKeys.RECON_TARGETS_FETCH,
    meta: { deferred },
    payload,
  }
}

// @TODO dead code
type FetchTargetsTotal = {
  type: TypeKeys.RECON_TARGETS_TOTAL_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

// @TODO dead code
export function RECON_TARGETS_TOTAL_FETCH(
  payload: FetchTargetsTotal['payload'],
  deferred: Deferred,
): FetchTargetsTotal {
  return {
    type: TypeKeys.RECON_TARGETS_TOTAL_FETCH,
    meta: { deferred },
    payload,
  }
}

// @TODO dead code
type UpdateTargetsPagination = {
  type: TypeKeys.RECON_TARGETS_PAGINATION_UPDATE
  payload: {
    count: number
    offset: number
    total: number
  }
}

// @TODO dead code
export function RECON_TARGETS_PAGINATION_UPDATE(payload: UpdateTargetsPagination['payload']): UpdateTargetsPagination {
  return {
    type: TypeKeys.RECON_TARGETS_PAGINATION_UPDATE,
    payload,
  }
}

// @TODO dead code
type UpdateTargets = {
  type: TypeKeys.RECON_TARGETS_UPDATE
  payload: Codecs.DetectionTargetResponse
}

// @TODO dead code
export function RECON_TARGETS_UPDATE(payload: UpdateTargets['payload']): UpdateTargets {
  return {
    type: TypeKeys.RECON_TARGETS_UPDATE,
    payload,
  }
}

export type RECON_TARGET_COUNT_FOR_ENTITY_FETCH = {
  type: TypeKeys.RECON_TARGET_COUNT_FOR_ENTITY_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    idField: string
  }
}

export function RECON_TARGET_COUNT_FOR_ENTITY_FETCH(
  payload: RECON_TARGET_COUNT_FOR_ENTITY_FETCH['payload'],
  deferred: Deferred,
): RECON_TARGET_COUNT_FOR_ENTITY_FETCH {
  return {
    type: TypeKeys.RECON_TARGET_COUNT_FOR_ENTITY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_IPS_FOR_HOSTNAME_FETCH = {
  type: TypeKeys.RECON_IPS_FOR_HOSTNAME_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    options: CrudQueryUtils.ReconQuery
  }
}

export function RECON_IPS_FOR_HOSTNAME_FETCH(
  payload: RECON_IPS_FOR_HOSTNAME_FETCH['payload'],
  deferred: Deferred,
): RECON_IPS_FOR_HOSTNAME_FETCH {
  return {
    type: TypeKeys.RECON_IPS_FOR_HOSTNAME_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_IPS_FOR_NETWORK_FETCH = {
  type: TypeKeys.RECON_IPS_FOR_NETWORK_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    options: CrudQueryUtils.ReconQuery
  }
}

export function RECON_IPS_FOR_NETWORK_FETCH(
  payload: RECON_IPS_FOR_NETWORK_FETCH['payload'],
  deferred: Deferred,
): RECON_IPS_FOR_NETWORK_FETCH {
  return {
    type: TypeKeys.RECON_IPS_FOR_NETWORK_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_ENTITY_PATCH = {
  type: TypeKeys.RECON_ENTITY_PATCH
  meta: { deferred: Deferred }
  payload: {
    body: CrudQueryUtils.PatchManyBody
    hydrateDataFromQ: boolean
    type: ReturnType<typeof EntityUtils.getEndpointByType>
  }
}

export function RECON_ENTITY_PATCH(payload: RECON_ENTITY_PATCH['payload'], deferred: Deferred): RECON_ENTITY_PATCH {
  return {
    type: TypeKeys.RECON_ENTITY_PATCH,
    meta: { deferred },
    payload,
  }
}

export type RECON_MISSED_AFFILIATIONS_POST = {
  type: TypeKeys.RECON_MISSED_AFFILIATIONS_POST
  meta: { deferred: Deferred }
  payload: {
    missedAffiliationFields: string[]
    missedAffiliationsUpload?: File
  }
}

export function RECON_MISSED_AFFILIATIONS_POST(
  payload: RECON_MISSED_AFFILIATIONS_POST['payload'],
  deferred: Deferred,
): RECON_MISSED_AFFILIATIONS_POST {
  return {
    type: TypeKeys.RECON_MISSED_AFFILIATIONS_POST,
    meta: { deferred },
    payload,
  }
}

// incremental refactor
// ---------------------------------------------------------------------------

export type IPS_STORE_UPDATE = {
  type: TypeKeys.IPS_STORE_UPDATE
  payload: Codecs.IpsResponse
}

export function IPS_STORE_UPDATE(payload: IPS_STORE_UPDATE['payload']): IPS_STORE_UPDATE {
  return {
    type: TypeKeys.IPS_STORE_UPDATE,
    payload,
  }
}

export type IP_STORE_UPDATE = {
  type: TypeKeys.IP_STORE_UPDATE
  payload: Codecs.Ip
}

export function IP_STORE_UPDATE(payload: IP_STORE_UPDATE['payload']): IP_STORE_UPDATE {
  return {
    type: TypeKeys.IP_STORE_UPDATE,
    payload,
  }
}

export type IPS_FETCH = {
  type: TypeKeys.IPS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function IPS_FETCH(payload: IPS_FETCH['payload'], deferred: Deferred): IPS_FETCH {
  return {
    type: TypeKeys.IPS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type IP_TOTALS_STORE_UPDATE = {
  type: TypeKeys.IP_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function IP_TOTALS_STORE_UPDATE(payload: IP_TOTALS_STORE_UPDATE['payload']): IP_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.IP_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type HOSTNAMES_STORE_UPDATE = {
  type: TypeKeys.HOSTNAMES_STORE_UPDATE
  payload: Codecs.HostnamesResponse
}

export function HOSTNAMES_STORE_UPDATE(payload: HOSTNAMES_STORE_UPDATE['payload']): HOSTNAMES_STORE_UPDATE {
  return {
    type: TypeKeys.HOSTNAMES_STORE_UPDATE,
    payload,
  }
}

export type HOSTNAMES_FETCH = {
  type: TypeKeys.HOSTNAMES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function HOSTNAMES_FETCH(payload: HOSTNAMES_FETCH['payload'], deferred: Deferred): HOSTNAMES_FETCH {
  return {
    type: TypeKeys.HOSTNAMES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type HOSTNAME_TOTALS_STORE_UPDATE = {
  type: TypeKeys.HOSTNAME_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function HOSTNAME_TOTALS_STORE_UPDATE(
  payload: HOSTNAME_TOTALS_STORE_UPDATE['payload'],
): HOSTNAME_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.HOSTNAME_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type NETWORKS_STORE_UPDATE = {
  type: TypeKeys.NETWORKS_STORE_UPDATE
  payload: Codecs.NetworksResponse
}

export function NETWORKS_STORE_UPDATE(payload: NETWORKS_STORE_UPDATE['payload']): NETWORKS_STORE_UPDATE {
  return {
    type: TypeKeys.NETWORKS_STORE_UPDATE,
    payload,
  }
}

export type NETWORKS_FETCH = {
  type: TypeKeys.NETWORKS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function NETWORKS_FETCH(payload: NETWORKS_FETCH['payload'], deferred: Deferred): NETWORKS_FETCH {
  return {
    type: TypeKeys.NETWORKS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type NETWORK_TOTALS_STORE_UPDATE = {
  type: TypeKeys.NETWORK_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function NETWORK_TOTALS_STORE_UPDATE(
  payload: NETWORK_TOTALS_STORE_UPDATE['payload'],
): NETWORK_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.NETWORK_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type RECON_SAVED_VIEWS_FETCH = {
  type: TypeKeys.RECON_SAVED_VIEWS_FETCH
  meta: { deferred: Deferred }
}

export function RECON_SAVED_VIEWS_FETCH(deferred: Deferred): RECON_SAVED_VIEWS_FETCH {
  return {
    type: TypeKeys.RECON_SAVED_VIEWS_FETCH,
    meta: { deferred },
  }
}

export type SERVICES_STORE_UPDATE = {
  type: TypeKeys.SERVICES_STORE_UPDATE
  payload: Codecs.ServicesResponse
}

export function SERVICES_STORE_UPDATE(payload: SERVICES_STORE_UPDATE['payload']): SERVICES_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICES_STORE_UPDATE,
    payload,
  }
}

export type SERVICES_FETCH = {
  type: TypeKeys.SERVICES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SERVICES_FETCH(payload: SERVICES_FETCH['payload'], deferred: Deferred): SERVICES_FETCH {
  return {
    type: TypeKeys.SERVICES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_TOTALS_STORE_UPDATE = {
  type: TypeKeys.SERVICE_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function SERVICE_TOTALS_STORE_UPDATE(
  payload: SERVICE_TOTALS_STORE_UPDATE['payload'],
): SERVICE_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type IP_TOTALS_FETCH = {
  type: TypeKeys.IP_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function IP_TOTALS_FETCH(payload: IPS_FETCH['payload'], deferred: Deferred): IP_TOTALS_FETCH {
  return {
    type: TypeKeys.IP_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type NETWORK_TOTALS_FETCH = {
  type: TypeKeys.NETWORK_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function NETWORK_TOTALS_FETCH(payload: NETWORKS_FETCH['payload'], deferred: Deferred): NETWORK_TOTALS_FETCH {
  return {
    type: TypeKeys.NETWORK_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_TOTALS_FETCH = {
  type: TypeKeys.SERVICE_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SERVICE_TOTALS_FETCH(payload: SERVICES_FETCH['payload'], deferred: Deferred): SERVICE_TOTALS_FETCH {
  return {
    type: TypeKeys.SERVICE_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type HOSTNAME_TOTALS_FETCH = {
  type: TypeKeys.HOSTNAME_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function HOSTNAME_TOTALS_FETCH(payload: HOSTNAMES_FETCH['payload'], deferred: Deferred): HOSTNAME_TOTALS_FETCH {
  return {
    type: TypeKeys.HOSTNAME_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TARGETS_STORE_UPDATE = {
  type: TypeKeys.TARGETS_STORE_UPDATE
  payload: Codecs.DetectionTargetResponse
}

export function TARGETS_STORE_UPDATE(payload: TARGETS_STORE_UPDATE['payload']): TARGETS_STORE_UPDATE {
  return {
    type: TypeKeys.TARGETS_STORE_UPDATE,
    payload,
  }
}

export type TARGET_CONTEXT_FETCH = {
  type: TypeKeys.TARGET_CONTEXT_FETCH
  meta: { deferred: Deferred }
  payload: {
    serviceId: string
    query: string
  }
}

export function TARGET_CONTEXT_FETCH(
  payload: TARGET_CONTEXT_FETCH['payload'],
  deferred: Deferred,
): TARGET_CONTEXT_FETCH {
  return {
    type: TypeKeys.TARGET_CONTEXT_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TARGETS_FETCH = {
  type: TypeKeys.TARGETS_FETCH
  meta: { deferred: Deferred; updateStore: boolean }
  payload: RouteComponentProps['location']['search']
}

export function TARGETS_FETCH(
  payload: TARGETS_FETCH['payload'],
  deferred: Deferred,
  updateStore = true,
): TARGETS_FETCH {
  return {
    type: TypeKeys.TARGETS_FETCH,
    meta: { deferred, updateStore },
    payload,
  }
}

export type TARGET_TOTALS_FETCH = {
  type: TypeKeys.TARGET_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function TARGET_TOTALS_FETCH(payload: TARGETS_FETCH['payload'], deferred: Deferred): TARGET_TOTALS_FETCH {
  return {
    type: TypeKeys.TARGET_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TARGET_TOTALS_STORE_UPDATE = {
  type: TypeKeys.TARGET_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function TARGET_TOTALS_STORE_UPDATE(payload: TARGET_TOTALS_STORE_UPDATE['payload']): TARGET_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.TARGET_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type TARGET_PATCH = {
  type: TypeKeys.TARGET_PATCH
  meta: { deferred: Deferred }
  payload: Partial<Codecs.DetectionTarget> & { id: string }
}

export function TARGET_PATCH(payload: TARGET_PATCH['payload'], deferred: Deferred): TARGET_PATCH {
  return {
    type: TypeKeys.TARGET_PATCH,
    meta: { deferred },
    payload,
  }
}

export type TARGET_STORE_UPDATE = {
  type: TypeKeys.TARGET_STORE_UPDATE
  payload: Codecs.DetectionTarget
}

export function TARGET_STORE_UPDATE(payload: TARGET_STORE_UPDATE['payload']): TARGET_STORE_UPDATE {
  return {
    type: TypeKeys.TARGET_STORE_UPDATE,
    payload,
  }
}

export type DETECTIONS_FETCH = {
  type: TypeKeys.DETECTIONS_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function DETECTIONS_FETCH(payload: DETECTIONS_FETCH['payload'], deferred: Deferred): DETECTIONS_FETCH {
  return {
    type: TypeKeys.DETECTIONS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type DETECTIONS_FOR_TARGETS_FETCH = {
  type: TypeKeys.DETECTIONS_FOR_TARGETS_FETCH
  meta: { deferred: Deferred }
  payload: Codecs.DetectionsForTargetsPayload
}

export function DETECTIONS_FOR_TARGETS_FETCH(
  payload: DETECTIONS_FOR_TARGETS_FETCH['payload'],
  deferred: Deferred,
): DETECTIONS_FOR_TARGETS_FETCH {
  return {
    type: TypeKeys.DETECTIONS_FOR_TARGETS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type DETECTIONS_STORE_UPDATE = {
  type: TypeKeys.DETECTIONS_STORE_UPDATE
  payload: Codecs.DetectionsResponse
}

export function DETECTIONS_STORE_UPDATE(payload: DETECTIONS_STORE_UPDATE['payload']): DETECTIONS_STORE_UPDATE {
  return {
    type: TypeKeys.DETECTIONS_STORE_UPDATE,
    payload,
  }
}

export type DETECTIONS_FOR_TARGETS_FETCH_P = {
  type: TypeKeys.DETECTIONS_FOR_TARGETS_FETCH_P
  meta: { deferred: Deferred }
  payload: {
    request: Codecs.DetectionsForTargetsPayload
    targetId: string
  }
}

export function DETECTIONS_FOR_TARGETS_FETCH_P(
  payload: DETECTIONS_FOR_TARGETS_FETCH_P['payload'],
  deferred: Deferred,
): DETECTIONS_FOR_TARGETS_FETCH_P {
  return {
    type: TypeKeys.DETECTIONS_FOR_TARGETS_FETCH_P,
    meta: { deferred },
    payload,
  }
}

export type DETECTIONS_STORE_UPDATE_P = {
  type: TypeKeys.DETECTIONS_STORE_UPDATE_P
  payload: {
    response: Codecs.DetectionsResponse
    targetId: string
  }
}

export function DETECTIONS_STORE_UPDATE_P(payload: DETECTIONS_STORE_UPDATE_P['payload']): DETECTIONS_STORE_UPDATE_P {
  return {
    type: TypeKeys.DETECTIONS_STORE_UPDATE_P,
    payload,
  }
}

export type DETECTIONS_STORE_RESET = {
  type: TypeKeys.DETECTIONS_STORE_RESET
}

export function DETECTIONS_STORE_RESET(): DETECTIONS_STORE_RESET {
  return {
    type: TypeKeys.DETECTIONS_STORE_RESET,
  }
}

export type SCREENSHOTS_FOR_TARGETS_FETCH = {
  type: TypeKeys.SCREENSHOTS_FOR_TARGETS_FETCH
  meta: { deferred: Deferred }
  payload: Codecs.DetectionsForTargetsPayload
}

export function SCREENSHOTS_FOR_TARGETS_FETCH(
  payload: SCREENSHOTS_FOR_TARGETS_FETCH['payload'],
  deferred: Deferred,
): SCREENSHOTS_FOR_TARGETS_FETCH {
  return {
    type: TypeKeys.SCREENSHOTS_FOR_TARGETS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type CSV_DOWNLOAD = {
  type: TypeKeys.CSV_DOWNLOAD
  meta: { deferred: Deferred }
  payload: {
    entityType: EntityUtils.EntityType
    query?: string
    total: number
  }
}

export function CSV_DOWNLOAD(payload: CSV_DOWNLOAD['payload'], deferred: Deferred): CSV_DOWNLOAD {
  return {
    type: TypeKeys.CSV_DOWNLOAD,
    meta: { deferred },
    payload,
  }
}

export type TARGETS_FOR_ENTITY_FETCH = {
  type: TypeKeys.TARGETS_FOR_ENTITY_FETCH
  meta: { deferred: Deferred }
  payload: {
    limit: number
    offset: number
    originatingEntityId: string
    originatingEntityLensId: Codecs.LensId
    originatingEntityType: EntityUtils.EntityType
  }
}

export function TARGETS_FOR_ENTITY_FETCH(
  payload: TARGETS_FOR_ENTITY_FETCH['payload'],
  deferred: Deferred,
): TARGETS_FOR_ENTITY_FETCH {
  return {
    type: TypeKeys.TARGETS_FOR_ENTITY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TARGETS_FOR_NETWORK_FETCH = {
  type: TypeKeys.TARGETS_FOR_NETWORK_FETCH
  meta: { deferred: Deferred }
  payload: {
    limit: number
    offset: number
    originatingEntityId: string
    originatingEntityLensId: Codecs.LensId
    value: string
  }
}

export function TARGETS_FOR_NETWORK_FETCH(
  payload: TARGETS_FOR_NETWORK_FETCH['payload'],
  deferred: Deferred,
): TARGETS_FOR_NETWORK_FETCH {
  return {
    type: TypeKeys.TARGETS_FOR_NETWORK_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TARGETS_FOR_ENTITY_STORE_UPDATE = {
  type: TypeKeys.TARGETS_FOR_ENTITY_STORE_UPDATE
  payload: Codecs.DetectionTargetResponse
  meta: {
    originatingEntityId: string
    originatingEntityLensId: Codecs.LensId
    originatingEntityType: EntityUtils.EntityType
  }
}

export function TARGETS_FOR_ENTITY_STORE_UPDATE(
  payload: TARGETS_FOR_ENTITY_STORE_UPDATE['payload'],
  originatingEntityId: string,
  originatingEntityType: EntityUtils.EntityType,
  originatingEntityLensId: Codecs.LensId,
): TARGETS_FOR_ENTITY_STORE_UPDATE {
  return {
    type: TypeKeys.TARGETS_FOR_ENTITY_STORE_UPDATE,
    meta: {
      originatingEntityId,
      originatingEntityType,
      originatingEntityLensId,
    },
    payload,
  }
}

// ---------------------------------------------------------------------------
// top-level detections

export type TOP_LEVEL_DETECTIONS_FETCH = {
  type: TypeKeys.TOP_LEVEL_DETECTIONS_FETCH
  meta: { deferred: Deferred; isGetSingle: boolean }
  payload: RouteComponentProps['location']['search']
}

export function TOP_LEVEL_DETECTIONS_FETCH(
  payload: TOP_LEVEL_DETECTIONS_FETCH['payload'],
  deferred: Deferred,
  isGetSingle = false,
): TOP_LEVEL_DETECTIONS_FETCH {
  return {
    type: TypeKeys.TOP_LEVEL_DETECTIONS_FETCH,
    meta: { isGetSingle, deferred },
    payload,
  }
}

export type TOP_LEVEL_DETECTIONS_STORE_UPDATE = {
  type: TypeKeys.TOP_LEVEL_DETECTIONS_STORE_UPDATE
  payload: Codecs.TopLevelDetectionsResponse
}

export function TOP_LEVEL_DETECTIONS_STORE_UPDATE(
  payload: TOP_LEVEL_DETECTIONS_STORE_UPDATE['payload'],
): TOP_LEVEL_DETECTIONS_STORE_UPDATE {
  return {
    type: TypeKeys.TOP_LEVEL_DETECTIONS_STORE_UPDATE,
    payload,
  }
}

export type TOP_LEVEL_DETECTION_STORE_UPDATE = {
  type: TypeKeys.TOP_LEVEL_DETECTION_STORE_UPDATE
  payload: Codecs.TopLevelDetectionsResponse
}

export function TOP_LEVEL_DETECTION_STORE_UPDATE(
  payload: TOP_LEVEL_DETECTION_STORE_UPDATE['payload'],
): TOP_LEVEL_DETECTION_STORE_UPDATE {
  return {
    type: TypeKeys.TOP_LEVEL_DETECTION_STORE_UPDATE,
    payload,
  }
}

export type TOP_LEVEL_DETECTION_TOTALS_FETCH = {
  type: TypeKeys.TOP_LEVEL_DETECTION_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function TOP_LEVEL_DETECTION_TOTALS_FETCH(
  payload: TOP_LEVEL_DETECTION_TOTALS_FETCH['payload'],
  deferred: Deferred,
): TOP_LEVEL_DETECTION_TOTALS_FETCH {
  return {
    type: TypeKeys.TOP_LEVEL_DETECTION_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE = {
  type: TypeKeys.TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE(
  payload: TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE['payload'],
): TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE,
    payload,
  }
}

// ---------------------------------------------------------------------------
// peer groups

export type PEER_GROUPS_FETCH = {
  type: TypeKeys.PEER_GROUPS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search'] | null
}

export function PEER_GROUPS_FETCH(payload: PEER_GROUPS_FETCH['payload'], deferred: Deferred): PEER_GROUPS_FETCH {
  return {
    type: TypeKeys.PEER_GROUPS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type PEER_GROUPS_STORE_UPDATE = {
  type: TypeKeys.PEER_GROUPS_STORE_UPDATE
  payload: Codecs.PeerGroupResponse
}

export function PEER_GROUPS_STORE_UPDATE(payload: PEER_GROUPS_STORE_UPDATE['payload']): PEER_GROUPS_STORE_UPDATE {
  return {
    type: TypeKeys.PEER_GROUPS_STORE_UPDATE,
    payload,
  }
}

export type PEER_GROUP_TOTALS_FETCH = {
  type: TypeKeys.PEER_GROUP_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function PEER_GROUP_TOTALS_FETCH(
  payload: PEER_GROUP_TOTALS_FETCH['payload'],
  deferred: Deferred,
): PEER_GROUP_TOTALS_FETCH {
  return {
    type: TypeKeys.PEER_GROUP_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type PEER_GROUP_TOTALS_STORE_UPDATE = {
  type: TypeKeys.PEER_GROUP_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function PEER_GROUP_TOTALS_STORE_UPDATE(
  payload: PEER_GROUP_TOTALS_STORE_UPDATE['payload'],
): PEER_GROUP_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.PEER_GROUP_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type PEER_GROUPS_POST = {
  type: TypeKeys.PEER_GROUPS_POST
  meta: { deferred: Deferred }
  payload: Codecs.PeerGroupPostPayload
}

export function PEER_GROUPS_POST(payload: PEER_GROUPS_POST['payload'], deferred: Deferred): PEER_GROUPS_POST {
  return {
    type: TypeKeys.PEER_GROUPS_POST,
    meta: { deferred },
    payload,
  }
}

// ---------------------------------------------------------------------------
// peer maps

export type PEER_MAPS_FETCH = {
  type: TypeKeys.PEER_MAPS_FETCH
  meta: { deferred: Deferred; updateStore: boolean }
  payload: RouteComponentProps['location']['search'] | null
}

export function PEER_MAPS_FETCH(
  payload: PEER_MAPS_FETCH['payload'],
  deferred: Deferred,
  updateStore = true,
): PEER_MAPS_FETCH {
  return {
    type: TypeKeys.PEER_MAPS_FETCH,
    meta: { deferred, updateStore },
    payload,
  }
}

export type PEER_MAP_FETCH = {
  type: TypeKeys.PEER_MAP_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function PEER_MAP_FETCH(payload: PEER_MAP_FETCH['payload'], deferred: Deferred): PEER_MAP_FETCH {
  return {
    type: TypeKeys.PEER_MAP_FETCH,
    meta: { deferred },
    payload,
  }
}

export type PEER_MAPS_STORE_UPDATE = {
  type: TypeKeys.PEER_MAPS_STORE_UPDATE
  payload: Codecs.PeerMapsResponse
}

export function PEER_MAPS_STORE_UPDATE(payload: PEER_MAPS_STORE_UPDATE['payload']): PEER_MAPS_STORE_UPDATE {
  return {
    type: TypeKeys.PEER_MAPS_STORE_UPDATE,
    payload,
  }
}

export type PEER_MAP_STORE_UPDATE = {
  type: TypeKeys.PEER_MAP_STORE_UPDATE
  payload: Codecs.PeerMapPatchResponse
}

export function PEER_MAP_STORE_UPDATE(payload: PEER_MAP_STORE_UPDATE['payload']): PEER_MAP_STORE_UPDATE {
  return {
    type: TypeKeys.PEER_MAP_STORE_UPDATE,
    payload,
  }
}

export type PEER_MAP_TOTALS_FETCH = {
  type: TypeKeys.PEER_MAP_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function PEER_MAP_TOTALS_FETCH(
  payload: PEER_MAP_TOTALS_FETCH['payload'],
  deferred: Deferred,
): PEER_MAP_TOTALS_FETCH {
  return {
    type: TypeKeys.PEER_MAP_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type PEER_MAP_TOTALS_STORE_UPDATE = {
  type: TypeKeys.PEER_MAP_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function PEER_MAP_TOTALS_STORE_UPDATE(
  payload: PEER_MAP_TOTALS_STORE_UPDATE['payload'],
): PEER_MAP_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.PEER_MAP_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type PEER_MAPS_POST = {
  type: TypeKeys.PEER_MAPS_POST
  meta: { deferred: Deferred }
  payload: Codecs.PeerMapPostPayload
}

export function PEER_MAPS_POST(payload: PEER_MAPS_POST['payload'], deferred: Deferred): PEER_MAPS_POST {
  return {
    type: TypeKeys.PEER_MAPS_POST,
    meta: { deferred },
    payload,
  }
}

export type PEER_MAP_PATCH = {
  type: TypeKeys.PEER_MAP_PATCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    data: Codecs.PeerMapPatchPayload['data']
  }
}

export function PEER_MAP_PATCH(payload: PEER_MAP_PATCH['payload'], deferred: Deferred): PEER_MAP_PATCH {
  return {
    type: TypeKeys.PEER_MAP_PATCH,
    meta: { deferred },
    payload,
  }
}

export type PEER_MAP_UPSERT = {
  type: TypeKeys.PEER_MAP_UPSERT
  meta: { deferred: Deferred }
  payload: {
    id: string
    data: Codecs.PeerMapPatchPayload['data']
  }
}

export function PEER_MAP_UPSERT(payload: PEER_MAP_UPSERT['payload'], deferred: Deferred): PEER_MAP_UPSERT {
  return {
    type: TypeKeys.PEER_MAP_UPSERT,
    meta: { deferred },
    payload,
  }
}

export type TIME_SERIES_ENTITY_STATS_FETCH = {
  type: TypeKeys.TIME_SERIES_ENTITY_STATS_FETCH
  meta: { deferred: Deferred }
  payload: {
    entity: EntityUtils.EntityType
    isHighQuery: boolean
    isPrioQuery: boolean
    range: [string, string] // iso-string
  }
}

export function TIME_SERIES_ENTITY_STATS_FETCH(
  payload: TIME_SERIES_ENTITY_STATS_FETCH['payload'],
  deferred: Deferred,
): TIME_SERIES_ENTITY_STATS_FETCH {
  return {
    type: TypeKeys.TIME_SERIES_ENTITY_STATS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEW_FETCH = {
  type: TypeKeys.SAVED_VIEW_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function SAVED_VIEW_FETCH(payload: SAVED_VIEW_FETCH['payload'], deferred: Deferred): SAVED_VIEW_FETCH {
  return {
    type: TypeKeys.SAVED_VIEW_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SAVED_VIEW_STORE_UPDATE = {
  type: TypeKeys.SAVED_VIEW_STORE_UPDATE
  payload: Codecs.SavedViews
}

export function SAVED_VIEW_STORE_UPDATE(payload: SAVED_VIEW_STORE_UPDATE['payload']): SAVED_VIEW_STORE_UPDATE {
  return {
    type: TypeKeys.SAVED_VIEW_STORE_UPDATE,
    payload,
  }
}

export type TOP_SAVED_VIEW_STORE_UPDATE = {
  type: TypeKeys.TOP_SAVED_VIEW_STORE_UPDATE
  payload: Codecs.SavedViews
}

export function TOP_SAVED_VIEW_STORE_UPDATE(
  payload: TOP_SAVED_VIEW_STORE_UPDATE['payload'],
): TOP_SAVED_VIEW_STORE_UPDATE {
  return {
    type: TypeKeys.TOP_SAVED_VIEW_STORE_UPDATE,
    payload,
  }
}

export type TARGET_FETCH = {
  type: TypeKeys.TARGET_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function TARGET_FETCH(payload: TARGET_FETCH['payload'], deferred: Deferred): TARGET_FETCH {
  return {
    type: TypeKeys.TARGET_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_STORE_UPDATE = {
  type: TypeKeys.SERVICE_STORE_UPDATE
  payload: Codecs.Service
}

export function SERVICE_STORE_UPDATE(payload: SERVICE_STORE_UPDATE['payload']): SERVICE_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_STORE_UPDATE,
    payload,
  }
}
