/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { AuthoritativeEntityType } from '../entity.codecs/platform-entity-types'
import { WithInstancePerspective } from '../perspective.codecs'

// ---------------------------------------------------------------------------

// @NOTE: Not currently being used as of 11/9/2023. In order to broaden the
// strings we get for objective__status and be able to display any unexpected
// values, but will likely revisit to have these types align with new protobuf
// enum values once they've been created.
export type ObjectiveStatus = t.TypeOf<typeof ObjectiveStatus>
export const ObjectiveStatus = t.keyof({ success: null, failure: null, unknown: null })

export type EntityStatus = t.TypeOf<typeof EntityStatus>
export const EntityStatus = t.keyof({ pending: null, deleted: null, materialized: null })

export type InstanceState = t.TypeOf<typeof InstanceState>
export const InstanceState = t.keyof({
  'Ingest Complete': null,
  'Ingest Failed': null,
  'Ingest Started': null,
  Pending: null,
  'Work Deploying': null,
  'Work Done': null,
  'Work Failed': null,
  'Work Running': null,
  'Work Started': null,
})

export type SimplifiedInstanceState = t.TypeOf<typeof SimplifiedInstanceState>
export const SimplifiedInstanceState = t.keyof({
  Complete: null,
  'In Progress': null,
  Pending: null,
  Failed: null,
})

export type TrafficDestinationDetail = t.TypeOf<typeof TrafficDestinationDetail>
export const TrafficDestinationDetail = t.type({
  attributes: t.union([t.UnknownRecord, t.undefined]),
  type: t.string,
})

export const _ActivityInstanceBase = t.type({
  configuration__description: t.string,
  configuration__id: t.string,
  configuration__name: t.string,
  configuration__version_id: t.union([t.string, t.null]),
  count__artifacts: t.number,
  count__entities_updated: t.number,
  count__relationships_deleted: t.number,
  id: t.string,
  instance__most_recent_instance_id: t.string,
  // instance__perspective: PerspectiveName, // v1
  instance__state: InstanceState,
  instance__time_duration: t.union([t.number, t.null]),
  instance__time_end: t.union([t.string, t.null]),
  instance__time_start: t.union([t.string, t.null]),
  matching_entity__id: t.string,
  matching_entity__name: t.string,
  matching_entity__type: AuthoritativeEntityType, // TODO: update to t.array(AuthoritativeEntityType)
  matching_entity__detection: t.union([t.string, t.null]),
  matching_entity__email: t.union([t.string, t.null]),
  matching_entity__hostname: t.union([t.string, t.null]),
  matching_entity__ip: t.union([t.string, t.null]),
  matching_entity__network: t.union([t.string, t.null]),
  matching_entity__target: t.union([t.string, t.null]),
  matching_entity__term: t.union([t.string, t.null]),
  mitre__mitigations: t.array(t.string),
  mitre__tactics: t.array(t.string),
  mitre__techniques: t.array(t.string),
  objective__attacker_perspective: t.string,
  objective__description: t.string,
  objective__implication: t.string,
  objective__status: t.string, // ObjectiveStatus,
  org_id: t.string,
  traffic_destination: t.string,
  traffic_destination_detail: TrafficDestinationDetail,
  traffic_source__ip_name: t.union([t.null, t.string]),
})

export type ActivityInstance = t.TypeOf<typeof ActivityInstance>
export const ActivityInstance = t.intersection([_ActivityInstanceBase, WithInstancePerspective])

// @NOTE: Eventual model w/ all fields
export type ActivityInstancesResponse = t.TypeOf<typeof ActivityInstancesResponse>
export const ActivityInstancesResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ActivityInstance),
})

export type ActivityInstanceResponse = t.TypeOf<typeof ActivityInstanceResponse>
export const ActivityInstanceResponse = t.type({
  data: ActivityInstance,
})
