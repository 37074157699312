/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

export const ReconSummaryReducer = (state = { isVisible: true }, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.TOGGLE_TABLE_SUMMARY: {
      return {
        isVisible: !state.isVisible,
      }
    }

    case Store.UIActions.TypeKeys.SHOW_INSPECT_PANE: {
      return {
        isVisible: false,
      }
    }

    default: {
      return state
    }
  }
}
