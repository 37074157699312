/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { isNil } from 'lodash/fp'

import { ImplantBakeStatus, SourceNormalized } from '@/codecs'
import { getRequiredValue } from '@/utilities/codec'

// ---------------------------------------------------------------------------

/**
 * Given a normalized source object (source with extracted parameters), determine the appropriate modal payload to return
 *
 * @param source - normalized source object
 * @returns a payload pertinent to the download source modal
 */
export const getDownloadSourceModalPayload = (source: SourceNormalized) => {
  // extract implant bake status from the source obj
  const validated = getRequiredValue(source, t.type({ 'implant.bake.status': ImplantBakeStatus }))

  if (isNil(validated)) {
    throw new Error(`Unable to get implant bake status for source ${source.id}`)
  }

  const implantBakeStatus = validated['implant.bake.status']
  switch (implantBakeStatus) {
    case 'BAKE_STATUS_TYPE_COMPLETE':
      return {
        addlClasses: 'download-installer',
        childProps: {
          source,
        },
        kind: 'download-installer' as const,
      }
    case 'BAKE_STATUS_TYPE_PENDING':
      return {
        addlClasses: 'creating-source',
        childProps: {
          source,
        },
        kind: 'creating-source' as const,
      }
    default:
      return {
        addlClasses: 'creating-source-error',
        childProps: {
          source,
        },
        kind: 'creating-source-error' as const,
      }
  }
}
