/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { Response, Server } from 'miragejs'

import { HostnameP } from '@/codecs'
import { get } from '@/utilities/codec'

import { HOSTNAMES_V2_URL } from '../constants'

export const hostnameV2Routes = (server: Server): void => {
  server.get(HOSTNAMES_V2_URL, (schema) => {
    const data = schema.db.hostnameV2

    const pagination = {
      offset: 0,
      count: data.length,
      total: data.length,
    }

    if (isNotNil(data)) {
      return {
        data,
        ...pagination,
      }
    }

    return new Response(500, {}, { errors: ['Boom'] })
  })

  server.get(`${HOSTNAMES_V2_URL}/:id`, (schema, request) => {
    const id = request.params.id

    try {
      const data = get(schema.db.hostnameV2.find(id), HostnameP)

      return { data }
    } catch {
      return new Response(404, {}, { errors: ['Resource not found'] })
    }
  })
}
