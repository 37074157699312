/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { NetworkP as Network } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, rflaskGetSingle, throwValidate, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type NetworkApi = {
  getNetwork: (id: UUID) => Promise<GetNetwork>
  getNetworks: (query: QueryString | '') => Promise<GetNetworks>
}

export function networkApiFactory(http: HttpClient): NetworkApi {
  const baseUrl = '/recon/api/v2/network'

  return {
    getNetwork(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetNetwork, 'GetNetwork'))
    },

    getNetworks(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetNetworks, 'GetNetworks'))
    },
  }
}

export type GetNetwork = t.TypeOf<typeof GetNetwork>
export const GetNetwork = rflaskGetSingle(Network)

export type GetNetworks = t.TypeOf<typeof GetNetworks>
export const GetNetworks = rflaskGetMany(Network)
