/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  UNKNOWNS_GET = 'UNKNOWNS_GET',
  UNKNOWNS_STORE_UPDATE = 'UNKNOWNS_STORE_UPDATE',
  UNKNOWN_GET_BY_ENTITY = 'UNKNOWN_GET_BY_ENTITY',
}

export type StateActionTypes = UNKNOWNS_STORE_UPDATE

export type UNKNOWNS_STORE_UPDATE = {
  type: TypeKeys.UNKNOWNS_STORE_UPDATE
  payload: {
    total: number
    type: EntityUtils.EntityType
    unknown: number
  }
}

export function UNKNOWNS_STORE_UPDATE(payload: UNKNOWNS_STORE_UPDATE['payload']): UNKNOWNS_STORE_UPDATE {
  return {
    type: TypeKeys.UNKNOWNS_STORE_UPDATE,
    payload,
  }
}

export type UNKNOWN_GET_BY_ENTITY = {
  type: TypeKeys.UNKNOWN_GET_BY_ENTITY
  meta: { deferred: Deferred }
  payload: EntityUtils.EntityType
}

export function UNKNOWN_GET_BY_ENTITY(
  payload: UNKNOWN_GET_BY_ENTITY['payload'],
  deferred: Deferred,
): UNKNOWN_GET_BY_ENTITY {
  return {
    type: TypeKeys.UNKNOWN_GET_BY_ENTITY,
    meta: { deferred },
    payload,
  }
}

export type UNKNOWNS_GET = {
  type: TypeKeys.UNKNOWNS_GET
  meta: { deferred: Deferred }
}

export function UNKNOWNS_GET(deferred: Deferred): UNKNOWNS_GET {
  return {
    type: TypeKeys.UNKNOWNS_GET,
    meta: { deferred },
  }
}
