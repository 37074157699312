/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
// ---------------------------------------------------------------------------
import { isNotEmpty } from '@randori/rootkit'

import { capitalize } from '@/utilities/capitalize'
import * as QueryFilterUtils from '@/utilities/query-filter-utils'

// ---------------------------------------------------------------------------

export type DisplayName =
  | "Attacker's Objective"
  | 'Activated'
  | 'Activity Class'
  | 'Activity Name'
  | 'Activity Type'
  | 'Activity'
  | 'Affiliation'
  | 'Artifact Type'
  | 'Artifacts'
  | 'Asset'
  | 'Authorization Source'
  | 'Authorization Status'
  | 'Authorization'
  | 'BAR State'
  | 'Bogus'
  | 'CPE Name'
  | 'CVE Name'
  | 'CVEs'
  | 'CVSS Score'
  | 'Characteristics'
  | 'Confidence'
  | 'Consolidated Targets'
  | 'Created By'
  | 'Created On'
  | 'Creation Date'
  | 'Data Hash'
  | 'Deleted'
  | 'Description'
  | 'Destination Email(s)'
  | 'Destination Host(s)'
  | 'Destination IP(s)'
  | 'Destination MAC(s)'
  | 'Destination Misc(s)'
  | 'Destination Network(s)'
  | 'Destination Path(s)'
  | 'Destination Port(s)'
  | 'Destination'
  | 'Detection Criteria'
  | 'Detection'
  | 'Detections'
  | 'Discovered Services'
  | 'Duration'
  | 'Edited On'
  | 'Email'
  | 'Enabled'
  | 'End Date / Time'
  | 'Entity Type'
  | 'Favorite'
  | 'First Name'
  | 'First Seen'
  | 'Full Text Search'
  | 'Global View'
  | 'Host IP(s)'
  | 'Hostname'
  | 'ID'
  | 'INET'
  | 'IP Address'
  | 'IP Count'
  | 'IP Range'
  | 'IP'
  | 'Impact Score'
  | 'Implant Nickname'
  | 'Implant UID'
  | 'Interval'
  | 'Last Check-In'
  | 'Last Name'
  | 'Last Queued'
  | 'Last Seen'
  | 'Location'
  | 'MITRE Mitigations'
  | 'MITRE Tactics'
  | 'MITRE Techniques'
  | 'Matching Entity Type'
  | 'Matching Entity'
  | 'Name'
  | 'Network'
  | 'Nickname'
  | 'OS'
  | 'Objective Result'
  | 'Open Port Count'
  | 'Open Port'
  | 'Org ID'
  | 'Organizations'
  | 'Origination Email(s)'
  | 'Origination Host(s)'
  | 'Origination IP(s)'
  | 'Origination MAC(s)'
  | 'Origination Misc(s)'
  | 'Path'
  | 'Perspective'
  | 'Perspective Type'
  | 'Policy Name'
  | 'Port'
  | 'Priority'
  | 'Process Blueprint Status'
  | 'Promoted'
  | 'Protocols'
  | 'Randori - Activity Description'
  | 'Randori - Authority Distance'
  | 'Randori - Authority Override'
  | 'Randori - Authority'
  | 'Randori - Authorization Source'
  | 'Randori - Authorization Status'
  | 'Randori - Authorization'
  | 'Randori - Characteristics - Old'
  | 'Randori - Deleted Discovery Relation Count'
  | 'Randori - Deleted'
  | 'Randori - Destination Entity Type'
  | 'Randori - First Seen'
  | 'Randori - ID'
  | 'Randori - INET'
  | 'Randori - Last Seen'
  | 'Randori - Location'
  | 'Randori - Matching Entity IP'
  | 'Randori - Network'
  | 'Randori - Org ID'
  | 'Randori - Perspective Name'
  | 'Randori - Service Version'
  | 'Randori - Temptation Private Weakness'
  | 'Randori - Temptation Public Weakness'
  | 'Randori - Updated Entities'
  | 'Randori - Validated Vulnerabilities Count'
  | 'Randori - Validated Vulnerabilities'
  | 'Related Detections'
  | 'Related Targets'
  | 'Retired'
  | 'Run State'
  | 'Runbook Status'
  | 'Runbook Title'
  | 'Runbook UID'
  | 'Select Rule'
  | 'Service Count'
  | 'Service Description'
  | 'Service Detector'
  | 'Service Name'
  | 'Service Vendor'
  | 'Service Version'
  | 'Short Name'
  | 'Start Date / Time'
  | 'Status'
  | 'Tags'
  | 'Target ID'
  | 'Technique IDs'
  | 'Temptation Applicability'
  | 'Temptation Criticality'
  | 'Temptation Enumerability'
  | 'Temptation Exploitablility'
  | 'Temptation Post Exploit Potential'
  | 'Temptation Private Weakness'
  | 'Temptation Public Weakness'
  | 'Temptation Research Potential'
  | 'Temptation'
  | 'Term'
  | 'Title'
  | 'Traffic Source'
  | 'Usage'
  | 'Validated Vulnerabilities Count'
  | 'Validated Vulnerabilities'

export function humanize(str: string) {
  const shortened = str.split('_').filter(isNotEmpty).map(capitalize).join(' ')

  if (shortened === 'Ip') {
    return 'IP'
  }

  if (shortened === 'Id') {
    return 'ID'
  }

  return shortened
}

export const getFieldDisplayName = (ui_id: string, randoriOnly: boolean): DisplayName => {
  switch (ui_id) {
    case '--':
      return 'Select Rule'

    case 'activated':
      return 'Activated'

    case 'activity_class':
      return 'Activity Class'

    case 'activity_configuration_name':
      return 'Activity Name'

    case 'activity_type_name':
      return 'Activity Type'

    case 'affected_orgs':
      return 'Organizations'

    case 'artifact_data_hash':
      return 'Data Hash'

    case 'artifact_detection_criteria':
      return 'Detection Criteria'

    case 'authority':
      return 'Randori - Authority'

    case 'authority_distance':
      return 'Randori - Authority Distance'

    case 'authority_override':
      return 'Randori - Authority Override'

    case 'bar_state':
      return 'BAR State'

    case 'blueprint_approval_status':
      return 'Process Blueprint Status'

    case 'confidence':
      return 'Confidence'

    case 'configuration__name':
      return 'Activity'

    case 'configuration__description':
      return 'Randori - Activity Description'

    case 'count__artifacts':
      return 'Artifacts'

    case 'count__relationships_deleted':
      return 'Randori - Deleted Discovery Relation Count'

    case 'count__entities_updated':
      return 'Randori - Updated Entities'

    case 'cpe_str':
      return 'CPE Name'

    case 'cve':
      return 'CVE Name'

    case 'cves':
      return 'CVEs'

    case 'cvss':
      return 'CVSS Score'

    case 'create_time':
      return 'Creation Date'

    case 'created_at':
      return 'Created On'

    case 'creator_user_name':
      return 'Created By'

    case 'deleted':
      return `${randoriOnly ? 'Randori - ' : ''}Deleted`

    case 'description':
      return 'Service Description'

    case 'detections':
      return 'Detections'

    case 'detection_target':
      return 'Detection'

    case 'display_name':
      return 'Asset'

    case 'dst_email_many':
      return 'Destination Email(s)'

    case 'dst_host_many':
      return 'Destination Host(s)'

    case 'dst_ip_many':
      return 'Destination IP(s)'

    case 'dst_mac_many':
      return 'Destination MAC(s)'

    case 'dst_misc_many':
      return 'Destination Misc(s)'

    case 'dst_network_many':
      return 'Destination Network(s)'

    case 'dst_path_many':
      return 'Destination Path(s)'

    case 'dst_port_many':
      return 'Destination Port(s)'

    case 'edited_at':
      return 'Edited On'

    case 'email':
      return 'Email'

    case 'end_time':
      return 'End Date / Time'

    case 'entity_type':
      return 'Entity Type'

    case 'exception_policy_description':
      return 'Description'

    case 'exception_policy_name':
      return 'Policy Name'

    case 'externalIp':
      return 'IP'

    case QueryFilterUtils.UI_ID_FTS:
      return 'Full Text Search'

    case 'host_ips':
      return 'Host IP(s)'

    case 'hostname':
      return 'Hostname'

    case 'hostnames':
      return 'Hostname'

    case 'impact_score':
      return 'Impact Score'

    case 'implant_nick':
      return 'Implant Nickname'

    case 'id':
      return `${randoriOnly ? 'Randori - ' : ''}ID`

    case 'instance__state':
      return 'Run State'

    case 'instance__time_duration':
      return 'Duration'

    case 'instance__time_end':
      return 'End Date / Time'

    case 'instance__time_start':
      return 'Start Date / Time'

    case 'ip_count':
      return 'IP Count'

    case 'ip_range':
      return 'IP Range'

    case 'ips':
      return 'IP Address'

    case 'ip_str':
      return 'IP Address'

    case 'is_favorite':
      return 'Favorite'

    case 'is_global':
      return 'Global View'

    case 'last_checkin':
      return 'Last Check-In'

    case 'last_planned_at':
      return 'Last Queued'

    case 'location':
      return `${randoriOnly ? 'Randori - ' : ''}Location`

    // Activity Library is single underscore, Log is double
    case 'mitre_mitigations':
    case 'mitre__mitigations':
      return 'MITRE Mitigations'

    case 'mitre_tactics':
    case 'mitre__tactics':
      return 'MITRE Tactics'

    case 'mitre_techniques':
    case 'mitre__techniques':
      return 'MITRE Techniques'

    case 'name':
      return 'Service Name'

    case 'network_str':
      return `${randoriOnly ? 'Randori - ' : ''}Network`

    case 'nickname':
      return 'Nickname'

    case 'objective__status':
      return 'Objective Result'

    case 'open_port':
      return 'Open Port'

    case 'open_port_count':
      return 'Open Port Count'

    case 'org_id':
      return `${randoriOnly ? 'Randori - ' : ''}Org ID`

    case 'org_shortname':
      return 'Short Name'

    case 'ostype':
      return 'OS'

    case 'period':
      return 'Interval'

    case 'person_first_name':
      return 'First Name'

    case 'person_last_name':
      return 'Last Name'

    case 'perspective':
      return 'Randori - Perspective Name'

    // 'perspective_name' in the backend has historically been 'Perspective' in the frontend
    case 'perspective_name':
      return 'Perspective'

    case 'perspective_type':
      return 'Perspective Type'

    case 'policy_name':
      return 'Policy Name'

    case 'priority_score':
      return 'Priority'

    case 'promoted':
      return 'Promoted'

    case 'retired':
      return 'Retired'

    case 'runbook_name':
      return 'Runbook Title'

    case 'runbook_status':
      return 'Runbook Status'

    case 'runbook_uid':
      return 'Runbook UID'

    case 'saved_view_name':
      return 'Name'

    case 'service_count':
      return 'Service Count'

    case 'service_deleted':
      return 'Deleted'

    case 'service_detector':
      return 'Service Detector'

    case 'src_email_many':
      return 'Origination Email(s)'

    case 'src_host_many':
      return 'Origination Host(s)'

    case 'src_ip_many':
      return 'Origination IP(s)'

    case 'src_mac_many':
      return 'Origination MAC(s)'

    case 'src_misc_many':
      return 'Origination Misc(s)'

    case 'start_time':
      return 'Start Date / Time'

    case 'suggestion_bogus':
      return 'Bogus'

    case 'target_id':
      return 'Target ID'

    case 'term':
      return 'Term'

    case 'target_temptation':
      return 'Temptation'

    case 'technique_ids':
      return 'Technique IDs'

    case 'title':
      return 'Title'

    case 'traffic_source__ip_name':
      return 'Traffic Source'

    case 'matching_entity__ip':
      return 'Randori - Matching Entity IP'

    case 'matching_entity__name':
      return 'Matching Entity'

    case 'matching_entity__type':
      return 'Matching Entity Type'

    case 'uid':
      return 'Implant UID'

    case 'unaffiliated':
      return 'Affiliation'

    case 'usage':
      return 'Usage'

    case 'version':
      return `${randoriOnly ? 'Randori - ' : ''}Service Version`

    case 'vendor':
      return 'Service Vendor'

    case 'characteristic_tags':
      return 'Characteristics'

    case 'user_tags':
      return 'Tags'

    case 'applicability':
      return 'Temptation Applicability'

    case 'criticality':
      return 'Temptation Criticality'

    case 'enumerability':
      return 'Temptation Enumerability'

    case 'exploitability':
      return 'Temptation Exploitablility'

    case 'post_exploit':
      return 'Temptation Post Exploit Potential'

    case 'private_weakness':
      return `${randoriOnly ? 'Randori - ' : ''}Temptation Private Weakness`

    case 'public_weakness':
      return `${randoriOnly ? 'Randori - ' : ''}Temptation Public Weakness`

    case 'research':
      return 'Temptation Research Potential'

    case 'validated_vulnerabilities_detection':
    case 'validated_vulnerabilities_target':
      return `${randoriOnly ? 'Randori - ' : ''}Validated Vulnerabilities`

    case 'validated_vulnerabilities_detection_count':
    case 'validated_vulnerabilities_target_count':
      return `${randoriOnly ? 'Randori - ' : ''}Validated Vulnerabilities Count`

    case 'artifact_first_seen':
    case 'first_seen':
    case 'target_first_seen':
      return `${randoriOnly ? 'Randori - ' : ''}First Seen`

    case 'artifact_last_seen':
    case 'last_seen':
    case 'target_last_seen':
      return `${randoriOnly ? 'Randori - ' : ''}Last Seen`

    case 'status':
    case 'recon_status':
    case 'implant_status':
    case 'redirector_status':
      return 'Status'

    case 'instance_count':
    case 'target_count':
      return 'Related Targets'

    case 'authorization_state':
      return `${randoriOnly ? 'Randori - ' : ''}Authorization`

    case 'attack_authorization':
      return `${randoriOnly ? 'Randori - ' : ''}Authorization`

    case 'artifactType':
    case 'target_artifact_type':
      return 'Artifact Type'

    case 'objective__attacker_perspective':
      return "Attacker's Objective"

    case 'objective__description':
    case 'objective__implication':
      const [_, name] = ui_id.split('__')
      return humanize(name) as DisplayName

    case 'detection_authorization':
      return `${randoriOnly ? 'Randori - ' : ''}Authorization`

    case 'authorized_detections':
      return `${randoriOnly ? 'Randori - ' : ''}Authorization`

    case 'authorizing_policies':
      return `${randoriOnly ? 'Randori - ' : ''}Authorization Source`

    case 'is_active':
      return 'Enabled'

    case 'is_dirty':
      return 'Status'

    case 'consolidated_target__names':
      return 'Consolidated Targets'

    case 'discovered_service__names':
      return 'Discovered Services'

    case 'detection_criteria__port':
      return 'Port'

    case 'detection_criteria__hostname':
      return 'Hostname'

    case 'detection_criteria__ip':
      return `${randoriOnly ? 'Randori - ' : ''}INET`

    case 'detection_criteria__ip_str':
      return 'IP'

    case 'detection_criteria__protocols':
      return 'Protocols'

    case 'detection_criteria__path':
      return 'Path'

    default:
      return `${randoriOnly ? 'Randori - ' : ''}${humanize(ui_id)}` as DisplayName
  }
}
