/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const _Authorization = t.keyof({
  Authorized: null,
  None: null,
  Prohibited: null,
})
type _Authorization = t.TypeOf<typeof _Authorization>

export const Authorization = t.union([_Authorization, t.null])
export type Authorization = t.TypeOf<typeof Authorization>
