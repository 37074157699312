/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNil } from 'lodash/fp'
import qs from 'query-string'
import * as Catch from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Codecs from '@/codecs'
import * as Store from '@/store'
import type {
  ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
  RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
} from '@/store/actions/activity/artifact-for-activity-instance.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import { UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export function* watchArtifactsForActivityInstance(io: MiddlewaresIO) {
  yield takeEvery(
    Store.ActivityActions.ArtifactForActivityInstance.TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
    Catch.deferredAction(_ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH, io),
  )

  yield takeEvery(
    Store.ActivityActions.ArtifactForActivityInstance.TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD,
    Catch.deferredAction(_ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD, io),
  )

  yield takeEvery(
    Store.ActivityActions.ArtifactForActivityInstance.TypeKeys.RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
    Catch.deferredAction(_RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DISPLAY, io),
  )

  yield takeEvery(
    Store.ActivityActions.ArtifactForActivityInstance.TypeKeys.RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD,
    Catch.deferredAction(_RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD, io),
  )
}

export function* _ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH(
  io: MiddlewaresIO,
  action: ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
) {
  const { id, options } = action.payload

  const query = qs.stringify({
    limit: options?.limit ?? 1,
    offset: options?.offset ?? 0,
  })

  const config = {
    id,
    query,
  }

  const response = yield* call(io.api.activities.getArtifactsForActivityInstance, config)

  yield* put(
    Store.ActivityActions.ArtifactForActivityInstance.ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE(response, id),
  )

  return response
}

// bulk download -- this one fetches artifacts first and then downloads
export function* _ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD(
  io: MiddlewaresIO,
  action: ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
) {
  const { id } = action.payload
  const _LIMIT = 10
  let allArtifacts = [] as Codecs.ArtifactForActivityInstance[]

  const fetchAction: ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH = {
    type: Store.ActivityActions.ArtifactForActivityInstance.TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
    meta: action.meta,
    payload: {
      id,
      options: {
        limit: _LIMIT,
      },
    },
  }

  // make an initial call to fetch artifacts with a preset limit and assess how many total artifacts there are
  const { data: initialArtifacts, total, offset } = yield* call(_ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH, io, fetchAction)
  allArtifacts = [...initialArtifacts]

  let currentOffset = offset

  // check to see if we still have more artifacts to get after our initial call
  if (total > _LIMIT) {
    let remainingArtifactCount = total - _LIMIT

    // so long as we still have more artifacts, keep fetching incrementally with the preset limit
    while (remainingArtifactCount > 0) {
      currentOffset = currentOffset + _LIMIT

      const subsequentFetchAction: ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH = {
        type: Store.ActivityActions.ArtifactForActivityInstance.TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
        meta: action.meta,
        payload: {
          id,
          options: {
            limit: _LIMIT,
            offset: currentOffset,
          },
        },
      }

      const { data: subsequentArtifacts } = yield* call(
        _ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
        io,
        subsequentFetchAction,
      )

      allArtifacts = [...allArtifacts, ...subsequentArtifacts]
      remainingArtifactCount = total - currentOffset
    }
  }

  // now that we have all our artifacts, map over them and download each one
  yield* allArtifacts.map((artifact) => {
    const { id, filename } = artifact

    const downloadAction: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH = {
      type: Store.ActivityActions.ArtifactForActivityInstance.TypeKeys.RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
      meta: action.meta,
      payload: {
        id: id as UUID,
        filename,
      },
    }

    return call(_RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD, io, downloadAction)
  })
}

export function* _RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH(
  io: MiddlewaresIO,
  action: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
) {
  const { id } = action.payload

  const artifact = yield* call(io.api.artifacts.getArtifactP, id)

  return artifact
}

export function* _GET_RAW_ARTIFACT_FOR_DISPLAY(io: MiddlewaresIO, action: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH) {
  const artifact = yield* call(_RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH, io, action)

  const contentType = artifact.headers.get('content-type')

  if (isNil(contentType)) {
    throw new Error('Response did not contain a content-type.')
  }

  const [mimeType] = contentType.split(';')

  switch (mimeType) {
    case 'application/json': {
      return yield* call([artifact, 'json'])
    }

    case 'text/plain': {
      return yield* call([artifact, 'text'])
    }

    // If we are dealing with an image artifact, just return its url to be used
    // as the img src in the RawArtifact component.
    case 'image/jpg':
    case 'image/png': {
      const imageUrl = artifact.url

      return imageUrl
    }

    default: {
      io.logger(`Got unknown mimetype: ${mimeType}`)

      // application/octet-stream
      // text/html; charset=iso-8859-1
      return yield* call([artifact, 'text'])
    }
  }
}

export function* _GET_RAW_ARTIFACT_FOR_DOWNLOAD(io: MiddlewaresIO, action: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH) {
  const artifact = yield* call(_RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH, io, action)
  const { filename } = action.payload

  const blob = yield* call([artifact, 'blob'])

  const link = document.createElement('a')

  if (link.download !== undefined) {
    const artifactUrl = URL.createObjectURL(blob)

    link.setAttribute('href', artifactUrl)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'

    document.body.prepend(link)

    link.click()

    document.body.removeChild(link)
  }
}

export function* _RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DISPLAY(
  io: MiddlewaresIO,
  action: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
) {
  const artifact = yield* call(_GET_RAW_ARTIFACT_FOR_DISPLAY, io, action)

  return artifact
}

export function* _RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD(
  io: MiddlewaresIO,
  action: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
) {
  yield* call(_GET_RAW_ARTIFACT_FOR_DOWNLOAD, io, action)
}
