/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

// ---------------------------------------------------------------------------
import * as ConnectionReducers from './connection.reducers'
import * as RecipeReducers from './recipe.reducers'

// ---------------------------------------------------------------------------

export type State = ReturnType<typeof Reducer>
export const Reducer = combineReducers({
  connections: ConnectionReducers.ConnectionsReducer,
  recipes: RecipeReducers.RecipesReducer,
})

export const Test = {}
