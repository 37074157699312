/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EntityName, initialState } from './redirector.constants'
import { normalizeRedirectorsForSource } from './redirector.schema'

// ---------------------------------------------------------------------------

export const redirectorForSourceSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    replaceRedirectors: (state, action: PayloadAction<ReturnType<typeof normalizeRedirectorsForSource>>) => {
      state.result = action.payload.result

      state.entities = {
        redirector_for_source: {
          ...state.entities.redirector_for_source,
          ...action.payload.entities.redirector_for_source,
        },
      }
    },

    upsertRedirectors: (state, action: PayloadAction<ReturnType<typeof normalizeRedirectorsForSource>>) => {
      state.entities = {
        redirector_for_source: {
          ...state.entities.redirector_for_source,
          ...action.payload.entities.redirector_for_source,
        },
      }
    },
  },
})
