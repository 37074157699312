/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { PaginatedLink, PaginationMeta } from '../json-api.codecs'

// ---------------------------------------------------------------------------

const _Attributes = {
  entity_type: t.string, // Authoritative
  service_id: t.string, // uuid
  version_match: t.boolean,
}

export const ApplicableEntityAttributes = t.type(_Attributes)

// ----------------------------------------------------------------------------

export const ApplicableEntity = t.type({
  attributes: ApplicableEntityAttributes,
  id: t.string, // uuid
  type: t.literal('applicable_entity'),
})
export type ApplicableEntity = t.TypeOf<typeof ApplicableEntity>

export const ApplicableEntitiesResponse = t.type({
  data: t.array(ApplicableEntity),
  links: PaginatedLink,
  meta: PaginationMeta,
})
export type ApplicableEntitiesResponse = t.TypeOf<typeof ApplicableEntitiesResponse>
