/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { AuthoritativeEntityTypes } from '@/utilities/r-entity/entity-types'

// ---------------------------------------------------------------------------

const EntityTypesProjection = AuthoritativeEntityTypes.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<AuthoritativeEntityTypes, void>)

/**
 * AuthoritativeEntityType differs from EntityType in how the platform uses an
 * "org_" prefix to enumerate entities for authority operations. We need to map
 * "org_" to the typical FE entity types.
 */
export type AuthoritativeEntityType = t.TypeOf<typeof AuthoritativeEntityType>
export const AuthoritativeEntityType = t.keyof(EntityTypesProjection)
