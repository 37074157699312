/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

// @see: https://github.com/gcanti/io-ts/blob/master/index.md#tips-and-tricks
export const TemptationFactorLevel = t.union([
  /* eslint-disable no-magic-numbers */
  t.literal(0),
  t.literal(1),
  t.literal(2),
  t.literal(3),
  t.literal(4),
  t.literal(5),
  /* eslint-enable no-magic-numbers */
])
export type TemptationFactorLevel = t.TypeOf<typeof TemptationFactorLevel>

export const TemptationFactorLevelRange = {
  /* eslint-disable no-magic-numbers */
  high: [4, 5],
  medium: [2, 3],
  low: [0, 1],
  /* eslint-enable no-magic-numbers */
}

export type Temptation = t.TypeOf<typeof Temptation>
export const Temptation = t.type({
  applicability: t.union([TemptationFactorLevel, t.null]),
  criticality: t.union([TemptationFactorLevel, t.null]),
  enumerability: t.union([TemptationFactorLevel, t.null]),
  exploitability: t.union([TemptationFactorLevel, t.null]),
  post_exploit: t.union([TemptationFactorLevel, t.null]),
  private_weakness: t.union([TemptationFactorLevel, t.null]),
  public_weakness: t.union([TemptationFactorLevel, t.null]),
  research: t.union([TemptationFactorLevel, t.null]),
  target_temptation: t.union([t.number, t.null]),
})

// @TODO: Remove this once global-services have exploitability
export type TemptationP = t.TypeOf<typeof TemptationP>
export const TemptationP = t.type({
  applicability: t.union([TemptationFactorLevel, t.null]),
  criticality: t.union([TemptationFactorLevel, t.null]),
  enumerability: t.union([TemptationFactorLevel, t.null]),
  // exploitability: t.union([TemptationFactorLevel, t.null]),
  post_exploit: t.union([TemptationFactorLevel, t.null]),
  private_weakness: t.union([TemptationFactorLevel, t.null]),
  public_weakness: t.union([TemptationFactorLevel, t.null]),
  research: t.union([TemptationFactorLevel, t.null]),
  target_temptation: t.union([t.number, t.null]),
})

export enum TemptationFactor {
  applicability = 'applicability',
  criticality = 'criticality',
  enumerability = 'enumerability',
  exploitability = 'exploitability',
  post_exploit = 'post_exploit',
  private_weakness = 'private_weakness',
  public_weakness = 'public_weakness',
  research = 'research',
}

// ---------------------------------------------------------------------------

export type WithTemptation = t.TypeOf<typeof WithTemptation>
export const WithTemptation = Temptation

export const withTemptation = <T extends t.Mixed>(entity: T) => {
  return t.intersection([entity, Temptation])
}

export type AssertWithTemptation<T> = T extends Temptation ? T : never
