/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'

// ---------------------------------------------------------------------------

export const Empty = () => {
  return <>--</>
}
