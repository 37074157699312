/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { PathEdge, PathNode } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

const PathToNearestPrimeResponse = t.interface({
  data: t.interface({
    nodes: t.record(t.string, PathNode),
    edges: t.record(t.string, PathEdge),
    paths: t.array(t.array(t.string)),
  }),
})

export type PathToNearestPrimeResponse = t.TypeOf<typeof PathToNearestPrimeResponse>

export type PathApi = {
  getPaths: (id: string) => Promise<PathToNearestPrimeResponse>
}

export function pathApiFactory(http: HttpClient): PathApi {
  const baseURL = '/recon/api/v1/paths'

  return {
    getPaths(id) {
      return http
        .get(`${baseURL}?terminal=${id}`)
        .then(CodecUtils.throwValidate(PathToNearestPrimeResponse, 'PathToNearestPrimeResponse'))
    },
  }
}
