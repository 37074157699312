/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'

import * as actions from './redirector.actions'
import { normalizeRedirectorsForSource } from './redirector.schema'
import { redirectorForSourceSlice } from './redirector.slice'

// ---------------------------------------------------------------------------

export function* redirectorForSourceSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchRedirectorsForSource.toString(), deferredAction(_fetchRedirectorsForSource, io))
}

export function* _fetchRedirectorsForSource(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchRedirectorsForSource>,
) {
  const response = yield* call(io.api.attack.getRedirectorsForSource)

  if (action.meta.persist) {
    yield* put(redirectorForSourceSlice.actions.replaceRedirectors(normalizeRedirectorsForSource(response.redirectors)))
  }

  return response
}
