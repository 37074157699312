/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

export function removePatchedBars(removedIDs: string[]) {
  return (id: string) => {
    return !removedIDs.includes(id)
  }
}
