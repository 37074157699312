/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const GlobalPort = t.interface({
  id: t.string,
  affiliation: t.number,
  affiliation_display: t.number,
  affiliation_override: t.boolean,
  confidence: t.number,
  confidence_display: t.number,
  confidence_override: t.boolean,
  country: t.union([t.string, t.null]),
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.union([t.string, t.null]),
  deleted: t.boolean,
  first_time: t.union([t.string, t.null]),
  port: t.string,
  port_str: t.string,
  latitude: t.union([t.string, t.null]),
  lens_view: t.union([t.string, t.null]),
  longitude: t.union([t.string, t.null]),
  // next_refresh: t.union([t.string, t.null]),
  org_id: t.string,
  perspective: t.union([t.string, t.null]),
  radius: t.union([t.number, t.null]),
  sys_period: t.union([t.string, t.null]),
  time: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
})

type _GlobalPort = t.TypeOf<typeof GlobalPort>
export type GlobalPort = _GlobalPort

export const GlobalPortResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(GlobalPort),
})
export type GlobalPortResponse = t.TypeOf<typeof GlobalPortResponse>
