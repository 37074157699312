/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ReconArtifactsReducer = (state = Schemas.Recon.NormalizeReconArtifacts([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ArtifactActions.TypeKeys.RECON_ARTIFACTS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeReconArtifacts(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          'recon-artifact': {
            ...state.entities['recon-artifact'],
            ...entities['recon-artifact'],
          },
        },
      }
    }

    default:
      return state
  }
}

export const ReconArtifactsTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }

export const ReconArtifactsTotalsReducer = (state = ReconArtifactsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ArtifactActions.TypeKeys.RECON_ARTIFACTS_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const ReconArtifactsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ReconArtifactsPaginationReducer = (
  state = ReconArtifactsPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ArtifactActions.TypeKeys.RECON_ARTIFACTS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
