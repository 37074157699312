/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { formatDistance } from 'date-fns'
import * as tz from 'date-fns-tz'

// ---------------------------------------------------------------------------

/**
 * Given start and end time strings, calculates the difference between the two
 * and formats it into a displayable string that represents how much time has passed
 *
 * @param start - a string indicating the start/base time
 * @param end - a string indicating the end/later time
 *
 * @returns a string that represents the time passed between the start and end times
 */
export const getDuration = (start: string, end: string | null) => {
  if (isNotNil(end)) {
    const _start = tz.toZonedTime(new Date(start), 'Etc/GMT')
    const _end = tz.toZonedTime(new Date(end), 'Etc/GMT')

    return formatDistance(_start, _end)
  } else {
    const _start = tz.toZonedTime(new Date(start), 'Etc/GMT')
    const _end = tz.toZonedTime(new Date(), 'Etc/GMT')

    return formatDistance(_start, _end)
  }
}
