/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'
import { NormalizedAttackActions } from '@/store/schemas/attack/attack.schemas'

// ---------------------------------------------------------------------------

export const ActionsReducer = (state = Schemas.AttackSchemas.NormalizeAttackActions([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ATTACK_ACTIONS_STORE_UPDATE: {
      const { entities, result } = Schemas.AttackSchemas.NormalizeAttackActions(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          attackAction: {
            ...state.entities.attackAction,
            ...entities.attackAction,
          },
        },
      }
    }

    case Store.AttackActions.TypeKeys.ATTACK_ACTION_STORE_UPDATE: {
      const previous = state.entities.attackAction[action.payload.id]

      return {
        result: state.result,
        entities: {
          ...state.entities,
          attackAction: {
            ...state.entities.attackAction,
            [action.payload.id]: {
              ...previous,
              ...action.payload,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export const ActionsTotalsInitialState: Codecs.EntityTotals = {
  unfilteredTotal: 0,
  unaffiliatedTotal: 0,
}

export const ActionsTotalsReducer = (state = ActionsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ATTACK_ACTION_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const ActionsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ActionsPaginationReducer = (state = ActionsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ATTACK_ACTIONS_STORE_UPDATE: {
      // eslint-disable-next-line
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}

// ---------------------------------------------------------------------------

type ActionsByRunbookReducer = {
  result: { [index: string]: string[] | undefined }
  entities: NormalizedAttackActions['entities']
}

const ActionsByRunbookReducerInit: ActionsByRunbookReducer = {
  result: {},
  entities: { attackAction: {} },
}

export const ActionsByRunbookReducer = (state = ActionsByRunbookReducerInit, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ACTIONS_BY_RUNBOOK_STORE_UPDATE: {
      const { entities, result } = Schemas.Attack.NormalizeAttackActions(action.payload.response.data)

      return {
        result: {
          ...state.result,
          [action.payload.runbookId]: result,
        },
        entities: {
          ...state.entities,
          attackAction: {
            ...state.entities.attackAction,
            ...entities.attackAction,
          },
        },
      }
    }

    case Store.AttackActions.TypeKeys.ACTION_BY_RUNBOOK_STORE_UPDATE: {
      const previous = state.entities.attackAction[action.payload.id]

      return {
        result: state.result,
        entities: {
          ...state.entities,
          attackAction: {
            ...state.entities.attackAction,
            [action.payload.id]: {
              ...previous,
              ...action.payload,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

type ActionsByRunbookPaginationState = { [index: string]: Codecs.EntityPagination | undefined }
const ActionsByRunbookPaginationInit: ActionsByRunbookPaginationState = {}

export const ActionsByRunbookPaginationReducer = (
  state = ActionsByRunbookPaginationInit,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.ACTIONS_BY_RUNBOOK_STORE_UPDATE: {
      const {
        response: { data, ...pagination },
      } = action.payload

      return {
        ...state,
        [action.payload.runbookId]: {
          ...pagination,
        },
      }
    }

    default:
      return state
  }
}
