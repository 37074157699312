/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable @typescript-eslint/no-use-before-define */
import * as Catch from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _Actions from '@/store/actions/entity/entity.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export function* watchEntity(io: MiddlewaresIO) {
  yield takeEvery(Store.EntityActions.TypeKeys.ENTITY_GET, Catch.deferredAction(_ENTITY_GET, io))
}

// ---------------------------------------------------------------------------

export function* _ENTITY_GET(io: MiddlewaresIO, action: _Actions.ENTITY_GET) {
  const { entityType, search } = action.payload

  const endpoint = EntityUtils.getEndpointByType(entityType)

  const response = yield* call(io.http.get, `${endpoint}?${search}`)

  return response
}
