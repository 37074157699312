/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Asset, EntityPagination } from '@/codecs'

// ---------------------------------------------------------------------------

export type AssetState = {
  pagination: EntityPagination
  result: string[]
  totals: { unfiltered: number; unaffiliated: number }

  entities: {
    asset: Record<string, Asset>
  }
}

export type EntityName = 'asset'
export const EntityName: EntityName = 'asset'

export const initialState: AssetState = {
  pagination: { count: 0, offset: 0, total: 0 },
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },

  entities: {
    asset: {},
  },
}
