/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { TargetP as Target } from '@/codecs'
import { DoubleUUID } from '@/utilities/codec'

import { EntityName } from './target.constants'

// ---------------------------------------------------------------------------

export const normalizeTargets = (records: Target[]) => {
  const Single = new schema.Entity(EntityName)
  const Plural = [Single]

  type NormalizedPlural = {
    result: DoubleUUID[]
    entities: { target: Record<DoubleUUID, Target> }
  }

  const entities: NormalizedPlural = normalize(records, Plural)

  return entities
}
