/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const Poc = t.interface({
  address: t.union([t.string, t.null]),
  affiliation: t.union([t.number, t.null]),
  affiliation_display: t.union([t.number, t.null]),
  affiliation_override: t.boolean,
  city: t.union([t.string, t.null]),
  company_name: t.union([t.string, t.null]),
  confidence: t.number,
  confidence_display: t.union([t.number, t.null]),
  confidence_override: t.boolean,
  country: t.union([t.string, t.null]),
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.union([t.string, t.null]),
  data_source: t.union([t.string, t.null]),
  deleted: t.boolean,
  details: t.union([t.interface({}), t.null]),
  domain: t.union([t.string, t.null]),
  email: t.string,
  email_type: t.union([t.string, t.null]),
  first_time: t.union([t.string, t.null]),
  id: t.string,
  lens_view: t.union([t.string, t.null]),
  // next_refresh: t.union([t.string, t.null]),
  org_id: t.string,
  person_first_name: t.union([t.string, t.null]),
  person_last_name: t.union([t.string, t.null]),
  person_middle_name: t.union([t.string, t.null]),
  person_name: t.union([t.string, t.null]),
  perspective: t.string,
  phone: t.union([t.string, t.null]),
  postal_code: t.union([t.string, t.null]),
  role: t.union([t.string, t.null]),
  seniority: t.union([t.string, t.null]),
  state: t.union([t.string, t.null]),
  sub_role: t.union([t.string, t.null]),
  sys_period: t.union([t.string, t.null]),
  time: t.union([t.string, t.null]),
  title: t.union([t.string, t.null]),
  tld: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
  username: t.union([t.string, t.null]),
})

type _Poc = t.TypeOf<typeof Poc>
export type Poc = _Poc

export const PocResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Poc),
})
export type PocResponse = t.TypeOf<typeof PocResponse>

export const PocPostPayloadOptional = t.partial({
  parent_info: t.UnknownRecord, // @TODO
})

export const PocPostPayloadRequired = t.interface({
  data: t.array(
    t.interface({
      email: t.string,
      org_id: t.string,
      perspective: t.union([t.string, t.undefined]),
    }),
  ),
})

export const PocPostPayload = t.intersection([PocPostPayloadRequired, PocPostPayloadOptional])

type _PocPostPayload = t.TypeOf<typeof PocPostPayload>
export type PocPostPayload = _PocPostPayload

export const PocPostPayloadResponse = t.interface({
  ids: t.array(t.string),
})
export type PocPostPayloadResponse = t.TypeOf<typeof PocPostPayloadResponse>
