/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const CharacteristicRulesReducer = (
  state = Schemas.HocSchemas.NormalizeCharacteristicRules([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.CHARACTERISTIC_RULES_STORE_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizeCharacteristicRules(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          characteristicRule: {
            ...state.entities.characteristicRule,
            ...entities.characteristicRule,
          },
        },
      }
    }

    case Store.HocActions.TypeKeys.CHARACTERISTIC_RULE_STORE_UPDATE: {
      const prev = state.entities.characteristicRule[action.payload.data[0].id]
      const next = { ...prev, ...action.payload.data[0] }

      return {
        result: state.result,
        entities: {
          ...state.entities,
          characteristicRule: {
            ...state.entities.characteristicRule,
            ...{ [action.payload.data[0].id]: next },
          },
        },
      }
    }

    default:
      return state
  }
}

export const CharacteristicRulesTotalsInitialState: Codecs.HocEntityTotals = {
  total: 0,
  unfilteredTotal: 0,
}

export const CharacteristicRulesTotalsReducer = (
  state = CharacteristicRulesTotalsInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const CharacteristicRulesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const CharacteristicRulesPaginationReducer = (
  state = CharacteristicRulesPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.CHARACTERISTIC_RULES_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
