/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { EntityType as _EntityType, EntityTypes as _EntityTypes } from '@/utilities/r-entity/entity-types'

// ---------------------------------------------------------------------------

const EntityTypesProjection = _EntityTypes.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<_EntityType, void>)

export const EntityType = t.keyof(EntityTypesProjection)
export type EntityType = t.TypeOf<typeof EntityType>
