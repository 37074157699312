/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { TargetP as Target } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { DoubleUUID, QueryString, rflaskGetMany, rflaskGetSingle, throwValidate } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type TargetApi = {
  getTarget: (id: DoubleUUID) => Promise<GetTarget>
  getTargets: (query: QueryString | '') => Promise<GetTargets>
  getTargetsForDetection: (query: QueryString | '') => Promise<GetTargets>
  getTargetsForHostname: (query: QueryString | '') => Promise<GetTargets>
  getTargetsForNetwork: (query: QueryString | '') => Promise<GetTargets>
  getTargetsForService: (query: QueryString | '') => Promise<GetTargets>
}

export function targetApiFactory(http: HttpClient): TargetApi {
  const baseUrl = '/recon/api/v2/single-detection-for-target'
  // const _targetUrl = '/recon/api/v2/target'

  return {
    getTarget(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetTarget, 'GetTarget'))
    },

    getTargets(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetTargets, 'GetTargets'))
    },

    /**
     * @deprecated: Use getTargets()
     */
    getTargetsForDetection(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetTargets, 'GetTargets'))
    },

    /**
     * @deprecated: Use getTargets()
     */
    getTargetsForHostname(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetTargets, 'GetTargets'))
    },

    /**
     * @deprecated: Use getTargets()
     */
    getTargetsForNetwork(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetTargets, 'GetTargets'))
    },

    /**
     * @deprecated: Use getTargets()
     */
    getTargetsForService(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetTargets, 'GetTargets'))
    },
  }
}

export type GetTargets = t.TypeOf<typeof GetTargets>
export const GetTargets = rflaskGetMany(Target)

export type GetTarget = t.TypeOf<typeof GetTarget>
export const GetTarget = rflaskGetSingle(Target)
