/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Context } from 'io-ts'
import { isEmpty } from 'lodash/fp'

// ---------------------------------------------------------------------------

export function getFilteredPath(context: Context) {
  return context
    .filter(({ key }) => !isEmpty(key) && isNaN(parseInt(key, 10)))
    .map(({ key }) => key)
    .join('.')
}
