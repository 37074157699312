/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ActivityInstanceReducer = (
  state = Schemas.Activity.NormalizeActivityInstances([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ActivityInstance.TypeKeys.ACTIVITIES_STORE_UPDATE: {
      const { entities, result } = Schemas.Activity.NormalizeActivityInstances(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          activity_instance: {
            ...state.entities.activity_instance,
            ...entities.activity_instance,
          },
        },
      }
    }

    case Store.ActivityActions.ActivityInstance.TypeKeys.ACTIVITY_STORE_UPDATE: {
      return {
        result: state.result,
        entities: {
          ...state.entities,
          activity_instance: {
            ...state.entities.activity_instance,
            [action.payload.id]: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}

const ActivityInstancesTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const ActivityInstancesTotalsReducer = (
  state = ActivityInstancesTotalsInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ActivityInstance.TypeKeys.ACTIVITIES_TOTAL_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export const ActivitiesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ActivitiesPaginationReducer = (state = ActivitiesPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ActivityActions.ActivityInstance.TypeKeys.ACTIVITIES_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
