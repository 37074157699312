/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { Hostname } from './hostname.codecs'
import { Ip } from './ip.codecs'
import { Network } from './network.codecs'
import { Poc } from './poc.codecs'
import { Term } from './term.codecs'

// ---------------------------------------------------------------------------

export const PrimesResponse = t.interface({
  hostnames: t.array(t.partial(CodecUtils.propsFromIntersection(Hostname))),
  ips: t.array(t.partial(CodecUtils.propsFromIntersection(Ip))),
  networks: t.array(t.partial(CodecUtils.propsFromIntersection(Network))),
  pocs: t.array(t.partial(Poc.props)),
  terms: t.array(t.partial(Term.props)),
})

type _PrimesResponse = t.TypeOf<typeof PrimesResponse>
export type PrimesResponse = _PrimesResponse
