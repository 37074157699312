/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { PerspectiveMetadata } from './perspective.codecs'
import { Trigger } from './trigger.codecs'

// ---------------------------------------------------------------------------

export const RunbookStatus = t.keyof({
  '10_SUCCESS': null,
  '20_IN_PROGRESS': null,
  '30_FAILED': null,
})
export type RunbookStatus = t.TypeOf<typeof RunbookStatus>

export const Runbook = t.interface({
  description: t.union([t.string, t.null]),
  dst_email: CodecUtils.nullable(t.array(t.string)),
  dst_host: CodecUtils.nullable(t.array(t.string)),
  dst_ip: CodecUtils.nullable(t.array(t.string)),
  dst_mac: CodecUtils.nullable(t.array(t.string)),
  dst_misc: CodecUtils.nullable(t.array(t.string)),
  dst_network: CodecUtils.nullable(t.array(t.string)),
  dst_path: CodecUtils.nullable(t.array(t.string)),
  dst_port: CodecUtils.nullable(t.array(t.number)),
  end_time: t.union([t.string, t.null]),
  guidance: t.union([t.string, t.null]),
  deleted: CodecUtils.nullable(t.boolean),
  id: t.string,
  implant_nick: t.union([t.string, t.null]),
  implant_src_host: t.union([t.array(t.string), t.null]),
  implant_src_ip: t.union([t.array(t.string), t.null]),
  instance_label: CodecUtils.nullable(t.string),
  name: t.string,
  objective: t.union([t.string, t.null]),
  org_id: t.string,
  perspective_metadata: t.array(PerspectiveMetadata),
  randori_notes: CodecUtils.nullable(t.string),
  results: t.union([t.string, t.null]),
  src_email: t.array(t.string),
  src_host: t.array(t.string),
  src_ip: t.array(t.string),
  src_mac: t.array(t.string),
  src_misc: t.array(t.string),
  start_time: t.string,
  status: RunbookStatus, // @TODO Enum for Runbook status
  technique_ids: t.array(t.string),
  trigger: CodecUtils.nullable(t.array(Trigger)),
  uid: t.string,
})
export type Runbook = t.TypeOf<typeof Runbook>

export const ApRunbook = t.interface({
  deleted: t.boolean,
  id: t.string,
  instance_label: CodecUtils.nullable(t.string),
  randori_notes: CodecUtils.nullable(t.string),
})
export type ApRunbook = t.TypeOf<typeof ApRunbook>

export const RunbooksResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Runbook),
})
export type RunbooksResponse = t.TypeOf<typeof RunbooksResponse>

export const RunbookDescription = t.interface({
  id: t.string,
  description: t.union([t.string, t.null]),
  guidance: t.union([t.string, t.null]),
  name: t.union([t.string, t.null]),
  objective: t.union([t.string, t.null]),
  runbook_id: t.string,
})
export type RunbookDescription = t.TypeOf<typeof RunbookDescription>

export const RunbookDescriptionPayload = t.interface({
  data: t.interface({
    description: t.union([t.string, t.null]),
    guidance: t.union([t.string, t.null]),
    name: t.union([t.string, t.null]),
    objective: t.union([t.string, t.null]),
    runbook_id: t.string,
  }),
})
export type RunbookDescriptionPayload = t.TypeOf<typeof RunbookDescriptionPayload>

export const RunbookDescriptionResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(RunbookDescription),
})
export type RunbookDescriptionResponse = t.TypeOf<typeof RunbookDescriptionResponse>

export const ApRunbookPatchResponse = t.interface({
  data: ApRunbook,
})
export type ApRunbookPatchResponse = t.TypeOf<typeof ApRunbookPatchResponse>

export const ApRunbookPatchPayload = t.interface({
  data: t.partial({
    deleted: t.boolean,
    instance_label: t.string,
    randori_notes: t.string,
  }),
})
export type ApRunbookPatchPayload = t.TypeOf<typeof ApRunbookPatchPayload>
