/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

type Deferred = Catch.DeferredAction['meta']['deferred']

// ---------------------------------------------------------------------------

export enum TypeKeys {
  PATH_TO_NEAREST_PRIME_FETCH = 'PATH_TO_NEAREST_PRIME_FETCH',
}

export type ActionTypes = FetchPathToNearestPrime

export type FetchPathToNearestPrime = {
  type: TypeKeys.PATH_TO_NEAREST_PRIME_FETCH
  meta: { deferred: Deferred }
  payload: { id: string }
}

export const PATH_TO_NEAREST_PRIME_FETCH = (
  payload: FetchPathToNearestPrime['payload'],
  deferred: Deferred,
): FetchPathToNearestPrime => ({
  type: TypeKeys.PATH_TO_NEAREST_PRIME_FETCH,
  meta: { deferred },
  payload,
})
