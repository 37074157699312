/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from '@/store'
import { UUID } from '@/utilities/codec'

import { makeSelectPagination, makeSelectTotals } from '../utilities/make-selectors'
import { EntityName } from './affiliated-network.constants'

// ---------------------------------------------------------------------------

export const selectAffiliatedNetworkPagination = makeSelectPagination(EntityName)
export const selectAffiliatedNetworkTotals = makeSelectTotals(EntityName)

export const selectAffiliatedNetworks = createSelector([(state: AppState) => state.affiliated_network], (state) => {
  return state.result.map((uuid) => state.entities[EntityName][uuid])
})

export const selectAffiliatedNetworkById = createSelector(
  [
    (state: AppState, _props: { id: UUID }) => state.affiliated_network.entities[EntityName],
    (_state: AppState, props: { id: UUID }) => props.id,
  ],
  (affiliatedNetworks, id) => {
    return affiliatedNetworks[id]
  },
)
