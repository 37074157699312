/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { CrudRuleGroup, isRuleGroup, RuleOrRuleGroup } from '@randori/rootkit'
import debug from 'debug'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as J from 'fp-ts/Json'

import * as SafeEncode from '@/utilities/b64'
import { formatValidationErrors } from '@/utilities/codec'
import { isNotNil } from '@/utilities/is-not'
import * as Logger from '@/utilities/logger'
import * as QueryFilterUtils from '@/utilities/query-filter-utils'
import { RandoriValidationError } from '@/utilities/r-error'

// ---------------------------------------------------------------------------

type MaybeCrudRuleGroup = Record<string, unknown>

const log = debug('RANDORI:serialize')

/**
 * @param crudRuleGroup - haystack
 * @param uiId - needle
 *
 * @returns A rule group identified by uiId, or null if one can't be located
 */
export const getRuleGroup = (crudRuleGroup: CrudRuleGroup, uiId: string): CrudRuleGroup | null => {
  if (crudRuleGroup.ui_id === uiId) {
    return crudRuleGroup
  }

  for (const rule of crudRuleGroup.rules) {
    if (isRuleGroup(rule) && rule.ui_id === uiId) {
      return rule
    }
  }

  return null
}

/**
 * @param q - A possibly valid query-builder RuleGroup
 *
 * @returns A base64 encoded query-builder object
 */
export function serializeQ(q: MaybeCrudRuleGroup) {
  const unserializedQ = pipe(
    RuleOrRuleGroup.decode(q),
    E.fold(
      (errors) => {
        log('serializeQ q:', {
          q,
          errors: formatValidationErrors(errors),
          raw: errors,
        })

        const validationError = new RandoriValidationError({
          errors,
          label: 'serializeQ',
        })

        Logger.error(validationError)

        return q as RuleOrRuleGroup
      },
      (valid) => valid,
    ),
  )

  // Occasionally we are serializing the `unset` rule, which causes the
  // platform to explode. I have not run down why this is happening yet, but
  // this might be a decent safety net.
  let next = unserializedQ

  if (isRuleGroup(next)) {
    // Prune all empty
    next = QueryFilterUtils.pruneEmptyGroups(next)
    if (isNotNil(next.rules) && Array.isArray(next.rules)) {
      next = {
        ...next,
        rules: next.rules.filter((rule) => rule.ui_id !== '--'),
      }
    }
  }

  return E.getOrElse(() => '')(SafeEncode.safeBtoa(JSON.stringify(next)))
}

/**
 * @param serializedQ - base64 encoded query-builder object
 *
 * @returns A query-builder RuleGroup
 */
export function unserializeQ(serializedQ: string) {
  const emptyRule = {
    condition: 'AND' as const,
    rules: [],
  } as CrudRuleGroup

  let _rule

  if (serializedQ == '') {
    _rule = emptyRule
  } else {
    const maybeString = pipe(serializedQ, SafeEncode.safeAtob)

    const maybeJson = pipe(maybeString, E.map(J.parse), E.flatten)

    const maybeRuleGroup = pipe(
      maybeJson,
      E.map((el) => CrudRuleGroup.decode(el)),
    )

    _rule = pipe(
      maybeRuleGroup,
      E.fold(
        (el) => {
          log('unserializeQ json:', el)

          const jsonError = new Error(el as string)

          Logger.error(jsonError)
          return emptyRule
        },
        (_maybeRuleGroup) => {
          return pipe(
            _maybeRuleGroup,
            E.fold(
              (errors) => {
                log('unserializeQ q:', {
                  q: serializedQ,
                  errors: formatValidationErrors(errors),
                  raw: errors,
                })

                const validationError = new RandoriValidationError({
                  errors,
                  label: 'unserializeQ',
                })

                Logger.error(validationError)
                return emptyRule
              },
              (valid) => valid,
            ),
          )
        },
      ),
    )
  }

  // Further massages groups to the latest application standard
  const root = QueryFilterUtils.getFilterHierarchy(_rule)

  return root
}
