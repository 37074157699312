/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export const ArtifactForActivityInstance = new schema.Entity('artifactForActivityInstance')
export const ArtifactsForActivityInstance = [ArtifactForActivityInstance]

/**
 * @param records - array of objects to parse into schema
 *
 * @returns a normalized schema for Artifacts for Activity Instances
 */
export function NormalizeArtifactsForActivityInstance(records: Codecs.ArtifactForActivityInstance[]) {
  type Artifacts = {
    artifactForActivityInstance: Record<string, Codecs.ArtifactForActivityInstance> | undefined
  }

  const { result, entities } = normalize<typeof ArtifactsForActivityInstance, Artifacts, string[]>(
    records,
    ArtifactsForActivityInstance,
  )

  const _forActivityInstance = entities.artifactForActivityInstance ?? {}

  return {
    result,
    entities: {
      artifactForActivityInstance: _forActivityInstance,
    },
  }
}
