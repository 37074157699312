/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/**
 * @param records -
 * @returns a single string
 */
export function convertToCSV(records: string[][]) {
  // @TODO: Replace for-in and for loops
  let str = ''
  for (let i = 0; i < records.length; i++) {
    let line = ''

    // eslint-disable-next-line @typescript-eslint/no-for-in-array
    for (const index in records[i]) {
      if (line != '') {
        line += ','
      }

      line += records[i][index]
    }

    str += line + '\r\n'
  }

  return str
}
