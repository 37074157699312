/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as _ActivityConfiguration from './activity-configuration.actions'
import * as _ActivityInstance from './activity-instance.actions'
import * as _ApplicableActivity from './applicable-activity.actions'
import * as _ApplicableEntity from './applicable-entity.actions'
import * as _ArtifactForActivityInstance from './artifact-for-activity-instance.actions'
import * as _EntityForActivityInstance from './entity-for-activity-instance.actions'

export const ActivityConfiguration = _ActivityConfiguration
export const ActivityInstance = _ActivityInstance
export const ApplicableActivity = _ApplicableActivity
export const ApplicableEntity = _ApplicableEntity
export const ArtifactForActivityInstance = _ArtifactForActivityInstance
export const EntityForActivityInstance = _EntityForActivityInstance

export type StateActionTypes =
  | _ActivityConfiguration.StateActionTypes
  | _ActivityInstance.StateActionTypes
  | _ApplicableActivity.StateActionTypes
  | _ApplicableEntity.StateActionTypes
  | _ArtifactForActivityInstance.StateActionTypes
  | _EntityForActivityInstance.StateActionTypes
