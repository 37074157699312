/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { PostPeriodicPayload, PostPeriodicResponse } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type SchedulerApi = {
  postPeriodic: (body: PostPeriodicPayload) => Promise<PostPeriodicResponse>
}

export function schedulerApiFactory(http: HttpClient): SchedulerApi {
  const baseURL = '/scheduler/api/v1'
  const baseURLHOC = '/scheduler/api/v1/hoc'

  return {
    postPeriodic(body) {
      return http
        .post(`${baseURLHOC}/periodic`, body)
        .then(CodecUtils.throwValidate(PostPeriodicResponse, 'PostPeriodicResponse'))
    },
  }
}
