/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/**
 * This is the complete list of artifact type renderers in the `web-client`.
 */
export const ArtifactRendererTypes = [
  'ARTIFACT_RENDERER_UNSPECIFIED',
  'ARTIFACT_RENDERER_TEXT',
  'ARTIFACT_RENDERER_JSON',
  'ARTIFACT_RENDERER_YAML',
  'ARTIFACT_RENDERER_HTML',
  'ARTIFACT_RENDERER_IMG',
  'ARTIFACT_RENDERER_X509',
] as const
export type ArtifactRendererType = (typeof ArtifactRendererTypes)[number]
