/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as FieldTypeCodecs from './field-type.codecs'
import { ValidationObject } from './validation.codecs'

// ---------------------------------------------------------------------------

export const ParameterTypes = ['configuration', 'execution'] as const
export type _ParameterType = (typeof ParameterTypes)[number]

const ParameterTypesProjection = ParameterTypes.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<_ParameterType, void>)

export const ParameterType = t.keyof(ParameterTypesProjection)
export type ParameterType = t.TypeOf<typeof ParameterType>

export const Parameter = t.type({
  type: t.literal('parameter'),
  attributes: t.intersection([
    t.type({
      description: t.string,
      field_label: t.string,
      is_configurable: t.boolean,
      is_optional: t.boolean,
      is_not_set: t.boolean,
      parameter_type: ParameterType, //formerly 'kind'
      parameter: t.string,
      validation: ValidationObject,
    }),
    FieldTypeCodecs.FieldTypeValuePairs,
  ]),
})
export type Parameter = t.TypeOf<typeof Parameter>
