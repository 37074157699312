/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { createSelector } from 'reselect'

import * as Codecs from '@/codecs'
import * as Store from '@/store'

// activity configuration
const _getActivityConfigurationsPagination = (state: Store.AppState) => state.activity.activityConfigurationPagination
const _getAllActivityConfigurations = (state: Store.AppState) => state.activity.activityConfiguration
const _getActivityConfigurationById = (state: Store.AppState, props: { id: string }) => {
  const activityConfiguration = state.activity.activityConfiguration.entities.activity_configuration[props.id]

  return activityConfiguration
}

// activity instance
const _getActivitiesPagination = (state: Store.AppState) => state.activity.activityInstancePagination
const _getAllActivityInstances = (state: Store.AppState) => state.activity.activityInstance
const _getEntitiesForActivityInstance = (state: Store.AppState) => state.activity.entityForActivityInstance
const _getArtifactsForActivityInstance = (state: Store.AppState) => state.activity.artifactForActivityInstance

const _getActivityInstanceById = (state: Store.AppState, props: { id: string }) => {
  const activityInstance = state.activity.activityInstance.entities.activity_instance[props.id]

  return activityInstance
}

const _getEntitiesForActivityInstanceById = (state: Store.AppState, props: { id: string }) => {
  return state.activity.entityForActivityInstance.byId[props.id]
}

const _getPaginationForEntitiesForActivityInstanceById = (state: Store.AppState, props: { id: string }) => {
  return state.activity.entityForActivityInstancePagination[props.id]
}

const _getArtifactsForActivityInstanceById = (state: Store.AppState, props: { id: string }) => {
  return state.activity.artifactForActivityInstance.byId[props.id]
}

const _getPaginationForArtifactsForActivityInstanceById = (state: Store.AppState, props: { id: string }) => {
  return state.activity.artifactForActivityInstancePagination[props.id]
}

// applicable activity
const _getApplicableActivitiesPagination = (state: Store.AppState) => state.activity.applicableActivityPagination
const _getAllApplicableActivities = (state: Store.AppState) => state.activity.applicableActivity

// applicable entity
const _getAllApplicableEntities = (state: Store.AppState) => state.activity.applicableEntity

/**
 * Flattens an activity configuration object that initially has properties nested inside attributes
 *
 * @param activityConfiguration - activity configuration object
 *
 * @returns a normalized object with only one level of depth  (i.e. all properties in attributes have been flattened)
 */
export const normalizeActivityConfiguration = (activityConfiguration: Codecs.ActivityConfiguration) => {
  const { id, type, attributes } = activityConfiguration
  const { created, updated, mitre, objective } = attributes
  const { date: createdOn, author_id: createdBy } = created
  const { date: updatedOn, author_id: updatedBy } = updated
  const { tactics, techniques, mitigations } = mitre
  const { attackers_perspective, description, implication } = objective

  const normalizedActivityConfiguration = {
    id,
    type,
    ...attributes,
    // these keys match the column keys from view schema
    activity_description: description,
    attackers_perspective,
    created_on: createdOn,
    created_by: createdBy,
    implications: implication,
    updated_on: updatedOn,
    updated_by: updatedBy,
    mitre_tactics: tactics,
    mitre_techniques: techniques,
    mitre_mitigations: mitigations,
  }

  return normalizedActivityConfiguration
}

// activity configuration
export const selectActivityConfigurations = createSelector(
  [_getAllActivityConfigurations],
  (_activityConfigurations) => {
    // array of activity configuration ids
    const activityConfigurationIds = _activityConfigurations.result

    // map each id to its corresponding data object with properties extracted from attributes
    const idsMappedToNormalizedObjects = activityConfigurationIds.map((id: string) => {
      const activityConfiguration = _activityConfigurations.entities.activity_configuration[id]

      return normalizeActivityConfiguration(activityConfiguration)
    })

    return idsMappedToNormalizedObjects.filter(Boolean)
  },
)

export const selectActivityConfigurationPagination = createSelector(
  [_getActivityConfigurationsPagination],
  (pagination) => pagination,
)

export const selectActivityConfigurationById = createSelector(
  [_getActivityConfigurationById],
  (activityConfiguration) => {
    if (isNotNil(activityConfiguration)) {
      return normalizeActivityConfiguration(activityConfiguration)
    }
  },
)

// activity instance
export const selectActivityInstances = createSelector([_getAllActivityInstances], (_activityInstances) => {
  return _activityInstances.result
    .map((id: string) => _activityInstances.entities.activity_instance[id])
    .filter(Boolean)
})

export const selectActivityPagination = createSelector([_getActivitiesPagination], (pagination) => pagination)

export const selectActivityInstanceById = createSelector(
  [_getActivityInstanceById],
  (activityInstance) => activityInstance,
)

export const selectEntitiesForActivityInstanceById = createSelector(
  [_getEntitiesForActivityInstanceById, _getEntitiesForActivityInstance],
  (result, entitiesForActivityInstance) => {
    const entities = entitiesForActivityInstance.entities.entityForActivityInstance

    return result.map((_id) => entities[_id])
  },
)

export const selectPaginationForEntitiesForActivityInstanceById = createSelector(
  [_getPaginationForEntitiesForActivityInstanceById],
  (pagination) => pagination,
)

export const selectArtifactsForActivityInstanceById = createSelector(
  [_getArtifactsForActivityInstanceById, _getArtifactsForActivityInstance],
  (result, artifactsForActivityInstance) => {
    const artifacts = artifactsForActivityInstance.entities.artifactForActivityInstance

    return result.map((_id) => artifacts[_id])
  },
)

export const selectPaginationForArtifactsForActivityInstanceById = createSelector(
  [_getPaginationForArtifactsForActivityInstanceById],
  (pagination) => pagination,
)

/**
 * Flattens an applicable activity object that initially has properties nested inside attributes
 *
 * @param applicableActivity - applicable activity object
 *
 * @returns a normalized object with only one level of depth  (i.e. all properties in attributes have been flattened)
 */
export const normalizeApplicableActivity = (applicableActivity: Codecs.ApplicableActivity) => {
  const { id, type, attributes } = applicableActivity

  const normalizedApplicableActivity = {
    id,
    type, // 'applicable_activity'
    ...attributes,
  }

  return normalizedApplicableActivity
}

// applicable activity
export const selectApplicableActivities = createSelector([_getAllApplicableActivities], (_applicableActivities) => {
  // array of applicable activity ids
  const applicableActivityIds = _applicableActivities.result

  // map each id to its corresponding data object with properties extracted from attributes
  const idsMappedToNormalizedObjects = applicableActivityIds.map((id: string) => {
    const applicableActivity = _applicableActivities.entities.applicable_activity[id]

    return normalizeApplicableActivity(applicableActivity)
  })

  return idsMappedToNormalizedObjects.filter(Boolean)
})

export const selectApplicableActivityPagination = createSelector(
  [_getApplicableActivitiesPagination],
  (pagination) => pagination,
)

/**
 * Flattens an applicable entity object that initially has properties nested inside attributes
 *
 * @param applicableEntity - applicable entity object
 *
 * @returns a normalized object with only one level of depth (i.e. all properties in attributes have been flattened)
 */
export const normalizeApplicableEntity = (applicableEntity: Codecs.ApplicableEntity) => {
  const { id, type, attributes } = applicableEntity

  const normalizedApplicableEntity = {
    id,
    type,
    ...attributes,
  }

  return normalizedApplicableEntity
}

export const selectApplicableEntities = createSelector([_getAllApplicableEntities], (_applicableEntities) => {
  const applicableEntityIds = _applicableEntities.result

  // map each id to its corresponding data object with properties extracted from attributes
  const idsMappedToNormalizedObjects = applicableEntityIds.map((id: string) => {
    const applicableEntity = _applicableEntities.entities.applicable_entity[id]

    return normalizeApplicableEntity(applicableEntity)
  })

  return idsMappedToNormalizedObjects.filter(Boolean)
})
