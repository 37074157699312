/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import classNames from 'classnames'
import * as React from 'react'

type ExternalLinkProps = {
  addlClasses?: string | string[]
  href: string
  noIcon?: boolean
  passedRef?: React.RefObject<HTMLAnchorElement>
}

export const ExternalLink: React.FunctionComponent<ExternalLinkProps> = (props) => {
  const noIcon = props.noIcon ?? false

  const classes = classNames('external-link', { 'no-icon': noIcon }, props.addlClasses)

  return (
    <a className={classes} href={props.href} ref={props.passedRef} rel="noopener noreferrer" target="_blank">
      {props.children}
    </a>
  )
}
