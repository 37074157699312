/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const DetectionCriteriaIP = t.type({
  version: t.number,
  address: t.string,
})
export type DetectionCriteriaIP = t.TypeOf<typeof DetectionCriteriaIP>

export const DetectionCriteriaTCP = t.type({
  port: t.number,
})
export type DetectionCriteriaTCP = t.TypeOf<typeof DetectionCriteriaTCP>

export const DetectionCriteriaUDP = t.type({
  port: t.number,
})
export type DetectionCriteriaUDP = t.TypeOf<typeof DetectionCriteriaUDP>

export const MaybeDetectionCriteriaTLS = t.partial({
  alpn: t.string,
  sni: t.string,
  client_cert: t.string,
})

export const DetectionCriteriaTLS = t.intersection([
  t.type({
    version: t.number,
  }),
  MaybeDetectionCriteriaTLS,
])
export type DetectionCriteriaTLS = t.TypeOf<typeof DetectionCriteriaTLS>

export const MaybeDetectionCriteriaHTTP = t.partial({
  host: t.string,
})

export const DetectionCriteriaHTTP = t.intersection([
  t.type({
    version: t.number,
    method: t.string,
    path: t.string,
  }),
  MaybeDetectionCriteriaHTTP,
])
export type DetectionCriteriaHTTP = t.TypeOf<typeof DetectionCriteriaHTTP>

export const MaybeDetectionCriteriaDNS = t.partial({
  rdata: t.string,
})

export const DetectionCriteriaDNS = t.intersection([
  t.type({
    type: t.string,
  }),
  MaybeDetectionCriteriaDNS,
])
export type DetectionCriteriaDNS = t.TypeOf<typeof DetectionCriteriaDNS>

export const DetectionCriteriaSearch = t.partial({
  hostname: t.string,
  ip: t.string,
  term: t.string,
  email: t.string,
})
export type DetectionCriteriaSearch = t.TypeOf<typeof DetectionCriteriaSearch>

export const DetectionCriteria = t.partial({
  ip: DetectionCriteriaIP,
  tcp: DetectionCriteriaTCP,
  udp: DetectionCriteriaUDP,
  tls: DetectionCriteriaTLS,
  http: DetectionCriteriaHTTP,
  dns: DetectionCriteriaDNS,
  search: DetectionCriteriaSearch,
})
export type DetectionCriteria = t.TypeOf<typeof DetectionCriteria>
