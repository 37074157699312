/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Reducer, StoreCreator, StoreEnhancer } from 'redux'

import { AppState } from './store.constants'

// ---------------------------------------------------------------------------

export function resetStore(next: StoreCreator) {
  return (
    reducer: Reducer<AppState>,
    // @see: Note below about `preloadedState`
    preloadedState: Record<string, unknown>,
    enhancer?: StoreEnhancer,
  ) => {
    // @see: https://github.com/wwayne/redux-reset/blob/master/src/index.js
    //
    // Largely lifted from redux-reset, wanted TS types
    const enhanceReducer: Reducer<AppState> = (state, action) => {
      if (action.type === 'RESET') {
        state = {} as AppState
      }

      return reducer(state, action)
    }

    return next(enhanceReducer, preloadedState, enhancer)
  }
}
