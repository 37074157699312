/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { head } from 'lodash/fp'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { DoubleUUID, get, QueryString, TripleUUID, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { getServiceId } from '@/utilities/entity-id'
import { stringify } from '@/utilities/query-string'

import * as actions from './target.actions'
import { normalizeTargets } from './target.schema'
import { targetSlice } from './target.slice'

// ---------------------------------------------------------------------------

const byPerspectiveId = (perpsectiveId: UUID | null) => {
  return {
    field: 'table.perspective_id',
    id: 'table.perspective_id',
    input: 'text',
    operator: 'equal',
    randoriOnly: true,
    type: 'string',
    value: perpsectiveId,
  }
}

export function* targetSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchTarget.toString(), deferredAction(_fetchTarget, io))
  yield takeEvery(actions.fetchTargetByTargetId.toString(), deferredAction(_fetchTargetByTargetId, io))
  yield takeEvery(actions.fetchTargetTotals.toString(), deferredAction(_fetchTargetTotals, io))
  yield takeEvery(actions.fetchTargets.toString(), deferredAction(_fetchTargets, io))
  yield takeEvery(actions.fetchTargetsForHostname.toString(), deferredAction(_fetchTargetsForHostname, io))
  yield takeEvery(actions.fetchTargetsForIp.toString(), deferredAction(_fetchTargetsForIp, io))
  yield takeEvery(actions.fetchTargetsForNetwork.toString(), deferredAction(_fetchTargetsForNetwork, io))
  yield takeEvery(actions.fetchTargetsForService.toString(), deferredAction(_fetchTargetsForService, io))
}

export function* _fetchTargets(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchTargets>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.target.getTargets, queryString)

  if (action.meta.persist) {
    yield* put(targetSlice.actions.replaceTargets(normalizeTargets(response.data)))

    yield* put(
      targetSlice.actions.replaceTargetPagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchTarget(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchTarget>) {
  const id = get(action.payload, DoubleUUID)

  const { data } = yield* call(io.api.target.getTarget, id)

  yield* put(targetSlice.actions.upsertTarget(data))

  return data
}

export function* _fetchTargetTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchTargetTotals>) {
  const { total: unfiltered } = yield* call(
    io.api.target.getTargets,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )

  const { total: unaffiliated } = yield* call(
    io.api.target.getTargets,
    get(CrudQueryUtils.createUnaffiliatedQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered,
    unaffiliated,
  }

  yield* put(targetSlice.actions.replaceTargetTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}

export function* _fetchTargetByTargetId(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchTarget>) {
  const id = get(action.payload, UUID)

  const q = {
    condition: 'AND',
    rules: [
      {
        field: 'table.target_id',
        id: 'table.target_id',
        input: 'text',
        operator: 'equal',
        randoriOnly: false,
        type: 'string',
        ui_id: 'target_id',
        value: id,
      },
    ],
  }

  const { data } = yield* call(
    io.api.target.getTargets,
    get(stringify({ q: CrudQueryUtils.serializeQ(q) }, true), QueryString),
  )

  const target = head(data)

  if (!target) {
    throw new Error(`Could not find target with target_id: ${id}`)
  }

  yield* put(targetSlice.actions.upsertTarget(target))

  return data
}

export function* _fetchTargetsForHostname(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchTargetsForHostname>,
) {
  const id = get(action.payload.entityId, UUID)
  const idField = 'hostname_id'
  const type = 'hostname' as const

  const q = {
    condition: 'AND',
    rules: [
      {
        field: `table.${idField}`,
        id: `table.${idField}`,
        input: 'text',
        operator: 'equal',
        randoriOnly: false,
        type: 'string',
        ui_id: idField,
        value: id,
      },
      byPerspectiveId(action.payload.perspectiveId),
    ],
  }

  const search = stringify(
    {
      limit: action.payload.limit,
      offset: action.payload.offset,
      q: CrudQueryUtils.serializeQ(q),
    },
    true,
  )

  const response = yield* call(io.api.target.getTargets, get(search, QueryString))

  if (action.meta.persist) {
    const payload = {
      forEntity: { id, type },
      nextState: normalizeTargets(response.data),
      pagination: { count: response.count, offset: response.offset, total: response.total },
    }

    yield* put(targetSlice.actions.replaceTargetsForEntity(payload))
  }

  return response
}

export function* _fetchTargetsForIp(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchTargetsForIp>) {
  const id = get(action.payload.entityId, UUID)
  const idField = 'ip_id'
  const type = 'ip' as const

  const q = {
    condition: 'AND',
    rules: [
      {
        field: `table.${idField}`,
        id: `table.${idField}`,
        input: 'text',
        operator: 'equal',
        randoriOnly: false,
        type: 'string',
        ui_id: idField,
        value: id,
      },
      byPerspectiveId(action.payload.perspectiveId),
    ],
  }

  const search = stringify(
    {
      limit: action.payload.limit,
      offset: action.payload.offset,
      q: CrudQueryUtils.serializeQ(q),
    },
    true,
  )

  const response = yield* call(io.api.target.getTargets, get(search, QueryString))

  if (action.meta.persist) {
    const payload = {
      forEntity: { id, type },
      nextState: normalizeTargets(response.data),
      pagination: { count: response.count, offset: response.offset, total: response.total },
    }

    yield* put(targetSlice.actions.replaceTargetsForEntity(payload))
  }

  return response
}

export function* _fetchTargetsForNetwork(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchTargetsForNetwork>) {
  const id = get(action.payload.entityId, UUID)
  const type = 'network' as const

  const q = {
    condition: 'AND',
    rules: [
      {
        field: 'table.ip',
        id: 'table.ip_str',
        input: 'text',
        operator: 'contained_by',
        randoriOnly: false,
        type: 'string',
        ui_id: 'ip_str',
        value: action.payload.networkStr,
      },
      byPerspectiveId(action.payload.perspectiveId),
    ],
  }

  const search = stringify(
    {
      limit: action.payload.limit,
      offset: action.payload.offset,
      q: CrudQueryUtils.serializeQ(q),
    },
    true,
  )

  const response = yield* call(io.api.target.getTargets, get(search, QueryString))

  if (action.meta.persist) {
    const payload = {
      forEntity: { id, type },
      nextState: normalizeTargets(response.data),
      pagination: { count: response.count, offset: response.offset, total: response.total },
    }

    yield* put(targetSlice.actions.replaceTargetsForEntity(payload))
  }

  return response
}

export function* _fetchTargetsForService(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchTargetsForService>) {
  const id = get(action.payload.entityId, TripleUUID)
  const idField = 'service_id'
  const serviceId = getServiceId(action.payload.entityId)
  const type = 'service' as const

  const q = {
    condition: 'AND',
    rules: [
      {
        field: `table.${idField}`,
        id: `table.${idField}`,
        input: 'text',
        operator: 'equal',
        randoriOnly: false,
        type: 'string',
        ui_id: idField,
        value: serviceId,
      },
      byPerspectiveId(action.payload.perspectiveId),
    ],
  }

  const search = stringify(
    {
      limit: action.payload.limit,
      offset: action.payload.offset,
      q: CrudQueryUtils.serializeQ(q),
    },
    true,
  )

  const response = yield* call(io.api.target.getTargets, get(search, QueryString))

  if (action.meta.persist) {
    const payload = {
      forEntity: { id, type },
      nextState: normalizeTargets(response.data),
      pagination: { count: response.count, offset: response.offset, total: response.total },
    }

    yield* put(targetSlice.actions.replaceTargetsForEntity(payload))
  }

  return response
}
