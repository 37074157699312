/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Organization, PostOrgPayload, PostOrgResponse } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export const OrganizationResponse = t.interface({ data: Organization })
export type OrganizationResponse = t.TypeOf<typeof OrganizationResponse>

const OrganizationsResponse = t.interface({
  count: t.number,
  data: t.array(Organization),
  offset: t.number,
  total: t.number,
})
type OrganizationsResponse = t.TypeOf<typeof OrganizationsResponse>

export type OrgApi = {
  getOrg: (id: string) => Promise<OrganizationResponse>
  getOrgs: (query: string) => Promise<OrganizationsResponse>
  createOrg: (body: PostOrgPayload) => Promise<PostOrgResponse>
}

export function orgApiFactory(http: HttpClient): OrgApi {
  const baseURL = '/auth/api/v1/organization'

  return {
    getOrg(id) {
      return http.get(`${baseURL}/${id}`).then(CodecUtils.throwValidate(OrganizationResponse, 'OrganizationResponse'))
    },
    getOrgs(query: string) {
      return http
        .get(`${baseURL}${query}`)
        .then(CodecUtils.throwValidate(OrganizationsResponse, 'OrganizationsResponse'))
    },
    createOrg(body: PostOrgPayload) {
      return http.post(`${baseURL}`, body).then(CodecUtils.throwValidate(PostOrgResponse, 'PostOrgResponse'))
    },
  }
}
