/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ComponentType as Component, ComponentClass } from 'react'

// ---------------------------------------------------------------------------

interface ComponentEnhancer<TInner, TOutter> {
  (component: Component<TInner>): ComponentClass<TOutter>
}

/**
 * @deprecated Use React.hooks instead
 */
export function compose<TInner, TOutter>(...funcs: any[]): ComponentEnhancer<TInner, TOutter> {
  return funcs.reduce(
    (a, b) =>
      (...args: any[]) =>
        a(b(...args)),
    (arg: any) => arg,
  )
}
