/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

const init: Codecs.ActivityConfigurationsResponse = {
  data: [],
  links: { self: undefined, next: undefined, last: undefined },
  meta: { count: 0, offset: 0, total: 0 },
}

export const ActivityConfigurationReducer = (
  state = Schemas.Activity.NormalizeActivityConfigurations(init),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATIONS_STORE_UPDATE: {
      const { entities, result } = Schemas.Activity.NormalizeActivityConfigurations(action.payload)

      return {
        result,
        entities: {
          activity_configuration: {
            ...state.entities.activity_configuration,
            ...entities.activity_configuration,
          },
        },
      }
    }

    case Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATION_STORE_UPDATE: {
      const { activity_configuration } = Schemas.Activity.NormalizeActivityConfiguration(action.payload)

      return {
        result: state.result,
        entities: {
          activity_configuration: {
            ...state.entities.activity_configuration,
            ...activity_configuration,
          },
        },
      }
    }

    case Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE: {
      const { activity_configuration } = Schemas.Activity.NormalizeActivityConfigurationPatch(action.payload)

      return {
        result: state.result,
        entities: {
          activity_configuration: {
            ...state.entities.activity_configuration,
            ...activity_configuration,
          },
        },
      }
    }

    default: {
      return state
    }
  }
}

const ActivityConfigurationsTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const ActivityConfigurationsTotalsReducer = (
  state = ActivityConfigurationsTotalsInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export const ActivityConfigurationsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ActivityConfigurationsPaginationReducer = (
  state = ActivityConfigurationsPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATIONS_STORE_UPDATE: {
      // pagination (limit, offset, total) is enclosed in meta object for json api
      const { meta: pagination } = action.payload // @TODO: need to do anything with links object here?

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
