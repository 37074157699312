/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'

interface Props {
  text: string
}

export class InlineLoader extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    return (
      <span className="inline-loader">
        {this.props.text}
        <div className="loader">
          <span className="dot dot1" />
          <span className="dot dot2" />
          <span className="dot dot3" />
        </div>
      </span>
    )
  }
}
