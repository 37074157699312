/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import * as EntityUtils from '@/utilities/r-entity'

import { TypeKeys } from './recon.actions.typekeys'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export type POLICY_FETCH = {
  type: TypeKeys.POLICY_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function POLICY_FETCH(payload: POLICY_FETCH['payload'], deferred: Deferred): POLICY_FETCH {
  return {
    type: TypeKeys.POLICY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type POLICIES_FETCH = {
  type: TypeKeys.POLICIES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function POLICIES_FETCH(payload: POLICIES_FETCH['payload'], deferred: Deferred): POLICIES_FETCH {
  return {
    type: TypeKeys.POLICIES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type POLICIES_POST = {
  type: TypeKeys.POLICIES_POST
  meta: { deferred: Deferred }
  payload: Codecs.PolicyPostPayload
}

export function POLICIES_POST(payload: POLICIES_POST['payload'], deferred: Deferred): POLICIES_POST {
  return {
    type: TypeKeys.POLICIES_POST,
    meta: { deferred },
    payload,
  }
}

export type POLICIES_PATCH = {
  type: TypeKeys.POLICIES_PATCH
  meta: { deferred: Deferred }
  payload: Codecs.PolicyPatchPayload & { id: string }
}

export function POLICIES_PATCH(payload: POLICIES_PATCH['payload'], deferred: Deferred): POLICIES_PATCH {
  return {
    type: TypeKeys.POLICIES_PATCH,
    meta: { deferred },
    payload,
  }
}

export type POLICIES_STORE_UPDATE = {
  type: TypeKeys.POLICIES_STORE_UPDATE
  payload: Codecs.PoliciesResponse
}

export function POLICIES_STORE_UPDATE(payload: POLICIES_STORE_UPDATE['payload']): POLICIES_STORE_UPDATE {
  return {
    type: TypeKeys.POLICIES_STORE_UPDATE,
    payload,
  }
}

export type POLICY_STORE_UPDATE = {
  type: TypeKeys.POLICY_STORE_UPDATE
  payload: Codecs.Policy
}

export function POLICY_STORE_UPDATE(payload: POLICY_STORE_UPDATE['payload']): POLICY_STORE_UPDATE {
  return {
    type: TypeKeys.POLICY_STORE_UPDATE,
    payload,
  }
}

export type POLICY_TOTALS_FETCH = {
  type: TypeKeys.POLICY_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function POLICY_TOTALS_FETCH(payload: POLICY_TOTALS_FETCH['payload'], deferred: Deferred): POLICY_TOTALS_FETCH {
  return {
    type: TypeKeys.POLICY_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type POLICY_TOTALS_STORE_UPDATE = {
  type: TypeKeys.POLICY_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function POLICY_TOTALS_STORE_UPDATE(payload: POLICY_TOTALS_STORE_UPDATE['payload']): POLICY_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.POLICY_TOTALS_STORE_UPDATE,
    payload,
  }
}
export type POLICY_DELETE = {
  type: TypeKeys.POLICY_DELETE
  meta: { deferred: Deferred }
  payload: string
}

export function POLICY_DELETE(payload: POLICY_DELETE['payload'], deferred: Deferred): POLICY_DELETE {
  return {
    type: TypeKeys.POLICY_DELETE,
    meta: { deferred },
    payload,
  }
}

export type POLICY_DELETE_STORE_UPDATE = {
  type: TypeKeys.POLICY_DELETE_STORE_UPDATE
  payload: string
}

export function POLICY_DELETE_STORE_UPDATE(payload: POLICY_DELETE_STORE_UPDATE['payload']): POLICY_DELETE_STORE_UPDATE {
  return {
    type: TypeKeys.POLICY_DELETE_STORE_UPDATE,
    payload,
  }
}

export type POLICY_FETCH_MATCHING_ENTITIES = {
  type: TypeKeys.POLICY_FETCH_MATCHING_ENTITIES
  meta: { deferred: Deferred }
  payload: {
    filter: Codecs.FilterData
    entityTypes: EntityUtils.EntityType[]
  }
}

export function POLICY_FETCH_MATCHING_ENTITIES(
  payload: POLICY_FETCH_MATCHING_ENTITIES['payload'],
  deferred: Deferred,
): POLICY_FETCH_MATCHING_ENTITIES {
  return {
    type: TypeKeys.POLICY_FETCH_MATCHING_ENTITIES,
    meta: { deferred },
    payload,
  }
}
