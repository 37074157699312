/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { CPE, CVE } from './cpe.codecs'

// ---------------------------------------------------------------------------

export type TargetContext = t.TypeOf<typeof TargetContext>
export const TargetContext = t.type({
  cpe: CPE,
  top_cve: t.array(CVE),
  total_cve: t.number,
})
