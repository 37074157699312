/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullable, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type Asset = t.TypeOf<typeof Asset>
export const Asset = t.type({
  id: UUID,
  display_name: t.string,
  first_seen: nullable(DateString),
  last_seen: nullable(DateString),
  label: t.string,
  org_id: UUID,
  perspective_id: UUID,
  perspective_type: t.string,
  properties: t.union([t.record(t.string, t.string), t.null]),
  sources: t.array(UUID),
})

export type AssetNormalized = t.TypeOf<typeof AssetNormalized>
export const AssetNormalized = t.type({
  id: UUID,
  display_name: t.string,
  first_seen: nullable(DateString),
  last_seen: nullable(DateString),
  label: t.string,
  org_id: UUID,
  perspective_id: UUID,
  perspective_type: t.string,
  sources: t.array(UUID),
})
