/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as _EntityForActivityInstanceSchemas from './activity'
import * as _ActivitySchemas from './activity'
import * as _ArtifactForActivityInstanceSchemas from './activity'
// rename
import * as _RetrieveArtifactSchemas from './activity'
import * as _AttackSchemas from './attack'
import * as _HocSchemas from './hoc'
import * as _IntegrationSchemas from './integration'
import * as _OrganizationSchemas from './organization'
import * as _ReconSchemas from './recon'
import * as _TagSchemas from './tag'

// ---------------------------------------------------------------------------

export const AttackSchemas = _AttackSchemas
export const RetrieveArtifactSchemas = _RetrieveArtifactSchemas
export const ArtifactForActivityInstanceSchemas = _ArtifactForActivityInstanceSchemas
export const EntityForActivityInstanceSchemas = _EntityForActivityInstanceSchemas
export const HocSchemas = _HocSchemas
export const IntegrationSchemas = _IntegrationSchemas
export const OrganizationSchemas = _OrganizationSchemas
export const TagSchemas = _TagSchemas

// better naming
export const Activity = _ActivitySchemas
export const Attack = _AttackSchemas
export const Hoc = _HocSchemas
export const Integration = _IntegrationSchemas
export const Organization = _OrganizationSchemas
export const Recon = _ReconSchemas
export const Tag = _TagSchemas
