/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

// ---------------------------------------------------------------------------

export class ExhaustiveError extends Error {
  constructor(value: never) {
    super(`Unreachable case: ${value}`)

    this.name = 'ExhaustiveError'
  }
}
