/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const TargetsReducer = (state = Schemas.Recon.NormalizeTargets([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TARGETS_STORE_UPDATE: {
      const { entities, result, byTargetId } = Schemas.Recon.NormalizeTargets(action.payload.data)

      return {
        result,
        byTargetId: {
          ...state.byTargetId,
          ...byTargetId,
        },
        entities: {
          ...state.entities,
          target: {
            ...state.entities.target,
            ...entities.target,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.TARGET_STORE_UPDATE: {
      return {
        byTargetId: {
          ...state.byTargetId,
          [action.payload.target_id]: action.payload.id,
        },
        result: state.result,
        entities: {
          ...state.entities,
          target: {
            ...state.entities.target,
            [action.payload.id]: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}

const TargetsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const TargetsPaginationReducer = (state = TargetsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TARGETS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}

const TargetsTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const TargetsTotalsReducer = (state = TargetsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TARGET_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}
