/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

// ---------------------------------------------------------------------------

/**
 * Rethrow Error
 *
 * @remarks
 * For the moment, we don't need any additional logic as a catch handler,
 * but leaving the seam.
 *
 * @param url - the url associated with the window.fetch promise chain
 *
 * @returns a re-throwing function
 */
export const handleErrorP = (_url: string) => {
  return (err: Error) => {
    throw err
  }
}
