/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

// @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
export class RandoriApiError extends Error {
  ref: string
  status: number

  constructor(message: { reason: string; ref: string }, status: number, ...rest: string[]) {
    super(...rest)

    this.message = message.reason
    this.name = 'RandoriApiError'
    this.ref = message.ref
    this.status = status

    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (isNotNil(Error.captureStackTrace)) {
      Error.captureStackTrace(this, RandoriApiError)
    } else {
      this.stack = new Error(message.reason).stack
    }
  }
}
