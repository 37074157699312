/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { CreatePerspectiveResponse, PerspectiveResponse, PerspectivesResponse } from '@/codecs/perspective.codecs'
import { SourceResponse, SourcesResponse } from '@/codecs/source.codecs'
import type { HttpClient } from '@/http/http.utils'
import { throwValidate, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

/* Note: Requests for READING Sources and Perspectives will go to the perspective service.
 * WRITE requests for Perspectives will go to perspective service.
 * WRITE requests for Sources will go to another service, likely Attack for v0.
 */
export type PerspectiveApi = {
  // @TODO: optional query params sourceType and perspectiveType
  createPerspective: (payload: {
    name: string
    perspectiveType: Codecs.PerspectiveType
  }) => Promise<CreatePerspectiveResponse>
  getPerspectives: () => Promise<PerspectivesResponse>
  getPerspective: (id: UUID) => Promise<PerspectiveResponse>
  getSources: () => Promise<SourcesResponse>
  getSource: (id: UUID) => Promise<SourceResponse>
}

export function perspectiveApiFactory(http: HttpClient): PerspectiveApi {
  // @TODO: confirm this as BE gets fleshed out
  const baseUrl = '/perspectiveservice/api/v1'

  return {
    createPerspective(payload: { name: string; perspectiveType: Codecs.PerspectiveType }) {
      return http
        .post(`${baseUrl}/perspectives`, payload)
        .then(throwValidate(CreatePerspectiveResponse, 'CreatePerspectiveResponse'))
    },

    getPerspectives() {
      return http.get(`${baseUrl}/perspectives`).then(throwValidate(PerspectivesResponse, 'PerspectivesV2Response'))
    },

    getPerspective(id) {
      return http.get(`${baseUrl}/perspectives/${id}`).then(throwValidate(PerspectiveResponse, 'PerspectiveV2Response'))
    },

    getSources() {
      return http.get(`${baseUrl}/sources`).then(throwValidate(SourcesResponse, 'SourcesResponse'))
    },

    getSource(id) {
      return http.get(`${baseUrl}/sources/${id}`).then(throwValidate(SourceResponse, 'SourceResponse'))
    },
  }
}
