/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'
import { ActionTypes, SessionActions } from '@/store/actions'

// ---------------------------------------------------------------------------

type _State = {
  authorization?: Codecs.DecodedSession
}

const initialState: _State = {}

export const Reducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case SessionActions.TypeKeys.SESSION_SET:
      return {
        ...state,
        authorization: action.payload,
      }

    default:
      return state
  }
}

export type State = ReturnType<typeof Reducer>
