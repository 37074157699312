/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------

const UnknownsReducerInitialState = {
  ['hostname']: { unknown: 0, total: 0 },
  ['ip']: { unknown: 0, total: 0 },
  ['network']: { unknown: 0, total: 0 },
  ['target']: { unknown: 0, total: 0 },
}

export const UnknownsReducer = (state = UnknownsReducerInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.CategoryActions.TypeKeys.UNKNOWNS_STORE_UPDATE: {
      const { type, ...rest } = action.payload

      return {
        ...state,
        [type]: rest,
      }
    }

    default: {
      return state
    }
  }
}

export const CategoriesReducer = combineReducers({
  unknowns: UnknownsReducer,
})
