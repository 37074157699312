/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { createLogger } from 'redux-logger'

// ---------------------------------------------------------------------------

export const createLoggingMiddleware = (log: boolean, options?: { logger: boolean }) => {
  if (log && isNotNil(options) && options.logger) {
    const logger = createLogger({
      collapsed: true,
      level: 'info',
      predicate: (_, action) => {
        const substring = action?.type?.substring(0, 2)

        return !(substring === '@@')
      },
    })

    return [logger]
  } else {
    return []
  }
}
