/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

const init: Codecs.ApplicableActivitiesResponse = {
  data: [],
  links: { self: undefined, next: undefined, last: undefined },
  meta: { count: 0, offset: 0, total: 0 },
}

export const ApplicableActivitiesReducer = (
  state = Schemas.Activity.NormalizeApplicableActivities(init),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ApplicableActivity.TypeKeys.APPLICABLE_ACTIVITIES_STORE_UPDATE: {
      const { entities, result } = Schemas.Activity.NormalizeApplicableActivities(action.payload)

      return {
        result,
        entities: {
          applicable_activity: {
            ...state.entities.applicable_activity,
            ...entities.applicable_activity,
          },
        },
      }
    }

    default: {
      return state
    }
  }
}

export const ApplicableActivitiesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ApplicableActivitiesPaginationReducer = (
  state = ApplicableActivitiesPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ApplicableActivity.TypeKeys.APPLICABLE_ACTIVITIES_STORE_UPDATE: {
      // pagination (limit, offset, total) is enclosed in meta object for json api
      const { meta: pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
