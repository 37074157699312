/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { format } from 'date-fns'

import type { EntityType } from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export const getFilename = (kind: EntityType) => {
  const date = new Date()
  const noun = kind === 'topLevelDetection' ? 'detections' : `${kind}s`

  const filename = `${noun}-${format(date, 'MM-dd-yyyy_HH-mm-ss')}.csv`

  return filename
}
