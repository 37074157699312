/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import { isNil } from 'lodash/fp'

import * as ActivityFixtures from '@/api/activity/__fixtures__'
import * as Codecs from '@/codecs'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { RFLOAT } from '@/utilities/disable-floating-header'

// ---------------------------------------------------------------------------

const allowedKeys = ['rlog', 'rstorelog', 'rtrace', 'rtrack'] as const

/**
 * Interpolate a possible boolean derived from a JSON.parse, e.g. JSON.parse('true')
 *
 * @param maybeBool - probably a boolean derived from JSON.parse('true')
 *
 * @returns the original value, or false
 */
const toBoolean = (maybeBool: unknown) => {
  if (maybeBool === true) {
    return maybeBool
  }

  return false
}

export const initPersistedConfig = (configJson: Codecs.ConfigJson) => {
  const keep: Record<string, boolean> = {}

  const _LOGS = localStorage.getItem('rlog')
  const _STORE_LOGS = localStorage.getItem('rstorelog')
  const _TRACES = localStorage.getItem('rtrace')
  const _TRACK_ENV = localStorage.getItem('rtrack')

  if (isNil(_LOGS)) {
    keep['rlog'] = configJson.LOGS
  } else {
    keep['rlog'] = toBoolean(JSON.parse(_LOGS))
  }

  if (isNil(_TRACK_ENV)) {
    keep['rtrack'] = configJson.TRACK_ENV
  } else {
    keep['rtrack'] = toBoolean(JSON.parse(_TRACK_ENV))
  }

  if (isNil(_STORE_LOGS)) {
    keep['rstorelog'] = configJson.STORE_LOGS ?? false
  } else {
    keep['rstorelog'] = toBoolean(JSON.parse(_STORE_LOGS))
  }

  if (isNil(_TRACES)) {
    keep['rtrace'] = configJson.TRACES ?? false
  } else {
    keep['rtrace'] = toBoolean(JSON.parse(_TRACES))
  }

  return keep
}

/**
 * Toggle runtime configuration sentinel values
 *
 * @param key - which configuration value to toggle, e.g., "rlog" or "rtrace"
 */
export const persistConfig = (key: (typeof allowedKeys)[number]) => {
  if (allowedKeys.includes(key)) {
    const _prev = localStorage.getItem(key) ?? 'false'
    const prev = toBoolean(JSON.parse(_prev))

    const nextValue = !prev

    localStorage.setItem(key, JSON.stringify(nextValue))

    console.warn(`set "${key}" to "${nextValue.toString()}"`)
  } else {
    console.warn(`persistConfig can only set: ${allowedKeys.join(', ')}`)
  }
}

export const getPersistedConfig = () => {
  const keep: Record<(typeof allowedKeys)[number], boolean | undefined> = {
    rlog: undefined,
    rstorelog: undefined,
    rtrace: undefined,
    rtrack: undefined,
  }

  allowedKeys.forEach((key) => {
    const _prev = localStorage.getItem(key)

    if (isNil(_prev)) {
      return // bail
    }

    const prev = toBoolean(JSON.parse(_prev))

    keep[key] = prev
  })

  return keep
}

const activityFixtures = [
  'ActivityConfiguration',
  'ActivityConfigurations',
  'ActivityInstances',
  'ApplicableActivities',
] as const
export type activityFixtures = (typeof activityFixtures)[number]
export type activityFixturesList = typeof activityFixtures

const getActivityFixtureList = () => activityFixtures

const getActivityFixture = (fixtureName: activityFixtures) => {
  switch (fixtureName) {
    case 'ActivityConfiguration':
      return ActivityFixtures.ActivityConfiguration
    case 'ActivityConfigurations':
      return ActivityFixtures.ActivityConfigurations
    case 'ActivityInstances':
      return ActivityFixtures.ActivityResponse
    case 'ApplicableActivities':
      return ActivityFixtures.ApplicableActivities
    default:
      return `No fixture exists with that name. Must be one of the following ${getActivityFixtureList().join(', ')}`
  }
}

export const init = () => {
  window.__RUTIL__ = {} as typeof window.__RUTIL__

  window.__RUTIL__.build = process.env.NODE_ENV as string
  window.__RUTIL__.debug = debug
  window.__RUTIL__.parse = CrudQueryUtils.RPARSE
  window.__RUTIL__.artifact = ''
  window.__RUTIL__.getActivityFixture = getActivityFixture
  window.__RUTIL__.getActivityFixtureList = getActivityFixtureList
  window.__RUTIL__.persistConfig = persistConfig
  window.__RUTIL__.release = isNil(process.env.__RRELEASE__)
    ? 'unversioned'
    : process.env.__RRELEASE__.replace(/^v/, '') // strip "v" from "v0.x.x"

  Object.defineProperty(window.__RUTIL__, 'rq', {
    get: function () {
      return CrudQueryUtils.RQ()
    },
  })

  Object.defineProperty(window.__RUTIL__, 'float', {
    get: function () {
      return RFLOAT()
    },
  })

  Object.defineProperty(window, '__RQ__', {
    get: function () {
      console.warn('__RQ__ is deprecated. Use __RUTIL__.rq insead')
    },
  })

  Object.defineProperty(window, '__RFLOAT__', {
    get: function () {
      console.warn('__RFLOAT__ is deprecated. Use __RUTIL__.float insead')
    },
  })
}
