/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { ConfidenceBoundaries, PriorityPreference } from '@/store/selectors/preferences/preferences.selectors'
import type { TTBoundaries } from '@/store/selectors/preferences/preferences.selectors.utils'
import type { EntityType } from '@/utilities/r-entity'

import { convertToCSV } from './convert-to-csv'
import { getFilename } from './get-filename'
import { getRows } from './get-rows'

// ---------------------------------------------------------------------------

export function exportCSVFile(
  kind: EntityType,
  records: Array<Record<string, any>>,
  selectedColumns: string[],
  priorityBoundaries: PriorityPreference,
  temptationBoundaries: TTBoundaries,
  confidenceBoundaries: ConfidenceBoundaries,
) {
  const filename = getFilename(kind)
  const rows = getRows(kind, records, selectedColumns, priorityBoundaries, temptationBoundaries, confidenceBoundaries)

  const csv = convertToCSV(rows)

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

  const link = document.createElement('a')
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
