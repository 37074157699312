/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { EntityType } from './entity.codecs/entity-types'
import { FilterData } from './rule.codecs'

// ---------------------------------------------------------------------------

export const PolicyActions = t.array(t.record(t.string, t.unknown))

const _base = {
  actions: PolicyActions,
  created_at: t.string,
  creator_user_name: t.string,
  edited_at: t.string,
  editor_user_name: t.string,
  entity_types: t.array(EntityType),
  expires_at: CodecUtils.nullable(t.string),
  filter_data: FilterData,
  id: t.string,
  is_active: t.boolean,
  is_deleted: CodecUtils.nullable(t.boolean), // @TODO: temporary
  is_dirty: CodecUtils.nullable(t.boolean), // @TODO: temporary
  name: t.string,
  notes: t.string,
  org_id: t.string,
  recent_success: CodecUtils.nullable(t.string),
  version: t.number,
}

export const Policy = t.type(_base)

type _Policy = t.TypeOf<typeof Policy>
export type Policy = _Policy

export const PolicyResponse = t.type({
  data: Policy,
})

export type PolicyResponse = t.TypeOf<typeof PolicyResponse>

export const PoliciesResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Policy),
})

export type PoliciesResponse = t.TypeOf<typeof PoliciesResponse>

export const PolicyPostPayload = t.type({
  data: t.array(
    t.type({
      actions: t.type({}),
      entity_types: t.array(t.string),
      filter_data: t.type({}),
      name: t.string,
      notes: t.string,
    }),
  ),
})

export type PolicyPostPayload = t.TypeOf<typeof PolicyPostPayload>

export const PolicyUpdateResponse = t.type({
  ids: t.array(t.string),
})

export type PolicyUpdateResponse = t.TypeOf<typeof PolicyUpdateResponse>

export const PolicyPatchPayload = t.type({
  data: t.partial(_base),
})

export type PolicyPatchPayload = t.TypeOf<typeof PolicyPatchPayload>
