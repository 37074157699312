/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const PermissionGroupInfo = t.interface({
  perm_group_desc: t.string,
  perm_group_name: t.string,
  perm_group_type: t.string,
})

type _PermissionGroupInfo = t.TypeOf<typeof PermissionGroupInfo>
export type PermissionGroupInfo = _PermissionGroupInfo

export const PermissionGroupsResponse = t.interface({
  perm_groups: t.array(t.string),
  target_user_id: t.string,
})
export type PermissionGroupsResponse = t.TypeOf<typeof PermissionGroupsResponse>
export type PermissionGroupsPayload = PermissionGroupsResponse

export const PermissionGroupTypesResponse = t.interface({
  perm_group_info: t.array(PermissionGroupInfo),
})

export type PermissionGroupTypesResponse = t.TypeOf<typeof PermissionGroupTypesResponse>
