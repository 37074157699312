/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const PocReducer = (state = Schemas.HocSchemas.NormalizePocs([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.POCS_STORE_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizePocs(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          poc: {
            ...state.entities.poc,
            ...entities.poc,
          },
        },
      }
    }

    default:
      return state
  }
}

export const PocsTotalsInitialState: Codecs.HocEntityTotals = {
  total: 0,
  unfilteredTotal: 0,
}

export const PocsTotalsReducer = (state = PocsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.POCS_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const PocsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const PocsPaginationReducer = (state = PocsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.POCS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
