/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'
import { Response, Server } from 'miragejs'

import { EXCEPTION_POLICIES_URL } from '../constants'

export const exceptionPolicyRoutes = (server: Server): void => {
  // for loading table data
  server.get(EXCEPTION_POLICIES_URL, (schema, request) => {
    const allData = schema.db.exceptionPolicies
    const totalItems = allData.length

    const responseMeta = {
      offset: 0,
      count: totalItems,
      total: totalItems,
    }

    const responseLinks = {
      self: request.url,
      next: '',
      last: '',
    }

    if (isNotNilOrEmpty(allData)) {
      return {
        data: allData,
        meta: responseMeta,
        links: responseLinks,
      }
    }

    return new Response(404, {}, { errors: ['Resource not found'] })
  })

  // for loading an individual detail page
  server.get(`${EXCEPTION_POLICIES_URL}/:id`, (schema, request) => {
    const id = request.params.id

    const exceptionPolicy = schema.db.exceptionPolicies.find(id)

    const responseLinks = {
      self: request.url,
      next: '',
      last: '',
    }

    if (isNotNilOrEmpty(exceptionPolicy)) {
      return {
        data: exceptionPolicy,
        links: responseLinks,
      }
    }

    return new Response(404, {}, { errors: ['Resource not found'] })
  })
}
