/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { createSelector } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash/fp'

import * as Codecs from '@/codecs'
import type { AppState } from '@/store'
import { get, UUID } from '@/utilities/codec'

/**
 * Normalizes the structure of an exception policy object for display in the summary table
 *
 * @param exceptionPolicy - The exception policy object to normalize
 *
 * @returns An object of type `ExceptionPolicyNormalizedForSummary`, representing the normalized exception policy,
 *
 */
export const normalizeExceptionPolicy = (
  exceptionPolicy: Codecs.ExceptionPolicy,
): Codecs.ExceptionPolicyNormalizedForSummary => {
  const { id, type, attributes } = exceptionPolicy
  const { created, updated, time_spans, week_spans } = attributes
  const { date: createdOn, author_id: createdBy } = created
  const { date: updatedOn, author_id: updatedBy } = updated

  // every policy will have one or the other week_spans or time_spans
  const exceptionSchedule = isEmpty(time_spans) ? week_spans : time_spans

  const normalizedExceptionPolicy = {
    id: get(id, UUID),
    type,
    ...attributes,
    // these keys match the column keys from view schema
    created_by: createdBy,
    created_on: createdOn,
    updated_by: updatedBy,
    updated_on: updatedOn,
    exception_schedule: exceptionSchedule,
  }

  return normalizedExceptionPolicy
}

export const selectExceptionPolicies = createSelector(
  [(state: AppState) => state.exceptionPolicy],
  (exceptionPoliciesState) => {
    return exceptionPoliciesState.result.map((uuid) =>
      normalizeExceptionPolicy(exceptionPoliciesState.entities.exception_policy[uuid]),
    )
  },
)

export const selectExceptionPoliciesPagination = createSelector(
  [(state: AppState) => state.exceptionPolicy],
  (exceptionPoliciesState) => {
    return exceptionPoliciesState.pagination
  },
)

export const _selectExceptionPolicyById = (state: AppState, props: { id: UUID }) => {
  const exceptionPolicy = state.exceptionPolicy.entities.exception_policy[props.id]

  return exceptionPolicy
}

export const selectExceptionPolicyById = createSelector([_selectExceptionPolicyById], (exceptionPolicy) => {
  if (isNotNil(exceptionPolicy)) {
    return normalizeExceptionPolicy(exceptionPolicy)
  }
})
