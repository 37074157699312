/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as d from 'date-fns'
import * as tz from 'date-fns-tz'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { isDate, isNil, isString } from 'lodash/fp'

import * as Codecs from '@/codecs'
import * as CodecUtils from '@/utilities/codec'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export const __SECONDS_PER_DAY__ = 86400

export function rDate(_dateToFormat: Date | string) {
  if (isString(_dateToFormat)) {
    const dateToFormat = Date.parse(_dateToFormat)

    return `${d.formatISO(dateToFormat, { representation: 'date' })}`
  } else {
    return `${d.formatISO(_dateToFormat, { representation: 'date' })}`
  }
}

// iso string
export function utcDate(dateString: string /* iso string*/) {
  const zonedDate = tz.toZonedTime(new Date(dateString), 'Etc/GMT')
  const formattedTime = tz.format(zonedDate, 'MM/dd/yy HH:mm zzz', { timeZone: 'Etc/GMT' })

  return formattedTime
}

export function utcTime(dateString: string /* iso string*/) {
  const zonedDate = tz.toZonedTime(new Date(dateString), 'Etc/GMT')
  const formattedTime = tz.format(zonedDate, 'HH:mm zzz', { timeZone: 'Etc/GMT' })

  return formattedTime
}

export function isValidDate(str: string) {
  if (str.includes('_')) {
    return false
  } else {
    return d.isValid(new Date(str))
  }
}

// convert date to a UTC timezone ISO string
export function formatToDateStr(str: string | Date) {
  return new Date(str).toISOString()
}

export function formatFromDateStr(_str?: unknown) {
  if (isNil(_str)) {
    return // bail
  } else {
    const str = CodecUtils.throwValidate(t.string, 'string')(_str)

    return d.format(d.parseISO(str), 'MM/dd/yyyy')
  }
}

export function formatSecondsToDaysString(_seconds?: unknown) {
  if (isNotNil(_seconds)) {
    const seconds = CodecUtils.throwValidate(t.string, 'string')(_seconds)

    const inDays = parseInt(seconds, 10) / __SECONDS_PER_DAY__

    return inDays.toString()
  }
}

export function formatRelative(nextValue: string) {
  return d.format(
    d.subDays(new Date(Date.now()), parseInt(nextValue, 10)),
    // eslint-disable-next-line quotes
    `yyyy-MM-dd'T'HH:mm:ss.000+00`,
  )
}

export const oneMonthBackFromDate = (date: string) =>
  formatToDateStr(d.subMonths(new Date(date).setHours(0, 0, 0, 0), 1))

export const getOneWeekAgo = (_date: Date = new Date(Date.now())) => {
  return formatToDateStr(d.subWeeks(_date.setHours(0, 0, 0, 0), 1))
}

export const getOneMonthAgo = (_date: Date = new Date(Date.now())) => {
  return formatToDateStr(d.subMonths(_date.setHours(0, 0, 0, 0), 1))
}

export const getThreeMonthsAgo = (_date: Date = new Date(Date.now())) => {
  return formatToDateStr(d.subMonths(_date.setHours(0, 0, 0, 0), 3))
}

export const getOneYearAgo = (_date: Date = new Date(Date.now())) => {
  return formatToDateStr(d.subYears(_date.setHours(0, 0, 0, 0), 1))
}

export const oneWeekAgo = {
  label: 'oneWeekAgo',
  value: getOneWeekAgo(),
}

export const oneMonthAgo = {
  label: 'oneMonthAgo',
  value: getOneMonthAgo(),
}

export const threeMonthsAgo = {
  label: 'threeMonthsAgo',
  value: getThreeMonthsAgo(),
}

type formatDateOrSeconds = {
  /** @deprecated
   * Use (date: unknown) instead. `baseDate` is only intended for usage by
   * tests.
   * */
  (date: unknown, baseDate: Date): string

  (date: unknown): string
}

// @TODO: Burn the need for this function to the ground
export const formatDateOrSeconds: formatDateOrSeconds = (date, baseDate = new Date(Date.now())) => {
  if (!isDate(baseDate)) {
    throw new Error('bad')
  }

  const _date = Codecs.DelimitedISODateTime.decode(date)

  if (isDate(date)) {
    return rDate(date)
  } else if (E.isRight(_date)) {
    const __date = E.getOrElse(() => new Date(Date.now()))(_date as any)

    return rDate(__date)
  } else if (isString(date)) {
    const seconds = parseInt(date, 10)

    const asDate = d.subSeconds(baseDate, seconds)

    return rDate(asDate)
  } else {
    throw new Error('Unsupported format for conversion to Date')
  }
}

export function isNumberOfDaysAgo(date: string, days: number) {
  const nDaysAgo = d.subDays(new Date(Date.now()), days)

  return Date.parse(date) > Date.parse(nDaysAgo.toString())
}
