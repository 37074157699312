/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Ip } from './ip'
import { IpForHostname } from './ip-for-hostname'
import { IpForNetwork } from './ip-for-network'

// ---------------------------------------------------------------------------

export type IpsResponse = t.TypeOf<typeof IpsResponse>
export const IpsResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Ip),
})

export type IpResponse = t.TypeOf<typeof IpResponse>
export const IpResponse = t.type({
  data: Ip,
})

export type IpsForHostnameResponse = t.TypeOf<typeof IpsForHostnameResponse>
export const IpsForHostnameResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(IpForHostname),
})

export type IpsForNetworkResponse = t.TypeOf<typeof IpsForNetworkResponse>
export const IpsForNetworkResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(IpForNetwork),
})
