/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DetectionCriteria } from './detection-criteria.codecs'

// ---------------------------------------------------------------------------

export const ServiceSuggestion = t.interface({
  id: t.string,

  bogus: t.boolean,
  name: t.string,
  detector: t.string,
  tag: t.string,
  time: t.string,
  version: t.string,
})
export type ServiceSuggestion = t.TypeOf<typeof ServiceSuggestion>

export const ServiceSuggestionResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ServiceSuggestion),
})
export type ServiceSuggestionResponse = t.TypeOf<typeof ServiceSuggestionResponse>

export const ServiceSuggestionPatchPayload = t.interface({
  data: t.partial({
    id: t.string,

    bogus: t.boolean,
    name: t.string,
    detector: t.string,
    tag: t.string,
    time: t.string,
    version: t.string,
  }),
})
export type ServiceSuggestionPatchPayload = t.TypeOf<typeof ServiceSuggestionPatchPayload>

export const ServiceSuggestionPatchResponse = t.interface({
  data: ServiceSuggestion,
})
export type ServiceSuggestionPatchResponse = t.TypeOf<typeof ServiceSuggestionPatchResponse>

// ---------------------------------------------------------------------------

export const ServiceSuggestionWithStats = t.interface({
  id: t.string,

  detector: t.string,
  name: t.string,
  version: t.string,
  bogus: t.boolean,
  detection_criterion: t.array(DetectionCriteria),
  affected_orgs: t.array(t.string),
  affected_org_count: t.number,
})

export type ServiceSuggestionWithStats = t.TypeOf<typeof ServiceSuggestionWithStats>

export const ServiceSuggestionWithStatsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ServiceSuggestionWithStats),
})
export type ServiceSuggestionWithStatsResponse = t.TypeOf<typeof ServiceSuggestionWithStatsResponse>

// ---------------------------------------------------------------------------

export const ServiceSuggestionStats = t.interface({
  existing_services: t.number,
  orgs_impacted: t.number,
})
export type ServiceSuggestionStats = t.TypeOf<typeof ServiceSuggestionStats>

export const ServiceSuggestionStatsResponse = ServiceSuggestionStats
export type ServiceSuggestionStatsResponse = t.TypeOf<typeof ServiceSuggestionStatsResponse>

// ---------------------------------------------------------------------------

export const ServiceSuggestionBulkPostPayload = t.interface({
  q: t.string,
})
export type ServiceSuggestionBulkPostPayload = t.TypeOf<typeof ServiceSuggestionBulkPostPayload>

export const ServiceSuggestionBulkPostResponse = t.unknown
export type ServiceSuggestionBulkPostResponse = t.TypeOf<typeof ServiceSuggestionBulkPostResponse>
