/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export enum StatusEnum {
  accepted = 'Accepted',
  mitigated = 'Mitigated',
  needsInvestigation = 'Needs Investigation',
  needsResolution = 'Needs Resolution',
  needsReview = 'Needs Review',
  none = 'None',
}

export type Status = t.TypeOf<typeof Status>
export const Status = t.keyof({
  [StatusEnum.accepted]: null,
  [StatusEnum.mitigated]: null,
  [StatusEnum.needsInvestigation]: null,
  [StatusEnum.needsResolution]: null,
  [StatusEnum.needsReview]: null,
  [StatusEnum.none]: null,
})

export type StatusGroups = t.TypeOf<typeof StatusGroups>
export const StatusGroups = t.type({
  total_in_ranges: t.number,
  total_other: t.number,
  tt_counts: t.array(
    t.type({
      total: t.number,
      tt_range_name: t.string,
    }),
  ),
})

export type StatusGroupsResponse = t.TypeOf<typeof StatusGroupsResponse>
export const StatusGroupsResponse = t.type({
  status_counts: t.array(
    t.type({
      status: Status,
      total: t.number,
    }),
  ),
  total: t.number,
})

export interface StatusBody {
  entity_type: string
  min_first_seen: string
  tt_ranges: {
    tt_max: number
    tt_min: number
    tt_range_name: string
  }[]
}
