/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ActivityReport, ReportResponse, ReportsResponse } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

import { getActivityReport } from './__fixtures__/validated'

// ---------------------------------------------------------------------------

const _TEST_ = false

export type ReportApi = {
  getReports: (query: string) => Promise<ReportsResponse>
  getReport: (id: string) => Promise<ReportResponse>
  getAttackOppoReport: (org_id: string) => Promise<Record<string, unknown>>
  getAttackOppoReportCSV: (org_id: string) => Promise<Record<string, unknown>>
}

export function reportApiFactory(http: HttpClient): ReportApi {
  const baseURL = '/recon/api/v1'

  return {
    getReports(query) {
      return http.get(`${baseURL}/report${query}`).then(CodecUtils.throwValidate(ReportsResponse, 'ReportsResponse'))
    },

    getReport(id: string) {
      return http.get(`${baseURL}/report/${id}`).then(CodecUtils.throwValidate(ReportResponse, 'ReportResponse'))
    },

    getAttackOppoReport(org_id: string) {
      if (_TEST_) {
        return Promise.resolve(getActivityReport()).then(CodecUtils.throwValidate(ActivityReport, 'ActivityReport'))
      } else {
        return http
          .get(`${baseURL}/attack-oppertunistic/${org_id}`)
          .then(CodecUtils.throwValidate(ActivityReport, 'ActivityReport'))
      }
    },

    getAttackOppoReportCSV(org_id: string) {
      return http.get(`${baseURL}/attack-oppertunistic-csv/${org_id}`)
    },
  }
}
