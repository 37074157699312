/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const FieldTypes = [
  'bool',
  'list<number>',
  'list<port>',
  'list<range<port>>',
  'list<string>',
  'number',
  'period',
  'port',
  'range<number>',
  'range<port>',
  'service',
  'string',
] as const
export type _FieldTypes = (typeof FieldTypes)[number]

const FieldTypesProjection = FieldTypes.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<_FieldTypes, void>)

export const FieldType = t.keyof(FieldTypesProjection)
export type FieldType = t.TypeOf<typeof FieldType>

export const PeriodFieldType = t.type({
  field_type: t.literal('period'),
  value: t.number,
})

export const NumberFieldType = t.type({
  field_type: t.literal('number'),
  value: t.number,
})

export const NumberListFieldType = t.type({
  field_type: t.literal('list<number>'),
  value: t.array(t.number),
})

export const NumberRangeFieldType = t.type({
  field_type: t.literal('range<number>'),
  value: t.tuple([t.number, t.number]),
})

export const PortFieldType = t.type({
  field_type: t.literal('port'),
  value: t.number,
})

export const PortListFieldType = t.type({
  field_type: t.literal('list<port>'),
  value: t.array(t.number),
})

export const PortRangeFieldType = t.type({
  field_type: t.literal('range<port>'),
  value: t.tuple([t.number, t.number]),
})

export const PortListofRangesFieldType = t.type({
  field_type: t.literal('list<range<port>>'),
  value: t.array(t.tuple([t.number, t.number])),
})

export const StringFieldType = t.type({
  field_type: t.literal('string'),
  value: t.string,
})

export const StringListFieldType = t.type({
  field_type: t.literal('list<string>'),
  value: t.array(t.string),
})

export const BooleanFieldType = t.type({
  field_type: t.literal('bool'),
  value: t.boolean,
})

export const ServiceFieldType = t.type({
  field_type: t.literal('service'),
  value: t.array(t.array(t.string)),
})

export const FieldTypeValuePairs = t.union([
  BooleanFieldType,
  NumberFieldType,
  NumberListFieldType,
  NumberRangeFieldType,
  PeriodFieldType,
  PortFieldType,
  PortListFieldType,
  PortListofRangesFieldType,
  PortRangeFieldType,
  ServiceFieldType,
  StringFieldType,
  StringListFieldType,
])

export type FieldTypeValuePairs = t.TypeOf<typeof FieldTypeValuePairs>
