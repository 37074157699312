/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, Network, nullableP, UUID } from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { WithPerspective } from '../perspective.codecs'
import { WithPriority } from '../priority.codecs'

// ---------------------------------------------------------------------------

const _Network = t.type({
  affiliation_state: Affiliation,
  characteristic_tags: t.array(t.string),
  confidence: nullableP(t.number),
  deleted: nullableP(t.boolean),
  first_seen: nullableP(DateString),
  id: UUID,
  ip_count: nullableP(t.number),
  last_seen: nullableP(DateString),
  max_confidence: nullableP(t.number),
  network: nullableP(t.string),
  network_str: nullableP(Network),
  only_in_review_targets: t.boolean,
  open_port_count: nullableP(t.number),
  org_id: nullableP(UUID),
  service_count: nullableP(t.number),
  target_count: nullableP(t.number),
  user_tags: t.array(t.string),
})

export type NetworkP = t.TypeOf<typeof NetworkP>
export const NetworkP = t.intersection([_Network, WithPerspective, WithPriority])
