/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { DeferredAction } from 'redux-saga-try-catch'

import { TripleUUID } from '@/utilities/codec'

import { makeFetchMany, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './service.constants'

// ---------------------------------------------------------------------------

export const fetchServiceTotals = makeFetchTotals(EntityName)
export const fetchServices = makeFetchMany(EntityName)

export const fetchService = createAction(
  `${EntityName}/fetch-single`,
  (payload: TripleUUID, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)
