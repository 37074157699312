/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'
import qs from 'query-string'

import type { HttpClient } from '@/http/http.utils'

// ---------------------------------------------------------------------------

export type ArtifactsApi = {
  getArtifactByShasum: (id: string, orgId?: string) => Promise<unknown>
  getArtifactP: (id: string) => Promise<Response>
  getArtifactRaw: (shasum: string) => Promise<unknown>
  postMissedAffiliations: (missedAffiliations: string[]) => Promise<void>
  postMissedAffiliationsFile: (formData: FormData) => Promise<void>
}

export function artifactsApiFactory(http: HttpClient): ArtifactsApi {
  const baseUrl = '/artifactstore/api/v1'

  return {

    getArtifactRaw(shasum) {
      return http.get(`${baseUrl}/artifact-raw/${shasum}`)
    },

    getArtifactP(id) {
      return window.fetch(`${baseUrl}/retrieve-artifact/${id}`)
    },

    getArtifactByShasum(shasum, orgId) {
      const query = qs.stringify({
        org_id: orgId,
      })

      if (isNotNilOrEmpty(query)) {
        return http.get(`${baseUrl}/user/retrieve/${shasum}?${query}`)
      } else {
        return http.get(`${baseUrl}/user/retrieve/${shasum}`)
      }
    },

    postMissedAffiliations(missedAffiliations) {
      return http.post(`${baseUrl}/add_affiliation`, missedAffiliations)
    },

    postMissedAffiliationsFile(formData) {
      return http.post(`${baseUrl}/add_affiliation_file`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
    },
  }
}
