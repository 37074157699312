/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/**
 * Returns the explicit endpoint for single detections for target.  This is for cases when we wish to
 * reference the top level detection directly, instead of the parent target.
 */
export const getExplicitSingleDetectionForTargetEndpoint = (): string => {
  return '/recon/api/v1/single-detection-for-target'
}
