/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { GlobalHostname } from './global-hostname'

// ---------------------------------------------------------------------------

export type GlobalHostnameResponse = t.TypeOf<typeof GlobalHostnameResponse>
export const GlobalHostnameResponse = t.type({
  count: t.number,
  data: t.array(GlobalHostname),
  offset: t.number,
  total: t.number,
})

export type GlobalHostnamePostPayload = t.TypeOf<typeof GlobalHostnamePostPayload>
export const GlobalHostnamePostPayload = t.type({
  parent_info: t.UnknownRecord,
  data: t.array(
    t.type({
      hostname: t.string,
      org_id: t.string,
      perspective: t.union([t.string, t.undefined]),
    }),
  ),
})

export type GlobalHostnamePostResponse = t.TypeOf<typeof GlobalHostnamePostResponse>
export const GlobalHostnamePostResponse = t.type({})
