/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

const isQueryString = /^\?/

export interface QueryStringBrand {
  readonly QueryString: unique symbol
}
export type QueryString = t.Branded<string, QueryStringBrand>
export const QueryString = t.brand(t.string, (s): s is QueryString => isQueryString.test(s), 'QueryString')

const isNotQueryString = /^[^?]/
export interface NonQueryStringBrand {
  readonly NonQueryString: unique symbol
}
export type NonQueryString = t.Branded<string, NonQueryStringBrand>
export const NonQueryString = t.brand(t.string, (s): s is NonQueryString => isNotQueryString.test(s), 'NonQueryString')
