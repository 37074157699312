/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, select, takeEvery } from 'typed-redux-saga/macro'

import { selectOrgId } from '@/store/selectors/organization/organization.selectors'
import type { MiddlewaresIO } from '@/store/store.utils'
import { get, Network, QueryString, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

import * as actions from './affiliated-network.actions'
import { normalizeAffiliatedNetworks } from './affiliated-network.schema'
import { affiliatedNetworkSlice as slice } from './affiliated-network.slice'

// ---------------------------------------------------------------------------

export function* affiliatedNetworkSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchAffiliatedNetwork.toString(), deferredAction(_fetchAffiliatedNetwork, io))
  yield takeEvery(actions.fetchAffiliatedNetworks.toString(), deferredAction(_fetchAffiliatedNetworks, io))
  yield takeEvery(actions.fetchTotals.toString(), deferredAction(_fetchTotals, io))
  yield takeEvery(actions.postAffiliatedNetworks.toString(), deferredAction(_postAffiliatedNetworks, io))
}

export function* _fetchAffiliatedNetworks(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchAffiliatedNetworks>,
) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.affiliate.getNetworks, queryString)

  if (action.meta.persist) {
    yield* put(slice.actions.replaceAffiliatedNetworks(normalizeAffiliatedNetworks(response.data)))

    yield* put(
      slice.actions.replaceAffiliatedNetworkPagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchAffiliatedNetwork(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchAffiliatedNetwork>) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.affiliate.getNetwork, id)

  yield* put(slice.actions.upsertAffiliatedNetwork(data))

  return data
}

export function* _fetchTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchTotals>) {
  const { total: unfiltered } = yield* call(
    io.api.affiliate.getNetworks,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )

  const { total: unaffiliated } = yield* call(
    io.api.affiliate.getNetworks,
    get(CrudQueryUtils.createUnaffiliatedQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered,
    unaffiliated,
  }

  yield* put(slice.actions.replaceAffiliatedNetworkTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}

export function* _postAffiliatedNetworks(io: MiddlewaresIO, action: ReturnType<typeof actions.postAffiliatedNetworks>) {
  const org_id = yield* select(selectOrgId)

  const networks = action.payload.networks.map((network) => {
    return {
      network: get(network, Network),
      org_id: get(org_id, UUID),
      perspective_id: get(action.payload.perspective?.id, UUID),
    }
  })

  const payload = {
    data: networks,
  }

  const response = yield* call(io.api.affiliate.postNetworks, payload)

  return response
}
