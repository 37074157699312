/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { Response, Server } from 'miragejs'

import { DetectionP } from '@/codecs'
import { get } from '@/utilities/codec'

import { DETECTION_P_URL } from '../constants'

export const detectionPRoutes = (server: Server): void => {
  server.get(DETECTION_P_URL, (schema) => {
    const data = schema.db.detectionP

    const pagination = {
      offset: 0,
      count: data.length,
      total: data.length,
    }

    if (isNotNil(data)) {
      return {
        data,
        ...pagination,
      }
    }

    return new Response(500, {}, { errors: ['Boom'] })
  })

  server.get(`${DETECTION_P_URL}/:id`, (schema, request) => {
    const id = request.params.id

    try {
      const data = get(schema.db.detectionP.find(id), DetectionP)

      return { data }
    } catch {
      return new Response(404, {}, { errors: ['Resource not found'] })
    }
  })
}
