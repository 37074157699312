/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { Response, Server } from 'miragejs'

import { Asset } from '@/codecs'
import { get } from '@/utilities/codec'

import { ASSETS_URL } from '../constants'

export const assetRoutes = (server: Server): void => {
  server.get(ASSETS_URL, (schema) => {
    const data = schema.db.assets

    const pagination = {
      offset: 0,
      count: data.length,
      total: data.length,
    }

    if (isNotNil(data)) {
      return {
        data,
        ...pagination,
      }
    }

    return new Response(500, {}, { errors: ['Yikes'] })
  })

  server.get(`${ASSETS_URL}/:id`, (schema, request) => {
    const id = request.params.id

    try {
      const data = get(schema.db.assets.find(id), Asset)

      return { data }
    } catch {
      return new Response(404, {}, { errors: ['Resource not found'] })
    }
  })
}
