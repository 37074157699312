/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { AddAffiliationModalProps } from '@/components/add-affiliations'
import { AddGlobalSettingsModalProps } from '@/components/add-global-setting'
import { CommentDeleteModalProps } from '@/components/comment'
import { BulkPromoteConfirmViewProps } from '@/components/entity-table-actions/bulk-promote-confirm'
import { AssignImpactModalViewProps } from '@/components/entity-table-actions/confirmation-modal/assign-impact-modal'
import {
  AuthorizeModalViewProps,
  UnauthorizeModalViewProps,
} from '@/components/entity-table-actions/confirmation-modal/authorize-modal'
import { MarkStatusModalViewProps } from '@/components/entity-table-actions/confirmation-modal/mark-status-modal'
import { UnaffiliateConfirmViewProps } from '@/components/entity-table-actions/unaffiliate-confirm'
import { CopyServiceFormProps } from '@/components/global-services-list/copy/copy-service-form'
import { GenericModalProps } from '@/components/modal/generic-modal'
import { CreateOrgModalProps } from '@/components/org-list/create-org-modal'
import { DisableOrgModalProps } from '@/components/org-list/disable-org-modal'
import { ReEnableOrgModalProps } from '@/components/org-list/re-enable-org-modal'
import { AuthorizationPolicyDeleteConfirmViewProps } from '@/components/policy-list/authorization-policy-delete-confirm'
import { AuthorizationPolicyToggleConfirmViewProps } from '@/components/policy-list/authorization-policy-toggle-confirm'
import { PolicyDeleteConfirmViewProps } from '@/components/policy-list/delete-policy-confirm'
import { ExceptionPolicyDeleteConfirmViewProps } from '@/components/policy-list/exception-policy-delete-confirm'
import { DeleteActionConfirmViewProps } from '@/components/runbook-actions-list/runbook-action-delete-confirm'
import { ConfirmCloseModalProps } from '@/components/saved-views'
import { DeleteSavedViewModalProps } from '@/components/saved-views/delete-saved-views-modal'
import { SaveNewViewModalProps } from '@/components/saved-views/save-new-view-modal'
import { EditServiceInfoProps } from '@/components/services-list/edit/edit-service-info--form'
import { CreatingSourceErrorModalProps } from '@/components/source/creating-source-error-modal'
import { CreatingSourceModalProps } from '@/components/source/creating-source-modal'
import { DeleteSourceModalProps } from '@/components/source/delete-source-modal'
import { DownloadInstallerModalProps } from '@/components/source/download-installer-modal'
import { PromoteSuggestionFormProps } from '@/components/suggestions-list/promote-suggestion-form'
import { ScreenshotModalProps } from '@/components/target-thumbnail'
import { PasteServiceFormProps } from '@/components/tpl-copy-paste/tpl-paste-form'
import { StabilityWarningModalProps } from '@/pages/entity-detail/activity-configuration/stability-warning-modal'
import { RawArtifactProps as ArtifactForActivityInstanceProps } from '@/pages/entity-detail/activity-instance/sections/artifacts/raw-artifact'
import { AddToQueueModalProps } from '@/pages/entity-detail/applicable-activity/add-to-queue-modal'
import { DisplayArtifactProps as ArtifactProps } from '@/pages/entity-detail/common-sections/artifacts/display-artifact'
import { NavigationEditProps } from '@/pages/entity-detail/entity-detail--navigation-edit-modal'
import { EditRunbookProps } from '@/pages/entity-detail/runbook/edit'
import { RequestPenTestModalProps } from '@/pages/entity-detail/target/request-pen-test-modal/request-pen-test-modal'
import { CopyApiTokenModalProps } from '@/pages/settings/pane-api/add-api-token-modal'
import { DeleteAPITokenModalProps } from '@/pages/settings/pane-api/delete-api-token-modal'
import { EditOrgInfoProps } from '@/pages/settings/pane-org-info/edit-org-info-modal'
import { DeleteSSOModalProps } from '@/pages/settings/pane-sso/sso-delete-modal'
import { SsoFormNewProps } from '@/pages/settings/pane-sso/sso-form--new'
import { AddDomainModalProps } from '@/pages/settings/pane-team/domain-list'
import { RemoveDomainModalProps } from '@/pages/settings/pane-team/domain-list-item'
import { EditPermissionsProps } from '@/pages/settings/pane-team/edit-permissions-modal'
import { InviteUserModalProps } from '@/pages/settings/pane-team/user-list'
import {
  RemoveUserModalProps,
  ResetPasswordModalProps,
  ResetTOTPModalProps,
} from '@/pages/settings/pane-team/user-list-item'
import { ConfirmPasswordProps } from '@/pages/settings/pane-user-account-info/confirm'
import { EditAccountInfoProps } from '@/pages/settings/pane-user-account-info/edit-account-info-modal'
import { EmbeddedWorkatoConnectionProps } from '@/pages/workato/embedded-connection'
import { WorkatoRecipeConfigurationProps } from '@/pages/workato/recipes/recipe-configuration-flow'
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

type ActiveModalBase = {
  addlClasses?: string | string[]
}

type AddAffiliation = {
  kind: 'add-affiliation'
  childProps: AddAffiliationModalProps
} & ActiveModalBase

type AddApiToken = {
  kind: 'add-api-token'
} & ActiveModalBase

type AddGlobalSetting = {
  kind: 'add-global-setting'
  childProps: AddGlobalSettingsModalProps
} & ActiveModalBase

type AddDomain = {
  kind: 'add-domain'
  childProps: AddDomainModalProps
} & ActiveModalBase

type ConfirmClose = {
  kind: 'confirm-close'
  childProps: ConfirmCloseModalProps
} & ActiveModalBase

type AddToQueue = {
  kind: 'add-to-queue'
  childProps: AddToQueueModalProps
} & ActiveModalBase

type Artifact = {
  kind: 'artifact'
  childProps: ArtifactProps
} & ActiveModalBase

type ArtifactForActivityInstance = {
  kind: 'artifact-for-activity-instance'
  childProps: ArtifactForActivityInstanceProps
} & ActiveModalBase

type AssignImpact = {
  kind: 'assign-impact'
  childProps: AssignImpactModalViewProps
} & ActiveModalBase

type Authorize = {
  kind: 'authorize'
  childProps: AuthorizeModalViewProps
} & ActiveModalBase

type BulkPromoteConfirm = {
  kind: 'bulk-promote-confirm'
  childProps: BulkPromoteConfirmViewProps
} & ActiveModalBase

type CommentDelete = {
  kind: 'comment-delete'
  childProps: CommentDeleteModalProps
} & ActiveModalBase

type ConfirmPassword = {
  kind: 'confirm-password'
  childProps: ConfirmPasswordProps
} & ActiveModalBase

type CopyServiceForm = {
  kind: 'copy-service-form'
  childProps: CopyServiceFormProps
} & ActiveModalBase

type CreateOrg = {
  kind: 'create-org'
  childProps: CreateOrgModalProps
} & ActiveModalBase

type CreatingSource = {
  kind: 'creating-source'
  childProps: CreatingSourceModalProps
} & ActiveModalBase

type CreatingSourceError = {
  kind: 'creating-source-error'
  childProps: CreatingSourceErrorModalProps
} & ActiveModalBase

type DeleteSavedView = {
  kind: 'delete-saved-view'
  childProps: DeleteSavedViewModalProps
} & ActiveModalBase

type DeleteSource = {
  kind: 'delete-source'
  childProps: DeleteSourceModalProps
} & ActiveModalBase

type DisableOrg = {
  kind: 'disable-org'
  childProps: DisableOrgModalProps
} & ActiveModalBase

type DownloadInstaller = {
  kind: 'download-installer'
  childProps: DownloadInstallerModalProps
} & ActiveModalBase

type InviteUser = {
  kind: 'invite-user'
  childProps: InviteUserModalProps
} & ActiveModalBase

type MarkStatus = {
  kind: 'mark-status'
  childProps: MarkStatusModalViewProps
} & ActiveModalBase

type NavigationEdit = {
  kind: 'navigation-edit'
  childProps: NavigationEditProps
} & ActiveModalBase

type PolicyDeleteConfirm = {
  kind: 'policy-delete-confirm'
  childProps: PolicyDeleteConfirmViewProps
} & ActiveModalBase

type PromoteSuggestionForm = {
  kind: 'promote-suggestion-form'
  childProps: PromoteSuggestionFormProps
} & ActiveModalBase

type ReEnableOrg = {
  kind: 're-enable-org'
  childProps: ReEnableOrgModalProps
} & ActiveModalBase

type RemoveDomain = {
  kind: 'remove-domain'
  childProps: RemoveDomainModalProps
} & ActiveModalBase

type RemoveUser = {
  kind: 'remove-user'
  childProps: RemoveUserModalProps
} & ActiveModalBase

type RequestPenTest = {
  kind: 'request-pen-test'
  childProps: RequestPenTestModalProps
} & ActiveModalBase

type ResetPassword = {
  kind: 'reset-password'
  childProps: ResetPasswordModalProps
} & ActiveModalBase

type ResetTOTP = {
  kind: 'reset-totp'
  childProps: ResetTOTPModalProps
} & ActiveModalBase

type SaveNewView = {
  kind: 'save-new-view'
  childProps: SaveNewViewModalProps
} & ActiveModalBase

type Screenshot = {
  kind: 'screenshot'
  childProps: ScreenshotModalProps
} & ActiveModalBase

type SsoFormNew = {
  kind: 'sso-form'
  childProps?: SsoFormNewProps
} & ActiveModalBase

type StabilityWarning = {
  kind: 'stability-warning'
  childProps: StabilityWarningModalProps
} & ActiveModalBase

type CopyApiToken = {
  kind: 'copy-api-token'
  childProps: CopyApiTokenModalProps
} & ActiveModalBase

type PasteService = {
  kind: 'tpl-paste-form'
  childProps: PasteServiceFormProps
} & ActiveModalBase

type UnaffiliateConfirm = {
  kind: 'unaffiliate-confirm'
  childProps: UnaffiliateConfirmViewProps
} & ActiveModalBase

type Unauthorize = {
  kind: 'unauthorize'
  childProps: UnauthorizeModalViewProps
} & ActiveModalBase

type Generic = {
  kind: 'generic'
  childProps: GenericModalProps
} & ActiveModalBase

type WorkatoEmbeddedConnection = {
  kind: 'workato-embedded-connection'
  childProps: EmbeddedWorkatoConnectionProps
} & ActiveModalBase

type WorkatoRecipeConfiguration = {
  kind: 'workato-recipe-configuration'
  childProps: WorkatoRecipeConfigurationProps
} & ActiveModalBase

type DeleteAPIToken = {
  kind: 'delete-api-token'
  childProps: DeleteAPITokenModalProps
} & ActiveModalBase

type DeleteSSO = {
  kind: 'delete-sso'
  childProps: DeleteSSOModalProps
} & ActiveModalBase

type EditAccountInfo = {
  kind: 'edit-account-info'
  childProps: EditAccountInfoProps
} & ActiveModalBase

type EditOrgInfo = {
  kind: 'edit-org-info'
  childProps: EditOrgInfoProps
} & ActiveModalBase

type EditRunbook = {
  kind: 'edit-runbook'
  childProps: EditRunbookProps
} & ActiveModalBase

type EditService = {
  kind: 'service-edit'
  childProps: EditServiceInfoProps
} & ActiveModalBase

type EditUserPermissions = {
  kind: 'edit-user-permissions'
  childProps: EditPermissionsProps
} & ActiveModalBase

type DeleteAction = {
  kind: 'delete-action'
  childProps: DeleteActionConfirmViewProps
} & ActiveModalBase

type AuthorizationPolicyDeleteConfirm = {
  kind: 'authorization-policy-delete-confirm'
  childProps: AuthorizationPolicyDeleteConfirmViewProps
} & ActiveModalBase

type AuthorizationPolicyToggleConfirm = {
  kind: 'authorization-policy-toggle-confirm'
  childProps: AuthorizationPolicyToggleConfirmViewProps
} & ActiveModalBase

type ExceptionPolicyDeleteConfirm = {
  kind: 'exception-policy-delete-confirm'
  childProps: ExceptionPolicyDeleteConfirmViewProps
} & ActiveModalBase

export type ActiveModal =
  | AddAffiliation
  | AddApiToken
  | AddDomain
  | AddGlobalSetting
  | AddToQueue
  | Artifact
  | ArtifactForActivityInstance
  | AssignImpact
  | AuthorizationPolicyDeleteConfirm
  | AuthorizationPolicyToggleConfirm
  | Authorize
  | BulkPromoteConfirm
  | CommentDelete
  | ConfirmClose
  | ConfirmPassword
  | CopyApiToken
  | CopyServiceForm
  | CreateOrg
  | CreatingSource
  | CreatingSourceError
  | DeleteAPIToken
  | DeleteAction
  | DeleteSSO
  | DeleteSavedView
  | DeleteSource
  | DisableOrg
  | DownloadInstaller
  | EditAccountInfo
  | EditOrgInfo
  | EditRunbook
  | EditService
  | EditUserPermissions
  | ExceptionPolicyDeleteConfirm
  | Generic
  | InviteUser
  | MarkStatus
  | NavigationEdit
  | PasteService
  | PolicyDeleteConfirm
  | PromoteSuggestionForm
  | ReEnableOrg
  | RemoveDomain
  | RemoveUser
  | RequestPenTest
  | ResetPassword
  | ResetTOTP
  | SaveNewView
  | Screenshot
  | SsoFormNew
  | StabilityWarning
  | UnaffiliateConfirm
  | Unauthorize
  | WorkatoEmbeddedConnection
  | WorkatoRecipeConfiguration

type ModalState = ActiveModal | null

export const ModalReducer = (state = null as ModalState, action: Store.ActionTypes): ModalState => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SHOW_MODAL:
      return action.payload

    case Store.UIActions.TypeKeys.HIDE_MODAL:
      return null

    default:
      return state
  }
}
