/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

export interface PortBrand {
  readonly Port: unique symbol
}
export type Port = t.Branded<number, PortBrand>
export const Port = t.brand(t.number, (n): n is Port => n >= 0 && n <= 65535, 'Port')
