/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import axios from 'axios'
import debug from 'debug'

import { ClientFactory } from './client-factory'
import { HttpFactoryP } from './http-factory-p'
import type { DefaultHeaders, HttpStatic } from './http.utils'
import { VerbFactory } from './verb-factory'

// ---------------------------------------------------------------------------

const _USE_FETCH_ = true

const log = debug('RANDORI:http-factory window.fetch')

type HttpFactoryConfig = Partial<{
  defaultHeaders: DefaultHeaders
  defaultHttp: HttpStatic
  shouldRetry: boolean
}>

/**
 * Construct an http client bound to a root url
 *
 * @param rootUrl - e.g. https://127.0.0.1:8182
 * @param config - default headers, http constructor, shouldRetry
 *
 * @returns the client
 */
export const HttpFactory = (
  rootUrl: string,
  { defaultHeaders = {}, defaultHttp = axios, shouldRetry = false }: HttpFactoryConfig,
) => {
  const _axios = VerbFactory(
    ClientFactory({
      headers: defaultHeaders,
      http: defaultHttp,
      shouldRetry: shouldRetry,
    })(rootUrl),
  )

  // @TODO: Investigate why rootUrl = '/' violates CSP in whale
  const protocol = window.location.protocol
  const host = window.location.host

  const _rootUrl = rootUrl === '/' ? `${protocol}//${host}` : rootUrl
  const _fetch = HttpFactoryP(_rootUrl, defaultHeaders, window.fetch, shouldRetry).create()

  log(_USE_FETCH_)

  return _USE_FETCH_ ? _fetch : _axios
}
