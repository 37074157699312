/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

export const ACTIVITY_CONFIGURATIONS_URL = 'cms/api/v1/frontend/activity-configurations'
export const AFFILIATE_URL = 'aggregator/api/v1/affiliate/internal/networks'
export const ASSETS_URL = 'recon/api/v2/asset'
export const ATTACK_DISPATCH_URL = 'attack-dispatch/api/v1/implant_config'
export const DETECTION_P_URL = 'recon/api/v1/detection'
export const EXCEPTION_POLICIES_URL = 'cms/api/v1/frontend/exception-policies'
export const HOSTNAMES_V2_URL = 'recon/api/v2/hostname'
export const PERSPECTIVES_URL = 'perspectiveservice/api/v1/perspectives'
export const SOURCES_URL = 'perspectiveservice/api/v1/sources'
export const SUGGESTED_SERVICE_COUNTS_URL = 'aggregator/api/v1/user/suggestedservicecounts'
