/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

import { affiliatedNetworkSlice } from '../slices/affiliated-network'
import { assetSlice } from '../slices/asset'
import { detectionSlice } from '../slices/detection'
import { exceptionPolicySlice } from '../slices/exception-policy'
import { hostnameSlice } from '../slices/hostname'
import { ipSlice } from '../slices/ip'
import { networkSlice } from '../slices/network'
import { perspectiveSlice } from '../slices/perspective'
import { redirectorForSourceSlice } from '../slices/redirectors-for-source/redirector.slice'
import { serviceSlice } from '../slices/service'
import { socialSlice } from '../slices/social'
import { sourceSlice } from '../slices/source'
import { targetSlice } from '../slices/target'
import * as Activity from './activity'
import * as Api from './api'
import * as Attack from './attack'
import Global from './global'
import * as Hoc from './hoc'
import * as Integration from './integration'
import Organization from './organization'
import Preferences from './preferences'
import Recon from './recon'
import Session from './session'
import Tags from './tags'
import * as UI from './ui'
import User from './user'

// ---------------------------------------------------------------------------

const rootReducer = combineReducers({
  activity: Activity.Reducer,
  affiliated_network: affiliatedNetworkSlice.reducer,
  api: Api.Reducer,
  asset: assetSlice.reducer,
  attack: Attack.Reducer,
  authorization: Session.Reducer,
  bdo_detection: detectionSlice.reducer,
  exceptionPolicy: exceptionPolicySlice.reducer,
  global: Global.Reducer,
  hoc: Hoc.Reducer,
  hostname: hostnameSlice.reducer,
  integration: Integration.Reducer,
  ip: ipSlice.reducer,
  network: networkSlice.reducer,
  organization: Organization.Reducer,
  perspective: perspectiveSlice.reducer,
  preferences: Preferences.Reducer,
  recon: Recon.Reducer,
  redirectorForSource: redirectorForSourceSlice.reducer,
  service: serviceSlice.reducer,
  social: socialSlice.reducer,
  source: sourceSlice.reducer,
  tags: Tags.Reducer,
  target: targetSlice.reducer,
  ui: UI.Reducer,
  user: User.Reducer,
})

// @see: https://redux.js.org/recipes/usage-with-typescript/#define-root-state-and-dispatch-types
export default rootReducer
