/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

/**
 * @typeParam T - the entity we're wrapping
 *
 * @param validator - a Codec
 *
 * @remarks
 *
 * Need to get a better method for exposing the TS type. Currently:
 * `type GetDetections = t.TypeOf<typeof GetDetections>`
 * `const GetDetections = rflaskGetMany(DetectionP)`
 * `type DetectionApi = { getDetections: (query: QueryString) => Promise<GetDetections> }`
 *
 * @returns a validator for a plural GET API response
 */
export const rflaskGetMany = <T extends t.Mixed>(validator: T) => {
  return t.type({
    count: t.number,
    offset: t.number,
    total: t.number,
    data: t.array(validator),
  })
}

/**
 * @typeParam T - the entity we're wrapping
 *
 * @param validator - a Codec
 *
 * @returns a validator for a singular GET API response
 */
export const rflaskGetSingle = <T extends t.Mixed>(validator: T) => {
  return t.type({
    data: validator,
  })
}

/**
 * @typeParam T - the entity we're wrapping
 *
 * @param validator - a Codec
 *
 * @returns a validator for a PATCH API response
 */
export const rflaskPatch = <T extends t.Mixed>(validator: T) => {
  return t.type({
    data: validator,
  })
}

/**
 * @returns a validator for a POST API response
 */
export const rflaskPost = () => {
  return t.type({
    ids: t.array(t.string),
  })
}
