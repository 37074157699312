/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { staticFlags } from '@/store/selectors/gate/gate.selectors'

import { AuthoritativeEntityTypes } from '../entity-types'
import { getDisplayByType } from './get-display-by-type'
import { mapTriggerAssetToEntityType } from './trigger-asset-type-to-entity-type'

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Removes the `_org` prefix from an `AuthoritativeEntityTypes` enum value and returns the result.
 *
 * @param entityType - An `AuthoritativeEntityTypes` enum value representing the type of the entity.
 *
 * @param flags - The `staticFlags` object from the `utils/static-flags` module.
 *
 * @returns A string containing the entity type without the `_org` prefix.
 *
 * @throws If the input `entityType` does not have the `_org` prefix.
 */
export const removeOrgPrefixFromEntityType = (
  entityType: AuthoritativeEntityTypes,
  flags: ReturnType<typeof staticFlags>,
) => mapTriggerAssetToEntityType(entityType, flags)

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Gets the displayable version of an authoritative entity type by dropping the `_org` prefix and capitalizing the first letter.
 *
 * @param entityType - An `AuthoritativeEntityTypes` enum value representing the type of the entity.
 *
 * @param flags - The `staticFlags` object from the `utils/static-flags` module.
 *
 * @returns A string containing the displayable version of the entity type.
 */
export const getDisplayByAuthoritativeType = (
  entityType: AuthoritativeEntityTypes,
  flags: ReturnType<typeof staticFlags>,
): string => {
  // first, drop the _org prefix
  const typeWithoutPrefix = removeOrgPrefixFromEntityType(entityType, flags)
  // get displayable type (capitalize first letter)
  const displayableType = getDisplayByType(typeWithoutPrefix)

  return displayableType
}
