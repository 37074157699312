/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { EntityPagination, ServiceP as Service } from '@/codecs'
import { TripleUUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type EntityName = 'service'

export const EntityName: EntityName = 'service'

export type State = typeof initialState

type InitialState = {
  entities: { service: Record<TripleUUID, Service> }
  pagination: EntityPagination
  result: TripleUUID[]
  totals: { unfiltered: number; unaffiliated: number }
}

export const initialState: InitialState = {
  entities: { service: {} },
  pagination: { count: 0, offset: 0, total: 0 },
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },
}
