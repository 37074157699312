/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { Feature } from './feature.codecs'
import { User } from './user.codecs'

// ---------------------------------------------------------------------------

export type Organization = t.TypeOf<typeof Organization>
export const Organization = t.type({
  address: t.union([t.string, t.null]),
  allowed_email_domains: t.array(t.string),
  contact: t.union([t.string, t.null]),
  created_on: CodecUtils.nullable(t.string),
  freeze_time: CodecUtils.nullable(t.string),
  freeze_time_last_update_by: CodecUtils.nullable(t.string),
  freeze_time_last_update_on: CodecUtils.nullable(t.string),
  id: t.string,
  license_level: CodecUtils.nullable(t.string), // @TODO: This should be an enum
  login_methods: CodecUtils.nullable(t.array(t.string)),
  name: t.union([t.string, t.null]),
  paying: t.boolean,
  platform_subscription_id: CodecUtils.nullable(t.string),
  shortname: t.union([t.string, t.null]),
  sso_path: CodecUtils.nullable(t.string),
  stasis: t.boolean,
  stasis_last_update_by: CodecUtils.nullable(t.string),
  stasis_last_update_on: CodecUtils.nullable(t.string),
})

export type PatchOrganizationPayload = {
  data: {
    address?: string
    allowed_email_domains?: string[]
    contact?: string
    name?: string
    login_methods?: string[]
    sso_path?: string
  }
}

export const OrgFeaturesResponse = t.type({ org_features: t.array(Feature) })
export type OrgFeaturesResponse = t.TypeOf<typeof OrgFeaturesResponse>

export type OrgUsersResponse = t.TypeOf<typeof OrgUsersResponse>
export const OrgUsersResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(User),
})

export const PostOrgPayloadRequired = t.type({
  name: t.string,
})

export const PostOrgPayloadOptional = t.partial({
  address: t.string,
  allowed_email_domains: t.array(t.string),
  contact: t.string,
  shortname: t.string,
})

export type PostOrgPayload = t.TypeOf<typeof PostOrgPayload>
export const PostOrgPayload = t.type({
  data: t.intersection([PostOrgPayloadRequired, PostOrgPayloadOptional]),
})

export type PostOrgResponse = t.TypeOf<typeof PostOrgResponse>
export const PostOrgResponse = t.type({
  ids: t.array(t.string),
})
