/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { i18n } from '@/utilities/i18n'

import type { EntityType } from '../entity-types'

export function getHelpTextByType(entityType: EntityType) {
  switch (entityType) {
    case 'topLevelDetection': {
      return i18n.t('helpTextByType.topLevelDetection')
    }

    default:
      return undefined
  }
}
