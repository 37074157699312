/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

import * as actions from './exception-policy.actions'
import { normalizeExceptionPolicies } from './exception-policy.schema'
import { exceptionPolicySlice } from './exception-policy.slice'

// ---------------------------------------------------------------------------

export function* _fetchExceptionPolicies(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchExceptionPolicies>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.exceptionPolicy.getExceptionPolicies, queryString)

  if (action.meta.persist) {
    yield* put(exceptionPolicySlice.actions.replaceExceptionPolicies(normalizeExceptionPolicies(response.data)))
    yield* put(
      exceptionPolicySlice.actions.replaceExceptionPolicyPagination({
        total: response.meta.total,
        offset: response.meta.offset,
        count: response.meta.count,
      }),
    )
  }

  return response
}

export function* _fetchExceptionPolicy(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchExceptionPolicy>) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.exceptionPolicy.getExceptionPolicy, id)

  yield* put(exceptionPolicySlice.actions.upsertExceptionPolicy(data))

  return data
}

export function* _fetchExceptionPolicyConfigurations(
  io: MiddlewaresIO,
  action: ReturnType<typeof actions.fetchExceptionPolicy>,
) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.exceptionPolicy.getExceptionPolicyConfigurations, id)

  return data
}

export function* _fetchExceptionPolicyTotals(
  io: MiddlewaresIO,
  _action: ReturnType<typeof actions.fetchExceptionPolicyTotals>,
) {
  const unfiltered = yield* call(
    io.api.exceptionPolicy.getExceptionPolicies,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered.meta.total,
    unaffiliated: 0,
  }

  yield* put(exceptionPolicySlice.actions.replaceExceptionPolicyTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}

export function* _patchExceptionPolicy(io: MiddlewaresIO, action: ReturnType<typeof actions.patchExceptionPolicy>) {
  const payload = action.payload

  const { data } = yield* call(io.api.exceptionPolicy.patchExceptionPolicy, payload)

  yield* put(exceptionPolicySlice.actions.upsertExceptionPolicy(data))

  return data
}

export function* _postExceptionPolicy(io: MiddlewaresIO, action: ReturnType<typeof actions.postExceptionPolicy>) {
  const payload = action.payload

  const { data } = yield* call(io.api.exceptionPolicy.postExceptionPolicy, payload)

  return data
}

export function* _assignExceptionPolicy(io: MiddlewaresIO, action: ReturnType<typeof actions.assignExceptionPolicy>) {
  const payload = action.payload

  const data = yield* call(io.api.exceptionPolicy.assignExceptionPolicy, payload)

  return data
}

export function* _deleteExceptionPolicy(io: MiddlewaresIO, action: ReturnType<typeof actions.deleteExceptionPolicy>) {
  const id = get(action.payload.id, UUID)

  const data = yield* call(io.api.exceptionPolicy.deleteExceptionPolicy, id)

  yield* put(exceptionPolicySlice.actions.deleteExceptionPolicy(action.payload))

  return data
}

export function* exceptionPolicySagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchExceptionPolicy.toString(), deferredAction(_fetchExceptionPolicy, io))
  yield takeEvery(
    actions.fetchExceptionPolicyConfigurations.toString(),
    deferredAction(_fetchExceptionPolicyConfigurations, io),
  )
  yield takeEvery(actions.fetchExceptionPolicies.toString(), deferredAction(_fetchExceptionPolicies, io))
  yield takeEvery(actions.fetchExceptionPolicyTotals.toString(), deferredAction(_fetchExceptionPolicyTotals, io))
  yield takeEvery(actions.patchExceptionPolicy.toString(), deferredAction(_patchExceptionPolicy, io))
  yield takeEvery(actions.postExceptionPolicy.toString(), deferredAction(_postExceptionPolicy, io))
  yield takeEvery(actions.assignExceptionPolicy.toString(), deferredAction(_assignExceptionPolicy, io))
  yield takeEvery(actions.deleteExceptionPolicy.toString(), deferredAction(_deleteExceptionPolicy, io))
}
