/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import {
  IpP as Ip,
  IpForHostnameP as IpForHostname,
  IpForNetworkP as IpForNetwork,
  IpForServiceP as IpForService,
} from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, rflaskGetSingle, throwValidate, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type IpApi = {
  getIp: (id: UUID) => Promise<GetIp>
  getIps: (query: QueryString | '') => Promise<GetIps>
  getIpsForHostname: (query: QueryString | '') => Promise<GetIpsForHostname>
  getIpsForNetwork: (query: QueryString | '') => Promise<GetIpsForNetwork>
  getIpsForService: (query: QueryString | '') => Promise<GetIpsForService>
}

export function ipApiFactory(http: HttpClient): IpApi {
  const baseUrl = '/recon/api/v2/ip'

  return {
    getIp(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetIp, 'GetIp'))
    },

    getIps(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetIps, 'GetIps'))
    },

    getIpsForHostname(query) {
      return http
        .get(`/recon/api/v2/ips-for-hostname${query}`)
        .then(throwValidate(GetIpsForHostname, 'GetIpsForHostname'))
    },

    getIpsForService(query) {
      return http.get(`/recon/api/v2/ips-for-service${query}`).then(throwValidate(GetIpsForService, 'GetIpsForService'))
    },

    getIpsForNetwork(query) {
      return http.get(`/recon/api/v2/ips-for-network${query}`).then(throwValidate(GetIpsForNetwork, 'GetIpsForNetwork'))
    },
  }
}

export type GetIp = t.TypeOf<typeof GetIp>
export const GetIp = rflaskGetSingle(Ip)

export type GetIps = t.TypeOf<typeof GetIps>
export const GetIps = rflaskGetMany(Ip)

export type GetIpsForHostname = t.TypeOf<typeof GetIpsForHostname>
export const GetIpsForHostname = rflaskGetMany(IpForHostname)

export type GetIpsForService = t.TypeOf<typeof GetIpsForService>
export const GetIpsForService = rflaskGetMany(IpForService)

export type GetIpsForNetwork = t.TypeOf<typeof GetIpsForNetwork>
export const GetIpsForNetwork = rflaskGetMany(IpForNetwork)
