/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable  */
// @ts-nocheck
import debug from 'debug'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import * as EmbedUtils from '../embed.utils'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:segment')

export const SegmentEmbed: React.FC = () => {
  const [isAllowed] = EmbedUtils.useKlaroWatch('segment', true)
  const _SEGMENT_ = 'Y6w6Czac329uEfc6QCbhwb7BOtaih6Gs'

  if (isAllowed) {
    log('enabled')

    return (
      <Helmet>
        <script>
          {(function () {
            var i = 'analytics',
              analytics = (window[i] = window[i] || [])

            if (!analytics.initialize)
              if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
              else {
                analytics.invoked = !0
                analytics.methods = [
                  'trackSubmit',
                  'trackClick',
                  'trackLink',
                  'trackForm',
                  'pageview',
                  'identify',
                  'reset',
                  'group',
                  'track',
                  'ready',
                  'alias',
                  'debug',
                  'page',
                  'screen',
                  'once',
                  'off',
                  'on',
                  'addSourceMiddleware',
                  'addIntegrationMiddleware',
                  'setAnonymousId',
                  'addDestinationMiddleware',
                  'register',
                ]
                analytics.factory = function (e) {
                  return function () {
                    if (window[i].initialized) return window[i][e].apply(window[i], arguments)
                    var n = Array.prototype.slice.call(arguments)
                    if (['track', 'screen', 'alias', 'group', 'page', 'identify'].indexOf(e) > -1) {
                      var c = document.querySelector("link[rel='canonical']")
                      n.push({
                        __t: 'bpc',
                        c: (c && c.getAttribute('href')) || void 0,
                        p: location.pathname,
                        u: location.href,
                        s: location.search,
                        t: document.title,
                        r: document.referrer,
                      })
                    }
                    n.unshift(e)
                    analytics.push(n)
                    return analytics
                  }
                }
                for (var n = 0; n < analytics.methods.length; n++) {
                  var key = analytics.methods[n]
                  analytics[key] = analytics.factory(key)
                }
                analytics.load = function (key, n) {
                  var t = document.createElement('script')
                  t.type = 'text/javascript'
                  t.async = !0
                  t.setAttribute('data-global-segment-analytics-key', i)
                  t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
                  var r = document.getElementsByTagName('script')[0]
                  r.parentNode.insertBefore(t, r)
                  analytics._loadOptions = n
                }
                analytics._writeKey = 'YOUR_API_KEY'
                analytics.SNIPPET_VERSION = '5.2.0'
                analytics.load(_SEGMENT_)
                analytics.page()
              }
          })()}
        </script>
      </Helmet>
    )
  } else {
    log('noop')

    return null
  }
}
