/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

import * as actions from './network.actions'
import { normalizeNetworks } from './network.schema'
import { networkSlice } from './network.slice'

// ---------------------------------------------------------------------------

export function* networkSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchNetwork.toString(), deferredAction(_fetchNetwork, io))
  yield takeEvery(actions.fetchNetworkTotals.toString(), deferredAction(_fetchNetworkTotals, io))
  yield takeEvery(actions.fetchNetworks.toString(), deferredAction(_fetchNetworks, io))
}

export function* _fetchNetworks(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchNetworks>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.network.getNetworks, queryString)

  if (action.meta.persist) {
    yield* put(networkSlice.actions.replaceNetworks(normalizeNetworks(response.data)))

    yield* put(
      networkSlice.actions.replaceNetworkPagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchNetwork(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchNetwork>) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.network.getNetwork, id)

  yield* put(networkSlice.actions.upsertNetwork(data))

  return data
}

export function* _fetchNetworkTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchNetworkTotals>) {
  const { total: unfiltered } = yield* call(
    io.api.network.getNetworks,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )

  const { total: unaffiliated } = yield* call(
    io.api.network.getNetworks,
    get(CrudQueryUtils.createUnaffiliatedQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered,
    unaffiliated,
  }

  yield* put(networkSlice.actions.replaceNetworkTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}
