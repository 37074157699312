/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ActivityReport } from '@/codecs'
import { get, UUID } from '@/utilities/codec'

import __ATTACK_OPPORTUNISTIC__ from './attack-opportunistic.json'
import __ROW__ from './row.json'

// -----------------------------------------------------------------------------

export const getActivityReport = () => {
  return get(__ATTACK_OPPORTUNISTIC__, ActivityReport)
}

const _makeList = () => {
  let count = 0
  const records: Array<typeof __ROW__> = []
  while (count <= 10) {
    records.push(__ROW__)
    count = count + 1
  }

  const date = new Date(Date.now())

  date.setMonth(date.getMonth() - 1)

  return {
    org_id: get('c1ad8a6a-9aa7-4858-81a8-bbf856235944', UUID),
    attack_oppertunistic: {
      [date.toString()]: records,
    },
  }
}
