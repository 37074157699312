/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs/activity'
import { UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  ENTITY_FOR_ACTIVITY_INSTANCE_FETCH = 'ENTITY_FOR_ACTIVITY_INSTANCE_FETCH', // may not need
  ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH = 'ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH',
  ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE = 'ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE',
}

export type StateActionTypes =
  | ENTITY_FOR_ACTIVITY_INSTANCE_FETCH
  | ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH
  | ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE

export type ENTITY_FOR_ACTIVITY_INSTANCE_FETCH = {
  type: TypeKeys.ENTITY_FOR_ACTIVITY_INSTANCE_FETCH
  meta: { deferred: Deferred }
  payload: {
    response: Codecs.EntityForActivityInstance
  }
}

export function ENTITY_FOR_ACTIVITY_INSTANCE_FETCH(
  payload: ENTITY_FOR_ACTIVITY_INSTANCE_FETCH['payload'],
  deferred: Deferred,
): ENTITY_FOR_ACTIVITY_INSTANCE_FETCH {
  return {
    type: TypeKeys.ENTITY_FOR_ACTIVITY_INSTANCE_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH = {
  type: TypeKeys.ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: UUID
    options?: CrudQueryUtils.ReconQuery
  }
}

export function ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH(
  payload: ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH['payload'],
  deferred: Deferred,
): ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH {
  return {
    type: TypeKeys.ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE = {
  type: TypeKeys.ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE
  payload: Codecs.EntityForActivityInstanceResponse
  meta: {
    forActivityInstance: UUID
  }
}

export function ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE(
  payload: ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE['payload'],
  forActivityInstance: ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE['meta']['forActivityInstance'],
): ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE {
  return {
    type: TypeKeys.ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE,
    payload,
    meta: {
      forActivityInstance,
    },
  }
}
