/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// eslint-disable-next-line @typescript-eslint/ban-types
type _empty = {}
// type _empty = Record<string, never>

const _is = (u: unknown): u is _empty => t.type({}).is(u) && Object.keys(u).length === 0

export const EmptyObject = new t.Type<_empty, _empty, unknown>(
  'EmptyObject',
  _is,
  (u, c) => (_is(u) ? t.success(u) : t.failure(u, c)),
  t.identity,
)
