/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { connect } from 'react-redux'

import { AppState, GateSelectors } from '@/store'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

type WithFeaturesAndPermissionsAndStateOwnProps = {
  features?: string[]
  permissions?: string[]
  selectors?: ((state: AppState) => boolean)[]
}

const _withFeaturesAndPermissionsAndState = (state: AppState, ownProps: WithFeaturesAndPermissionsAndStateOwnProps) => {
  const _permissions = isNotNil(ownProps.permissions) ? ownProps.permissions : []
  const _features = isNotNil(ownProps.features) ? ownProps.features : []

  const selectedStates = isNotNil(ownProps.selectors) ? ownProps.selectors.map((selector) => selector(state)) : []

  const permissions = _permissions.map(GateSelectors.hasPermission(state))
  const features = _features.map(GateSelectors.hasFeatureFlag(state))

  return {
    isEnabled: [...features, ...permissions, ...selectedStates].every(Boolean),
  }
}

export const withFeaturesAndPermissionsAndState = connect(_withFeaturesAndPermissionsAndState)

export const Test = {
  _withFeaturesAndPermissionsAndState,
}
