/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil, isNotNilOrEmpty } from '@randori/rootkit'
import { throttle } from 'lodash/fp'
import * as React from 'react'

import * as Store from '@/store'
import {
  EntityDetailNavigationItem as EntityDetailNavigationItemType,
  keyOfInitialState,
} from '@/store/reducers/ui/entity-detail.reducers'
import * as CodecUtils from '@/utilities/codec'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export const SCROLL_THROTTLE_VALUE = 100
export const TOP_OFFSET_MAX = 400
export const TOP_OFFSET_MIN = TOP_OFFSET_MAX - 50

export const isInView = (
  offsets: { top: undefined | number; bottom: undefined | number },
  max: number,
  min: number,
) => {
  const _inOffsetRange = (offset: number) => offset < max && offset > min

  return (
    isNotNil(offsets.top) && isNotNil(offsets.bottom) && (_inOffsetRange(offsets.top) || _inOffsetRange(offsets.bottom))
  )
}

export const useNavigationScroll = (
  navItem: EntityDetailNavigationItemType,
  _entityType: EntityUtils.EntityType,
  childNavItem: { id: string; label: string } | null = null,
) => {
  const entityType = CodecUtils.throwValidate(keyOfInitialState, 'keyOfInitialState')(_entityType)

  const dispatch = Store.useAppDispatch()

  const updateNav = () => {
    dispatch(
      Store.UIActions.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY({
        activeItems: {
          parentId: navItem.id,
          childId: isNotNilOrEmpty(childNavItem) ? childNavItem.id : null,
        },
        entityType,
        priority: Store.UIActions.entityDetailActiveNavPriority.scroll,
      }),
    )
  }

  const _handleScroll = () => {
    const _fallback = { top: undefined, bottom: undefined }
    const offsets = document.getElementById(navItem.id)?.getBoundingClientRect() ?? _fallback
    const isInBottomPane = document.getElementsByClassName('modal-inspect-pane--l-bottom').length !== 0

    const body = document.body
    const html = document.documentElement
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    )

    const max = isInBottomPane ? height / 2 + 200 : TOP_OFFSET_MAX
    const min = isInBottomPane ? max - 50 : TOP_OFFSET_MIN

    if (isNotNilOrEmpty(childNavItem)) {
      const _childOffsets = document.getElementById(childNavItem.id)?.getBoundingClientRect() ?? _fallback

      // With the current implementation of guidance, the bottom of the
      // previous guidance article equals the top of the next guidance
      // article, resulting in wonky nav updates. The "-50" on the bottom
      // value is meant to prevent these issues to create space and avoid
      // overlap in nav updates.
      const childOffsets = {
        bottom: isNotNil(_childOffsets.bottom) ? _childOffsets.bottom - 50 : undefined,
        top: _childOffsets.top,
      }

      if (isInView(childOffsets, max, min)) {
        updateNav()
      }
    } else if (isInView(offsets, max, min)) {
      updateNav()
    }
  }

  const handleScroll = throttle(SCROLL_THROTTLE_VALUE, _handleScroll)

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { capture: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })
}
