/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DetectionCriteria } from './detection-criteria.codecs'
import { EnrichedData } from './enriched-data.codecs'
import { LensView, Perspective } from './perspective.codecs'

// ---------------------------------------------------------------------------
export const CharacteristicRuleMetadata = t.interface({
  id: t.string,

  affiliation: t.number,
  affiliation_display: t.number,
  affiliation_override: t.boolean,
  artifact_id: t.string,
  artifact_type: t.number,
  confidence: t.number,
  confidence_display: t.number,
  confidence_override: t.boolean,
  creator_type: t.string,
  creator_uuid: t.string,
  data_hash: t.string,
  deleted: t.boolean,
  detection_criteria: DetectionCriteria,
  enriched_data: EnrichedData,
  first_time: t.string,
  flags: t.number,
  lens_id: t.string,
  lens_view: t.union([LensView, t.null]),
  org_id: t.string,
  perspective: Perspective,
  rule_id: t.string,
  src: t.string,
  src_type: t.string,
  time: t.string,
  type: t.string,
  work_type: t.string,
})
export type CharacteristicRuleMetadata = t.TypeOf<typeof CharacteristicRuleMetadata>

export const CharacteristicRuleMetadataResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(CharacteristicRuleMetadata),
})
export type CharacteristicRuleMetadataResponse = t.TypeOf<typeof CharacteristicRuleMetadataResponse>
