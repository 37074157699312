/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DecodedSession } from './session.codecs'

// ---------------------------------------------------------------------------

export const User = t.interface({
  created_on: t.union([t.string, t.null]),
  email: t.union([t.string, t.null]),
  id: t.string,
  last_login: t.union([t.string, t.null]),
  lock_expiry: t.union([t.string, t.null]),
  lock_reason: t.union([t.string, t.null]),
  locked: t.boolean,
  login_type: t.union([t.string, t.null]),
  name: t.union([t.string, t.null]),
  password_failures: t.union([t.number, t.null]),
  title: t.union([t.string, t.null]),
  totp_failures: t.union([t.number, t.null]),
  username: t.union([t.string, t.null]),
  view_org: t.union([t.string, t.null]),
})
export type User = t.TypeOf<typeof User>

export const FetchUserResponse = t.interface({
  count: t.number,
  data: t.array(User),
  offest: t.number,
  total: t.number,
})
export type FetchUserResponse = t.TypeOf<typeof FetchUserResponse>

export const RenewResponse = t.interface({
  Authorization: t.string,
})
export type RenewResponse = t.TypeOf<typeof RenewResponse>

export const LoginResponse = RenewResponse
export type LoginResponse = t.TypeOf<typeof LoginResponse>

export type RemoveUserPayload = {
  target_org_id: string
  target_user_id: string
}

export type InviteUserPayload = {
  name: string
  perm_groups: string[]
  user_email: string
  target_org_id?: string
}

export const InviteUserResponse = t.interface({})
export type InviteUserResponse = t.TypeOf<typeof InviteUserResponse>

export type ResetUserPasswordPayload = { userid: string }

export const ResetUserPasswordResponse = t.interface({ userid: t.string })
export type ResetUserPasswordResponse = t.TypeOf<typeof ResetUserPasswordResponse>

export type UpdateUserPayload = {
  email?: string | null
  id: string
  name?: string | null
  password?: string
  title?: string | null
  view_org?: string | null
}

export const ValidateJWTResponse = DecodedSession
export type ValidateJWTResponse = t.TypeOf<typeof ValidateJWTResponse>
