/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type AttackApi = {
  getAttackActions: (query: string) => Promise<Codecs.AttackActionResponse>
  getCallbacksForImplant: (query: string) => Promise<Codecs.ImplantCallbacksResponse>
  getImplant: (id: string) => Promise<Codecs.ImplantResponse>
  getImplantStats: (query: string) => Promise<Codecs.ImplantStatsResponse>
  getImplants: (query: string) => Promise<Codecs.ImplantsResponse>
  getInterfacesForImplant: (query: string) => Promise<Codecs.ImplantInterfacesResponse>
  getRedirectors: (query: string) => Promise<Codecs.RedirectorsResponse>
  // used for Sources v0, allows those with manage internal to see redirector ip without having full access to redirectors from ^
  getRedirectorsForSource: () => Promise<Codecs.RedirectorsForSourceResponse>
  getRunbookDescription: (query: string) => Promise<Codecs.RunbookDescriptionResponse>
  getRunbooks: (query: string) => Promise<Codecs.RunbooksResponse>
  patchAction: (id: string, payload: Codecs.ApAttackActionPatchPayload) => Promise<Codecs.ApAttackActionPatchResponse>
  patchRunbook: (id: string, payload: Codecs.ApRunbookPatchPayload) => Promise<Codecs.ApRunbookPatchResponse>
  postRunbookDescription: (payload: Codecs.RunbookDescriptionPayload) => Promise<Codecs.RunbookDescriptionResponse>
  // for internal v0, this is used for create source, but in the future there are plans to call different services depending on the source type
  postImplant: (payload: Codecs.CreateImplantPayload) => Promise<Codecs.CreateImplantResponse>
  putImplant: (payload: { id: CodecUtils.UUID; dns_override: string }) => Promise<void>
  deleteImplant: (id: string) => Promise<void>
}

export function attackApiFactory(http: HttpClient): AttackApi {
  const baseUrl = '/attack/api/v1/user'
  const attackDispatchBaseUrl = '/attack-dispatch/api/v1'

  return {
    getImplantStats(query) {
      return http
        .get(`${baseUrl}/statistics?${query}`)
        .then(CodecUtils.throwValidate(Codecs.ImplantStatsResponse, 'ImplantStatsResponse'))
    },

    getImplants(query) {
      return http
        .get(`${baseUrl}/implants${query}`)
        .then(CodecUtils.throwValidate(Codecs.ImplantsResponse, 'ImplantsResponse'))
    },

    getImplant(id) {
      return http
        .get(`${baseUrl}/implants/${id}`)
        .then(CodecUtils.throwValidate(Codecs.ImplantResponse, 'ImplantResponse'))
    },

    getCallbacksForImplant(query) {
      return http
        .get(`${baseUrl}/checkins-for-implant${query}`)
        .then(CodecUtils.throwValidate(Codecs.ImplantCallbacksResponse, 'ImplantCallbacksResponse'))
    },

    getInterfacesForImplant(query) {
      return http
        .get(`${baseUrl}/interfaces-for-implant${query}`)
        .then(CodecUtils.throwValidate(Codecs.ImplantInterfacesResponse, 'ImplantInterfacesResponse'))
    },

    getRedirectors(query) {
      return http
        .get(`${baseUrl}/redirectors${query}`)
        .then(CodecUtils.throwValidate(Codecs.RedirectorsResponse, 'RedirectorsResponse'))
    },

    getRedirectorsForSource() {
      return http
        .get(`${attackDispatchBaseUrl}/redirectors`)
        .then(CodecUtils.throwValidate(Codecs.RedirectorsForSourceResponse, 'RedirectorsForSourceResponse'))
    },

    getRunbooks(query) {
      return http
        .get(`${baseUrl}/runbooks${query}`)
        .then(CodecUtils.throwValidate(Codecs.RunbooksResponse, 'RunbookResponse'))
    },

    getRunbookDescription(query) {
      return http
        .get(`${baseUrl}/attack-runbook-descriptions?${query}`)
        .then(CodecUtils.throwValidate(Codecs.RunbookDescriptionResponse, 'RunbookDescriptionResponse'))
    },

    postRunbookDescription(payload) {
      return http.post(`${baseUrl}/attack-runbook-descriptions`, payload)
    },

    getAttackActions(query) {
      return http
        .get(`${baseUrl}/actions${query}`)
        .then(CodecUtils.throwValidate(Codecs.AttackActionResponse, 'AttackActionResponse'))
    },

    patchRunbook(id, payload) {
      return http
        .patch(`${baseUrl}/ap-runbook-instance/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.ApRunbookPatchResponse, 'ApRunbookPatchResponse'))
    },

    patchAction(id, payload) {
      return http
        .patch(`${baseUrl}/ap-action-instance/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.ApAttackActionPatchResponse, 'ApAttackActionPatchResponse'))
    },

    postImplant(payload) {
      return http
        .post(`${attackDispatchBaseUrl}/implant_config`, payload)
        .then(CodecUtils.throwValidate(Codecs.CreateImplantResponse, 'CreateImplantResponse'))
    },

    // v0 edit dns
    putImplant(payload) {
      const { id, dns_override: override } = payload
      const editDNSPayload = {
        'override.dns.servers[0]': override,
      }

      return http.put(`${attackDispatchBaseUrl}/implant_config/${id}`, editDNSPayload)
    },

    deleteImplant(id) {
      return http.delete(`${attackDispatchBaseUrl}/implant_config/${id}`)
    },
  }
}
