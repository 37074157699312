/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

export const scrollTo = (anchor: string, validIds: string[] | readonly string[], byClass = false) => {
  if (!anchor || !validIds.includes(anchor)) {
    return
  }

  const element = byClass ? document.getElementsByClassName('graph')[0] : document.getElementById(anchor)

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
