/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'

import { withFeaturesAndPermissions } from './with-features-and-permissions'
import { withPredicate } from './with-predicate'

// ---------------------------------------------------------------------------

type GateViewProps = {
  render: ({ isEnabled }: { isEnabled: boolean }) => React.ReactNode
  isEnabled: boolean
}

class GateView extends React.Component<GateViewProps> {
  render() {
    return this.props.render({ isEnabled: this.props.isEnabled })
  }
}

export const FPGate = withFeaturesAndPermissions(GateView)
export const Gate = withPredicate(GateView)

export const Test = {
  GateView,
}
