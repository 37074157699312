/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { capitalize } from 'lodash/fp'

import type { EntityType } from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

/**
 * @param headers -
 * @returns formatted CSV column headers
 */
export const formatHeaders = (headers: string[], kind: EntityType) => {
  return headers.map((header) => {
    switch (header) {
      case 'validated_vulnerabilities_target':
        return 'Validated Vulnerabilities'

      case 'validated_vulnerabilities_target_count':
        return 'Validated Vulnerabilities Count'

      case 'validated_vulnerabilities_detection':
        return 'Validated Vulnerabilities'

      case 'validated_vulnerabilities_detection_count':
        return 'Validated Vulnerabilities Count'

      case 'detection_authorization_state':
        return 'Authorization State'

      case 'target_authorization_state':
        return 'Authorization State'

      case 'user-tags':
        return 'Tags'

      case 'characteristic_tags':
        return 'Characteristics'

      case 'target_temptation':
        return 'Temptation'

      case 'user_tags':
        return 'Tags'

      case 'target_num_detections':
        return 'Detections'

      case 'banners_uuid':
        return 'Banner UUID'

      case 'certs_uuid':
        return 'Certificate UUID'

      case 'detection_uuid':
        return 'Detection UUID'

      case 'cpe':
        return 'CPE'

      case 'priority_score':
        return 'Priority'

      case 'instance_count':
        return kind === 'service' ? 'Service Count' : header

      case 'description':
        return kind === 'topLevelDetection' || 'target' ? 'Service Description' : header

      case 'name':
        return kind === 'topLevelDetection' || 'target' ? 'Service Name' : header

      case 'vendor':
        return kind === 'topLevelDetection' || 'target' ? 'Service Vendor' : header

      case 'version':
        return kind === 'topLevelDetection' || 'target' ? 'Service Version' : header

      case 'ip_str':
        return 'IP'

      case 'network_str':
        return 'Network'

      case 'detection_criteria':
        return 'Location'

      case 'id': {
        if (kind === 'target') {
          return 'Target ID'
        }

        return 'ID'
      }

      default: {
        const _header = header
          .split('_')
          .map(capitalize)
          .join(' ')
          .replace(/\bId\b/g, 'ID')
          .replace(/\bIp\b/g, 'IP')

        return _header
      }
    }
  })
}
