/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

import * as actions from './asset.actions'
import { normalizeAssets } from './asset.schema'
import { assetSlice } from './asset.slice'

// ---------------------------------------------------------------------------

export function* assetSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchAsset.toString(), deferredAction(_fetchAsset, io))
  yield takeEvery(actions.fetchAssets.toString(), deferredAction(_fetchAssets, io))
  yield takeEvery(actions.fetchAssetTotals.toString(), deferredAction(_fetchAssetTotals, io))
}

export function* _fetchAssets(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchAssets>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.asset.getAssets, queryString)

  if (action.meta.persist) {
    yield* put(assetSlice.actions.updateAssets(normalizeAssets(response.data)))
    yield* put(
      assetSlice.actions.updateAssetPagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchAsset(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchAsset>) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.asset.getAsset, id)

  yield* put(assetSlice.actions.updateAsset(data))

  return data
}

export function* _fetchAssetTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchAssetTotals>) {
  const { total: unfiltered } = yield* call(
    io.api.asset.getAssets,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )
  const { total: unaffiliated } = yield* call(
    io.api.asset.getAssets,
    get(CrudQueryUtils.createUnaffiliatedQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered,
    unaffiliated,
  }

  yield* put(assetSlice.actions.updateAssetTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}
