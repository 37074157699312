/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { CarbonIconKind } from '@randori/rootkit'

// ---------------------------------------------------------------------------

export const EntityDetailNavId = [
  'actions',
  'activityLog',
  'applicableActivities',
  'applicableTargets',
  'artifacts',
  'attackersPerspective',
  'callbacks',
  'comments',
  'configuration',
  'consolidatedTargets',
  'details',
  'detections',
  'discoveryPath',
  'guidance',
  'matchingActivities',
  'matchingEntities',
  'profiles',
  'relatedAssets',
  'relatedHostnames',
  'relatedIps',
  'relatedPorts',
  'relatedTargets',
  'resultArtifacts',
] as const

export type EntityDetailNavId = (typeof EntityDetailNavId)[number]

export type EntityDetailNavigationItem = {
  icon: CarbonIconKind
  id: EntityDetailNavId
  label: string
}

export const attackersPerspectiveNavItem: EntityDetailNavigationItem = {
  icon: 'attackers-perspective',
  id: 'attackersPerspective',
  label: "Attacker's Perspective",
}

export const guidanceNavItem: EntityDetailNavigationItem = {
  icon: 'guidance',
  id: 'guidance',
  label: 'Guidance',
}

export const artifactsNavItem: EntityDetailNavigationItem = {
  icon: 'artifacts',
  id: 'artifacts',
  label: 'Artifacts',
}

export const relatedDetectionsNavItem: EntityDetailNavigationItem = {
  icon: 'detections',
  id: 'detections',
  label: 'Related Detections',
}

export const commentsNavItem: EntityDetailNavigationItem = {
  icon: 'comment',
  id: 'comments',
  label: 'Comments',
}

export const relatedTargetsNavItem: EntityDetailNavigationItem = {
  icon: 'targets',
  id: 'relatedTargets',
  label: 'Related Targets',
}

export const relatedIpsNavItem: EntityDetailNavigationItem = {
  icon: 'ips',
  id: 'relatedIps',
  label: 'Related IPs',
}

export const discoveryPathNavItem: EntityDetailNavigationItem = {
  icon: 'discovery-path',
  id: 'discoveryPath',
  label: 'Discovery Path',
}

export const detailsNavItem: EntityDetailNavigationItem = {
  icon: 'carbon-details',
  id: 'details',
  label: 'Details',
}

export const detectionsNavItem: EntityDetailNavigationItem = {
  icon: 'detections',
  id: 'detections',
  label: 'Detections',
}

export const relatedHostnamesNavItem: EntityDetailNavigationItem = {
  icon: 'hostnames',
  id: 'relatedHostnames',
  label: 'Related Hostnames',
}

export const relatedPortsNavItem: EntityDetailNavigationItem = {
  icon: 'ports',
  id: 'relatedPorts',
  label: 'Related Ports',
}

export const configurationNavItem: EntityDetailNavigationItem = {
  icon: 'configuration',
  id: 'configuration',
  label: 'Configuration',
}

export const matchingEntitiesNavItem: EntityDetailNavigationItem = {
  icon: 'matching-entities',
  id: 'matchingEntities',
  label: 'Matching Entities',
}

export const profilesNavItem: EntityDetailNavigationItem = {
  icon: 'profiles',
  id: 'profiles',
  label: 'Profiles',
}

export const callbacksNavItem: EntityDetailNavigationItem = {
  icon: 'callbacks',
  id: 'callbacks',
  label: 'Callbacks',
}

export const actionsNavItem: EntityDetailNavigationItem = {
  icon: 'runbook-actions',
  id: 'actions',
  label: 'Runbook Actions',
}

export const relatedAssetsNavItem: EntityDetailNavigationItem = {
  icon: 'runbook-actions',
  id: 'relatedAssets',
  label: 'Discovered Entities',
}

export const resultArtifactsNavItem: EntityDetailNavigationItem = {
  icon: 'repo-artifact',
  id: 'resultArtifacts',
  label: 'Result Artifacts',
}

export const activityLogNavItem: EntityDetailNavigationItem = {
  icon: 'movement',
  id: 'activityLog',
  label: 'Activity Log',
}

export const applicableActivitiesNavItem: EntityDetailNavigationItem = {
  icon: 'catalog',
  id: 'applicableActivities',
  label: 'Applicable Activities',
}

export const applicableTargetsNavItem: EntityDetailNavigationItem = {
  icon: 'targets',
  id: 'applicableTargets',
  label: 'Applicable Targets',
}

export const matchingActivitiesNavItem: EntityDetailNavigationItem = {
  icon: 'matching-entities',
  id: 'matchingActivities',
  label: 'Matching Activities',
}

export const consolidatedTargetsNavItem: EntityDetailNavigationItem = {
  icon: 'targets',
  id: 'consolidatedTargets',
  label: 'Consolidated Targets',
}

export const initDetections = [
  detailsNavItem,
  attackersPerspectiveNavItem,
  artifactsNavItem,
  relatedDetectionsNavItem,
  guidanceNavItem,
  commentsNavItem,
]

export const initHostnames = [
  detailsNavItem,
  attackersPerspectiveNavItem,
  relatedTargetsNavItem,
  relatedIpsNavItem,
  discoveryPathNavItem,
  activityLogNavItem,
  guidanceNavItem,
  commentsNavItem,
]

export const initNetworks = [
  detailsNavItem,
  attackersPerspectiveNavItem,
  relatedTargetsNavItem,
  relatedIpsNavItem,
  discoveryPathNavItem,
  activityLogNavItem,
  guidanceNavItem,
  commentsNavItem,
]

export const initTarget = [
  detailsNavItem,
  attackersPerspectiveNavItem,
  detectionsNavItem,
  discoveryPathNavItem,
  applicableActivitiesNavItem,
  activityLogNavItem,
  guidanceNavItem,
  commentsNavItem,
]

export const initSavedView = [detailsNavItem, commentsNavItem]

export const initSocial = [
  detailsNavItem,
  attackersPerspectiveNavItem,
  activityLogNavItem,
  guidanceNavItem,
  commentsNavItem,
]

export const initSource = [detailsNavItem, guidanceNavItem]

export const initAsset = [detailsNavItem, activityLogNavItem]

export const initService = [
  detailsNavItem,
  attackersPerspectiveNavItem,
  relatedTargetsNavItem,
  guidanceNavItem,

  // TODO: services cannot currently be commented on
  // commentsNavItem,
]

export const initIp = [
  detailsNavItem,
  attackersPerspectiveNavItem,
  relatedTargetsNavItem,
  relatedHostnamesNavItem,
  relatedPortsNavItem,
  discoveryPathNavItem,
  activityLogNavItem,
  guidanceNavItem,
  commentsNavItem,
]

export const initPolicy = [detailsNavItem, configurationNavItem, matchingEntitiesNavItem, commentsNavItem]

export const initImplant = [detailsNavItem, profilesNavItem, callbacksNavItem, commentsNavItem]

export const initRedirector = [detailsNavItem, commentsNavItem]

export const initRunbook = [detailsNavItem, actionsNavItem, guidanceNavItem, commentsNavItem]

export const initActivityConfiguration = [
  attackersPerspectiveNavItem,
  detailsNavItem,
  applicableTargetsNavItem,
  activityLogNavItem,
]

export const initActivityInstance = [
  attackersPerspectiveNavItem,
  detailsNavItem,
  resultArtifactsNavItem,
  relatedAssetsNavItem,
]

export const initAuthorizationPolicy = [detailsNavItem, configurationNavItem, matchingEntitiesNavItem, commentsNavItem]

export const initDetectionsP = [
  detailsNavItem,
  consolidatedTargetsNavItem,
  attackersPerspectiveNavItem,
  artifactsNavItem,
  discoveryPathNavItem,
  activityLogNavItem,
  commentsNavItem,
]

export const initExceptionPolicy = [detailsNavItem, configurationNavItem, matchingActivitiesNavItem]
