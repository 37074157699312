/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { nullable, nullableP } from '@/utilities/codec'

// ---------------------------------------------------------------------------

const CPE_VERSION = nullableP(t.string)
const CPE_PART = nullableP(t.string)

export type CPE = t.TypeOf<typeof CPE>
export const CPE = nullable(
  t.type({
    cpe_version: CPE_VERSION,
    edition: nullableP(t.string),
    language: nullableP(t.string),
    other: nullableP(t.string),
    part: CPE_PART,
    product: nullableP(t.string),
    str: t.string,
    sw_edition: nullableP(t.string),
    target_hw: nullableP(t.string),
    target_sw: nullableP(t.string),
    update: nullableP(t.string),
    vendor: nullableP(t.string),
    version: nullableP(t.string),
  }),
)

export type CVE = t.TypeOf<typeof CVE>
export const CVE = t.type({
  base_score: nullable(t.number),
  description: t.string,
  meta: t.UnknownRecord,
  signature: t.string,
})
