/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { getTiming } from './http.utils/get-timing'
import { handleErrorP } from './http.utils/handle-error-p'
import { handleResponse as handleResponseBuilder } from './http.utils/handle-response'
import { isFormData } from './http.utils/is-form-data'
import { retryFetch } from './http.utils/retry-fetch'
import { Message } from './http.utils/types'

// ---------------------------------------------------------------------------

export const HttpFactoryP = (
  baseUrl: string,
  defaultHeaders: RequestInit['headers'] = {},
  fetch = window.fetch,
  shouldRetry = false,
) => ({
  create: () => {
    const handleResponse = handleResponseBuilder(baseUrl)

    return {
      fetch: (uri: string, config?: RequestInit) => {
        const method = config?.method
        const t0 = window.performance.now()

        const f = shouldRetry ? retryFetch(method, fetch) : fetch

        const c = {
          ...config,
          method,
          headers: {
            ...defaultHeaders,
            ...config?.headers,
          },
        }

        return f(`${baseUrl}${uri}`, c).then(handleResponse).catch(handleErrorP).finally(getTiming(method, uri, t0))
      },

      delete: async (uri: string, config?: RequestInit) => {
        const method = 'DELETE'
        const t0 = window.performance.now()

        const f = shouldRetry ? retryFetch(method, fetch) : fetch

        const c = {
          ...config,
          method,
          headers: {
            ...defaultHeaders,
            ...config?.headers,
          },
        }

        return f(`${baseUrl}${uri}`, c)
          .then(handleResponse)
          .catch(handleErrorP(uri))
          .finally(getTiming(method, uri, t0))
      },

      get: async (uri: string, config?: RequestInit) => {
        const method = 'GET'
        const t0 = window.performance.now()

        const f = shouldRetry ? retryFetch(method, fetch) : fetch

        const c = {
          ...config,
          method,
          headers: {
            ...defaultHeaders,
            ...config?.headers,
          },
        }

        return f(`${baseUrl}${uri}`, c)
          .then(handleResponse)
          .catch(handleErrorP(uri))
          .finally(getTiming(method, uri, t0))
      },

      patch: (uri: string, data: Message, config?: RequestInit) => {
        const method = 'PATCH'
        const t0 = window.performance.now()

        const f = shouldRetry ? retryFetch(method, fetch) : fetch

        const c = {
          ...config,
          method,
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            ...defaultHeaders,
            ...config?.headers,
          },
        }

        return f(`${baseUrl}${uri}`, c)
          .then(handleResponse)
          .catch(handleErrorP(uri))
          .finally(getTiming(method, uri, t0))
      },

      post: (uri: string, data: Message, config?: RequestInit) => {
        const method = 'POST'
        const t0 = window.performance.now()

        const f = shouldRetry ? retryFetch(method, fetch) : fetch

        if (isFormData(data, config)) {
          const c = {
            ...config,
            method,
            body: data as FormData,
            headers: {
              ...defaultHeaders,
            },
          }

          return f(uri, c).then(handleResponse).catch(handleErrorP(uri)).finally(getTiming(method, uri, t0))
        }

        const c = {
          method,
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            ...defaultHeaders,
            ...config?.headers,
          },
          ...config,
        }

        return f(uri, c).then(handleResponse).catch(handleErrorP(uri)).finally(getTiming(method, uri, t0))
      },

      put: (uri: string, data: Message, config?: RequestInit) => {
        const method = 'PUT'
        const t0 = window.performance.now()

        const f = shouldRetry ? retryFetch(method, fetch) : fetch

        const c = {
          ...config,
          method,
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            ...defaultHeaders,
            ...config?.headers,
          },
        }

        return f(uri, c).then(handleResponse).catch(handleErrorP(uri)).finally(getTiming(method, uri, t0))
      },
    }
  },
})
