/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import * as React from 'react'

import { RandoriComponentLoadError } from '@/utilities/r-error'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:lazy')

/**
 * @param factory - () =\> import('./path/to/a/component')
 *
 * @returns the component
 *
 * @see https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
export function lazyWithRetry<T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  const tryLoad = () =>
    new Promise<{ default: T }>((resolve) => {
      const _cached = window.localStorage.getItem('force-reloaded') ?? 'false'
      const pageHasAlreadyBeenForceRefreshed = _cached === 'true'

      factory()
        .then((component) => {
          window.localStorage.setItem('force-reloaded', 'false')

          resolve(component)
        })
        .catch((err) => {
          if (!pageHasAlreadyBeenForceRefreshed) {
            window.localStorage.setItem('force-reloaded', 'true')

            window.location.reload()
          }

          throw new RandoriComponentLoadError(err)
        })
    })

  return React.lazy(tryLoad)
}

/**
 * Initialize retry sentinel for lazy component loading
 *
 * If the application has re-initialized using "force-reload", we want to
 * reset the "force-reload" value after a period of time, in-case there is
 * another long-lived session.
 *
 * @returns function to set localStorage sentinel
 */
function _lazyWithRetry() {
  const howLong = 1000 * 60 // 1 minute
  let scheduled = false

  log('Initializing lazy sentinel')

  return function () {
    if (!scheduled) {
      scheduled = true

      setTimeout(() => {
        log('Resetting lazy sentinel')

        window.localStorage.setItem('force-reloaded', 'false')
      }, howLong)
    }
  }
}

export const lazyWithRetryInit = _lazyWithRetry()
