/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullableP, UUID } from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { Authorization } from '../authorization.codecs'
import { WithPerspective } from '../perspective.codecs'
import { WithPriority } from '../priority.codecs'

// ---------------------------------------------------------------------------

export const _Social = t.type({
  address: nullableP(t.string),
  affiliation_state: Affiliation,
  authorization_state: Authorization,
  city: nullableP(t.string),
  company_name: nullableP(t.string),
  country: nullableP(t.string),
  details: nullableP(t.type({})),
  domain: nullableP(t.string),
  email: t.string,
  first_seen: nullableP(DateString),
  id: UUID,
  last_seen: nullableP(DateString),
  person_first_name: nullableP(t.string),
  person_last_name: nullableP(t.string),
  phone: nullableP(t.string),
  postal_code: nullableP(t.string),
  characteristic_tags: t.array(t.string),
  role: nullableP(t.string),
  state: nullableP(t.string),
  sub_role: nullableP(t.string),
  title: nullableP(t.string),
  user_tags: t.array(t.string),
})

export type SocialP = t.TypeOf<typeof SocialP>
export const SocialP = t.intersection([_Social, WithPerspective, WithPriority])
