/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export type StateActionTypes =
  | ACTIVITY_CONFIGURATIONS_FETCH
  | ACTIVITY_CONFIGURATION_FETCH
  | ACTIVITY_CONFIGURATION_PATCH_ENABLED
  | ACTIVITY_CONFIGURATIONS_STORE_UPDATE
  | ACTIVITY_CONFIGURATION_STORE_UPDATE
  | ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE
  | ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH
  | ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  ACTIVITY_CONFIGURATIONS_FETCH = 'ACTIVITY_CONFIGURATIONS_FETCH',
  ACTIVITY_CONFIGURATION_FETCH = 'ACTIVITY_CONFIGURATION_FETCH',
  ACTIVITY_CONFIGURATION_PATCH_ENABLED = 'ACTIVITY_CONFIGURATION_PATCH_ENABLED',
  ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH = 'ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH',
  ACTIVITY_CONFIGURATION_STORE_UPDATE = 'ACTIVITY_CONFIGURATION_STORE_UPDATE',
  ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE = 'ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE',
  ACTIVITY_CONFIGURATIONS_STORE_UPDATE = 'ACTIVITY_CONFIGURATIONS_STORE_UPDATE',
  ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE = 'ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE',
}

export type ACTIVITY_CONFIGURATION_FETCH = {
  type: TypeKeys.ACTIVITY_CONFIGURATION_FETCH
  meta: { deferred: Deferred }
  payload: { id: string }
}

export function ACTIVITY_CONFIGURATION_FETCH(
  payload: ACTIVITY_CONFIGURATION_FETCH['payload'],
  deferred: Deferred,
): ACTIVITY_CONFIGURATION_FETCH {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATION_FETCH,
    meta: { deferred },
    payload,
  }
}
export type ACTIVITY_CONFIGURATION_PATCH_ENABLED = {
  type: TypeKeys.ACTIVITY_CONFIGURATION_PATCH_ENABLED
  meta: { deferred: Deferred }
  payload: { id: string; enabled: boolean }
}

export function ACTIVITY_CONFIGURATION_PATCH_ENABLED(
  payload: ACTIVITY_CONFIGURATION_PATCH_ENABLED['payload'],
  deferred: Deferred,
): ACTIVITY_CONFIGURATION_PATCH_ENABLED {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATION_PATCH_ENABLED,
    meta: { deferred },
    payload,
  }
}

export type ACTIVITY_CONFIGURATIONS_FETCH = {
  type: TypeKeys.ACTIVITY_CONFIGURATIONS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function ACTIVITY_CONFIGURATIONS_FETCH(
  payload: ACTIVITY_CONFIGURATIONS_FETCH['payload'],
  deferred: Deferred,
): ACTIVITY_CONFIGURATIONS_FETCH {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATIONS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH = {
  type: TypeKeys.ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH
  meta: { deferred: Deferred; updateStore: boolean }
  payload: RouteComponentProps['location']['search']
}

export function ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH(
  payload: ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH['payload'],
  deferred: Deferred,
  updateStore = false,
): ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH,
    meta: { deferred, updateStore },
    payload,
  }
}

// ---------------------------------------------------------------------------

export type ACTIVITY_CONFIGURATION_STORE_UPDATE = {
  type: TypeKeys.ACTIVITY_CONFIGURATION_STORE_UPDATE
  payload: Codecs.ActivityConfigurationResponse
}

export function ACTIVITY_CONFIGURATION_STORE_UPDATE(
  payload: ACTIVITY_CONFIGURATION_STORE_UPDATE['payload'],
): ACTIVITY_CONFIGURATION_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATION_STORE_UPDATE,
    payload,
  }
}

export type ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE = {
  type: TypeKeys.ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE
  payload: Codecs.ActivityConfigurationPatchResponse
}

export function ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE(
  payload: ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE['payload'],
): ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE,
    payload,
  }
}

export type ACTIVITY_CONFIGURATIONS_STORE_UPDATE = {
  type: TypeKeys.ACTIVITY_CONFIGURATIONS_STORE_UPDATE
  payload: Codecs.ActivityConfigurationsResponse
}

export function ACTIVITY_CONFIGURATIONS_STORE_UPDATE(
  payload: ACTIVITY_CONFIGURATIONS_STORE_UPDATE['payload'],
): ACTIVITY_CONFIGURATIONS_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATIONS_STORE_UPDATE,
    payload,
  }
}

export type ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE = {
  type: TypeKeys.ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE(
  payload: ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE['payload'],
): ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE,
    payload,
  }
}
