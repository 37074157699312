/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { Source } from '@/codecs/source.codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './source.constants'

// ---------------------------------------------------------------------------

export function normalizeSources(records: Source[]) {
  const Source = new schema.Entity(EntityName)
  const Sources = [Source]

  type NormalizedSources = {
    result: UUID[]
    entities: {
      [EntityName]: Record<UUID, Source>
    }
  }

  const sources: NormalizedSources = normalize(records, Sources)

  return sources
}
