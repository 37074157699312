/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

// ---------------------------------------------------------------------------

/**
 * Call the appropriate Response[method] depending on the Response
 * content-type header.
 *
 * @param response -
 *
 * @returns the response body
 */
export const handleExtractP = async (response: Response): Promise<unknown> => {
  const contentType = response.headers.get('content-type')
  const responseUrl = response.url

  // @TODO: return Promise.resolve(null)
  // @see: https://www.rfc-editor.org/rfc/rfc9110#field.content-type
  if (contentType === null) {
    return null
  }

  // @TODO: Remove responseUrl checks when EN-12933 is deployed
  //
  // ACS is giving us a contentType of text/plain but the content is actually
  // a json formatted string, remove responseUrl code after this gets fixed.
  if (responseUrl.includes('activity-configuration') || responseUrl.includes('applicable-activities')) {
    return response.json()
  }

  if (contentType.toLowerCase().includes('application/json')) {
    return response.json()
  }

  if (contentType.toLowerCase().includes('text/')) {
    return response.text()
  }

  if (contentType.toLowerCase().includes('multipart/form-data')) {
    return response.formData()
  }

  if (contentType.toLowerCase().startsWith('image/')) {
    return response.blob()
  }

  if (contentType.toLowerCase().startsWith('audio/')) {
    return response.blob()
  }

  if (contentType.toLowerCase().startsWith('video/')) {
    return response.blob()
  }

  // The artifacts endpoint gives us back a non-useful content-type header
  // for everything. We'll first try to parse the response as JSON, otherwise
  // return text. This can still throw, but not in an "expected" way, e.g.
  // receiving an artifact that has no text represenation.
  if (contentType.toLowerCase().includes('application/octet-stream')) {
    const text = await response.text()

    try {
      return JSON.parse(text)
    } catch (e) {
      return text
    }
  }

  // default
  return response.text()
}
