/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { EntityPagination, TargetP as Target } from '@/codecs'
import { DoubleUUID, TripleUUID, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type EntityName = 'target'

export const EntityName: EntityName = 'target'

export type State = typeof initialState

// ID aliases
type EntityId = TripleUUID | UUID
type TargetId = DoubleUUID

type TargetsForEntity = {
  pagination: EntityPagination
  result: TargetId[]
}

type InitialState = {
  entities: { target: Record<DoubleUUID, Target> }
  pagination: EntityPagination
  result: DoubleUUID[]
  totals: { unfiltered: number; unaffiliated: number }

  byEntity: {
    hostname: Record<EntityId, TargetsForEntity>
    ip: Record<EntityId, TargetsForEntity>
    network: Record<EntityId, TargetsForEntity>
    service: Record<EntityId, TargetsForEntity>
  }
}

export const initialState: InitialState = {
  entities: { target: {} },
  pagination: { count: 0, offset: 0, total: 0 },
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },

  byEntity: {
    hostname: {} as Record<EntityId, TargetsForEntity>,
    ip: {} as Record<EntityId, TargetsForEntity>,
    network: {} as Record<EntityId, TargetsForEntity>,
    service: {} as Record<EntityId, TargetsForEntity>,
  },
}
