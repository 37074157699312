/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

// ---------------------------------------------------------------------------
import { OrganizationApiTokensReducer } from './api-token.reducers'
import { OrganizationFeaturesReducer } from './feature.reducers'
import { OrganizationInfoReducer } from './org-info.reducers'
import { ProvidersReducer } from './sso.reducers'
import {
  OrganizationUsersPaginationReducer,
  OrganizationUsersReducer,
  UserOrganizationsPaginationReducer,
  UserOrganizationsReducer,
} from './user.reducers'

// ---------------------------------------------------------------------------

export type State = ReturnType<typeof Reducer>
export const Reducer = combineReducers({
  orgApiTokens: OrganizationApiTokensReducer,
  orgFeatures: OrganizationFeaturesReducer,
  orgInfo: OrganizationInfoReducer,
  orgUsers: OrganizationUsersReducer,
  orgUsersPagination: OrganizationUsersPaginationReducer,
  organizationsPagination: UserOrganizationsPaginationReducer,
  ssoProviders: ProvidersReducer,
  userOrganizations: UserOrganizationsReducer,
})

export const Test = {
  OrganizationApiTokensReducer,
  OrganizationUsersReducer,
  OrganizationFeaturesReducer,
}
