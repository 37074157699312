/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type SpecApi = {
  getApiSpec: () => Promise<Codecs.ApiSpecResponse>
}

export function specApiFactory(http: HttpClient): SpecApi {
  return {
    getApiSpec() {
      return http.get('/openapi').then(CodecUtils.throwValidate(Codecs.ApiSpecResponse, 'ApiSpecResponse'))
    },
  }
}
