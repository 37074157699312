/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store'
import * as ArtifactForActivityInstanceActions from '@/store/actions/activity/artifact-for-activity-instance.actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

const ArtifactForActivityInstanceReducerInitialState = {
  byId: {} as Record<string, string[]>,
  ...Schemas.ArtifactForActivityInstanceSchemas.NormalizeArtifactsForActivityInstance([]),
}

export const ArtifactForActivityInstanceReducer = (
  state = ArtifactForActivityInstanceReducerInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case ArtifactForActivityInstanceActions.TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE: {
      const { entities, result } = Schemas.ArtifactForActivityInstanceSchemas.NormalizeArtifactsForActivityInstance(
        action.payload.data,
      )

      return {
        result,
        byId: {
          ...state.byId,
          [action.meta.forActivityInstance]: result,
        },
        entities: {
          ...state.entities,
          artifactForActivityInstance: {
            ...state.entities.artifactForActivityInstance,
            ...entities.artifactForActivityInstance,
          },
        },
      }
    }

    default:
      return state
  }
}

const ArtifactForActivityInstancePaginationReducerInitialState: Record<string, Codecs.ArtifactPagination> = {}
export const ArtifactForActivityInstancePaginationReducer = (
  state = ArtifactForActivityInstancePaginationReducerInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case ArtifactForActivityInstanceActions.TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE: {
      const { data: _data, ...pagination } = action.payload

      return {
        [action.meta.forActivityInstance]: pagination,
      }
    }

    default:
      return state
  }
}
