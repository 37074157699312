/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import qs from 'query-string'

import { SocialP as Social } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, throwValidate, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

// ---------------------------------------------------------------------------

export type SocialApi = {
  getSocial: (id: UUID) => Promise<GetSocial>
  getSocials: (query: QueryString | '') => Promise<GetSocials>
}

export function socialApiFactory(http: HttpClient): SocialApi {
  const baseUrl = '/recon/api/v2/social-entity'

  return {
    getSocial(id) {
      const q = {
        condition: 'AND',
        rules: [
          {
            field: 'table.id',
            id: 'table.id',
            input: 'text',
            operator: 'equal',
            randoriOnly: false,
            type: 'string',
            ui_id: 'poc_id',
            value: id,
          },
        ],
      }

      const query = qs.stringify({
        q: CrudQueryUtils.serializeQ(q),
      })

      return http.get(`${baseUrl}${query}`).then(throwValidate(GetSocial, 'GetSocial'))
    },

    getSocials(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetSocials, 'GetSocials'))
    },
  }
}

export type GetSocial = t.TypeOf<typeof GetSocial>
export const GetSocial = rflaskGetMany(Social)

export type GetSocials = t.TypeOf<typeof GetSocials>
export const GetSocials = rflaskGetMany(Social)
