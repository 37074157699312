/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { uniq } from 'lodash/fp'

import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const PeerGroupsReducer = (state = Schemas.Recon.NormalizePeerGroups([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.PEER_GROUPS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizePeerGroups(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          'peer-group': {
            ...state.entities['peer-group'],
            ...entities['peer-group'],
          },
        },
      }
    }

    default:
      return state
  }
}

const PeerGroupTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const PeerGroupTotalsReducer = (state = PeerGroupTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.PEER_GROUP_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const PeerGroupsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const PeerGroupsPaginationReducer = (state = PeerGroupsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.PEER_GROUPS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}

type PeerMapsInitialState = {
  result: string[]
  byPeerGroupId: { [i: string]: string[] }
  entities: {
    'peer-map': { [i: string]: Codecs.PeerMap }
  }
}
const PeerMapsInitialState: PeerMapsInitialState = {
  byPeerGroupId: {},
  ...Schemas.Recon.NormalizePeerMaps([]),
}

export const PeerMapsReducer = (state = PeerMapsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.PEER_MAPS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizePeerMaps(action.payload.data)

      return {
        result,
        // @TODO: Anticipating "org total" roll-ups for global admins
        byPeerGroupId: state.byPeerGroupId,
        entities: {
          'peer-map': {
            ...state.entities['peer-map'],
            ...entities['peer-map'],
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.PEER_MAP_STORE_UPDATE: {
      const peerMap = action.payload.data

      return {
        ...state,
        // PEER_MAP_STORE_UPDATE need to produce a state change that causes
        // selectors to rerun. selectPeerMaps() needs to have a revised array
        // to iterate, or dependent selectors will not rerun - this specific
        // selector is currently used by the form for initial values, which
        // will be stale unless the selector reruns.
        result: uniq([...state.result, peerMap.id]),
        entities: {
          'peer-map': {
            ...state.entities['peer-map'],
            [peerMap.id]: peerMap,
          },
        },
      }
    }

    default:
      return state
  }
}

const PeerMapTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const PeerMapTotalsReducer = (state = PeerMapTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.PEER_MAP_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const PeerMapsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const PeerMapsPaginationReducer = (state = PeerMapsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.PEER_MAPS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
