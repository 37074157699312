/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as Codecs from '@/codecs'
import { get, nullableP, UUID } from '@/utilities/codec'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export const getDetectionProps = (entityType: EntityUtils.EntityType, entity: Codecs.Entity) => {
  const validator = t.type({
    perspective_id: nullableP(UUID),
  })

  const validated = get(entity, validator)

  return {
    originatingEntityId: entity.id,
    originatingEntityPerspectiveId: validated.perspective_id,
    originatingEntityType: entityType,
  }
}
