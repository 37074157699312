/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:is-pojo')

export function isPojo(maybeObject: unknown): maybeObject is Record<string, unknown> {
  try {
    /* eslint-disable */
    // @ts-ignore
    return maybeObject.constructor.name === 'Object'
    /* eslint-enable */
  } catch (err) {
    log(err)
    return false
  }
}
