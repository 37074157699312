/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Affiliation } from '../affiliation.codecs'
import { Perspective, PerspectiveName } from '../perspective.codecs'
import { withPriority } from '../priority.codecs'

// ---------------------------------------------------------------------------

export type Network = t.TypeOf<typeof Network>
export const Network = withPriority(
  t.type({
    id: t.string,
    affiliation_state: Affiliation,
    confidence: t.union([t.number, t.null]),
    deleted: t.union([t.boolean, t.null]),
    first_seen: t.union([t.string, t.null]),
    ip_count: t.union([t.number, t.null]),
    last_seen: t.union([t.string, t.null]),
    max_confidence: t.union([t.number, t.null]),
    name: t.union([t.string, t.undefined]),
    network: t.union([t.string, t.null]),
    network_str: t.union([t.string, t.null]),
    only_in_review_targets: t.boolean,
    open_port_count: t.union([t.number, t.null]),
    org_id: t.union([t.string, t.null]),
    perspective: Perspective,
    perspective_name: PerspectiveName,
    characteristic_tags: t.array(t.string),
    service_count: t.union([t.number, t.null]),
    target_count: t.union([t.number, t.null]),
    user_tags: t.array(t.string),
  }),
)
