/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { makeFetchMany, makeFetchManyForEntity, makeFetchSingle, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './hostname.constants'

// ---------------------------------------------------------------------------

export const fetchHostname = makeFetchSingle(EntityName)
export const fetchHostnameTotals = makeFetchTotals(EntityName)
export const fetchHostnames = makeFetchMany(EntityName)
export const fetchHostnamesForIp = makeFetchManyForEntity(EntityName, 'ip')
