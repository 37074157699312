/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import { DeferredAction } from 'redux-saga-try-catch'

import { ExceptionPolicyNormalizedForSummary } from '@/codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './exception-policy.constants'

// ---------------------------------------------------------------------------

export const fetchExceptionPolicies = createAction(
  `${EntityName}/fetch-many`,
  (
    payload: RouteComponentProps['location']['search'],
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchExceptionPolicy = createAction(
  `${EntityName}/fetch-single`,
  (payload: UUID, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const fetchExceptionPolicyConfigurations = createAction(
  `${EntityName}/fetch-assigned-configurations`,
  (payload: UUID, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const fetchExceptionPolicyTotals = createAction(
  `${EntityName}/fetch-totals`,
  (payload: RouteComponentProps['location']['search'], deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const patchExceptionPolicy = createAction(
  `${EntityName}/patch-policy`,
  (payload: any, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const postExceptionPolicy = createAction(
  `${EntityName}/post-single`,
  (payload: any, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const assignExceptionPolicy = createAction(
  `${EntityName}/assign-policy`,
  (payload: any, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const deleteExceptionPolicy = createAction(
  `${EntityName}/delete-single`,
  (payload: ExceptionPolicyNormalizedForSummary, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)
