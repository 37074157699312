/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import * as EmbedUtils from '../embed.utils'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:zendesk')

export const ZendeskEmbed: React.FC = () => {
  const [isAllowed] = EmbedUtils.useKlaroWatch('zendesk', true)

  if (isAllowed) {
    log('enabled')

    return (
      <Helmet>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=248d850d-8127-4e1b-b3b3-319a6ee8b213"
        ></script>
      </Helmet>
    )
  } else {
    log('noop')

    return null
  }
}
