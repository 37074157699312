/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  STAT_FETCH = 'STAT_FETCH',
}

// ---------------------------------------------------------------------------

export type STAT_FETCH = {
  type: TypeKeys.STAT_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function STAT_FETCH(payload: STAT_FETCH['payload'], deferred: Deferred): STAT_FETCH {
  return {
    type: TypeKeys.STAT_FETCH,
    meta: { deferred },
    payload,
  }
}
