/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Icon, StatBlock, StatBlockGroup } from '@randori/rootkit'
import classNames from 'classnames'
import * as React from 'react'

import { formatNumber } from '@/utilities/number'

// ---------------------------------------------------------------------------
import { StatDiff } from './graph-container'

// ---------------------------------------------------------------------------

type AttackStatProps = {
  data: Record<string, StatDiff>
  handleOnClick: (args?: any) => void
  selectedEntity: string
}

export const AttackStats: React.FunctionComponent<AttackStatProps> = (props) => {
  const hostnames = props.data.hostnames
  const ips = props.data.ips
  const networks = props.data.networks
  const services = props.data.services
  const targets = props.data.targets

  return (
    <StatBlockGroup addlClasses="dash-attack-stats" modifier={['no-border', 'no-separator']}>
      <StatBlock
        addlClasses={getAddlClasses(props, 'targets')}
        label={getLabelProps('Targets')}
        stat={formatNumber(targets.stat)}
        onClick={props.handleOnClick}
      >
        {getTrendIcons(targets.difference)}
        {Math.abs(targets.difference)}
      </StatBlock>

      <StatBlock
        addlClasses={getAddlClasses(props, 'services')}
        label={getLabelProps('Services')}
        stat={formatNumber(services.stat)}
        onClick={props.handleOnClick}
      >
        {getTrendIcons(services.difference)}
        {Math.abs(services.difference)}
      </StatBlock>

      <StatBlock
        addlClasses={getAddlClasses(props, 'hostnames')}
        label={getLabelProps('Hostnames')}
        stat={formatNumber(hostnames.stat)}
        onClick={props.handleOnClick}
      >
        {getTrendIcons(hostnames.difference)}
        {Math.abs(hostnames.difference)}
      </StatBlock>

      <StatBlock
        addlClasses={getAddlClasses(props, 'ips')}
        label={getLabelProps('IP Addresses')}
        stat={formatNumber(ips.stat)}
        onClick={props.handleOnClick}
      >
        {getTrendIcons(ips.difference)}
        {Math.abs(ips.difference)}
      </StatBlock>

      <StatBlock
        addlClasses={getAddlClasses(props, 'networks')}
        label={{ position: 'top', message: 'Networks' }}
        stat={formatNumber(networks.stat)}
        onClick={props.handleOnClick}
      >
        {getTrendIcons(networks.difference)}
        {Math.abs(networks.difference)}
      </StatBlock>
    </StatBlockGroup>
  )
}

function getLabelProps(message: string) {
  return { position: 'top', message }
}

function getAddlClasses(props: AttackStatProps, currentEntity: string) {
  return classNames(
    { 'is-selected': props.selectedEntity === currentEntity },
    { 'is-trending--up': props.data[currentEntity].difference > 0 },
    { 'is-trending--down': props.data[currentEntity].difference < 0 },
    { 'is-trending--no-difference': props.data[currentEntity].difference === 0 },
  )
}

function getTrendIcons(difference: number) {
  if (difference === 0) {
    return <Icon kind="arrow-thick" addlClasses="icon-trend-arrow--no-difference" />
  }

  return difference > 0 ? (
    <Icon kind="arrow-thick" addlClasses="icon-trend-arrow--up" />
  ) : (
    <Icon kind="arrow-thick" addlClasses="icon-trend-arrow--down" />
  )
}
