/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import qs from 'query-string'
import * as Catch from 'redux-saga-try-catch'
import { all, call, put, select, takeEvery } from 'typed-redux-saga/macro'

import * as Codecs from '@/codecs'
import * as Store from '@/store'
import * as _HocActions from '@/store/actions/hoc'
import { MiddlewaresIO } from '@/store/store.utils'
import { isNotNil, isNotNilOrEmpty } from '@/utilities/is-not'

import { _FEATURES_FETCH } from '../organization'
import { _PREFERENCES_GET } from '../preferences'

// ---------------------------------------------------------------------------

/* eslint-disable @typescript-eslint/no-use-before-define */
export function* watchHocs(io: MiddlewaresIO) {
  // init
  yield takeEvery(Store.HocActions.TypeKeys.HOC_INIT, Catch.deferredAction(_HOC_INIT as any, io))

  // blueprints
  yield takeEvery(Store.HocActions.TypeKeys.BLUEPRINTS_FETCH, Catch.deferredAction(_BLUEPRINTS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.BLUEPRINT_PATCH, Catch.deferredAction(_BLUEPRINT_PATCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.BLUEPRINT_TOTALS_FETCH, Catch.deferredAction(_BLUEPRINT_TOTALS_FETCH, io))

  // bars
  yield takeEvery(Store.HocActions.TypeKeys.BARS_FETCH, Catch.deferredAction(_BARS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.BARS_PATCH, Catch.deferredAction(_BARS_PATCH as any, io))
  yield takeEvery(Store.HocActions.TypeKeys.BAR_TOTALS_FETCH, Catch.deferredAction(_BAR_TOTALS_FETCH, io))

  // global ips
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_IPS_FETCH, Catch.deferredAction(_GLOBAL_IPS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_IPS_TOTALS_FETCH, Catch.deferredAction(_GLOBAL_IPS_TOTALS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_IPS_POST, Catch.deferredAction(_GLOBAL_IPS_POST, io))

  // global services
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_SERVICES_FETCH, Catch.deferredAction(_GLOBAL_SERVICES_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_SERVICE_PATCH, Catch.deferredAction(_GLOBAL_SERVICE_PATCH, io))
  yield takeEvery(
    Store.HocActions.TypeKeys.GLOBAL_SERVICE_STATS_FETCH,
    Catch.deferredAction(_GLOBAL_SERVICE_STATS_FETCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.GLOBAL_SERVICE_TOTALS_FETCH,
    Catch.deferredAction(_GLOBAL_SERVICE_TOTALS_FETCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.GLOBAL_SERVICE_POST_MANY,
    Catch.deferredAction(_GLOBAL_SERVICE_POST_MANY as any, io),
  )

  // global networks
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_NETWORKS_FETCH, Catch.deferredAction(_GLOBAL_NETWORKS_FETCH, io))
  yield takeEvery(
    Store.HocActions.TypeKeys.GLOBAL_NETWORKS_TOTALS_FETCH,
    Catch.deferredAction(_GLOBAL_NETWORKS_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_NETWORK_POST, Catch.deferredAction(_GLOBAL_NETWORK_POST, io))

  // org terms
  yield takeEvery(Store.HocActions.TypeKeys.TERMS_FETCH, Catch.deferredAction(_TERMS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.TERMS_TOTALS_FETCH, Catch.deferredAction(_TERMS_TOTALS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.ORG_TERM_POST, Catch.deferredAction(_ORG_TERM_POST, io))

  // global hostnames
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_HOSTNAMES_FETCH, Catch.deferredAction(_GLOBAL_HOSTNAMES_FETCH, io))
  yield takeEvery(
    Store.HocActions.TypeKeys.GLOBAL_HOSTNAMES_TOTALS_FETCH,
    Catch.deferredAction(_GLOBAL_HOSTNAMES_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.HocActions.TypeKeys.GLOBAL_HOSTNAME_POST, Catch.deferredAction(_GLOBAL_HOSTNAME_POST, io))

  // suggestions
  yield takeEvery(
    Store.HocActions.TypeKeys.SERVICE_SUGGESTIONS_BULK_POST,
    Catch.deferredAction(_SERVICE_SUGGESTIONS_BULK_POST, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.SERVICE_SUGGESTIONS_FETCH,
    Catch.deferredAction(_SERVICE_SUGGESTIONS_FETCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.SERVICE_SUGGESTION_PATCH,
    Catch.deferredAction(_SERVICE_SUGGESTION_PATCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.SERVICE_SUGGESTION_PROMOTE,
    Catch.deferredAction(_SERVICE_SUGGESTION_PROMOTE, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.SERVICE_SUGGESTION_STATS_FETCH,
    Catch.deferredAction(_SERVICE_SUGGESTION_STATS_FETCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.SERVICE_SUGGESTION_TOTALS_FETCH,
    Catch.deferredAction(_SERVICE_SUGGESTION_TOTALS_FETCH, io),
  )

  // service rules
  yield takeEvery(Store.HocActions.TypeKeys.SERVICE_RULES_FETCH, Catch.deferredAction(_SERVICE_RULES_FETCH, io))
  yield takeEvery(
    Store.HocActions.TypeKeys.SERVICE_RULES_METADATA_FETCH,
    Catch.deferredAction(_SERVICE_RULES_METADATA_FETCH, io),
  )
  yield takeEvery(Store.HocActions.TypeKeys.SERVICE_RULE_PATCH, Catch.deferredAction(_SERVICE_RULE_PATCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.SERVICE_RULE_POST, Catch.deferredAction(_SERVICE_RULE_POST, io))

  // characteristic rules
  yield takeEvery(
    Store.HocActions.TypeKeys.CHARACTERISTIC_RULES_FETCH,
    Catch.deferredAction(_CHARACTERISTIC_RULES_FETCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.CHARACTERISTIC_RULES_METADATA_FETCH,
    Catch.deferredAction(_CHARACTERISTIC_RULES_METADATA_FETCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.CHARACTERISTIC_RULE_PATCH,
    Catch.deferredAction(_CHARACTERISTIC_RULE_PATCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.CHARACTERISTIC_RULE_POST,
    Catch.deferredAction(_CHARACTERISTIC_RULE_POST, io),
  )

  // services
  yield takeEvery(Store.HocActions.TypeKeys.SERVICE_PATCH, Catch.deferredAction(_SERVICE_PATCH, io))

  // artifacts
  yield takeEvery(Store.HocActions.TypeKeys.ARTIFACTS_FETCH, Catch.deferredAction(_ARTIFACTS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.ARTIFACT_TOTALS_FETCH, Catch.deferredAction(_ARTIFACT_TOTALS_FETCH, io))

  // regex
  yield takeEvery(
    Store.HocActions.TypeKeys.VALIDATE_CHARACTERISTIC_REGEX,
    Catch.deferredAction(_VALIDATE_CHARACTERISTIC_REGEX, io),
  )
  yield takeEvery(Store.HocActions.TypeKeys.VALIDATE_SERVICE_REGEX, Catch.deferredAction(_VALIDATE_SERVICE_REGEX, io))

  // pocs
  yield takeEvery(Store.HocActions.TypeKeys.POCS_FETCH, Catch.deferredAction(_POCS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.POCS_TOTALS_FETCH, Catch.deferredAction(_POCS_TOTALS_FETCH, io))
  yield takeEvery(Store.HocActions.TypeKeys.POCS_POST, Catch.deferredAction(_POCS_POST, io))

  // scheduler
  yield takeEvery(Store.HocActions.TypeKeys.PERIODIC_POST, Catch.deferredAction(_PERIODIC_POST, io))
  yield takeEvery(Store.HocActions.TypeKeys.POST_ORG_KEYVAL, Catch.deferredAction(_POST_ORG_KEYVAL, io))

  // org primes
  yield takeEvery(Store.HocActions.TypeKeys.GET_ORG_PRIMES, Catch.deferredAction(_GET_ORG_PRIMES, io))
}
/* eslint-enable @typescript-eslint/no-use-before-define */

// init
// ---------------------------------------------------------------------------

export function* _HOC_INIT(io: MiddlewaresIO) {
  return yield* all([call(_PREFERENCES_GET, io), call(_FEATURES_FETCH, io)])
}

// blueprints
// ---------------------------------------------------------------------------

export function* _BLUEPRINTS_FETCH(io: MiddlewaresIO, action: _HocActions.BLUEPRINTS_FETCH) {
  const result = yield* call(io.api.access.getBlueprints, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.access.getBlueprints, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.BLUEPRINT_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.BLUEPRINTS_STORE_UPDATE(result))

  return result
}

export function* _BLUEPRINT_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.BLUEPRINT_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.access.getBlueprints,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.access.getBlueprints, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.BLUEPRINT_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

// @TODO: Overload this function to handle:
//
// * Approve process blueprint
// * Modify associated runbook
export function* _BLUEPRINT_PATCH(io: MiddlewaresIO, action: _HocActions.BLUEPRINT_PATCH) {
  const username = yield* select(Store.AuthSelectors.selectUserName)

  const blueprintPayload = {
    data: {
      approver: username,
      bart_id: null,
    },
  }

  const response = yield* call(io.api.access.patchBlueprint, action.payload.process_blueprint_id, blueprintPayload)

  if (action.payload.type === 'runbook') {
    const runbookPayload = {
      data: {
        description: action.payload.runbook_description,
        guidance: action.payload.runbook_guidance,
        name: action.payload.runbook_name,
        objective: action.payload.runbook_objective,
        runbook_id: action.payload.runbook_id,
      },
    }

    yield* call(io.api.attack.postRunbookDescription, runbookPayload)
  }

  yield* put(Store.HocActions.BLUEPRINTS_STORE_UPDATE_P(response))

  return response
}

// bars
// ---------------------------------------------------------------------------

export function* _BARS_FETCH(io: MiddlewaresIO, action: _HocActions.BARS_FETCH) {
  const result = yield* call(io.api.access.getBars, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.access.getBars, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.BAR_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.BARS_STORE_UPDATE(result))

  return result
}

export function* _BAR_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.BAR_TOTALS_FETCH) {
  const { total } = yield* call(io.api.access.getBars, `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`)

  const { total: unfilteredTotal } = yield* call(io.api.access.getBars, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.BAR_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

//This is no longer patching a bar it ACKS bars, this should probably be renamed.
export function* _BARS_PATCH(io: MiddlewaresIO, action: _HocActions.BARS_PATCH) {
  const prevBars = yield* select(Store.HocSelectors.selectBarsById, { ids: action.payload.barIds })

  const next = prevBars.map((bar) => ({
    ...bar,
    approved: action.payload.approved,
  }))

  // Ack bars with approved or denied
  const effects = next.map((bar) => call(io.api.access.ackBar, { id: bar.id, approved: bar.approved }))

  // post bar acks

  const patchResponses = yield* all(effects)

  // Drop local cache of platform-events
  yield* put(Store.HocActions.BARS_DROP(action.payload.barIds))

  return []
}

// services
// ---------------------------------------------------------------------------

export function* _SERVICE_PATCH(io: MiddlewaresIO, action: _HocActions.SERVICE_PATCH) {
  const { id, ...payload } = action.payload

  const response = yield* call(io.api.aggregator.patchGlobalService, id, {
    data: payload,
  })

  return response
}

// global ips
// ---------------------------------------------------------------------------

export function* _GLOBAL_IPS_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_IPS_FETCH) {
  const result = yield* call(io.api.aggregator.getGlobalIps, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalIps, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_IPS_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.GLOBAL_IPS_UPDATE(result))

  return result
}

export function* _GLOBAL_IPS_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_IPS_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.aggregator.getGlobalIps,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalIps, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_IPS_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

export function* _GLOBAL_IPS_POST(io: MiddlewaresIO, action: _HocActions.GLOBAL_IPS_POST) {
  const response = yield* call(io.api.aggregator.postGlobalIp, action.payload)

  return response
}

// global services
// ---------------------------------------------------------------------------

export function* _GLOBAL_SERVICES_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_SERVICES_FETCH) {
  const result = yield* call(io.api.aggregator.getGlobalServicesWithStats, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalServices, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_SERVICE_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.GLOBAL_SERVICES_STORE_UPDATE(result))

  return result
}

export function* _GLOBAL_SERVICE_STATS_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_SERVICE_STATS_FETCH) {
  const result = yield* call(io.api.aggregator.getGlobalServiceStats, action.payload)

  yield* put(Store.HocActions.GLOBAL_SERVICE_STATS_STORE_UPDATE(result))

  return result
}

export function* _GLOBAL_SERVICE_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_SERVICE_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.aggregator.getGlobalServices,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalServices, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_SERVICE_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

export function* _GLOBAL_SERVICE_PATCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_SERVICE_PATCH) {
  const { id, ...payload } = action.payload

  const response = yield* call(io.api.aggregator.patchGlobalService, id, { data: payload })

  yield* put(Store.HocActions.GLOBAL_SERVICE_STORE_UPDATE(response))

  return response
}

export function* _GLOBAL_SERVICE_POST_MANY(io: MiddlewaresIO, action: _HocActions.GLOBAL_SERVICE_POST_MANY) {
  if (action.payload.entityType === 'serviceSuggestion') {
    // suggestions
    const payload = action.payload.nextValues.services.map((service) => {
      const { id, ...rest } = service

      return {
        ...rest,
        promoted: true,
        deleted: false,
      }
    })

    const { ids: newIds } = yield* call(io.api.aggregator.postGlobalService, { data: payload })

    const ids = action.payload.nextValues.services.map((service) => service.id)

    // @TODO: Change return type of Store.HocSelectors.selectServiceSuggestionsById
    const _suggestions: any = yield* select(Store.HocSelectors.selectServiceSuggestionsById, { ids })
    const suggestions: Codecs.ServiceSuggestion[] = _suggestions

    const globalTags = suggestions.map((suggestion, i) => {
      return {
        deleted: false,
        dst: newIds[i],
        dst_type: 'global_service' as const,
        tag: suggestion.tag,
      }
    })

    yield call(io.api.aggregator.postGlobalTags, { data: globalTags })

    return newIds
  } else {
    // services
    const payload = action.payload.nextValues.services.map((service) => {
      return {
        ...service,
        promoted: true,
        deleted: false,
      }
    })

    yield* call(io.api.aggregator.postGlobalService, { data: payload })
  }
}

// global networks
// ---------------------------------------------------------------------------

export function* _GLOBAL_NETWORKS_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_NETWORKS_FETCH) {
  const result = yield* call(io.api.aggregator.getGlobalNetworks, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalNetworks, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_NETWORKS_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.GLOBAL_NETWORKS_UPDATE(result))

  return result
}

export function* _GLOBAL_NETWORKS_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_NETWORKS_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.aggregator.getGlobalNetworks,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalNetworks, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_NETWORKS_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

export function* _GLOBAL_NETWORK_POST(io: MiddlewaresIO, action: _HocActions.GLOBAL_NETWORK_POST) {
  const response = yield* call(io.api.aggregator.postGlobalNetwork, action.payload)

  return response
}

// org term
// ---------------------------------------------------------------------------

export function* _TERMS_FETCH(io: MiddlewaresIO, action: _HocActions.TERMS_FETCH) {
  const result = yield* call(io.api.aggregator.getOrgTerms, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getOrgTerms, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.TERMS_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.TERMS_UPDATE(result))

  return result
}

export function* _TERMS_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.TERMS_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.aggregator.getOrgTerms,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getOrgTerms, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.TERMS_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

export function* _ORG_TERM_POST(io: MiddlewaresIO, action: _HocActions.ORG_TERM_POST) {
  const response = yield* call(io.api.aggregator.postOrgTerm, action.payload)

  return response
}

// global hostnames
// ---------------------------------------------------------------------------

export function* _GLOBAL_HOSTNAME_POST(io: MiddlewaresIO, action: _HocActions.GLOBAL_HOSTNAME_POST) {
  const response = yield* call(io.api.aggregator.postGlobalHostname, action.payload)

  return response
}

export function* _GLOBAL_HOSTNAMES_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_HOSTNAMES_FETCH) {
  const result = yield* call(io.api.aggregator.getGlobalHostnames, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalHostnames, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.GLOBAL_HOSTNAMES_UPDATE(result))

  return result
}

export function* _GLOBAL_HOSTNAMES_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.GLOBAL_HOSTNAMES_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.aggregator.getGlobalHostnames,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalHostnames, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

// suggestion
// ---------------------------------------------------------------------------

export function* _SERVICE_SUGGESTIONS_BULK_POST(io: MiddlewaresIO, action: _HocActions.SERVICE_SUGGESTIONS_BULK_POST) {
  yield* call(io.api.aggregator.bulkPromoteUnscored, action.payload)
}

export function* _SERVICE_SUGGESTIONS_FETCH(io: MiddlewaresIO, action: _HocActions.SERVICE_SUGGESTIONS_FETCH) {
  const result = yield* call(io.api.aggregator.getServiceSuggestionsWithStats, action.payload)

  const { total: unfilteredTotal } = yield* call(
    io.api.aggregator.getServiceSuggestions,
    `?${qs.stringify({ limit: 0 })}`,
  )

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.SERVICE_SUGGESTION_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.SERVICE_SUGGESTIONS_STORE_UPDATE(result))

  return result
}

export function* _SERVICE_SUGGESTION_TOTALS_FETCH(
  io: MiddlewaresIO,
  action: _HocActions.SERVICE_SUGGESTION_TOTALS_FETCH,
) {
  const { total } = yield* call(
    io.api.aggregator.getServiceSuggestions,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(
    io.api.aggregator.getServiceSuggestions,
    `?${qs.stringify({ limit: 0 })}`,
  )

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.SERVICE_SUGGESTION_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

export function* _SERVICE_SUGGESTION_STATS_FETCH(
  io: MiddlewaresIO,
  action: _HocActions.SERVICE_SUGGESTION_STATS_FETCH,
) {
  const result = yield* call(io.api.aggregator.getServiceSuggestionStats, action.payload)

  yield* put(Store.HocActions.SERVICE_SUGGESTION_STATS_STORE_UPDATE(result))

  return result
}

export function* _SERVICE_SUGGESTION_PROMOTE(io: MiddlewaresIO, action: _HocActions.SERVICE_SUGGESTION_PROMOTE) {
  const { id, ...restPayload } = action.payload

  // @TODO: fix return type of Store.HocSelectors.selectServiceSuggestionById
  const _suggestion: any = yield* select(Store.HocSelectors.selectServiceSuggestionById, { id })
  const suggestion: Codecs.ServiceSuggestion = _suggestion

  const postPayload = [
    {
      ...restPayload,
      promoted: true,
      deleted: false,
    },
  ]

  const {
    ids: [newServiceId],
  } = yield* call(io.api.aggregator.postGlobalService, { data: postPayload })

  const globalTag = [
    {
      deleted: false,
      dst: newServiceId,
      dst_type: 'global_service' as const,
      tag: suggestion.tag,
    },
  ]

  yield* call(io.api.aggregator.postGlobalTags, { data: globalTag })
}

export function* _SERVICE_SUGGESTION_PATCH(io: MiddlewaresIO, action: _HocActions.SERVICE_SUGGESTION_PATCH) {
  const { id, ...payload } = action.payload

  const response = yield* call(io.api.aggregator.patchServiceSuggestion, id, { data: payload })

  return response
}

// service rules
// ---------------------------------------------------------------------------

export function* _SERVICE_RULES_FETCH(io: MiddlewaresIO, action: _HocActions.SERVICE_RULES_FETCH) {
  const result = yield* call(io.api.aggregator.getServiceRules, action.payload)

  const totals = {
    total: result.total,
    unfilteredTotal: result.total,
  }

  yield* put(Store.HocActions.SERVICE_RULES_STORE_UPDATE(result))
  yield* put(Store.HocActions.SERVICE_RULE_TOTALS_STORE_UPDATE(totals))

  return result
}

export function* _SERVICE_RULES_METADATA_FETCH(io: MiddlewaresIO, action: _HocActions.SERVICE_RULES_METADATA_FETCH) {
  const result = yield* call(io.api.aggregator.getServiceRulesMetadata, action.payload)

  yield* put(Store.HocActions.SERVICE_RULES_METADATA_STORE_UPDATE(result))

  return result
}

export function* _SERVICE_RULE_PATCH(io: MiddlewaresIO, action: _HocActions.SERVICE_RULE_PATCH) {
  const { id, ...rest } = action.payload

  const payload = {
    ...rest,
    matches_filter_strings: isNotNil(rest.matches_filter_strings)
      ? rest.matches_filter_strings.filter(isNotNilOrEmpty)
      : [],

    not_matches_filter_strings: isNotNil(rest.not_matches_filter_strings)
      ? rest.not_matches_filter_strings.filter(isNotNilOrEmpty)
      : [],

    service_capture_filter_strings: isNotNil(rest.service_capture_filter_strings)
      ? rest.service_capture_filter_strings.filter(isNotNilOrEmpty)
      : [],
  }

  const patchResponse = yield* call(io.api.aggregator.patchServiceRule, id, { data: payload })

  const q = btoa(
    JSON.stringify({
      condition: 'AND',
      rules: [
        {
          id: 'table.service_rule_metadata_id',
          field: 'table.id',
          type: 'object',
          input: 'text',
          operator: 'equal',
          value: id,
        },
      ],
    }),
  )

  const getResult = yield* call(io.api.aggregator.getServiceRules, `?${qs.stringify({ q: q })}`)

  yield* put(Store.HocActions.SERVICE_RULE_STORE_UPDATE(getResult))

  return patchResponse
}

export function* _SERVICE_RULE_POST(io: MiddlewaresIO, action: _HocActions.SERVICE_RULE_POST) {
  const payload = {
    ...action.payload.data,
    matches_filter_strings: isNotNil(action.payload.data.matches_filter_strings)
      ? action.payload.data.matches_filter_strings.filter(isNotNilOrEmpty)
      : [],

    not_matches_filter_strings: isNotNil(action.payload.data.not_matches_filter_strings)
      ? action.payload.data.not_matches_filter_strings.filter(isNotNilOrEmpty)
      : [],

    service_capture_filter_strings: isNotNil(action.payload.data.service_capture_filter_strings)
      ? action.payload.data.service_capture_filter_strings.filter(isNotNilOrEmpty)
      : [],
  }

  const postResponse = yield* call(io.api.aggregator.postServiceRule, { data: payload })

  const getResult = yield* call(io.api.aggregator.getServiceRules, action.payload.currentQuery)

  yield* put(Store.HocActions.SERVICE_RULES_STORE_UPDATE(getResult))

  return postResponse
}

// characteristic rules
// ---------------------------------------------------------------------------

export function* _CHARACTERISTIC_RULES_FETCH(io: MiddlewaresIO, action: _HocActions.CHARACTERISTIC_RULES_FETCH) {
  const result = yield* call(io.api.aggregator.getCharacteristicRules, action.payload)

  const totals = {
    total: result.total,
    unfilteredTotal: result.total,
  }

  yield* put(Store.HocActions.CHARACTERISTIC_RULES_STORE_UPDATE(result))
  yield* put(Store.HocActions.CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE(totals))

  return result
}

export function* _CHARACTERISTIC_RULES_METADATA_FETCH(
  io: MiddlewaresIO,
  action: _HocActions.CHARACTERISTIC_RULES_METADATA_FETCH,
) {
  const result = yield* call(io.api.aggregator.getCharacteristicRulesMetadata, action.payload)

  yield* put(Store.HocActions.CHARACTERISTIC_RULES_METADATA_STORE_UPDATE(result))

  return result
}

export function* _CHARACTERISTIC_RULE_PATCH(io: MiddlewaresIO, action: _HocActions.CHARACTERISTIC_RULE_PATCH) {
  const { id, ...rest } = action.payload

  const payload = {
    ...rest,
    matches_filter_strings: isNotNil(rest.matches_filter_strings)
      ? rest.matches_filter_strings.filter(isNotNilOrEmpty)
      : [],

    not_matches_filter_strings: isNotNil(rest.not_matches_filter_strings)
      ? rest.not_matches_filter_strings.filter(isNotNilOrEmpty)
      : [],
  }

  const patchResponse = yield* call(io.api.aggregator.patchCharacteristicRule, id, { data: payload })

  const q = btoa(
    JSON.stringify({
      condition: 'AND',
      rules: [
        {
          id: 'table.id',
          field: 'table.id',
          type: 'object',
          input: 'text',
          operator: 'equal',
          value: id,
        },
      ],
    }),
  )

  const getResult = yield* call(io.api.aggregator.getCharacteristicRules, `?${qs.stringify({ q: q })}`)

  yield* put(Store.HocActions.CHARACTERISTIC_RULE_STORE_UPDATE(getResult))

  return patchResponse
}

export function* _CHARACTERISTIC_RULE_POST(io: MiddlewaresIO, action: _HocActions.CHARACTERISTIC_RULE_POST) {
  const payload = {
    ...action.payload,
    matches_filter_strings: isNotNil(action.payload.data.matches_filter_strings)
      ? action.payload.data.matches_filter_strings.filter(isNotNilOrEmpty)
      : [],

    not_matches_filter_strings: isNotNil(action.payload.data.not_matches_filter_strings)
      ? action.payload.data.not_matches_filter_strings.filter(isNotNilOrEmpty)
      : [],
  }

  const postResponse = yield* call(io.api.aggregator.postCharacteristicRule, payload)

  const getResult = yield* call(io.api.aggregator.getCharacteristicRules, action.payload.currentQuery)

  yield* put(Store.HocActions.CHARACTERISTIC_RULES_STORE_UPDATE(getResult))

  return postResponse
}

// artifacts
// ---------------------------------------------------------------------------

export function* _ARTIFACTS_FETCH(io: MiddlewaresIO, action: _HocActions.ARTIFACTS_FETCH) {
  const result = yield* call(io.api.aggregator.getGlobalArtifacts, action.payload)

  // const { total: unfilteredTotal }: Codecs.ArtifactsResponse = yield call(
  //   io.api.aggregator.getGlobalArtifacts,
  //   `?${qs.stringify({ limit: 0 })}`
  // )

  const totals = {
    total: result.total,
  }

  yield* put(Store.HocActions.ARTIFACT_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.ARTIFACTS_STORE_UPDATE(result))

  return result
}

export function* _ARTIFACT_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.ARTIFACT_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.aggregator.getGlobalArtifacts,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getGlobalArtifacts, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.ARTIFACT_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

export function* _VALIDATE_SERVICE_REGEX(io: MiddlewaresIO, action: _HocActions.VALIDATE_SERVICE_REGEX) {
  const { artifact_ids, service_rule } = action.payload

  const payload = {
    artifact_ids,
    service_rule: {
      ...service_rule,
      matches_filter_strings: isNotNil(service_rule.matches_filter_strings)
        ? service_rule.matches_filter_strings.filter(isNotNilOrEmpty)
        : [],

      not_matches_filter_strings: isNotNil(service_rule.not_matches_filter_strings)
        ? service_rule.not_matches_filter_strings.filter(isNotNilOrEmpty)
        : [],

      service_capture_filter_strings: isNotNil(service_rule.service_capture_filter_strings)
        ? service_rule.service_capture_filter_strings.filter(isNotNilOrEmpty)
        : [],
    },
  }

  const response = yield* call(io.api.aggregator.tryServiceRule, payload)

  return response
}

export function* _VALIDATE_CHARACTERISTIC_REGEX(io: MiddlewaresIO, action: _HocActions.VALIDATE_CHARACTERISTIC_REGEX) {
  const { artifact_ids, characteristic_rule } = action.payload

  const payload = {
    artifact_ids,
    characteristic_rule: {
      ...characteristic_rule,

      matches_filter_strings: isNotNil(characteristic_rule.matches_filter_strings)
        ? characteristic_rule.matches_filter_strings.filter(isNotNilOrEmpty)
        : [],

      not_matches_filter_strings: isNotNil(characteristic_rule.not_matches_filter_strings)
        ? characteristic_rule.not_matches_filter_strings.filter(isNotNilOrEmpty)
        : [],
    },
  }

  const response = yield* call(io.api.aggregator.tryCharacteristicRule, payload)

  return response
}

// aggregator - pocs
// ---------------------------------------------------------------------------

export function* _POCS_FETCH(io: MiddlewaresIO, action: _HocActions.POCS_FETCH) {
  const result = yield* call(io.api.aggregator.getPocs, action.payload)

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getPocs, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total: result.total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.POCS_TOTALS_STORE_UPDATE(totals))
  yield* put(Store.HocActions.POCS_STORE_UPDATE(result))

  return result
}

export function* _POCS_TOTALS_FETCH(io: MiddlewaresIO, action: _HocActions.POCS_TOTALS_FETCH) {
  const { total } = yield* call(
    io.api.aggregator.getPocs,
    `?${qs.stringify({ q: qs.parse(action.payload).q, limit: 0 })}`,
  )

  const { total: unfilteredTotal } = yield* call(io.api.aggregator.getPocs, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    total,
    unfilteredTotal,
  }

  yield* put(Store.HocActions.POCS_TOTALS_STORE_UPDATE(totals))

  return {
    total: unfilteredTotal,
  }
}

export function* _POCS_POST(io: MiddlewaresIO, action: _HocActions.POCS_POST) {
  const res = yield* call(io.api.aggregator.postPoc, action.payload)

  return res
}

// ---------------------------------------------------------------------------

export function* _PERIODIC_POST(io: MiddlewaresIO, action: _HocActions.PERIODIC_POST) {
  const res = yield* call(io.api.scheduler.postPeriodic, action.payload)

  return res
}

export function* _POST_ORG_KEYVAL(io: MiddlewaresIO, action: _HocActions.POST_ORG_KEYVAL) {
  const res = yield* call(io.api.aggregator.postOrgKeyVal, action.payload)

  return res
}

// org primes
// ---------------------------------------------------------------------------

export function* _GET_ORG_PRIMES(io: MiddlewaresIO, action: _HocActions.GET_ORG_PRIMES) {
  const query = `?${qs.stringify({ org_id: action.payload.org_id })}`
  const result = yield* call(io.api.aggregator.getOrgPrimes, query)

  return result
}
