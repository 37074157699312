/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
// --------------------------------------------------------------------------------------- //
// The central location for generating top level query filters.                            //
// --------------------------------------------------------------------------------------- //
import { Condition, CrudRule, CrudRuleGroup, isRule, isRuleGroup, RuleOrRuleGroup } from '@randori/rootkit'

export const UI_ID_TOP_LEVEL_AND = '_group_tla'
export const UI_ID_NVD = '_group_nvd'
export const UI_ID_STANDARD = '_group_standard'
export const UI_ID_FTS = '_group_fts'

export const SINGLETON_GROUPS = new Set([UI_ID_NVD, UI_ID_STANDARD, UI_ID_FTS])

const PRUNABLE_NAMED_PARENT_GROUPS = new Set([UI_ID_TOP_LEVEL_AND, UI_ID_NVD, UI_ID_STANDARD, UI_ID_FTS])

export interface UiIdToModern {
  [ui_id: string]: string
}

// These are henceforth forbidden and actively checked at serialization time, and rewritten at deserialization time
const LEGACY_UI_ID_TO_MODERN: UiIdToModern = {
  base: UI_ID_STANDARD,
  fts: UI_ID_FTS,
}

// Can't serialize these ui_ids anymore.
const FORBIDDEN_UI_IDS = new Set(Object.keys(LEGACY_UI_ID_TO_MODERN))

// When deserializing a q-string, any thing that's left after populating the specific ui_id labelled rule groups, will
// default into this group if it remains unpopulated
export const UI_ID_LEFTOVER = UI_ID_STANDARD

// All groupings which DO NOT constitute a renderable logic group - meaning they can't be encapsulated and represented
// by title - for instance, FTS filter group can be called "FTS filters" in the UI, because each filter rule though
// independent, is based on the same query string.  In this case, these groupings are entirely for the sake of
// separation and don't necessarily equate to groupable rendering concerns.  Damn this is vague...
const LOGICAL_GROUP_UI_IDS = new Set([UI_ID_TOP_LEVEL_AND, UI_ID_NVD, UI_ID_STANDARD])

const GROUP_TOP_LEVEL_AND = {
  ui_id: UI_ID_TOP_LEVEL_AND,
  condition: 'AND',
  rules: [],
} as CrudRuleGroup

const GROUP_NVD = {
  ui_id: UI_ID_NVD,
  condition: 'AND',
  rules: [],
} as CrudRuleGroup

export const GROUP_STANDARD = {
  ui_id: UI_ID_STANDARD,
  condition: 'AND',
  rules: [],
} as CrudRuleGroup

export const GROUP_FTS = {
  ui_id: UI_ID_FTS,
  condition: 'OR',
  rules: [],
} as CrudRuleGroup

// Groups which will be top level AND'd together
const TOP_LEVEL_AND_GROUPS = [UI_ID_NVD, UI_ID_STANDARD, UI_ID_FTS]

const EMPTY_GROUP_BY_UI_ID = Object.fromEntries([
  [UI_ID_NVD, GROUP_NVD],
  [UI_ID_STANDARD, GROUP_STANDARD],
  [UI_ID_FTS, GROUP_FTS],
])

// These UI IDs should be dropped if not nested below any of the UI IDs listed in the value set
interface UiIdNestingRules {
  [uiId: string]: Set<string>
}
const EXPLICIT_NESTING_RULE = {
  'table.cpe': new Set([UI_ID_NVD, UI_ID_FTS]),
  'table.cve': new Set([UI_ID_NVD, UI_ID_FTS]),
  'table.base_score': new Set([UI_ID_NVD]),
} as UiIdNestingRules

/**
 * Returns the first non-empty rule group with a matching ui_id.  If no groups can be located, null is returned.
 *
 * @param {string} uiId
 * @param {CrudRuleGroup} ruleGroup
 * @return {*}  {(CrudRuleGroup | null)}
 */
export const getGroupByUiId = (uiId: string, ruleGroup: CrudRuleGroup, allowEmpty = true): CrudRuleGroup | null => {
  if (ruleGroup.ui_id === uiId) {
    return ruleGroup
  }

  for (const ruleOrGroup of ruleGroup.rules) {
    if (isRuleGroup(ruleOrGroup)) {
      const child = getGroupByUiId(uiId, ruleOrGroup, allowEmpty)
      if (child !== null && (allowEmpty === true || child.rules.length > 0)) {
        return child
      }
    }
  }

  return null
}

/**
 * Replaces a rule group in root, identified by uiId, with ruleGroup.  The replacement rule group will adopt the
 * specified uiId automatically.
 *
 * @param {CrudRuleGroup} root
 * @param {string} uiId
 * @param {CrudRuleGroup} ruleGroup
 * @return {*}  {CrudRuleGroup}
 */
export const replaceRuleGroup = (root: CrudRuleGroup, uiId: string, ruleGroup: CrudRuleGroup): CrudRuleGroup => {
  if (root.ui_id === uiId) {
    const newRuleGroup = {
      ...ruleGroup,
      ui_id: uiId,
    }
    return newRuleGroup
  }

  const rules = root.rules.map((ruleOrGroup) => {
    if (isRuleGroup(ruleOrGroup)) {
      return replaceRuleGroup(ruleOrGroup, uiId, ruleGroup)
    }

    return ruleOrGroup
  })

  return {
    ...root,
    rules,
  }
}

/*
 * Expands the FTS query as a set of independent rules within their own OR rule group, and
 * removes the corresponding FTS query.
 */
export const expandFTSQuery = (root: CrudRuleGroup): CrudRuleGroup => {
  let ftsGroup = getGroupByUiId(UI_ID_FTS, root)
  if (ftsGroup === null) {
    return root
  }

  ftsGroup = replaceRuleGroup(root, UI_ID_FTS, {
    ...ftsGroup,
    dontRenderAsRule: true,
  })

  return ftsGroup
}

/**
 * Returns a group will all the empty groups pruned.  Additionally, any rule ID which should belong to a subgroup of
 * parentId, which is found to exist outside of that subgroup, will be removed.  ALSO, upgrades legacy ui_id's to
 * the modern ones.
 *
 * @param {CrudRuleGroup} ruleGroup
 * @param {Set<string>} orphanedRuleIds
 * @param {string} parentId
 * @param {(Set<string> | null)} [ancestors=null]
 * @return {*}  {CrudRuleGroup}
 */
export const removeOrphanedRulesAndPrune = (
  ruleGroup: CrudRuleGroup,
  ancestors: Set<string> | null = null,
): CrudRuleGroup => {
  const currentAncestors: Set<string> = ancestors === null ? new Set() : new Set([...ancestors.values()])
  if (ruleGroup.ui_id !== undefined) {
    currentAncestors.add(ruleGroup.ui_id)
  }

  const rules: Array<RuleOrRuleGroup> = []
  for (const ruleOrGroup of ruleGroup.rules) {
    if (isRuleGroup(ruleOrGroup)) {
      const rg = removeOrphanedRulesAndPrune(ruleOrGroup, currentAncestors)
      if (rg.rules.length > 0) {
        rules.push(rg)
      }
    } else {
      if (
        ruleOrGroup.id === undefined ||
        EXPLICIT_NESTING_RULE[ruleOrGroup.id] === undefined ||
        new Set([...EXPLICIT_NESTING_RULE[ruleOrGroup.id]].filter((x) => currentAncestors.has(x))).size > 0
      ) {
        let uiId = ruleOrGroup.ui_id
        if (uiId !== undefined && FORBIDDEN_UI_IDS.has(uiId)) {
          uiId = LEGACY_UI_ID_TO_MODERN[uiId]
        }

        rules.push({
          ...ruleOrGroup,
          ui_id: uiId,
        })
      }
    }
  }

  let uiId = ruleGroup.ui_id
  if (uiId !== undefined && FORBIDDEN_UI_IDS.has(uiId)) {
    uiId = LEGACY_UI_ID_TO_MODERN[uiId]
  }

  return {
    ...ruleGroup,
    rules,
    ui_id: uiId,
  } as CrudRuleGroup
}

/**
 * Returns a cleansed and re-organized crud rule group from one which has been deserialized.  The idea is to call
 * this method to ingest something which came back from a q-string and ensure it is ready to be consumed by the
 * application, which can then make assumptions about the filter rule hierarchy.
 *
 * @param {CrudRuleGroup} ruleGroup
 * @return {*}  {CrudRuleGroup}
 */
export const getFilterHierarchy = (ruleGroup: CrudRuleGroup): CrudRuleGroup => {
  // First, cleanse the group, removing any empty groups, and removing any filters which aren't properly nested
  // in their respective group.  Also rename any legacy ui ids.
  ruleGroup = removeOrphanedRulesAndPrune(ruleGroup, null)

  // Next, extract the known rule groups
  let andGroups = TOP_LEVEL_AND_GROUPS.map((uiId) => {
    let extractedGroup = getGroupByUiId(uiId, ruleGroup, false)
    if (extractedGroup === null) {
      extractedGroup = EMPTY_GROUP_BY_UI_ID[uiId]
    }

    // Get rid of accidentally sub-nested singleton groups
    extractedGroup = _deduplicateGroup(extractedGroup)
    extractedGroup = pruneEmptyParents(extractedGroup)
    extractedGroup = nestRuleGroupIfExplicitlyNamed(extractedGroup, uiId, EMPTY_GROUP_BY_UI_ID[uiId].condition)

    return extractedGroup
  })

  const uiIds = new Set([...TOP_LEVEL_AND_GROUPS])
  let remaining = removeLabelledGroups(ruleGroup, uiIds)
  if (remaining !== null) {
    remaining = pruneEmptyGroups(remaining)
    if (remaining.rules.length > 0) {
      remaining = pruneEmptyParents(remaining)
      andGroups = andGroups.map((group) => {
        if (group.ui_id === UI_ID_LEFTOVER && group.rules.length === 0) {
          if (remaining === null) {
            throw new Error('Impossible')
          }
          return nestRuleGroupIfExplicitlyNamed(remaining, UI_ID_STANDARD, GROUP_STANDARD.condition)
        }

        return group
      })
    }
  }

  return {
    ...GROUP_TOP_LEVEL_AND,
    rules: andGroups,
  }
}

/**
 * Returns true if the ui_id represents a logical group (as opposed to a group that represents a broader rule)
 *
 * @param {string} ui_id
 * @return {*}  {boolean}
 */
export const isLogicalGroup = (ui_id: string): boolean => {
  return LOGICAL_GROUP_UI_IDS.has(ui_id)
}

/**
 * Prunes empty rule groups from the tree, then performs a second pass, pruning single nested rules, from the top down.
 * At least one rule group is always returned, even if it's empty.
 *
 * @param {CrudRuleGroup} root
 * @return {*}  {CrudRuleGroup}
 */
export const biDirectionalPrune = (root: CrudRuleGroup): CrudRuleGroup => {
  return pruneEmptyParents(pruneEmptyGroups(root))
}

/**
 * Prunes all empty groups from the hierarchy.  This should be called centrally by the serialize method prior
 * to sending rule groups q-strings to the server.  The root group is not pruned if empty.  ALSO does a check
 * to ensure that no forbidden rule group ui_ids are being used.
 *
 * @param {CrudRuleGroup} ruleGroup
 */
export const pruneEmptyGroups = (ruleGroup: CrudRuleGroup): CrudRuleGroup => {
  if (ruleGroup.ui_id !== undefined && FORBIDDEN_UI_IDS.has(ruleGroup.ui_id)) {
    throw new Error(`Serializing rule group with forbidden ui_id: ${ruleGroup.ui_id}`)
  }

  const rules = []
  for (const ruleOrGroup of ruleGroup.rules) {
    if (isRuleGroup(ruleOrGroup)) {
      const rg = pruneEmptyGroups(ruleOrGroup)
      if (rg.rules.length > 0) {
        rules.push(rg)
      }
    } else {
      if (ruleOrGroup.ui_id !== undefined && FORBIDDEN_UI_IDS.has(ruleOrGroup.ui_id)) {
        throw new Error(`Serializing rule with forbidden ui_id: ${ruleOrGroup.ui_id}`)
      }
      rules.push(ruleOrGroup)
    }
  }

  return {
    ...ruleGroup,
    rules,
  }
}

/**
 * Removes all rule groups named by uiIds, and returns whatever is left.
 *
 * @param {CrudRuleGroup} root
 * @param {Set<string>} uiIds
 * @return {*}  {(CrudRuleGroup | null)}
 */
export const removeLabelledGroups = (root: CrudRuleGroup, uiIds: Set<string>): CrudRuleGroup | null => {
  if (root.ui_id !== undefined && uiIds.has(root.ui_id)) {
    return null
  }

  const rules = []
  for (const ruleOrGroup of root.rules) {
    if (isRuleGroup(ruleOrGroup)) {
      const rg = removeLabelledGroups(ruleOrGroup, uiIds)
      if (rg !== null) {
        rules.push(rg)
      }
    } else {
      rules.push(ruleOrGroup)
    }
  }

  return {
    ...root,
    rules,
  }
}

/**
 * Extracts rule groups with ui_ids that match the set.
 *
 * @param {CrudRuleGroup} root
 * @param {Set<string>} uiIds
 * @return {*}  {CrudRuleGroup[]}
 */
export const extractGroupsWithLabelledUiIds = (root: CrudRuleGroup, uiIds: Set<string>): CrudRuleGroup[] => {
  let rules: CrudRuleGroup[] = []
  root.rules.forEach((rule) => {
    if (isRuleGroup(rule)) {
      if (rule.ui_id === undefined) {
        rules = [...extractGroupsWithLabelledUiIds(rule, uiIds)]
      } else if (uiIds.has(rule.ui_id)) {
        rules = [...rules, rule]
      }
    }
  })

  return rules
}

/**
 * Remove anything with UI IDs matching the set.
 *
 * @param {CrudRuleGroup} root
 * @param {Set<string>} uiIds
 * @return {*}  {CrudRuleGroup[]}
 */
export const removeGroupsWithLabelledUiIds = (root: CrudRuleGroup, uiIds: Set<string>): CrudRuleGroup => {
  let rules: RuleOrRuleGroup[] = []

  root.rules.forEach((rule) => {
    if (isRuleGroup(rule)) {
      if (rule.ui_id === undefined) {
        rules = [...rules, removeGroupsWithLabelledUiIds(rule, uiIds)]
      } else if (!uiIds.has(rule.ui_id)) {
        rules = [...rules, rule]
      }
    } else {
      rules = [...rules, rule]
    }
  })

  return {
    ...root,
    rules,
  }
}

/**
 * Returns a rule group or null.  Prunes all groups which have a single group rule as the child.  This allows us to
 * drill down to the relevant part of the group.
 *
 * @param {CrudRuleGroup} root
 * @return {*}  {CrudRuleGroup}
 */
export const pruneEmptyParents = (root: CrudRuleGroup): CrudRuleGroup => {
  if (root.rules.length != 1) {
    return root
  }

  // We don't want to prune away ui named single rule groups, b/c it could break some existing functionality
  if (root.ui_id !== undefined && !PRUNABLE_NAMED_PARENT_GROUPS.has(root.ui_id)) {
    return root
  }

  const ruleOrGroup = root.rules[0]
  if (isRule(ruleOrGroup)) {
    return root
  }

  return pruneEmptyParents(ruleOrGroup)
}

/**
 * Inserts a top level and-able group into the top level and rule group.
 *
 * @param {CrudRuleGroup} root
 * @param {CrudRuleGroup} ruleGroup
 * @return {*}  {CrudRuleGroup}
 */
export const insertAndableGroup = (root: CrudRuleGroup, ruleGroup: CrudRuleGroup): CrudRuleGroup => {
  if (root.ui_id !== UI_ID_TOP_LEVEL_AND) {
    throw new Error('This function only works with properly formatted rule groups (see getFilterHierarchy)')
  }

  return {
    ...root,
    rules: [...root.rules, ruleGroup],
  }
}

/**
 * Removes any rule or group with a matching ui_id, and prunes any empty groups.
 *
 * @param {CrudRuleGroup} root
 * @param {string} uiId
 * @return {*}  {CrudRuleGroup}
 */
export const removeUiIdAndPrune = (root: CrudRuleGroup, uiId: string): CrudRuleGroup => {
  return {
    ...root,
    rules: root.rules.filter((ruleOrGroup) => {
      if (ruleOrGroup.ui_id === uiId) {
        return false
      }

      if (isRuleGroup(ruleOrGroup)) {
        const rg = removeUiIdAndPrune(ruleOrGroup, uiId)
        return rg.rules.length > 0
      }

      return true
    }),
  }
}

/**
 * Removes any rule or group with a matching ui_id, and DO NOT prune any empty groups.
 *
 * @param {CrudRuleGroup} root
 * @param {string} uiId
 * @return {*}  {CrudRuleGroup}
 */
export const removeUiIdAndDontPrune = (root: CrudRuleGroup, uiId: string): CrudRuleGroup => {
  return {
    ...root,
    rules: root.rules.filter((ruleOrGroup) => {
      if (ruleOrGroup.ui_id === uiId) {
        return false
      }

      if (isRuleGroup(ruleOrGroup)) {
        removeUiIdAndDontPrune(ruleOrGroup, uiId)
      }

      return true
    }),
  }
}

/**
 * Removes nested logic groups which may have been left behind due to mismanagement of a group.  This should
 * only be performed on extracted groups within the main getRuleHierarchy.
 *
 * @param {CrudRuleGroup} root
 * @return {*}  {CrudRuleGroup}
 */
export const _deduplicateGroup = (root: CrudRuleGroup): CrudRuleGroup => {
  const rules: Array<RuleOrRuleGroup> = []

  root.rules.forEach((ruleOrGroup) => {
    if (isRuleGroup(ruleOrGroup)) {
      if (ruleOrGroup.ui_id === undefined || !SINGLETON_GROUPS.has(ruleOrGroup.ui_id)) {
        const rg = _deduplicateGroup(ruleOrGroup)
        if (rg.rules.length > 0) {
          rules.push(rg)
        }
      }
    } else {
      rules.push(ruleOrGroup)
    }
  })

  return {
    ...root,
    rules,
  }
}

/**
 * Nests a rule group in a container group if it has an explicitly named ui_id.  This prevents rule groups that behave
 * as single rules, from being renamed to top level groups with a standard ui_id.
 *
 * @param {CrudRuleGroup} root
 * @param {string} uiId
 * @param {string} Condition
 * @return {*}  {CrudRuleGroup}
 */
const nestRuleGroupIfExplicitlyNamed = (root: CrudRuleGroup, uiId: string, condition: Condition): CrudRuleGroup => {
  if (root.ui_id !== undefined && !PRUNABLE_NAMED_PARENT_GROUPS.has(root.ui_id)) {
    root = {
      condition,
      rules: [{ ...root }],
      ui_id: uiId,
    }
  } else {
    root = {
      ...root,
      ui_id: uiId,
    }
  }

  return root
}

export const isStandardRuleGroupSummaryClauseMatching = (
  andClauseGroup: CrudRuleGroup,
  testGroup: CrudRuleGroup,
): boolean => {
  if (andClauseGroup.ui_id !== testGroup.ui_id) {
    return false
  }

  if (andClauseGroup.rules.length !== testGroup.rules.length) {
    return false
  }

  for (let i = 0; i < andClauseGroup.rules.length; i++) {
    const r1 = andClauseGroup.rules[i] as CrudRule
    const r2 = testGroup.rules[i] as CrudRule

    if (r1.label !== r2.label) {
      return false
    }
  }

  return true
}

// matching clause is based on label - this is brittle AF but  we can use value because mutliple values have the same prioty label
export const isStandardRuleGroupSummaryClauseActive = (root: CrudRuleGroup, ruleGroup: CrudRuleGroup): boolean => {
  if (isStandardRuleGroupSummaryClauseMatching(root, ruleGroup)) {
    return true
  }

  for (const rule of root.rules) {
    if (isRuleGroup(rule)) {
      if (isStandardRuleGroupSummaryClauseActive(rule, ruleGroup) === true) {
        return true
      }
    }
  }

  return false
}

/**
 * Adds or removes an entity summary rule group to the `ui_id: "_group_standard"` rule group
 *
 * @param root - a `CrudRuleGroup` for the top level query string.
 * ie. the `ui_id` equals `_group_tla` and contains the nvd, standard, and full text search rules
 * @param toAction - the `CrudRuleGroup` to either add or remove. Rule group must contain a `ui_id`
 * that matches one of the values in `uiIds`
 * @param uiIds - a set of strings that corresponds the `ui_id` value in `toAction`. This set makes any additional filters added with the `OR` operator instead of `AND`
 * @param add - a boolean that determines if the `toAction` rule group is being added (true) or removed (false)
 *
 * @returns a new rule group with a filter added or removed
 */
export const addOrRemoveStandardRuleGroupSummaryClause = (
  root: CrudRuleGroup,
  toAction: CrudRuleGroup,
  uiIds: Set<string>,
  add: boolean,
): CrudRuleGroup => {
  if (root.ui_id !== UI_ID_TOP_LEVEL_AND) {
    throw Error('This function can only be applied to top level and groups')
  }

  if (toAction.ui_id === undefined) {
    throw Error('This rule cannot have an undefined ui id.')
  }

  // Get the standard rule group first
  let standardRuleGroup = getGroupByUiId(UI_ID_STANDARD, root)
  if (standardRuleGroup === null) {
    throw Error('Standard group cannot be null')
  }

  // Extract all the relevant rules groups

  let summaryGroups: Array<CrudRuleGroup> = extractGroupsWithLabelledUiIds(standardRuleGroup, uiIds).filter(
    (ruleGroup) => {
      return !isStandardRuleGroupSummaryClauseMatching(ruleGroup, toAction)
    },
  )

  if (add === true) {
    summaryGroups = [...summaryGroups, toAction]
  }

  // Remove them from the standard group and prune
  standardRuleGroup = removeGroupsWithLabelledUiIds(standardRuleGroup, uiIds)
  standardRuleGroup = biDirectionalPrune(standardRuleGroup)
  if (standardRuleGroup.ui_id !== UI_ID_STANDARD) {
    standardRuleGroup = {
      condition: 'AND',
      rules: [standardRuleGroup],
      ui_id: UI_ID_STANDARD,
    }
  }

  if (summaryGroups.length > 0) {
    const summaryGroup = {
      condition: 'OR',
      rules: [...summaryGroups],
    } as CrudRuleGroup

    if (standardRuleGroup.condition === 'AND') {
      standardRuleGroup = {
        ...standardRuleGroup,
        ui_id: UI_ID_STANDARD,
        rules: [...standardRuleGroup.rules, summaryGroup],
      }
    } else {
      standardRuleGroup = {
        ui_id: UI_ID_STANDARD,
        condition: 'AND',
        rules: [
          {
            ...standardRuleGroup,
          },
          summaryGroup,
        ],
      }
    }
  }

  return replaceRuleGroup(root, UI_ID_STANDARD, standardRuleGroup)
}
