/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import { Preference, PreferencePostPayload } from '@/codecs'

type Deferred = Catch.DeferredAction['meta']['deferred']

// ---------------------------------------------------------------------------

export enum TypeKeys {
  PREFERENCES_GET = 'PREFERENCES_GET',
  PREFERENCES_SET = 'PREFERENCES_SET',
  PREFERENCES_UPDATE = 'PREFERENCES_UPDATE',
}

export type ActionTypes = GetPreferences | UpdatePreferences

export type GetPreferences = {
  type: TypeKeys.PREFERENCES_GET
  meta: { deferred: Deferred }
}

export const getPreferences = (deferred: Deferred): GetPreferences => ({
  type: TypeKeys.PREFERENCES_GET,
  meta: { deferred },
})

export type UpdatePreferences = {
  type: TypeKeys.PREFERENCES_UPDATE
  payload: UpdatePreferencesPayload
}

export type UpdatePreferencesPayload = Preference[]

export const updatePreferences = (payload: UpdatePreferencesPayload): UpdatePreferences => {
  return {
    type: TypeKeys.PREFERENCES_UPDATE,
    payload,
  }
}

export type SetPreference = {
  type: TypeKeys.PREFERENCES_SET
  meta: { deferred: Deferred }
  payload: SetPreferencePayload
}

export type SetPreferencePayload = PreferencePostPayload

export const setPreference = (payload: SetPreferencePayload, deferred: Deferred): SetPreference => {
  return {
    type: TypeKeys.PREFERENCES_SET,
    meta: { deferred },
    payload,
  }
}
