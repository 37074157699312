/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'
import { Response, Server } from 'miragejs'

import { SUGGESTED_SERVICE_COUNTS_URL } from '../constants'

export const suggestedServiceRoutes = (server: Server): void => {
  // for loading table data
  server.get(SUGGESTED_SERVICE_COUNTS_URL, (schema) => {
    const allData = schema.db.suggestedServices
    const totalItems = allData.length

    if (isNotNilOrEmpty(allData)) {
      return {
        count: totalItems,
        data: allData,
        offset: 0,
        total: totalItems,
      }
    }

    return new Response(404, {}, { errors: ['Resource not found'] })
  })
}
