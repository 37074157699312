/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const _Affiliation = t.keyof({
  None: null,
  Unaffiliated: null,
})
type _Affiliation = t.TypeOf<typeof _Affiliation>

export const Affiliation = t.union([_Affiliation, t.null])
export type Affiliation = t.TypeOf<typeof Affiliation>

export enum AffiliationDisplay {
  None = 'Not Specified',
  Unaffiliated = 'Unaffiliated',
}

export enum AffiliationFieldValue {
  None = 'None',
  Unaffiliated = 'Unaffiliated',
}
