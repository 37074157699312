/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { omit as _omit } from 'lodash/fp'

// @see: https://github.com/gcanti/io-ts/issues/338
export function omit<O extends Record<string, unknown>, K extends keyof O>(o: O, k: K): Omit<O, K> {
  return _omit(k, o)
}
