/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { GrowlerContainer, Loader } from '@randori/rootkit'
import debug from 'debug'
import * as KlaroLib from 'klaro/dist/klaro-no-css'
import * as React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { compose, Store as ReduxStore } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'

import { RootApp } from '@/app'
import * as Codecs from '@/codecs'
import { PendoEmbed, SegmentEmbed, WorkatoEmbed, ZendeskEmbed } from '@/components/embed'
import * as Store from '@/store'
import * as CodecUtils from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { Flags } from '@/utilities/flags'
import * as GlobalUtils from '@/utilities/global'
import * as Klaro from '@/utilities/klaro'
import { lazyWithRetryInit } from '@/utilities/lazy'
import type { Tracer } from '@/utilities/tracer'
import * as Tracker from '@/utilities/tracker'

import './styles/main.scss'

import { makeMirageServer } from './mirage/server'
import { cb, report } from './utilities/web-vitals'

// ---------------------------------------------------------------------------

declare global {
  interface Window {
    __RANDORI__: Codecs.ConfigJson

    __RUTIL__: {
      activitiesReport: string
      artifact: string
      build: string
      debug: typeof debug
      flag: (flag: keyof typeof Flags) => void
      float: () => void
      getActivityFixture: (fixtureName: GlobalUtils.activityFixtures) => string | object
      getActivityFixtureList: () => GlobalUtils.activityFixturesList
      getAllFlags: () => Record<keyof typeof Flags, boolean>
      getFlag: (flag: keyof typeof Flags) => boolean
      parse: typeof CrudQueryUtils.RPARSE
      persistConfig: typeof GlobalUtils.persistConfig
      release: string
      rq: () => ReturnType<typeof CrudQueryUtils.RQ>
      store: ReduxStore<Store.AppState> | ReduxStore<void>
      tracer: typeof Tracer
    }

    __Cypress__?: boolean

    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (config: Record<string, unknown>) => typeof compose

    klaroConfig: KlaroLib.KlaroConfig
    klaro: typeof KlaroLib

    Workato: {
      configure: (args: { embeddingUrlPrefix: string }) => unknown
      extractWorkatoUrl: (url: string) => string
      generateIFrameUrl: (token: string, url: string) => string
      on: (type: string, nav: (navigation: { url: string }) => unknown) => unknown
    }

    pendo: {
      initialize: (config: Tracker.PendoInit) => void
      loadGuides: () => void
    }
  }
}

// ---------------------------------------------------------------------------

GlobalUtils.init()

const logInit = debug('RANDORI:init')

// web-vitals to console.log
report(cb)

// Iterate the values in config.json, and attach them to the window.
const writeEnv = (_configJson: unknown) => {
  const validate = CodecUtils.throwValidate(Codecs.ConfigJson, 'ConfigJson')
  const configJson = validate(_configJson)

  const persistedConfig = GlobalUtils.initPersistedConfig(configJson)

  window.__RANDORI__ = {
    ...configJson,
    LOGS: persistedConfig.rlog,
    STORE_LOGS: persistedConfig.rstorelog,
    TRACES: persistedConfig.rtrace,
    TRACK_ENV: persistedConfig.rtrack,
  }

  logInit('__RANDORI__', window.__RANDORI__)
}

const writeArtifactBaseUrl = () => {
  const initRartifact = () => {
    if (window.__RANDORI__.API_URL === '/') {
      window.__RUTIL__.artifact = `${window.location.protocol}//${window.location.hostname}/artifactstore/api/v1/user/retrieve/`
      window.__RUTIL__.activitiesReport = `${window.location.protocol}//${window.location.hostname}/recon/api/v1/attack-oppertunistic-csv/`
    } else {
      window.__RUTIL__.artifact = `${window.__RANDORI__.API_URL}/artifactstore/api/v1/user/retrieve/`
      window.__RUTIL__.activitiesReport = `${window.__RANDORI__.API_URL}/recon/api/v1/attack-oppertunistic-csv/`
    }
  }

  initRartifact()
}

// ---------------------------------------------------------------------------

// Note: This is required to ensure carbon styling gets applied
const AddAClassToBodySideEffect: React.FC = () => {
  const flags = Store.useAppSelector(Store.GateSelectors.staticFlags)

  React.useEffect(() => {
    // @TODO Carbon: when resuming carbon migration dev, will need to add conditional to check for the designated Carbon flag
    // and adding the correct carbon class. Because of current implementation, will likely need to define and use a class other
    // than 'carbon' (e.g. 'carbon-colors') in Rootkit so as not to unintentionally remove typography changes which have already
    // been released as of 12/15/2023
    if (flags.carbonColors) {
      document.body.classList.add('carbon-colors')
    }

    document.body.classList.add('carbon')
    return () => document.body.classList.remove('carbon-colors')
  }, [flags.carbonColors])

  return null
}

const init = () => {
  Klaro.init()
  lazyWithRetryInit()

  if (window.__RANDORI__.LOGS) {
    debug.enable('RANDORI:*')
  }

  const { persistor, store } = Store.configureStore({}, {})

  function _init(App: React.ComponentClass) {
    render(
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <App />

          <GrowlerContainer />

          <PendoEmbed />
          <SegmentEmbed />
          <WorkatoEmbed />
          <ZendeskEmbed />
          <AddAClassToBodySideEffect />
        </PersistGate>
      </Provider>,
      document.getElementById('root'),
    )
  }

  _init(RootApp)
}

const initTracer = () => {
  return import('@/utilities/tracer').then(({ Tracer }) => {
    window.__RUTIL__.tracer = Tracer
  })
}

// ---------------------------------------------------------------------------

if (process.env.NODE_ENV === 'development') {
  const persistedConfig = GlobalUtils.getPersistedConfig()

  Promise.resolve({
    API_URL: 'https://127.0.0.1:8182',
    CSV_RECORD_LIMIT: 2000,
    JWT_EXPIRY_WINDOW_SECONDS: 240,
    KEYCLOAK_CLIENT_ID: 'randori',
    KEYCLOAK_GLOBAL_CLIENT_ID: '',
    KEYCLOAK_GLOBAL_SSO_REALM_NAME: '',
    KEYCLOAK_GLOBAL_SVC_URL: '',
    KEYCLOAK_SVC_URL: 'http://localhost:9999',
    LOGS: false,
    RANDORI_FIXTURES: true,
    TRACES: persistedConfig.rtrace ?? false,
    TRACK_ENV: false,
    WORKATO_API_PROXY_URL: 'https://automate.app.randori.io',
    WORKATO_PREFIX: '/integrations',
    WORKATO_URL: 'https://www.workato.com',
  })
    .then(writeEnv)
    .then(writeArtifactBaseUrl)
    .then(initTracer)
    .then(init)
    .catch(logInit)
} else {
  fetch('/config.json')
    .then((response) => response.json())
    .then(writeEnv)
    .then(writeArtifactBaseUrl)
    .then(initTracer)
    .then(init)
    .catch(logInit)
}

// ---------------------------------------------------------------------------

if (process.env.MIRAGE_SERVER === 'true') {
  makeMirageServer()
}
