/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@/utilities/is-not'
import type { EntityType } from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export class RandoriEntityTypeError extends Error {
  entityType: EntityType

  constructor(message: { entityType: EntityType }, ...rest: string[]) {
    super(...rest)

    this.message = `Unsupported entityType: ${message.entityType}`
    this.name = 'RandoriEntityTypeError'

    this.entityType = message.entityType

    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (isNotNil(Error.captureStackTrace)) {
      Error.captureStackTrace(this, RandoriEntityTypeError)
    } else {
      this.stack = new Error(`Unsupported entityType: ${message.entityType}`).stack
    }
  }
}
