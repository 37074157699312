/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { ServiceP as Service } from '@/codecs'
import { TripleUUID } from '@/utilities/codec'

import { EntityName } from './service.constants'

// ---------------------------------------------------------------------------

export const normalizeServices = (records: Service[]) => {
  const Single = new schema.Entity(EntityName)
  const Plural = [Single]

  type NormalizedPlural = {
    result: TripleUUID[]
    entities: { service: Record<TripleUUID, Service> }
  }

  const entities: NormalizedPlural = normalize(records, Plural)

  return entities
}
