/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

import { removePatchedBars } from './hoc.reducers.utils'

// ---------------------------------------------------------------------------

export const BarsReducer = (state = Schemas.HocSchemas.NormalizeBars([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.BARS_STORE_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizeBars(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          bar: {
            ...state.entities.bar,
            ...entities.bar,
          },
        },
      }
    }

    case Store.HocActions.TypeKeys.BARS_STORE_UPDATE_P: {
      const { entities } = Schemas.HocSchemas.NormalizeBars(action.payload.data)

      return {
        result: state.result,
        entities: {
          ...state.entities,
          bar: {
            ...state.entities.bar,
            ...entities.bar,
          },
        },
      }
    }

    case Store.HocActions.TypeKeys.BARS_DROP: {
      return {
        ...state,
        result: state.result.filter(removePatchedBars(action.payload)),
      }
    }

    case Store.HocActions.TypeKeys.BAR_MARK_RECEIVED: {
      const next: Codecs.Bar[] = action.payload.map((id) => {
        const prevBar = state.entities.bar[id]

        return {
          ...prevBar,
          state: 'received',
        }
      })

      const { entities } = Schemas.HocSchemas.NormalizeBars(next)

      return {
        result: state.result,
        entities: {
          ...state.entities,
          bar: {
            ...state.entities.bar,
            ...entities.bar,
          },
        },
      }
    }

    default:
      return state
  }
}

export const BarsTotalsInitialState: Codecs.HocEntityTotals = {
  total: 0,
  unfilteredTotal: 0,
}

export const BarsTotalsReducer = (state = BarsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.BAR_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const BarsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const BarsPaginationReducer = (state = BarsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.BARS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
