/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { AuthoritativeEntityType, EntityType } from '@/codecs'
import { staticFlags } from '@/store/selectors/gate/gate.selectors'
import { ExhaustiveError, RandoriEntityTypeError } from '@/utilities/r-error'

// ----------------------------------------------------------------------------

export const mapTriggerAssetToEntityType = (
  triggerAssetType: AuthoritativeEntityType,
  _flags: ReturnType<typeof staticFlags>,
): EntityType => {
  switch (triggerAssetType) {
    case 'org_asset':
      return 'asset'

    case 'org_hostname':
      return 'hostname'

    case 'org_ip':
      return 'ip'

    case 'org_network':
      return 'network'

    case 'org_term':
      return 'term'

    case 'org_poc':
      return 'poc'

    case 'org_detection': {
      return 'bdo_detection'
    }

    case 'org_target':
      return 'target'

    default:
      throw new ExhaustiveError(triggerAssetType)
  }
}

export const mapEntityTypeToTriggerAsset = (entityType: EntityType): AuthoritativeEntityType => {
  switch (entityType) {
    case 'asset':
      return 'org_asset'

    case 'hostname':
      return 'org_hostname'

    case 'ip':
      return 'org_ip'

    case 'network':
      return 'org_network'

    case 'term':
      return 'org_term'

    case 'poc':
      return 'org_poc'

    case 'bdo_detection':
    case 'detection_target':
    case 'topLevelDetection':
      return 'org_detection'

    case 'target':
      return 'org_target'

    default:
      throw new RandoriEntityTypeError({ entityType })
  }
}
