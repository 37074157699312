/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

type Deferred = Catch.DeferredAction['meta']['deferred']

// ---------------------------------------------------------------------------

export enum TypeKeys {
  USER_FETCH = 'USER_FETCH',
  USER_UPDATE = 'USER_UPDATE', // putting this in the session reducer
}

export type ActionTypes = USER_FETCH | USER_UPDATE

// ---------------------------------------------------------------------------

type USER_FETCH = {
  type: TypeKeys.USER_FETCH
  meta: { deferred: Deferred }
}

export const USER_FETCH = (deferred: Deferred): USER_FETCH => ({
  type: TypeKeys.USER_FETCH,
  meta: { deferred },
})

type UpdateUserPayload = {
  email_verified: boolean
  locked: boolean
  login_sessions: number
  name: string
  organization: string
  signed_up: boolean
  title: string
}

type USER_UPDATE = {
  type: TypeKeys.USER_UPDATE
  payload: UpdateUserPayload
}

export const USER_UPDATE = (payload: UpdateUserPayload): USER_UPDATE => ({
  type: TypeKeys.USER_UPDATE,
  payload,
})
