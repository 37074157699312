/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type WorkatoAPI = {
  getAccountProperties: (recipeName: Codecs.WorkatoRecipeName) => Promise<Codecs.WorkatoGetAccountProperties>
  getConnections: () => Promise<Codecs.WorkatoConnections>
  getConnectionsCheck: (recipeName: Codecs.WorkatoRecipeName) => Promise<Codecs.WorkatoConnectionsCheck>
  getRecipes: () => Promise<Codecs.WorkatoRecipes>
  postAccountProperties: (payload: Codecs.WorkatoPostAccountProperties) => Promise<Codecs.WorkatoSuccess>
  postActivateRecipe: (recipe_name: Codecs.WorkatoRecipeName, recipe_id: number) => Promise<Codecs.WorkatoSuccess>
  postDeactivateRecipe: (recipe_id: number) => Promise<Codecs.WorkatoSuccess>
}

export function workatoApiFactory(http: HttpClient): WorkatoAPI {
  const baseUrl = '/workato/api/v1'

  return {
    getAccountProperties(recipeName: Codecs.WorkatoRecipeName) {
      return http
        .get(`${baseUrl}/integrations-proxy/recipes/account-properties?recipe_name=${recipeName}`)
        .then(CodecUtils.throwValidate(Codecs.WorkatoGetAccountProperties, 'WorkatoGetAccountProperties'))
    },

    getConnections() {
      return http
        .get(`${baseUrl}/integrations-proxy/connections`)
        .then(CodecUtils.throwValidate(Codecs.WorkatoConnections, 'WorkatoConnection'))
    },

    getConnectionsCheck(recipeName: Codecs.WorkatoRecipeName) {
      return http
        .get(`${baseUrl}/integrations-proxy/recipes/connection-check?recipe_name=${recipeName}`)
        .then(CodecUtils.throwValidate(Codecs.WorkatoConnectionsCheck, 'WorkatoConnectionsCheck'))
    },

    getRecipes() {
      return http
        .get(`${baseUrl}/integrations-proxy/recipes`)
        .then(CodecUtils.throwValidate(Codecs.WorkatoRecipes, 'WorkatoRecipes'))
    },

    postAccountProperties(payload) {
      return http
        .post(`${baseUrl}/integrations-proxy/recipes/account-properties`, payload)
        .then(CodecUtils.throwValidate(Codecs.WorkatoSuccess, 'WorkatoSuccess'))
    },

    postActivateRecipe(recipe_name: Codecs.WorkatoRecipeName, recipe_id: number) {
      return http
        .post(`${baseUrl}/integrations-proxy/recipes/activate`, { recipe_name, recipe_id })
        .then(CodecUtils.throwValidate(Codecs.WorkatoSuccess, 'WorkatoSuccess'))
    },

    postDeactivateRecipe(recipe_id: number) {
      return http
        .post(`${baseUrl}/integrations-proxy/recipes/deactivate`, { recipe_id })
        .then(CodecUtils.throwValidate(Codecs.WorkatoSuccess, 'WorkatoSuccess'))
    },
  }
}
