/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'

import * as Store from '@/store/actions'

import * as EntityDetailInit from './initializers'

// ---------------------------------------------------------------------------

export const initialState = {
  ['activity_configuration']: EntityDetailInit.initActivityConfiguration,
  ['activity_instance']: EntityDetailInit.initActivityInstance,
  ['asset']: EntityDetailInit.initAsset,
  ['authorization_policy']: EntityDetailInit.initAuthorizationPolicy,
  ['bdo_detection']: EntityDetailInit.initDetectionsP,
  ['detection_target']: EntityDetailInit.initDetections,
  ['exception_policy']: EntityDetailInit.initExceptionPolicy,
  ['hostname']: EntityDetailInit.initHostnames,
  ['implant']: EntityDetailInit.initImplant,
  ['ip']: EntityDetailInit.initIp,
  ['network']: EntityDetailInit.initNetworks,
  ['policy']: EntityDetailInit.initPolicy,
  ['redirector']: EntityDetailInit.initRedirector,
  ['runbook']: EntityDetailInit.initRunbook,
  ['savedViews']: EntityDetailInit.initSavedView,
  ['service']: EntityDetailInit.initService,
  ['social']: EntityDetailInit.initSocial,
  ['source']: EntityDetailInit.initSource,
  ['target']: EntityDetailInit.initTarget,

  // @TODO: Carefully remove this
  //
  // @see: https://randori.atlassian.net/browse/EN-10145
  ['topLevelDetection']: EntityDetailInit.initDetections,
}

export const EntityDetailNavigationItemsReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SET_ENTITY_DETAIL_NAV_ORDER_STATE: {
      return {
        ...state,
        [action.payload.entityType]: action.payload.navItems,
      }
    }

    case Store.UIActions.TypeKeys.UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES: {
      return {
        ...state,
        [action.payload.entityType]: action.payload.navItemIds.map((id) => {
          const navItem = state[action.payload.entityType].find((item) => item.id === id)

          if (!isNotNilOrEmpty(navItem)) {
            throw new Error(`${id} has no corresponding entity detail nav item for ${action.payload.entityType}`)
          }

          return navItem
        }),
      }
    }

    default: {
      return state
    }
  }
}
