/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { compose as _compose } from 'redux'

import { isNotNil } from '@/utilities/is-not'

import { getDevtoolsConfig } from './get-devtools-config'

// ---------------------------------------------------------------------------

export const compose = isNotNil(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(getDevtoolsConfig())
  : _compose
