/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { AddNetworkPaneProps } from '@/components/add-network-pane'
import { EditTableFieldsProps } from '@/components/edit-table-field-modal'
import { GuidancePaneProps } from '@/components/guidance-pane'
import { HowDidYouFindThisViewProps } from '@/components/hdyft/hdyft'
import {
  AuthorizationPolicyEditPaneProps,
  ExceptionPolicyEditPaneProps,
  PolicyEditPaneProps,
} from '@/components/policy-list/edit-policy-pane'
import { AuthorizationPolicyPaneProps } from '@/components/policy/authorization-policy-pane/constants'
import { ExceptionPolicyPaneProps } from '@/components/policy/exception-policy/constants'
import { PolicyPaneProps } from '@/components/policy/policy-pane'
import { EditSavedViewsPaneProps } from '@/components/saved-views-edit-pane'
import { GenericSlideoutProps } from '@/components/slideout/generic-slideout'
import { SourcePaneProps } from '@/components/source/constants'
import { TplSlideoutProps } from '@/components/tpl-copy-paste/tpl-view-slideout'
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

type ActiveSlideoutBase = Partial<{
  addlClasses: string | string[]
  side: 'left' | 'right'
}>

type EditSavedViewPane = {
  kind: 'edit-saved-view'
  childProps: EditSavedViewsPaneProps
} & ActiveSlideoutBase

type ViewTpl = {
  kind: 'tpl-view'
  childProps: TplSlideoutProps
} & ActiveSlideoutBase

type EditPolicy = {
  kind: 'policy-edit'
  childProps: PolicyEditPaneProps
} & ActiveSlideoutBase

type CreatePolicy = {
  kind: 'policy-create'
  childProps: PolicyPaneProps
} & ActiveSlideoutBase

type ViewPolicy = {
  kind: 'policy-view'
  childProps: PolicyPaneProps
} & ActiveSlideoutBase

type HowDidYouFindThis = {
  kind: 'hdyft'
  childProps: HowDidYouFindThisViewProps
} & ActiveSlideoutBase

type Generic = {
  kind: 'generic'
  childProps: GenericSlideoutProps
} & ActiveSlideoutBase

type GuidancePane = {
  kind: 'guidance-pane'
  childProps: GuidancePaneProps
} & ActiveSlideoutBase

type EditTableFields = {
  kind: 'edit-table-fields'
  childProps: EditTableFieldsProps
} & ActiveSlideoutBase

type CreateAuthorizationPolicy = {
  kind: 'authorization-policy-create'
  childProps: AuthorizationPolicyPaneProps
} & ActiveSlideoutBase

type EditAuthorizationPolicy = {
  kind: 'authorization-policy-edit'
  childProps: AuthorizationPolicyEditPaneProps
} & ActiveSlideoutBase

type CreateExceptionPolicy = {
  kind: 'exception-policy-create'
  childProps: ExceptionPolicyPaneProps
} & ActiveSlideoutBase

type EditExceptionPolicy = {
  kind: 'exception-policy-edit'
  childProps: ExceptionPolicyEditPaneProps
} & ActiveSlideoutBase

type CreateSource = {
  kind: 'source-create'
  childProps: SourcePaneProps
} & ActiveSlideoutBase

type AddNetwork = {
  kind: 'add-network'
  childProps: AddNetworkPaneProps
} & ActiveSlideoutBase

export type ActiveSlideout =
  | AddNetwork
  | CreateAuthorizationPolicy
  | CreateExceptionPolicy
  | CreatePolicy
  | CreateSource
  | EditAuthorizationPolicy
  | EditExceptionPolicy
  | EditPolicy
  | EditSavedViewPane
  | EditTableFields
  | Generic
  | GuidancePane
  | HowDidYouFindThis
  | ViewPolicy
  | ViewTpl

type SlideoutState = ActiveSlideout | null

export const SlideoutReducer = (state = null as SlideoutState, action: Store.ActionTypes): SlideoutState => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SHOW_SLIDEOUT:
      return action.payload

    case Store.UIActions.TypeKeys.HIDE_SLIDEOUT:
      return null

    default:
      return state
  }
}
