/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { GateSelectors } from '@/store'
import * as EntityUtils from '@/utilities/r-entity'

import { getInternalListByEntity, getInternalListByEntityAndFlags } from './internal-list'

// ---------------------------------------------------------------------------

/**
 * Determines if a given entity type is considered internal by checking it against a list of pre-defined
 * internal entity types
 *
 * @param dataType - string representing an entity type
 * @param useInternal - optional boolean (default false) that, if set to true, will
 * take precedence and force the function to return true
 *
 * @returns boolean that indicates whether a given data type (entity type) is considered internal
 */
export const isInternalField = (
  dataType: EntityUtils.EntityType,
  useInternal = false,
  staticFlags: ReturnType<typeof GateSelectors.staticFlags> | null = null,
) => {
  let internalList: string[] = []
  if (!staticFlags) {
    internalList = getInternalListByEntity(dataType)
  } else {
    internalList = getInternalListByEntityAndFlags(dataType, staticFlags)
  }

  return (c: string) => {
    return useInternal || internalList.includes(c)
  }
}
