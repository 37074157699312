/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Klaro from 'klaro/dist/klaro-no-css'

// ---------------------------------------------------------------------------

// @see: https://heyklaro.com/docs/integration/annotated-configuration

const services = [
  {
    name: 'randori',
    purposes: ['essential'],
    required: true,
  },

  {
    name: 'workato',
    purposes: ['integrations'],
  },

  {
    name: 'pendo',
    purposes: ['analytics'],
  },

  {
    name: 'segment',
    purposes: ['analytics'],
  },

  // {
  //   name: 'zendesk',
  //   purposes: ['analytics'],
  // },
]

const en = {
  workato: {
    title: 'Workato',
    description: 'Integration workflow partner.',
  },

  pendo: {
    title: 'Pendo',
    description: 'Product guidance and user analytics.',
  },

  segment: {
    title: 'Segment.io',
    description: 'User analytics.',
  },

  ok: 'I Accept',

  purposes: {
    analytics: {
      title: 'Analytics',
      description: 'Services that allow Randori to provide user guidance, and to analyze user behavior.',
    },

    essential: {
      title: 'Essential',
      description: "Services that are essential to Randori's core functionality.",
    },

    integrations: {
      title: 'Integrations',
      description: 'Services that allow Randori to integrate with 3rd-party tools.',
    },
  },

  privacyPolicyUrl: 'https://www.ibm.com/us-en/privacy',

  consentModal: {
    title: 'Services Consent',
    description:
      'Randori uses cookies and similar technology to enhance your experience with integrations, recognize your repeat visits & preferences, and deliver product usage insights.',
  },

  consentNotice: {
    description:
      'Randori uses cookies and similar technology to <b>enhance your experience with integrations, recognize your repeat visits & preferences</b>, and <b>deliver product usage insights</b>. To learn more about cookies, click "Let me choose". By clicking "I Accept", you consent to the use of cookies unless you have disabled them.',
  },
}

// @see: https://github.com/kiprotect/klaro/blob/master/src/lib.js
//
// We may want to disable autoLoad, and then render after `init` with a theme.
// This would clean up the CSS a bit.
export const config = {
  acceptAll: true,
  default: false,
  hideDeclineAll: true,
  htmlTexts: true,
  services,

  translations: {
    en,
  },
}

window.klaroConfig = config
window.klaro = Klaro

export const init = () => {
  window.klaro.setup(config)
}

export const manager = () => {
  return window.klaro.getManager()
}

export const render = () => {
  return window.klaro.render(config, { show: true })
}

// @TODO: Consider returning a promise
export const watch = (watcher: Klaro.Watcher, m = manager) => {
  const manager = m()

  manager.watch(watcher)
}

export const unwatch = (watcher: Klaro.Watcher, m = manager) => {
  const manager = m()

  manager.unwatch(watcher)
}

export const updateServiceStorage = (app: string, consent: boolean, m = manager) => {
  const manager = m()

  manager.updateServiceStorage(app, consent)
}

export const updateServiceElements = (app: string, consent: boolean, m = manager) => {
  const manager = m()

  manager.updateServiceElements(app, consent)
}
