/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@/utilities/is-not'

export const RFLOAT = () => {
  const isDisabled = window.localStorage.getItem('disable-floating-header')

  if (isNotNil(isDisabled)) {
    window.localStorage.removeItem('disable-floating-header')
  } else {
    window.localStorage.setItem('disable-floating-header', 'true')
  }
}
