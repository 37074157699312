/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export const EntityForActivityInstance = new schema.Entity(
  'entityForActivityInstance',
  {},
  {
    idAttribute: 'result__entity_id',
  },
)

export const EntitiesForActivityInstance = [EntityForActivityInstance]

/**
 * @param records - array of objects to parse into schema
 *
 * @returns a normalized schema for Entities for Activity Instances
 */
export function NormalizeEntitiesForActivityInstance(records: Codecs.EntityForActivityInstance[]) {
  type Entities = {
    entityForActivityInstance: Record<string, Codecs.EntityForActivityInstance> | undefined
  }

  const { result, entities } = normalize<typeof EntitiesForActivityInstance, Entities, string[]>(
    records,
    EntitiesForActivityInstance,
  )

  const _forActivityInstance = entities.entityForActivityInstance ?? {}

  return {
    result,
    entities: {
      entityForActivityInstance: _forActivityInstance,
    },
  }
}
