/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, IP, nullableP, UUID } from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { WithPerspective } from '../perspective.codecs'

// ---------------------------------------------------------------------------

const _IpForHostname = t.type({
  affiliation_state: Affiliation,
  confidence: nullableP(t.number),
  country: nullableP(t.string),
  hostname_id: nullableP(UUID),
  id: t.string,
  ip: nullableP(t.string),
  ip_id: nullableP(UUID),
  ip_str: nullableP(IP),
  last_seen: nullableP(DateString),
  latitude: nullableP(t.number),
  longitude: nullableP(t.number),
  open_port_count: nullableP(t.number),
  org_id: nullableP(t.string),
  radius: nullableP(t.number),
  service_count: nullableP(t.number),
  target_count: nullableP(t.number),
  target_temptation: nullableP(t.number),
  top_hostname: nullableP(t.string),
})

export type IpForHostnameP = t.TypeOf<typeof IpForHostnameP>
export const IpForHostnameP = t.intersection([_IpForHostname, WithPerspective])
