/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'

import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

// prettier-ignore
export type ReconApi = {
  authorizeDetections: (body: Codecs.AuthorizeDetectionsPayload) => Promise<unknown>
  deletePolicy: (id: string) => Promise<void>
  deleteSavedView: (id: string) => Promise<void> // 204 no response
  getArtifacts: (query: string) => Promise<Codecs.ReconArtifactResponse>
  getImpactScoreGroups: (body: Codecs.ImpactScoreBody) => Promise<Codecs.ImpactScoreResponse>
  getPeerGroups: (query: string) => Promise<Codecs.PeerGroupResponse>
  getPeerMap: (id: string) => Promise<Codecs.PeerMapResponse>
  getPeerMaps: (query: string) => Promise<Codecs.PeerMapsResponse>
  getPolicies: (query: string) => Promise<Codecs.PoliciesResponse>
  getPolicy: (id: string) => Promise<Codecs.PolicyResponse>
  getPriorityGroups: (body: Codecs.PriorityGroupsResponse) => Promise<Codecs.PriorityGroupsResponse>
  getSavedViews: (query: string) => Promise<Codecs.SavedViewsResponse>
  getSocialEntities: (query: string) => Promise<Codecs.SocialEntityResponse>
  getStatistics: (query: string) => Promise<Codecs.StatsResponse>
  getStatusGroups: (body: Codecs.StatusBody) => Promise<Codecs.StatusGroupsResponse>
  getTargetContext: (serviceId: string, query: string) => Promise<Codecs.TargetContext>
  getTargetTemptationGroups: (body: Codecs.TargetTemptationGroupsBody) => Promise<Codecs.TargetTemptationGroupsResponse>
  patchEntity: (body: Codecs.PatchEntityBody | CrudQueryUtils.PatchManyBody, type: ReturnType<typeof EntityUtils.getEndpointByType>,) => Promise<Codecs.PatchEntityResponse>
  patchPeerMap: (id: string, payload: Codecs.PeerMapPatchPayload) => Promise<Codecs.PeerMapPatchResponse>
  patchPolicy: (id: string, body: Codecs.PolicyPatchPayload) => Promise<Codecs.PolicyResponse>
  patchSavedView: (id: string, body: Codecs.SavedViewsPatchPayload) => Promise<Codecs.SavedViewsUpdateResponse>
  postPeerGroups: (payload: Codecs.PeerGroupPostPayload) => Promise<Codecs.PeerGroupPostResponse>
  postPeerMaps: (payload: Codecs.PeerMapPostPayload) => Promise<Codecs.PeerMapPostResponse>
  postPolicy: (body: Codecs.PolicyPostPayload) => Promise<Codecs.PolicyUpdateResponse>
  postSavedView: (body: Codecs.SavedViewsPostPayload) => Promise<Codecs.SavedViewsPostResponse>
} & AuthorizationPolicyApi & V1Api

export function reconApiFactory(http: HttpClient): ReconApi {
  const baseUrl = '/recon/api/v1'

  return {
    getTargetContext(serviceId: string, query: string) {
      const q = query.length > 0 ? `?q=${query}` : ''

      return http
        .get(`${baseUrl}/nvd/service/${serviceId}${q}`)
        .then(CodecUtils.throwValidate(Codecs.TargetContext, 'TargetContext'))
    },

    getPriorityGroups(query) {
      return http
        .post(`${baseUrl}/priority_groups`, query)
        .then(CodecUtils.throwValidate(Codecs.PriorityGroupsResponse, 'PriorityGroupsResponse'))
    },

    getTargetTemptationGroups(body) {
      return http
        .post(`${baseUrl}/target_temptation_groups`, body)
        .then(CodecUtils.throwValidate(Codecs.TargetTemptationGroupsResponse, 'TargetTemptationGroupsResponse'))
    },

    getStatusGroups(body) {
      return http
        .post(`${baseUrl}/status_groups`, body)
        .then(CodecUtils.throwValidate(Codecs.StatusGroupsResponse, 'StatusGroupsResponse'))
    },

    getImpactScoreGroups(body) {
      return http
        .post(`${baseUrl}/impact_score_groups`, body)
        .then(CodecUtils.throwValidate(Codecs.ImpactScoreResponse, 'ImpactScoreResponse'))
    },

    getSavedViews(query) {
      return http
        .get(`${baseUrl}/saved-views${query}`)
        .then(CodecUtils.throwValidate(Codecs.SavedViewsResponse, 'SavedViewsResponse'))
    },

    getStatistics(query) {
      return http
        .get(`${baseUrl}/statistics?${query}`)
        .then(CodecUtils.throwValidate(Codecs.StatsResponse, 'StatsResponse'))
    },

    getSocialEntities(query) {
      return http
        .get(`${baseUrl}/social-entity${query}`)
        .then(CodecUtils.throwValidate(Codecs.SocialEntityResponse, 'SocialEntityResponse'))
    },

    getArtifacts(query) {
      return http
        .get(`${baseUrl}/artifact${query}`)
        .then(CodecUtils.throwValidate(Codecs.ReconArtifactResponse, 'ReconArtifactResponse'))
    },

    patchEntity(body, type) {
      return http
        .patch(`${type}`, body)
        .then(CodecUtils.throwValidate(Codecs.PatchEntityResponse, 'PatchEntityResponse'))
    },

    patchSavedView(id, body) {
      return http
        .patch(`${baseUrl}/saved-views/${id}`, body)
        .then(CodecUtils.throwValidate(Codecs.SavedViewsUpdateResponse, 'SavedViewsUpdateResponse'))
    },

    postSavedView(body) {
      return http
        .post(`${baseUrl}/saved-views`, body)
        .then(CodecUtils.throwValidate(Codecs.SavedViewsPostResponse, 'SavedViewsPostResponse'))
    },

    deleteSavedView: async (id: string) => {
      return http.delete(`${baseUrl}/saved-views/${id}`)
    },

    getPeerGroups(query) {
      return http
        .get(`${baseUrl}/peer${query}`)
        .then(CodecUtils.throwValidate(Codecs.PeerGroupResponse, 'PeerGroupReponse'))
    },

    postPeerGroups(payload) {
      return http
        .post(`${baseUrl}/peer`, payload)
        .then(CodecUtils.throwValidate(Codecs.PeerGroupPostResponse, 'PeerGroupPostResponse'))
    },

    getPeerMaps(query) {
      return http
        .get(`${baseUrl}/peer-map${query}`)
        .then(CodecUtils.throwValidate(Codecs.PeerMapsResponse, 'PeerMapsResponse'))
    },

    getPeerMap(id) {
      return http
        .get(`${baseUrl}/peer-map/${id}`)
        .then(CodecUtils.throwValidate(Codecs.PeerMapResponse, 'PeerMapResponse'))
    },

    postPeerMaps(payload) {
      return http
        .post(`${baseUrl}/peer-map`, payload)
        .then(CodecUtils.throwValidate(Codecs.PeerMapPostResponse, 'PeerMapPostResponse'))
    },

    patchPeerMap(id, payload) {
      return http
        .patch(`${baseUrl}/peer-map/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.PeerMapPatchResponse, 'PeerMapPatchResponse'))
    },

    patchPolicy(id, body) {
      return http
        .patch(`${baseUrl}/policy/${id}`, body)
        .then(CodecUtils.throwValidate(Codecs.PolicyResponse, 'PolicyResponse'))
    },

    /* @deprecated: modify field { deleted: true } instead */
    deletePolicy: async (id: string) => {
      return http.delete(`${baseUrl}/policy/${id}`)
    },

    getPolicy(id) {
      return http.get(`${baseUrl}/policy/${id}`).then(CodecUtils.throwValidate(Codecs.PolicyResponse, 'PolicyResponse'))
    },

    getPolicies(query) {
      return http
        .get(`${baseUrl}/policy${query}`)
        .then(CodecUtils.throwValidate(Codecs.PoliciesResponse, 'PoliciesResponse'))
    },

    postPolicy(body) {
      return http
        .post(`${baseUrl}/policy`, body)
        .then(CodecUtils.throwValidate(Codecs.PolicyUpdateResponse, 'PolicyUpdateResponse'))
    },

    authorizeDetections(body) {
      const response = http.post(`${baseUrl}/manual-authorization`, body)

      return response
    },

    ...makeV1(http, baseUrl),
    ...makeAuthorizationPolicyEndpoint(http, baseUrl),
  }
}

type AuthorizationPolicyApi = {
  deleteAuthorizationPolicy: (id: string) => Promise<void>
  getAuthorizationPolicies: (query: string) => Promise<Codecs.PoliciesResponse>
  getAuthorizationPolicy: (id: string) => Promise<Codecs.PolicyResponse>
  patchAuthorizationPolicy: (id: string, body: Codecs.PolicyPatchPayload) => Promise<Codecs.PolicyResponse>
  postAuthorizationPolicy: (body: Codecs.PolicyPostPayload) => Promise<Codecs.PolicyUpdateResponse>
}

const makeAuthorizationPolicyEndpoint = (http: HttpClient, baseUrl: string): AuthorizationPolicyApi => {
  return {
    patchAuthorizationPolicy(id, body) {
      return http
        .patch(`${baseUrl}/authorization-policy/${id}`, body)
        .then(CodecUtils.throwValidate(Codecs.PolicyResponse, 'PolicyResponse'))
    },

    /* @deprecated: modify field { deleted: true } instead */
    deleteAuthorizationPolicy: async (id: string) => {
      return http.delete(`${baseUrl}/authorization-policy/${id}`)
    },

    getAuthorizationPolicy(id) {
      return http
        .get(`${baseUrl}/authorization-policy/${id}`)
        .then(CodecUtils.throwValidate(Codecs.PolicyResponse, 'PolicyResponse'))
    },

    getAuthorizationPolicies(query) {
      return http
        .get(`${baseUrl}/authorization-policy${query}`)
        .then(CodecUtils.throwValidate(Codecs.PoliciesResponse, 'PoliciesResponse'))
    },

    postAuthorizationPolicy(body) {
      return http
        .post(`${baseUrl}/authorization-policy`, body)
        .then(CodecUtils.throwValidate(Codecs.PolicyUpdateResponse, 'PolicyUpdateResponse'))
    },
  }
}

type V1Api = {
  getDataForIp: (dataType: string, query: string) => Promise<unknown>
  getDataForNetwork: (ipstr: string, dataType: string, limit?: number) => Promise<unknown>
  getDataForService: (ipstr: string, dataType: string, limit?: number) => Promise<unknown>
  getDetectionTargets: (query: string) => Promise<Codecs.DetectionTargetResponse>
  getDetections: (query: string) => Promise<Codecs.TopLevelDetectionsResponse>
  getHostname: (id: string) => Promise<Codecs.HostnameResponse>
  getHostnames: (query: string) => Promise<Codecs.HostnamesResponse>
  getIp: (ipstr: string) => Promise<Codecs.IpResponse>
  getIps: (query: string) => Promise<Codecs.IpsResponse>
  getIpsForHostname: (query: string) => Promise<Codecs.IpsForHostnameResponse>
  getIpsForNetwork: (query: string) => Promise<Codecs.IpsForNetworkResponse>
  getNetwork: (id: string) => Promise<Codecs.NetworkResponse>
  getNetworks: (query: string) => Promise<Codecs.NetworksResponse>
  getService: (id: string) => Promise<Codecs.ServiceResponse>
  getServices: (query: string) => Promise<Codecs.ServicesResponse>
  getSingleDetectionForTarget: (query: string) => Promise<Codecs.DetectionTargetResponse>
  getTargets: (query: string) => Promise<Codecs.DetectionTargetResponse>
}

const makeV1 = (http: HttpClient, baseUrl: string): V1Api => {
  const log = (msg: string) => {
    const _log = debug('RANDORI:recon.api.v1')

    _log(msg)

    if (debug.enabled('RANDORI:recon.api.v1')) {
      // eslint-disable-next-line
      console.trace()
    }
  }

  const _getSingleDetectionForTarget = (query: string) => {
    return http
      .get(`${baseUrl}/single-detection-for-target${query}`)
      .then(CodecUtils.throwValidate(Codecs.DetectionTargetResponse, 'DetectionTarget'))
  }

  const _targetFallback = (query: string) => (_err: Error) => {
    // fallback to old endpoint
    return _getSingleDetectionForTarget(query)
  }

  const _getTargets = (query: string) => {
    if (window.__RUTIL__.getFlag('targetRefactor')) {
      return http
        .get(`${baseUrl}/target${query}`)
        .then(CodecUtils.throwValidate(Codecs.DetectionTargetResponse, 'DetectionTargetResponse', false))
        .catch(_targetFallback(query))
    } else {
      return _getSingleDetectionForTarget(query)
    }
  }

  return {
    /**
     * @deprecated: Use the v2 API.
     */
    getHostnames(query) {
      log('getHostnames() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/hostname${query}`)
        .then(CodecUtils.throwValidate(Codecs.HostnamesResponse, 'HostnamesResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getHostname(id) {
      log('getHostname() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/hostname/${id}`)
        .then(CodecUtils.throwValidate(Codecs.HostnameResponse, 'HostnameResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getIpsForHostname(query) {
      log('getIpsForHostname() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/ips-for-hostname${query}`)
        .then(CodecUtils.throwValidate(Codecs.IpsForHostnameResponse, 'IpsForHostnameResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getIpsForNetwork(query) {
      log('getIpsForNetwork() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/ips-for-network${query}`)
        .then(CodecUtils.throwValidate(Codecs.IpsForNetworkResponse, 'IpsNetworkResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getTargets(query) {
      log('getTargets() is deprecated. Use the v2 API')

      return _getTargets(query)
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getSingleDetectionForTarget(query) {
      log('getSingleDetectionForTarget() is deprecated. Use the v2 API')

      return _getSingleDetectionForTarget(query)
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getDetectionTargets(query) {
      log('getDetectionTargets() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/all-detections-for-target${query}`)
        .then(CodecUtils.throwValidate(Codecs.DetectionTargetResponse, 'DetectionTargetResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getDataForIp(dataType, query) {
      log('getDataForIp() is deprecated. Use the v2 API')

      if (dataType === 'targets') {
        return _getTargets(query)
      }

      return http.get(`${baseUrl}/${dataType}-for-ip${query}`)
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getDataForService(dataType, query) {
      log('getDataForService() is deprecated. Use the v2 API')

      if (dataType === 'targets') {
        return _getTargets(query)
      }

      return http.get(`${baseUrl}/${dataType}-for-service${query}`)
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getDataForNetwork(dataType, query) {
      log('getDataForNetwork() is deprecated. Use the v2 API')

      if (dataType === 'targets') {
        return _getTargets(query)
      }

      return http.get(`${baseUrl}/${dataType}-for-network${query}`)
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getIp(id) {
      log('getIp() is deprecated. Use the v2 API')

      return http.get(`${baseUrl}/ip/${id}`).then(CodecUtils.throwValidate(Codecs.IpResponse, 'IpResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getIps(query) {
      log('getIps() is deprecated. Use the v2 API')

      return http.get(`${baseUrl}/ip${query}`).then(CodecUtils.throwValidate(Codecs.IpsResponse, 'IpsResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getNetwork(id: string) {
      log('getNetwork() is deprecated. Use the v2 API')

      return http.get(`${baseUrl}/network/${id}?`)
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getNetworks(query) {
      log('getNetworks() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/network${query}`)
        .then(CodecUtils.throwValidate(Codecs.NetworksResponse, 'NetworksResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getService(id) {
      log('getService() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/service/${id}`)
        .then(CodecUtils.throwValidate(Codecs.ServiceResponse, 'ServiceResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getServices(query) {
      log('getServices() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/service${query}`)
        .then(CodecUtils.throwValidate(Codecs.ServicesResponse, 'ServicesResponse'))
    },

    /**
     * @deprecated: Use the v2 API.
     */
    getDetections(query) {
      log('getDetections() is deprecated. Use the v2 API')

      return http
        .get(`${baseUrl}/detection${query}`)
        .then(CodecUtils.throwValidate(Codecs.TopLevelDetectionsResponse, 'TopLevelDetectionsResponse'))
    },
  }
}
