/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { NetworkP as Network } from '@/codecs'

import { makeNormalizer } from '../utilities/make-normalizer'
import { EntityName } from './network.constants'

// ---------------------------------------------------------------------------

export const normalizeNetworks = makeNormalizer<EntityName, Network>(EntityName)
