/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ConnectionsReducer = (
  state = Schemas.IntegrationSchemas.NormalizeConnections([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.WorkatoActions.TypeKeys.CONNECTIONS_STORE_UPDATE: {
      const { entities, result } = Schemas.IntegrationSchemas.NormalizeConnections(action.payload.result)

      return {
        result,
        entities: {
          ...state.entities,
          ...entities,

          // connection: {
          //   ...state.entities.connection,
          //   ...entities.connection,
          // },
        },
      }
    }

    default:
      return state
  }
}
