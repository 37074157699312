/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

//@TODO: Move to utilities/string
export const capitalize = (str: string) => {
  if (!str) {
    return ''
  } else {
    return `${str[0].toUpperCase()}${str.slice(1)}`
  }
}
