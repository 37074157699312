/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { ActionTypes, GlobalActions } from '@/store/actions'

// ---------------------------------------------------------------------------

type ApiSpecReducerState = Partial<Codecs.ApiSpecResponse>

const initialState: ApiSpecReducerState = {}

export const ApiSpecReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case GlobalActions.TypeKeys.OPENAPI_SET:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}
