/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

// formik field validators
// ---------------------------------------------------------------------------

export const testDomain = (allowedDomains: string[]) => (email: string | undefined | null) => {
  if (typeof email !== 'string') {
    return false
  }

  // @see: http://emailregex.com/
  // eslint-disable-next-line no-useless-escape
  const isEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (isEmail.test(email)) {
    const domain = email.split('@')[1]
    return allowedDomains.includes(domain)
  } else {
    return false
  }
}

export const doesNotContainProtocol = (domain: string | undefined | null) => {
  if (typeof domain !== 'string') {
    return false
  }

  if (domain.includes('/') || domain.includes(':')) {
    return false
  } else {
    return true
  }
}

// Only validate email if user attempts to update it
//
// This is a hack for now. <Formik /> validationSchema does not pass any
// Yup context, as can be seen below:
//
// @see: https://github.com/jaredpalmer/formik/blob/12c100bc8894e42206729e71f1cdf3765ad7c33c/src/Formik.tsx#L387
// @see: https://github.com/jaredpalmer/formik/blob/12c100bc8894e42206729e71f1cdf3765ad7c33c/src/Formik.tsx#L747
export const testDomainIfEmailChanged =
  (prevEmail: string | undefined | null, allowedDomains: string[]) => (nextEmail: string | undefined | null) => {
    if (typeof nextEmail !== 'string') {
      return false
    }

    if (prevEmail === nextEmail) {
      return true
    } else {
      return testDomain(allowedDomains)(nextEmail)
    }
  }

export const testName = (nextName: string | undefined | null) => {
  if (typeof nextName !== 'string') {
    return false
  }

  const isValidName = /^[^\\@#$%ˆ&*(){}|~<>;:[\]`]{1,80}$/

  return isValidName.test(nextName)
}

export const testTitle = (nextTitle: string | undefined | null) => {
  if (typeof nextTitle !== 'string') {
    const undefinedOrNull = nextTitle === null || nextTitle === undefined
    return undefinedOrNull
  }

  const isValidTitle = /^[^\\@#$%ˆ&*(){}|~<>;:[\]`]{1,80}$/

  if (nextTitle !== '') {
    return isValidTitle.test(nextTitle)
  } else {
    return true
  }
}
