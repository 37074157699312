/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { EntityPagination } from '@/codecs'
import { Source } from '@/codecs/source.codecs'

// ---------------------------------------------------------------------------
export const testSourceId = '481b7871-891a-4186-b3ab-2bc5a28b2c03'

export type SourceState = {
  pagination: EntityPagination
  result: string[]
  totals: { unfiltered: number; unaffiliated: number }

  entities: {
    source: Record<string, Source>
  }
}

export type EntityName = 'source'
export const EntityName: EntityName = 'source'

export const initialState: SourceState = {
  pagination: { count: 0, offset: 0, total: 0 },
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },

  entities: {
    source: {},
  },
}
