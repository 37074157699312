/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

// @see: https://randori.atlassian.net/wiki/spaces/HOC/pages/1246593039/Characteristics
export enum AUTO_TAG_DESCRIPTIONS {
  'DefaultPage' = 'Entities presenting default installation pages can indicate the system is not fully or properly configured. Systems that are not fully configured may not be properly hardened, or have other common configurations. Default pages can also indicate development or staging systems.',
  'Directory Listing' = 'This indicates a potential open directory listing has been discovered. Open directory listings can expose sensitive information or details for web services. Open directories can also be an indicator of a system being misconfigured or unmaintained.',
  'ExpiredSSLCert' = 'Expired SSL Certificates can indicate an unmaintained entity. Additionally systems with expired SSL certificates are often not monitored properly, creating a possible gap in coverage that an attacker would happily take advantage of.',
  'Internal' = 'Entities intended for internal use (defined per RFC 1918 or for hostnames with non-valid TLDs) that are exposed publicly are often done so by mistake and do not have the appropriate controls in place around them. It is often possible for an attacker to glean information about the internal structure of an environment by observing such data leakage. ',
  'Login' = 'Login pages indicate gated access to information and controls that could be valuable in furthering an attack if compromised. Login pages also provide useful templates to clone for use in phishing and other social engineering attacks.',
  'NoCSS' = 'The target does not include Cascading Style Sheets (CSS) stylization, which may imply the site is not intended to be exposed, is under active development, or is other wise unmaintained. ',
  'OldCopyright' = 'Entities presenting copyright dates significantly older than the current date indicate a potentially forgotten and unmonitored entity. Randori currently adds a characteristic on entities with copyright that is three or more years old.',
  'Redirect - Unencrypted' = 'A web server is redirecting the browser to a web page that does not encrypt traffic using SSL. While this in and of itself is not a vulnerability it can indicate an older instance of a web server and might garner additional attention from an attacker.',
  'SSO Portal' = 'Page is secured behind an SSO identity and authorization portal.',
  // 'Apache Default Page' = '',
  // 'Tomcat Default Page' = '',
  // 'Login Form' = '',
  // 'Nginx Default Test Page on RedHat' = '',
}

export enum TAG_KIND_DESCRIPTIONS {
  user = 'Tags allows authorized users to apply labels, keywords or terms to discovered items.',
  interesting = 'Characteristics are tags automatically applied by Randori to items with detected characteristics of interest.',
}

export type TagDescriptionString = keyof typeof AUTO_TAG_DESCRIPTIONS
export type TagKindDescriptionString = keyof typeof TAG_KIND_DESCRIPTIONS
