/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Affiliation } from '../affiliation.codecs'
import { ImpactScore } from '../impact-score.codecs'
import { Perspective, PerspectiveName } from '../perspective.codecs'
import { withPriority } from '../priority.codecs'

// ---------------------------------------------------------------------------

export type Hostname = t.TypeOf<typeof Hostname>
export const Hostname = withPriority(
  t.type({
    affiliation_state: Affiliation,
    characteristic_tags: t.array(t.string),
    confidence: t.union([t.number, t.null]),
    first_seen: t.union([t.string, t.null]), // @TODO: iso string
    hostname: t.union([t.string, t.null]),
    id: t.string,
    impact_score: ImpactScore,
    ip_count: t.union([t.number, t.null]),
    last_seen: t.union([t.string, t.null]), // @TODO: iso string
    only_in_review_targets: t.boolean,
    org_id: t.union([t.string, t.null]),
    perspective: Perspective,
    perspective_name: PerspectiveName,
    user_tags: t.array(t.string),
  }),
)
