/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotEmpty } from '@randori/rootkit'
import { reduce } from 'lodash/fp'
import { normalize, schema } from 'normalizr'
import { propOr } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export const ReconArtifact = new schema.Entity('recon-artifact')
export const ReconArtifacts = [ReconArtifact]

export function NormalizeReconArtifacts(records: Codecs.ReconArtifact[]) {
  type _ReconArtifacts = {
    ['recon-artifact']: {
      [index: string]: Codecs.ReconArtifact
    }
  }

  type NormalizedReconArtifacts = {
    entities: _ReconArtifacts
    result: string[]
  }

  const { result, entities } = normalize(records, ReconArtifacts)

  const _reconArtifacts = propOr({}, 'recon-artifact', entities)

  return {
    result,
    entities: {
      ['recon-artifact']: _reconArtifacts,
    },
  } as NormalizedReconArtifacts
}

// ---------------------------------------------------------------------------

export const Ip = new schema.Entity('ip')
export const Ips = [Ip]

export function NormalizeIps(records: Codecs.Ip[]) {
  type Entities = {
    ip: Record<string, Codecs.Ip> | undefined
  }

  const { result, entities } = normalize<typeof Ips, Entities, string[]>(records, Ips)

  const _ips = entities.ip ?? {}

  const normalized = {
    result,
    entities: {
      ip: _ips,
    },
  }

  return normalized
}

// ---------------------------------------------------------------------------

export const Hostname = new schema.Entity('hostname')
export const Hostnames = [Hostname]

export function NormalizeHostnames(records: Codecs.Hostname[]) {
  type _Hostnames = {
    hostname: {
      [index: string]: Codecs.Hostname
    }
  }

  type NormalizedHostnames = {
    entities: _Hostnames
    result: string[]
  }

  const { result, entities } = normalize(records, Hostnames)

  const _hostnames = propOr({}, 'hostname', entities)

  return {
    result,
    entities: {
      hostname: _hostnames,
    },
  } as NormalizedHostnames
}

// ---------------------------------------------------------------------------

export const Network = new schema.Entity('network')
export const Networks = [Network]

export function NormalizeNetworks(records: Codecs.Network[]) {
  type _Networks = {
    network: {
      [index: string]: Codecs.Network
    }
  }

  type NormalizedNetworks = {
    entities: _Networks
    result: string[]
  }

  const { result, entities } = normalize(records, Networks)

  const _networks = propOr({}, 'network', entities)

  return {
    result,
    entities: {
      network: _networks,
    },
  } as NormalizedNetworks
}

// ---------------------------------------------------------------------------

export const SavedView = new schema.Entity('saved-view')
export const SavedViews = [SavedView]

export function NormalizeSavedViews(records: Codecs.SavedViews[]) {
  type _SavedViews = {
    savedViews: {
      [index: string]: Codecs.SavedViews
    }
  }

  type NormalizedSavedViews = {
    entities: _SavedViews
    result: string[]
  }

  const { result, entities } = normalize(records, SavedViews)

  const _savedViews = propOr({}, 'saved-view', entities)

  return {
    result,
    entities: {
      savedViews: _savedViews,
    },
  } as NormalizedSavedViews
}

// ---------------------------------------------------------------------------

export const Service = new schema.Entity('service')
export const Services = [Service]

export function NormalizeServices(records: Codecs.Service[]) {
  type Entities = {
    service: Record<string, Codecs.Service> | undefined
  }

  const { result, entities } = normalize<typeof Services, Entities, string[]>(records, Services)

  const _services = entities.service ?? {}

  const byServiceId = isNotEmpty(result)
    ? reduce(
        (acc, serviceId) => {
          const _service = _services[serviceId]
          const actualServiceId = _service.id

          acc[actualServiceId] = actualServiceId

          return acc
        },
        {} as Record<string, string>,
        result,
      )
    : {}

  const normalized = {
    byServiceId,
    result,
    entities: {
      service: _services,
    },
  }

  return normalized
}

// ---------------------------------------------------------------------------

export const SocialEntity = new schema.Entity('socialEntity')
export const SocialEntities = [SocialEntity]

export function NormalizeSocialEntities(records: Codecs.SocialEntity[]) {
  type _SocialEntities = {
    socialEntity: {
      [index: string]: Codecs.SocialEntity
    }
  }

  type NormalizedSocialEntities = {
    entities: _SocialEntities
    result: string[]
  }

  const { result, entities } = normalize(records, SocialEntities)

  const _socialEntity = propOr({}, 'socialEntity', entities)

  return {
    result,
    entities: {
      socialEntity: _socialEntity,
    },
  } as NormalizedSocialEntities
}

// ---------------------------------------------------------------------------

export const Target = new schema.Entity<Codecs.DetectionTarget>('target')
export const Targets = [Target]

export function NormalizeTargets(records: Codecs.DetectionTarget[]) {
  type Entities = {
    target: Record<string, Codecs.DetectionTarget> | undefined
  }

  const { result, entities } = normalize<typeof Targets, Entities, string[]>(records, Targets)

  const _targets = entities.target ?? {}

  const byTargetId = isNotEmpty(result)
    ? reduce(
        (acc, targetId) => {
          const _target = _targets[targetId]
          const actualTargetId = _target.target_id

          acc[actualTargetId] = targetId

          return acc
        },
        {} as Record<string, string>,
        result,
      )
    : {}

  const normalized = {
    byTargetId,
    result,
    entities: {
      target: _targets,
    },
  }

  return normalized
}

// ---------------------------------------------------------------------------

export const Detection = new schema.Entity('detection')
export const Detections = [Detection]

type _Detections = {
  detection: {
    [index: string]: Codecs.Detection
  }
}

export type NormalizedDetections = {
  entities: _Detections
  result: string[]
}

export function NormalizeDetections(records: Codecs.Detection[]) {
  const { result, entities } = normalize(records, Detections)

  const _detection = propOr({}, 'detection', entities)

  return {
    result,
    entities: {
      detection: _detection,
    },
  } as NormalizedDetections
}

// ---------------------------------------------------------------------------

export const TargetForEntity = new schema.Entity(
  'target',
  {},
  {
    idAttribute: (entity) => `${entity.id},${entity.perspective}`,
  },
)

export const TargetsForEntities = [TargetForEntity]

export function NormalizeTargetsForEntity(records: Codecs.DetectionTarget[]) {
  type _Targets = {
    target: {
      [index: string]: Codecs.DetectionTarget
    }
  }

  type NormalizedTargets = {
    entities: _Targets
    result: string[]
  }

  const { result, entities } = normalize(records, TargetsForEntities)

  const _targets = propOr({}, 'target', entities)

  return {
    result,
    entities: {
      target: _targets,
    },
  } as NormalizedTargets
}

// ---------------------------------------------------------------------------

export const Policy = new schema.Entity('policy')
export const Policies = [Policy]

export function NormalizePolicies(records: Codecs.Policy[]) {
  type _Policies = {
    policy: {
      [index: string]: Codecs.Policy
    }
  }

  type NormalizedPolicies = {
    entities: _Policies
    result: string[]
  }

  const { result, entities } = normalize(records, Policies)

  const _policies = propOr({}, 'policy', entities)

  return {
    result,
    entities: {
      policy: _policies,
    },
  } as NormalizedPolicies
}

// ---------------------------------------------------------------------------

export const Report = new schema.Entity('report')
export const Reports = [Report]

export function NormalizeReports(records: Codecs.ASAReport | Codecs.ExecSummaryReport[]) {
  type _Reports = {
    report: {
      [index: string]: Codecs.ASAReport | Codecs.ExecSummaryReport
    }
  }

  type NormalizedReports = {
    entities: _Reports
    result: string[]
  }

  const { result, entities } = normalize(records, Reports)

  const _reports = propOr({}, 'report', entities)

  return {
    result,
    entities: {
      report: _reports,
    },
  } as NormalizedReports
}

// ---------------------------------------------------------------------------

export const TopLevelDetection = new schema.Entity('top-level-detection')
export const TopLevelDetections = [TopLevelDetection]

export function NormalizeTopLevelDetections(records: Codecs.TopLevelDetection[]) {
  type _TopLevelDetection = {
    'top-level-detection': {
      [index: string]: Codecs.TopLevelDetection
    }
  }

  type NormalizedTopLevelDetections = {
    entities: _TopLevelDetection
    result: string[]
  }

  const { result, entities } = normalize(records, TopLevelDetections)

  const _topLevelDetections = propOr({}, 'top-level-detection', entities)

  return {
    result,
    entities: {
      ['top-level-detection']: _topLevelDetections,
    },
  } as NormalizedTopLevelDetections
}

// ---------------------------------------------------------------------------

export const PeerGroup = new schema.Entity('peer-group')
export const PeerGroups = [PeerGroup]

export function NormalizePeerGroups(records: Codecs.PeerGroup[]) {
  type _PeerGroup = {
    'peer-group': {
      [index: string]: Codecs.PeerGroup
    }
  }

  type NormalizedPeerGroups = {
    entities: _PeerGroup
    result: string[]
  }

  const { result, entities } = normalize(records, PeerGroups)

  const _peerGroups = propOr({}, 'peer-group', entities)

  return {
    result,
    entities: {
      ['peer-group']: _peerGroups,
    },
  } as NormalizedPeerGroups
}

// ---------------------------------------------------------------------------

export const PeerMap = new schema.Entity('peer-map')
export const PeerMaps = [PeerMap]

export function NormalizePeerMaps(records: Codecs.PeerMap[]) {
  type _PeerMap = {
    'peer-map': {
      [index: string]: Codecs.PeerMap
    }
  }

  type NormalizedPeerMaps = {
    entities: _PeerMap
    result: string[]
  }

  const { result, entities } = normalize(records, PeerMaps)

  const _peerMaps = propOr({}, 'peer-map', entities)

  return {
    result,
    entities: {
      ['peer-map']: _peerMaps,
    },
  } as NormalizedPeerMaps
}
