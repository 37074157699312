/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { ExceptionPolicy } from '@/codecs/exception-policy.codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './exception-policy.constants'

// ---------------------------------------------------------------------------

export function normalizeExceptionPolicies(records: ExceptionPolicy[]) {
  const ExceptionPolicy = new schema.Entity(EntityName)
  const ExceptionPolicies = [ExceptionPolicy]

  type NormalizedExceptionPolicies = {
    result: UUID[]
    entities: {
      [EntityName]: Record<UUID, ExceptionPolicy>
    }
  }

  const exceptionPolicies: NormalizedExceptionPolicies = normalize(records, ExceptionPolicies)

  return exceptionPolicies
}
