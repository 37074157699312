/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// @see: https://github.com/gcanti/io-ts-types/blob/master/src/fromRefinement.ts
function fromRefinement<A>(name: string, is: (u: unknown) => u is A): t.Type<A, A, unknown> {
  return new t.Type(name, is, (u, c) => (is(u) ? t.success(u) : t.failure(u, c)), t.identity)
}

// @see: https://github.com/gcanti/io-ts-types/blob/master/src/date.ts
export type DateC = t.Type<Date, Date, unknown>
const isDate = (u: unknown): u is Date => u instanceof Date
export const date: DateC = fromRefinement('Date', isDate)

export interface DateStringBrand {
  readonly DateString: unique symbol
}
export type DateString = t.Branded<string, DateStringBrand>
export const DateString = t.brand(
  t.string,
  (s): s is DateString => {
    return new Date(s).toDateString() !== 'Invalid Date'
  },
  'DateString',
)
