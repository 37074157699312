/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ArtifactsReducer = (state = Schemas.HocSchemas.NormalizeArtifacts([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.ARTIFACTS_STORE_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizeArtifacts(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          artifact: {
            ...state.entities.artifact,
            ...entities.artifact,
          },
        },
      }
    }

    default:
      return state
  }
}

export const ArtifactsTotalsInitialState: Omit<Codecs.HocEntityTotals, 'unfilteredTotal'> = {
  total: 0,
}

export const ArtifactsTotalsReducer = (state = ArtifactsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.ARTIFACT_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const ArtifactsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ArtifactsPaginationReducer = (state = ArtifactsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.ARTIFACTS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
