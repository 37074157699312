/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { prop, propOr } from 'ramda'

import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export function getScheme(dc: Codecs.DetectionTarget['detection_criteria']): string {
  if (!prop('http', dc)) {
    return ''
  } else {
    return prop('tls', dc) ? 'https' : 'http'
  }
}

export function getHttpHost(dc: Codecs.DetectionTarget['detection_criteria']): string | null {
  const http = propOr({}, 'http', dc)

  return propOr(null, 'host', http)
}

export function getHttpPath(dc: Codecs.DetectionTarget['detection_criteria']): string {
  const http = propOr({}, 'http', dc)

  return propOr('', 'path', http)
}

export function getIpAddress(dc: Codecs.DetectionTarget['detection_criteria']): string {
  const ip = propOr({}, 'ip', dc)

  return propOr('', 'address', ip)
}

export function getTcpPort(dc: Codecs.DetectionTarget['detection_criteria']): number {
  const tcp = propOr({}, 'tcp', dc)

  return propOr(null, 'port', tcp)
}

export function getUrl(dc: Codecs.DetectionTarget['detection_criteria']): string {
  const _host = getHttpHost(dc)
  const _path = getHttpPath(dc)
  const _ip = getIpAddress(dc)

  const host = isNotNil(_host) ? _host : _ip
  const path = _path.startsWith('/') ? _path : `/${_path}`
  const port = _ip ? getTcpPort(dc) : ''
  const _scheme = getScheme(dc)
  const scheme = _scheme.length ? `${_scheme}://` : ''

  if (!host) {
    return ''
  }

  if ((scheme === 'http' && port === 80) || (scheme === 'https' && port === 443)) {
    return `${scheme}${host}${path}`
  } else {
    return `${scheme}${host}:${port}${path}`
  }
}

export function getSchemaFromDetection(detectionCriteria: Codecs.DetectionTarget['detection_criteria']): {
  domain: string | null
  // @TODO: remove httpHost, it's confusing
  httpHost: string | null
  ipAddr: string
  scheme: string
  tcpPort: number
  url: string
} {
  const domain = getHttpHost(detectionCriteria)
  const httpHost = getHttpHost(detectionCriteria)
  const ipAddr = `${getIpAddress(detectionCriteria)}`
  const scheme = `${getScheme(detectionCriteria)}`
  const tcpPort = getTcpPort(detectionCriteria)
  const url = `${getUrl(detectionCriteria)}`

  return {
    domain,
    httpHost,
    ipAddr,
    scheme,
    tcpPort,
    url,
  }
}
