/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import { MiddlewaresIO } from '@/store/store.utils'

import * as AuthorizationPolicySagas from './authorization-policy.sagas'
import { _CSV_DOWNLOAD } from './csv-download'
import * as DetectionSagas from './detection.sagas'
import * as ReconSagas from './recon.sagas'

export function* watchRecon(io: MiddlewaresIO) {
  yield takeEvery(
    Store.ReconActions.TypeKeys.ENTITY_GROUPING_FETCH,
    Catch.deferredAction(ReconSagas._ENTITY_GROUPING_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.FETCH_QUERY_AS_GROUPING,
    Catch.deferredAction(ReconSagas._FETCH_QUERY_AS_GROUPING, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_DATA_FOR_ENTITY_FETCH,
    Catch.deferredAction(ReconSagas._RECON_DATA_FOR_ENTITY_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_DATA_FOR_HOSTNAME_FETCH,
    Catch.deferredAction(ReconSagas._RECON_DATA_FOR_HOSTNAME_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_ENTITY_PATCH,
    Catch.deferredAction(ReconSagas._RECON_ENTITY_PATCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_HOSTNAMES_FETCH,
    Catch.deferredAction(ReconSagas._RECON_HOSTNAMES_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_HOSTNAME_FETCH,
    Catch.deferredAction(ReconSagas._RECON_HOSTNAME_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.RECON_IPS_FETCH, Catch.deferredAction(ReconSagas._RECON_IPS_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_IPS_FOR_HOSTNAME_FETCH,
    Catch.deferredAction(ReconSagas._RECON_IPS_FOR_HOSTNAME_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_IPS_FOR_NETWORK_FETCH,
    Catch.deferredAction(ReconSagas._RECON_IPS_FOR_NETWORK_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_MISSED_AFFILIATIONS_POST,
    Catch.deferredAction(ReconSagas._RECON_MISSED_AFFILIATIONS_POST, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_NETWORKS_FETCH,
    Catch.deferredAction(ReconSagas._RECON_NETWORKS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_NETWORK_FETCH,
    Catch.deferredAction(ReconSagas._RECON_NETWORK_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_SAVED_VIEWS_FETCH,
    Catch.deferredAction(ReconSagas._RECON_SAVED_VIEWS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_SERVICES_FETCH,
    Catch.deferredAction(ReconSagas._RECON_SERVICES_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_SERVICE_FETCH,
    Catch.deferredAction(ReconSagas._RECON_SERVICE_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_STATS_PRIO_FETCH,
    Catch.deferredAction(ReconSagas._RECON_STATS_PRIO_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_TARGETS_FETCH,
    Catch.deferredAction(ReconSagas._RECON_TARGETS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_TARGETS_TOTAL_FETCH,
    Catch.deferredAction(ReconSagas._RECON_TARGETS_TOTAL_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_TARGET_COUNT_FOR_ENTITY_FETCH,
    Catch.deferredAction(ReconSagas._RECON_TARGET_COUNT_FOR_ENTITY_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.RECON_TT_FETCH, Catch.deferredAction(ReconSagas._RECON_TT_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.RECON_TT_FOR_HOSTNAME_FETCH,
    Catch.deferredAction(ReconSagas._RECON_TT_FOR_HOSTNAME_FETCH, io),
  )

  // -------------------------------------------------------------------------

  yield takeEvery(
    Store.ReconActions.TypeKeys.CHARACTERISTICS_BY_PRIORITY_FETCH,
    Catch.deferredAction(ReconSagas._CHARACTERISTICS_BY_PRIORITY_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.CSV_DOWNLOAD, Catch.deferredAction(_CSV_DOWNLOAD, io))
  yield takeEvery(Store.ReconActions.TypeKeys.DETECTIONS_FETCH, Catch.deferredAction(ReconSagas._DETECTIONS_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.DETECTIONS_FOR_TARGETS_FETCH,
    Catch.deferredAction(ReconSagas._DETECTIONS_FOR_TARGETS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.DETECTIONS_FOR_TARGETS_FETCH_P,
    Catch.deferredAction(ReconSagas._DETECTIONS_FOR_TARGETS_FETCH_P, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.ENTITY_TOTAL_COUNT_FETCH,
    Catch.deferredAction(ReconSagas._ENTITY_TOTAL_COUNT_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.HOSTNAMES_FETCH, Catch.deferredAction(ReconSagas._HOSTNAMES_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.HOSTNAME_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._HOSTNAME_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.IPS_FETCH, Catch.deferredAction(ReconSagas._IPS_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.IP_FETCH, Catch.deferredAction(ReconSagas._IP_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.HOSTNAME_FETCH, Catch.deferredAction(ReconSagas._HOSTNAME_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.IP_TOTALS_FETCH, Catch.deferredAction(ReconSagas._IP_TOTALS_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.NETWORK_FETCH, Catch.deferredAction(ReconSagas._NETWORK_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.NETWORKS_FETCH, Catch.deferredAction(ReconSagas._NETWORKS_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.NETWORK_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._NETWORK_TOTALS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.PEER_GROUPS_FETCH,
    Catch.deferredAction(ReconSagas._PEER_GROUPS_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.PEER_GROUPS_POST, Catch.deferredAction(ReconSagas._PEER_GROUPS_POST, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.PEER_GROUP_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._PEER_GROUP_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.PEER_MAPS_FETCH, Catch.deferredAction(ReconSagas._PEER_MAPS_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.PEER_MAPS_POST, Catch.deferredAction(ReconSagas._PEER_MAPS_POST, io))
  yield takeEvery(Store.ReconActions.TypeKeys.PEER_MAP_FETCH, Catch.deferredAction(ReconSagas._PEER_MAP_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.PEER_MAP_PATCH, Catch.deferredAction(ReconSagas._PEER_MAP_PATCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.PEER_MAP_UPSERT, Catch.deferredAction(ReconSagas._PEER_MAP_UPSERT, io))
  yield takeEvery(Store.ReconActions.TypeKeys.POLICIES_FETCH, Catch.deferredAction(ReconSagas._POLICIES_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.POLICIES_PATCH, Catch.deferredAction(ReconSagas._POLICIES_PATCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.POLICIES_POST, Catch.deferredAction(ReconSagas._POLICIES_POST, io))
  yield takeEvery(Store.ReconActions.TypeKeys.POLICY_DELETE, Catch.deferredAction(ReconSagas._POLICY_DELETE, io))
  yield takeEvery(Store.ReconActions.TypeKeys.POLICY_FETCH, Catch.deferredAction(ReconSagas._POLICY_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.POLICY_FETCH_MATCHING_ENTITIES,
    Catch.deferredAction(ReconSagas._POLICY_FETCH_MATCHING_ENTITIES, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.POLICY_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._POLICY_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.SAVED_VIEW_FETCH, Catch.deferredAction(ReconSagas._SAVED_VIEW_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.SAVED_VIEWS_DELETE,
    Catch.deferredAction(ReconSagas._SAVED_VIEWS_DELETE, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.SAVED_VIEWS_FETCH,
    Catch.deferredAction(ReconSagas._SAVED_VIEWS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.SAVED_VIEWS_FETCH_ALL,
    Catch.deferredAction(ReconSagas._SAVED_VIEWS_FETCH_ALL, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.SAVED_VIEWS_PATCH,
    Catch.deferredAction(ReconSagas._SAVED_VIEWS_PATCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.SAVED_VIEWS_POST, Catch.deferredAction(ReconSagas._SAVED_VIEWS_POST, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.SAVED_VIEWS_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._SAVED_VIEWS_TOTALS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.SCREENSHOTS_FOR_TARGETS_FETCH,
    Catch.deferredAction(ReconSagas._SCREENSHOTS_FOR_TARGETS_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.SERVICES_FETCH, Catch.deferredAction(ReconSagas._SERVICES_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.SERVICE_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._SERVICE_TOTALS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.SOCIAL_ENTITIES_FETCH,
    Catch.deferredAction(ReconSagas._SOCIAL_ENTITIES_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.SOCIAL_ENTITY_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas.SOCIAL_ENTITY_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.TARGETS_FETCH, Catch.deferredAction(ReconSagas._TARGETS_FETCH, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.TARGETS_FOR_ENTITY_FETCH,
    Catch.deferredAction(ReconSagas._TARGETS_FOR_ENTITY_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.TARGETS_FOR_NETWORK_FETCH,
    Catch.deferredAction(ReconSagas._TARGETS_FOR_NETWORK_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.TARGET_CONTEXT_FETCH,
    Catch.deferredAction(ReconSagas._TARGET_CONTEXT_FETCH, io),
  )
  yield takeEvery(Store.ReconActions.TypeKeys.TARGET_FETCH, Catch.deferredAction(ReconSagas._TARGET_FETCH, io))
  yield takeEvery(Store.ReconActions.TypeKeys.TARGET_PATCH, Catch.deferredAction(ReconSagas._TARGET_PATCH as any, io))
  yield takeEvery(
    Store.ReconActions.TypeKeys.TARGET_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._TARGET_TOTALS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.TIME_SERIES_ENTITY_STATS_FETCH,
    Catch.deferredAction(ReconSagas._TIME_SERIES_ENTITY_STATS_FETCH as any, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.TOP_LEVEL_DETECTIONS_FETCH,
    Catch.deferredAction(ReconSagas._TOP_LEVEL_DETECTIONS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.TOP_LEVEL_DETECTION_TOTALS_FETCH,
    Catch.deferredAction(ReconSagas._TOP_LEVEL_DETECTION_TOTALS_FETCH, io),
  )

  yield takeEvery(
    Store.ReconActions.TypeKeys.AUTHORIZATION_POLICIES_FETCH,
    Catch.deferredAction(AuthorizationPolicySagas._AUTHORIZATION_POLICIES_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.AUTHORIZATION_POLICIES_PATCH,
    Catch.deferredAction(AuthorizationPolicySagas._AUTHORIZATION_POLICIES_PATCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.AUTHORIZATION_POLICIES_POST,
    Catch.deferredAction(AuthorizationPolicySagas._AUTHORIZATION_POLICIES_POST, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_DELETE,
    Catch.deferredAction(AuthorizationPolicySagas._AUTHORIZATION_POLICY_DELETE, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_FETCH,
    Catch.deferredAction(AuthorizationPolicySagas._AUTHORIZATION_POLICY_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_TOTALS_FETCH,
    Catch.deferredAction(AuthorizationPolicySagas._AUTHORIZATION_POLICY_TOTALS_FETCH, io),
  )
  yield takeEvery(
    Store.ReconActions.TypeKeys.DETECTIONS_AUTHORIZE,
    Catch.deferredAction(DetectionSagas._DETECTIONS_AUTHORIZE, io),
  )
}
