/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Loader } from '@randori/rootkit'
import debug from 'debug'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Route, RouteComponentProps, Router, Switch } from 'react-router-dom'

import { ResetBoundary } from '@/components/boundary'
import { AuthProtectedRoute } from '@/hocs/protected'
import Auth from '@/pages/auth'
import * as Store from '@/store'
import { Flags as FlagsLookup } from '@/utilities/flags'
import * as historyUtil from '@/utilities/history'
import { lazyWithRetry } from '@/utilities/lazy'

import { RouteChangeSubscription } from './app.utils'

// ---------------------------------------------------------------------------

const AuthenticatedApp = lazyWithRetry(() => import('./route-components/authenticated-app'))
const TermsOfService = lazyWithRetry(() => import('./route-components/terms-of-service'))

const Flags: React.FC = () => {
  const dispatch = Store.useAppDispatch()
  const staticFlags = Store.useAppSelector(Store.GateSelectors.selectStaticFlags)

  window.__RUTIL__.flag = (flag: keyof typeof FlagsLookup) => {
    dispatch(Store.UIActions.TOGGLE_FLAG(flag))
  }

  window.__RUTIL__.getFlag = (flag: keyof typeof FlagsLookup) => {
    return staticFlags[flag]
  }

  window.__RUTIL__.getAllFlags = () => {
    return staticFlags
  }

  return null
}

export class RootApp extends React.Component {
  componentDidMount(): void {
    debug('RANDORI:root')('base url:', historyUtil.basename)
  }

  render(): JSX.Element {
    return (
      <>
        <Flags />

        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
          <meta name="googlebot" content="noindex, nofollow" />
        </Helmet>

        <ResetBoundary>
          <Router history={historyUtil.history}>
            <React.Suspense fallback={<Loader />}>
              <RouteChangeSubscription />

              <Switch>
                {/* Onboarding */}
                <Route path="/terms-of-service" component={TermsOfService} />
                <Route path="/activate" component={Auth.Activate} />

                {/* Login */}
                <Route path="/login" component={Auth.Login} />
                <Route path="/login-sso" exact component={Auth.LoginSSO} />
                <Route path="/login-sso/:id" component={Auth.LoginSSO} />
                <Route path="/login-check-sso" component={Auth.LoginCheckSSO} />

                {/*
                  @TODO: Re-enable 404 catchall
                  <Route path='/404' component={FourOhFour} />
                */}

                <AuthProtectedRoute render={(props: RouteComponentProps) => <AuthenticatedApp {...props} />} />
              </Switch>
            </React.Suspense>
          </Router>
        </ResetBoundary>
      </>
    )
  }
}
