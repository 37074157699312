/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const PathNode = t.interface({
  id: t.string,
  type: t.string,
  content: t.record(t.string, t.unknown),
})

type _PathNode = t.TypeOf<typeof PathNode>
export type PathNode = _PathNode

export const PathEdge = t.interface({
  id: t.string,
  src: t.string,
  dst: t.string,
  type: t.string, // `type` can be a union of known strings, once we know them
  content: t.record(t.string, t.unknown),
})

type _PathEdge = t.TypeOf<typeof PathEdge>
export type PathEdge = _PathEdge
