/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { DropdownNew, InfoPopper, RenderEffect, Typography } from '@randori/rootkit'
import classnames from 'classnames'
import debug from 'debug'
import { head } from 'lodash/fp'
import { omit, pathOr } from 'ramda'
import * as React from 'react'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'
import { ErrBoundary } from '@/components/boundary'
import { ResizeContainer } from '@/components/resize-container'
import * as Store from '@/store'
import * as DateUtils from '@/utilities/date'
import { isNotNil } from '@/utilities/is-not'
import * as EntityUtils from '@/utilities/r-entity'
import { ExhaustiveError } from '@/utilities/r-error'
import * as Tracker from '@/utilities/tracker'

import { Graph } from './graph'
// ---------------------------------------------------------------------------
import { AttackLegend } from './legend'
import { AttackStats } from './stats'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:attack-graph')

export enum __INTERVAL__ {
  oneWeek = 1008,
  oneMonth = 4320,
  threeMonths = 12960,
  oneYear = 52560,
}

// INTERVAL = number of 10 minute intervals to skip
// 6 (ten minute intervals) x 24 (hours in day) * variable number of days
// FOR DELTA latest = false
export const __ONE_WEEK__ = __INTERVAL__.oneWeek // 6 * 24 * 7 = 1008
export const __ONE_MONTH__ = __INTERVAL__.oneMonth // 6 * 24 * 30 = 4320
export const __THREE_MONTH__ = __INTERVAL__.threeMonths // 6 * 24 * 90 = 12960
export const __ONE_YEAR__ = __INTERVAL__.oneYear // 6 * 24 * 365 = 52560

export const oneWeekInterval = __INTERVAL__.oneWeek
export const oneMonthInterval = __INTERVAL__.oneMonth
export const threeMonthInterval = __INTERVAL__.threeMonths
export const yearInterval = __INTERVAL__.oneYear

export enum __QUERY_TYPE__ {
  all = 'all',
  priority = 'priority',
  temptation = 'temptation',
}

// ---------------------------------------------------------------------------

export type StatDiff = {
  difference: number
  stat: number
}

type StatData = {
  graphData: Codecs.Statistics[]
  hostnames: StatDiff
  ips: StatDiff
  networks: StatDiff
  services: StatDiff
  targets: StatDiff
}

const initStats = {
  graphData: [],
  hostnames: { difference: 0, stat: 0 },
  ips: { difference: 0, stat: 0 },
  networks: { difference: 0, stat: 0 },
  services: { difference: 0, stat: 0 },
  targets: { difference: 0, stat: 0 },
}

// ---------------------------------------------------------------------------

const Container: React.FC = () => {
  const dispatch = Store.useAppDispatch()

  // Use hook reducer for filter state management
  const [entity, setEntity] = React.useState<EntityUtils.EntityType>('target')
  const [interval, setSpan] = React.useState(__INTERVAL__.threeMonths)
  const [stats, setStats] = React.useState<StatData>(initStats)

  const _selectEntity = (e: React.MouseEvent<HTMLDivElement>) => {
    const $target = e.target as EventTarget & HTMLDivElement

    const entity = elementToEntityType($target)

    Tracker.track('dashboard-attack-graph', { action: 'change-entity', kind: entity })

    setEntity(entity)
  }

  const _effect = React.useCallback(() => {
    const _getEffect = (queryType: __QUERY_TYPE__) =>
      new Promise<StatData>((resolve, reject) => {
        const statType = queryTypeToStatType(entity, queryType)
        const _filterFlags = queryTypeToFilterFlags(queryType)

        const success = (response: StatData) => {
          log('hydrating attack-graph', response)

          if (queryType === __QUERY_TYPE__.all) {
            setStats(response)
          }

          resolve(response)
        }

        const failure = (err: Error) => {
          log('failed to hydrate attack-graph', err)
          reject(err)
        }

        const _ago = getAgo(interval)

        const range: [string, string] = [_ago(), DateUtils.formatToDateStr(new Date())]

        const query = {
          entity,
          range,
          statType: statType,
          ..._filterFlags,
        }

        dispatch(
          Store.ReconActions.TIME_SERIES_ENTITY_STATS_FETCH(query, {
            success,
            failure,
          }),
        )
      })

    return Promise.all([_getEffect(__QUERY_TYPE__.all), _getEffect(__QUERY_TYPE__.priority)])
  }, [dispatch, entity, interval])

  const infoPopperContent =
    'If you’ve recently requested or made a change on your Attack Surface, stats are calculated periodically and may not be reflected on this graph.'

  /* Note: color assignments happening outside of scss */
  // Attack Surface Over Time graph
  const fillColor = ['var(--rootkit__palette--gray200)', 'var(--rootkit__palette--red100)']
  const strokeColor = ['var(--rootkit__palette--gray500)', 'var(--rootkit__palette--red600)']

  const getLabels = (entity: EntityUtils.EntityType) =>
    [
      `All ${EntityUtils.getDisplayPluralByType(entity)}`,
      `High Priority ${EntityUtils.getDisplayPluralByType(entity)}`,
    ].filter((label) => label !== 'High Priority Services')

  const getLegendInfo = (entity: EntityUtils.EntityType) => {
    const labels = getLabels(entity)

    return labels.map((label, i) => {
      return {
        fill: fillColor[i],
        label: label,
        stroke: strokeColor[i],
      }
    })
  }

  return (
    <ErrBoundary>
      <div className="dash-graph-header">
        <div className="dash-graph-title-info-container">
          <Typography addlClasses={classnames('dash-graph-title', 'dashboard-section__header')} as="h2" variant="h4">
            Attack Surface Over Time
          </Typography>
          <InfoPopper isInteractive options={{ placement: 'bottom' }}>
            {infoPopperContent}
          </InfoPopper>
        </div>

        <DropdownNew
          triggerLabel={intervalToLabel(interval)}
          render={(dProps) => (
            <ul className="dropdown-new-options">
              <li
                className="dropdown-new-option"
                onClick={() => {
                  setSpan(__INTERVAL__.oneWeek)
                  Tracker.track('dashboard-attack-graph', { action: 'change-timeframe', kind: 'one-week' })
                  dProps.toggle()
                }}
              >
                {intervalToLabel(__INTERVAL__.oneWeek)}
              </li>

              <li
                className="dropdown-new-option"
                onClick={() => {
                  setSpan(__INTERVAL__.oneMonth)
                  Tracker.track('dashboard-attack-graph', { action: 'change-timeframe', kind: 'one-month' })
                  dProps.toggle()
                }}
              >
                {intervalToLabel(__INTERVAL__.oneMonth)}
              </li>

              <li
                className="dropdown-new-option"
                onClick={() => {
                  setSpan(__INTERVAL__.threeMonths)
                  Tracker.track('dashboard-attack-graph', { action: 'change-timeframe', kind: 'three-months' })
                  dProps.toggle()
                }}
              >
                {intervalToLabel(__INTERVAL__.threeMonths)}
              </li>

              <li
                className="dropdown-new-option"
                onClick={() => {
                  setSpan(__INTERVAL__.oneYear)
                  Tracker.track('dashboard-attack-graph', { action: 'change-timeframe', kind: 'one-year' })
                  dProps.toggle()
                }}
              >
                {intervalToLabel(__INTERVAL__.oneYear)}
              </li>
            </ul>
          )}
        />
      </div>

      <div className="dash-graph-sub-header">
        <AttackStats
          data={formatStatBlockData(stats)}
          handleOnClick={_selectEntity}
          selectedEntity={mungeEntityType(entity)}
        />

        <AttackLegend legend={getLegendInfo(entity)} />
      </div>

      <ResizeContainer
        addlClasses="dash-graph-container"
        defaultHeight={200}
        render={({ width, height }) => (
          <RenderEffect
            effect={_effect}
            render={({ effResult }) => {
              const resultHead = head(effResult) as StatData

              return (
                <Graph
                  data={effResult.map((d) => d.graphData)}
                  fillColor={fillColor}
                  graphEntity={entity}
                  graphHeight={height}
                  graphWidth={width}
                  interval={interval}
                  labels={getLabels(entity)}
                  strokeColor={strokeColor}
                  yAxisLimit={getGraphYAxisLimit(formatStatBlockData(resultHead), entity)}
                />
              )
            }}
          />
        )}
      />
    </ErrBoundary>
  )
}

// ---------------------------------------------------------------------------

function queryTypeToStatType(entity: EntityUtils.EntityType, queryType: __QUERY_TYPE__) {
  switch (queryType) {
    case __QUERY_TYPE__.all:
      return mungeEntityType(entity)

    case __QUERY_TYPE__.priority:
      return `top_prio_${mungeEntityType(entity)}`

    case __QUERY_TYPE__.temptation:
      return `top_${mungeEntityType(entity)}`
  }
}

function queryTypeToFilterFlags(queryType: __QUERY_TYPE__) {
  switch (queryType) {
    case __QUERY_TYPE__.all: {
      return {
        isHighQuery: false,
        isPrioQuery: false,
      }
    }

    case __QUERY_TYPE__.priority: {
      return {
        isHighQuery: true,
        isPrioQuery: true,
      }
    }

    case __QUERY_TYPE__.temptation: {
      return {
        isHighQuery: true,
        isPrioQuery: false,
      }
    }
  }
}

function intervalToLabel(interval: __INTERVAL__) {
  switch (interval) {
    case __INTERVAL__.oneMonth:
      return 'Over the Last Month'
    case __INTERVAL__.oneWeek:
      return 'Over the Last Week'
    case __INTERVAL__.oneYear:
      return 'Over the Last Year'
    case __INTERVAL__.threeMonths:
      return 'Over the Last Three Months'
  }
}

function entityToLabel(entity: EntityUtils.EntityType) {
  if (entity === 'ip') {
    return 'IP Addresses'
  } else {
    return `${entity.replace(/^\w/, (c) => c.toUpperCase())}s`
  }
}

function labelToEntity(label?: string | null) {
  switch (label) {
    case 'Hostnames':
      return 'hostname'
    case 'IP Addresses':
      return 'ip'
    case 'Networks':
      return 'network'
    case 'Services':
      return 'service'
    case 'Targets':
      return 'target'

    default:
      throw new Error(`Unsupported label: ${label}`)
  }
}

function elementToEntityType($el: EventTarget & HTMLDivElement) {
  const $closestStatBlock = $el.closest('.stat-block')

  if (isNotNil($closestStatBlock)) {
    const nextEntity = $closestStatBlock.getAttribute('data-stat-id')

    return labelToEntity(nextEntity)
  } else {
    throw new Error('Could not find .stat-block')
  }
}

function formatStatBlockData(attackGraphData: StatData) {
  return omit(['graphData'], attackGraphData)
}

function getGraphYAxisLimit(statBlockData: Omit<StatData, 'graphData'>, selectedEntity: EntityUtils.EntityType) {
  const _index = mungeEntityType(selectedEntity)

  const max = pathOr(1, [_index, 'stat'], statBlockData)

  const roundedTo10 = Math.ceil(max / 10) * 10

  const highestNum = roundedTo10 * 1.3

  return highestNum === 0 ? 1 : highestNum
}

export function mungeEntityType(entity: EntityUtils.EntityType) {
  return `${entity}s`
}

function getAgo(_ago: __INTERVAL__) {
  switch (_ago) {
    case __INTERVAL__.oneWeek:
      return DateUtils.getOneWeekAgo
    case __INTERVAL__.oneMonth:
      return DateUtils.getOneMonthAgo
    case __INTERVAL__.threeMonths:
      return DateUtils.getThreeMonthsAgo
    case __INTERVAL__.oneYear:
      return DateUtils.getOneYearAgo

    default:
      throw new ExhaustiveError(_ago)
  }
}

// ---------------------------------------------------------------------------

export const GraphContainer = Container
export const Test = { formatStatBlockData }
