/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { DeferredAction } from 'redux-saga-try-catch'

import { FormValues as AddNetworkFormValues } from '@/components/add-network-pane/add-network.constants'

import { makeFetchMany, makeFetchSingle, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './affiliated-network.constants'

// ---------------------------------------------------------------------------

export const fetchAffiliatedNetworks = makeFetchMany(EntityName)
export const fetchAffiliatedNetwork = makeFetchSingle(EntityName)
export const fetchTotals = makeFetchTotals(EntityName)

export const postAffiliatedNetworks = createAction(
  `${EntityName}/post-many`,
  (payload: AddNetworkFormValues, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)
