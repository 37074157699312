/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

import { TypeKeys } from './recon.actions.typekeys'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export type AUTHORIZATION_POLICY_FETCH = {
  type: TypeKeys.AUTHORIZATION_POLICY_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function AUTHORIZATION_POLICY_FETCH(
  payload: AUTHORIZATION_POLICY_FETCH['payload'],
  deferred: Deferred,
): AUTHORIZATION_POLICY_FETCH {
  return {
    type: TypeKeys.AUTHORIZATION_POLICY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type AUTHORIZATION_POLICIES_FETCH = {
  type: TypeKeys.AUTHORIZATION_POLICIES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function AUTHORIZATION_POLICIES_FETCH(
  payload: AUTHORIZATION_POLICIES_FETCH['payload'],
  deferred: Deferred,
): AUTHORIZATION_POLICIES_FETCH {
  return {
    type: TypeKeys.AUTHORIZATION_POLICIES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type AUTHORIZATION_POLICIES_POST = {
  type: TypeKeys.AUTHORIZATION_POLICIES_POST
  meta: { deferred: Deferred }
  payload: Codecs.PolicyPostPayload
}

export function AUTHORIZATION_POLICIES_POST(
  payload: AUTHORIZATION_POLICIES_POST['payload'],
  deferred: Deferred,
): AUTHORIZATION_POLICIES_POST {
  return {
    type: TypeKeys.AUTHORIZATION_POLICIES_POST,
    meta: { deferred },
    payload,
  }
}

// @TODO: These should be singular, not plural

export type AUTHORIZATION_POLICIES_PATCH = {
  type: TypeKeys.AUTHORIZATION_POLICIES_PATCH
  meta: { deferred: Deferred }
  payload: Codecs.PolicyPatchPayload & { id: string }
}

export function AUTHORIZATION_POLICIES_PATCH(
  payload: AUTHORIZATION_POLICIES_PATCH['payload'],
  deferred: Deferred,
): AUTHORIZATION_POLICIES_PATCH {
  return {
    type: TypeKeys.AUTHORIZATION_POLICIES_PATCH,
    meta: { deferred },
    payload,
  }
}

export type AUTHORIZATION_POLICIES_STORE_UPDATE = {
  type: TypeKeys.AUTHORIZATION_POLICIES_STORE_UPDATE
  payload: Codecs.PoliciesResponse
}

export function AUTHORIZATION_POLICIES_STORE_UPDATE(
  payload: AUTHORIZATION_POLICIES_STORE_UPDATE['payload'],
): AUTHORIZATION_POLICIES_STORE_UPDATE {
  return {
    type: TypeKeys.AUTHORIZATION_POLICIES_STORE_UPDATE,
    payload,
  }
}

export type AUTHORIZATION_POLICY_STORE_UPDATE = {
  type: TypeKeys.AUTHORIZATION_POLICY_STORE_UPDATE
  payload: Codecs.Policy
}

export function AUTHORIZATION_POLICY_STORE_UPDATE(
  payload: AUTHORIZATION_POLICY_STORE_UPDATE['payload'],
): AUTHORIZATION_POLICY_STORE_UPDATE {
  return {
    type: TypeKeys.AUTHORIZATION_POLICY_STORE_UPDATE,
    payload,
  }
}

export type AUTHORIZATION_POLICY_TOTALS_FETCH = {
  type: TypeKeys.AUTHORIZATION_POLICY_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function AUTHORIZATION_POLICY_TOTALS_FETCH(
  payload: AUTHORIZATION_POLICY_TOTALS_FETCH['payload'],
  deferred: Deferred,
): AUTHORIZATION_POLICY_TOTALS_FETCH {
  return {
    type: TypeKeys.AUTHORIZATION_POLICY_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE = {
  type: TypeKeys.AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE(
  payload: AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE['payload'],
): AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type AUTHORIZATION_POLICY_DELETE = {
  type: TypeKeys.AUTHORIZATION_POLICY_DELETE
  meta: { deferred: Deferred }
  payload: string
}

export function AUTHORIZATION_POLICY_DELETE(
  payload: AUTHORIZATION_POLICY_DELETE['payload'],
  deferred: Deferred,
): AUTHORIZATION_POLICY_DELETE {
  return {
    type: TypeKeys.AUTHORIZATION_POLICY_DELETE,
    meta: { deferred },
    payload,
  }
}

export type AUTHORIZATION_POLICY_DELETE_STORE_UPDATE = {
  type: TypeKeys.AUTHORIZATION_POLICY_DELETE_STORE_UPDATE
  payload: string
}

export function AUTHORIZATION_POLICY_DELETE_STORE_UPDATE(
  payload: AUTHORIZATION_POLICY_DELETE_STORE_UPDATE['payload'],
): AUTHORIZATION_POLICY_DELETE_STORE_UPDATE {
  return {
    type: TypeKeys.AUTHORIZATION_POLICY_DELETE_STORE_UPDATE,
    payload,
  }
}
