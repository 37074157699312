/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { AreaGraph, isNotNilOrEmpty } from '@randori/rootkit'
import { format } from 'date-fns'
import { isNumber } from 'lodash/fp'
import * as React from 'react'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

type GraphProps = {
  data: Codecs.Statistics[][]
  fillColor: string[]
  graphEntity: string
  graphHeight: number
  graphWidth: number
  interval: number
  labels: string[]
  strokeColor: string[]
  yAxisLimit: number
}

export const Graph: React.FunctionComponent<GraphProps> = (props) => {
  if (!isNotNilOrEmpty(props.data)) {
    return <h1>No Available Data</h1>
  }

  const _formatData = (data: Codecs.Statistics[], i: number) =>
    data.map((d) => {
      const dateWithTime = isNotNilOrEmpty(d.time) ? new Date(d.time) : new Date()
      const dateWithoutTime = new Date(dateWithTime.getFullYear(), dateWithTime.getMonth(), dateWithTime.getDate())

      return {
        y: isNotNilOrEmpty(d.value) ? d.value : 0,
        x: dateWithoutTime.getTime(),
        label: props.labels[i],
      }
    })

  const _data = props.data.map((data, i) => _formatData(data, i))

  const _xFormatter = (x: Date | number | string) => {
    return format(new Date(x), 'MMM d')
  }

  const _yFormatter = (y: Date | number | string) => {
    if (!isNumber(y)) {
      return ''
    }

    if (y % 1 !== 0) {
      return ''
    } else {
      return y.toString()
    }
  }

  return (
    <AreaGraph
      data={_data}
      height={props.graphHeight}
      padding={10}
      strokeColor={props.strokeColor}
      fillColor={props.fillColor}
      width={props.graphWidth}
      xFormatter={_xFormatter}
      yFormatter={_yFormatter}
      xScaleKind="time"
      onMouseMove={(_e, _v) => {
        return // noop
      }}
    />
  )
}
