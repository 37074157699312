/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import qs from 'query-string'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { serializeQ } from '@/utilities/crud-query'

import * as actions from './hostname.actions'
import { normalizeHostnames } from './hostname.schema'
import { hostnameSlice } from './hostname.slice'

// ---------------------------------------------------------------------------

export function* hostnameSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchHostname.toString(), deferredAction(_fetchHostname, io))
  yield takeEvery(actions.fetchHostnameTotals.toString(), deferredAction(_fetchHostnameTotals, io))
  yield takeEvery(actions.fetchHostnames.toString(), deferredAction(_fetchHostnames, io))
  yield takeEvery(actions.fetchHostnamesForIp.toString(), deferredAction(_fetchHostnamesForIp, io))
}

export function* _fetchHostnames(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchHostnames>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.hostname.getHostnames, queryString)

  if (action.meta.persist) {
    yield* put(hostnameSlice.actions.replaceHostnames(normalizeHostnames(response.data)))

    yield* put(
      hostnameSlice.actions.replaceHostnamePagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchHostname(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchHostname>) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.hostname.getHostname, id)

  yield* put(hostnameSlice.actions.upsertHostname(data))

  return data
}

export function* _fetchHostnameTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchHostnameTotals>) {
  const { total: unfiltered } = yield* call(
    io.api.hostname.getHostnames,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )

  const { total: unaffiliated } = yield* call(
    io.api.hostname.getHostnames,
    get(CrudQueryUtils.createUnaffiliatedQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered,
    unaffiliated,
  }

  yield* put(hostnameSlice.actions.replaceHostnameTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}

export function* _fetchHostnamesForIp(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchHostnamesForIp>) {
  const { limit, offset, sort } = qs.parse(action.payload.search)

  const search = qs.stringify({
    limit,
    offset,
    sort,
    q: serializeQ({
      condition: 'AND',
      rules: [
        {
          ui_id: 'ip_id',
          id: `table.ip_id`,
          field: `table.ip_id`,
          type: 'string',
          input: 'text',
          operator: 'equal',
          value: action.payload.id,
        },
      ],
    }),
  })

  const queryString = get(`?${search}`, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.hostname.getHostnamesForIp, queryString)

  return response
}
