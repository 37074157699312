/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _Actions from '@/store/actions/workato/workato'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchWorkato(io: MiddlewaresIO) {
  yield takeEvery(
    Store.WorkatoActions.TypeKeys.GET_ACCOUNT_PROPERTIES,
    Catch.deferredAction(_GET_ACCOUNT_PROPERTIES, io),
  )
  yield takeEvery(Store.WorkatoActions.TypeKeys.GET_CONNECTIONS, Catch.deferredAction(_GET_CONNECTIONS, io))
  yield takeEvery(Store.WorkatoActions.TypeKeys.GET_CONNECTIONS_CHECK, Catch.deferredAction(_GET_CONNECTIONS_CHECK, io))
  yield takeEvery(Store.WorkatoActions.TypeKeys.GET_RECIPES, Catch.deferredAction(_GET_RECIPES, io))
  yield takeEvery(Store.WorkatoActions.TypeKeys.GET_WORKATO_DETAILS, Catch.deferredAction(_GET_WORKATO_DETAILS, io))
  yield takeEvery(
    Store.WorkatoActions.TypeKeys.POST_ACCOUNT_PROPERTIES,
    Catch.deferredAction(_POST_ACCOUNT_PROPERTIES, io),
  )
  yield takeEvery(Store.WorkatoActions.TypeKeys.POST_ACTIVATE_RECIPES, Catch.deferredAction(_POST_ACTIVATE_RECIPES, io))
  yield takeEvery(
    Store.WorkatoActions.TypeKeys.POST_DEACTIVATE_RECIPES,
    Catch.deferredAction(_POST_DEACTIVATE_RECIPES, io),
  )
  yield takeEvery(Store.WorkatoActions.TypeKeys.VALIDATE_CONNECTION, Catch.deferredAction(_VALIDATE_CONNECTION, io))
}

export function* _GET_ACCOUNT_PROPERTIES(io: MiddlewaresIO, action: _Actions.GET_ACCOUNT_PROPERTIES) {
  return yield* call(io.api.workato.getAccountProperties, action.payload.recipeName)
}

export function* _GET_CONNECTIONS(io: MiddlewaresIO) {
  const response = yield* call(io.api.workato.getConnections)

  yield* put(Store.WorkatoActions.CONNECTIONS_STORE_UPDATE(response))

  return response
}

export function* _GET_CONNECTIONS_CHECK(io: MiddlewaresIO, action: _Actions.GET_CONNECTIONS_CHECK) {
  return yield* call(io.api.workato.getConnectionsCheck, action.payload.recipeName)
}

export function* _GET_RECIPES(io: MiddlewaresIO) {
  const recipes = yield* call(io.api.workato.getRecipes)

  yield* put(Store.WorkatoActions.RECIPES_STORE_UPDATE(recipes))
}

export function* _GET_WORKATO_DETAILS(io: MiddlewaresIO) {
  return yield* call(io.api.auth.getWorkatoDetail)
}

export function* _POST_ACCOUNT_PROPERTIES(io: MiddlewaresIO, action: _Actions.POST_ACCOUNT_PROPERTIES) {
  return yield* call(io.api.workato.postAccountProperties, action.payload)
}

export function* _POST_ACTIVATE_RECIPES(io: MiddlewaresIO, action: _Actions.POST_ACTIVATE_RECIPES) {
  const result = yield* call(io.api.workato.postActivateRecipe, action.payload.recipeName, action.payload.recipeId)

  const recipes = yield* call(io.api.workato.getRecipes)

  yield* put(Store.WorkatoActions.RECIPES_STORE_UPDATE(recipes))

  return result
}

export function* _POST_DEACTIVATE_RECIPES(io: MiddlewaresIO, action: _Actions.POST_DEACTIVATE_RECIPES) {
  const result = yield* call(io.api.workato.postDeactivateRecipe, action.payload.recipeId)

  const recipes = yield* call(io.api.workato.getRecipes)

  yield* put(Store.WorkatoActions.RECIPES_STORE_UPDATE(recipes))

  return result
}

export function* _VALIDATE_CONNECTION(io: MiddlewaresIO, action: _Actions.VALIDATE_CONNECTION) {
  const { auth_success, unsuccessful_connections } = yield* call(
    io.api.workato.getConnectionsCheck,
    action.payload.recipeName,
  )

  if (auth_success) {
    return true
  }

  return !unsuccessful_connections.some((connection) => connection.id === action.payload.connectionId)
}
