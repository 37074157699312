/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, DoubleUUID, IP, nullable, nullableP, Sha1, UUID } from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { Authorization } from '../authorization.codecs'
import { CPE } from '../cpe.codecs'
import { DetectionCriteria } from '../detection-criteria.codecs'
import { WithPerspective } from '../perspective.codecs'
import { WithPriority } from '../priority.codecs'
import { TechCategoryList } from '../tech-category.codecs'
import { WithTemptation } from '../temptation.codecs'
import { TextSource } from '../text-source.codecs'

// ---------------------------------------------------------------------------

const _Target = t.type({
  affiliation_state: Affiliation,
  attack_note: nullableP(t.string),
  authorization_state: Authorization,
  banners_data_hash: nullableP(Sha1),
  banners_uuid: nullableP(UUID),
  cert_data_hash: nullableP(Sha1),
  cert_uuid: nullableP(UUID),
  characteristic_tags: t.array(t.string),
  characteristics_count: t.number,
  cpe: nullable(CPE),
  deleted: t.boolean,
  description: nullableP(t.string),
  description_source: TextSource,
  detection_criteria: DetectionCriteria,
  first_seen: nullableP(DateString),
  headers_data_hash: nullableP(Sha1),
  headers_uuid: nullableP(UUID),
  hostname: nullableP(t.string),
  hostname_id: nullable(UUID),
  id: DoubleUUID,
  ip_id: nullableP(UUID),
  ip_str: nullableP(IP),
  last_seen: nullableP(DateString),
  name: nullableP(t.string),
  org_id: nullableP(UUID),
  path: nullableP(t.string),
  poc_email: nullable(t.string),
  poc_id: nullable(t.string),
  port: nullableP(t.number),
  protocol: nullableP(t.string),
  randori_notes: nullableP(t.string),
  reference: nullableP(t.string),
  screenshot_data_hash: nullableP(Sha1),
  screenshot_uuid: nullableP(UUID),
  service_id: nullableP(UUID),
  target_first_seen: nullableP(DateString),
  target_id: UUID,
  target_last_seen: nullableP(DateString),
  target_num_detections: t.number,
  tech_category: nullable(TechCategoryList),
  thumbnail_data_hash: nullableP(Sha1),
  thumbnail_uuid: nullableP(UUID),
  user_tags: t.array(t.string),
  validated_vulnerabilities_detection: t.array(t.string),
  validated_vulnerabilities_detection_count: t.number,
  validated_vulnerabilities_target: t.array(t.string),
  validated_vulnerabilities_target_count: t.number,
  vendor: nullableP(t.string),
  version: nullableP(t.string),

  // @TODO: These are not nullable after BE deployments settle
  authorizing_policies: nullable(t.array(t.string)),
  detection_authorization_state: nullable(Authorization),
  target_num_authorized_detections: nullable(t.number),
})

export type TargetP = t.TypeOf<typeof TargetP>
export const TargetP = t.intersection([_Target, WithPerspective, WithPriority, WithTemptation])
