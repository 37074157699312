/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'
import { propOr } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export const Connection = new schema.Entity('connection')
export const Connections = [Connection]

export function NormalizeConnections(connections: Codecs.WorkatoConnection[]) {
  type _Connections = {
    connection: {
      [index: string]: Codecs.WorkatoConnection
    }
  }

  type NormalizedConnections = {
    entities: _Connections
    result: string[]
  }

  const { result, entities } = normalize(connections, Connections)

  const _connections = propOr({}, 'connection', entities)

  return {
    result,
    entities: {
      connection: _connections,
    },
  } as NormalizedConnections
}
