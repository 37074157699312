/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'

import { DecodedSession } from '@/codecs'
import { throwValidate } from '@/utilities/codec'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:token')

export const parseAuth = (token: string) => {
  const _token = JSON.parse(atob(token))

  log(_token)

  return throwValidate(DecodedSession, 'DecodedSession')(_token)
}
