/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { mapValues, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import * as Store from '@/store'

// ---------------------------------------------------------------------------

// ---------------------------------------------------------------------------

const _getUnknownAssets = (state: Store.AppState) => state.recon.categories.unknowns

export type ShadowITEntities = 'ip' | 'network' | 'hostname' | 'target'

const _getUnknownAssetsByEntityType = (state: Store.AppState, props: { entityType: ShadowITEntities }) => {
  return state.recon.categories.unknowns[props.entityType]
}

export const selectUnknownAssets = createSelector(
  [_getUnknownAssets],
  mapValues((stat) => {
    return {
      known: stat.total - stat.unknown,
      percentage: stat.unknown / Math.max(stat.total, 1),
      total: stat.total,
      unknown: stat.unknown,
    }
  }),
)

export const selectUnknownAssetsByEntityType = createSelector([_getUnknownAssetsByEntityType], (stat) => {
  const known = stat.total - stat.unknown

  return {
    known,
    percentageKnown: known / Math.max(stat.total, 1),
    percentageUnknown: stat.unknown / Math.max(stat.total, 1),
    total: stat.total,
    unknown: stat.unknown,
  }
})

export const selectAllUnknown = createSelector([_getUnknownAssets], (stats) => {
  const totals = mapValues('total', stats)
  const unknowns = mapValues('unknown', stats)

  const sum = (sum: number, num: number) => sum + num

  // eslint-disable-next-line no-magic-numbers
  const allTotal = values(totals).reduce(sum, 0)
  // eslint-disable-next-line no-magic-numbers
  const allUnknown = values(unknowns).reduce(sum, 0)

  const allKnown = allTotal - allUnknown

  return {
    allPercentageKnown: allKnown / Math.max(allTotal, 1),
    allPercentageUnknown: allUnknown / Math.max(allTotal, 1),
    allTotal,
    allUnknown,
  }
})
