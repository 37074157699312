/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

// @TODO: Rename Statistics codec to Statistic
export const Statistics = t.interface({
  id: t.string,
  name: CodecUtils.nullable(t.string),
  org_id: CodecUtils.nullable(t.string),
  time: CodecUtils.nullable(t.string),
  type: CodecUtils.nullable(t.string), // "core"
  value: CodecUtils.nullable(t.number),
})

type _Statistics = t.TypeOf<typeof Statistics>
export type Statistics = _Statistics

export type RequiredStats = {
  [K in keyof Statistics]: NonNullable<Statistics[K]>
}

export const StatsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Statistics),
})

export type StatsResponse = t.TypeOf<typeof StatsResponse>
