/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DetectionTarget } from './detection-target'

// ---------------------------------------------------------------------------

export const Detection = DetectionTarget
export type Detection = t.TypeOf<typeof Detection>
