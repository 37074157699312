/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store/actions'

import * as EntityTableReducersUtils from './entity-table.reducers.utils'

// ---------------------------------------------------------------------------

export const EntityTableColumnsReducer = (
  state = EntityTableReducersUtils.getInitialState(),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS: {
      return {
        ...state,
        [action.payload.tableId]: {
          ...state[action.payload.tableId],
          columns: action.payload.columns,
        },
      }
    }

    case Store.UIActions.TypeKeys.UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS: {
      return {
        ...state,
        [action.payload.tableId]: {
          ...state[action.payload.tableId],
          meta: {
            ...state[action.payload.tableId].meta,
            availableColumns: action.payload.availableColumns,
          },
        },
      }
    }

    default: {
      return state
    }
  }
}
