/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const PostPeriodicPayload = t.interface({
  org_id: t.string,
})
export type PostPeriodicPayload = t.TypeOf<typeof PostPeriodicPayload>

export const PostPeriodicResponse = t.interface({
  id: t.string,
})
export type PostPeriodicResponse = t.TypeOf<typeof PostPeriodicResponse>
