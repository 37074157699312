/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { ImpactScore } from '../impact-score.codecs'
import { LensId, LensView, Perspective, PerspectiveName } from '../perspective.codecs'
import { Status } from '../status.codecs'

// ---------------------------------------------------------------------------

export type HostnameForIp = t.TypeOf<typeof HostnameForIp>
export const HostnameForIp = t.type({
  affiliation_state: Affiliation,
  confidence: CodecUtils.nullable(t.number),
  deleted: t.boolean,
  hostname: t.string,
  hostname_id: t.string,
  id: t.string,
  impact_score: ImpactScore,
  ip_id: t.string,
  last_seen: t.string,
  lens_id: LensId,
  lens_view: LensView,
  org_id: t.string,
  perspective: Perspective,
  perspective_name: PerspectiveName,
  status: Status,
})
