/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Card, Loader, Logo as RandoriLogo, withCatch, withEffect } from '@randori/rootkit'
import debug from 'debug'
import { isString } from 'lodash/fp'
import qs from 'query-string'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom'

import { DecodedSession } from '@/codecs'
import { AuthActions, GlobalActions } from '@/store'
import { lazyWithRetry } from '@/utilities/lazy'
import { error } from '@/utilities/logger'
import { compose as RCompose } from '@/utilities/recompose'

import { nextPage, Page, PageKeys } from './auth.utils'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:auth')

const AddOTP = lazyWithRetry(() => import('./form-add-otp'))
const AddPassword = lazyWithRetry(() => import('./form-add-password'))
const ConfirmMe = lazyWithRetry(() => import('./form-confirm-me'))
const OTP = lazyWithRetry(() => import('./form-otp'))
const ToS = lazyWithRetry(() => import('./form-tos'))

// ---------------------------------------------------------------------------

type ActivateState = {
  page: Page
}

const getActivationToken = (props: RouteProps) => {
  const search = props.location?.search ?? ''
  const parsed = qs.parse(search)
  const token = parsed.token

  if (isString(token)) {
    return token
  } else {
    return ''
  }
}

const ActivateErrorMessage: React.FunctionComponent<{ onReset?: () => void }> = () => {
  const { t } = useTranslation()

  return <p>{t('activate.activate-error-message')}</p>
}

const ActivateTokenCheckErrorMessageView: React.FunctionComponent<{ handleLogout: () => void }> = (props) => {
  const { t } = useTranslation()

  return (
    <div className="auth">
      <Card addlClasses="auth-box" pad={false} flex>
        <div className="auth-segment logo">
          <div className="auth-segment-inner">
            <div className="auth-logo">
              <RandoriLogo />
            </div>
          </div>

          <h1>{t('activate.welcome')}</h1>
        </div>

        <div className="auth-segment">
          <div className="auth-segment-inner">
            <p className="onboarding-error-container">
              {t('activate.onboarding-error-container.segment-1')}
              <span className="error-message-link" onClick={props.handleLogout}>
                {t('activate.onboarding-error-container.error-message-link')}
              </span>
              {t('activate.onboarding-error-container.segment-3')}
            </p>
          </div>
        </div>
      </Card>
    </div>
  )
}

const ActivateTokenCheckErrorMessage = RCompose<{ handleLogout: () => void }, {}>(
  withRouter,
  connect(
    () => ({}),
    (dispatch, ownProps: RouteComponentProps) => {
      return {
        handleLogout: () => {
          dispatch({
            type: AuthActions.TypeKeys.LOG_OUT,
            meta: {
              deferred: {
                success: () => {
                  ownProps.history.push('/login')
                },
                failure: (err: Error) => {
                  error(err)

                  dispatch(GlobalActions.reset())
                },
              },
            },
          })
        },
      }
    },
  ),
)(ActivateTokenCheckErrorMessageView)

const ActivateBoundary = withCatch({ log: error })(ActivateErrorMessage)

export type ActivateProps = {
  activationToken: string
  effResult: DecodedSession
  handleLogout: () => void
}

class Activate extends React.Component<ActivateProps, ActivateState> {
  constructor(props: ActivateProps) {
    super(props)

    this.state = {
      page: nextPage(props.effResult),
    }

    this.getPage = this.getPage.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  render() {
    log('Activate.render', {
      props: this.props,
      state: this.state,
    })

    return (
      <div className="auth">
        <Card addlClasses="auth-box" pad={false} flex>
          <div className="auth-segment logo">
            <div className="auth-segment-inner">
              <div className="auth-logo">
                <RandoriLogo />
              </div>
            </div>

            <h1>
              <Trans i18nKey="activate.welcome" />
            </h1>
          </div>

          <div className={`auth-segment ${this.state.page}`}>
            <div className="auth-segment-inner">
              <ActivateBoundary>{this.getPage(this.state.page)}</ActivateBoundary>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  // -------------------------------------------------------------------------

  getPage(page: ActivateState['page']) {
    switch (page) {
      case PageKeys.acceptToS: {
        return (
          <React.Suspense fallback={<Loader />}>
            <ToS onPageChange={this.handlePageChange} setRedirect={() => undefined} />
          </React.Suspense>
        )
      }

      case PageKeys.confirm: {
        return (
          <React.Suspense fallback={<Loader />}>
            <ConfirmMe
              activationToken={this.props.activationToken}
              onPageChange={this.handlePageChange}
              onLogout={this.props.handleLogout}
            />
          </React.Suspense>
        )
      }

      case PageKeys.addOTP: {
        return (
          <React.Suspense fallback={<Loader />}>
            <AddOTP onPageChange={this.handlePageChange} />
          </React.Suspense>
        )
      }

      case PageKeys.addPassword: {
        return (
          <React.Suspense fallback={<Loader />}>
            <AddPassword activationToken={this.props.activationToken} onPageChange={this.handlePageChange} />
          </React.Suspense>
        )
      }

      case PageKeys.submitOTP: {
        return (
          <React.Suspense fallback={<Loader />}>
            <OTP onPageChange={this.handlePageChange} setRedirect={() => undefined} />
          </React.Suspense>
        )
      }

      case PageKeys.redirect: {
        return <Redirect to="/dashboard" />
      }

      default: {
        return <Redirect to="/dashboard" />
      }
    }
  }

  handlePageChange(page: ActivateState['page']) {
    this.setState({ page })
  }
}

// ---------------------------------------------------------------------------

const withConnector = connect(
  (_state, ownProps) => ({
    activationToken: getActivationToken(ownProps),
  }),

  (dispatch, ownProps: RouteComponentProps) => {
    const activationToken = getActivationToken(ownProps)

    return {
      shouldEffectKey: activationToken,

      handleLogout: () => {
        dispatch({
          type: AuthActions.TypeKeys.LOG_OUT,
          meta: {
            deferred: {
              success: () => {
                ownProps.history.push('/login')
              },
              failure: (err: Error) => {
                error(err)

                dispatch(GlobalActions.reset())
              },
            },
          },
        })
      },

      effect: () => {
        // We can't know if we have an active session because clients can no longer
        // access cookies set by our auth service.
        // We can know when we are onboarding and have set the cookie client side,
        // so in that case we can skip over the logout to avoid invalidating our token form the URL.

        if (activationToken && activationToken.length) {
          document.cookie = `authorization=${activationToken}`

          return new Promise((res, rej) => {
            dispatch(AuthActions.validateJWT({ success: res, failure: rej }))
          })
        } else {
          return new Promise((resolve, reject) => {
            dispatch({
              type: AuthActions.TypeKeys.LOG_OUT,
              meta: {
                deferred: {
                  success: () => {
                    if (activationToken && activationToken.length) {
                      document.cookie = `authorization=${activationToken}`
                    }

                    dispatch(AuthActions.validateJWT({ success: resolve, failure: reject }))
                  },

                  failure: (err: Error) => {
                    if (activationToken && activationToken.length) {
                      document.cookie = `authorization=${activationToken}`
                    }

                    dispatch(AuthActions.validateJWT({ success: resolve, failure: reject }))

                    error(err)
                  },
                },
              },
            })
          })
        }
      },
    }
  },
)

export default RCompose<ActivateProps, RouteComponentProps>(
  withConnector,
  withEffect({
    ErrorComponent: () => <ActivateTokenCheckErrorMessage />,
  }),
)(Activate)
