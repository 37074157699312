/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

const sha1 = /^[a-fA-F0-9]{40}$/
const sha256 = /^[a-fA-F0-9]{64}$/
const sha512 = /^[a-fA-F0-9]{128}$/

export interface Sha1Brand {
  readonly Sha1: unique symbol
}
export type Sha1 = t.Branded<string, Sha1Brand>
export const Sha1 = t.brand(t.string, (s): s is Sha1 => sha1.test(s), 'Sha1')

export interface Sha256Brand {
  readonly Sha256: unique symbol
}
export type Sha256 = t.Branded<string, Sha256Brand>
export const Sha256 = t.brand(t.string, (s): s is Sha256 => sha256.test(s), 'Sha256')

export interface Sha512Brand {
  readonly Sha512: unique symbol
}
export type Sha512 = t.Branded<string, Sha512Brand>
export const Sha512 = t.brand(t.string, (s): s is Sha512 => sha512.test(s), 'Sha512')

export interface ShaAllBrand {
  readonly ShaAll: unique symbol
}
export type ShaAll = t.Branded<string, ShaAllBrand>
export const ShaAll = t.brand(t.string, (s): s is ShaAll => sha1.test(s) || sha256.test(s) || sha512.test(s), 'ShaAll')
