/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { default as _i18n } from 'i18next'
import { initReactI18next } from 'react-i18next'

import common from '@/locales/en/common.json'
import * as Logger from '@/utilities/logger'

export const defaultNS = 'common' // custom namespace type, if different from default 'translation'

export const resources = {
  en: {
    common,
  },
} as const

const baseConfig = {
  defaultNS,
  lng: 'en',
  ns: ['common'], // include all namespaces here
  resources,
  returnNull: false,
}

_i18n.use(initReactI18next).init(baseConfig).catch(Logger.error)

export const i18n = _i18n
