/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const RedirectorsReducer = (
  state = Schemas.AttackSchemas.NormalizeRedirectors([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.REDIRECTORS_STORE_UPDATE: {
      const { entities, result } = Schemas.AttackSchemas.NormalizeRedirectors(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          redirector: {
            ...state.entities.redirector,
            ...entities.redirector,
          },
        },
      }
    }

    case Store.AttackActions.TypeKeys.REDIRECTOR_STORE_UPDATE: {
      const normalizedRedirector = Schemas.AttackSchemas.NormalizeRedirectors([action.payload])

      return {
        ...state,
        entities: {
          ...state.entities,
          redirector: {
            ...state.entities.redirector,
            ...normalizedRedirector.entities.redirector,
          },
        },
      }
    }

    default:
      return state
  }
}

export const RedirectorsTotalsInitialState: Codecs.EntityTotals = {
  unfilteredTotal: 0,
  unaffiliatedTotal: 0,
}

export const RedirectorsTotalsReducer = (state = RedirectorsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.REDIRECTOR_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const RedirectorsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const RedirectorsPaginationReducer = (state = RedirectorsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.REDIRECTORS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
