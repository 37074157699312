/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { EntityType } from '../entity-types'

export const isHocService = (entityType: EntityType): boolean => {
  switch (entityType) {
    case 'globalService':
      return true

    default:
      return false
  }
}
