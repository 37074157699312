/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { RedirectorForSource } from '@/codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './redirector.constants'

// ---------------------------------------------------------------------------

export function normalizeRedirectorsForSource(records: RedirectorForSource[]) {
  const Redirector = new schema.Entity(EntityName)
  const Redirectors = [Redirector]

  type NormalizedRedirectors = {
    result: UUID[]
    entities: {
      [EntityName]: Record<UUID, RedirectorForSource>
    }
  }

  const redirectors: NormalizedRedirectors = normalize(records, Redirectors)

  return redirectors
}
