/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { pipe } from 'fp-ts/function'
import * as D from 'io-ts/Decoder'

export const ISODateTime: D.Decoder<unknown, Date> = pipe(
  D.string,
  D.parse((s) => {
    const d = new Date(s)

    return isNaN(d.getTime()) ? D.failure(s, 'not a valid date format') : D.success(d)
  }),
)

export const DelimitedISODateTime: D.Decoder<unknown, Date> = pipe(
  D.string,
  D.parse((s) => {
    // If a string doesn't include a `-` delimiter, like 12-05-1986, then it
    // *may* not be intended to represent an ISO string.
    if (!s.includes('-')) {
      return D.failure(s, 'not a valid date format')
    }

    const d = new Date(s)

    return isNaN(d.getTime()) ? D.failure(s, 'not a valid date format') : D.success(d)
  }),
)
