/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EntityPagination, TargetP as Target } from '@/codecs'
import { TripleUUID, UUID } from '@/utilities/codec'

import { EntityName, initialState, State } from './target.constants'
import { normalizeTargets } from './target.schema'

// ---------------------------------------------------------------------------

export const targetSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    // base
    // ------------------------------------------------------------------------

    replaceTargets: (state, action: PayloadAction<ReturnType<typeof normalizeTargets>>) => {
      state.result = action.payload.result

      state.entities = {
        target: {
          ...state.entities.target,
          ...action.payload.entities.target,
        },
      }
    },

    replaceTargetTotals: (state, action: PayloadAction<State['totals']>) => {
      state.totals = action.payload
    },

    replaceTargetPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },

    upsertTargets: (state, action: PayloadAction<ReturnType<typeof normalizeTargets>>) => {
      state.entities = {
        target: {
          ...state.entities.target,
          ...action.payload.entities.target,
        },
      }
    },

    upsertTarget: (state, action: PayloadAction<Target>) => {
      const id = action.payload.id

      state.entities.target[id] = action.payload
    },

    // extended
    // ------------------------------------------------------------------------

    replaceTargetsForEntity: (
      state,
      action: PayloadAction<{
        forEntity: { id: TripleUUID | UUID; type: 'hostname' | 'ip' | 'network' | 'service' }
        nextState: ReturnType<typeof normalizeTargets>
        pagination: EntityPagination
      }>,
    ) => {
      state.entities = {
        target: {
          ...state.entities.target,
          ...action.payload.nextState.entities.target,
        },
      }

      state.byEntity[action.payload.forEntity.type] = {
        ...state.byEntity[action.payload.forEntity.type],
        [action.payload.forEntity.id]: {
          pagination: action.payload.pagination,
          result: action.payload.nextState.result,
        },
      }
    },
  },
})
