/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { complement, isNil, memoize, nth } from 'lodash/fp'
import * as React from 'react'
import { RouteProps, useLocation } from 'react-router-dom'

import { isSpecialViewName } from '@/components/dashboard-favorite-saved-views/special-views.utils'
import * as Store from '@/store'
import { GroupingType } from '@/utilities/entity-groupings'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

// eslint-disable-next-line complexity
export function getLocClasses(location: RouteProps['location']) {
  if (isNil(location)) {
    return []
  }

  const segments = location.pathname.split('/')

  // eslint-disable-next-line no-magic-numbers
  const topic = segments[2]

  if (isNotNil(topic) && topic === 'settings') {
    return ['l-panel--relative', 'bg-white']
  }

  if (isNotNil(topic) && topic === 'configuration') {
    return ['l-panel--relative', 'bg-white']
  }

  switch (topic) {
    case 'artifacts':
    case 'authorization-policies':
    case 'bars':
    case 'blueprints':
    case 'characteristicrules':
    case 'detections':
    case 'global':
    case 'hostnames':
    case 'hostnames-v2':
    case 'implants':
    case 'integrations':
    case 'ips':
    case 'ips-v2':
    case 'networks':
    case 'networks-v2':
    case 'organizations':
    case 'pocs':
    case 'policies':
    case 'randori-attack':
    case 'redirectors':
    case 'reports':
    case 'runbooks':
    case 'saved-views':
    case 'servicerules':
    case 'services':
    case 'services-v2':
    case 'social':
    case 'suggestions':
    case 'targets':
    case 'terms':
      return ['bg-white']

    default:
      return []
  }
}

// @TODO: Create top-level history type
//
// We should manage the *types* of pushState we have in a central place
type PossiblePushState =
  | null
  | undefined
  | Partial<{
      redirectTo: string
      sessionExpired: boolean
      showAuthorizationPolicySlideout: boolean
      showExceptionPolicySlideout: boolean
      showGuidanceSlideout: boolean
      showPolicySlideout: boolean
      statusKey: GroupingType
    }>

export const RouteChangeSubscription: React.FunctionComponent = () => {
  const dispatch = Store.useAppDispatch()
  const hasPriority = Store.useAppSelector(Store.GateSelectors.hasPriority)
  const isInspectPaneOpen = Store.useAppSelector(Store.UISelectors.isInspectPaneOpen)
  const location = useLocation<PossiblePushState>()

  const pathname = location.pathname
  const state = location.state

  // guidance should not be shown if the inspect pane is open.
  const showGuidanceSlideout = (state?.showGuidanceSlideout ?? false) && !isInspectPaneOpen
  const showPolicySlideout = state?.showPolicySlideout ?? false
  const showAuthorizationPolicySlideout = state?.showAuthorizationPolicySlideout ?? false
  const showExceptionPolicySlideout = state?.showExceptionPolicySlideout ?? false

  React.useEffect(() => {
    const { page } = getUrlSegments(pathname)

    if (page === 'policies' && showPolicySlideout) {
      const policyPaneConfig = {
        addlClasses: 'create-policy-pane',
        kind: 'policy-create' as const,
        childProps: {
          entityTypes: [],
          hasPriority,
          postAction: () => undefined,
          toggleClose: () => dispatch(Store.UIActions.HIDE_SLIDEOUT()),
        },
      }

      dispatch(Store.UIActions.SHOW_SLIDEOUT(policyPaneConfig))
    }

    if (page === 'authorization-policies' && showAuthorizationPolicySlideout) {
      const authorizationPolicyPaneConfig = {
        addlClasses: 'create-authorization-policy-pane',
        kind: 'authorization-policy-create' as const,
        childProps: {
          entityTypes: [],
          hasPriority,
          postAction: () => undefined,
          toggleClose: () => dispatch(Store.UIActions.HIDE_SLIDEOUT()),
        },
      }

      dispatch(Store.UIActions.SHOW_SLIDEOUT(authorizationPolicyPaneConfig))
    }

    if (page === 'exception-policies' && showExceptionPolicySlideout) {
      const exceptionPolicyPaneConfig = {
        addlClasses: 'create-policy-pane',
        kind: 'exception-policy-create' as const,
        childProps: {
          hasPriority,
          postAction: () => undefined,
          toggleClose: () => dispatch(Store.UIActions.HIDE_SLIDEOUT()),
        },
      }

      dispatch(Store.UIActions.SHOW_SLIDEOUT(exceptionPolicyPaneConfig))
    }

    const [_, maybeSpecialViewName] = pathname.split('/view/')
    const isSpecialView = isSpecialViewName(maybeSpecialViewName)

    if (showGuidanceSlideout && isSpecialView) {
      dispatch(Store.UIActions.SHOW_GUIDANCE_PANE({ savedViewSlug: maybeSpecialViewName }))
    }
  }, [
    dispatch,
    hasPriority,
    pathname,
    showAuthorizationPolicySlideout,
    showExceptionPolicySlideout,
    showGuidanceSlideout,
    showPolicySlideout,
    state,
  ])

  return null
}

const getUrlSegments = memoize((pathname: string) => {
  const segments = pathname.split('/')

  const page = segments.slice(2).join('/')
  const prefix = nth(1, segments)
  const section = nth(2, segments)

  return {
    segments,
    page,
    prefix,
    section,
  }
})

export const isSummaryView = (pathname: string) => {
  const pathNameSegments = pathname.split('/')

  const summarySegment = nth(2, pathNameSegments) ?? ''
  const isSavedView = nth(3, pathNameSegments) === 'view' || pathNameSegments.length === 3

  const summarySegments = [
    'authorization-policies',
    'detections',
    'exception-policies',
    'hostnames',
    'implants',
    'ips',
    'networks',
    'policies',
    'redirectors',
    'reports',
    'runbooks',
    'saved-views',
    'services',
    'social',
    'targets',
  ]

  return summarySegments.includes(summarySegment) && isSavedView
}

export const isNotSummaryView = complement(isSummaryView)

export const getOrgSegment = (pathname: string) => {
  const pathNameSegments = pathname.split('/')

  return nth(1, pathNameSegments) ?? null
}
