/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AffiliatedNetwork, EntityPagination } from '@/codecs'

import { EntityName, initialState, State } from './affiliated-network.constants'
import { normalizeAffiliatedNetworks } from './affiliated-network.schema'

// ---------------------------------------------------------------------------

export const affiliatedNetworkSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    // base
    // ------------------------------------------------------------------------

    replaceAffiliatedNetworks: (state, action: PayloadAction<ReturnType<typeof normalizeAffiliatedNetworks>>) => {
      state.result = action.payload.result

      state.entities = {
        ...state.entities,
        ...action.payload.entities,
      }
    },

    replaceAffiliatedNetworkTotals: (state, action: PayloadAction<State['totals']>) => {
      state.totals = action.payload
    },

    replaceAffiliatedNetworkPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },

    upsertAffiliatedNetwork: (state, action: PayloadAction<AffiliatedNetwork>) => {
      const id = action.payload.id

      state.entities[`${EntityName}`][id] = action.payload
    },

    upsertAffiliatedNetworks: (state, action: PayloadAction<ReturnType<typeof normalizeAffiliatedNetworks>>) => {
      state.entities = {
        ...state.entities,
        ...action.payload.entities,
      }
    },
  },
})
