/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil, isNotNilOrEmpty } from '@randori/rootkit'

import * as Codecs from '@/codecs'
import * as TagUtils from '@/utilities/tags'

// ---------------------------------------------------------------------------

type HasTechCategory = {
  tech_category: string[]
} & Codecs.Entity

export const formatUserTags = (tags: string[]) => (isNotNilOrEmpty(tags) ? tags.join(', ') : '--')

export const entityHasTechCategory = (entity: Codecs.Entity): entity is HasTechCategory => {
  return isNotNil((entity as Record<string, unknown>).tech_category)
}

export const createTagsAndPropertyList = (entity: Codecs.Entity) => {
  const list = []

  if (TagUtils.entityHasUserTags(entity)) {
    list.push([
      {
        term: 'tags',
        desc: formatUserTags(entity.user_tags),
      },
    ])
  }

  if (entityHasTechCategory(entity)) {
    list.push([
      {
        term: 'tech category',
        desc: isNotNilOrEmpty(entity.tech_category) ? entity.tech_category.join(', ') : '--',
      },
    ])
  }

  return list
}
