/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { map, reduce } from 'lodash/fp'

import type { ConfidenceBoundaries, PriorityPreference } from '@/store/selectors/preferences/preferences.selectors'
import type { TTBoundaries } from '@/store/selectors/preferences/preferences.selectors.utils'
import type { EntityType } from '@/utilities/r-entity'

import { stringWrap } from './string-wrap'
import { transformFields } from './transform-fields'

// ---------------------------------------------------------------------------

export const formatRecord =
  (
    fields: string[],
    priorityBoundaries: PriorityPreference,
    temptationBoundaries: TTBoundaries,
    confidenceBoundaries: ConfidenceBoundaries,
    kind: EntityType,
  ) =>
  (record: Record<string, unknown>) => {
    const sortedRecord = reduce(
      (acc, el) => {
        acc[el] = record[el]

        return acc
      },
      {} as Record<string, unknown>,
      fields,
    )

    const formattedRecord = transformFields(
      sortedRecord,
      priorityBoundaries,
      temptationBoundaries,
      confidenceBoundaries,
      kind,
    )
    const wrappedRecord = map(stringWrap, formattedRecord)

    return wrappedRecord
  }
