/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Asset } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, rflaskGetSingle, throwValidate, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type GetAssets = t.TypeOf<typeof GetAssets>
const GetAssets = rflaskGetMany(Asset)

export type GetAsset = t.TypeOf<typeof GetAsset>
const GetAsset = rflaskGetSingle(Asset)

export type AssetApi = {
  getAssets: (query: QueryString | '') => Promise<GetAssets>
  getAsset: (id: UUID) => Promise<GetAsset>
}

export function assetApiFactory(http: HttpClient): AssetApi {
  const baseUrl = '/recon/api/v2/asset'

  return {
    getAssets(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetAssets, 'GetAssets'))
    },

    getAsset(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetAsset, 'GetAsset'))
    },
  }
}
