/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from '@/store'
import { UUID } from '@/utilities/codec'

import { makeSelectPagination, makeSelectTotals } from '../utilities/make-selectors'
import { EntityName } from './social.constants'

// ---------------------------------------------------------------------------

export const selectSocialPagination = makeSelectPagination(EntityName)
export const selectSocialTotals = makeSelectTotals(EntityName)

export const selectSocials = createSelector([(state: AppState) => state.social], (state) => {
  const orderedEntities = state.result.map((uuid) => state.entities.social[uuid])

  return orderedEntities
})

export const selectSocialById = createSelector(
  [
    (state: AppState, _props: { id: UUID }) => state.social.entities,
    (_state: AppState, props: { id: UUID }) => props.id,
  ],
  (entities, uuid) => {
    const entity = entities.social[uuid]

    return entity
  },
)
