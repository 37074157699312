/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { DeferredAction } from 'redux-saga-try-catch'

// ---------------------------------------------------------------------------

export const fetchEntitlements = createAction(
  'entitlement/fetch-entitlements',
  (payload: { orgId: string }, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const fetchEntitlementsSources = createAction(
  'entitlement/fetch-entitlements-sources',
  (payload: { orgId: string }, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const fetchEntitlementsUsage = createAction(
  'entitlement/fetch-entitlements-usage',
  (payload: { orgId: string }, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)
