/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Network } from './network'

// ---------------------------------------------------------------------------

export type NetworksResponse = t.TypeOf<typeof NetworksResponse>
export const NetworksResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Network),
})

export type NetworkResponse = t.TypeOf<typeof NetworkResponse>
export const NetworkResponse = t.type({
  data: Network,
})
