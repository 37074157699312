/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { AxiosInstance } from 'axios'
import axiosRetry, { exponentialDelay } from 'axios-retry'
import debug from 'debug'

import * as Logger from '@/utilities/logger'

import * as HttpUtils from './http.utils'
import type { DefaultHeaders, HttpClient, HttpStatic } from './http.utils'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:http-factory')

type ClientFactoryConfig = {
  headers: DefaultHeaders
  http: HttpStatic
  shouldRetry: boolean
}

/**
 * Create a factory function to instantiate an http client with http.create
 *
 * @remarks
 * Instruments retry logic on failed requests
 *
 * @param config - configuration
 *
 * @returns the initializer:  (rootUrl: string) =\> http.create(...)
 */
export function ClientFactory({ headers, http, shouldRetry }: ClientFactoryConfig) {
  return (rootUrl: string): HttpClient => {
    const client = http.create({
      baseURL: rootUrl,
      headers,
    })

    if (shouldRetry) {
      log('instrumenting exponential delayed retry')

      return _instrumentAxiosRetry(client)
    }

    return client
  }
}

const _instrumentAxiosRetry = (client: HttpClient) => {
  axiosRetry(client as AxiosInstance, {
    retries: 9,

    retryCondition: (error) => {
      return HttpUtils.is429(error)
    },

    retryDelay: (retryCount, error) => {
      const _wait = exponentialDelay(retryCount)

      if (HttpUtils.is429(error)) {
        Logger.log(`Retry - delay: ${_wait} - count: ${retryCount}`)
      }

      return _wait
    },
  })

  return client
}
