/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const ActivityOperators = [
  'equal',
  'not_equal',
  'less_than',
  'greater_than_or_equal_to',
  'less_than_or_equal_to',
  'greater_than',
  'service_match',
  'service_not_match',
  'in',
  'not_in',
  'contains',
  'not_contains',
  'exists', //for input variable
  'match', //for input variable
  'not_match', //for input variable
] as const
export type _ActivityOperators = (typeof ActivityOperators)[number]

const ActivityOperatorsProjection = ActivityOperators.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<_ActivityOperators, void>)

export const ActivityOperator = t.keyof(ActivityOperatorsProjection)
export type ActivityOperator = t.TypeOf<typeof ActivityOperator>
