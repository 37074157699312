/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export type ProviderId = t.TypeOf<typeof ProviderId>
export const ProviderId = t.keyof({
  saml: null,
})

export type SamlConfig = t.TypeOf<typeof SamlConfig>
export const SamlConfig = t.type({
  addExtensionsElementWithKeyInfo: t.string,
  nameIDPolicyFormat: t.string,
  postBindingAuthnRequest: t.string,
  postBindingLogout: t.string,
  postBindingResponse: t.string,
  principalType: t.string,
  samlXmlKeyNameTranformer: t.string,
  signatureAlgorithm: t.string,
  signingCertificate: t.string,
  singleSignOnServiceUrl: t.string,
  syncMode: t.string,
  useJwksUrl: t.string,
  validateSignature: t.string,
  wantAssertionsEncrypted: t.string,
  wantAssertionsSigned: t.string,
  wantAuthnRequestsSigned: t.string,
})

export type SsoProvider = t.TypeOf<typeof SsoProvider>
export const SsoProvider = t.type({
  alias: t.string,
  // config: SamlConfig,
  config: t.record(t.string, t.string),
  displayName: t.string,
  enabled: t.boolean,
  providerId: ProviderId,
})

export type SsoProvidersResponse = t.TypeOf<typeof SsoProvidersResponse>
export const SsoProvidersResponse = t.type({
  sso_connections: t.array(SsoProvider),
})

export type SsoConfirm = t.TypeOf<typeof SsoConfirm>
export const SsoConfirm = t.type({
  access_token: t.string,
})

export type SsoConfirmResponse = t.TypeOf<typeof SsoConfirmResponse>
export const SsoConfirmResponse = t.type({
  Authorization: t.string,
})

export type SsoRecover = t.TypeOf<typeof SsoRecover>
export const SsoRecover = t.type({
  user_email: t.string,
})

export type ParsedIDP = t.TypeOf<typeof ParsedIDP>
export const ParsedIDP = t.record(t.string, t.string)

export type ParsedIDPResponse = t.TypeOf<typeof ParsedIDPResponse>
export const ParsedIDPResponse = t.type({
  data: ParsedIDP,
})
