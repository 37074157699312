/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, UUID } from '@/utilities/codec'

import * as actions from './perspective.actions'
import { normalizePerspectives } from './perspective.schema'
import { perspectiveSlice } from './perspective.slice'

// ---------------------------------------------------------------------------

export function* _fetchPerspectives(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchPerspectives>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.perspective.getPerspectives, queryString)

  if (action.meta.persist) {
    yield* put(perspectiveSlice.actions.updatePerspectives(normalizePerspectives(response.perspectives)))
  }

  return response
}

export function* _fetchPerspective(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchPerspective>) {
  const id = get(action.payload, UUID)

  const perspective = yield* call(io.api.perspective.getPerspective, id)

  yield* put(perspectiveSlice.actions.updatePerspective(perspective))

  return perspective
}

export function* _createPerspective(io: MiddlewaresIO, action: ReturnType<typeof actions.createPerspective>) {
  const requestPayload = action.payload

  const data = yield* call(io.api.perspective.createPerspective, requestPayload)

  yield* put(perspectiveSlice.actions.updatePerspective(data))

  return data
}

export function* perspectiveSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.createPerspective.toString(), deferredAction(_createPerspective, io))
  yield takeEvery(actions.fetchPerspective.toString(), deferredAction(_fetchPerspective, io))
  yield takeEvery(actions.fetchPerspectives.toString(), deferredAction(_fetchPerspectives, io))
}
