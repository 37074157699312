/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

const RuleState = t.keyof({
  applying: null,
  matching: null,
  inactive: null,
})
type RuleState = t.TypeOf<typeof RuleState>

export const CharacteristicRule = t.interface({
  id: t.string,

  artifact_flags: t.array(t.number),
  artifact_types: t.array(t.number),
  creator_uuid: t.string,
  description: t.string,
  detections: t.number,
  first_time: t.string,
  is_case_sensitive: t.boolean,
  matches_filter_strings: t.array(t.string),
  name: t.string,
  not_matches_filter_strings: t.array(t.string),
  org_id: t.union([t.string, t.null]),
  state: RuleState,
  time: t.string,
})
export type CharacteristicRule = t.TypeOf<typeof CharacteristicRule>

export const CharacteristicRuleResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(CharacteristicRule),
})
export type CharacteristicRuleResponse = t.TypeOf<typeof CharacteristicRuleResponse>

export const CharacteristicRulePatchPayload = t.interface({
  data: t.partial({
    artifact_flags: t.array(t.number),
    artifact_types: t.array(t.number),
    creator_uuid: t.string,
    description: t.string,
    detections: t.number,
    first_time: t.string,
    is_case_sensitive: t.boolean,
    matches_filter_strings: t.array(t.string),
    name: t.string,
    not_matches_filter_strings: t.array(t.string),
    org_id: t.union([t.string, t.null]),
    state: RuleState,
    time: t.string,
  }),
})
export type CharacteristicRulePatchPayload = t.TypeOf<typeof CharacteristicRulePatchPayload>
export type CharacteristicRulePostPayload = t.TypeOf<typeof CharacteristicRulePatchPayload>

export const CharacteristicRulePatchResponse = t.interface({
  data: t.partial({
    artifact_flags: t.array(t.number),
    artifact_types: t.array(t.number),
    creator_uuid: t.string,
    description: t.string,
    detections: t.number,
    first_time: t.string,
    is_case_sensitive: t.boolean,
    matches_filter_strings: t.array(t.string),
    name: t.string,
    not_matches_filter_strings: t.array(t.string),
    org_id: t.union([t.string, t.null]),
    state: RuleState,
    time: t.string,
  }),
})
export type CharacteristicRulePatchResponse = t.TypeOf<typeof CharacteristicRulePatchResponse>

export const CharacteristicRulePostResponse = t.interface({
  ids: t.array(t.string),
})
export type CharacteristicRulePostResponse = t.TypeOf<typeof CharacteristicRulePostResponse>
