/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as HttpUtils from './http.utils'
import type { HttpClient, Message } from './http.utils'

// ---------------------------------------------------------------------------

/**
 * Wraps HTTP verbs with some utility to extract data and handle errors

 * @param client - HttpClient
 *
 * @returns the augmented HttpClient
 */
export function VerbFactory(client: HttpClient): HttpClient {
  const _delete = (url: string) => {
    const t0 = window.performance.now()

    return client
      .delete(url)
      .then(HttpUtils.handleExtract)
      .catch(HttpUtils.handleError(url))
      .finally(HttpUtils.getTiming('DELETE', url, t0))
  }

  const _get = (url: string) => {
    const t0 = window.performance.now()

    return client
      .get(url)
      .then(HttpUtils.handleExtract)
      .catch(HttpUtils.handleError(url))
      .finally(HttpUtils.getTiming('GET', url, t0))
  }

  const _patch = (url: string, data: Message) => {
    const t0 = window.performance.now()

    return client
      .patch(url, data)
      .then(HttpUtils.handleExtract)
      .catch(HttpUtils.handleError(url))
      .finally(HttpUtils.getTiming('PATCH', url, t0))
  }

  const _post = (url: string, data: Message) => {
    const t0 = window.performance.now()

    return client
      .post(url, data)
      .then(HttpUtils.handleExtract)
      .catch(HttpUtils.handleError(url))
      .finally(HttpUtils.getTiming('POST', url, t0))
  }

  const _put = (url: string, data: Message) => {
    const t0 = window.performance.now()

    return client
      .put(url, data)
      .then(HttpUtils.handleExtract)
      .catch(HttpUtils.handleError(url))
      .finally(HttpUtils.getTiming('PUT', url, t0))
  }

  return {
    delete: _delete,
    get: _get,
    patch: _patch,
    post: _post,
    put: _put,
  }
}
