/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'

import * as Store from '@/store'

import { HydratePrefs } from './hydrate-prefs'
import { withSession, WithSessionInnerProps } from './with-session'

// ---------------------------------------------------------------------------

type HocProtectedRouteViewProps = WithSessionInnerProps & RouteProps

const HocProtectedRouteView: React.FunctionComponent<HocProtectedRouteViewProps> = (props) => {
  const hasPermission = useSelector(Store.GateSelectors.hasPermission)
  const isAuthenticated = useSelector(Store.SessionSelectors.selectIsAuthenticated)

  const isEnabled = [isAuthenticated, hasPermission('hoc-access')].every(Boolean)

  const { render, ...rest } = props

  // @TODO: Change redirect to view_org /dashboard
  return (
    <React.Fragment>
      {/*
        <HydratePrefs /> is currently a necssary function, as the user can
        initialize the application from a HOC route, in which case there are
        no Randori preferences in memory. This has the bad side-effect of
        breaking any rendering that relies on preferences, like
        target_temptation or tt_madlib.
      */}
      <Route
        {...rest}
        render={(props: RouteComponentProps) =>
          isEnabled ? <HydratePrefs {...props} render={render} /> : <Redirect to="/login" />
        }
      />
    </React.Fragment>
  )
}

// ---------------------------------------------------------------------------

export const HocProtectedRoute = withSession(HocProtectedRouteView)
