/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

const RuleState = t.keyof({
  applying: null,
  matching: null,
  inactive: null,
})
export type RuleState = t.TypeOf<typeof RuleState>

export const ServiceRule = t.interface({
  id: t.string,

  artifact_flags: t.array(t.number),
  artifact_types: t.array(t.number),
  creator_user_name: t.string,
  creator_uuid: t.string,
  description: t.string,
  first_time: t.string,
  is_case_sensitive: t.boolean,
  matches_filter_strings: t.array(t.string),
  name: t.string,
  not_matches_filter_strings: t.array(t.string),
  org_id: t.union([t.string, t.null]),
  service_capture_filter_strings: t.array(t.string),
  service_name: t.union([t.string, t.null]),
  service_version: t.union([t.string, t.null]),
  state: RuleState,
  time: t.string,
})
export type ServiceRule = t.TypeOf<typeof ServiceRule>

const _ServiceRuleMeta = t.interface({
  detections: t.number,
})

export const ServiceRuleWithStats = t.intersection([ServiceRule, _ServiceRuleMeta])
export type ServiceRuleWithStats = t.TypeOf<typeof ServiceRuleWithStats>

export const ServiceRuleResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ServiceRuleWithStats),
})
export type ServiceRuleResponse = t.TypeOf<typeof ServiceRuleResponse>

export const ServiceRulePatchPayload = t.interface({
  data: t.partial({
    artifact_flags: t.union([t.array(t.number), t.null]),
    artifact_types: t.union([t.array(t.number), t.null]),
    creator_uuid: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),
    first_time: t.union([t.string, t.null]),
    is_active: t.union([t.boolean, t.null]),
    is_case_sensitive: t.union([t.boolean, t.null]),
    matches_filter_strings: t.union([t.array(t.string), t.null]),
    name: t.union([t.string, t.null]),
    not_matches_filter_strings: t.union([t.array(t.string), t.null]),
    org_id: t.union([t.string, t.null]),
    service_capture_filter_strings: t.union([t.array(t.string), t.null]),
    service_name: t.union([t.string, t.null]),
    service_version: t.union([t.string, t.null]),
    state: RuleState,
    time: t.union([t.string, t.null]),
  }),
})

export type ServiceRulePatchPayload = t.TypeOf<typeof ServiceRulePatchPayload>
export type ServiceRulePostPayload = t.TypeOf<typeof ServiceRulePatchPayload>

export const ServiceRulePatchResponse = t.interface({
  data: t.interface({
    id: t.string,
    artifact_flags: t.array(t.number),
    artifact_types: t.array(t.number),
    creator_uuid: t.string,
    description: t.string,
    first_time: t.string,
    is_case_sensitive: t.boolean,
    matches_filter_strings: t.array(t.string),
    name: t.string,
    not_matches_filter_strings: t.array(t.string),
    org_id: t.union([t.string, t.null]),
    service_capture_filter_strings: t.array(t.string),
    service_name: t.union([t.string, t.null]),
    service_version: t.union([t.string, t.null]),
    state: RuleState,
    time: t.string,
  }),
})
export type ServiceRulePatchResponse = t.TypeOf<typeof ServiceRulePatchResponse>

export const ServiceRulePostResponse = t.interface({
  ids: t.array(t.string),
})
export type ServiceRulePostResponse = t.TypeOf<typeof ServiceRulePostResponse>

const TryServiceRuleMatches = t.interface({
  end: t.number,
  name: t.string,
  name_end: t.number,
  name_start: t.number,
  start: t.number,
  version: t.string,
  version_end: t.number,
  version_start: t.number,
})

export type TryServiceRuleMatches = t.TypeOf<typeof TryServiceRuleMatches>

export const TryServiceRule = t.interface({
  matches: t.record(t.string, TryServiceRuleMatches),
  misses: t.union([t.record(t.string, t.unknown), t.null]),
})
export type TryServiceRuleResponse = t.TypeOf<typeof TryServiceRule>
