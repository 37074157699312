/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from 'reselect'

// ---------------------------------------------------------------------------
import { AppState } from '@/store'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

const _getBlueprints = (state: AppState) => state.hoc.blueprints
const _getBlueprintsPagination = (state: AppState) => state.hoc.blueprintsPagination
const _getBlueprintsTotals = (state: AppState) => state.hoc.blueprintsTotals

const _getBars = (state: AppState) => state.hoc.bars
const _getBarsPagination = (state: AppState) => state.hoc.barsPagination
const _getBarsTotals = (state: AppState) => state.hoc.barsTotals
// const _getBarById = (state: AppState, props: { id: string }) =>
//   state.hoc.bars.entities.bar[props.id]
const _getBarsById = (state: AppState, props: { ids: string[] }) =>
  props.ids.map((id) => state.hoc.bars.entities.bar[id])

const _getGlobalServices = (state: AppState) => state.hoc.globalServices
const _getGlobalServicesPagination = (state: AppState) => state.hoc.globalServicesPagination
const _getGlobalServicesTotals = (state: AppState) => state.hoc.globalServicesTotals

const _getGlobalServiceById = (state: AppState, props: { id: string }) =>
  state.hoc.globalServices.entities.globalService[props.id]

const _getGlobalServicesById = (state: AppState, props: { ids: string[] }) => {
  return props.ids.map((id) => state.hoc.globalServices.entities.globalService[id])
}

const _getGlobalIps = (state: AppState) => state.hoc.globalIps
const _getGlobalIpsPagination = (state: AppState) => state.hoc.globalIpsPagination
const _getGlobalIpsTotals = (state: AppState) => state.hoc.globalIpsTotals

const _getGlobalHostnames = (state: AppState) => state.hoc.globalHostnames
const _getGlobalHostnamesPagination = (state: AppState) => state.hoc.globalHostnamesPagination
const _getGlobalHostnamesTotals = (state: AppState) => state.hoc.globalHostnamesTotals

const _getGlobalNetworks = (state: AppState) => state.hoc.globalNetworks
const _getGlobalNetworksPagination = (state: AppState) => state.hoc.globalNetworksPagination
const _getGlobalNetworksTotals = (state: AppState) => state.hoc.globalNetworksTotals

const _getPocs = (state: AppState) => state.hoc.pocs
const _getPocPagination = (state: AppState) => state.hoc.pocsPagination
const _getPocsTotals = (state: AppState) => state.hoc.pocsTotals

const _getTerms = (state: AppState) => state.hoc.terms
const _getTermsPagination = (state: AppState) => state.hoc.termsPagination
const _getTermsTotals = (state: AppState) => state.hoc.termsTotals

const _getServiceSuggestions = (state: AppState) => state.hoc.serviceSuggestions
const _getServiceSuggestionsPagination = (state: AppState) => state.hoc.serviceSuggestionsPagination
const _getServiceSuggestionsTotals = (state: AppState) => state.hoc.serviceSuggestionsTotals

const _getServiceSuggestionById = (state: AppState, props: { id: string }) =>
  state.hoc.serviceSuggestions.entities.serviceSuggestion[props.id]

const _getServiceSuggestionsById = (state: AppState, props: { ids: string[] }) => {
  return props.ids.map((id) => state.hoc.serviceSuggestions.entities.serviceSuggestion[id])
}

const _getServiceRules = (state: AppState) => state.hoc.serviceRules
const _getServiceRulesPagination = (state: AppState) => state.hoc.serviceRulesPagination
const _getServiceRulesTotals = (state: AppState) => state.hoc.serviceRulesTotals
const _getServiceRuleById = (state: AppState, props: { id: string }) => {
  return state.hoc.serviceRules.entities.serviceRule[props.id]
}

const _getCharacteristicRules = (state: AppState) => state.hoc.characteristicRules
const _getCharacteristicRulesPagination = (state: AppState) => state.hoc.characteristicRulesPagination
const _getCharacteristicRulesTotals = (state: AppState) => state.hoc.characteristicRulesTotals
const _getCharacteristicRuleById = (state: AppState, props: { id?: string }) => {
  if (isNotNil(props.id)) {
    return state.hoc.characteristicRules.entities.characteristicRule[props.id]
  } else {
    return undefined
  }
}

const _getArtifacts = (state: AppState) => state.hoc.artifacts
const _getArtifactsPagination = (state: AppState) => state.hoc.artifactsPagination
const _getArtifactsTotals = (state: AppState) => state.hoc.artifactsTotals
const _getArtifactById = (state: AppState, props: { id: string }) => {
  return state.hoc.artifacts.entities.artifact[props.id]
}
// blueprint
// ---------------------------------------------------------------------------

export const selectBlueprints = createSelector([_getBlueprints], (_blueprints) => {
  return (
    _blueprints.result
      .map((id: string) => _blueprints.entities.blueprint[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectBlueprintsPagination = createSelector([_getBlueprintsPagination], (pagination) => pagination)

export const selectBlueprintsTotals = createSelector([_getBlueprintsTotals], (totals) => totals)

// bar
// ---------------------------------------------------------------------------

export const selectBars = createSelector([_getBars], (_bars) => {
  return (
    _bars.result
      .map((id: string) => _bars.entities.bar[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectBarsPagination = createSelector([_getBarsPagination], (pagination) => pagination)

export const selectBarsTotals = createSelector([_getBarsTotals], (totals) => totals)

export const selectBarsById = createSelector([_getBarsById], (bars) => bars)

// global service
// ---------------------------------------------------------------------------

export const selectGlobalServices = createSelector([_getGlobalServices], (_services) => {
  return (
    _services.result
      .map((id: string) => _services.entities.globalService[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectGlobalServicesPagination = createSelector([_getGlobalServicesPagination], (pagination) => pagination)

export const selectGlobalServicesTotals = createSelector([_getGlobalServicesTotals], (totals) => totals)

export const selectGlobalServiceById = createSelector([_getGlobalServiceById], (service) => service)

export const selectGlobalServicesById = createSelector([_getGlobalServicesById], (services) => services)

// global ip
// ---------------------------------------------------------------------------

export const selectGlobalIps = createSelector([_getGlobalIps], (_ips) => {
  return (
    _ips.result
      .map((id: string) => _ips.entities.globalIp[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectGlobalIpsPagination = createSelector([_getGlobalIpsPagination], (pagination) => pagination)

export const selectGlobalIpsTotals = createSelector([_getGlobalIpsTotals], (totals) => totals)

// global hostname
// ---------------------------------------------------------------------------

export const selectGlobalHostnames = createSelector([_getGlobalHostnames], (_hostnames) => {
  return (
    _hostnames.result
      .map((id: string) => _hostnames.entities.globalHostname[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectGlobalHostnamesPagination = createSelector(
  [_getGlobalHostnamesPagination],
  (pagination) => pagination,
)

export const selectGlobalHostnamesTotals = createSelector([_getGlobalHostnamesTotals], (totals) => totals)

// global networks
// ---------------------------------------------------------------------------

export const selectGlobalNetworks = createSelector([_getGlobalNetworks], (_networks) => {
  return (
    _networks.result
      .map((id: string) => _networks.entities.globalNetwork[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectGlobalNetworksPagination = createSelector([_getGlobalNetworksPagination], (pagination) => pagination)

export const selectGlobalNetworksTotals = createSelector([_getGlobalNetworksTotals], (totals) => totals)

// pocs
// ---------------------------------------------------------------------------

export const selectPocs = createSelector([_getPocs], (_pocs) => {
  return (
    _pocs.result
      .map((id: string) => _pocs.entities.poc[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectPocsPagination = createSelector([_getPocPagination], (pagination) => pagination)

export const selectPocsTotals = createSelector([_getPocsTotals], (totals) => totals)

// terms
// ---------------------------------------------------------------------------

export const selectTerms = createSelector([_getTerms], (_terms) => {
  return (
    _terms.result
      .map((id: string) => _terms.entities.term[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectTermsPagination = createSelector([_getTermsPagination], (pagination) => pagination)

export const selectTermsTotals = createSelector([_getTermsTotals], (totals) => totals)

// suggestion
// ---------------------------------------------------------------------------

export const selectServiceSuggestions = createSelector([_getServiceSuggestions], (_services) => {
  return (
    _services.result
      .map((id: string) => _services.entities.serviceSuggestion[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectServiceSuggestionsPagination = createSelector(
  [_getServiceSuggestionsPagination],
  (pagination) => pagination,
)

export const selectServiceSuggestionsTotals = createSelector([_getServiceSuggestionsTotals], (totals) => totals)

export const selectServiceSuggestionById = createSelector([_getServiceSuggestionById], (suggestion) => suggestion)

export const selectServiceSuggestionsById = createSelector([_getServiceSuggestionsById], (suggestions) => suggestions)

// service rules
// ---------------------------------------------------------------------------

export const selectServiceRules = createSelector([_getServiceRules], (_services) => {
  return (
    _services.result
      .map((id: string) => _services.entities.serviceRule[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectServiceRulesPagination = createSelector([_getServiceRulesPagination], (pagination) => pagination)

export const selectServiceRulesTotals = createSelector([_getServiceRulesTotals], (totals) => totals)

export const selectServiceRuleById = createSelector(
  [_getServiceRuleById],
  (serviceRule): ReturnType<typeof _getServiceRuleById> | undefined => serviceRule,
)

// characteristic rules
// ---------------------------------------------------------------------------

export const selectCharacteristicRules = createSelector([_getCharacteristicRules], (_rules) => {
  return (
    _rules.result
      .map((id: string) => _rules.entities.characteristicRule[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectCharacteristicRulesPagination = createSelector(
  [_getCharacteristicRulesPagination],
  (pagination) => pagination,
)

export const selectCharacteristicRulesTotals = createSelector([_getCharacteristicRulesTotals], (totals) => totals)

export const selectCharacteristicRuleById = createSelector(
  [_getCharacteristicRuleById],
  (characteristicRule): ReturnType<typeof _getCharacteristicRuleById> | undefined => characteristicRule,
)

// artifacts
// ---------------------------------------------------------------------------

export const selectArtifacts = createSelector([_getArtifacts], (_artifacts) => {
  return (
    _artifacts.result
      .map((id: string) => _artifacts.entities.artifact[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectArtifactsPagination = createSelector([_getArtifactsPagination], (pagination) => pagination)

export const selectArtifactsTotals = createSelector([_getArtifactsTotals], (totals) => totals)

export const selectArtifactById = createSelector(
  [_getArtifactById],
  (artifact): ReturnType<typeof _getArtifactById> | undefined => artifact,
)
