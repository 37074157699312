/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const PostOrgKeyValPayload = t.interface({
  data: t.interface({
    key: t.string,
    org_id: t.string,
    value: t.string,
  }),
})
export type PostOrgKeyValPayload = t.TypeOf<typeof PostOrgKeyValPayload>

export const PostOrgKeyValResponse = t.interface({
  ids: t.array(t.string),
})
export type PostOrgKeyValResponse = t.TypeOf<typeof PostOrgKeyValResponse>
