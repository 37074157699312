/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  MITRE_MITIGATION_FETCH = 'MITRE_MITIGATION_FETCH',
  MITRE_TACTIC_FETCH = 'MITRE_TACTIC_FETCH',
  MITRE_TECHNIQUE_FETCH = 'MITRE_TECHNIQUE_FETCH',
}

export type ActionTypes = MITRE_MITIGATION_FETCH | MITRE_TACTIC_FETCH | MITRE_TECHNIQUE_FETCH

export type MITRE_MITIGATION_FETCH = {
  type: TypeKeys.MITRE_MITIGATION_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
  }
}

export function MITRE_MITIGATION_FETCH(
  payload: MITRE_MITIGATION_FETCH['payload'],
  deferred: Deferred,
): MITRE_MITIGATION_FETCH {
  return {
    type: TypeKeys.MITRE_MITIGATION_FETCH,
    meta: { deferred },
    payload,
  }
}

export type MITRE_TACTIC_FETCH = {
  type: TypeKeys.MITRE_TACTIC_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
  }
}

export function MITRE_TACTIC_FETCH(payload: MITRE_TACTIC_FETCH['payload'], deferred: Deferred): MITRE_TACTIC_FETCH {
  return {
    type: TypeKeys.MITRE_TACTIC_FETCH,
    meta: { deferred },
    payload,
  }
}

export type MITRE_TECHNIQUE_FETCH = {
  type: TypeKeys.MITRE_TECHNIQUE_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
  }
}

export function MITRE_TECHNIQUE_FETCH(
  payload: MITRE_TECHNIQUE_FETCH['payload'],
  deferred: Deferred,
): MITRE_TECHNIQUE_FETCH {
  return {
    type: TypeKeys.MITRE_TECHNIQUE_FETCH,
    meta: { deferred },
    payload,
  }
}
