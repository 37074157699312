/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import {
  ArtifactRendererType as _ArtifactRendererType,
  ArtifactRendererTypes as _ArtifactRendererTypes,
} from '@/utilities/artifact'

// ---------------------------------------------------------------------------

const ArtifactRendererTypesProjection = _ArtifactRendererTypes.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<_ArtifactRendererType, void>)

export const ArtifactRendererType = t.keyof(ArtifactRendererTypesProjection)
export type ArtifactRendererType = t.TypeOf<typeof ArtifactRendererType>

export const ArtifactForActivityInstance = t.type({
  artifact_description: t.string,
  artifact_renderer: t.string,
  created: t.string,
  filename: t.string,
  id: t.string,
  is_renderable: t.boolean,
  org_id: t.string,
  renderable_metadata: t.union([t.record(t.string, t.string), t.null]),
  shasum: t.string,
  source: t.string,
  updated: t.string,
})

export const ArtifactPagination = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
})
export type ArtifactPagination = t.TypeOf<typeof ArtifactPagination>

export type ArtifactForActivityInstance = t.TypeOf<typeof ArtifactForActivityInstance>

export const ArtifactForActivityInstanceResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ArtifactForActivityInstance),
})

export type ArtifactForActivityInstanceResponse = t.TypeOf<typeof ArtifactForActivityInstanceResponse>
