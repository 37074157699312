/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'
import { propOr } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

const Feature = new schema.Entity(
  'feature',
  {},
  {
    idAttribute: (value) => {
      return value.name
    },
  },
)

export const Features = [Feature]

export function NormalizeOrgFeatures(features: Codecs.Feature[]) {
  type _Features = {
    feature: {
      [index: string]: Codecs.Feature
    }
  }

  type NormalizedFeatures = {
    entities: _Features | {}
    result: string[]
  }

  const munged: NormalizedFeatures = normalize(features, Features)

  return munged
}

const User = new schema.Entity('user')

export const Users = [User]

export function NormalizeOrgUsers(users: Codecs.User[]) {
  type _Users = {
    user: {
      [index: string]: Codecs.User
    }
  }

  type NormalizedUsers = {
    entities: _Users | {}
    result: string[]
  }

  const munged: NormalizedUsers = normalize(users, Users)

  return munged
}

export const ApiToken = new schema.Entity('api-token')
export const ApiTokens = [ApiToken]

export function NormalizeApiTokens(tokens: Codecs.ApiToken[]) {
  type _ApiTokens = {
    'api-token': {
      [index: string]: Codecs.ApiToken
    }
  }

  type NormalizedApiTokens = {
    entities: _ApiTokens | {}
    result: string[]
  }

  const munged: NormalizedApiTokens = normalize(tokens, ApiTokens)

  return munged
}

export const Provider = new schema.Entity('provider', {}, { idAttribute: 'alias' })
export const Providers = [Provider]

export function NormalizeProviders(providers: Codecs.SsoProvider[]) {
  type _Providers = {
    provider: {
      [index: string]: Codecs.SsoProvider
    }
  }

  type NormalizedProviders = {
    entities: _Providers
    result: string[]
  }

  const { result, entities } = normalize(providers, Providers)

  const _providers = propOr({}, 'provider', entities)

  return {
    result,
    entities: {
      provider: _providers,
    },
  } as NormalizedProviders
}
