/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { EntityPagination } from '@/codecs'
import { PerspectiveV2 } from '@/codecs/perspective.codecs'

// ---------------------------------------------------------------------------

export const testPerspectiveId = '440bb225-45f9-4ad8-b07c-c7557ed653d2'

export type PerspectiveState = {
  pagination: EntityPagination
  result: string[]
  totals: { unfiltered: number; unaffiliated: number }

  entities: {
    perspective: Record<string, PerspectiveV2>
  }
}

export type EntityName = 'perspective'
export const EntityName: EntityName = 'perspective'

export const initialState: PerspectiveState = {
  pagination: { count: 0, offset: 0, total: 0 },
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },

  entities: {
    perspective: {},
  },
}
