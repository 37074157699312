/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import { DeferredAction } from 'redux-saga-try-catch'

import { UUID } from '@/utilities/codec'

import { EntityName } from './asset.constants'

// ---------------------------------------------------------------------------

export const fetchAssets = createAction(
  `${EntityName}/fetch-many`,
  (
    payload: RouteComponentProps['location']['search'],
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchAsset = createAction(
  `${EntityName}/fetch-single`,
  (payload: UUID, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)

export const fetchAssetTotals = createAction(
  `${EntityName}/fetch-totals`,
  (payload: RouteComponentProps['location']['search'], deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  },
)
