/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { UUID } from '@/utilities/codec'
import { nullableP } from '@/utilities/codec'

import { PerspectiveTypeActivity, PerspectiveTypeRecon } from './perspective-type'

// ---------------------------------------------------------------------------

export type WithPerspective = t.TypeOf<typeof WithPerspective>
export const WithPerspective = t.type({
  perspective_id: nullableP(UUID), // uuid
  perspective_type: nullableP(PerspectiveTypeRecon), // external
  perspective__name: nullableP(t.string), // Default External
  perspective_is_targeted: nullableP(t.boolean), // false
})

export function withPerspective<T extends t.Mixed>(type: T) {
  return t.intersection([type, WithPerspective])
}

export type WithInstancePerspective = t.TypeOf<typeof WithInstancePerspective>
export const WithInstancePerspective = t.type({
  instance__perspective__id: nullableP(UUID),
  instance__perspective_type: nullableP(PerspectiveTypeActivity),
  instance__perspective__name: nullableP(t.string),
  instance__perspective_is_targeted: nullableP(t.boolean),
})

export function withInstancePerspective<T extends t.Mixed>(type: T) {
  return t.intersection([type, WithInstancePerspective])
}
