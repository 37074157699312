/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

const initialState = 0

export const EntityDetailContentRenderedReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.UPDATE_ENTITY_DETAIL_RENDER: {
      return state + 1
    }

    default: {
      return state
    }
  }
}
