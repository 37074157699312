/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ActivityInstance, ActivityInstancesResponse } from '@/codecs/activity'

// ---------------------------------------------------------------------------

const _perspectiveV2 = {
  instance__perspective__id: null,
  instance__perspective_type: null,
  instance__perspective__name: null,
  instance__perspective_is_targeted: null,
}

const _trafficDestination = {
  traffic_destination: 'unknown',
  traffic_destination_detail: {
    attributes: undefined,
    type: 'unknown',
  },
}

export const AttackActivity1: ActivityInstance = {
  id: '88e630ed-03f8-4def-bfa0-31ebf79ec6a0',
  configuration__description:
    'This attack attempts to exploit Wordpress related vulnerabilities via Remote Code Execution.',
  configuration__id: '682d34f9-bb40-467a-b657-20d491config',
  configuration__name: 'Wordpress, Wordpress, 3.2 Exploit',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 15.408097,
  instance__time_end: '2023-07-11 21:23:13.818018+00',
  instance__time_start: '2023-07-11 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006', 'TA0043'],
  mitre__techniques: ['G0050'], // NOTE: this mitre id will link to a group on mitre site: https://attack.mitre.org/groups/G0050/ (APT12)
  objective__attacker_perspective:
    'In an effort to seek additional data, Attackers will discover subdomains of a known domain.',
  objective__description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__implication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '09.34.20.15',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'Wordpress, Wordpress, 3.2',
  matching_entity__type: 'org_target',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const AttackActivity2: ActivityInstance = {
  id: '88e630ed-03f8-4def-bfa0-31ebf79ec6a1',
  configuration__description:
    'This attack attempts to exploit Wordpress related vulnerabilities via Remote Code Execution.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Wordpress, Wordpress, 3.2 Exploit',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 15.408097,
  instance__time_end: '2023-06-11 21:23:13.818018+00',
  instance__time_start: '2023-06-11 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Exploitation of this vulnerability allows me to escalate privileges.',
  objective__description:
    'A remote code execution vulnerability may exist in this target system that, if successful, will allow me to take control of the system.',
  objective__implication:
    'The system has an account with administrative privileges that could be controlled by an attacker.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '09.34.20.15',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'Wordpress, Wordpress, 3.2',
  matching_entity__type: 'org_target',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const AttackActivity3: ActivityInstance = {
  id: '88e530ed-03f8-4def-bfa0-31ebf79ec6a1',
  configuration__description:
    'This attack attempts to exploit Wordpress related vulnerabilities via Remote Code Execution.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Wordpress, Wordpress, 3.2 Exploit',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 15.408097,
  instance__time_end: '2023-05-11 21:23:13.818018+00',
  instance__time_start: '2023-05-11 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Exploitation of this vulnerability allows me to escalate privileges.',
  objective__description:
    'A remote code execution vulnerability may exist in this target system that, if successful, will allow me to take control of the system.',
  objective__implication:
    'The system has an account with administrative privileges that could be controlled by an attacker.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '09.34.20.15',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'Wordpress, Wordpress, 3.2',
  matching_entity__type: 'org_target',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const AttackActivity4: ActivityInstance = {
  id: '88e630ed-03f8-4def-bfa0-31ebf79123a1',
  configuration__description:
    'This attack attempts to exploit Wordpress vulnerability via pingback service calls with a source URI that corresponds to a large file, which triggers a long download session without a timeout constraint.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Wordpress, Wordpress, 3.2 Exploit',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 15.408097,
  instance__time_end: '2023-06-11 21:23:13.818018+00',
  instance__time_start: '2023-06-11 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Exploitation of this vulnerability allows an attacker to attempt a DDOS attack.',
  objective__description:
    'By denying service to this target, users will be unable to access this asset and business disruption will occur.',
  objective__implication: 'The organization loses functionality of this entity.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '09.34.20.15',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'Wordpress, Wordpress, 3.2',
  matching_entity__type: 'org_target',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const AttackActivity5: ActivityInstance = {
  id: '88e630ed-03f8-4def-bfa0-31ecf79423a1',
  configuration__description:
    'This attack attempts to exploit Wordpress vulnerability via pingback service calls with a source URI that corresponds to a large file, which triggers a long download session without a timeout constraint.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dd1d6',
  configuration__name: 'Wordpress, Wordpress, 3.2 Exploit',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 15.408097,
  instance__time_end: '2023-06-16 21:23:13.818018+00',
  instance__time_start: '2023-06-16 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Exploitation of this vulnerability allows an attacker to attempt a DDOS attack.',
  objective__description:
    'By denying service to this target, users will be unable to access this asset and business disruption will occur.',
  objective__implication: 'The organization loses functionality of this entity.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '09.34.20.15',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'Wordpress, Wordpress, 3.2',
  matching_entity__type: 'org_target',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const AttackActivity6: ActivityInstance = {
  id: '88e630ed-33f8-4def-bfa0-31ecf79423a19',
  configuration__description:
    'Cross-site request forgery (CSRF) vulnerability in wp-admin/index.php in WordPress 3.4.2 allows remote attackers to hijack the authentication of administrators for requests that modify an RSS URL via a dashboard_incoming_links edit action.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dd1d6',
  configuration__name: 'Wordpress, Wordpress, 3.2 Exploit',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 15.408097,
  instance__time_end: '2023-07-16 21:23:13.818018+00',
  instance__time_start: '2023-07-16 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective:
    'Exploitation of this vulnerability allows an attacker to hijack the authentication of administrators.',
  objective__description: 'Using a cross site request forgery attack, the attacker attempts to elevate privileges.',
  objective__implication: 'The attacker has some administrative privileges.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '09.34.20.15',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'Wordpress, Wordpress, 3.2',
  matching_entity__type: 'org_target',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const AttackActivity7: ActivityInstance = {
  id: '88e630ed-33f8-4def-bfa0-31ecf76423a19',
  configuration__description:
    'Cross-site request forgery (CSRF) vulnerability in wp-admin/index.php in WordPress 3.4.2 allows remote attackers to hijack the authentication of administrators for requests that modify an RSS URL via a dashboard_incoming_links edit action.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dd1d6',
  configuration__name: 'Wordpress, Wordpress, 3.2 Exploit',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 15.408097,
  instance__time_end: '2023-07-09 21:23:13.818018+00',
  instance__time_start: '2023-07-09 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective:
    'Exploitation of this vulnerability allows an attacker to hijack the authentication of administrators.',
  objective__description: 'Using a cross site request forgery attack, the attacker attempts to elevate privileges.',
  objective__implication: 'The attacker has some administrative privileges.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '09.34.20.15',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'Wordpress, Wordpress, 3.2',
  matching_entity__type: 'org_target',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

// ---------------------------------------------------------------------------

export const CredentialStuffingActivity1: ActivityInstance = {
  id: '88e630ed-03f8-4def-bfa0-31ebf76ec6y0',
  configuration__description:
    'Credential Stuffing activities use default, dictionaries, or dark web threat hunting to attempt to breach login pages, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56141046-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'Credential Stuffing',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1,
  count__relationships_deleted: 0,
  count__entities_updated: 0,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 14.408097,
  instance__time_end: '2023-04-04 21:23:13.818018+00',
  instance__time_start: '2023-04-04 21:23:38.226115+00',
  mitre__mitigations: ['M1036'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['T1110'],
  objective__attacker_perspective: 'To attempt to breach this target.',
  objective__description: 'Leveraging common or default passwords to breach a login page.',
  objective__implication: 'Access to the systems beyond the login page.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '192.168.2.1',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'mustang.demo.webernets.online',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const CredentialStuffingActivity2: ActivityInstance = {
  id: '88e630ed-03f8-4def-cfa0-31fbf76ec6a0',
  configuration__description:
    'Credential Stuffing activities use default, dictionaries, or dark web threat hunting to attempt to breach login pages, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56141046-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'Credential Stuffing',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1,
  count__relationships_deleted: 0,
  count__entities_updated: 0,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 1.408097,
  instance__time_end: '2023-03-03 21:23:13.818018+00',
  instance__time_start: '2023-03-03 21:23:38.226115+00',
  mitre__mitigations: ['M1036'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['T1110'],
  objective__attacker_perspective: 'To attempt to breach this target.',
  objective__description: 'Leveraging common or default passwords to breach a login page.',
  objective__implication: 'Access to the systems beyond the login page.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '192.168.2.1',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'mustang.demo.webernets.online',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const CredentialStuffingActivity3: ActivityInstance = {
  id: '88e630ed-05f8-9def-cfa0-31ebf76ec6a0',
  configuration__description:
    'Credential Stuffing activities use default, dictionaries, or dark web threat hunting to attempt to breach login pages, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56141046-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'Credential Stuffing',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1,
  count__relationships_deleted: 0,
  count__entities_updated: 0,
  instance__most_recent_instance_id: '04da9f15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 1.308097,
  instance__time_end: '2023-03-03 21:23:13.818018+00',
  instance__time_start: '2023-03-03 21:23:38.226115+00',
  mitre__mitigations: ['M1036'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['T1110'],
  objective__attacker_perspective: 'To attempt to breach this target.',
  objective__description: 'Leveraging common or default passwords to breach a login page.',
  objective__implication: 'Access to the systems beyond the login page.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '192.168.2.1',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'mustang.demo.webernets.online',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const CredentialStuffingActivity4: ActivityInstance = {
  id: '88e830ed-03f8-4def-bfa0-31ebf76ec6a0',
  configuration__description:
    'Credential Stuffing activities use default, dictionaries, or dark web threat hunting to attempt to breach login pages, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56141046-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'Credential Stuffing',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1,
  count__relationships_deleted: 0,
  count__entities_updated: 0,
  instance__most_recent_instance_id: '04da3b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 14.408097,
  instance__time_end: '2023-03-015 21:23:13.818018+00',
  instance__time_start: '2023-03-015 21:23:38.226115+00',
  mitre__mitigations: ['M1036'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['T1110'],
  objective__attacker_perspective: 'To attempt to breach this target.',
  objective__description: 'Leveraging common or default passwords to breach a login page.',
  objective__implication: 'Access to the systems beyond the login page.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '192.168.2.1',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'inmobiliariabaturone.com',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const CredentialStuffingActivity5: ActivityInstance = {
  id: '88e638ed-03f8-4def-bfa0-31ebf76ec6a0',
  configuration__description:
    'Credential Stuffing activities use default, dictionaries, or dark web threat hunting to attempt to breach login pages, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56141046-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'Credential Stuffing',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1,
  count__relationships_deleted: 0,
  count__entities_updated: 0,
  instance__most_recent_instance_id: '04da3b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 14.408097,
  instance__time_end: '2023-03-015 21:23:13.818018+00',
  instance__time_start: '2023-03-015 21:23:38.226115+00',
  mitre__mitigations: ['M1036'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['T1110'],
  objective__attacker_perspective: 'To attempt to breach this target.',
  objective__description: 'Leveraging common or default passwords to breach a login page.',
  objective__implication: 'Access to the systems beyond the login page.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '192.168.2.1',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'inmobiliariabaturone.com',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const CredentialStuffingActivity6: ActivityInstance = {
  id: '88e730ed-03f8-4def-bfa0-31ebf76ec6a0',
  configuration__description:
    'Credential Stuffing activities use default, dictionaries, or dark web threat hunting to attempt to breach login pages, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56141046-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'Credential Stuffing',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1,
  count__relationships_deleted: 0,
  count__entities_updated: 0,
  instance__most_recent_instance_id: '04da3b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Started',
  instance__time_duration: 14.408097,
  instance__time_end: '2023-03-015 21:23:13.818018+00',
  instance__time_start: '2023-03-015 21:23:38.226115+00',
  mitre__mitigations: ['M1036'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['T1110'],
  objective__attacker_perspective: 'To attempt to breach this target.',
  objective__description: 'Leveraging common or default passwords to breach a login page.',
  objective__implication: 'Access to the systems beyond the login page.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '192.168.2.1',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'inmobiliariabaturone.com',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

// ---------------------------------------------------------------------------

export const DNSHighActivity1: ActivityInstance = {
  id: '51cd147f-d1e7-4dfd-91dd-af10d9bbaf8e',

  configuration__description:
    'A Scraper is used to fetch headers and the DOM for lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56146946-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'DNSHigh',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 3,
  count__entities_updated: 5,
  instance__most_recent_instance_id: '04da9b13-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 24.408097,
  instance__time_end: '2023-04-03 21:23:13.818018+00',
  instance__time_start: '2023-04-03 21:23:38.226115+00',
  mitre__mitigations: ['M1056'],
  mitre__tactics: ['TA0043'],
  mitre__techniques: ['T1595', 'T1592'],
  objective__attacker_perspective: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__implication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '0.0.0.0',
  matching_entity__id: '181722a7-1e32-4fd1-b979-2894602b0767',
  matching_entity__name: '0.0.0.0',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const DNSHighActivity2: ActivityInstance = {
  id: '88e630ed-03f8-4def-bfa0-31ebf76ec6a0',
  configuration__description:
    'DNS queries are searches on Ips and hostnames that may be associated with your organization.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Domain Name Search High',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 10,
  count__relationships_deleted: 25,
  count__entities_updated: 100,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 6.408097,
  instance__time_end: '2023-10-11 21:23:13.818018+00',
  instance__time_start: '2023-10-11 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'To identify sites relevant to this target organization.',
  objective__description: 'An attacker is able to identify sites relevant to your organization.',
  objective__implication: 'An attacker is able to further their understanding of your network.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'inmo-factory.com',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const DNSLowActivity: ActivityInstance = {
  id: '88e630ed-03f8-4def-bda0-31abf76ec7a0',

  configuration__description:
    'A Scraper is used to fetch headers and the DOM for lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56146946-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'DNSLow',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 1,
  count__entities_updated: 5,
  instance__most_recent_instance_id: '04da9b13-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 24.408097,
  instance__time_end: '2023-04-03 21:23:13.818018+00',
  instance__time_start: '2023-04-03 21:23:38.226115+00',
  mitre__mitigations: ['M1056'],
  mitre__tactics: ['TA0043'],
  mitre__techniques: ['T1595', 'T1592'],
  objective__attacker_perspective: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__implication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '0.0.0.0',
  matching_entity__id: '3168f640-c075-43fd-b907-10bad0615e1f',
  matching_entity__name: '0.0.0.0',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

// ---------------------------------------------------------------------------

export const PortscannerExtremeActivity1: ActivityInstance = {
  configuration__description:
    'A portscanner is used to detect open ports on an IP address lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56146946-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'PortscannerExtreme',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 3,
  count__entities_updated: 6,
  count__relationships_deleted: 1,
  id: 'd79ad522-0c73-4405-af0b-edaa7b6de136',
  instance__most_recent_instance_id: '04da9b13-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 24.408097,
  instance__time_end: '2023-04-03 21:23:13.818018+00',
  instance__time_start: '2023-04-03 21:23:38.226115+00',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__id: '24f7b133-d4d5-42ee-88f8-bbefd135cf6c',
  matching_entity__ip: '192.168.40.67',
  matching_entity__name: '0.0.0.0',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  matching_entity__type: 'org_ip',
  mitre__mitigations: ['M1056'],
  mitre__tactics: ['TA0043'],
  mitre__techniques: ['T1595', 'T1592'],
  objective__attacker_perspective: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__implication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '0.0.0.0',
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerExtremeActivity2: ActivityInstance = {
  id: '85e630ed-03f8-4ref-bfa0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56941046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanner Extreme',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 5,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9c15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 0.308097,
  instance__time_end: '2023-10-11 21:23:13.818018+00',
  instance__time_start: '2023-10-11 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '194.18.20.6',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerExtremeActivity3: ActivityInstance = {
  id: '88e630ed-03f8-0ref-bfa0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56941046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanner Extreme',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 3,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9c15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 0.308097,
  instance__time_end: '2023-10-01 21:23:13.818018+00',
  instance__time_start: '2023-10-01 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.10.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '194.18.20.6',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerExtremeActivity4: ActivityInstance = {
  id: '88e630ed-03f8-4rff-bfa0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56941046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanner Extreme',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 3,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9c15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 0.208097,
  instance__time_end: '2023-10-05 21:23:13.818018+00',
  instance__time_start: '2023-10-05 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.10.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '194.18.20.6',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerExtremeActivity5: ActivityInstance = {
  id: '88e630ed-03p8-4ref-bfa0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56941046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanner Extreme',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 3,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9c15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 0.208097,
  instance__time_end: '2023-9-27 21:23:13.818018+00',
  instance__time_start: '2023-9-27 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.20.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '194.18.20.6',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerExtremeActivity6: ActivityInstance = {
  id: '88e630ed-03f8-4ref-bfa0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56941046-06y0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanner Extreme',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 7,
  count__relationships_deleted: 0,
  count__entities_updated: 1,
  instance__most_recent_instance_id: '04da9c15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 0.208097,
  instance__time_end: '2023-9-27 21:23:13.818018+00',
  instance__time_start: '2023-9-27 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.20.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '192.168.4.2',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerHighActivity: ActivityInstance = {
  id: '04da9b13-bbe9-44c4-b9e6-5c1882d8f6a1',

  configuration__description:
    'A portscanner is used to detect open ports on an IP address lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56146946-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'PortscannerHigh',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 3,
  count__relationships_deleted: 1,
  count__entities_updated: 6,
  instance__most_recent_instance_id: '04da9b13-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 24.408097,
  instance__time_end: '2023-04-03 21:23:13.818018+00',
  instance__time_start: '2023-04-03 21:23:38.226115+00',
  mitre__mitigations: ['M1056'],
  mitre__tactics: ['TA0043'],
  mitre__techniques: ['T1596', 'T1592'],
  objective__attacker_perspective: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__implication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '0.0.0.0',
  matching_entity__id: '5cd2c291-8e2b-496c-9209-3a3fd770f499',
  matching_entity__name: '0.0.0.1',
  matching_entity__type: 'org_ip',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerNetHighActivity1: ActivityInstance = {
  id: '88e630ed-03f8-4dtf-bfa0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanning Network High',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 200,
  count__relationships_deleted: 5,
  count__entities_updated: 156,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 6.408097,
  instance__time_end: '2023-10-11 21:23:13.818018+00',
  instance__time_start: '2023-10-11 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '192.168.4.0/24',
  matching_entity__type: 'org_network',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerNetHighActivity2: ActivityInstance = {
  id: '88e630ed-03f8-4def-bya0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanning Network High',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 125,
  count__relationships_deleted: 5,
  count__entities_updated: 100,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 6.408097,
  instance__time_end: '2023-10-06 21:23:13.818018+00',
  instance__time_start: '2023-10-06 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '192.168.10.0/24',
  matching_entity__type: 'org_network',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerNetHighActivity3: ActivityInstance = {
  id: '88e630ed-03f8-4ref-bfa0-31ebf76yc6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56941046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Portscanning Network High',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 115,
  count__relationships_deleted: 5,
  count__entities_updated: 100,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 6.408097,
  instance__time_end: '2023-10-16 21:23:13.818018+00',
  instance__time_start: '2023-10-16 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '192.168.20.0/24',
  matching_entity__type: 'org_network',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const PortscannerNetHighActivity4: ActivityInstance = {
  id: '88e630ed-03f8-4ref-bfa0-31ebf76ec6a0',
  configuration__description:
    'Portscanning requires communicating with systems and listening for responses on specific ports.',
  configuration__id: '56941046-06b0-675c-bfe5-f85b113dc1z6',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  configuration__name: 'Portscanning Network High',
  count__artifacts: 105,
  count__relationships_deleted: 5,
  count__entities_updated: 110,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 3.408097,
  instance__time_end: '2023-10-21 21:23:13.818018+00',
  instance__time_start: '2023-10-21 21:23:38.226115+00',
  mitre__mitigations: ['M1042'],
  mitre__tactics: ['TA0006'],
  mitre__techniques: ['G0050'],
  objective__attacker_perspective: 'Identify open ports to communicate with to identify an attack path.',
  objective__description: 'Communicate with open ports that are not typically identifiable as threats.',
  objective__implication: 'The attacker will be able to identify open ports.',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: '192.168.20.0/24',
  matching_entity__type: 'org_network',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

// ---------------------------------------------------------------------------

export const ScraperActivity1: ActivityInstance = {
  id: 'e78cd446-d88f-11ed-afa1-0242ac120002',

  configuration__description:
    'A Scraper is used to fetch headers and the DOM for lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  configuration__id: '56146946-06b0-495c-bfe5-f85b113dc1d6',
  configuration__name: 'ScraperHigh',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 2,
  count__relationships_deleted: 2,
  count__entities_updated: 5,
  instance__most_recent_instance_id: '04da9b13-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Started',
  instance__time_duration: null,
  instance__time_end: null,
  instance__time_start: '2023-04-03 21:23:38.226115+00',
  mitre__mitigations: ['M1056'],
  mitre__tactics: ['TA0043'],
  mitre__techniques: ['T1595', 'T1592'],
  objective__attacker_perspective: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__implication: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempus nisi risus.',
  objective__status: 'failure',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '0.0.0.0',
  matching_entity__id: '29f02e44-5717-4a65-8c9b-15eb17081d2a',
  matching_entity__name: '0.0.0.0',
  matching_entity__type: 'org_detection',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const ScraperActivity2: ActivityInstance = {
  id: '99e890ed-03f8-4def-bfa0-31ebf76ec6a0',
  configuration__description: "Scraper's collect content and data from discovered HTTP(s) services.",
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Web Scraper',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1000,
  count__relationships_deleted: 4,
  count__entities_updated: 850,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 3.408097,
  instance__time_end: '2023-09-15 21:23:13.818018+00',
  instance__time_start: '2023-09-15 21:23:38.226115+00',
  mitre__mitigations: ['M1043'],
  mitre__tactics: ['TA0009'],
  mitre__techniques: ['G0150'],
  objective__attacker_perspective: 'Discover information about the network from the current perspective.',
  objective__description: "Scraper's allow a threat actor to enumerate http.",
  objective__implication: 'An attacker will further their understanding of your threat landscape',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'micasainmobiliaria.com',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const ScraperActivity3: ActivityInstance = {
  id: '99e890ed-03f8-4def-bfa0-31ebf76xy6a0',
  configuration__description: "Scraper's collect content and data from discovered HTTP(s) services.",
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Web Scraper',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1000,
  count__relationships_deleted: 4,
  count__entities_updated: 850,
  instance__most_recent_instance_id: '04da9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 3.408097,
  instance__time_end: '2023-09-17 21:23:13.818018+00',
  instance__time_start: '2023-09-17 21:23:38.226115+00',
  mitre__mitigations: ['M1043'],
  mitre__tactics: ['TA0009'],
  mitre__techniques: ['G0150'],
  objective__attacker_perspective: 'Discover information about the network from the current perspective.',
  objective__description: "Scraper's allow a threat actor to enumerate http.",
  objective__implication: 'An attacker will further their understanding of your threat landscape',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'micasainmobiliaria.com1',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const ScraperActivity4: ActivityInstance = {
  id: '99e890ed-03f8-4def-rfa0-31ebf76xy6a0',
  configuration__description: "Scraper's collect content and data from discovered HTTP(s) services.",
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Web Scraper',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 1000,
  count__relationships_deleted: 4,
  count__entities_updated: 850,
  instance__most_recent_instance_id: '04ea9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 3.408097,
  instance__time_end: '2023-09-17 21:23:13.818018+00',
  instance__time_start: '2023-09-17 21:23:38.226115+00',
  mitre__mitigations: ['M1043'],
  mitre__tactics: ['TA0009'],
  mitre__techniques: ['G0150'],
  objective__attacker_perspective: 'Discover information about the network from the current perspective.',
  objective__description: "Scraper's allow a threat actor to enumerate http.",
  objective__implication: 'An attacker will further their understanding of your threat landscape',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'mustang.demo.webernets.online',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const ScraperActivity5: ActivityInstance = {
  id: '99e890ed-03f8-4def-rfa0-31ebf76yy6a0',
  configuration__description: "Scraper's collect content and data from discovered HTTP(s) services.",
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Web Scraper',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 600,
  count__relationships_deleted: 10,
  count__entities_updated: 350,
  instance__most_recent_instance_id: '04ea9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 1.408097,
  instance__time_end: '2023-10-17 21:23:13.818018+00',
  instance__time_start: '2023-10-17 21:23:38.226115+00',
  mitre__mitigations: ['M1043'],
  mitre__tactics: ['TA0009'],
  mitre__techniques: ['G0150'],
  objective__attacker_perspective: 'Discover information about the network from the current perspective.',
  objective__description: "Scraper's allow a threat actor to enumerate http.",
  objective__implication: 'An attacker will further their understanding of your threat landscape',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'mustang.demo.webernets.online',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const ScraperActivity6: ActivityInstance = {
  id: '99e890ed-03f8-4def-rfa0-31ebf76zy6a0',
  configuration__description: "Scraper's collect content and data from discovered HTTP(s) services.",
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Web Scraper',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 600,
  count__relationships_deleted: 10,
  count__entities_updated: 350,
  instance__most_recent_instance_id: '04ea9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 1.408097,
  instance__time_end: '2023-12-17 21:23:13.818018+00',
  instance__time_start: '2023-12-17 21:23:38.226115+00',
  mitre__mitigations: ['M1043'],
  mitre__tactics: ['TA0009'],
  mitre__techniques: ['G0150'],
  objective__attacker_perspective: 'Discover information about the network from the current perspective.',
  objective__description: "Scraper's allow a threat actor to enumerate http.",
  objective__implication: 'An attacker will further their understanding of your threat landscape',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'inmobiliariabaturone.com',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

export const ScraperActivity7: ActivityInstance = {
  id: '11e890ed-03f8-4def-rfa0-31ebf76zy6a0',
  configuration__description: "Scraper's collect content and data from discovered HTTP(s) services.",
  configuration__id: '56141046-06b0-675c-bfe5-f85b113dc1d6',
  configuration__name: 'Web Scraper',
  configuration__version_id: '682d34f9-bb40-467a-b657-20d491e90abc',
  count__artifacts: 600,
  count__relationships_deleted: 10,
  count__entities_updated: 350,
  instance__most_recent_instance_id: '04ea9b15-bbe9-44c4-b9e6-5c1882d8f6a1',
  instance__state: 'Ingest Complete',
  instance__time_duration: 1.408097,
  instance__time_end: '2023-11-17 21:23:13.818018+00',
  instance__time_start: '2023-11-17 21:23:38.226115+00',
  mitre__mitigations: ['M1043'],
  mitre__tactics: ['TA0009'],
  mitre__techniques: ['G0150'],
  objective__attacker_perspective: 'Discover information about the network from the current perspective.',
  objective__description: "Scraper's allow a threat actor to enumerate http.",
  objective__implication: 'An attacker will further their understanding of your threat landscape',
  objective__status: 'success',
  org_id: '6fbe27b0-958e-4b1b-a7e5-2d983b824470',
  traffic_source__ip_name: '11.15.80.19',
  matching_entity__id: '3168f640-c075-44fd-b907-10bad0615e1f',
  matching_entity__name: 'inmobiliariabaturone.com',
  matching_entity__type: 'org_hostname',
  matching_entity__detection: 'hostname: -, ip: -, network: -, port: -',
  matching_entity__email: null,
  matching_entity__hostname: null,
  matching_entity__ip: '192.168.40.67',
  matching_entity__network: null,
  matching_entity__target: null,
  matching_entity__term: null,
  ..._trafficDestination,
  ..._perspectiveV2,
}

// ---------------------------------------------------------------------------

export const activityInstances = [
  AttackActivity1,
  AttackActivity2,
  AttackActivity3,
  AttackActivity4,
  AttackActivity5,
  AttackActivity6,
  AttackActivity7,
  CredentialStuffingActivity1,
  CredentialStuffingActivity2,
  CredentialStuffingActivity3,
  CredentialStuffingActivity4,
  CredentialStuffingActivity5,
  CredentialStuffingActivity6,
  DNSHighActivity1,
  DNSHighActivity2,
  DNSLowActivity,
  PortscannerExtremeActivity1,
  PortscannerExtremeActivity2,
  PortscannerExtremeActivity3,
  PortscannerExtremeActivity4,
  PortscannerExtremeActivity5,
  PortscannerExtremeActivity6,
  PortscannerHighActivity,
  PortscannerNetHighActivity1,
  PortscannerNetHighActivity2,
  PortscannerNetHighActivity3,
  PortscannerNetHighActivity4,
  ScraperActivity1,
  ScraperActivity2,
  ScraperActivity3,
  ScraperActivity4,
  ScraperActivity5,
  ScraperActivity6,
  ScraperActivity7,
]

export const ActivityResponse: ActivityInstancesResponse = {
  data: [PortscannerHighActivity],
  total: 1,
  count: 1,
  offset: 0,
}
