/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { DecodedSession } from '@/codecs'

// ---------------------------------------------------------------------------

export enum TypeKeys {
  SESSION_SET = 'SESSION_SET',
}

export type ActionTypes = SetSession

// ---------------------------------------------------------------------------

export type SetSession = {
  type: TypeKeys.SESSION_SET
  payload: DecodedSession
}

export const SESSION_SET = (payload: DecodedSession): SetSession => {
  return {
    type: TypeKeys.SESSION_SET,
    payload: payload,
  }
}
