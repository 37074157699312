/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  ATTACKS_FETCH = 'ATTACKS_FETCH',
  ATTACK_FETCH = 'ATTACK_FETCH',
  ATTACK_PAGINATION_UPDATE = 'ATTACK_PAGINATION_UPDATE',
  ATTACK_STATS_FETCH = 'ATTACK_STATS_FETCH',
  ATTACK_STATS_UPDATE = 'ATTACK_STATS_UPDATE',
  ATTACK_STAT_FETCH = 'ATTACK_STAT_FETCH',
  ATTACK_SUMMARY_FETCH = 'ATTACK_SUMMARY_FETCH',
  ATTACK_TARGETS_FETCH = 'ATTACK_TARGETS_FETCH',
  ATTACK_TARGETS_PATCH = 'ATTACK_TARGETS_PATCH',
  ATTACK_TARGETS_UPDATE = 'ATTACK_TARGETS_UPDATE',
  ATTACK_TARGET_PATCH = 'ATTACK_TARGET_PATCH',

  // v2
  // -------------------------------------------------------------------------

  ACTIONS_BY_RUNBOOK_FETCH = 'ACTIONS_BY_RUNBOOK_FETCH',
  ACTIONS_BY_RUNBOOK_STORE_UPDATE = 'ACTIONS_BY_RUNBOOK_STORE_UPDATE',
  ACTION_BY_RUNBOOK_STORE_UPDATE = 'ACTION_BY_RUNBOOK_STORE_UPDATE',
  ACTION_DELETE = 'ACTION_DELETE',
  ACTION_PATCH = 'ACTION_PATCH',
  ATTACK_ACTIONS_FETCH = 'ATTACK_ACTIONS_FETCH',
  ATTACK_ACTIONS_STORE_UPDATE = 'ATTACK_ACTIONS_STORE_UPDATE',
  ATTACK_ACTION_STORE_UPDATE = 'ATTACK_ACTION_STORE_UPDATE',
  ATTACK_ACTION_TOTALS_FETCH = 'ATTACK_ACTION_TOTALS_FETCH',
  ATTACK_ACTION_TOTALS_STORE_UPDATE = 'ATTACK_ACTION_TOTALS_STORE_UPDATE',
  IMPLANTS_FETCH = 'IMPLANTS_FETCH',
  IMPLANTS_STORE_UPDATE = 'IMPLANTS_STORE_UPDATE',
  IMPLANT_CALLBACKS_FETCH = 'IMPLANT_CALLBACKS_FETCH',
  IMPLANT_CALLBACKS_STORE_UPDATE = 'IMPLANT_CALLBACKS_STORE_UPDATE',
  IMPLANT_FETCH = 'IMPLANT_FETCH',
  IMPLANT_INTERFACES_FETCH = 'IMPLANT_INTERFACES_FETCH',
  IMPLANT_INTERFACES_STORE_UPDATE = 'IMPLANT_INTERFACES_STORE_UPDATE',
  IMPLANT_STATS_FETCH = 'IMPLANT_STATS_FETCH',
  IMPLANT_STATS_STORE_UPDATE = 'IMPLANT_STATS_STORE_UPDATE',
  IMPLANT_STORE_UPDATE = 'IMPLANT_STORE_UPDATE',
  IMPLANT_TOTALS_FETCH = 'IMPLANT_TOTALS_FETCH',
  IMPLANT_TOTALS_STORE_UPDATE = 'IMPLANT_TOTALS_STORE_UPDATE',
  REDIRECTORS_FETCH = 'REDIRECTORS_FETCH',
  REDIRECTORS_STORE_UPDATE = 'REDIRECTORS_STORE_UPDATE',
  REDIRECTOR_FETCH = 'REDIRECTOR_FETCH',
  REDIRECTOR_STORE_UPDATE = 'REDIRECTOR_STORE_UPDATE',
  REDIRECTOR_TOTALS_FETCH = 'REDIRECTOR_TOTALS_FETCH',
  REDIRECTOR_TOTALS_STORE_UPDATE = 'REDIRECTOR_TOTALS_STORE_UPDATE',
  RUNBOOKS_FETCH = 'RUNBOOKS_FETCH',
  RUNBOOKS_STORE_UPDATE = 'RUNBOOKS_STORE_UPDATE',
  RUNBOOK_DELETE = 'RUNBOOK_DELETE',
  RUNBOOK_DESC_FETCH = 'RUNBOOK_DESC_FETCH',
  RUNBOOK_FETCH = 'RUNBOOK_FETCH',
  RUNBOOK_PATCH = 'RUNBOOK_PATCH',
  RUNBOOK_STORE_UPDATE = 'RUNBOOK_STORE_UPDATE',
  RUNBOOK_TOTALS_FETCH = 'RUNBOOK_TOTALS_FETCH',
  RUNBOOK_TOTALS_STORE_UPDATE = 'RUNBOOK_TOTALS_STORE_UPDATE',
}

export type StateActionTypes =
  | ACTIONS_BY_RUNBOOK_STORE_UPDATE
  | ACTION_BY_RUNBOOK_STORE_UPDATE
  | ATTACK_ACTIONS_STORE_UPDATE
  | ATTACK_ACTION_STORE_UPDATE
  | ATTACK_ACTION_TOTALS_STORE_UPDATE
  | ATTACK_PAGINATION_UPDATE
  | ATTACK_STATS_UPDATE
  | ATTACK_TARGETS_UPDATE
  | IMPLANTS_STORE_UPDATE
  | IMPLANT_CALLBACKS_STORE_UPDATE
  | IMPLANT_INTERFACES_STORE_UPDATE
  | IMPLANT_STATS_STORE_UPDATE
  | IMPLANT_STORE_UPDATE
  | IMPLANT_TOTALS_STORE_UPDATE
  | REDIRECTORS_STORE_UPDATE
  | REDIRECTOR_STORE_UPDATE
  | REDIRECTOR_TOTALS_STORE_UPDATE
  | RUNBOOKS_STORE_UPDATE
  | RUNBOOK_STORE_UPDATE
  | RUNBOOK_TOTALS_STORE_UPDATE

// ---------------------------------------------------------------------------

type FetchAttacks = {
  type: TypeKeys.ATTACKS_FETCH
  meta: { deferred: Deferred }
  payload: {
    dataType: string
    id: string
  }
}

export function fetchAttacks(payload: FetchAttacks['payload'], deferred: Deferred): FetchAttacks {
  return {
    type: TypeKeys.ATTACKS_FETCH,
    meta: { deferred },
    payload,
  }
}

type FetchAttackTargets = {
  type: TypeKeys.ATTACK_TARGETS_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function fetchAttackTargets(payload: FetchAttackTargets['payload'], deferred: Deferred): FetchAttackTargets {
  return {
    type: TypeKeys.ATTACK_TARGETS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type FetchAttack = {
  type: TypeKeys.ATTACK_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    ip_id?: string
    service_id?: string
  }
}

export function ATTACK_FETCH(payload: FetchAttack['payload'], deferred: Deferred): FetchAttack {
  return {
    type: TypeKeys.ATTACK_FETCH,
    meta: { deferred },
    payload,
  }
}

export type FetchAttackSummary = {
  type: TypeKeys.ATTACK_SUMMARY_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    ip_id?: string
    service_id?: string
  }
}

export function ATTACK_SUMMARY_FETCH(payload: FetchAttackSummary['payload'], deferred: Deferred): FetchAttackSummary {
  return {
    type: TypeKeys.ATTACK_SUMMARY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type FetchAttackStat = {
  type: TypeKeys.ATTACK_STAT_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    ip_id?: string
    service_id?: string
    status?: 'fail' | 'preflight' | 'success' | 'error'
  }
}

export function fetchAttackStat(payload: FetchAttackStat['payload'], deferred: Deferred): FetchAttackStat {
  return {
    type: TypeKeys.ATTACK_STAT_FETCH,
    meta: { deferred },
    payload,
  }
}

export type FetchAttackStats = {
  type: TypeKeys.ATTACK_STATS_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    ip_id?: string
    service_id?: string
  }
}

export function fetchAttackStats(payload: FetchAttackStats['payload'], deferred: Deferred): FetchAttackStats {
  return {
    type: TypeKeys.ATTACK_STATS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ATTACK_STATS_UPDATE = {
  type: TypeKeys.ATTACK_STATS_UPDATE
  payload: any
}

export function ATTACK_STATS_UPDATE(payload: ATTACK_STATS_UPDATE['payload']): ATTACK_STATS_UPDATE {
  return {
    type: TypeKeys.ATTACK_STATS_UPDATE,
    payload,
  }
}

export type PatchAttackTargets = {
  type: TypeKeys.ATTACK_TARGETS_PATCH
  meta: { deferred: Deferred }
  payload: {
    targets: string[]
    fields: {
      config: string
      eula_hash: string
      eula_timedelta: number
      status: string
    }
  }
}

export function patchAttackTargets(payload: PatchAttackTargets['payload'], deferred: Deferred): PatchAttackTargets {
  return {
    type: TypeKeys.ATTACK_TARGETS_PATCH,
    meta: { deferred },
    payload,
  }
}

export type ATTACK_PAGINATION_UPDATE = {
  type: TypeKeys.ATTACK_PAGINATION_UPDATE
  payload: {
    count: number
    offset: number
    total: number
  }
}

export function updateAttackPagination(payload: ATTACK_PAGINATION_UPDATE['payload']): ATTACK_PAGINATION_UPDATE {
  return {
    type: TypeKeys.ATTACK_PAGINATION_UPDATE,
    payload,
  }
}

export type ATTACK_TARGETS_UPDATE = {
  type: TypeKeys.ATTACK_TARGETS_UPDATE
  payload: {}
}

export function ATTACK_TARGETS_UPDATE(payload: ATTACK_TARGETS_UPDATE['payload']): ATTACK_TARGETS_UPDATE {
  return {
    type: TypeKeys.ATTACK_TARGETS_UPDATE,
    payload,
  }
}

// v2
// ---------------------------------------------------------------------------

export type IMPLANTS_STORE_UPDATE = {
  type: TypeKeys.IMPLANTS_STORE_UPDATE
  payload: Codecs.ImplantsResponse
}

export function IMPLANTS_STORE_UPDATE(payload: IMPLANTS_STORE_UPDATE['payload']): IMPLANTS_STORE_UPDATE {
  return {
    type: TypeKeys.IMPLANTS_STORE_UPDATE,
    payload,
  }
}

export type IMPLANTS_FETCH = {
  type: TypeKeys.IMPLANTS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function IMPLANTS_FETCH(payload: IMPLANTS_FETCH['payload'], deferred: Deferred): IMPLANTS_FETCH {
  return {
    type: TypeKeys.IMPLANTS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type IMPLANT_TOTALS_FETCH = {
  type: TypeKeys.IMPLANT_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function IMPLANT_TOTALS_FETCH(payload: IMPLANTS_FETCH['payload'], deferred: Deferred): IMPLANT_TOTALS_FETCH {
  return {
    type: TypeKeys.IMPLANT_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type IMPLANT_TOTALS_STORE_UPDATE = {
  type: TypeKeys.IMPLANT_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function IMPLANT_TOTALS_STORE_UPDATE(
  payload: IMPLANT_TOTALS_STORE_UPDATE['payload'],
): IMPLANT_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.IMPLANT_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type IMPLANT_STATS_STORE_UPDATE = {
  type: TypeKeys.IMPLANT_STATS_STORE_UPDATE
  payload: Codecs.ImplantStatsResponse
}

export function IMPLANT_STATS_STORE_UPDATE(payload: IMPLANT_STATS_STORE_UPDATE['payload']): IMPLANT_STATS_STORE_UPDATE {
  return {
    type: TypeKeys.IMPLANT_STATS_STORE_UPDATE,
    payload,
  }
}

export type IMPLANT_STATS_FETCH = {
  type: TypeKeys.IMPLANT_STATS_FETCH
  meta: { deferred: Deferred }
}

export function IMPLANT_STATS_FETCH(deferred: Deferred): IMPLANT_STATS_FETCH {
  return {
    type: TypeKeys.IMPLANT_STATS_FETCH,
    meta: { deferred },
  }
}

export type REDIRECTORS_STORE_UPDATE = {
  type: TypeKeys.REDIRECTORS_STORE_UPDATE
  payload: Codecs.RedirectorsResponse
}

export function REDIRECTORS_STORE_UPDATE(payload: REDIRECTORS_STORE_UPDATE['payload']): REDIRECTORS_STORE_UPDATE {
  return {
    type: TypeKeys.REDIRECTORS_STORE_UPDATE,
    payload,
  }
}

export type REDIRECTORS_FETCH = {
  type: TypeKeys.REDIRECTORS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function REDIRECTORS_FETCH(payload: REDIRECTORS_FETCH['payload'], deferred: Deferred): REDIRECTORS_FETCH {
  return {
    type: TypeKeys.REDIRECTORS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type REDIRECTOR_FETCH = {
  type: TypeKeys.REDIRECTOR_FETCH
  meta: { deferred: Deferred }
  payload: { id: string }
}

export function REDIRECTOR_FETCH(payload: REDIRECTOR_FETCH['payload'], deferred: Deferred): REDIRECTOR_FETCH {
  return {
    type: TypeKeys.REDIRECTOR_FETCH,
    meta: { deferred },
    payload,
  }
}

export type REDIRECTOR_TOTALS_FETCH = {
  type: TypeKeys.REDIRECTOR_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function REDIRECTOR_TOTALS_FETCH(
  payload: REDIRECTORS_FETCH['payload'],
  deferred: Deferred,
): REDIRECTOR_TOTALS_FETCH {
  return {
    type: TypeKeys.REDIRECTOR_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type REDIRECTOR_TOTALS_STORE_UPDATE = {
  type: TypeKeys.REDIRECTOR_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function REDIRECTOR_TOTALS_STORE_UPDATE(
  payload: REDIRECTOR_TOTALS_STORE_UPDATE['payload'],
): REDIRECTOR_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.REDIRECTOR_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type IMPLANT_STORE_UPDATE = {
  type: TypeKeys.IMPLANT_STORE_UPDATE
  payload: Codecs.ImplantResponse
}

export function IMPLANT_STORE_UPDATE(payload: IMPLANT_STORE_UPDATE['payload']): IMPLANT_STORE_UPDATE {
  return {
    type: TypeKeys.IMPLANT_STORE_UPDATE,
    payload,
  }
}

export type REDIRECTOR_STORE_UPDATE = {
  type: TypeKeys.REDIRECTOR_STORE_UPDATE
  payload: Codecs.Redirector
}

export function REDIRECTOR_STORE_UPDATE(payload: REDIRECTOR_STORE_UPDATE['payload']): REDIRECTOR_STORE_UPDATE {
  return {
    type: TypeKeys.REDIRECTOR_STORE_UPDATE,
    payload,
  }
}

export type IMPLANT_FETCH = {
  type: TypeKeys.IMPLANT_FETCH
  meta: { deferred: Deferred }
  payload: { id: string }
}

export function IMPLANT_FETCH(payload: IMPLANT_FETCH['payload'], deferred: Deferred): IMPLANT_FETCH {
  return {
    type: TypeKeys.IMPLANT_FETCH,
    meta: { deferred },
    payload,
  }
}

export type IMPLANT_CALLBACKS_STORE_UPDATE = {
  type: TypeKeys.IMPLANT_CALLBACKS_STORE_UPDATE
  payload: Codecs.ImplantCallbacksResponse
}

export function IMPLANT_CALLBACKS_STORE_UPDATE(
  payload: IMPLANT_CALLBACKS_STORE_UPDATE['payload'],
): IMPLANT_CALLBACKS_STORE_UPDATE {
  return {
    type: TypeKeys.IMPLANT_CALLBACKS_STORE_UPDATE,
    payload,
  }
}

export type IMPLANT_CALLBACKS_FETCH = {
  type: TypeKeys.IMPLANT_CALLBACKS_FETCH
  meta: { deferred: Deferred }
  payload: { id: string; offset: number; limit: number; sort: string | string[] }
}

export function IMPLANT_CALLBACKS_FETCH(
  payload: IMPLANT_CALLBACKS_FETCH['payload'],
  deferred: Deferred,
): IMPLANT_CALLBACKS_FETCH {
  return {
    type: TypeKeys.IMPLANT_CALLBACKS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type IMPLANT_INTERFACES_STORE_UPDATE = {
  type: TypeKeys.IMPLANT_INTERFACES_STORE_UPDATE
  payload: Codecs.ImplantInterfacesResponse
}

export function IMPLANT_INTERFACES_STORE_UPDATE(
  payload: IMPLANT_INTERFACES_STORE_UPDATE['payload'],
): IMPLANT_INTERFACES_STORE_UPDATE {
  return {
    type: TypeKeys.IMPLANT_INTERFACES_STORE_UPDATE,
    payload,
  }
}

export type IMPLANT_INTERFACES_FETCH = {
  type: TypeKeys.IMPLANT_INTERFACES_FETCH
  meta: { deferred: Deferred }
  payload: { id: string; offset: number; limit: number; sort: string | string[] }
}

export function IMPLANT_INTERFACES_FETCH(
  payload: IMPLANT_INTERFACES_FETCH['payload'],
  deferred: Deferred,
): IMPLANT_INTERFACES_FETCH {
  return {
    type: TypeKeys.IMPLANT_INTERFACES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RUNBOOKS_STORE_UPDATE = {
  type: TypeKeys.RUNBOOKS_STORE_UPDATE
  payload: Codecs.RunbooksResponse
}

export function RUNBOOKS_STORE_UPDATE(payload: RUNBOOKS_STORE_UPDATE['payload']): RUNBOOKS_STORE_UPDATE {
  return {
    type: TypeKeys.RUNBOOKS_STORE_UPDATE,
    payload,
  }
}

export type RUNBOOKS_FETCH = {
  type: TypeKeys.RUNBOOKS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function RUNBOOKS_FETCH(payload: RUNBOOKS_FETCH['payload'], deferred: Deferred): RUNBOOKS_FETCH {
  return {
    type: TypeKeys.RUNBOOKS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RUNBOOK_FETCH = {
  type: TypeKeys.RUNBOOK_FETCH
  meta: { deferred: Deferred }
  payload: { id: string }
}

export function RUNBOOK_FETCH(payload: RUNBOOK_FETCH['payload'], deferred: Deferred): RUNBOOK_FETCH {
  return {
    type: TypeKeys.RUNBOOK_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RUNBOOK_TOTALS_FETCH = {
  type: TypeKeys.RUNBOOK_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function RUNBOOK_TOTALS_FETCH(payload: RUNBOOKS_FETCH['payload'], deferred: Deferred): RUNBOOK_TOTALS_FETCH {
  return {
    type: TypeKeys.RUNBOOK_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RUNBOOK_TOTALS_STORE_UPDATE = {
  type: TypeKeys.RUNBOOK_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function RUNBOOK_TOTALS_STORE_UPDATE(
  payload: RUNBOOK_TOTALS_STORE_UPDATE['payload'],
): RUNBOOK_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.RUNBOOK_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type RUNBOOK_DESC_FETCH = {
  type: TypeKeys.RUNBOOK_DESC_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function RUNBOOK_DESC_FETCH(payload: RUNBOOK_DESC_FETCH['payload'], deferred: Deferred): RUNBOOK_DESC_FETCH {
  return {
    type: TypeKeys.RUNBOOK_DESC_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ATTACK_ACTIONS_STORE_UPDATE = {
  type: TypeKeys.ATTACK_ACTIONS_STORE_UPDATE
  payload: Codecs.AttackActionResponse
}

export function ATTACK_ACTIONS_STORE_UPDATE(
  payload: ATTACK_ACTIONS_STORE_UPDATE['payload'],
): ATTACK_ACTIONS_STORE_UPDATE {
  return {
    type: TypeKeys.ATTACK_ACTIONS_STORE_UPDATE,
    payload,
  }
}

export type ATTACK_ACTIONS_FETCH = {
  type: TypeKeys.ATTACK_ACTIONS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function ATTACK_ACTIONS_FETCH(
  payload: ATTACK_ACTIONS_FETCH['payload'],
  deferred: Deferred,
): ATTACK_ACTIONS_FETCH {
  return {
    type: TypeKeys.ATTACK_ACTIONS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ATTACK_ACTION_TOTALS_FETCH = {
  type: TypeKeys.ATTACK_ACTION_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function ATTACK_ACTION_TOTALS_FETCH(
  payload: ATTACK_ACTIONS_FETCH['payload'],
  deferred: Deferred,
): ATTACK_ACTION_TOTALS_FETCH {
  return {
    type: TypeKeys.ATTACK_ACTION_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ATTACK_ACTION_TOTALS_STORE_UPDATE = {
  type: TypeKeys.ATTACK_ACTION_TOTALS_STORE_UPDATE
  payload: Codecs.EntityTotals
}

export function ATTACK_ACTION_TOTALS_STORE_UPDATE(
  payload: ATTACK_ACTION_TOTALS_STORE_UPDATE['payload'],
): ATTACK_ACTION_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.ATTACK_ACTION_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type ACTIONS_BY_RUNBOOK_FETCH = {
  type: TypeKeys.ACTIONS_BY_RUNBOOK_FETCH
  meta: { deferred: Deferred }
  payload: {
    queryString: string
    runbookId: string
  }
}

export function ACTIONS_BY_RUNBOOK_FETCH(
  payload: ACTIONS_BY_RUNBOOK_FETCH['payload'],
  deferred: Deferred,
): ACTIONS_BY_RUNBOOK_FETCH {
  return {
    type: TypeKeys.ACTIONS_BY_RUNBOOK_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ACTIONS_BY_RUNBOOK_STORE_UPDATE = {
  type: TypeKeys.ACTIONS_BY_RUNBOOK_STORE_UPDATE
  payload: {
    response: Codecs.AttackActionResponse
    runbookId: string
  }
}

export function ACTIONS_BY_RUNBOOK_STORE_UPDATE(
  payload: ACTIONS_BY_RUNBOOK_STORE_UPDATE['payload'],
): ACTIONS_BY_RUNBOOK_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIONS_BY_RUNBOOK_STORE_UPDATE,
    payload,
  }
}

export type ACTION_PATCH = {
  type: TypeKeys.ACTION_PATCH
  meta: { deferred: Deferred }
  payload: { id: string } & Partial<{
    deleted: boolean
    randori_notes: string
  }>
}

export function ACTION_PATCH(payload: ACTION_PATCH['payload'], deferred: Deferred): ACTION_PATCH {
  return {
    type: TypeKeys.ACTION_PATCH,
    meta: { deferred },
    payload,
  }
}

export type RUNBOOK_PATCH = {
  type: TypeKeys.RUNBOOK_PATCH
  meta: { deferred: Deferred }
  payload: { id: string } & Partial<{
    deleted: boolean
    instance_label: string
    randori_notes: string
  }>
}

export function RUNBOOK_PATCH(payload: RUNBOOK_PATCH['payload'], deferred: Deferred): RUNBOOK_PATCH {
  return {
    type: TypeKeys.RUNBOOK_PATCH,
    meta: { deferred },
    payload,
  }
}

export type RUNBOOK_STORE_UPDATE = {
  type: TypeKeys.RUNBOOK_STORE_UPDATE
  payload: Partial<Codecs.Runbook> & { id: string }
}

export function RUNBOOK_STORE_UPDATE(payload: RUNBOOK_STORE_UPDATE['payload']): RUNBOOK_STORE_UPDATE {
  return {
    type: TypeKeys.RUNBOOK_STORE_UPDATE,
    payload,
  }
}

export type ATTACK_ACTION_STORE_UPDATE = {
  type: TypeKeys.ATTACK_ACTION_STORE_UPDATE
  payload: Partial<Codecs.AttackAction> & { id: string }
}

export function ATTACK_ACTION_STORE_UPDATE(payload: ATTACK_ACTION_STORE_UPDATE['payload']): ATTACK_ACTION_STORE_UPDATE {
  return {
    type: TypeKeys.ATTACK_ACTION_STORE_UPDATE,
    payload,
  }
}

export type ACTION_BY_RUNBOOK_STORE_UPDATE = {
  type: TypeKeys.ACTION_BY_RUNBOOK_STORE_UPDATE
  payload: Partial<Codecs.AttackAction> & { id: string }
}

export function ACTION_BY_RUNBOOK_STORE_UPDATE(
  payload: ACTION_BY_RUNBOOK_STORE_UPDATE['payload'],
): ACTION_BY_RUNBOOK_STORE_UPDATE {
  return {
    type: TypeKeys.ACTION_BY_RUNBOOK_STORE_UPDATE,
    payload,
  }
}

export type ACTION_DELETE = {
  type: TypeKeys.ACTION_DELETE
  meta: { deferred: Deferred }
  payload: { id: string }
}

export function ACTION_DELETE(payload: ACTION_DELETE['payload'], deferred: Deferred): ACTION_DELETE {
  return {
    type: TypeKeys.ACTION_DELETE,
    meta: { deferred },
    payload,
  }
}

export type RUNBOOK_DELETE = {
  type: TypeKeys.RUNBOOK_DELETE
  meta: { deferred: Deferred }
  payload: { id: string }
}

export function RUNBOOK_DELETE(payload: RUNBOOK_DELETE['payload'], deferred: Deferred): RUNBOOK_DELETE {
  return {
    type: TypeKeys.RUNBOOK_DELETE,
    meta: { deferred },
    payload,
  }
}
