/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

export enum TechCategoryValuesEnum {
  AppServers = 'App Servers',
  Applications = 'Applications',
  Databases = 'Databases',
  Firewalls = 'Firewalls',
  IoT = 'IoT',
  LoadBalancers = 'Load Balancers',
  OperatingSystems = 'Operating Systems',
  StorageDevices = 'Storage Devices',
  VPNs = 'VPNs',
  WebServers = 'Web Servers',
  NetworkEquipment = 'Network Equipment',
  NetworkServices = 'Network Services',
  Plugins = 'Plugins',
}

export const TechCategoryValues = t.keyof({
  [TechCategoryValuesEnum.AppServers]: null,
  [TechCategoryValuesEnum.Applications]: null,
  [TechCategoryValuesEnum.Databases]: null,
  [TechCategoryValuesEnum.Firewalls]: null,
  [TechCategoryValuesEnum.IoT]: null,
  [TechCategoryValuesEnum.LoadBalancers]: null,
  [TechCategoryValuesEnum.OperatingSystems]: null,
  [TechCategoryValuesEnum.StorageDevices]: null,
  [TechCategoryValuesEnum.VPNs]: null,
  [TechCategoryValuesEnum.WebServers]: null,
  [TechCategoryValuesEnum.NetworkEquipment]: null,
  [TechCategoryValuesEnum.NetworkServices]: null,
  [TechCategoryValuesEnum.Plugins]: null,
})

export const TechCategoryList = t.array(TechCategoryValues)

type _TechCategoryValues = t.TypeOf<typeof TechCategoryValues>
export type TechCategoryValues = _TechCategoryValues

type _TechCategoryList = t.TypeOf<typeof TechCategoryList>
export type TechCategoryList = _TechCategoryList
