/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'
import { Helmet } from 'react-helmet'

// ---------------------------------------------------------------------------

type LoginCheckSSOProps = Record<string, unknown>

const LoginCheckSSOView: React.FunctionComponent<LoginCheckSSOProps> = () => {
  return (
    <Helmet>
      <script type="text/javascript">parent.postMessage(location.href, location.origin)</script>
    </Helmet>
  )
}

const LoginCheckSSO = LoginCheckSSOView

export default LoginCheckSSO
