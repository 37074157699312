/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { Watcher } from 'klaro/dist/klaro-no-css'
import { isEqual } from 'lodash/fp'
import * as React from 'react'

// ---------------------------------------------------------------------------
import * as Klaro from '@/utilities/klaro'

// ---------------------------------------------------------------------------

// @see: https://heyklaro.com/docs/api/js_api

export const useKlaroWatch = (
  serviceName: string,
  reload = false,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const initState = Klaro.manager().getConsent(serviceName)
  const [isAllowed, setIsAllowed] = React.useState(initState)

  React.useEffect(() => {
    const watcher: Watcher = {
      update: (obj, name) => {
        const nextConsent = obj.consents[serviceName] ?? false
        const shouldUpdate = !isEqual(nextConsent, isAllowed)

        if (name === 'saveConsents') {
          setIsAllowed(nextConsent)

          if (shouldUpdate) {
            Klaro.updateServiceStorage(serviceName, nextConsent)
          }

          if (reload && shouldUpdate) {
            window.location.reload()
          }
        }
      },
    }

    Klaro.watch(watcher)

    return () => {
      Klaro.unwatch(watcher)
    }
  })

  return [isAllowed, setIsAllowed]
}
