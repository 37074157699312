/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PerspectiveV2 } from '@/codecs/perspective.codecs'

import { EntityName, initialState } from './perspective.constants'
import { normalizePerspectives } from './perspective.schema'

// ---------------------------------------------------------------------------

export const perspectiveSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    updatePerspective: (state, action: PayloadAction<PerspectiveV2>) => {
      const id = action.payload.id

      state.entities.perspective[id] = action.payload
    },

    updatePerspectives: (state, action: PayloadAction<ReturnType<typeof normalizePerspectives>>) => {
      state.result = action.payload.result

      state.entities = {
        ...state.entities,
        ...action.payload.entities,
      }
    },
  },
})
