/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { EntityType } from '../entity-types'

export const getEntityTypeFromPluralString = (entityType: string): EntityType => {
  switch (entityType) {
    case 'activities':
      return 'activity_instance'
    case 'artifacts':
      return 'artifact'
    case 'targets':
      return 'target'
    case 'hostnames':
      return 'hostname'
    case 'ips':
      return 'ip'
    case 'networks':
      return 'network'

    default:
      throw new Error(`Unhandled entity plural: ${entityType}`)
  }
}
