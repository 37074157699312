/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { AffiliatedNetwork } from '@/codecs/affiliated-network.codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, rflaskGetSingle, rflaskPatch, throwValidate, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type GetNetworks = t.TypeOf<typeof GetNetworks>
export const GetNetworks = rflaskGetMany(AffiliatedNetwork)

export type GetNetwork = t.TypeOf<typeof GetNetwork>
export const GetNetwork = rflaskGetSingle(AffiliatedNetwork)

export type PatchNetwork = t.TypeOf<typeof PatchNetwork>
export const PatchNetwork = rflaskPatch(AffiliatedNetwork)

export type PostNetworks = t.TypeOf<typeof PostNetworks>
export const PostNetworks = t.type({
  ids: t.array(UUID),
  warnings: t.array(t.string),
})

export type PostNetworksPayload = t.TypeOf<typeof PostNetworksPayload>
const { id: _id, perspective: _perspective, ...postProps } = AffiliatedNetwork.props

export const PostNetworksPayload = t.type({
  data: t.array(
    t.type({
      ...postProps,
      perspective_id: UUID,
    }),
  ),
})

// @TODO: Add PATCH payload when that use-case arises
export type PatchNetworkPayload = t.TypeOf<typeof PatchNetworkPayload>
export const PatchNetworkPayload = t.type({})

export type AffiliateApi = {
  getNetwork: (id: UUID) => Promise<GetNetwork>
  getNetworks: (query: QueryString | '') => Promise<GetNetworks>
  patchNetwork: (id: UUID, payload: PostNetworksPayload) => Promise<PatchNetwork>
  postNetworks: (payload: PostNetworksPayload) => Promise<PostNetworks>
}

export function affiliateApiFactory(http: HttpClient): AffiliateApi {
  const baseUrl = '/aggregator/api/v2/affiliate/internal/networks'

  return {
    getNetwork(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetNetwork, 'GetNetwork'))
    },

    getNetworks(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetNetworks, 'GetNetworks'))
    },

    patchNetwork(id, payload) {
      return http.patch(`${baseUrl}/${id}`, payload).then(throwValidate(PatchNetwork, 'PatchNetwork'))
    },

    postNetworks(payload) {
      return http.post(`${baseUrl}`, payload).then(throwValidate(PostNetworks, 'PostNetworks'))
    },
  }
}
