/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNil, prop } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'
import { TTBoundaries } from '@/store/selectors/preferences/preferences.selectors.utils'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { isNotNil } from '@/utilities/is-not'
import { keys } from '@/utilities/keys'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

// @see: https://docs.google.com/document/d/1H91DKTEiDep_qcuAsYyyOdUncsli1PBVcLowiCkf0hc

enum ApplicabilityEnum {
  low = 'The service is deployed in few circumstances or is unique to this detected instance.',
  medium = 'The service is likely to be found only in a particular market segment or industry.',
  high = 'The service is in common use and has few competitive services. A market may exist for vulnerabilities for this service due to its popularity alone.',
}

enum EnumerabilityEnum {
  low = 'No version or configuration information was discovered for this service. Without probes, the applicability of vulnerabilities is uncertain.',
  medium = 'Major or major and minor version information was discovered for this service. Associations with vulnerabilities may have low accuracy.',
  high = 'Specific version or configuration information was discovered for this service. This is useful for determining the applicability of vulnerabilities.',
}

enum PostExploitationEnum {
  low = 'This service runs in an esoteric environment, such as a proprietary embedded systems, for which little or no tooling exists.',
  medium = 'The post exploitation environment is known, and tooling varies.  Attackers may expect typical defensive capabilities or endpoint protections.',
  high = 'The post exploitation environment is well known. Tooling is typically available and defenses are usually unlikely to be present on these systems.',
}

enum PublicWeaknessEnum {
  low = 'Known weaknesses, including vulnerabilities, are either nonexistent or of low impact.',
  medium = 'Weaknesses, including potentially exploitable vulnerabilities may exist for this version. Public exploits are not widely available.',
  high = 'Exploitable vulnerabilities exist for this version. A reliable exploit may be public or available from private parties.',
}

enum PrivateWeaknessEnum {
  low = 'Known weaknesses, including vulnerabilities, are either nonexistent or of low impact.',
  medium = 'Weaknesses, including potentially exploitable vulnerabilities may exist for this version. Public exploits are not widely available.',
  high = "High exploitability in the absence of associated CVEs can indicate Randori's knowledge of privately held, non-public, capabilities or vulnerabilities not tracked or reported by the NVD.",
}

enum ResearchPotentialEnum {
  low = 'This software is unavailable, is difficult to obtain, or is prohibitively expensive for most researchers. Public research is limited.',
  medium = 'This software is available and some amount of prior research may be available. A history of impactful weakness may exist.',
  high = 'This software is widely available or easy to obtain. Proof-of-concept exploits or significant research is available.',
}

enum CriticalityEnum {
  low = 'The service is not intrinsically associated with an integrity boundary.',
  medium = 'The service might be a component of an integrity boundary or could be configured to protect other services, but this function may not be detectable.',
  high = 'The service is intrinsically associated with an integrity boundary. It is intended to protect or separate services or data.',
}

export const TemptationFactorDescriptions = {
  [Codecs.TemptationFactor.applicability]: ApplicabilityEnum,
  [Codecs.TemptationFactor.criticality]: CriticalityEnum,
  [Codecs.TemptationFactor.enumerability]: EnumerabilityEnum,
  [Codecs.TemptationFactor.post_exploit]: PostExploitationEnum,
  [Codecs.TemptationFactor.private_weakness]: PrivateWeaknessEnum,
  [Codecs.TemptationFactor.public_weakness]: PublicWeaknessEnum,
  [Codecs.TemptationFactor.research]: ResearchPotentialEnum,
}

export type FixedValues = {
  critical: boolean
  high: boolean
  in_review: boolean
  low: boolean
  medium: boolean
  no_targets: boolean
}

export enum TemptationLevel {
  critical = 'critical',
  high = 'high',
  in_review = 'in_review',
  low = 'low',
  medium = 'medium',
  no_targets = 'no_targets',
}

export enum TemptationColor {
  crimson = 'crimson',
  grey = 'grey',
  orange = 'orange',
  red = 'red',
}

export function getTemptation(temptation: number | null, targetTemptationBoundaries: TTBoundaries) {
  if (isNil(temptation)) {
    return TemptationLevel.in_review
  }

  if (temptation >= (targetTemptationBoundaries.critical.lowerBound as number)) {
    return TemptationLevel.critical
  } else if (temptation >= (targetTemptationBoundaries.high.lowerBound as number)) {
    return TemptationLevel.high
  } else if (temptation >= (targetTemptationBoundaries.medium.lowerBound as number)) {
    return TemptationLevel.medium
  } else {
    return TemptationLevel.low
  }
}

export function getTemptationStr(
  temptation: number | null,
  targetTemptationBoundaries: TTBoundaries,
  onlyInReviewTargets = true,
) {
  if (isNil(temptation)) {
    if (onlyInReviewTargets) {
      return 'In Review'
    } else {
      return 'No Targets'
    }
  }

  if (temptation >= (targetTemptationBoundaries.critical.lowerBound as number)) {
    return TemptationLevel.critical
  } else if (temptation >= (targetTemptationBoundaries.high.lowerBound as number)) {
    return TemptationLevel.high
  } else if (temptation >= (targetTemptationBoundaries.medium.lowerBound as number)) {
    return TemptationLevel.medium
  } else {
    return TemptationLevel.low
  }
}

export function getTemptationLabel(label?: string) {
  switch (label) {
    case TemptationLevel.in_review:
      return 'in review'
    case TemptationLevel.no_targets:
      return 'no targets'

    default:
      return label
  }
}

export function getTemptationModifier(
  temptation: number | null,
  targetTemptationBoundaries: TTBoundaries,
): keyof typeof TemptationColor {
  if (isNil(temptation)) {
    return TemptationColor.grey
  }

  if (temptation >= (targetTemptationBoundaries.critical.lowerBound as number)) {
    return TemptationColor.crimson
  } else if (temptation >= (targetTemptationBoundaries.high.lowerBound as number)) {
    return TemptationColor.red
  } else if (temptation >= (targetTemptationBoundaries.medium.lowerBound as number)) {
    return TemptationColor.orange
  } else {
    return TemptationColor.grey
  }
}

export function getTemptationProps(temptation: number, targetTemptationBoundaries: TTBoundaries) {
  return {
    modifier: getTemptationModifier(temptation, targetTemptationBoundaries),
    children: getTemptation(temptation, targetTemptationBoundaries),
    solid: temptation >= (targetTemptationBoundaries.critical.lowerBound as number),
  }
}

export const createTT = (
  selected: FixedValues,
  targetTemptationBoundaries: TTBoundaries,
  entityTypes: EntityUtils.EntityType[],
) => {
  const low = {
    label: TemptationLevel.low,
    condition: 'AND' as const,
    rules: [
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal' as const,
        value: targetTemptationBoundaries.low.lowerBound,
      },
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal' as const,
        value: targetTemptationBoundaries.low.upperBound,
      },
    ],
  }

  const medium = {
    label: TemptationLevel.medium,
    condition: 'AND' as const,
    rules: [
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal' as const,
        value: targetTemptationBoundaries.medium.lowerBound,
      },
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal' as const,
        value: targetTemptationBoundaries.medium.upperBound,
      },
    ],
  }

  const high = {
    label: TemptationLevel.high,
    condition: 'AND' as const,
    rules: [
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal' as const,
        value: targetTemptationBoundaries.high.lowerBound,
      },
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal' as const,
        value: targetTemptationBoundaries.high.upperBound,
      },
    ],
  }

  const critical = {
    label: TemptationLevel.critical,
    condition: 'AND' as const,
    rules: [
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal' as const,
        value: targetTemptationBoundaries.critical.lowerBound,
      },
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal' as const,
        value: targetTemptationBoundaries.critical.upperBound,
      },
    ],
  }

  const in_review = {
    label: TemptationLevel.in_review,
    condition: 'AND' as const,
    rules: [
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'is_null' as const,
        value: null as unknown,
      },
    ] as CrudQueryUtils.CrudRule[],
  }

  if (
    !entityTypes.includes('detection_target') &&
    !entityTypes.includes('globalService') &&
    !entityTypes.includes('service') &&
    !entityTypes.includes('social') &&
    !entityTypes.includes('target')
  ) {
    in_review.rules.push({
      id: 'table.only_in_review_targets',
      field: 'table.only_in_review_targets',
      type: 'boolean',
      input: 'radio',
      operator: 'equal' as const,
      value: true,
    })
  }

  const no_targets = {
    label: TemptationLevel.no_targets,
    condition: 'AND' as const,
    rules: [
      {
        id: 'table.target_temptation',
        field: 'table.target_temptation',
        type: 'integer',
        input: 'number',
        operator: 'is_null' as const,
        value: null,
      },
    ] as CrudQueryUtils.CrudRule[],
  }

  if (!entityTypes.includes('globalService')) {
    no_targets.rules.push({
      id: 'table.only_in_review_targets',
      field: 'table.only_in_review_targets',
      type: 'boolean',
      input: 'radio',
      operator: 'equal' as const,
      value: false,
    })
  }

  const lookup = {
    critical,
    high,
    in_review,
    low,
    medium,
    no_targets,
  }

  const lookupKeys = keys(lookup)

  const nextTT = lookupKeys.reduce((acc, lookupKey) => {
    // If our UI filters set a value to `true`, then we should include it in
    // the filter values we will send to the backend.
    //
    // There are some shennanigans going on here. It's not clear to me yet how
    // to get these typings correct.
    //
    // @see: https://blog.mariusschulz.com/2016/03/31/string-literal-types-in-typescript
    // @see: https://github.com/Microsoft/TypeScript/issues/13254
    //
    // Ramda is no help here, as `keys()` returns `string[]`. It might be
    // worthwhile to look into an iterable object here, like a `Map`, as we
    // wouldn't need to use `Object.keys` and its ilk.

    const isSelected = prop(lookupKey, selected)
    const rule = prop(lookupKey, lookup)

    return isSelected ? acc.concat(rule) : acc
  }, [] as CrudQueryUtils.CrudRuleGroup[])

  return {
    condition: 'OR' as const,
    rules: nextTT,
    ui_id: 'target_temptation',
  }
}

/**
 * Tests if a string *might* be related to a query against a range value for
 * priority/impact/temptation/temptation factor.
 */
export const isRangeValue = (query: string) => {
  if (!isNotNil(query)) {
    return false
  }

  const isCriticalQuery = /critical/i.test(query)
  const isHighQuery = /high/i.test(query)
  const isMediumQuery = /medium/i.test(query)
  const isLowQuery = /low/i.test(query)

  return isCriticalQuery || isHighQuery || isMediumQuery || isLowQuery
}

export const createTemptationFactorFilter = (
  factor: string,
  values: Record<keyof typeof Codecs.TemptationFactorLevelRange, boolean>,
) => {
  const rules = keys(values).reduce((_rules, range) => {
    const rule: CrudQueryUtils.CrudRule = {
      field: `table.${factor}`,
      id: `table.${factor}`,
      input: 'number',
      operator: 'between',
      type: 'integer',
      ui_id: range,
      value: Codecs.TemptationFactorLevelRange[range],
    }

    if (values[range]) {
      return [..._rules, rule]
    } else {
      return _rules
    }
  }, [] as CrudQueryUtils.CrudRule[])

  const ruleGroup = {
    ui_id: factor,
    condition: 'OR' as const,
    rules,
  }

  return ruleGroup
}

export const createTemptationFactorExploitabilityFilter = (
  _factor: string,
  values: Record<keyof typeof Codecs.TemptationFactorLevelRange, boolean>,
) => {
  const rules = keys(values).reduce((_rules, range) => {
    const rule: CrudQueryUtils.CrudRule = {
      field: 'table.exploitability',
      id: 'table.exploitability',
      input: 'number',
      operator: 'between',
      type: 'integer',
      ui_id: range,
      value: Codecs.TemptationFactorLevelRange[range],
    }

    if (values[range]) {
      return [..._rules, rule]
    } else {
      return _rules
    }
  }, [] as CrudQueryUtils.CrudRule[])

  const ruleGroup = {
    ui_id: 'exploitability',
    condition: 'OR' as const,
    rules,
  }

  return ruleGroup
}
