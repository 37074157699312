/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import { DeferredAction } from 'redux-saga-try-catch'

import { UUID } from '@/utilities/codec'

import { makeFetchMany, makeFetchSingle, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './detection.constants'

// ---------------------------------------------------------------------------

export const fetchDetections = makeFetchMany(EntityName)
export const fetchDetection = makeFetchSingle(EntityName)
export const fetchTotals = makeFetchTotals(EntityName)

export const fetchDetectionsAndPolicies = createAction(
  `${EntityName}/fetch-detections-and-policies`,
  (
    payload: RouteComponentProps['location']['search'],
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchDetectionsForTarget = createAction(
  `${EntityName}/fetch-detections-for-target`,
  (
    payload: {
      limit: number
      offset: number
      targetId: UUID
    },
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchDetectionScreenshotsForTarget = createAction(
  `${EntityName}/fetch-detection-screenshots-for-target`,
  (
    payload: {
      limit: number
      offset: number
      targetId: UUID
    },
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)
