/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { ServiceP as Service } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, rflaskGetSingle, throwValidate, TripleUUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type ServiceApi = {
  getService: (id: TripleUUID) => Promise<GetService>
  getServices: (query: QueryString | '') => Promise<GetServices>
}

export function serviceApiFactory(http: HttpClient): ServiceApi {
  const baseUrl = '/recon/api/v2/service'

  return {
    getService(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetService, 'GetService'))
    },

    getServices(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetServices, 'GetServices'))
    },
  }
}

export type GetService = t.TypeOf<typeof GetService>
export const GetService = rflaskGetSingle(Service)

export type GetServices = t.TypeOf<typeof GetServices>
export const GetServices = rflaskGetMany(Service)
