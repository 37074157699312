/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { nullableP } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export const Perspective = nullableP(t.string)
export type Perspective = t.TypeOf<typeof Perspective>
