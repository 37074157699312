/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export type TargetTemptationGroupsResponse = t.TypeOf<typeof TargetTemptationGroupsResponse>
export const TargetTemptationGroupsResponse = t.type({
  total_in_ranges: t.number,
  total_other: t.number,
  tt_counts: t.array(
    t.type({
      total: t.number,
      tt_range_name: t.string,
    }),
  ),
})

export type TargetTemptationGroupsBody = {
  entity_type: string
  min_first_seen: string
  tt_ranges: Array<{
    tt_max: number
    tt_min: number
    tt_range_name: string
  }>
}
