/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { HttpClient } from '@/http/http.utils'

// ---------------------------------------------------------------------------

export type UsersApi = {
  getUser: (user: string) => Promise<any>
}

export function usersApiFactory(http: HttpClient): UsersApi {
  const baseUrl = '/api/v1/users'

  return {
    getUser(user) {
      return http.get(`${baseUrl}/${user}`)
    },
  }
}
