/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import * as React from 'react'
import { Helmet } from 'react-helmet'

// ---------------------------------------------------------------------------
import * as EmbedUtils from '../embed.utils'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:workato')

export const WorkatoEmbed: React.FC = () => {
  const [isAllowed] = EmbedUtils.useKlaroWatch('workato', true)

  if (isAllowed) {
    log('enabled')

    return (
      <Helmet>
        <script src="https://embedding.workato.com/r/embedding-client.js" type="text/javascript"></script>
      </Helmet>
    )
  } else {
    log('noop')

    return null
  }
}
