/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import debug from 'debug'
import type { Metric } from 'web-vitals'

import * as Logger from '@/utilities/logger'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:web-vitals')

export const report = (_cb: typeof cb) => {
  if (isNotNil(_cb) && _cb instanceof Function) {
    import('web-vitals')
      .then(({ onCLS, onINP, onFCP, onLCP, onTTFB }) => {
        onCLS(_cb)
        onFCP(_cb)
        onINP(_cb)
        onLCP(_cb)
        onTTFB(_cb)
      })
      .catch(Logger.error)
  }
}

export const cb = (metric: Metric) => {
  log(metric)
}
