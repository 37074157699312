/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { PerspectiveV2 } from '@/codecs/perspective.codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './perspective.constants'

// ---------------------------------------------------------------------------

export function normalizePerspectives(records: PerspectiveV2[]) {
  const Perspective = new schema.Entity(EntityName)
  const Perspectives = [Perspective]

  type NormalizedPerspectives = {
    result: UUID[]
    entities: {
      [EntityName]: Record<UUID, PerspectiveV2>
    }
  }

  const perspectives: NormalizedPerspectives = normalize(records, Perspectives)

  return perspectives
}
