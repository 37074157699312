/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const FilterData = t.record(t.string, t.unknown)
export type FilterData = t.TypeOf<typeof FilterData>
