/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ReportsReducer = (state = Schemas.Recon.NormalizeReports([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReportActions.TypeKeys.RECON_REPORTS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeReports(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          report: {
            ...state.entities.report,
            ...entities.report,
          },
        },
      }
    }

    default:
      return state
  }
}

const ReportTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const ReportTotalsReducer = (state = ReportTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReportActions.TypeKeys.RECON_REPORTS_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const ReportsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const ReportsPaginationReducer = (state = ReportsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReportActions.TypeKeys.RECON_REPORTS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
