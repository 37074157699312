/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { Response, Server } from 'miragejs'
import { v4 as uuidv4 } from 'uuid'

import * as Codecs from '@/codecs'

import { ATTACK_DISPATCH_URL, SOURCES_URL } from '../constants'

export const sourceRoutes = (server: Server): void => {
  server.get(SOURCES_URL, (schema) => {
    const data = schema.db.sources

    const pagination = {
      offset: 0,
      count: data.length,
      total: data.length,
    }

    if (isNotNil(data)) {
      return {
        sources: data,
        ...pagination,
      }
    }

    return new Response(500, {}, { errors: ['yikes'] })
  })

  server.get(`${SOURCES_URL}/:id`, (schema, request) => {
    const id = request.params.id

    try {
      const source = schema.db.sources.find(id)

      return source
    } catch {
      return new Response(404, {}, { errors: ['Resource not found'] })
    }
  })

  server.post(`${ATTACK_DISPATCH_URL}`, (schema, request) => {
    const requestBody = JSON.parse(request.requestBody) as Codecs.CreateImplantPayload
    const {
      source_name: sourceName,
      implant_type: implantType,
      os_type: osType,
      architecture,
      perspective_id: perspectiveId,
      source_note: sourceNote,
    } = requestBody

    const newImplantId = uuidv4()

    const newImplant = {
      id: newImplantId,
      sourceType: 'SOURCE_TYPE_ATTACK_IMPLANT',
      name: sourceName,
      defaultPerspectiveId: perspectiveId,
      createTime: '2024-04-10T19:58:30.867286+00:00',
      updateTime: '2024-04-10T19:58:30.867286+00:00',
      deleted: false,
      // this is only a subset of the parameters we expect
      parameters: {
        'implant.type': implantType,
        'implant.bake.status': 'BAKE_STATUS_TYPE_PENDING', // need to update this after a delay
        'implant.bake.os': osType,
        'implant.bake.architecture': architecture,
        'user.note': sourceNote,
      },
    }

    const implant = schema.db.sources.firstOrCreate(newImplant)

    // this is for the immediate response from attack-dispatch
    const implantFromAttackDispatch = {
      sourceId: newImplantId,
      sourceName,
      implantType,
      osType,
      architecture,
      perspectiveId,
      sourceNote,
    }

    return implantFromAttackDispatch
  })

  // this will be wonky too bc it will just return some success message
  // but the real update happens when perspective service eventually gets updated by attack
  server.put(`${ATTACK_DISPATCH_URL}/:id`, (schema, request) => {
    const id = request.params.id

    const requestBody = JSON.parse(request.requestBody)
    const override = requestBody.dns_override

    const implant = schema.db.sources.find(id)

    implant.parameters['override.dns.servers[0]'] = override

    return { message: 'DNS override updated' }
  })

  server.delete(`${ATTACK_DISPATCH_URL}/:id`, (schema, request) => {
    const id = request.params.id

    const implantToBeDeleted = schema.db.sources.find(id)

    if (implantToBeDeleted) {
      implantToBeDeleted.deleted = true
      return { message: 'Source marked as deleted' }
    } else {
      return new Response(404, {}, { error: 'Source not found' })
    }
  })
}
