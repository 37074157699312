/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { EntityPagination } from '@/codecs'
import { UUID } from '@/utilities/codec'

import { KeyNames } from './constants'

// ---------------------------------------------------------------------------

export type MakeState<T extends KeyNames, U extends Record<string, unknown>> = {
  entities: { [K in T]: Record<UUID, U> }
  pagination: EntityPagination
  result: UUID[]
  totals: { unfiltered: number; unaffiliated: number }
}

export const makeState = <T extends KeyNames, U extends Record<string, unknown>>(entityName: T): MakeState<T, U> => {
  return {
    entities: { [entityName]: {} } as { [K in T]: Record<UUID, U> },
    pagination: { count: 0, offset: 0, total: 0 },
    result: [],
    totals: { unfiltered: 0, unaffiliated: 0 },
  }
}
