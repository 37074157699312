/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import type { MITRE_MITIGATION_FETCH, MITRE_TACTIC_FETCH, MITRE_TECHNIQUE_FETCH } from '@/store/actions/mitre'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchMitre(io: MiddlewaresIO) {
  yield takeEvery(Store.MitreActions.TypeKeys.MITRE_MITIGATION_FETCH, Catch.deferredAction(_MITRE_MITIGATION_FETCH, io))
  yield takeEvery(Store.MitreActions.TypeKeys.MITRE_TACTIC_FETCH, Catch.deferredAction(_MITRE_TACTIC_FETCH, io))
  yield takeEvery(Store.MitreActions.TypeKeys.MITRE_TECHNIQUE_FETCH, Catch.deferredAction(_MITRE_TECHNIQUE_FETCH, io))
}

export function* _MITRE_MITIGATION_FETCH(io: MiddlewaresIO, action: MITRE_MITIGATION_FETCH) {
  const { id } = action.payload

  const mitreMitigation = yield* call(io.api.mitre.getMitreMitigation, id)

  return mitreMitigation
}

export function* _MITRE_TACTIC_FETCH(io: MiddlewaresIO, action: MITRE_TACTIC_FETCH) {
  const { id } = action.payload

  const mitreTactic = yield* call(io.api.mitre.getMitreTactic, id)

  return mitreTactic
}

export function* _MITRE_TECHNIQUE_FETCH(io: MiddlewaresIO, action: MITRE_TECHNIQUE_FETCH) {
  const { id } = action.payload

  const mitreTechnique = yield* call(io.api.mitre.getMitreTechnique, id)

  return mitreTechnique
}
