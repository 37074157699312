/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export enum TargetTempLevels {
  tt_level_low = 'tt_level_low',
  tt_level_medium = 'tt_level_medium',
  tt_level_high = 'tt_level_high',
  tt_level_critical = 'tt_level_critical',
}

export type TTBoundPreference = { preference: TargetTempLevels } & Codecs.Preference

export type TTBoundaries = {
  critical: {
    upperBound: TTBoundPreference['value']
    lowerBound: TTBoundPreference['value']
  }
  high: {
    upperBound: TTBoundPreference['value']
    lowerBound: TTBoundPreference['value']
  }
  medium: {
    upperBound: TTBoundPreference['value']
    lowerBound: TTBoundPreference['value']
  }
  low: {
    upperBound: TTBoundPreference['value']
    lowerBound: TTBoundPreference['value']
  }
}

export const __TT_PREF_FALLBACK__ = {
  tt_level_critical: {
    can_override: false,
    preference: 'tt_level_critical',
    preference_source: 'DEFAULT',
    value: 40,
  },
  tt_level_high: {
    can_override: false,
    preference: 'tt_level_high',
    preference_source: 'DEFAULT',
    value: 30,
  },
  tt_level_low: {
    can_override: false,
    preference: 'tt_level_low',
    preference_source: 'DEFAULT',
    value: 0,
  },
  tt_level_medium: {
    can_override: false,
    preference: 'tt_level_medium',
    preference_source: 'DEFAULT',
    value: 15,
  },
}

export const preferencesTemptationGetter = (prefs: TTBoundPreference[], prefKey: TargetTempLevels) => {
  const orgPref = prefs.find((pref: TTBoundPreference) => pref.preference === prefKey)

  if (isNotNil(orgPref)) {
    return orgPref
  } else {
    return __TT_PREF_FALLBACK__[prefKey]
  }
}
