/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { HostnameP as Hostname } from '../hostname.codecs'
import { IpP as Ip } from '../ip.codecs'
import { NetworkP as Network } from '../network.codecs'
import { SocialEntity } from '../social.codecs'
import { TargetP as Target } from '../target.codecs'
import { Term } from '../term.codecs'

// ---------------------------------------------------------------------------

const DetectionForActivityInstance = t.type({
  activity_instance__id: t.string,
  activity_configuration__id: t.string,
  result__entity_id: t.string,
  result__entity_type: t.literal('org_detection'),
  result__entity_embed: Target,
})

const HostnameForActivityInstance = t.type({
  activity_instance__id: t.string,
  activity_configuration__id: t.string,
  result__entity_id: t.string,
  result__entity_type: t.literal('org_hostname'),
  result__entity_embed: Hostname,
})

const IPForActivityInstance = t.type({
  activity_instance__id: t.string,
  activity_configuration__id: t.string,
  result__entity_id: t.string,
  result__entity_type: t.literal('org_ip'),
  result__entity_embed: Ip,
})

const NetworkForActivityInstance = t.type({
  activity_instance__id: t.string,
  activity_configuration__id: t.string,
  result__entity_id: t.string,
  result__entity_type: t.literal('org_network'),
  result__entity_embed: Network,
})

const SocialForActivityInstance = t.type({
  activity_instance__id: t.string,
  activity_configuration__id: t.string,
  result__entity_id: t.string,
  result__entity_type: t.literal('org_poc'),
  result__entity_embed: SocialEntity,
})

const TermForActivityInstance = t.type({
  activity_instance__id: t.string,
  activity_configuration__id: t.string,
  result__entity_id: t.string,
  result__entity_type: t.literal('org_term'),
  result__entity_embed: Term,
})

const TargetForActivityInstance = t.type({
  activity_instance__id: t.string,
  activity_configuration__id: t.string,
  result__entity_id: t.string,
  result__entity_type: t.literal('org_target'),
  result__entity_embed: Target,
})

export const EntityForActivityInstance = t.union([
  DetectionForActivityInstance,
  HostnameForActivityInstance,
  IPForActivityInstance,
  NetworkForActivityInstance,
  SocialForActivityInstance,
  TargetForActivityInstance,
  TermForActivityInstance,
])

export type EntityForActivityInstance = t.TypeOf<typeof EntityForActivityInstance>

export const EntityForActivityInstanceResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(EntityForActivityInstance),
})

export type EntityForActivityInstanceResponse = t.TypeOf<typeof EntityForActivityInstanceResponse>
