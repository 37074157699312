/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import jsonApiNormalizer from 'json-api-normalizer'
import { normalize, schema } from 'normalizr'
import { propOr } from 'ramda'

import * as Codecs from '@/codecs'
import { isPojo } from '@/utilities/is-pojo'
import * as Logger from '@/utilities/logger'

// Activity Log -- Activity Instance
// ---------------------------------------------------------------------------

export const ActivityInstance = new schema.Entity('activity_instance')
export const ActivityInstances = [ActivityInstance]

export function NormalizeActivityInstances(records: Codecs.ActivityInstance[]) {
  type _ActivityInstances = {
    activity_instance: {
      [index: string]: Codecs.ActivityInstance
    }
  }

  type NormalizedActivityInstances = {
    entities: _ActivityInstances
    result: string[]
  }

  const { result, entities } = normalize(records, ActivityInstances)

  const _activity_instance = propOr({}, 'activity_instance', entities)

  return {
    result,
    entities: {
      activity_instance: _activity_instance,
    },
  } as NormalizedActivityInstances
}

// Activity Library -- Activity Configuration
// ---------------------------------------------------------------------------

export function NormalizeActivityConfigurations(apiResponse: Codecs.ActivityConfigurationsResponse) {
  type _ActivityConfigurations = {
    result: string[]
    entities: {
      activity_configuration: Record<string, Codecs.ActivityConfiguration>
    }
  }

  const isValid = (input: any): input is _ActivityConfigurations['entities'] => {
    try {
      const _ac = input?.activity_configuration ?? undefined

      return isNotNil(_ac) && isPojo(_ac)
    } catch (err) {
      Logger.error(err)

      return false
    }
  }

  const fromJsonApi = jsonApiNormalizer(apiResponse, { camelizeKeys: false })

  if (isValid(fromJsonApi)) {
    const normal: _ActivityConfigurations = {
      result: apiResponse.data.map((el) => el.id),
      entities: fromJsonApi,
    }

    return normal
  } else {
    const normal: _ActivityConfigurations = {
      result: [],
      entities: { activity_configuration: {} },
    }

    return normal
  }
}

export function NormalizeActivityConfiguration(apiResponse: Codecs.ActivityConfigurationResponse) {
  type _ActivityConfiguration = {
    activity_configuration: Record<string, Codecs.ActivityConfiguration>
  }

  const isValid = (input: any): input is _ActivityConfiguration => {
    try {
      const _ac = input?.activity_configuration ?? undefined

      return isNotNil(_ac) && isPojo(_ac)
    } catch (err) {
      Logger.error(err)

      return false
    }
  }

  const fromJsonApi = jsonApiNormalizer(apiResponse, { camelizeKeys: false })

  if (isValid(fromJsonApi)) {
    return fromJsonApi
  } else {
    const normal: _ActivityConfiguration = { activity_configuration: {} }

    return normal
  }
}

export function NormalizeActivityConfigurationPatch(apiResponse: Codecs.ActivityConfigurationPatchResponse) {
  type _ActivityConfiguration = {
    activity_configuration: Record<string, Codecs.ActivityConfiguration>
  }

  const isValid = (input: any): input is _ActivityConfiguration => {
    try {
      const _ac = input?.activity_configuration ?? undefined

      return isNotNil(_ac) && isPojo(_ac)
    } catch (err) {
      Logger.error(err)

      return false
    }
  }

  const fromJsonApi = jsonApiNormalizer(apiResponse, { camelizeKeys: false })

  if (isValid(fromJsonApi)) {
    return fromJsonApi
  } else {
    const normal: _ActivityConfiguration = { activity_configuration: {} }

    return normal
  }
}

// Applicable Activities
// ---------------------------------------------------------------------------
export function NormalizeApplicableActivities(apiResponse: Codecs.ApplicableActivitiesResponse) {
  type _ApplicableActivities = {
    result: string[]
    entities: {
      applicable_activity: Record<string, Codecs.ApplicableActivity>
    }
  }

  const isValid = (input: any): input is _ApplicableActivities['entities'] => {
    try {
      const _applicableActivity = input?.applicable_activity ?? undefined

      return isNotNil(_applicableActivity) && isPojo(_applicableActivity)
    } catch (err) {
      Logger.error(err)

      return false
    }
  }

  const fromJsonApi = jsonApiNormalizer(apiResponse, { camelizeKeys: false })

  if (isValid(fromJsonApi)) {
    const normal: _ApplicableActivities = {
      result: apiResponse.data.map((el) => el.id),
      entities: fromJsonApi,
    }

    return normal
  } else {
    // default values in case we don't have valid json api
    const normal: _ApplicableActivities = {
      result: [],
      entities: { applicable_activity: {} },
    }

    return normal
  }
}

// Applicable Entities
// ---------------------------------------------------------------------------
export function NormalizeApplicableEntities(apiResponse: Codecs.ApplicableEntitiesResponse) {
  type _ApplicableEntities = {
    result: string[]
    entities: {
      applicable_entity: Record<string, Codecs.ApplicableEntity>
    }
  }

  const isValid = (input: any): input is _ApplicableEntities['entities'] => {
    try {
      const _applicableEntity = input?.applicable_entity ?? undefined

      return isNotNil(_applicableEntity) && isPojo(_applicableEntity)
    } catch (err) {
      Logger.error(err)

      return false
    }
  }

  const fromJsonApi = jsonApiNormalizer(apiResponse, { camelizeKeys: false })

  if (isValid(fromJsonApi)) {
    const normal: _ApplicableEntities = {
      result: apiResponse.data.map((el) => el.id),
      entities: fromJsonApi,
    }

    return normal
  } else {
    // default values in case we don't have valid json api
    const normal: _ApplicableEntities = {
      result: [],
      entities: { applicable_entity: {} },
    }

    return normal
  }
}
