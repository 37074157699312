/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { EntityType } from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export const getMandatoryFields = (kind: EntityType) => {
  switch (kind) {
    case 'detection_target':
    case 'target':
    case 'topLevelDetection':
      return ['id', 'name', 'vendor', 'version']

    case 'ip':
      return ['id', 'ip']

    case 'service':
      return ['id', 'name', 'vendor', 'version']

    case 'network':
      return ['id', 'network']

    default:
      return []
  }
}
