/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

// custom type for recipe names
// for reference: https://dev.to/ruizb/using-fp-ts-and-io-ts-types-and-implementation-1k6a
interface WorkatoRecipeNameBrand {
  readonly WorkatoRecipeName: unique symbol
}

// https://randori.atlassian.net/wiki/spaces/DEV/pages/1876721665/Workato+Marketplace+Documentation
const recipeNamePattern = /([\s\S].*--[\s\S].*--[\s\S].*)/i

const WorkatoRecipeName: t.BrandC<t.StringC, WorkatoRecipeNameBrand> = t.brand(
  t.string,
  (s: string): s is t.Branded<string, WorkatoRecipeNameBrand> => recipeNamePattern.test(s),
  'WorkatoRecipeName',
)

export type WorkatoRecipeName = t.TypeOf<typeof WorkatoRecipeName>

export const WorkatoRecipe = t.interface({
  id: t.number,
  name: WorkatoRecipeName,
  description: t.string,
  running: t.boolean,
  stopped_at: CodecUtils.nullable(t.string),
  last_run_at: CodecUtils.nullable(t.string),
  job_succeeded_count: CodecUtils.nullable(t.number),
  job_failed_count: CodecUtils.nullable(t.number),
})
export type WorkatoRecipe = t.TypeOf<typeof WorkatoRecipe>

export const WorkatoRecipes = t.interface({
  result: t.array(WorkatoRecipe),
})

export type WorkatoRecipes = t.TypeOf<typeof WorkatoRecipes>

export const WorkatoRecipePut = t.interface({
  success: t.boolean,
})

export type WorkatoRecipePut = t.TypeOf<typeof WorkatoRecipePut>

export const WorkatoConnection = t.interface({
  authorization_error: CodecUtils.nullable(t.string),
  authorization_status: CodecUtils.nullable(t.string),
  authorized_at: CodecUtils.nullable(t.string),
  created_at: CodecUtils.nullable(t.string),
  external_id: CodecUtils.nullable(t.string),
  folder_id: t.number,
  id: t.number,
  name: t.string,
  parent_id: CodecUtils.nullable(t.string),
  provider: t.string,
  updated_at: CodecUtils.nullable(t.string),
})
export type WorkatoConnection = t.TypeOf<typeof WorkatoConnection>

export const WorkatoConnections = t.interface({
  result: t.array(WorkatoConnection),
})
export type WorkatoConnections = t.TypeOf<typeof WorkatoConnections>

// TODO: clean up - maybe dead code
export const WorkatoDetail = t.interface({
  customer_id: t.string,
  user_id: t.string,
})
export type WorkatoDetail = t.TypeOf<typeof WorkatoDetail>

const WorkatoConnectionCheck = t.interface({
  id: t.number,
  name: t.string,
})
export type WorkatoConnectionCheck = t.TypeOf<typeof WorkatoConnectionCheck>

export const WorkatoConnectionsCheck = t.interface({
  auth_success: t.boolean,
  unsuccessful_connections: t.array(WorkatoConnectionCheck),
})
export type WorkatoConnectionsCheck = t.TypeOf<typeof WorkatoConnectionsCheck>

export const WorkatoGetAccountProperties = t.interface({
  result: t.record(t.string, t.string),
})
export type WorkatoGetAccountProperties = t.TypeOf<typeof WorkatoGetAccountProperties>

export const WorkatoPostAccountProperties = t.interface({
  properties: t.record(t.string, t.string),
  recipe_name: WorkatoRecipeName,
})
export type WorkatoPostAccountProperties = t.TypeOf<typeof WorkatoPostAccountProperties>

export const WorkatoSuccess = t.interface({
  success: t.boolean,
})

export type WorkatoSuccess = t.TypeOf<typeof WorkatoSuccess>

export const WorkatoDeactivate = t.interface({
  recipe_id: t.number,
})

export type WorkatoDeactivate = t.TypeOf<typeof WorkatoDeactivate>
