/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs/activity'
import { UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  // artifacts for activity instance
  ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH = 'ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH', // may not need
  ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH = 'ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH',
  ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE = 'ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE',
  // fetches artifacts for activity instance and downloads raw artifacts
  ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD = 'ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD',
  // raw artifacts
  RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH = 'RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH',
  RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD = 'RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD',
}

export type StateActionTypes =
  | ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH
  | ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH
  | ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD
  | ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE
  | RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH
  | RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD

export type ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH = {
  type: TypeKeys.ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH
  meta: { deferred: Deferred }
  payload: {
    response: Codecs.ArtifactForActivityInstance
  }
}

export function ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH(
  payload: ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH['payload'],
  deferred: Deferred,
): ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH {
  return {
    type: TypeKeys.ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD = {
  type: TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD
  meta: { deferred: Deferred }
  payload: {
    id: UUID
  }
}

export function ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD(
  payload: ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD['payload'],
  deferred: Deferred,
): ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD {
  return {
    type: TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_BULK_DOWNLOAD,
    meta: { deferred },
    payload,
  }
}

export type ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH = {
  type: TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: UUID
    options?: CrudQueryUtils.ReconQuery
  }
}

export function ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH(
  payload: ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH['payload'],
  deferred: Deferred,
): ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH {
  return {
    type: TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE = {
  type: TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE
  payload: Codecs.ArtifactForActivityInstanceResponse
  meta: {
    forActivityInstance: UUID
  }
}

export function ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE(
  payload: ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE['payload'],
  forActivityInstance: ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE['meta']['forActivityInstance'],
): ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE {
  return {
    type: TypeKeys.ARTIFACTS_FOR_ACTIVITY_INSTANCE_STORE_UPDATE,
    payload,
    meta: {
      forActivityInstance,
    },
  }
}

export type RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH = {
  type: TypeKeys.RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH
  meta: { deferred: Deferred }
  payload: {
    id: UUID
    filename: string
  }
}

export function RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH(
  payload: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH['payload'],
  deferred: Deferred,
): RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH {
  return {
    type: TypeKeys.RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_FETCH,
    meta: { deferred },
    payload,
  }
}

export type RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD = {
  type: TypeKeys.RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD
  meta: { deferred: Deferred }
  payload: {
    id: UUID
    filename: string
  }
}

export function RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD(
  payload: RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD['payload'],
  deferred: Deferred,
): RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD {
  return {
    type: TypeKeys.RAW_ARTIFACT_FOR_ACTIVITY_INSTANCE_DOWNLOAD,
    meta: { deferred },
    payload,
  }
}
