/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { AvailableColumns, Columns } from './reducer-types'

export const entityForActivityInstance = (tableId: 'entity_for_activity_instance') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'name',
      spec: {
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const activityInstance = (tableId: 'activity_instance') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'configuration__name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'instance__state',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'objective__status',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'traffic_source__ip_name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'instance__time_duration',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'instance__time_start',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'count__artifacts',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const exceptionPolicy = (tableId: 'exception_policy') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'name',
      spec: { type: 'string' },
    },

    {
      isEditable: false,
      isInternal: false,
      fieldName: 'enabled',
      spec: { type: 'boolean' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'exception_schedule',
      spec: { type: 'string' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'created_by',
      spec: { type: 'string' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'created_on',
      spec: { type: 'string' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'edited_by',
      spec: { type: 'string' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'edited_on',
      spec: { type: 'string' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'activities_count',
      spec: { type: 'string' },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const detectionTarget = (tableId: 'detection_target') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'detection_authorization_state',
      spec: null,
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'screenshot',
      spec: null,
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'vendor-service-version',
      spec: null,
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'authorizing_policies',
      spec: {
        items: { type: 'string' },
        type: 'array',
      },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'hostname',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'path',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'ip_str',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'port',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'first_seen',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'last_seen',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'validated_vulnerabilities_detection',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'validated_vulnerabilities_detection_count',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const hostname = (tableId: 'hostname') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'hostname',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'priority_score',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_temptation',
      spec: {
        type: 'object',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'characteristic_tags',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'impact_score',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'ip_count',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const implant = (tableId: 'implant') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'nick',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'last_checkin',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'host_ips',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'osdataType',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'uid',
      spec: {
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const asset = (tableId: 'asset') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'display_name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'perspective_id',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'sources',
      spec: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'implant_type',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'last_seen',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'os_type',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'interfaces',
      spec: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const source = (tableId: 'source') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'status',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'last_checkin',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'implant_type',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'os_type',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'default_perspective_id',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'perspective_type',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'created_by',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'asset',
      spec: {
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const ip = (tableId: 'ip') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'ip_str',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'priority_score',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_temptation',
      spec: {
        type: 'object',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'characteristic_tags',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'impact_score',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_count',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const network = (tableId: 'network') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'network',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'priority_score',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_temptation',
      spec: {
        type: 'object',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'characteristic_tags',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'impact_score',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_count',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const redirector = (tableId: 'redirector') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'external_ip',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status ',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'usage',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'created_on',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'retired',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const runbook = (tableId: 'runbook') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'name',
      spec: {
        type: 'string',
      },
    },
    {
      fieldName: 'duration',
      isEditable: false,
      isInternal: false,
      spec: null,
    },
    {
      fieldName: 'origination',
      isEditable: false,
      isInternal: false,
      spec: null,
    },
    {
      fieldName: 'destination',
      isEditable: false,
      isInternal: false,
      spec: null,
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'start_time',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const service = (tableId: 'service') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'vendor-service-version',
      spec: null,
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_temptation',
      spec: {
        type: 'object',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'tech_category',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'perspective__name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'instance_count',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const social = (tableId: 'social') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'authorization_state',
      spec: null,
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'email',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'person_first_name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'person_last_name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'title',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'priority_score',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_temptation',
      spec: {
        type: 'object',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'characteristic_tags',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'impact_score',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const target = (tableId: 'target') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'detection_authorization_state',
      spec: null,
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'screenshot',
      spec: null,
    },
    {
      fieldName: 'vendor-service-version',
      isEditable: false,
      isInternal: false,
      spec: null,
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'detection_criteria',
      spec: {
        type: 'object',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'priority_score',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_temptation',
      spec: {
        type: 'object',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'characteristic_tags',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'impact_score',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        enum: [], // @TODO: fill in later
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'user_tags',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'target_num_detections',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'validated_vulnerabilities_target',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'validated_vulnerabilities_target_count',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const policy = (tableId: 'policy') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'filter_data',
      spec: {
        type: 'array',
        items: { type: 'object' },
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'actions',
      spec: {
        type: 'array',
        items: { type: 'object' },
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const report = (tableId: 'report') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'report_name',
      spec: null,
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'created',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const savedView = (tableId: 'savedViews') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'entity_type',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'is_favorite',
      spec: {
        type: 'boolean',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'creator_user_name',
      spec: null, // setting to null because data is not available in db/randapispec
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'created_at',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'editor_user_name',
      spec: null, // setting to null because data is not available in db/randapispec
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'edited_at',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const activityConfiguration = (tableId: 'activity_configuration') => {
  const availableColumns: AvailableColumns = {}

  // Our best guess at what columns the user wants to see for Activity Library in case open api fetch does not complete
  // Required columns first / to the left
  const columns: Columns = [
    // Required / Non-editable
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'enabled',
      spec: {
        type: 'string',
      },
    },

    // Not required / Editable
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'cves',
      spec: {
        items: { type: 'string' },
        type: 'array',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'stability',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'targets_count',
      spec: {
        type: 'integer',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'activity_type_name',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'period',
      spec: {
        type: 'integer',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const authorizationPolicy = (tableId: 'authorization_policy') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'name',
      spec: { type: 'string' },
    },

    {
      isEditable: false,
      isInternal: false,
      fieldName: 'is_active',
      spec: { type: 'boolean' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'is_dirty',
      spec: { type: 'boolean' },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'creator_user_name',
      spec: null,
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'filter_data',
      spec: {
        type: 'array',
        items: { type: 'object' },
      },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'created_at',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'editor_user_name',
      spec: null,
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'edited_at',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },

    {
      isEditable: true,
      isInternal: false,
      fieldName: 'matching_entity',
      spec: null,
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}

export const detectionP = (tableId: 'bdo_detection') => {
  const availableColumns: AvailableColumns = {}

  const columns: Columns = [
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'authorization',
      spec: null,
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'artifact__thumbnail_sha',
      spec: null,
    },
    {
      isEditable: false,
      isInternal: false,
      fieldName: 'detection_criteria',
      spec: null,
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'detection_criteria__port',
      spec: {
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'detection_criteria__protocols',
      spec: {
        items: { type: 'string' },
        type: 'array',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'discovered_service__names',
      spec: {
        items: { type: 'string' },
        type: 'array',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'validated_vulnerabilities',
      spec: {
        type: 'array',
        items: { type: 'string' },
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'status',
      spec: {
        enum: [],
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'first_seen',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
    {
      isEditable: true,
      isInternal: false,
      fieldName: 'last_seen',
      spec: {
        format: 'date-time',
        type: 'string',
      },
    },
  ]

  return {
    columns,
    meta: {
      tableId,
      availableColumns,
    },
  }
}
