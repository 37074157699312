/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNil } from 'ramda'

// ---------------------------------------------------------------------------

/**
 * Wraps contents to prevent formatting issues that break the CSV.
 * @param field -
 * @returns a safe string
 */
export const stringWrap = (field: unknown) => {
  return `"${isNil(field) ? '' : field}"`
}
