/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import debug from 'debug'
import { isError } from 'lodash/fp'

// ---------------------------------------------------------------------------

const _log = debug('RANDORI:logger')
const _logNoop = debug('RANDORI:logger noop')

// eslint-disable-next-line lodash-fp/prefer-constant
function _getIsTracked(): boolean {
  return true
}

const _getFaro = () => {
  if (isNotNil(window?.__RUTIL__?.tracer?.getTracer)) {
    return window.__RUTIL__.tracer.getTracer()
  }
}

type LoggerConfig = {
  getFaro: typeof _getFaro
  getIsTracked: typeof _getIsTracked
  log: typeof _log
  logNoop: typeof _logNoop
}

// ---------------------------------------------------------------------------

function _exception({ getFaro, getIsTracked, log, logNoop }: LoggerConfig) {
  return function (err: unknown) {
    const faro = getFaro()
    const isTracked = getIsTracked()

    if (isNotNil(faro) && isTracked && isError(err)) {
      faro.api.pushError(err)

      log('exception', err)
    } else {
      logNoop('exception', err)
    }
  }
}

function _message({ getFaro, getIsTracked, log, logNoop }: LoggerConfig) {
  return function (msg: string) {
    const faro = getFaro()
    const isTracked = getIsTracked()

    if (isNotNil(faro) && isTracked) {
      faro.api.pushLog([msg])

      log('message', msg)
    } else {
      logNoop('message', msg)
    }
  }
}

function _setUser({ getFaro, getIsTracked, log, logNoop }: LoggerConfig) {
  type User = {
    id: string
    org_id: string
  }

  const setUser = (user: User) => {
    const faro = getFaro()
    const isTracked = getIsTracked()

    if (isNotNil(faro) && isTracked) {
      faro.api.setUser(user)

      log('Exception tracking context set')
    } else {
      logNoop('Exception tracking context set')
    }
  }

  return setUser
}

function _unsetUser({ getFaro, getIsTracked, log, logNoop }: LoggerConfig) {
  return () => {
    const faro = getFaro()
    const isTracked = getIsTracked()

    if (isNotNil(faro) && isTracked) {
      faro.api.resetUser()

      log('Exception tracking context cleared')
    } else {
      logNoop('Exception tracking context cleared')
    }
  }
}

// ---------------------------------------------------------------------------

export const error = _exception({ getFaro: _getFaro, log: _log, logNoop: _logNoop, getIsTracked: _getIsTracked })
export const log = _message({ getFaro: _getFaro, log: _log, logNoop: _logNoop, getIsTracked: _getIsTracked })
export const setUser = _setUser({ getFaro: _getFaro, log: _log, logNoop: _logNoop, getIsTracked: _getIsTracked })
export const unsetUser = _unsetUser({ getFaro: _getFaro, log: _log, logNoop: _logNoop, getIsTracked: _getIsTracked })

export const Test = {
  _exception,
  _getIsTracked,
  _message,
  _setUser,
  _unsetUser,
}
