/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'
import { NormalizedDetections } from '@/store/schemas/recon/recon.schemas'

// ---------------------------------------------------------------------------

export const DetectionsReducer = (state = Schemas.Recon.NormalizeDetections([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.DETECTIONS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeDetections(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          detection: {
            ...state.entities.detection,
            ...entities.detection,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.DETECTIONS_STORE_RESET: {
      return Schemas.Recon.NormalizeDetections([])
    }

    default:
      return state
  }
}

const DetectionsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const DetectionsPaginationReducer = (state = DetectionsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.DETECTIONS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}

// ---------------------------------------------------------------------------

type DetectionsStateP = {
  result: { [index: string]: string[] | undefined }
  entities: NormalizedDetections['entities']
}

const DetectionsReducerInitP: DetectionsStateP = {
  result: {},
  entities: { detection: {} },
}

export const DetectionsReducerP = (state = DetectionsReducerInitP, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.DETECTIONS_STORE_UPDATE_P: {
      const { entities, result } = Schemas.Recon.NormalizeDetections(action.payload.response.data)

      return {
        result: {
          ...state.result,
          [action.payload.targetId]: result,
        },
        entities: {
          ...state.entities,
          detection: {
            ...state.entities.detection,
            ...entities.detection,
          },
        },
      }
    }

    default:
      return state
  }
}

type DetectionsPaginationStateP = { [index: string]: Codecs.EntityPagination | undefined }
const DetectionsPaginationInitialStateP: DetectionsPaginationStateP = {}

export const DetectionsPaginationReducerP = (state = DetectionsPaginationInitialStateP, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.DETECTIONS_STORE_UPDATE_P: {
      const {
        response: { data, ...pagination },
      } = action.payload

      return {
        ...state,
        [action.payload.targetId]: {
          ...pagination,
        },
      }
    }

    default:
      return state
  }
}

// ---------------------------------------------------------------------------

export const TopLevelDetectionsReducer = (
  state = Schemas.Recon.NormalizeTopLevelDetections([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TOP_LEVEL_DETECTIONS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeTopLevelDetections(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          'top-level-detection': {
            ...state.entities['top-level-detection'],
            ...entities['top-level-detection'],
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.TOP_LEVEL_DETECTION_STORE_UPDATE: {
      const [detection] = action.payload.data

      return {
        result: state.result,
        entities: {
          ...state.entities,
          'top-level-detection': {
            ...state.entities['top-level-detection'],
            [detection.id]: detection,
          },
        },
      }
    }

    default:
      return state
  }
}

export const TopLevelDetectionTotalsInitialState: Codecs.EntityTotals = {
  unfilteredTotal: 0,
  unaffiliatedTotal: 0,
}

export const TopLevelDetectionTotalsReducer = (
  state = TopLevelDetectionTotalsInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const TopLevelDetectionsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const TopLevelDetectionsPaginationReducer = (
  state = TopLevelDetectionsPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TOP_LEVEL_DETECTIONS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
