/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { without } from 'ramda'

import { Source } from '@/codecs/source.codecs'

import { EntityName, initialState, SourceState } from './source.constants'
import { normalizeSources } from './source.schema'

// ---------------------------------------------------------------------------

export const sourceSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    updateSource: (state, action: PayloadAction<Source>) => {
      const id = action.payload.id

      state.entities.source[id] = action.payload
    },

    updateSourceDelete: (state, action: PayloadAction<Source>) => {
      const id = action.payload.id

      const remainingEntities = { ...state.entities.source }
      delete remainingEntities[id]

      state.entities.source = remainingEntities
      state.result = without([id], state.result)
    },

    updateSources: (state, action: PayloadAction<ReturnType<typeof normalizeSources>>) => {
      state.result = action.payload.result

      state.entities = {
        ...state.entities,
        ...action.payload.entities,
      }
    },

    updateSourceTotals: (state, action: PayloadAction<SourceState['totals']>) => {
      state.totals = action.payload
    },

    // @TODO
    // updateSourcePagination: (state, action: PayloadAction<EntityPagination>) => {
    //   state.pagination = action.payload
    // },
  },
})
