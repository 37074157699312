/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

import { EntityTableColumnsReducer } from './entity-table.reducers'

// ---------------------------------------------------------------------------

export * from './reducer-types'

export * from './entity-table.reducers.utils'

export const EntityTableReducer = combineReducers({
  columns: EntityTableColumnsReducer,
})
