/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { EntityType } from '../entity-types'

// If the openapi spec name is different than web-clients type name, we need
// to map back to the spec name in order for filters to auto-populate. In most
// cases, this will just work, but for "user" prefixed specs, we obviously
// need a mapping.
//
// This is a crucial part of how the list of available filters is mapped from
// an entity type to an openapi spec type.
export const getSchemaForEntityType = (entityType: EntityType): string => {
  // prettier-ignore
  switch (entityType) {
    case 'activity_configuration': return 'activity-configuration'
    case 'activity_instance': return 'v2-activity-log'
    case 'authorization_policy': return 'policy'
    case 'bar': return 'platform-event-view'
    case 'bdo_detection': return 'v2-detection'
    case 'blueprint': return 'platform-process-definition'
    case 'characteristicRule': return 'user-characteristic-rule'
    case 'detection_target': return 'v2-single-detection-for-target'
    case 'globalArtifact': return 'user-artifact'
    case 'globalHostname': return 'user-hostname'
    case 'globalIps': return 'user-ip'
    case 'globalNetwork': return 'user-network'
    case 'globalService': return 'user-service-count'
    case 'hostname': return 'v2-hostname'
    case 'implant': return 'attack-implants'
    case 'ip': return 'v2-ip'
    case 'network': return 'v2-network'
    case 'redirector': return 'attack-redirectors'
    case 'runbook': return 'attack-runbook'
    case 'savedViews': return 'saved-views'
    case 'service': return 'v2-service'
    case 'serviceRule': return 'user-service-rule'
    case 'serviceSuggestion': return 'user-suggestedservice'
    case 'social': return 'v2-social-entity'
    case 'target': return 'v2-single-detection-for-target'
    case 'topLevelDetection': return 'single-detection-for-target'

    default:
      return entityType
  }
}
