/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from 'reselect'

// ---------------------------------------------------------------------------
import * as Static from '@/store/static'

// ---------------------------------------------------------------------------

export const selectEdgeDescriptions = createSelector([() => Static.EDGE_DESCRIPTIONS], (descriptions) => descriptions)
