/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Affiliation } from '../affiliation.codecs'
import { withPriority } from '../priority.codecs'

// ---------------------------------------------------------------------------

export type SocialEntity = t.TypeOf<typeof SocialEntity>
export const SocialEntity = withPriority(
  t.type({
    address: t.union([t.string, t.null]),
    affiliation_state: Affiliation,
    authorization_state: t.union([t.string, t.null]),
    city: t.union([t.string, t.null]),
    company_name: t.union([t.string, t.null]),
    country: t.union([t.string, t.null]),
    details: t.union([t.type({}), t.null]),
    domain: t.union([t.string, t.null]),
    email: t.string,
    first_seen: t.union([t.string, t.null]), // @TODO: iso string
    id: t.string,
    last_seen: t.union([t.string, t.null]), // @TODO: iso string
    person_first_name: t.union([t.string, t.null]),
    person_last_name: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    postal_code: t.union([t.string, t.null]),
    characteristic_tags: t.array(t.string),
    role: t.union([t.string, t.null]),
    state: t.union([t.string, t.null]),
    sub_role: t.union([t.string, t.null]),
    title: t.union([t.string, t.null]),
    user_tags: t.array(t.string),
  }),
)
