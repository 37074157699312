/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, select, takeEvery } from 'typed-redux-saga/macro'

import { GlobalActions, SessionSelectors } from '@/store'
import { MiddlewaresIO } from '@/store/store.utils'
import { isNotNil } from '@/utilities/is-not'
import * as Logger from '@/utilities/logger'

// ---------------------------------------------------------------------------

export function* watchTracking(io: MiddlewaresIO) {
  yield takeEvery(GlobalActions.TypeKeys.RESET, Catch.standardAction(_RESET, io))
  yield takeEvery(GlobalActions.TypeKeys.TRACK, Catch.standardAction(_TRACK, io))
}

export function* _TRACK(_io: MiddlewaresIO) {
  const { authorization } = yield* select(SessionSelectors.selectSession)

  if (isNotNil(authorization) && isNotNil(authorization.view_org) && isNotNil(authorization.id)) {
    yield* call(Logger.setUser, { id: authorization.id, org_id: authorization.view_org })
  }
}

export function* _RESET(_io: MiddlewaresIO) {
  yield* call(Logger.unsetUser)
}
