/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const RedirectorStatus = t.keyof({
  '20_ACTIVE': null,
  '10_RETIRED': null,
})
export type RedirectorStatus = t.TypeOf<typeof RedirectorStatus>

export const KnownUsage = t.keyof({
  exploitation: null,
  c2: null,
  secondary: null,
})
export type KnownUsage = t.TypeOf<typeof KnownUsage>

export const Redirector = t.interface({
  bart_id: t.string,
  created_on: t.string, // @TODO: iso string
  deleted: t.boolean,
  external_ip: t.union([t.string, t.null]),
  external_ip_str: t.union([t.string, t.null]),
  id: t.string,
  org_id: t.string,
  remote_row_id: t.number,
  retired: t.union([t.string, t.null]), // @TODO: iso string
  status: RedirectorStatus,
  updated_on: t.string, // @TODO: iso string
  usage: t.array(t.union([KnownUsage, t.string])),
})

type _Redirector = t.TypeOf<typeof Redirector>
export type Redirector = _Redirector

export const RedirectorsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Redirector),
})
export type RedirectorsResponse = t.TypeOf<typeof RedirectorsResponse>
