/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { uniq } from 'lodash/fp'

// ---------------------------------------------------------------------------
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ProvidersReducer = (
  state = Schemas.OrganizationSchemas.NormalizeProviders([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.OrganizationActions.TypeKeys.SSO_PROVIDERS_STORE_UPDATE: {
      const { entities, result } = Schemas.OrganizationSchemas.NormalizeProviders(action.payload.sso_connections)

      return {
        result,
        entities: {
          ...state.entities,
          ...entities,
        },
      }
    }

    case Store.OrganizationActions.TypeKeys.SSO_PROVIDERS_STORE_UPDATE_SINGLE: {
      const { entities, result } = Schemas.OrganizationSchemas.NormalizeProviders([action.payload])

      return {
        result: uniq([...state.result, ...result]),
        entities: {
          provider: {
            ...state.entities.provider,
            ...entities.provider,
          },
        },
      }
    }

    default:
      return state
  }
}
