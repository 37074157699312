/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { AnyAction } from 'redux'

import { isNotNil } from '@/utilities/is-not'

import { AppState } from './store.constants'

// ---------------------------------------------------------------------------

export const getDevtoolsConfig = () => {
  // @see: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
  const actionSanitizer = (action: AnyAction) => {
    return action.type === 'OPENAPI_SET' && isNotNil(action.payload)
      ? { ...action, payload: '<<CHECK PLATFORM>>' }
      : action
  }

  const stateSanitizer = (state: AppState) => {
    return isNotNil(state.api.apiSpec) ? { ...state, api: { ...state.api, apiSpec: '<<CHECK PLATFORM>>' } } : state
  }

  const actionsDenylist = ['@@']

  return {
    actionSanitizer,
    actionsDenylist,
    stateSanitizer,
    trace: true,
  }
}
