/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export type IpForService = t.TypeOf<typeof IpForService>
export const IpForService = t.type({
  characteristic_tags: t.array(t.string),
  user_tags: t.array(t.string),
})
