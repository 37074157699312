/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

export const ConfigJson = t.type({
  API_URL: t.string,
  CSV_RECORD_LIMIT: CodecUtils.nullable(t.number),
  JWT_EXPIRY_WINDOW_SECONDS: CodecUtils.nullable(t.number),
  KEYCLOAK_CLIENT_ID: t.string,
  KEYCLOAK_GLOBAL_CLIENT_ID: t.string,
  KEYCLOAK_GLOBAL_SSO_REALM_NAME: t.string,
  KEYCLOAK_GLOBAL_SVC_URL: t.string,
  KEYCLOAK_SVC_URL: t.string,
  LOGS: t.boolean,
  RANDORI_FIXTURES: CodecUtils.nullable(t.boolean),
  STORE_LOGS: CodecUtils.nullable(t.boolean),
  TELEMETRY_ENDPOINT: CodecUtils.nullable(t.string),
  TRACES: CodecUtils.nullable(t.boolean),
  TRACK_ENV: t.boolean,
  WORKATO_PREFIX: CodecUtils.nullable(t.string),
  WORKATO_URL: t.string,
})
export type ConfigJson = t.TypeOf<typeof ConfigJson>
