/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Statistics } from './statistics.codecs'

// ---------------------------------------------------------------------------

export const Attack = t.interface({
  attack_id: t.union([t.string, t.null]),
  count_error: t.union([t.number, t.null]),
  count_fail: t.union([t.number, t.null]),
  count_inflight: t.union([t.number, t.null]),
  count_new: t.union([t.number, t.null]),
  count_preflight: t.union([t.number, t.null]),
  count_success: t.union([t.number, t.null]),
  description: t.union([t.string, t.null]),
  global_service_id: t.union([t.string, t.null]),
  id: t.string,
  ip_id: t.union([t.string, t.undefined]), // difference between /attack-for-service and /attack-for-ip
  name: t.union([t.string, t.null]),
  org_id: t.union([t.string, t.null]),
  outcome: t.union([t.string, t.null]),
  vector: t.union([t.string, t.null]),
})

type _Attack = t.TypeOf<typeof Attack>
export type Attack = _Attack

export const AttackTargetResult = t.interface({
  execution_as: t.union([t.string, t.undefined, t.null]),
  internal_hostname: t.union([t.string, t.undefined, t.null]),
  internal_ip: t.union([t.array(t.string), t.undefined, t.null]),
})

export const AttackTarget = t.interface({
  approved_time: t.union([t.string, t.null]), // @TODO: iso string
  attack_id: t.union([t.string, t.null]),
  created_time: t.union([t.string, t.null]), // @TODO: iso string
  description: t.union([t.string, t.null]),
  global_service_id: t.union([t.string, t.null]),
  id: t.string,
  ip_id: t.union([t.string, t.null]),
  ip_str: t.union([t.string, t.null]),
  name: t.union([t.string, t.null]),
  org_id: t.union([t.string, t.null]),
  outcome: t.union([t.string, t.null]),
  results: t.union([AttackTargetResult, t.null]),
  service_name: t.union([t.string, t.null]),
  service_version: t.union([t.string, t.null]),
  status: t.union([t.string, t.null]),
  target_id: t.union([t.string, t.null]),
  target_temptation: t.union([t.number, t.null]),
  updated_time: t.union([t.string, t.null]), // @TODO: iso string
  vector: t.union([t.string, t.null]),
})

type _AttackTarget = t.TypeOf<typeof AttackTarget>
export type AttackTarget = _AttackTarget

export const AttackResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Attack),
})
export type AttackResponse = t.TypeOf<typeof AttackResponse>

export const AttackTargetsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(AttackTarget),
})
export type AttackTargetsResponse = t.TypeOf<typeof AttackTargetsResponse>

export const AttackStatsMinResponse = t.interface({
  data: t.array(Statistics),
})
export type AttackStatsMinResponse = t.TypeOf<typeof AttackStatsMinResponse>
