/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export function getConnectionsUrl(id: number, auth: Codecs.LoginResponse['Authorization']) {
  return `${window.__RANDORI__.WORKATO_URL}/direct_link/embedded/connections/${id}?workato_dl_token=${auth}`
}
