/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { DetectionV2 as Detection } from '@/codecs'

import { makeNormalizer } from '../utilities/make-normalizer'
import { EntityName } from './detection.constants'

// ---------------------------------------------------------------------------

export const normalizeDetections = makeNormalizer<EntityName, Detection>(EntityName)
