/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

// @TODO: Talk to BE about literal perms.
//
// Unions *do* break if unexpected members appear in the array. We
// should discuss how lockstep the perms array should be. Ultimately, it's
// probably *good* to be literal, but if we're moving fast, new perms will
// break validation.
//
// perms: t.array(PermissionType),

const TokenType = t.keyof({
  user: null,
  service: null,
  api: null,
})

export const DecodedSession = t.interface({
  exp: t.union([t.number, t.undefined]),
  iat: t.union([t.number, t.undefined]),
  id: t.union([t.string, t.undefined]),
  jti: t.union([t.string, t.undefined]),
  paying: CodecUtils.nullable(t.boolean),
  perms: t.union([t.array(t.string), t.undefined]),
  shortname: t.union([t.string, t.undefined]),
  tracking: CodecUtils.nullable(t.boolean),
  type: t.union([TokenType, t.undefined]),
  username: t.union([t.string, t.undefined]),
  view_org: t.union([t.string, t.undefined]),
})
export type DecodedSession = t.TypeOf<typeof DecodedSession>

export const NewOtpTokenResponse = t.interface({
  Authorization: t.string,
  secret: t.string,
  uri: t.string,
})
export type NewOtpTokenResponse = t.TypeOf<typeof NewOtpTokenResponse>
