/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'
import { head } from 'lodash/fp'
import * as React from 'react'

import { useAppDispatch, useAppSelector } from '@/store'
import { SHOW_MODAL } from '@/store/actions/ui'
import {
  EntityDetailChildNavigationItem,
  EntityDetailNavigationItem,
  NarrowedEntityTypes,
} from '@/store/reducers/ui/entity-detail.reducers'
import {
  selectEntityDetailGuidanceNavItems,
  selectEntityDetailNavActiveItems,
  selectEntityDetailNavItems,
} from '@/store/selectors/ui/ui.selectors'
import { scrollTo } from '@/utilities/scroll-to'

// ---------------------------------------------------------------------------

export const useEntityDetailNavigationState = (entityType: NarrowedEntityTypes) => {
  const [activeChildItem, setActiveChildItem] = React.useState<string | undefined>()
  const dispatch = useAppDispatch()
  const guidanceItems = useAppSelector((state) => selectEntityDetailGuidanceNavItems(state, { entityType: entityType }))
  const navActiveItems = useAppSelector((state) => selectEntityDetailNavActiveItems(state, { entityType: entityType }))
  const navItems = useAppSelector((state) => selectEntityDetailNavItems(state, { entityType: entityType }))

  const [activeItem, setActiveItem] = React.useState<string>(head(navItems)?.id ?? 'details')

  const topLevelIds = navItems.map((item) => item.id)
  const guidanceIds = guidanceItems.map((item) => item.id)

  const navIdsWithChildren = [...topLevelIds, ...guidanceIds]

  const onNavClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    navItem: EntityDetailNavigationItem,
    navChild: EntityDetailChildNavigationItem | null = null,
  ) => {
    e.stopPropagation()

    setActiveItem(navItem.id)

    if (isNotNilOrEmpty(navChild)) {
      setActiveChildItem(navChild.id)
      scrollTo(navChild.id, navIdsWithChildren)
    } else {
      setActiveChildItem(undefined)
      scrollTo(navItem.id, navIdsWithChildren)
    }
  }

  const onEditClick = () => {
    const payload = {
      kind: 'navigation-edit' as const,
      childProps: {
        entityType: entityType,
      },
    }

    dispatch(SHOW_MODAL(payload))
  }

  return {
    activeChildItem,
    activeItem,
    guidanceItems,
    navActiveItems,
    navItems,
    onEditClick,
    onNavClick,
  }
}
