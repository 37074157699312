/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { AuthoritativeEntityType } from '@/codecs'

import { AuthoritativeEntityTypes } from '../entity-types'

export const isAuthoritativeEntityType = (
  maybeAuthEntityType: string,
): maybeAuthEntityType is AuthoritativeEntityType => {
  const _check: string[] = [...AuthoritativeEntityTypes]

  return _check.includes(maybeAuthEntityType)
}
