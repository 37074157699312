/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { SocialEntity } from './social'

// ---------------------------------------------------------------------------
export type SocialEntityResponse = t.TypeOf<typeof SocialEntityResponse>
export const SocialEntityResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(SocialEntity),
})
