/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { produce } from 'immer'

import { Asset, EntityPagination } from '@/codecs'

import { AssetState, EntityName, initialState } from './asset.constants'
import { normalizeAssets } from './asset.schema'

// ---------------------------------------------------------------------------

export const assetSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    updateAsset: (state, action: PayloadAction<Asset>) => {
      const id = action.payload.id

      state.entities.asset[id] = action.payload
    },

    updateAssets: (state, action: PayloadAction<ReturnType<typeof normalizeAssets>>) => {
      state.result = action.payload.result

      state.entities = produce(state.entities, (draft) => {
        return {
          asset: {
            ...draft.asset,
            ...action.payload.entities.asset,
          },
        }
      })
    },

    updateEntities: (state, action: PayloadAction<ReturnType<typeof normalizeAssets>>) => {
      state.entities = produce(state.entities, (draft) => {
        return {
          asset: {
            ...draft.asset,
            ...action.payload.entities.asset,
          },
        }
      })
    },

    updateAssetTotals: (state, action: PayloadAction<AssetState['totals']>) => {
      state.totals = action.payload
    },

    updateAssetPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },
  },
})
