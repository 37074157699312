/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

// ---------------------------------------------------------------------------
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  ENTITY_GET = 'ENTITY_GET',
}

// export type StateActionTypes =
//   never

// ---------------------------------------------------------------------------

export type ENTITY_GET = {
  type: TypeKeys.ENTITY_GET
  meta: { deferred: Deferred }
  payload: {
    entityType: EntityUtils.EntityType
    search: RouteComponentProps['location']['search']
  }
}

// a GET for data that we do not intend to persist.
//
// Only use this for fetching, say, totals, etc... The saga this corresponds
// to does not use `put.`
export const ENTITY_GET = (payload: ENTITY_GET['payload'], deferred: Deferred): ENTITY_GET => {
  return {
    type: TypeKeys.ENTITY_GET,
    meta: { deferred },
    payload,
  }
}
