/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

const COLUMN_ORDER_PREFERENCE_SUFFIX = '_column_order'

export const getColumnOrderPreferenceByEntity = (entity: EntityUtils.EntityTypesTableSummary) =>
  `${entity}${COLUMN_ORDER_PREFERENCE_SUFFIX}`

export const getEntityFromColumnOrderPreferenceName = (preferenceName: string) => {
  const [entityType] = preferenceName.split(COLUMN_ORDER_PREFERENCE_SUFFIX)

  // validate here

  return entityType as EntityUtils.EntityTypesTableSummary
}
