/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as FlagUtils from '@/utilities/flags'

import { CharacteristicReducer } from './characteristic.reducers'
import * as EntityDetailReducer from './entity-detail.reducers'
import { EntityTableReducer } from './entity-table.reducers'
import { FlagReducer } from './flag.reducers'
import { GuidancePaneReducer } from './guidance-pane.reducers'
import { HocTemplateReducer } from './hoc-template.reducers'
import { InspectPaneReducer } from './inspect-pane.reducers'
import { ModalReducer } from './modal.reducers'
import { NavigationReducer } from './navigation.reducers'
import { ReconSummaryReducer } from './recon-summary.reducers'
import { SlideoutReducer } from './slideout.reducers'
import { TTFactorReducer } from './tt.reducers'

// ---------------------------------------------------------------------------

export type State = ReturnType<typeof Reducer>
export const Reducer = combineReducers({
  characteristic: persistReducer({ key: 'characteristic', storage }, CharacteristicReducer),
  entityTable: EntityTableReducer,
  flags: persistReducer({ key: FlagUtils.storageKey, storage }, FlagReducer),
  guidancePane: persistReducer({ key: 'guidance-pane', storage }, GuidancePaneReducer),
  hocTemplate: persistReducer({ key: 'hoc-template', storage }, HocTemplateReducer),
  inspectPane: persistReducer({ key: 'inspect-pane', storage }, InspectPaneReducer),
  modal: ModalReducer,
  navigation: persistReducer({ key: 'navigation', storage }, NavigationReducer),
  reconSummary: persistReducer({ key: 'recon-summary', storage }, ReconSummaryReducer),
  slideout: SlideoutReducer,
  tt: persistReducer({ key: 'tt-factors', storage }, TTFactorReducer),

  entityDetailNav: combineReducers({
    activeItem: EntityDetailReducer.EntityDetailNavigationActiveStateReducer,
    contentRendered: EntityDetailReducer.EntityDetailContentRenderedReducer,
    guidanceContent: EntityDetailReducer.EntityDetailNavigationGuidanceStateReducer,
    items: EntityDetailReducer.EntityDetailNavigationItemsReducer,
  }),
})

export const Test = {
  InspectPaneReducer,
  ModalReducer,
  SlideoutReducer,
}
