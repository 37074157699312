/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { Asset } from '@/codecs'
import { UUID } from '@/utilities/codec'

import { EntityName } from './asset.constants'

// ---------------------------------------------------------------------------

export function normalizeAssets(records: Asset[]) {
  const Asset = new schema.Entity(EntityName)
  const Assets = [Asset]

  type NormalizedAssets = {
    result: UUID[]
    entities: {
      [EntityName]: Record<UUID, Asset>
    }
  }

  const assets: NormalizedAssets = normalize(records, Assets)

  return assets
}
