/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { Authorization } from '../authorization.codecs'
import { CPE } from '../cpe.codecs'
import { DetectionCriteria } from '../detection-criteria.codecs'
import { LensView, Perspective } from '../perspective.codecs'
import { withPriority } from '../priority.codecs'
import { TechCategoryList } from '../tech-category.codecs'
import { withTemptation } from '../temptation.codecs'
import { TextSource } from '../text-source.codecs'

// ---------------------------------------------------------------------------

export type DetectionTarget = t.TypeOf<typeof DetectionTarget>
export const DetectionTarget = withPriority(
  withTemptation(
    t.type({
      affiliation_state: Affiliation,
      attack_note: t.union([t.string, t.null]),
      authorization_state: Authorization,
      banners_data_hash: t.union([t.string, t.null]),
      banners_uuid: t.union([t.string, t.null]),
      cert_data_hash: t.union([t.string, t.null]),
      cert_uuid: t.union([t.string, t.null]),
      characteristic_tags: t.array(t.string),
      characteristics_count: t.number,
      cpe: CodecUtils.nullable(CPE),
      deleted: t.boolean,
      description: t.union([t.string, t.null]),
      description_source: TextSource,
      detection_criteria: DetectionCriteria,
      first_seen: t.union([t.string, t.null]),
      headers_data_hash: t.union([t.string, t.null]),
      headers_uuid: t.union([t.string, t.null]),
      hostname: t.union([t.string, t.null]),
      hostname_id: t.union([t.string, t.undefined, t.null]),
      id: t.string,
      ip_id: t.union([t.string, t.null]),
      ip_str: t.union([t.string, t.null]),
      last_seen: t.union([t.string, t.null]),
      lens_id: t.union([t.string, t.null]),
      lens_view: t.union([LensView, t.null]),
      name: t.union([t.string, t.null]),
      org_id: t.union([t.string, t.null]),
      path: t.union([t.string, t.null]),
      perspective: t.union([t.string, t.null]),
      perspective_name: t.union([Perspective, t.null]), // @TODO: Remove nullability
      poc_email: CodecUtils.nullable(t.string),
      poc_id: CodecUtils.nullable(t.string),
      port: t.union([t.number, t.null]),
      protocol: t.union([t.string, t.null]),
      randori_notes: t.union([t.string, t.null]),
      reference: t.union([t.string, t.null]),
      screenshot_data_hash: t.union([t.string, t.null]),
      screenshot_uuid: t.union([t.string, t.null]),
      service_id: t.union([t.string, t.null]),
      target_first_seen: t.union([t.string, t.null]),
      target_id: t.string,
      target_last_seen: t.union([t.string, t.null]),
      target_num_detections: t.number,
      tech_category: CodecUtils.nullable(TechCategoryList),
      thumbnail_data_hash: t.union([t.string, t.null]),
      thumbnail_uuid: t.union([t.string, t.null]),
      user_tags: t.array(t.string),
      validated_vulnerabilities_detection: t.array(t.string),
      validated_vulnerabilities_detection_count: t.number,
      validated_vulnerabilities_target: t.array(t.string),
      validated_vulnerabilities_target_count: t.number,
      vendor: t.union([t.string, t.null]),
      version: t.union([t.string, t.null]),

      // @TODO: These are not nullable after BE deployments settle
      authorizing_policies: CodecUtils.nullable(t.array(t.string)),
      detection_authorization_state: CodecUtils.nullable(Authorization),
      target_num_authorized_detections: CodecUtils.nullable(t.number),
    }),
  ),
)
