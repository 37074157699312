/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { DateString, DoubleUUID, get, IP, Sha1, UUID } from '@/utilities/codec'

// -----------------------------------------------------------------------------

export const Target: Codecs.TargetP = {
  affiliation_state: 'None',
  applicability: 4,
  attack_note: '',
  authorization_state: 'Authorized',
  authorizing_policies: [],
  banners_data_hash: get('f6275c715b849d5d3c09528a13fc66fcd9d86f97', Sha1),
  banners_uuid: get('ba4bcfb2-453f-4d9d-99ce-05d55d56535f', UUID),
  cert_data_hash: null,
  cert_uuid: null,
  characteristic_tags: [],
  characteristics_count: 0,
  criticality: 3,
  deleted: false,
  description:
    'Exim is a message transfer agent (MTA) developed at the University of Cambridge for use on Unix systems connected to the Internet.',
  description_source: 'default',
  detection_authorization_state: 'Authorized',
  enumerability: 3,
  exploitability: 5,
  first_seen: get('2022-12-15T08:04:15.594002+00:00', DateString),
  headers_data_hash: null,
  headers_uuid: null,
  hostname: null,
  hostname_id: null,
  id: get('af3de2bc-cc96-4ce8-a27d-d2f6d711d7e4,3fff0760-3bc7-4064-9442-42a200d4e641', DoubleUUID),
  impact_score: Codecs.ImpactScoreEnum.low,
  ip_id: get('38724391-dbba-4e40-b69a-8a96c779252f', UUID),
  ip_str: get('0.0.0.0', IP),
  last_seen: get('2023-04-05T20:09:42.537821+00:00', DateString),
  name: 'Exim',
  org_id: get('b28f017c-c015-4c11-87e0-3e886cdf1b05', UUID),
  path: null,
  perspective__name: 'Default External',
  perspective_id: get('f7627d26-456c-4340-bf12-af7c123cc4ae', UUID),
  perspective_is_targeted: false,
  perspective_type: 'external',
  poc_email: null,
  poc_id: null,
  port: 587,
  post_exploit: 3,
  priority_impact_factor: 0,
  priority_score: 100.5,
  priority_status_factor: 0.1675,
  priority_tags_factor: 0,
  private_weakness: 4,
  protocol: 'tcp',
  public_weakness: 5,
  randori_notes:
    'Exim versions prior to 4.95 are vulnerable to a series of vulnerabilities that are remotely exploitable and could lead to system compromise.\n\nhttps://nvd.nist.gov/vuln/detail/CVE-2022-37452',
  reference: '',
  research: 4,
  screenshot_data_hash: null,
  screenshot_uuid: null,
  service_id: get('96ed0fd8-e075-4cb8-824e-cd26ff177398', UUID),
  status: Codecs.StatusEnum.accepted,
  target_first_seen: get('2023-03-01T16:59:22.331246+00:00', DateString),
  target_id: get('3fff0760-3bc7-4064-9442-42a200d4e641', UUID),
  target_last_seen: get('2023-04-06T04:38:49.839976+00:00', DateString),
  target_num_authorized_detections: 0,
  target_num_detections: 1,
  target_temptation: 67,
  tech_category: [Codecs.TechCategoryValuesEnum.IoT],
  thumbnail_data_hash: null,
  thumbnail_uuid: null,
  user_tags: ['g tag', 'Rob Test Tag'],
  validated_vulnerabilities_detection: ['cve-0001-0001'],
  validated_vulnerabilities_detection_count: 1,
  validated_vulnerabilities_target: ['cve-0001-0001'],
  validated_vulnerabilities_target_count: 1,
  vendor: 'Exim',
  version: '4.93',

  detection_criteria: {
    ip: {
      address: '0.0.0.0',
      version: 4,
    },
    tcp: {
      port: 587,
    },
  },

  cpe: {
    cpe_version: '2.3',
    edition: null,
    language: null,
    other: null,
    part: 'a',
    product: 'exim',
    str: 'cpe:2.3:a:exim:exim:4.93:*:*:*:*:*:*:*',
    sw_edition: null,
    target_hw: null,
    target_sw: null,
    update: null,
    vendor: 'exim',
    version: '4.93',
  },
}
