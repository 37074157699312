/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import Keycloak from 'keycloak-js'
import type { KeycloakInstance, KeycloakOnLoad } from 'keycloak-js'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:keycloak')

type KeyCloakConfig = {
  config: {
    url: string
    realm: string // UUID
    clientId: string
  }

  onAuthError: KeycloakInstance['onAuthError']
  onAuthLogout: KeycloakInstance['onAuthLogout']
  onAuthRefreshError: KeycloakInstance['onAuthRefreshError']
  onAuthRefreshSuccess: KeycloakInstance['onAuthRefreshSuccess']
  onAuthSuccess: KeycloakInstance['onAuthSuccess']
  onInitError: (error: unknown) => void
  onReady: KeycloakInstance['onReady']
  onTokenExpired: KeycloakInstance['onTokenExpired']
}

export function useKeyCloak(props: KeyCloakConfig) {
  log('constructor config', props.config)

  const keycloak = new Keycloak(props.config)

  keycloak.onReady = props.onReady
  keycloak.onAuthSuccess = props.onAuthSuccess
  keycloak.onAuthError = props.onAuthError
  keycloak.onAuthRefreshSuccess = props.onAuthRefreshSuccess
  keycloak.onAuthRefreshError = props.onAuthRefreshError
  keycloak.onAuthLogout = props.onAuthLogout
  keycloak.onTokenExpired = props.onTokenExpired

  const onLoadInit: KeycloakOnLoad =
    props.config.realm !== window.__RANDORI__.KEYCLOAK_GLOBAL_SSO_REALM_NAME ? 'login-required' : 'check-sso'

  const config = {
    onLoad: onLoadInit,
    checkLoginIframe: false,

    // silentCheckSsoRedirectUri is breaking redirects in Firefox
    // silentCheckSsoRedirectUri: window.location.protocol + '//' + window.location.host + '/login-check-sso',
  }

  log('init config', config)

  keycloak.init(config).catch(props.onInitError)

  return keycloak
}

export const KC_ACS_URL_FORMATTER = (orgId: string, connectionAlias: string) => {
  const url = `${window.__RANDORI__.KEYCLOAK_SVC_URL}/realms/${orgId}/broker/${connectionAlias}/endpoint`

  log('broker-endpoint:', url)

  return url
}

export const KC_CLIENT_ID_FORMATTER = (orgId: string) => {
  const url = `${window.__RANDORI__.KEYCLOAK_SVC_URL}/realms/${orgId}`

  log('org-realm:', url)

  return url
}
