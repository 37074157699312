/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import qs from 'query-string'

import { CommentBody, GetCommentPageResponse, PostCommentPayload } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

const baseURL = '/recon/api/v1/entity'

export type CommentApi = {
  createComment: (entityId: string, body: PostCommentPayload) => Promise<CommentBody>
  fetchCommentPage: (entityId: string, offset: number, limit: number) => Promise<GetCommentPageResponse>
  editComment: (entityId: string, commentId: string, body: PostCommentPayload) => Promise<CommentBody>
  deleteComment: (entityId: string, commentId: string) => Promise<CommentBody>
}

export function commentApiFactory(http: HttpClient): CommentApi {
  return {
    createComment(entityId: string, body: PostCommentPayload) {
      return http
        .post(`${baseURL}/${entityId}/comment`, body.data)
        .then(CodecUtils.throwValidate(CommentBody, 'CommentBody'))
    },
    fetchCommentPage(entityId: string, offset: number, limit: number) {
      const args = qs.stringify({
        offset,
        limit,
      })
      return http
        .get(`${baseURL}/${entityId}/comment?${args}`)
        .then(CodecUtils.throwValidate(GetCommentPageResponse, 'GetCommentPageResponse'))
    },
    editComment(entityId: string, commentId: string, body: PostCommentPayload) {
      return http
        .patch(`${baseURL}/${entityId}/comment/${commentId}`, body.data)
        .then(CodecUtils.throwValidate(CommentBody, 'CommentBody'))
    },
    deleteComment(entityId: string, commentId: string) {
      return http
        .delete(`${baseURL}/${entityId}/comment/${commentId}`)
        .then(CodecUtils.throwValidate(CommentBody, 'CommentBody'))
    },
  }
}
