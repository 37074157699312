/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { createSelector } from '@reduxjs/toolkit'

import { Asset, AssetNormalized } from '@/codecs'
import type { AppState } from '@/store'
import { get, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

/**
 * Normalizes the structure of an asset object for display in the summary table
 *
 * @param asset - The asset object to normalize
 *
 * @returns An object with varying fields due to implementation of Asset properties
 *
 */
export const normalizeAsset = (asset: Asset): AssetNormalized => {
  const { id, properties, ...rest } = asset

  const normalizedAsset = {
    id: get(id, UUID),
    ...rest,
    ...properties,
  }

  return normalizedAsset
}

export const selectAssets = createSelector([(state: AppState) => state.asset], (assetsState) => {
  return assetsState.result.map((uuid) => normalizeAsset(assetsState.entities.asset[uuid]))
})

export const selectAssetsPagination = createSelector([(state: AppState) => state.asset], (assetsState) => {
  return assetsState.pagination
})

export const _selectAssetById = (state: AppState, props: { id: UUID }) => {
  const asset = state.asset.entities.asset[props.id]

  return asset
}

export const selectAssetById = createSelector([_selectAssetById], (asset) => {
  if (isNotNil(asset)) {
    return normalizeAsset(asset)
  }
})
