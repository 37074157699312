/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNil } from 'lodash/fp'

import { isPojo } from '@/utilities/is-pojo'

import { Message } from './types'

// ---------------------------------------------------------------------------

export const isFormData = (data: Message, config?: RequestInit) => {
  const headers = config?.headers

  if (!(data instanceof FormData)) {
    return false
  }

  if (isNil(headers)) {
    return false
  }

  if (!isPojo(headers)) {
    return false
  }

  const contentType = headers['content-type']

  return contentType === 'multipart/form-data'
}
