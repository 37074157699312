/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { keyOfInitialState, NarrowedEntityTypes } from '@/store/reducers/ui/entity-detail.reducers'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

const NAV_ORDER_PREFERENCE_SUFFIX = '_details_nav_order'

export const getNavOrderPreferenceByEntity = (entity: NarrowedEntityTypes) => {
  switch (entity) {
    case 'savedViews':
      return `saved_view${NAV_ORDER_PREFERENCE_SUFFIX}`
    default:
      return `${entity}${NAV_ORDER_PREFERENCE_SUFFIX}`
  }
}

export const getEntityFromNavOrderPreferenceName = (preferenceName: string) => {
  switch (preferenceName) {
    case 'saved_view_details_nav_order':
      return 'savedViews'
    default:
      const [_entityType] = preferenceName.split(NAV_ORDER_PREFERENCE_SUFFIX)
      const entityType = CodecUtils.throwValidate(keyOfInitialState, 'keyOfInitialState')(_entityType)

      return entityType
  }
}
