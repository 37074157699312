/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil, isNotNilOrEmpty } from '@randori/rootkit'
import { uniq } from 'lodash/fp'

import type { ConfidenceBoundaries, PriorityPreference } from '@/store/selectors/preferences/preferences.selectors'
import type { TTBoundaries } from '@/store/selectors/preferences/preferences.selectors.utils'
import type { EntityType } from '@/utilities/r-entity'

import { formatHeaders } from './format-headers'
import { formatRecord } from './format-record'
import { getMandatoryFields } from './get-mandatory-fields'
import { omitField } from './omit-field'

// ---------------------------------------------------------------------------

/**
 * Takes a Record and transforms it so that it can be fed to our CSV converter.
 *
 * @param kind -
 * @param records -
 * @param selectedColumns -
 *
 * @returns the records formatted as a string[][]
 */
export function getRows(
  kind: EntityType,
  records: Array<Record<string, any>>,
  selectedColumns: string[],
  priorityBoundaries: PriorityPreference,
  temptationBoundaries: TTBoundaries,
  confidenceBoundaries: ConfidenceBoundaries,
) {
  const firstRecord = records[0]

  if (!isNotNil(firstRecord)) {
    return [[]]
  }

  const mandatoryFields = getMandatoryFields(kind)
  const fields = isNotNilOrEmpty(selectedColumns)
    ? uniq(mandatoryFields.concat(selectedColumns).filter(omitField))
    : uniq(Object.keys(firstRecord).filter(omitField))

  const formattedHeaders = formatHeaders(fields, kind)
  const formattedRecords = records.map(
    formatRecord(fields, priorityBoundaries, temptationBoundaries, confidenceBoundaries, kind),
  )

  const rows = [formattedHeaders, ...formattedRecords]

  return rows
}
