/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { UUID } from '@/utilities/codec'

// -----------------------------------------------------------------------------

// const example = {
//   configuration__activity_class: 'recon',
//   configuration__description: 'Runs portscanner network ping against a public network',
//   count: 10,
//   matching_entity__type: 'org_network',
//   mitre__mitigations: ['M1056'],
//   mitre__tactics: ['TA0043'],
//   mitre__techniques: ['T1595.001'],
//   name: 'Portscanner',
//   result: 'Ingest Complete',
//   run_time: 374449.7,
//   status: 'failure',
// }

export type ActivityReportRow = t.TypeOf<typeof ActivityReportRow>
export const ActivityReportRow = t.type({
  configuration__activity_class: t.string,
  configuration__description: t.string,
  count: t.number,
  matching_entity__type: t.string,
  mitre__mitigations: t.array(t.string),
  mitre__tactics: t.array(t.string),
  mitre__techniques: t.array(t.string),
  name: t.string,
  result: t.string,
  run_time: t.number,
  status: t.string,
})

export type ActivityReport = t.TypeOf<typeof ActivityReport>
export const ActivityReport = t.type({
  org_id: UUID,
  attack_oppertunistic: t.record(t.string, t.array(ActivityReportRow)),
})
