/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import { cloneDeep } from 'lodash/fp'
import { createSelector, defaultMemoize } from 'reselect'

import { DecodedSession } from '@/codecs'
import { AppState } from '@/store'
import { isNotEmpty, isNotNil } from '@/utilities/is-not'
import {
  EntityType,
  getActivityConfigurationViewSchema,
  getAssetViewSchema,
  getExceptionPolicyViewSchema,
  getSourceViewSchema,
  getViewSchema,
} from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:auth-protected')
const mockLog = debug('RANDORI:mocks')
const _getApi = (state: AppState) => state.api
const _getSession = (state: AppState) => state.authorization

export const selectSession = createSelector([_getSession], (session) => {
  return session
})

export const selectAuthorization = createSelector([_getSession], (session) => {
  return session.authorization
})

export const selectUsername = createSelector([_getSession], (session) => {
  return session.authorization && isNotNil(session.authorization.username) ? session.authorization.username : ''
})

export const selectCurrentOrgShortname = createSelector([_getSession], (session) => {
  return session.authorization && isNotNil(session.authorization.shortname) ? session.authorization.shortname : ''
})

export const selectViewOrg = createSelector([_getSession], (session) => {
  return session.authorization && isNotNil(session.authorization.view_org) ? session.authorization.view_org : ''
})

export const selectPermissions = createSelector([_getSession], (session) => {
  return session.authorization && session.authorization.perms ? session.authorization.perms : []
})

export const selectIsAuthenticated = createSelector([selectSession], (session) => {
  if (isNotEmpty(session)) {
    const { authorization } = session

    const permissions = isDecodedSession(authorization) && isStringArray(authorization.perms) ? authorization.perms : []

    log('hasAuthenticatedSession: %O', authorization)

    return permissions.includes('authenticated')
  } else {
    return false
  }
})

export const selectOpenApiSpec = createSelector([_getApi, (state: AppState) => state.ui.flags], (apiState, flags) => {
  const { _persist /* shake */, ...rest } = apiState.apiSpec

  const _apiSpec = cloneDeep(rest)

  const mockKeys: string[] = []

  mockLog('selectOpenApiSpec', {
    apiSpec: _apiSpec,
    flags,
    mockKeys,
  })

  // Mutate api spec to contain mocked data
  mockKeys.forEach((__MOCK_KEY__) => {
    mockLog('noop')

    // Leaving as example:
    // if (__MOCK_KEY__ == 'activity-log') {
    //   _apiSpec = set(['components', 'schemas', __MOCK_KEY__, 'properties'], ActivityInstanceSpec, _apiSpec)
    //
    //   mockLog(`spec: ${__MOCK_KEY__} -> `, _apiSpec)
    // }
  })

  return _apiSpec
})

export const selectViewSchema = createSelector(
  [selectOpenApiSpec, (_state: AppState, entityType: EntityType) => entityType],
  (spec, entityType) => {
    switch (entityType) {
      case 'activity_configuration':
        return getActivityConfigurationViewSchema(spec)

      case 'exception_policy':
        return getExceptionPolicyViewSchema(spec)

      case 'source':
        return getSourceViewSchema()

      case 'asset':
        return getAssetViewSchema()

      default:
        return getViewSchema(spec, entityType)
    }
  },
)

export const selectViewSchemas = createSelector(
  [selectOpenApiSpec, (_state: AppState, entityTypes: EntityType[]) => entityTypes],
  (apiSpec, entityTypes) => {
    const entityTypesMappedToViewSchemas = entityTypes.map((entityType) => {
      if (entityType === 'activity_configuration') {
        return getActivityConfigurationViewSchema(apiSpec)
      } else if (entityType === 'exception_policy') {
        return getExceptionPolicyViewSchema(apiSpec)
      } else if (entityType === 'source') {
        return getSourceViewSchema()
      } else if (entityType === 'asset') {
        return getAssetViewSchema()
      } else {
        return getViewSchema(apiSpec, entityType)
      }
    })
    return entityTypesMappedToViewSchemas
  },
)

export const makeSelectViewSchema = createSelector([selectOpenApiSpec], (apiSpec) =>
  defaultMemoize((entityType: EntityType) => {
    if ((entityType = 'activity_configuration')) {
      return getActivityConfigurationViewSchema(apiSpec)
    } else if (entityType === 'exception_policy') {
      return getExceptionPolicyViewSchema(apiSpec)
    } else if (entityType === 'source') {
      return getSourceViewSchema()
    } else if (entityType === 'asset') {
      return getAssetViewSchema()
    } else {
      return getViewSchema(apiSpec, entityType)
    }
  }),
)

function isDecodedSession(state: DecodedSession | undefined): state is DecodedSession {
  return isNotNil(state)
}

function isStringArray(state: string[] | undefined): state is string[] {
  return isNotNil(state)
}
