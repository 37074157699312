/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { equals } from 'ramda'

import { _TimeSpan, _WeekSpan, ACAttributes, ExceptionPolicy, Link, PaginatedLink, PaginationMeta } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, throwValidate, UUID } from '@/utilities/codec'
import { parseQuery, unserializeQ } from '@/utilities/crud-query'
import { EMPTY_ROOT_RULE_GROUP } from '@/utilities/query-filter-utils/__fixtures__/root-rule-group.fixture'

// ---------------------------------------------------------------------------

export const ExceptionPolicyAssignRequest = t.type({
  id: UUID,
  config: t.type({
    configuration_ids: t.array(UUID),
    operation: t.string,
  }),
})

export type ExceptionPolicyAssignRequest = t.TypeOf<typeof ExceptionPolicyAssignRequest>

export const ExceptionPolicyPatchRequest = t.type({
  id: UUID,
  data: t.type({
    name: t.string,
    changed_fields: t.array(t.number),
    description: t.string,
    enabled: t.boolean,
    config: t.type({
      activity_classes: t.array(t.string),
      timezone: t.string,
      perspectives: t.array(t.string),
      week_spans: t.union([t.array(_WeekSpan), t.array(t.null)]),
      time_spans: t.union([t.array(_TimeSpan), t.array(t.null)]),
    }),
  }),
})

export type ExceptionPolicyPatchRequest = t.TypeOf<typeof ExceptionPolicyPatchRequest>

export const ExceptionPolicyPostRequest = t.type({
  id: UUID,
  data: t.type({
    name: t.string,
    description: t.string,
    enabled: t.boolean,
    config: t.type({
      activity_classes: t.array(t.string),
      timezone: t.string,
      perspectives: t.array(t.string),
      week_spans: t.union([t.array(_WeekSpan), t.array(t.null)]),
      time_spans: t.union([t.array(_TimeSpan), t.array(t.null)]),
    }),
  }),
})

export type ExceptionPolicyPostRequest = t.TypeOf<typeof ExceptionPolicyPostRequest>

export const ExceptionPolicyNullResponse = t.type({
  data: t.null,
  links: t.null,
})
export type ExceptionPolicyNullResponse = t.TypeOf<typeof ExceptionPolicyNullResponse>

export const ExceptionPoliciesResponse = t.type({
  data: t.array(ExceptionPolicy),
  links: PaginatedLink,
  meta: PaginationMeta,
})
export type ExceptionPoliciesResponse = t.TypeOf<typeof ExceptionPoliciesResponse>

export const ExceptionPolicyResponse = t.type({
  data: ExceptionPolicy,
  links: Link,
})
export type ExceptionPolicyResponse = t.TypeOf<typeof ExceptionPolicyResponse>

export const ExceptionPolicyPostResponse = t.type({
  data: ExceptionPolicy,
  links: Link,
})
export type ExceptionPolicyPostResponse = t.TypeOf<typeof ExceptionPolicyPostResponse>

export const ExceptionPolicyPatchResponse = t.type({
  data: ExceptionPolicy,
  links: Link,
})
export type ExceptionPolicyPatchResponse = t.TypeOf<typeof ExceptionPolicyPatchResponse>

export const ExceptionPolicyConfigurations = t.array(
  t.type({
    attributes: ACAttributes,
    id: UUID,
    relationships: t.any,
    type: t.string,
  }),
)
export type ExceptionPolicyConfigurations = t.TypeOf<typeof ExceptionPolicyConfigurations>

export const ExceptionPolicyGetConfigurationsResponse = t.type({
  data: ExceptionPolicyConfigurations,
  links: PaginatedLink,
  meta: PaginationMeta,
})
export type ExceptionPolicyGetConfigurationsResponse = t.TypeOf<typeof ExceptionPolicyGetConfigurationsResponse>

export type ExceptionPolicyApi = {
  getExceptionPolicies: (query: QueryString | '') => Promise<ExceptionPoliciesResponse>
  getExceptionPolicy: (id: UUID) => Promise<ExceptionPolicyResponse>
  getExceptionPolicyConfigurations: (id: UUID) => Promise<ExceptionPolicyGetConfigurationsResponse>
  patchExceptionPolicy: (request: ExceptionPolicyPatchRequest) => Promise<ExceptionPolicyPatchResponse>
  postExceptionPolicy: (request: ExceptionPolicyPostRequest) => Promise<ExceptionPolicyPostResponse>
  assignExceptionPolicy: (request: ExceptionPolicyAssignRequest) => Promise<ExceptionPolicyNullResponse>
  deleteExceptionPolicy: (id: UUID) => Promise<ExceptionPolicyNullResponse>
}

export function exceptionPolicyApiFactory(http: HttpClient): ExceptionPolicyApi {
  const baseUrl = '/cms/api/v1/frontend'

  return {
    getExceptionPolicies(query) {
      let exceptionPolicyQuery = query
      const { q } = parseQuery(exceptionPolicyQuery)
      const unserializedQ = unserializeQ(q ?? '')

      if (equals(unserializedQ, EMPTY_ROOT_RULE_GROUP)) {
        // ACS will error with an empty q string so if it's empty we need to remove it
        exceptionPolicyQuery = exceptionPolicyQuery.replace(/([&?])q=[^&]*&?/, '$1') as QueryString
      }

      if (exceptionPolicyQuery.includes('reversed_nulls')) {
        // ACS does not support reversed_nulls so we must remove it
        const replaceCharacter = exceptionPolicyQuery.includes('sort') ? '&' : ''
        exceptionPolicyQuery = exceptionPolicyQuery.replace(
          /([&?])reversed_nulls=(true|false)&?/,
          replaceCharacter,
        ) as QueryString
      }

      return http
        .get(`${baseUrl}/exception-policies${exceptionPolicyQuery}`)
        .then(throwValidate(ExceptionPoliciesResponse, 'ExceptionPoliciesResponse'))
    },

    getExceptionPolicy(id) {
      return http
        .get(`${baseUrl}/exception-policies/${id}`)
        .then(throwValidate(ExceptionPolicyResponse, 'ExceptionPolicyResponse'))
    },

    getExceptionPolicyConfigurations(id) {
      return http
        .get(`${baseUrl}/exception-policies/${id}/configurations`)
        .then(throwValidate(ExceptionPolicyGetConfigurationsResponse, 'ExceptionPolicyGetConfigurationsResponse'))
    },

    patchExceptionPolicy(request) {
      return http
        .patch(`${baseUrl}/exception-policies/${request.id}`, request.data)
        .then(throwValidate(ExceptionPolicyPatchResponse, 'ExceptionPolicyPatchResponse'))
    },

    postExceptionPolicy(request) {
      return http
        .post(`${baseUrl}/exception-policies`, request)
        .then(throwValidate(ExceptionPolicyPostResponse, 'ExceptionPolicyPostResponse'))
    },

    assignExceptionPolicy(request) {
      return http
        .put(`${baseUrl}/exception-policies/${request.id}`, request.config)
        .then(throwValidate(ExceptionPolicyNullResponse, 'ExceptionPolicyNullResponse'))
    },

    deleteExceptionPolicy(id) {
      return http
        .delete(`${baseUrl}/exception-policies/${id}`)
        .then(throwValidate(ExceptionPolicyNullResponse, 'ExceptionPolicyNullResponse'))
    },
  }
}
