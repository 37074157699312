/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { HostnameP as Hostname, HostnameForIpP as HostnameForIp } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, rflaskGetSingle, throwValidate, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type HostnameApi = {
  getHostname: (id: UUID) => Promise<GetHostname>
  getHostnames: (query: QueryString | '') => Promise<GetHostnames>
  getHostnamesForIp: (query: QueryString | '') => Promise<GetHostnamesForIp>
}

export function hostnameApiFactory(http: HttpClient): HostnameApi {
  const baseUrl = '/recon/api/v2/hostname'

  return {
    getHostname(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetHostname, 'GetHostname'))
    },

    getHostnames(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetHostnames, 'GetHostnames'))
    },

    getHostnamesForIp(query) {
      return http
        .get(`/recon/api/v2/hostnames-for-ip${query}`)
        .then(throwValidate(GetHostnamesForIp, 'GetHostnamesForIp'))
    },
  }
}

export type GetHostnames = t.TypeOf<typeof GetHostnames>
export const GetHostnames = rflaskGetMany(Hostname)

export type GetHostname = t.TypeOf<typeof GetHostname>
export const GetHostname = rflaskGetSingle(Hostname)

export type GetHostnamesForIp = t.TypeOf<typeof GetHostnamesForIp>
export const GetHostnamesForIp = rflaskGetMany(HostnameForIp)
