/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { makeFetchMany, makeFetchSingle, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './social.constants'

// ---------------------------------------------------------------------------

export const fetchSocial = makeFetchSingle(EntityName)
export const fetchSocialTotals = makeFetchTotals(EntityName)
export const fetchSocials = makeFetchMany(EntityName)
