/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

const TargetsForEntityReducerInitialState = {
  byId: {} as Record<string, string[]>,
  ...Schemas.Recon.NormalizeTargetsForEntity([]),
}

export const TargetsForEntityReducer = (state = TargetsForEntityReducerInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TARGETS_FOR_ENTITY_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeTargetsForEntity(action.payload.data)

      // We need a very unique ID to store results by
      const localId = `${action.meta.originatingEntityId},${action.meta.originatingEntityLensId}`

      return {
        result,
        byId: {
          ...state.byId,
          [localId]: result,
        },
        entities: {
          ...state.entities,
          target: {
            ...state.entities.target,
            ...entities.target,
          },
        },
      }
    }

    default:
      return state
  }
}

const TargetsForEntityPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const TargetsForEntityPaginationReducer = (
  state = TargetsForEntityPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.TARGETS_FOR_ENTITY_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
