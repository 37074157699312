/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { RouteComponentProps } from 'react-router-dom'
import { DeferredAction } from 'redux-saga-try-catch'

import { UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

// Assisted by WCA@IBM
// Latest GenAI contribution: ibm/granite-20b-code-instruct-v2
/**
 * Creates an action creator for fetching multiple entities from the server.
 *
 * @param entityName - The name of the entity being fetched.
 *
 * @returns An action creator that returns an action with the given entity name
 * and a payload containing the query string, deferred promise, and a flag
 * indicating whether the data should be persisted.
 */
export const makeFetchMany = <T extends string>(entityName: T) =>
  createAction(
    `${entityName}/fetch-many`,
    (
      payload: RouteComponentProps['location']['search'],
      deferred: DeferredAction['meta']['deferred'],
      persist?: boolean,
    ) => {
      const _persist = persist ?? true

      return {
        meta: { deferred, persist: _persist },
        payload,
      }
    },
  )

// Assisted by WCA@IBM
// Latest GenAI contribution: ibm/granite-20b-code-instruct-v2
/**
 * Creates an action creator for fetching a single entity from the server.
 *
 * @param entityName - The name of the entity being fetched.
 *
 * @returns An action creator that returns an action with the given entity name
 * and a payload containing the id, query string, deferred promise, and a
 * flag indicating whether the data should be persisted.
 */
export const makeFetchSingle = <T extends string>(entityName: T) =>
  createAction(`${entityName}/fetch-single`, (payload: UUID, deferred: DeferredAction['meta']['deferred']) => {
    return {
      meta: { deferred },
      payload,
    }
  })

// Assisted by WCA@IBM
// Latest GenAI contribution: ibm/granite-20b-code-instruct-v2
/**
 * Creates an action creator for fetching a totals object from the server.
 *
 * @param entityName - The name of the entity being fetched.
 *
 * @returns An action creator that returns an action with the given entity name
 * and a payload containing the query string, deferred promise, and a flag
 * indicating whether the data should be persisted.
 */
export const makeFetchTotals = <T extends string>(entityName: T) =>
  createAction(
    `${entityName}/fetch-totals`,
    (payload: RouteComponentProps['location']['search'], deferred: DeferredAction['meta']['deferred']) => {
      return {
        meta: { deferred },
        payload,
      }
    },
  )

export const makeFetchManyForEntity = <T extends string, U extends string>(entityName: T, forEntityName: U) =>
  createAction(
    `${entityName}/fetch-many-for-${forEntityName}`,
    (
      payload: { id: UUID; search: RouteComponentProps['location']['search'] },
      deferred: DeferredAction['meta']['deferred'],
      persist?: boolean,
    ) => {
      const _persist = persist ?? true

      return {
        meta: { deferred, persist: _persist },
        payload,
      }
    },
  )
