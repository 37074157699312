/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { call } from 'typed-redux-saga/macro'

import * as _DetectionActions from '@/store/actions/recon/detection.actions'
import { MiddlewaresIO } from '@/store/store.utils'

export function* _DETECTIONS_AUTHORIZE(io: MiddlewaresIO, action: _DetectionActions.DETECTIONS_AUTHORIZE) {
  const detectionIds = action.payload.detection_ids.map((compoundId) => {
    const [detectionId, _targetId] = compoundId.split(',')

    return detectionId
  })

  const mungedPayload = {
    ...action.payload,
    detection_ids: detectionIds,
  }

  const result = yield* call(io.api.recon.authorizeDetections, mungedPayload)

  return result
}
