/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export const CoreAssociation = t.interface({
  // @TODO: We should make an enum of CORE entity types
  type: t.string, // CORE entity type
  uuid: CodecUtils.nullable(t.union([t.array(t.string), t.string])), // string or null
  identifier: CodecUtils.nullable(t.union([t.array(t.string), t.string])), // string or null
})

export type CoreAssociation = t.TypeOf<typeof CoreAssociation>

export const Trigger = t.interface({
  core_association: CoreAssociation,
})
