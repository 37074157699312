/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { CrudRuleGroup } from '@randori/rootkit'
import { head, omit } from 'lodash/fp'
import qs from 'query-string'
import { call, put, select } from 'typed-redux-saga/macro'

import * as Codecs from '@/codecs'
import * as Store from '@/store'
import * as _AuthorizationPolicyActions from '@/store/actions/recon/authorization-policy.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { isNotNil } from '@/utilities/is-not'
import * as QueryFilterUtils from '@/utilities/query-filter-utils'

export function* _AUTHORIZATION_POLICY_FETCH(
  io: MiddlewaresIO,
  action: _AuthorizationPolicyActions.AUTHORIZATION_POLICY_FETCH,
) {
  const q = {
    condition: 'AND',
    rules: [
      {
        field: 'table.id',
        id: 'table.id',
        input: 'text',
        operator: 'equal',
        randoriOnly: true,
        type: 'string',
        ui_id: 'id',
        value: action.payload,
      },
    ],
  }

  const serializedQuery = `?${qs.stringify({ q: CrudQueryUtils.serializeQ(q) })}`

  const result = yield* call(io.api.recon.getAuthorizationPolicies, serializedQuery)

  const policy = head(result.data)

  if (isNotNil(policy)) {
    const modifiedPolicy = {
      ...policy,
      filter_data: QueryFilterUtils.getFilterHierarchy(policy.filter_data as CrudRuleGroup),
    }

    yield* put(Store.ReconActions.AUTHORIZATION_POLICY_STORE_UPDATE(modifiedPolicy))

    return modifiedPolicy
  } else {
    io.logger('_AUTHORIZATION_POLICY_FETCH returned nothing')

    return policy
  }
}

export function* _AUTHORIZATION_POLICIES_FETCH(
  io: MiddlewaresIO,
  action: _AuthorizationPolicyActions.AUTHORIZATION_POLICIES_FETCH,
) {
  const serializedQuery = CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload))

  const result = yield* call(io.api.recon.getAuthorizationPolicies, serializedQuery)

  const modifiedResult = {
    ...result,
    data: [
      ...result.data.map((row) => {
        return {
          ...row,
          filter_data: QueryFilterUtils.getFilterHierarchy(row.filter_data as CrudRuleGroup),
        }
      }),
    ],
  }

  yield* put(Store.ReconActions.AUTHORIZATION_POLICIES_STORE_UPDATE(modifiedResult))

  return modifiedResult
}

export function* _AUTHORIZATION_POLICIES_PATCH(
  io: MiddlewaresIO,
  action: _AuthorizationPolicyActions.AUTHORIZATION_POLICIES_PATCH,
) {
  const { id, ...payload } = action.payload

  const result = yield* call(io.api.recon.patchAuthorizationPolicy, id, payload)

  yield* put(Store.ReconActions.AUTHORIZATION_POLICY_STORE_UPDATE(result.data))

  return result
}

export function* _AUTHORIZATION_POLICIES_POST(
  io: MiddlewaresIO,
  action: _AuthorizationPolicyActions.AUTHORIZATION_POLICIES_POST,
) {
  const result = yield* call(io.api.recon.postAuthorizationPolicy, action.payload)

  return result
}

export function* _AUTHORIZATION_POLICY_TOTALS_FETCH(
  io: MiddlewaresIO,
  action: _AuthorizationPolicyActions.AUTHORIZATION_POLICY_TOTALS_FETCH,
) {
  const { total } = yield* call(io.api.recon.getAuthorizationPolicies, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
    total: total,
  }

  yield* put(Store.ReconActions.AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE(totals))

  return totals
}

export function* _AUTHORIZATION_POLICY_DELETE(
  io: MiddlewaresIO,
  action: _AuthorizationPolicyActions.AUTHORIZATION_POLICY_DELETE,
) {
  const policy = yield* select(Store.ReconSelectors.selectAuthorizationPolicyById, { id: action.payload })

  const patchPayload: Codecs.PolicyPatchPayload = {
    data: omit(['created_at', 'creator_user_name', 'edited_at', 'editor_user_name', 'org_id', 'id', 'version'], {
      ...policy,
      is_deleted: true,
    }),
  }

  yield* call(io.api.recon.patchAuthorizationPolicy, action.payload, patchPayload)

  yield* put(Store.ReconActions.AUTHORIZATION_POLICY_DELETE_STORE_UPDATE(action.payload))
}
