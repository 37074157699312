/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import qs from 'query-string'
import { RouteComponentProps } from 'react-router-dom'

import type { ReconQuery, StatsQuery } from '@/utilities/crud-query'

// ---------------------------------------------------------------------------

type MaybeValue = string | (string | null)[] | null

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Parses a value to a number.
 *
 * @param value - The value to parse.
 *
 * @returns The parsed number, or undefined if the parsing failed.
 *
 * @throws An error if the value is not a string.
 */
export function parseNumber(value?: any) {
  if (isNotNil(value) && typeof value !== 'string') {
    throw new Error(`Unsupported typeof value: ${typeof value}`)
  }

  const _value = parseInt(value, 10)

  return !isNaN(_value) ? _value : undefined
}

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Parses a string value.
 *
 * @param value - The value to parse.
 *
 * @returns The parsed string, or `undefined` if the value was not a string.
 */
export function parseString(value: MaybeValue) {
  if (typeof value === 'string') {
    return value
  } else {
    return undefined
  }
}

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Parses the search query parameters from the given location object.
 *
 * @param location - The location object from the React Router
 *
 * @returns A StatsQuery or ReconQuery object, depending on the query parameters
 */
export const parseSearch = (location: RouteComponentProps['location']): StatsQuery | ReconQuery => {
  const { interval, limit, offset, q, sort } = qs.parse(location.search)

  const parsed = {
    interval: parseNumber(interval),
    limit: parseNumber(limit),
    offset: parseNumber(offset),
    q: parseString(q),
    sort: parseString(sort),
  }

  return parsed
}
