/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export const getRequiredProps = CodecUtils.getRequiredValue
export const getRequiredValue = CodecUtils.getRequiredValue
