/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { isRandori } from '@/utilities/is-randori-user'
import { PendoInit } from '@/utilities/tracker'

// ---------------------------------------------------------------------------

export function getPendoConfig(session: Codecs.DecodedSession) {
  const isPaying = session.paying ?? false
  const isTracked = session.tracking ?? true

  const whitelistTransform = {
    attr: 'search',
    action: 'AllowOnlyKeys',
    data: [],
  }

  const config: PendoInit = {
    visitor: {
      id: session.id,
      is_randori: isRandori(session.username),
      username: session.username,
    },

    account: {
      id: session.view_org,
      is_paying: isPaying,
      is_tracked: isTracked,
      shortname: session.shortname,
    },

    location: {
      transforms: [whitelistTransform],
    },

    excludeAllText: true,

    htmlAttributeBlacklist: /(href)/,
  }

  return {
    config,
    isPaying,
    isTracked,
  }
}
