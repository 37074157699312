/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import qs from 'query-string'
import * as Catch from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import { TagsActions } from '@/store'
import { FetchTagCounts } from '@/store/actions/tags'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchTags(io: MiddlewaresIO) {
  yield takeEvery(TagsActions.TypeKeys.TAGS_FETCH, Catch.deferredAction(_TAGS_FETCH, io))
  yield takeEvery(TagsActions.TypeKeys.TAG_COUNTS_FETCH, Catch.deferredAction(_TAG_COUNTS_FETCH, io))
}

export function* _TAGS_FETCH(io: MiddlewaresIO) {
  const tags = yield* call(io.api.tags.getTags)

  //@TODO: Tags were added to the redux store as an incremental step. Still need to create selectors and use in codebase.
  yield* put(TagsActions.USER_TAGS_STATE_UPDATE(tags.tags))

  return tags
}

export function* _TAG_COUNTS_FETCH(io: MiddlewaresIO, action: FetchTagCounts) {
  const response = yield* call(io.api.tags.getTagCounts, qs.stringify(action.payload))

  return response
}
