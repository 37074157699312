/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import * as Catch from 'redux-saga-try-catch'
import { all, call, put, select, takeEvery, takeLeading } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _UIActions from '@/store/actions/ui/ui.actions'
import { AvailableColumns, Column } from '@/store/reducers/ui/entity-table.reducers'
import { MiddlewaresIO } from '@/store/store.utils'
import * as EntityUtils from '@/utilities/r-entity'
import * as WorkatoUtils from '@/utilities/workato'

import { getAvailableColumns } from './ui.sagas.utils'

// ---------------------------------------------------------------------------

export function* watchUI(io: MiddlewaresIO) {
  yield* takeEvery(Store.UIActions.TypeKeys.SHOW_WORKATO_EMBED, Catch.deferredAction(_SHOW_WORKATO_EMBED, io))
  yield* takeEvery(Store.UIActions.TypeKeys.MAP_API_SPEC_TO_TABLE_COLUMNS, _MAP_API_SPEC_TO_TABLE_COLUMNS, io)

  // takeEvery does not work here. It creates a new saga for each action
  // takeLeading creates one ongoing saga that watches all relevant incoming actions
  yield* takeLeading(
    Store.UIActions.TypeKeys.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY,
    _SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY,
  )
}

// ---------------------------------------------------------------------------

export function* _SHOW_WORKATO_EMBED(io: MiddlewaresIO, action: _UIActions.SHOW_WORKATO_EMBED) {
  const { Authorization } = yield* call(io.api.auth.getWorkatoJwt)

  const widgetURL = yield* call(WorkatoUtils.getConnectionsUrl, action.payload.connectionId, Authorization)

  const payload = {
    kind: 'workato-embedded-connection' as const,
    childProps: {
      embeddedConnectionURL: widgetURL,
      // We cannot `put` from this callback without the additional complexity
      // of `yield`. Additionally, it does not appear to be used from within
      // the modal, so aside from the fun of solving a puzzle, there's no
      // practical reason to solve this.
      //
      // @see: https://decembersoft.com/posts/redux-saga-put-from-inside-a-callback/
      connectionName: action.payload.connectionName,
    },
  }

  yield* put(Store.UIActions.SHOW_MODAL(payload))
}

export function* _SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY() {
  return // bail
}

export function* _MAP_API_SPEC_TO_TABLE_COLUMNS(io: MiddlewaresIO) {
  try {
    yield* call(io.logger, 'refreshing available table columns')

    yield* all(
      EntityUtils.EntityTypesTableSummary.map((tableId) => {
        return call(getTableColumnUpdateEffect, tableId)
      }),
    )
  } catch (e) {
    yield* call(io.stdout, '_MAP_API_SPEC_TO_TABLE_COLUMNS', e)
  }
}

/**
 * Reconciles the state reducer for an EntityType with OpenAPI specs
 *
 * @remarks
 * This state reconciliation needs to be improved. It seems to race, and there
 * are weird page repaints with what looks like bad or stale state.
 *
 * @param tableId - alias for EntityType
 */
function* getTableColumnUpdateEffect(tableId: EntityUtils.EntityTypesTableSummary) {
  const initialState = yield* select(Store.UISelectors.selectColumnsForAllEntities)
  const isInternalSupport = yield* select(Store.GateSelectors.hasPlatformSupport)
  const schema = yield* select(Store.SessionSelectors.selectViewSchema, tableId)
  const staticFlags = yield* select(Store.GateSelectors.staticFlags)

  const initialColumns = initialState[tableId].columns

  const availableColumns = getAvailableColumns({
    isInternalSupport,
    schema,
    staticFlags,
    tableId,
  })

  const prunedActiveColumns = initialColumns.filter(isColumnAvailable(availableColumns))

  yield* put(
    Store.UIActions.UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS({
      tableId,
      columns: prunedActiveColumns,
    }),
  )

  yield* put(
    Store.UIActions.UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS({
      tableId,
      availableColumns,
    }),
  )
}

/**
 * @param availableColumns - The hashmap of available columns
 * @param column - column that will be filtered
 *
 * @returns whether or not the column is a valid available column
 */
const isColumnAvailable = (availableColumns: AvailableColumns) => (column: Column) => {
  // If column.spec is null, this column is a front-end only column, and cannot be modified by the UI that is rendered
  if (column.spec === null) {
    return true
  }

  return isNotNil(availableColumns[column.fieldName])
}
