/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export type StateActionTypes = APPLICABLE_ACTIVITIES_FETCH | APPLICABLE_ACTIVITIES_STORE_UPDATE | VALIDATE_JOB_POST

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  APPLICABLE_ACTIVITIES_FETCH = 'APPLICABLE_ACTIVITIES_FETCH',
  // TODO: remove this if we don't want to store applicable activities
  APPLICABLE_ACTIVITIES_STORE_UPDATE = 'APPLICABLE_ACTIVITIES_STORE_UPDATE',
  VALIDATE_JOB_POST = 'VALIDATE_JOB_POST',
}

export type APPLICABLE_ACTIVITIES_FETCH = {
  type: TypeKeys.APPLICABLE_ACTIVITIES_FETCH
  meta: { deferred: Deferred }
  // Note: we are getting non-authoritative EntityType here but will be converting it to authoritative in the saga (before passing to ACS)
  payload: { entityType: Codecs.EntityType; entityId: string; query: string }
}

export function APPLICABLE_ACTIVITIES_FETCH(
  payload: APPLICABLE_ACTIVITIES_FETCH['payload'],
  deferred: Deferred,
): APPLICABLE_ACTIVITIES_FETCH {
  return {
    type: TypeKeys.APPLICABLE_ACTIVITIES_FETCH,
    meta: { deferred },
    payload,
  }
}

// ---------------------------------------------------------------------------

export type APPLICABLE_ACTIVITIES_STORE_UPDATE = {
  type: TypeKeys.APPLICABLE_ACTIVITIES_STORE_UPDATE
  payload: Codecs.ApplicableActivitiesResponse
}

export function APPLICABLE_ACTIVITIES_STORE_UPDATE(
  payload: APPLICABLE_ACTIVITIES_STORE_UPDATE['payload'],
): APPLICABLE_ACTIVITIES_STORE_UPDATE {
  return {
    type: TypeKeys.APPLICABLE_ACTIVITIES_STORE_UPDATE,
    payload,
  }
}

// ---------------------------------------------------------------------------

export type VALIDATE_JOB_POST = {
  type: TypeKeys.VALIDATE_JOB_POST
  meta: { deferred: Deferred }
  // Note: we are getting non-authoritative EntityType here but will be converting it to authoritative in the saga (before passing to ACS)
  payload: { entityType: Codecs.EntityType; entityId: string; configurationId: string }
}

export function VALIDATE_JOB_POST(payload: VALIDATE_JOB_POST['payload'], deferred: Deferred): VALIDATE_JOB_POST {
  return {
    type: TypeKeys.VALIDATE_JOB_POST,
    meta: { deferred },
    payload,
  }
}
