/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export type StateActionTypes = APPLICABLE_ENTITIES_FETCH | APPLICABLE_ENTITIES_STORE_UPDATE

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  APPLICABLE_ENTITIES_FETCH = 'APPLICABLE_ENTITIES_FETCH',
  APPLICABLE_ENTITIES_STORE_UPDATE = 'APPLICABLE_ENTITIES_STORE_UPDATE',
}

export type APPLICABLE_ENTITIES_FETCH = {
  type: TypeKeys.APPLICABLE_ENTITIES_FETCH
  meta: { deferred: Deferred }
  payload: { configurationId: string; query: string; offset: number; entityType: Codecs.AuthoritativeEntityType }
}

export function APPLICABLE_ENTITIES_FETCH(
  payload: APPLICABLE_ENTITIES_FETCH['payload'],
  deferred: Deferred,
): APPLICABLE_ENTITIES_FETCH {
  return {
    type: TypeKeys.APPLICABLE_ENTITIES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type APPLICABLE_ENTITIES_STORE_UPDATE = {
  type: TypeKeys.APPLICABLE_ENTITIES_STORE_UPDATE
  payload: Codecs.ApplicableEntitiesResponse
}

export function APPLICABLE_ENTITIES_STORE_UPDATE(
  payload: APPLICABLE_ENTITIES_STORE_UPDATE['payload'],
): APPLICABLE_ENTITIES_STORE_UPDATE {
  return {
    type: TypeKeys.APPLICABLE_ENTITIES_STORE_UPDATE,
    payload,
  }
}
