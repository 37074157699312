/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { applyMiddleware, createStore, StoreEnhancer } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import { ApiFactory } from '@/api'
import { HttpFactory } from '@/http'
import * as historyUtil from '@/utilities/history'
import * as Logger from '@/utilities/logger'
import * as Tracker from '@/utilities/tracker'

import rootReducer from './reducers'
import createRootSaga from './sagas'
import { compose, createLoggingMiddleware, resetStore, StoreConfig } from './store.utils'

// ---------------------------------------------------------------------------

export * from './actions'
export * from './selectors'

type ConfiguredStore = ReturnType<typeof configureStore>
export type AppState = ReturnType<typeof rootReducer>
type AppDispatch = ConfiguredStore['store']['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export const configureStore = (
  config: StoreConfig,
  // @TODO: Type `preloadedState`
  //
  // We can just grab the type for preloadedState from redux-toolkit, if
  // necessary. Until then, this seems fine.
  //
  // @see: https://github.com/reduxjs/redux-toolkit/blob/master/src/configureStore.ts#L87
  preloadedState: Record<string, unknown>,
) => {
  const defaultHttp = HttpFactory(window.__RANDORI__.API_URL, {
    shouldRetry: true,
  })
  const defaultApi = ApiFactory(defaultHttp)

  const {
    api = defaultApi,
    exception = Logger.error,
    history = historyUtil.history,
    http = defaultHttp,
    logger = Logger.log,
    options = { logger: true },
    stdout = debug('RANDORI:store'),
    tracker = Tracker,
  } = config

  const loggingMiddleware = createLoggingMiddleware(window.__RANDORI__.STORE_LOGS ?? false, options)

  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor: {
      effectRejected: (_effectId, error) => {
        exception(error)
      },
    },
  })

  const io = {
    api,
    history,
    http,
    logger,
    stdout,
    tracker,
  }

  const middleware = [sagaMiddleware, ...loggingMiddleware]

  const enhancer: StoreEnhancer = compose(applyMiddleware(...(middleware as any[])), resetStore)

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['navigation'],
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, preloadedState, enhancer)

  window.__RUTIL__.store = store

  const rootSaga = createRootSaga(io)

  sagaMiddleware.run(rootSaga)

  return {
    io,
    persistor: persistStore(store),
    store,
  }
}
