/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'

import * as Store from '@/store'

import { withSession, WithSessionInnerProps } from './with-session'

// ---------------------------------------------------------------------------

const AuthProtectedRouteView: React.FunctionComponent<WithSessionInnerProps & RouteProps> = (props) => {
  const isAuthenticated = useSelector(Store.SessionSelectors.selectIsAuthenticated)

  const { render, ...rest } = props

  return (
    <React.Fragment>
      <Route
        {...rest}
        render={(props: RouteComponentProps) => (isAuthenticated ? render({ ...props }) : <Redirect to="/login" />)}
      />
    </React.Fragment>
  )
}

// ---------------------------------------------------------------------------

export const AuthProtectedRoute = withSession(AuthProtectedRouteView)
