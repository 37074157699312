/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNil, keys, values } from 'lodash/fp'

import * as Logger from '@/utilities/logger'

// ---------------------------------------------------------------------------

type Str<T extends string> = T

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Maps an array of IDs and labels to an array of objects with the given property name and ID.
 *
 * @param srcIds - An array of IDs.
 * @param srcLabels - An array of labels.
 * @param propName - The name of the property to be added to the returned objects.
 *
 * @returns An array of objects with the given property name and ID.
 */
export const mapRelatedFieldsP = <
  Id extends string,
  Label extends string,
  Ids extends Str<Id>[],
  Labels extends Str<Label>[],
>(
  srcIds: Ids,
  srcLabels: Labels,
  propName: string,
) => {
  type IdType = Ids[0]
  type LabelType = Labels[0]

  try {
    const mapped = srcIds
      .map((id, i) => {
        if (isNil(srcLabels[i])) {
          throw new Error('dedupe: missing label')
        }

        return {
          [`${id}`]: srcLabels[i],
        }
      })
      .map((mapping) => {
        const [id] = keys(mapping)
        const [label] = values(mapping)

        // @TODO: Need a more sophisticated type here
        // const transformed: { id: IdType; [x: string]: LabelType } = {
        const transformed = {
          [`${propName}`]: label as LabelType,
          id: id as IdType,
        }

        return transformed
      })

    return mapped
  } catch (e) {
    Logger.error(e)
    return []
  }
}

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Deduplicates related items in a tuple of arrays.
 *
 * @param srcIds - An array of item IDs.
 * @param srcLabels - An array of item labels.
 *
 * @returns A tuple containing two arrays of unique strings.
 *
 * @throws `{Error}` If the lengths of the input arrays are different.
 */
export const dedupeRelatedTuple = <
  Id extends string,
  Label extends string,
  Ids extends Str<Id>[],
  Labels extends Str<Label>[],
>(
  srcIds: Ids,
  srcLabels: Labels,
) => {
  type IdType = Ids[0]
  type LabelType = Labels[0]

  const ids = srcIds
  const labels = srcLabels

  if (ids.length !== labels.length) {
    throw new Error('dedupeRelatedTuple: list lengths are different')
  }

  const tuple: [IdType[], LabelType[]] = [ids, labels]

  return tuple
}

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Maps related items in a tuple of arrays to an object with the given property name.
 *
 * @typeParam Id - The type of item IDs.
 * @typeParam Label - The type of item labels.
 *
 * @param ids - An array of item IDs.
 * @param labels - An array of item labels.
 * @param propName - The name of the property to assign to each object.
 *
 * @returns An array of objects with the given property and ID.
 *
 * @throws `{Error}` If the lengths of the input arrays are different.
 */
export const mapRelatedTuple = <
  Id extends string,
  Label extends string,
  Ids extends Str<Id>[],
  Labels extends Str<Label>[],
>(
  ids: Ids,
  labels: Labels,
  propName: string,
) => {
  if (ids.length !== labels.length) {
    throw new Error('mapRelatedTuple: list lengths are different')
  }

  const mapped = labels.map((label, i) => {
    return {
      [`${propName}` as const]: label,
      id: ids[i],
    }
  })

  return mapped
}

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Maps related fields (IDs and labels) to an array of objects with the specified property name.
 *
 * @typeParam Id - The type of the item IDs.
 * @typeParam Label - The type of the item labels.
 * @typeParam Ids - The type of the array of item IDs.
 * @typeParam Labels - The type of the array of item labels.
 *
 * @param ids - An array of item IDs.
 * @param labels - An array of item labels.
 * @param propName - The name of the property to be added to each object.
 *
 * @returns An array of objects with the specified property name.
 */
export const mapRelatedFields = <
  Id extends string,
  Label extends string,
  Ids extends Str<Id>[],
  Labels extends Str<Label>[],
>(
  ids: Ids,
  labels: Labels,
  propName: string,
) => {
  try {
    const [dedupedIds, dedupedLables] = dedupeRelatedTuple(ids, labels)
    const records = mapRelatedTuple(dedupedIds, dedupedLables, propName)

    return records
  } catch (e) {
    Logger.error(e)

    return []
  }
}
