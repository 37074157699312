/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import type { ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH } from '@/store/actions/activity/entity-for-activity-instance.actions'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchEntitiesForActivityInstance(io: MiddlewaresIO) {
  yield takeEvery(
    Store.ActivityActions.EntityForActivityInstance.TypeKeys.ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH,
    Catch.deferredAction(_ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH, io),
  )
}

export function* _ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH(
  io: MiddlewaresIO,
  action: ENTITIES_FOR_ACTIVITY_INSTANCE_FETCH,
) {
  const { id, options } = action.payload

  // this API only supports the limit and offset query strings
  const query = `?limit=${options?.limit ?? 5}&offset=${options?.offset ?? 0}`

  const response = yield* call(io.api.activities.getEntitiesForActivityInstance, { id, query })

  yield* put(Store.ActivityActions.EntityForActivityInstance.ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE(response, id))

  return response
}
