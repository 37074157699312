/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { pipe } from 'fp-ts/function'
import { chain, Either, left, right } from 'fp-ts/lib/Either'
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const _SafeString = t.string
export type SafeString = t.TypeOf<typeof _SafeString>

// @see: https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it

function encode(str: string): Either<unknown, string> {
  try {
    const _str = unescape(encodeURIComponent(str))

    return right(window.btoa(_str).trim())
  } catch (e) {
    return left(e)
  }
}

function decode(str: string): Either<unknown, string> {
  try {
    return right(decodeURIComponent(escape(window.atob(str))))
  } catch (e) {
    return left(e)
  }
}

export const safeAtob = (str: SafeString) => {
  const base64String = _SafeString.decode(str) // Either<t.Errors, string>

  return pipe(base64String, chain(decode))
}

export const safeBtoa = (str: SafeString) => {
  const decoded = _SafeString.decode(str) // Either<t.Errors, string>

  return pipe(decoded, chain(encode))
}
