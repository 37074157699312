/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { fork } from 'redux-saga/effects'

import { MiddlewaresIO } from '@/store/store.utils'

import { affiliatedNetworkSagasRoot } from '../slices/affiliated-network'
import { assetSagasRoot } from '../slices/asset'
import { detectionSagasRoot } from '../slices/detection/detection.sagas'
import { entitlementSagasRoot } from '../slices/entitlement'
import { exceptionPolicySagasRoot } from '../slices/exception-policy'
import { hostnameSagasRoot } from '../slices/hostname/hostname.sagas'
import { ipSagasRoot } from '../slices/ip/ip.sagas'
import { networkSagasRoot } from '../slices/network/network.sagas'
import { perspectiveSagasRoot } from '../slices/perspective'
import { redirectorForSourceSagasRoot } from '../slices/redirectors-for-source/redirector.sagas'
import { serviceSagasRoot } from '../slices/service/service.sagas'
import { socialSagasRoot } from '../slices/social'
import { sourceSagasRoot } from '../slices/source'
import { targetSagasRoot } from '../slices/target'
import * as ActivitySagas from './activity'
import * as ArtifactSagas from './artifact'
import * as AttackSagas from './attack'
import * as AuthSagas from './auth'
import * as CategorySagas from './category'
import * as CommentSagas from './comment'
import * as EntitySagas from './entity'
import * as GlobalSagas from './global'
import * as HocSagas from './hoc'
import * as MitreSagas from './mitre'
import * as OrganizationSagas from './organization'
import * as PathSagas from './path'
import * as PreferencesSagas from './preferences'
import * as ReconSagas from './recon'
import * as ReportSagas from './report'
import * as SpecSagas from './spec'
import * as StatSagas from './stat'
import * as TagsSagas from './tags'
import * as TrackingSagas from './tracking'
import * as UiSagas from './ui'
import * as UserSagas from './user'
import * as WorkatoSagas from './workato'

// ---------------------------------------------------------------------------

export default function createRootSaga(io: MiddlewaresIO) {
  return function* rootSaga() {
    yield fork(ActivitySagas.watchActivities, io)
    yield fork(ActivitySagas.watchArtifactsForActivityInstance, io)
    yield fork(ActivitySagas.watchEntitiesForActivityInstance, io)
    yield fork(ArtifactSagas.watchArtifact, io)
    yield fork(AttackSagas.watchAttack, io)
    yield fork(AuthSagas.watchAccount, io)
    yield fork(CategorySagas.watchCategory, io)
    yield fork(CommentSagas.watchComment, io)
    yield fork(EntitySagas.watchEntity, io)
    yield fork(GlobalSagas.watchGlobal, io)
    yield fork(HocSagas.watchHocs, io)
    yield fork(MitreSagas.watchMitre, io)
    yield fork(OrganizationSagas.watchOrganization, io)
    yield fork(PathSagas.watchPaths, io)
    yield fork(PreferencesSagas.watchPreferences, io)
    yield fork(ReconSagas.watchRecon, io)
    yield fork(ReportSagas.watchReports, io)
    yield fork(SpecSagas.watchSpec, io)
    yield fork(StatSagas.watch, io)
    yield fork(TagsSagas.watchTags, io)
    yield fork(TrackingSagas.watchTracking, io)
    yield fork(UiSagas.watchUI, io)
    yield fork(UserSagas.watchUser, io)
    yield fork(WorkatoSagas.watchWorkato, io)
    yield fork(affiliatedNetworkSagasRoot, io)
    yield fork(assetSagasRoot, io)
    yield fork(detectionSagasRoot, io)
    yield fork(entitlementSagasRoot, io)
    yield fork(exceptionPolicySagasRoot, io)
    yield fork(hostnameSagasRoot, io)
    yield fork(ipSagasRoot, io)
    yield fork(networkSagasRoot, io)
    yield fork(perspectiveSagasRoot, io)
    yield fork(redirectorForSourceSagasRoot, io)
    yield fork(serviceSagasRoot, io)
    yield fork(socialSagasRoot, io)
    yield fork(sourceSagasRoot, io)
    yield fork(targetSagasRoot, io)
  }
}
