/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from '@/store'
import { TripleUUID } from '@/utilities/codec'

import { makeSelectPagination, makeSelectTotals } from '../utilities/make-selectors'
import { EntityName } from './service.constants'

// ---------------------------------------------------------------------------

export const selectServicePagination = makeSelectPagination(EntityName)
export const selectServiceTotals = makeSelectTotals(EntityName)

export const selectServices = createSelector([(state: AppState) => state.service], (state) => {
  const orderedEntities = state.result.map((uuid) => state.entities.service[uuid])

  return orderedEntities
})

export const selectServiceById = createSelector(
  [
    (state: AppState, _props: { id: TripleUUID }) => state.service.entities,
    (_state: AppState, props: { id: TripleUUID }) => props.id,
  ],
  (entities, uuid) => {
    const entity = entities.service[uuid]

    return entity
  },
)
