/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'

export const Detection: Codecs.DetectionTarget = {
  affiliation_state: 'None',
  applicability: 4,
  attack_note: '',
  authorization_state: 'Authorized',
  authorizing_policies: [],
  banners_data_hash: '6d0f7e38c609c5a3ccfbb32d3165ebedcb4bf7a0',
  banners_uuid: '51d4a6da-160d-42c4-ab26-ba0630f94540',
  cert_data_hash: null,
  cert_uuid: null,
  characteristic_tags: [],
  characteristics_count: 0,
  cpe: null,
  criticality: 3,
  deleted: false,
  detection_authorization_state: 'Authorized',
  target_num_authorized_detections: 0,

  description:
    'Exim is a message transfer agent (MTA) developed at the University of Cambridge for use on Unix systems connected to the Internet.',
  description_source: 'default',
  detection_criteria: {
    ip: {
      address: '0.0.0.0',
      version: 4,
    },
    tcp: {
      port: 25,
    },
  },
  enumerability: 3,
  exploitability: 5,
  first_seen: '2022-12-15T08:00:49.391567+00:00',
  headers_data_hash: null,
  headers_uuid: null,
  hostname: null,
  hostname_id: null,
  id: 'a63777ce-8cba-4552-bb95-40bf36e49289,cd40cb56-784b-4783-a2a2-d52c5049b51c',
  impact_score: Codecs.ImpactScoreEnum.high,
  ip_id: '17dc5dc7-7238-48eb-bfa3-d8647523ec3f',
  ip_str: '0.0.0.0',
  last_seen: '2023-04-09T00:31:14.399950+00:00',
  lens_id: 'a86ef0cf-e350-46c2-8589-0ba8daff40e1',
  lens_view: 'public',
  name: 'Exim',
  org_id: 'b28f017c-c015-4c11-87e0-3e886cdf1b05',
  path: null,
  perspective: '00000000-0000-0000-0000-000000000000',
  perspective_name: 'PUBLIC',
  poc_email: null,
  poc_id: null,
  port: 25,
  post_exploit: 3,
  priority_impact_factor: 0,
  priority_score: 100.5,
  priority_status_factor: 0.1675,
  priority_tags_factor: 0,
  private_weakness: 4,
  protocol: 'tcp',
  public_weakness: 5,
  randori_notes:
    'Exim versions prior to 4.95 are vulnerable to a series of vulnerabilities that are remotely exploitable and could lead to system compromise.\n\nhttps://nvd.nist.gov/vuln/detail/CVE-2022-37452',
  reference: '',
  research: 4,
  screenshot_data_hash: null,
  screenshot_uuid: null,
  service_id: '23b526e9-9ec2-4142-859a-c12aeb1c5525',
  status: Codecs.StatusEnum.needsReview,
  target_first_seen: '2023-03-01T12:33:08.073790+00:00',
  target_id: 'cd40cb56-784b-4783-a2a2-d52c5049b51c',
  target_last_seen: '2023-04-09T01:10:43.926016+00:00',
  target_num_detections: 1,
  target_temptation: 67,
  tech_category: [Codecs.TechCategoryValuesEnum.VPNs],
  thumbnail_data_hash: null,
  thumbnail_uuid: null,
  user_tags: ['g tag', 'Rob Test Tag'],
  validated_vulnerabilities_detection: ['cve-0001-0001'],
  validated_vulnerabilities_detection_count: 1,
  validated_vulnerabilities_target: ['cve-0001-0001'],
  validated_vulnerabilities_target_count: 1,
  vendor: 'Exim',
  version: '4.94.2',
}
