/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

type _PerspectiveName = t.TypeOf<typeof _PerspectiveName>
const _PerspectiveName = t.keyof({
  PUBLIC: null,
  INTERNAL: null,
})

export type PerspectiveName = t.TypeOf<typeof _PerspectiveName>
export const PerspectiveName = t.union([_PerspectiveName, t.null])
