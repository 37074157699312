/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import { TagEntityArgs } from '@/api/tags'
import * as Codecs from '@/codecs'
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  TAGS_FETCH = 'TAGS_FETCH',
  TAG_COUNTS_FETCH = 'TAG_COUNTS_FETCH',
  TAG_COUNTS_STATE_UPDATE = 'TAG_COUNTS_STATE_UPDATE',
  TAG_ENTITY = 'TAG_ENTITY',
  UNTAG_ENTITY = 'UNTAG_ENTITY',
  USER_TAGS_STATE_UPDATE = 'USER_TAGS_STATE_UPDATE',
}

export type ActionTypes = FetchTagCounts | FetchTags | RemoveTagEntity | UpdateTagCountsState | UserTagsStateUpdate

// ---------------------------------------------------------------------------

type FetchTags = {
  type: TypeKeys.TAGS_FETCH
  meta: { deferred: Deferred }
}

const fetchTags = (deferred: Deferred): FetchTags => ({
  type: TypeKeys.TAGS_FETCH,
  meta: { deferred },
})

export type TagEntity = {
  type: TypeKeys.TAG_ENTITY
  meta: { deferred: Deferred }
  payload: TagEntityArgs & { type: ReturnType<typeof EntityUtils.getEndpointByType> }
}

const tagEntity = (payload: TagEntity['payload'], deferred: Deferred): TagEntity => ({
  type: TypeKeys.TAG_ENTITY,
  meta: { deferred },
  payload,
})

export type RemoveTagEntity = {
  type: TypeKeys.UNTAG_ENTITY
  meta: { deferred: Deferred }
  payload: TagEntityArgs & { type: ReturnType<typeof EntityUtils.getEndpointByType> }
}

const untagEntity = (payload: RemoveTagEntity['payload'], deferred: Deferred): RemoveTagEntity => ({
  type: TypeKeys.UNTAG_ENTITY,
  meta: { deferred },
  payload,
})

export const actionCreators = {
  fetchTags,
  tagEntity,
  untagEntity,
}

// ---------------------------------------------------------------------------

export type FetchTagCounts = {
  type: TypeKeys.TAG_COUNTS_FETCH
  meta: { deferred: Deferred }
  payload: CrudQueryUtils.ReconQuery
}

export function TAG_COUNTS_FETCH(payload: FetchTagCounts['payload'], deferred: Deferred): FetchTagCounts {
  return {
    type: TypeKeys.TAG_COUNTS_FETCH,
    meta: { deferred },
    payload,
  }
}

type UpdateTagCountsState = {
  type: TypeKeys.TAG_COUNTS_STATE_UPDATE
  payload: Codecs.TagCountsResponse
}

export function TAG_COUNTS_STATE_UPDATE(payload: UpdateTagCountsState['payload']): UpdateTagCountsState {
  return {
    type: TypeKeys.TAG_COUNTS_STATE_UPDATE,
    payload,
  }
}

// ---------------------------------------------------------------------------

type UserTagsStateUpdate = {
  type: TypeKeys.USER_TAGS_STATE_UPDATE
  payload: string[]
}

export function USER_TAGS_STATE_UPDATE(payload: UserTagsStateUpdate['payload']): UserTagsStateUpdate {
  return {
    type: TypeKeys.USER_TAGS_STATE_UPDATE,
    payload,
  }
}
