/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import pluralize from 'pluralize'

import { capitalize as _capitalize } from '@/utilities/capitalize'

// ---------------------------------------------------------------------------

export type EntityName = 'hostname' | 'ip' | 'network' | 'port' | 'service' | 'social' | 'target'

export const capitalize = _capitalize

const _pluralizeEntityName = (entityName: string, entityCount: number) => {
  const pluralized = pluralize(entityName, entityCount)

  if (pluralized === 'IPS') {
    return 'IPs'
  } else {
    return pluralized
  }
}

const _capitalizeEntityName = (entityName: EntityName) => {
  if (entityName === 'ip') {
    return 'IP'
  } else {
    return capitalize(entityName)
  }
}

// formatEntityName
//
// Capitalizes and pluralizes entity names.
// @see: https://github.com/blakeembrey/pluralize/issues/49
// ---------------------------------------------------------------------------
export const formatEntityName = (entityName: EntityName, entityCount?: number) => {
  if (entityCount !== undefined) {
    return _pluralizeEntityName(_capitalizeEntityName(entityName), entityCount)
  } else {
    return _capitalizeEntityName(entityName)
  }
}

export const Test = {
  _capitalizeEntityName,
  _pluralizeEntityName,
}
