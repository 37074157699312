/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------
export const ApiTokenTypes = ['access_token', 'api_key'] as const
export type ApiTokenTypes = (typeof ApiTokenTypes)[number]

const ApiTokenTypesProjection = ApiTokenTypes.reduce((acc, curr) => {
  acc[curr] = undefined

  return acc
}, {} as Record<ApiTokenTypes, void>)

export type ApiTokenType = t.TypeOf<typeof ApiTokenType>
export const ApiTokenType = t.keyof(ApiTokenTypesProjection)

export const ApiToken = t.type({
  id: t.string,

  apitoken_type: ApiTokenType,
  created_by: t.union([t.string, t.null]),
  created_on: t.union([t.string, t.null]),
  creator_id: t.union([t.string, t.null]),
  label: t.union([t.string, t.null]),
  org_id: t.union([t.string, t.null]),
})

type _ApiToken = t.TypeOf<typeof ApiToken>
export type ApiToken = _ApiToken

export const ApiTokenVisible = t.type({
  id: t.string,
  label: t.string,
  token: t.string,
})

type _ApiTokenVisible = t.TypeOf<typeof ApiTokenVisible>
export type ApiTokenVisible = _ApiTokenVisible

export const ApiTokensResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ApiToken),
})
export type ApiTokensResponse = t.TypeOf<typeof ApiTokensResponse>

export type ApiTokenPayload = {
  data: Array<{
    label: string
    permission_groups: string[]
  }>
}

export const ApiTokenPostResponse = t.type({
  tokens: t.array(ApiTokenVisible),
})
export type ApiTokenPostResponse = t.TypeOf<typeof ApiTokenPostResponse>

export type ApiKeyPayload = {
  label: string
  permission_groups: string[]
}

export const ApiKeyPostResponse = t.type({
  api_key: t.string,
})
export type ApiKeyPostResponse = t.TypeOf<typeof ApiKeyPostResponse>
