/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import * as CrudQuery from '@/utilities/crud-query'

type Deferred = Catch.DeferredAction['meta']['deferred']

// ---------------------------------------------------------------------------

export enum TypeKeys {
  FEATURES_FETCH = 'FEATURES_FETCH',
  FEATURES_UPDATE = 'FEATURES_UPDATE',
  LOGIN_OPTIONS_PATCH = 'LOGIN_OPTIONS_PATCH',
  ORGANIZATION_CREATE = 'ORGANIZATION_CREATE',
  ORGANIZATIONS_GET = 'ORGANIZATIONS_GET',
  ORGANIZATIONS_UPDATE = 'ORGANIZATIONS_UPDATE',
  ORGANIZATION_GET = 'ORGANIZATION_GET',
  ORGANIZATION_PATCH = 'ORGANIZATION_PATCH',
  ORGANIZATIONS_PATCH = 'ORGANIZATIONS_PATCH',
  ORGANIZATIONS_PAGINATION_UPDATE = 'ORGANIZATIONS_PAGINATION_UPDATE',
  ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE',
  SSO_CONFIG_FETCH = 'SSO_CONFIG_FETCH',
  SSO_CONFIG_POST = 'SSO_CONFIG_POST',
  SSO_CONFIG_XML_POST = 'SSO_CONFIG_XML_POST',
  SSO_PROVIDERS_STORE_UPDATE = 'SSO_PROVIDERS_STORE_UPDATE',
  SSO_PROVIDERS_STORE_UPDATE_SINGLE = 'SSO_PROVIDERS_STORE_UPDATE_SINGLE',
  USERS_FETCH = 'USERS_FETCH',
  USERS_PAGINATION_UPDATE = 'USERS_PAGINATION_UPDATE',
  USERS_UPDATE = 'USERS_UPDATE',
  USER_ROLES_FETCH = 'USER_ROLES_FETCH',
  USER_ROLES_POST = 'USER_ROLES_POST',
  USER_ROLES_UPDATE = 'USER_ROLES_UPDATE',
  USER_ROLE_OPTIONS_FETCH = 'USER_ROLE_OPTIONS_FETCH',
}

export type ActionTypes =
  | ORGANIZATION_CREATE
  | FetchFeatures
  | USER_ROLE_OPTIONS_FETCH
  | USER_ROLES_FETCH
  | ORGANIZATION_GET
  | ORGANIZATIONS_GET
  | LOGIN_OPTIONS_PATCH
  | SSO_CONFIG_FETCH
  | SSO_CONFIG_POST
  | SSO_CONFIG_XML_POST
  | SSO_PROVIDERS_STORE_UPDATE
  | SSO_PROVIDERS_STORE_UPDATE_SINGLE
  | USERS_FETCH
  | USER_ROLES_POST
  | UpdateFeatures
  | UpdateOrganization
  | UpdateOrganizations
  | ORGANIZATIONS_PAGINATION_UPDATE
  | UpdateUserRoles
  | UpdateUsers
  | UpdateUsersPagination

// Pure
// ---------------------------------------------------------------------------

export type ORGANIZATION_CREATE = {
  type: TypeKeys.ORGANIZATION_CREATE
  meta: { deferred: Deferred }
  payload: {
    data: Codecs.PostOrgPayload['data'] & { poc: string }
  }
}

export const ORGANIZATION_CREATE = (
  payload: ORGANIZATION_CREATE['payload'],
  deferred: Deferred,
): ORGANIZATION_CREATE => {
  return {
    type: TypeKeys.ORGANIZATION_CREATE,
    meta: { deferred },
    payload,
  }
}

export type ORGANIZATIONS_GET = {
  type: TypeKeys.ORGANIZATIONS_GET
  meta: { deferred: Deferred }
  payload: string
}

export const getOrganizations = (payload: ORGANIZATIONS_GET['payload'], deferred: Deferred): ORGANIZATIONS_GET => ({
  type: TypeKeys.ORGANIZATIONS_GET,
  meta: { deferred },
  payload,
})

type UpdateOrganizations = {
  type: TypeKeys.ORGANIZATIONS_UPDATE
  payload: Codecs.Organization[]
}

export const ORGANIZATIONS_UPDATE = (payload: UpdateOrganizations['payload']): UpdateOrganizations => {
  return {
    type: TypeKeys.ORGANIZATIONS_UPDATE,
    payload,
  }
}

export type ORGANIZATION_GET = {
  type: TypeKeys.ORGANIZATION_GET
  meta: { deferred: Deferred }
}

export const getOrganization = (deferred: Deferred): ORGANIZATION_GET => ({
  type: TypeKeys.ORGANIZATION_GET,
  meta: { deferred },
})

type UpdateOrganization = {
  type: TypeKeys.ORGANIZATION_UPDATE
  payload: Codecs.Organization
}

export const ORGANIZATION_UPDATE = (payload: UpdateOrganization['payload']): UpdateOrganization => {
  return {
    type: TypeKeys.ORGANIZATION_UPDATE,
    payload,
  }
}

export type ORGANIZATIONS_PAGINATION_UPDATE = {
  type: TypeKeys.ORGANIZATIONS_PAGINATION_UPDATE
  payload: {
    count: number
    offset: number
    total: number
  }
}

export function ORGANIZATIONS_PAGINATION_UPDATE(
  payload: ORGANIZATIONS_PAGINATION_UPDATE['payload'],
): ORGANIZATIONS_PAGINATION_UPDATE {
  return {
    type: TypeKeys.ORGANIZATIONS_PAGINATION_UPDATE,
    payload,
  }
}

type FetchFeatures = {
  type: TypeKeys.FEATURES_FETCH
  meta: { deferred: Deferred }
}

export const FEATURES_FETCH = (deferred: Deferred): FetchFeatures => ({
  type: TypeKeys.FEATURES_FETCH,
  meta: { deferred },
})

export type UpdateFeatures = {
  type: TypeKeys.FEATURES_UPDATE
  payload: Codecs.Feature[]
}

export const FEATURES_UPDATE = (payload: UpdateFeatures['payload']): UpdateFeatures => {
  return {
    type: TypeKeys.FEATURES_UPDATE,
    payload,
  }
}

export type USERS_FETCH = {
  type: TypeKeys.USERS_FETCH
  meta: { deferred: Deferred }
  payload: ReturnType<typeof CrudQuery.parseQuery>
  updateStore: boolean
}

export const USERS_FETCH = (payload: USERS_FETCH['payload'], deferred: Deferred, updateStore = true): USERS_FETCH => ({
  type: TypeKeys.USERS_FETCH,
  meta: { deferred },
  payload,
  updateStore,
})

export type UpdateUsers = {
  type: TypeKeys.USERS_UPDATE
  payload: Codecs.User[]
}

export const USERS_UPDATE = (payload: UpdateUsers['payload']): UpdateUsers => {
  return {
    type: TypeKeys.USERS_UPDATE,
    payload,
  }
}

type UpdateUsersPagination = {
  type: TypeKeys.USERS_PAGINATION_UPDATE
  payload: {
    count: number
    offset: number
    total: number
  }
}

export function USERS_PAGINATION_UPDATE(payload: UpdateUsersPagination['payload']): UpdateUsersPagination {
  return {
    type: TypeKeys.USERS_PAGINATION_UPDATE,
    payload,
  }
}

export type USER_ROLES_FETCH = {
  type: TypeKeys.USER_ROLES_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export const USER_ROLES_FETCH = (payload: USER_ROLES_FETCH['payload'], deferred: Deferred): USER_ROLES_FETCH => ({
  type: TypeKeys.USER_ROLES_FETCH,
  meta: { deferred },
  payload,
})

export type UpdateUserRoles = {
  type: TypeKeys.USER_ROLES_UPDATE
  payload: Codecs.PermissionGroupsResponse
}

export const USER_ROLES_UPDATE = (payload: UpdateUserRoles['payload']): UpdateUserRoles => ({
  type: TypeKeys.USER_ROLES_UPDATE,
  payload,
})

export type USER_ROLES_POST = {
  type: TypeKeys.USER_ROLES_POST
  payload: Codecs.PermissionGroupsPayload
  meta: {
    deferred: Deferred
    cancelRefetch?: boolean
  }
}

export const USER_ROLES_POST = (
  payload: USER_ROLES_POST['payload'],
  deferred: Deferred,
  cancelRefetch?: boolean,
): USER_ROLES_POST => ({
  type: TypeKeys.USER_ROLES_POST,
  payload,
  meta: {
    deferred,
    cancelRefetch,
  },
})

export type USER_ROLE_OPTIONS_FETCH = {
  type: TypeKeys.USER_ROLE_OPTIONS_FETCH
  meta: { deferred: Deferred }
}

export const USER_ROLE_OPTIONS_FETCH = (deferred: Deferred): USER_ROLE_OPTIONS_FETCH => ({
  type: TypeKeys.USER_ROLE_OPTIONS_FETCH,
  meta: { deferred },
})

export type ORGANIZATION_PATCH = {
  type: TypeKeys.ORGANIZATION_PATCH
  meta: { deferred: Deferred }
  payload: Codecs.PatchOrganizationPayload
}

export const ORGANIZATION_PATCH = (payload: ORGANIZATION_PATCH['payload'], deferred: Deferred): ORGANIZATION_PATCH => ({
  type: TypeKeys.ORGANIZATION_PATCH,
  meta: { deferred },
  payload,
})

export type ORGANIZATIONS_PATCH = {
  type: TypeKeys.ORGANIZATIONS_PATCH
  meta: { deferred: Deferred }
  payload: {
    id: string
    body: Codecs.PatchOrganizationPayload
  }
}

export const ORGANIZATIONS_PATCH = (
  payload: ORGANIZATIONS_PATCH['payload'],
  deferred: Deferred,
): ORGANIZATIONS_PATCH => ({
  type: TypeKeys.ORGANIZATIONS_PATCH,
  meta: { deferred },
  payload,
})

export type LOGIN_OPTIONS_PATCH = {
  type: TypeKeys.LOGIN_OPTIONS_PATCH
  meta: { deferred: Deferred }
  payload: any // @TODO: Replace `any`
}

export const LOGIN_OPTIONS_PATCH = (
  payload: LOGIN_OPTIONS_PATCH['payload'],
  deferred: Deferred,
): LOGIN_OPTIONS_PATCH => ({
  type: TypeKeys.LOGIN_OPTIONS_PATCH,
  meta: { deferred },
  payload,
})

export type SSO_CONFIG_POST = {
  type: TypeKeys.SSO_CONFIG_POST
  meta: { deferred: Deferred }
  payload: any // @TODO: Replace `any`
}

export const SSO_CONFIG_POST = (payload: SSO_CONFIG_POST['payload'], deferred: Deferred): SSO_CONFIG_POST => ({
  type: TypeKeys.SSO_CONFIG_POST,
  meta: { deferred },
  payload,
})

export type SSO_CONFIG_FETCH = {
  type: TypeKeys.SSO_CONFIG_FETCH
  meta: { deferred: Deferred }
}

export const SSO_CONFIG_FETCH = (deferred: Deferred): SSO_CONFIG_FETCH => ({
  type: TypeKeys.SSO_CONFIG_FETCH,
  meta: { deferred },
})

export type SSO_CONFIG_XML_POST = {
  type: TypeKeys.SSO_CONFIG_XML_POST
  meta: { deferred: Deferred }
  payload:
    | { providerId: Codecs.ProviderId; metadata_xml_str: string }
    | { providerId: Codecs.ProviderId; fromUrl: string }
}

export const SSO_CONFIG_XML_POST = (
  payload: SSO_CONFIG_XML_POST['payload'],
  deferred: Deferred,
): SSO_CONFIG_XML_POST => ({
  type: TypeKeys.SSO_CONFIG_XML_POST,
  meta: { deferred },
  payload,
})

export type SSO_PROVIDERS_STORE_UPDATE = {
  type: TypeKeys.SSO_PROVIDERS_STORE_UPDATE
  payload: Codecs.SsoProvidersResponse
}

export function SSO_PROVIDERS_STORE_UPDATE(payload: SSO_PROVIDERS_STORE_UPDATE['payload']): SSO_PROVIDERS_STORE_UPDATE {
  return {
    type: TypeKeys.SSO_PROVIDERS_STORE_UPDATE,
    payload,
  }
}

export type SSO_PROVIDERS_STORE_UPDATE_SINGLE = {
  type: TypeKeys.SSO_PROVIDERS_STORE_UPDATE_SINGLE
  payload: Codecs.SsoProvidersResponse['sso_connections'][0]
}

export function SSO_PROVIDERS_STORE_UPDATE_SINGLE(
  payload: SSO_PROVIDERS_STORE_UPDATE_SINGLE['payload'],
): SSO_PROVIDERS_STORE_UPDATE_SINGLE {
  return {
    type: TypeKeys.SSO_PROVIDERS_STORE_UPDATE_SINGLE,
    payload,
  }
}
