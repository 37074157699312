/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'
import { Response, Server } from 'miragejs'

import { ActivityConfiguration } from '@/codecs'

import { ACTIVITY_CONFIGURATIONS_URL } from '../constants'

export const activityRoutes = (server: Server): void => {
  // for loading table data
  server.get(ACTIVITY_CONFIGURATIONS_URL, (schema, request) => {
    const allData = schema.db.activityConfigurations
    const totalItems = allData.length

    const responseMeta = {
      offset: 0,
      count: totalItems,
      total: totalItems,
    }

    const responseLinks = {
      self: request.url,
      next: '',
      last: '',
    }

    if (isNotNilOrEmpty(allData)) {
      return {
        data: allData,
        meta: responseMeta,
        links: responseLinks,
      }
    }

    return new Response(404, {}, { errors: ['Resource not found'] })
  })

  // for loading an individual detail page
  server.get(`${ACTIVITY_CONFIGURATIONS_URL}/:id`, (schema, request) => {
    const id = request.params.id

    const configuration = schema.db.activityConfigurations.find(id) as ActivityConfiguration

    const responseLinks = {
      self: request.url,
      next: '',
      last: '',
    }

    if (isNotNilOrEmpty(configuration)) {
      return {
        data: configuration,
        links: responseLinks,
      }
    }

    return new Response(404, {}, { errors: ['Resource not found'] })
  })
}
