/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as EntityUtils from '@/utilities/r-entity'
import { ExhaustiveError } from '@/utilities/r-error'

import * as SliceDefaults from './reducer-defaults'

// ---------------------------------------------------------------------------

export const getInitialState = () => {
  return {
    activity_configuration: getInitialStatePerTable('activity_configuration'),
    activity_instance: getInitialStatePerTable('activity_instance'),
    asset: getInitialStatePerTable('asset'),
    bdo_detection: getInitialStatePerTable('bdo_detection'),
    detection_target: getInitialStatePerTable('detection_target'),
    exception_policy: getInitialStatePerTable('exception_policy'),
    hostname: getInitialStatePerTable('hostname'),
    implant: getInitialStatePerTable('implant'),
    ip: getInitialStatePerTable('ip'),
    network: getInitialStatePerTable('network'),
    redirector: getInitialStatePerTable('redirector'),
    runbook: getInitialStatePerTable('runbook'),
    service: getInitialStatePerTable('service'),
    social: getInitialStatePerTable('social'),
    source: getInitialStatePerTable('source'),
    target: getInitialStatePerTable('target'),

    // TICKET TO FIX: https://randori.atlassian.net/browse/EN-9687
    // entity_for_activity_instance: getInitialStatePerTable('entity_for_activity_instance'),

    // static tables
    authorization_policy: getInitialStatePerTable('authorization_policy'),
    policy: getInitialStatePerTable('policy'),
    report: getInitialStatePerTable('report'),
    savedViews: getInitialStatePerTable('savedViews'),

    // @TODO: revisit this
    topLevelDetection: getInitialStatePerTable('detection_target'),
  }
}

/**
 * Given an entity table id, we return our best guess at what columns the user would expect to see upon loading an entity page.
 * This function is meant to be a fail-safe in case the open api fetch does not complete successfully (a table will still be rendered).
 *
 * @param tableId - EntityType
 *
 * @returns an object containing column data for a specific table
 *
 * @remarks If the open api fetch completes and a user's column configurations are different than what we predicted,
 * the user is likely to see the table columns in the UI flash and be replaced with a configuration different than what
 * it was loaded with initially.
 *
 * This switch case serves as the source of truth for which fields are the default fields for each entity table.
 * All required (isEditable === false) fields should be in the beginning of the list.
 * Meta-data for each field can be found in `src/utilities/randapispec/field-map.ts`
 */
export const getInitialStatePerTable = (tableId: EntityUtils.EntityTypesTableSummary) => {
  switch (tableId) {
    // TICKET TO FIX: https://randori.atlassian.net/browse/EN-9687
    // case 'entity_for_activity_instance': {
    //   return SliceDefaults.entityForActivityInstance(tableId)
    // }

    case 'activity_instance': {
      return SliceDefaults.activityInstance(tableId)
    }

    case 'activity_configuration': {
      return SliceDefaults.activityConfiguration(tableId)
    }

    case 'asset': {
      return SliceDefaults.asset(tableId)
    }

    case 'detection_target': {
      return SliceDefaults.detectionTarget(tableId)
    }

    case 'exception_policy': {
      return SliceDefaults.exceptionPolicy(tableId)
    }

    case 'hostname': {
      return SliceDefaults.hostname(tableId)
    }

    case 'implant': {
      return SliceDefaults.implant(tableId)
    }

    case 'ip': {
      return SliceDefaults.ip(tableId)
    }

    case 'network': {
      return SliceDefaults.network(tableId)
    }

    case 'redirector': {
      return SliceDefaults.redirector(tableId)
    }

    case 'runbook': {
      return SliceDefaults.runbook(tableId)
    }

    case 'service': {
      return SliceDefaults.service(tableId)
    }

    case 'social': {
      return SliceDefaults.social(tableId)
    }

    case 'source': {
      return SliceDefaults.source(tableId)
    }

    case 'target': {
      return SliceDefaults.target(tableId)
    }

    // Static tables

    case 'policy': {
      return SliceDefaults.policy(tableId)
    }

    case 'report': {
      return SliceDefaults.report(tableId)
    }

    case 'savedViews': {
      return SliceDefaults.savedView(tableId)
    }

    case 'authorization_policy': {
      return SliceDefaults.authorizationPolicy(tableId)
    }

    case 'bdo_detection': {
      return SliceDefaults.detectionP(tableId)
    }

    default: {
      throw new ExhaustiveError(tableId)
    }
  }
}

export const getDefaultColumns = (tableId: EntityUtils.EntityTypesTableSummary) => {
  const initialState = getInitialStatePerTable(tableId)

  return initialState.columns.map((column) => column.fieldName)
}
