/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const Feature = t.interface({
  end_time_utc: t.union([t.string, t.null]), // @TODO: iso string
  feature_uuid: t.union([t.string, t.null]),
  name: t.union([t.string, t.null]),
  org_uuid: t.union([t.string, t.null]),
  start_time_utc: t.union([t.string, t.null]), // @TODO: iso string
  type: t.union([t.string, t.null]),
})

// @see: https://lorefnon.tech/2018/03/25/typescript-and-validations-at-runtime-boundaries
// However because typescript compiler aggressively flattens type aliases, the
// error messages can sometimes become unwieldy for large complex types. Note
// that the error tooltip above does not contain the name IPerson.  A simple
// solution is to define an interface which extends from the generated type.

type _Feature = t.TypeOf<typeof Feature>
export type Feature = _Feature
