/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import axios from 'axios'
import debug from 'debug'
import { isNil } from 'lodash/fp'

import { getCookieByName } from '@/utilities/crud-query'
import * as HistoryUtils from '@/utilities/history'
import * as Logger from '@/utilities/logger'

import { HttpClient } from './types'

// ---------------------------------------------------------------------------

const logRenewal = debug('RANDORI:http-factory')

// Set a sentinel value that acts as a singleton, due to import.
//
// @TODO: This seems risky, and could possibly depending on build-time
// behavior. Consider refactoring to a class based singleton.
//
// @see: https://github.com/RandoriDev/web-client/blob/main/src/utilities/tracer/index.ts#L43
let RENEW_LOCKED = false

/**
 * If a request fails due to a 401, we'll hit the login endpoint again, which ought to return a session cookie.
 *
 * @remarks
 * We don't care about the actual response returned by the login endpoint, just the cookie
 *
 * @param log - logging function
 *
 * @returns nothing
 */
export const tryRenewal = (
  http: HttpClient = axios,
  getCookie = getCookieByName,
  logReporter: (msg: string) => void = logRenewal,
  errorReporter = Logger.error,
) => {
  const LOGOUT_URL = '/auth/api/v1/logout'
  const RENEW_URL = '/auth/api/v1/renew'
  const authorizationCookie = getCookie('authorization')
  const isUnlocked = !RENEW_LOCKED

  // Prevent dispatching renewal request if cookie is already set
  if (isUnlocked && isNil(authorizationCookie)) {
    RENEW_LOCKED = true

    return http
      .post(RENEW_URL, {})
      .then(() => {
        RENEW_LOCKED = false

        logReporter('Renewal success')
      })
      .catch((err) => {
        errorReporter(err)
        logReporter('Renewal fail')

        return http
          .post(LOGOUT_URL, {})
          .then(() => {
            RENEW_LOCKED = false

            logReporter('Expiring session')

            HistoryUtils.history.push('/login', { sessionExpired: true })
          })
          .catch(() => {
            RENEW_LOCKED = false

            logReporter('Expiring session')

            HistoryUtils.history.push('/login', { sessionExpired: true })
          })
      })
  }
}
