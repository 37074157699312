/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-use-before-define */
import { isNotNil } from '@randori/rootkit'
import { filter, find, head, isNil } from 'lodash/fp'
import qs from 'query-string'
import * as Catch from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _AttackActions from '@/store/actions/attack'
import { MiddlewaresIO } from '@/store/store.utils'
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as QueryFilterUtils from '@/utilities/query-filter-utils'

// ---------------------------------------------------------------------------

export function* watchAttack(io: MiddlewaresIO) {
  yield takeEvery(
    Store.AttackActions.TypeKeys.ACTIONS_BY_RUNBOOK_FETCH,
    Catch.deferredAction(_ACTIONS_BY_RUNBOOK_FETCH, io),
  )
  yield takeEvery(Store.AttackActions.TypeKeys.ACTION_DELETE, Catch.deferredAction(_ACTION_DELETE, io))
  yield takeEvery(Store.AttackActions.TypeKeys.ACTION_PATCH, Catch.deferredAction(_ACTION_PATCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.ATTACK_ACTIONS_FETCH, Catch.deferredAction(_ATTACK_ACTIONS_FETCH, io))
  yield takeEvery(
    Store.AttackActions.TypeKeys.ATTACK_ACTION_TOTALS_FETCH,
    Catch.deferredAction(_ATTACK_ACTION_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.AttackActions.TypeKeys.IMPLANTS_FETCH, Catch.deferredAction(_IMPLANTS_FETCH, io))
  yield takeEvery(
    Store.AttackActions.TypeKeys.IMPLANT_CALLBACKS_FETCH,
    Catch.deferredAction(_IMPLANT_CALLBACKS_FETCH, io),
  )
  yield takeEvery(Store.AttackActions.TypeKeys.IMPLANT_FETCH, Catch.deferredAction(_IMPLANT_FETCH, io))
  yield takeEvery(
    Store.AttackActions.TypeKeys.IMPLANT_INTERFACES_FETCH,
    Catch.deferredAction(_IMPLANT_INTERFACES_FETCH, io),
  )
  yield takeEvery(Store.AttackActions.TypeKeys.IMPLANT_STATS_FETCH, Catch.deferredAction(_IMPLANT_STATS_FETCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.IMPLANT_TOTALS_FETCH, Catch.deferredAction(_IMPLANT_TOTALS_FETCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.REDIRECTORS_FETCH, Catch.deferredAction(_REDIRECTORS_FETCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.REDIRECTOR_FETCH, Catch.deferredAction(_REDIRECTOR_FETCH, io))
  yield takeEvery(
    Store.AttackActions.TypeKeys.REDIRECTOR_TOTALS_FETCH,
    Catch.deferredAction(_REDIRECTOR_TOTALS_FETCH, io),
  )
  yield takeEvery(Store.AttackActions.TypeKeys.RUNBOOKS_FETCH, Catch.deferredAction(_RUNBOOKS_FETCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.RUNBOOK_DELETE, Catch.deferredAction(_RUNBOOK_DELETE, io))
  yield takeEvery(Store.AttackActions.TypeKeys.RUNBOOK_DESC_FETCH, Catch.deferredAction(_RUNBOOK_DESC_FETCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.RUNBOOK_FETCH, Catch.deferredAction(_RUNBOOK_FETCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.RUNBOOK_PATCH, Catch.deferredAction(_RUNBOOK_PATCH, io))
  yield takeEvery(Store.AttackActions.TypeKeys.RUNBOOK_TOTALS_FETCH, Catch.deferredAction(_RUNBOOK_TOTALS_FETCH, io))
}

// ---------------------------------------------------------------------------

export function* _IMPLANT_STATS_FETCH(io: MiddlewaresIO) {
  const __A_WEEK_AGO_SECONDS__ = 604800

  const current = {
    condition: 'AND',
    rules: [
      {
        condition: 'OR',
        rules: [
          {
            field: 'table.name',
            operator: 'equal',
            value: 'implants_total_alive',
          },
          {
            field: 'table.name',
            operator: 'equal',
            value: 'implants_checking_in',
          },
          {
            field: 'table.name',
            operator: 'equal',
            value: 'implants_delayed',
          },
        ],
      },
      {
        field: 'table.current',
        operator: 'equal',
        value: true,
      },
    ],
  }

  const deltaQ = {
    condition: 'OR',
    rules: [
      {
        condition: 'AND',
        rules: [
          {
            field: 'table.time',
            operator: 'greater_utc_seconds_ago',
            value: __A_WEEK_AGO_SECONDS__,
          },
          {
            field: 'table.name',
            operator: 'equal',
            value: 'implants_total_alive',
          },
        ],
      },
    ],
  }

  const serializedCurrentQ = CrudQueryUtils.serializeQ({ condition: 'OR', rules: [current] })

  const currentResponse = yield* call(io.api.attack.getImplantStats, qs.stringify({ q: serializedCurrentQ }))

  const serializedDeltaQ = CrudQueryUtils.serializeQ(deltaQ)

  const deltaResponse = yield* call(
    io.api.attack.getImplantStats,
    qs.stringify({ q: serializedDeltaQ, sort: 'time', limit: 1 }),
  )

  const response = {
    count: 0,
    offset: 0,
    total: 0,
    data: [...currentResponse.data, ...deltaResponse.data],
  }

  yield* put(Store.AttackActions.IMPLANT_STATS_STORE_UPDATE(response))

  return response
}

export function* _IMPLANTS_FETCH(io: MiddlewaresIO, action: _AttackActions.IMPLANTS_FETCH) {
  const result = yield* call(io.api.attack.getImplants, action.payload)

  yield* put(Store.AttackActions.IMPLANTS_STORE_UPDATE(result))

  return result
}

// @TODO Source - will either need source version of this or just use the results.length of SOURCES_FETCH since there will be no filtering
export function* _IMPLANT_TOTALS_FETCH(io: MiddlewaresIO, action: _AttackActions.IMPLANT_TOTALS_FETCH) {
  const { total } = yield* call(io.api.attack.getImplants, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.AttackActions.IMPLANT_TOTALS_STORE_UPDATE(totals))

  return {
    total,
  }
}

export function* _REDIRECTORS_FETCH(io: MiddlewaresIO, action: _AttackActions.REDIRECTORS_FETCH) {
  const result = yield* call(io.api.attack.getRedirectors, action.payload)

  yield* put(Store.AttackActions.REDIRECTORS_STORE_UPDATE(result))

  return result
}

export function* _REDIRECTOR_TOTALS_FETCH(io: MiddlewaresIO, action: _AttackActions.REDIRECTOR_TOTALS_FETCH) {
  const { total } = yield* call(io.api.attack.getRedirectors, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.AttackActions.REDIRECTOR_TOTALS_STORE_UPDATE(totals))

  return {
    total,
  }
}

export function* _RUNBOOKS_FETCH(io: MiddlewaresIO, action: _AttackActions.RUNBOOKS_FETCH) {
  const { q = '', ...rest } = CrudQueryUtils.parseQuery(action.payload)
  const initial = CrudQueryUtils.unserializeQ(q)

  const getNonStandardRules = filter<CrudQueryUtils.CrudRuleGroup>((rule) => {
    return rule.ui_id !== QueryFilterUtils.UI_ID_STANDARD
  })

  const getStandardRules = find<CrudQueryUtils.CrudRuleGroup>((rule) => {
    return rule.ui_id === QueryFilterUtils.UI_ID_STANDARD
  })

  const standardRules = getStandardRules(initial.rules)

  if (isNil(standardRules)) {
    throw new Error(`RuleGroup is missing ${QueryFilterUtils.UI_ID_STANDARD}`)
  }

  const modifiedQ = CrudQueryUtils.serializeQ({
    ...initial,
    rules: [
      ...getNonStandardRules(initial.rules),
      {
        ...standardRules,
        rules: [
          ...standardRules.rules,
          {
            id: 'table.deleted',
            field: 'table.deleted',
            operator: 'equal',
            value: false,
          },
        ],
      },
    ],
  })

  const queryString = qs.stringify({ q: modifiedQ, ...rest })

  const result = yield* call(io.api.attack.getRunbooks, `?${queryString}`)

  yield* put(Store.AttackActions.RUNBOOKS_STORE_UPDATE(result))

  return result
}

export function* _RUNBOOK_TOTALS_FETCH(io: MiddlewaresIO, action: _AttackActions.RUNBOOK_TOTALS_FETCH) {
  const { q = '', ...rest } = CrudQueryUtils.parseQuery(action.payload)

  const initial = CrudQueryUtils.unserializeQ(q)

  const getNonStandardRules = filter<CrudQueryUtils.CrudRuleGroup>((rule) => {
    return rule.ui_id !== QueryFilterUtils.UI_ID_STANDARD
  })

  const getStandardRules = find<CrudQueryUtils.CrudRuleGroup>((rule) => {
    return rule.ui_id === QueryFilterUtils.UI_ID_STANDARD
  })

  const standardRules = getStandardRules(initial.rules)

  if (isNil(standardRules)) {
    throw new Error(`RuleGroup is missing ${QueryFilterUtils.UI_ID_STANDARD}`)
  }

  const modifiedQ = CrudQueryUtils.serializeQ({
    ...initial,
    rules: [
      ...getNonStandardRules(initial.rules),
      {
        ...standardRules,
        rules: [
          ...standardRules.rules,
          {
            id: 'table.deleted',
            field: 'table.deleted',
            operator: 'equal',
            value: false,
          },
        ],
      },
    ],
  })

  const queryString = qs.stringify({ q: modifiedQ, ...rest })

  const { total } = yield* call(io.api.attack.getRunbooks, `?${queryString}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.AttackActions.RUNBOOK_TOTALS_STORE_UPDATE(totals))

  return {
    total,
  }
}

export function* _IMPLANT_FETCH(io: MiddlewaresIO, action: _AttackActions.IMPLANT_FETCH) {
  const result = yield* call(io.api.attack.getImplant, action.payload.id)

  yield* put(Store.AttackActions.IMPLANT_STORE_UPDATE(result))

  return result
}

export function* _REDIRECTOR_FETCH(io: MiddlewaresIO, action: _AttackActions.REDIRECTOR_FETCH) {
  const q = {
    condition: 'AND',
    rules: [
      {
        field: 'table.id',
        id: 'table.id',
        input: 'text',
        operator: 'equal',
        randoriOnly: true,
        type: 'string',
        ui_id: 'id',
        value: action.payload.id,
      },
    ],
  }

  const serializedQuery = `?${qs.stringify({ q: CrudQueryUtils.serializeQ(q) })}`

  const result = yield* call(io.api.attack.getRedirectors, serializedQuery)

  const redirector = head(result.data)

  if (isNotNil(redirector)) {
    yield* put(Store.AttackActions.REDIRECTOR_STORE_UPDATE(redirector))
  }

  return redirector
}

export function* _IMPLANT_CALLBACKS_FETCH(io: MiddlewaresIO, action: _AttackActions.IMPLANT_CALLBACKS_FETCH) {
  const q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.implant_id',
        field: 'table.implant_id',
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: action.payload.id,
      },
    ],
  }

  const queryString = `?${qs.stringify({
    q: CrudQueryUtils.serializeQ(q),
    limit: 10,
    sort: action.payload.sort,
    offset: action.payload.offset,
  })}`

  const result = yield* call(io.api.attack.getCallbacksForImplant, queryString)

  yield* put(Store.AttackActions.IMPLANT_CALLBACKS_STORE_UPDATE(result))

  return result
}

export function* _IMPLANT_INTERFACES_FETCH(io: MiddlewaresIO, action: _AttackActions.IMPLANT_INTERFACES_FETCH) {
  const q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.implant_id',
        field: 'table.implant_id',
        type: 'string',
        input: 'text',
        operator: 'equal',
        value: action.payload.id,
      },
    ],
  }

  const queryString = `?${qs.stringify({
    q: CrudQueryUtils.serializeQ(q),
    limit: 10,
    sort: action.payload.sort,
    offset: action.payload.offset,
  })}`

  const result = yield* call(io.api.attack.getInterfacesForImplant, queryString)

  yield* put(Store.AttackActions.IMPLANT_INTERFACES_STORE_UPDATE(result))

  return result
}

export function* _RUNBOOK_DESC_FETCH(io: MiddlewaresIO, action: _AttackActions.RUNBOOK_DESC_FETCH) {
  const q = {
    condition: 'AND',
    rules: [
      {
        id: 'table.runbook_id',
        field: 'table.runbook_id',
        type: 'object',
        input: 'text',
        operator: 'equal',
        value: action.payload,
      },
    ],
  }

  const result = yield* call(io.api.attack.getRunbookDescription, qs.stringify({ q: CrudQueryUtils.serializeQ(q) }))

  return result
}

export function* _ATTACK_ACTIONS_FETCH(io: MiddlewaresIO, action: _AttackActions.ATTACK_ACTIONS_FETCH) {
  const result = yield* call(io.api.attack.getAttackActions, action.payload)

  yield* put(Store.AttackActions.ATTACK_ACTIONS_STORE_UPDATE(result))

  return result
}

export function* _ATTACK_ACTION_TOTALS_FETCH(io: MiddlewaresIO) {
  const { total } = yield* call(io.api.attack.getAttackActions, `?${qs.stringify({ limit: 0 })}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.AttackActions.ATTACK_ACTION_TOTALS_STORE_UPDATE(totals))

  return {
    total,
  }
}

export function* _ACTIONS_BY_RUNBOOK_FETCH(io: MiddlewaresIO, action: _AttackActions.ACTIONS_BY_RUNBOOK_FETCH) {
  const { q = '', ...rest } = CrudQueryUtils.parseQuery(action.payload.queryString)
  const initial = CrudQueryUtils.unserializeQ(q)

  const getNonStandardRules = filter<CrudQueryUtils.CrudRuleGroup>((rule) => {
    return rule.ui_id !== QueryFilterUtils.UI_ID_STANDARD
  })

  const getStandardRules = find<CrudQueryUtils.CrudRuleGroup>((rule) => {
    return rule.ui_id === QueryFilterUtils.UI_ID_STANDARD
  })

  const standardRules = getStandardRules(initial.rules)

  if (isNil(standardRules)) {
    throw new Error(`RuleGroup is missing ${QueryFilterUtils.UI_ID_STANDARD}`)
  }

  const modifiedQ = CrudQueryUtils.serializeQ({
    ...initial,
    rules: [
      ...getNonStandardRules(initial.rules),
      {
        ...standardRules,
        rules: [
          ...standardRules.rules,
          {
            id: 'table.deleted',
            field: 'table.deleted',
            operator: 'equal',
            value: false,
          },
        ],
      },
    ],
  })

  const queryString = qs.stringify({ q: modifiedQ, ...rest })

  const result = yield* call(io.api.attack.getAttackActions, `?${queryString}`)

  yield* put(
    Store.AttackActions.ACTIONS_BY_RUNBOOK_STORE_UPDATE({
      response: result,
      runbookId: action.payload.runbookId,
    }),
  )

  return result
}

export function* _ACTION_PATCH(io: MiddlewaresIO, action: _AttackActions.ACTION_PATCH) {
  const { id, ...updatedFields } = action.payload

  const result = yield* call(io.api.attack.patchAction, id, { data: updatedFields })

  const updated = {
    ...result.data,
    id,
  }

  yield* put(Store.AttackActions.ACTION_BY_RUNBOOK_STORE_UPDATE(updated))

  return result
}

export function* _RUNBOOK_PATCH(io: MiddlewaresIO, action: _AttackActions.RUNBOOK_PATCH) {
  const { id, ...updatedFields } = action.payload

  const result = yield* call(io.api.attack.patchRunbook, id, { data: updatedFields })

  const updated = {
    ...result.data,
    id,
  }

  yield* put(Store.AttackActions.RUNBOOK_STORE_UPDATE(updated))

  return result
}

export function* _ACTION_DELETE(io: MiddlewaresIO, action: _AttackActions.ACTION_DELETE) {
  const { id } = action.payload

  const result = yield* call(io.api.attack.patchAction, id, { data: { deleted: true } })

  const updated = {
    ...result.data,
    id,
  }

  yield* put(Store.AttackActions.ACTION_BY_RUNBOOK_STORE_UPDATE(updated))

  return result
}

export function* _RUNBOOK_DELETE(io: MiddlewaresIO, action: _AttackActions.RUNBOOK_DELETE) {
  const { id } = action.payload

  const result = yield* call(io.api.attack.patchRunbook, id, { data: { deleted: true } })

  const updated = {
    ...result.data,
    id,
  }

  yield* put(Store.AttackActions.RUNBOOK_STORE_UPDATE(updated))

  return result
}

export function* _RUNBOOK_FETCH(io: MiddlewaresIO, action: _AttackActions.RUNBOOK_FETCH) {
  const q = {
    condition: 'AND',
    rules: [
      {
        field: 'table.id',
        id: 'table.id',
        input: 'text',
        operator: 'equal',
        randoriOnly: true,
        type: 'string',
        ui_id: 'id',
        value: action.payload.id,
      },
    ],
  }

  const serializedQuery = `?${qs.stringify({ q: CrudQueryUtils.serializeQ(q) })}`

  const result = yield* call(io.api.attack.getRunbooks, serializedQuery)

  const runbook = head(result.data)

  if (isNotNil(runbook)) {
    yield* put(Store.AttackActions.RUNBOOK_STORE_UPDATE(runbook))
  }

  return runbook
}
