/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { connect } from 'react-redux'

import { AppState, GateSelectors } from '@/store'
import { Flags } from '@/utilities/flags'

type WithStaticFlagsOwnProps = {
  flags?: Array<keyof typeof Flags>
}

const _withStaticFlags = (state: AppState, ownProps: WithStaticFlagsOwnProps) => {
  const _flags = ownProps.flags ?? []

  const flags = _flags.map(GateSelectors.hasStaticFlag(state))

  return {
    isEnabled: [...flags].every(Boolean),
  }
}

export const withStaticFlags = connect(_withStaticFlags)

export const Test = {
  _withStaticFlags,
}
