/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

export const OrgTags = t.interface({
  count: t.number,
  tags: t.array(t.string),
})
type _OrgTags = t.TypeOf<typeof OrgTags>
export type OrgTags = _OrgTags

export const TagCount = t.interface({
  all_count: t.number,
  content: t.string,
  hostname_count: t.number,
  id: t.string,
  ip_count: t.number,
  is_characteristic_tag: t.boolean,
  network_count: t.number,
  org_id: t.string,
  poc_count: t.number,
  target_count: t.number,
})

type _TagCount = t.TypeOf<typeof TagCount>
export type TagCount = _TagCount

export const TagCountsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(TagCount),
})
export type TagCountsResponse = t.TypeOf<typeof TagCountsResponse>
