/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNil, keys } from 'ramda'

// ---------------------------------------------------------------------------

type FixedValues = {
  low: boolean
  medium: boolean
  high: boolean
}

export const confidenceBoundaries = {
  high: {
    upperBound: 100,
    lowerBound: 75,
  },

  medium: {
    upperBound: 74,
    lowerBound: 60,
  },

  low: {
    upperBound: 59,
    lowerBound: 0,
  },
}

export const getConfidence = (confidence: number | null, boundaries: typeof confidenceBoundaries) => {
  if (isNil(confidence)) {
    return 'Low'
  }

  if (confidence >= boundaries.high.lowerBound) {
    return 'High'
  } else if (confidence >= boundaries.medium.lowerBound) {
    return 'Medium'
  } else {
    return 'Low'
  }
}

// @TODO: createConfidence needs to return a CrudRule or CrudRuleGroup
export const createConfidence = (selected: FixedValues) => {
  const low = {
    label: 'low',
    condition: 'AND',
    rules: [
      {
        id: 'table.confidence',
        field: 'table.confidence',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal',
        value: confidenceBoundaries.low.lowerBound,
      },
      {
        id: 'table.confidence',
        field: 'table.confidence',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal',
        value: confidenceBoundaries.low.upperBound,
      },
    ],
  }

  const medium = {
    label: 'medium',
    condition: 'AND',
    rules: [
      {
        id: 'table.confidence',
        field: 'table.confidence',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal',
        value: confidenceBoundaries.medium.lowerBound,
      },
      {
        id: 'table.confidence',
        field: 'table.confidence',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal',
        value: confidenceBoundaries.medium.upperBound,
      },
    ],
  }

  const high = {
    label: 'high',
    condition: 'AND',
    rules: [
      {
        id: 'table.confidence',
        field: 'table.confidence',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal',
        value: confidenceBoundaries.high.lowerBound,
      },
      {
        id: 'table.confidence',
        field: 'table.confidence',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal',
        value: confidenceBoundaries.high.upperBound,
      },
    ],
  }

  type Shennanigans = 'low' | 'medium' | 'high'

  const lookup = { low, medium, high }
  const lookupKeys = keys(lookup)

  const reducer = (acc: { [index: string]: any }[], lookupKey: string) => {
    // If our UI filters set a value to `true`, then we should include it in
    // the filter values we will send to the backend.
    //
    // There are some shennanigans going on here. It's not clear to me yet how
    // to get these typings correct.
    //
    // @see: https://blog.mariusschulz.com/2016/03/31/string-literal-types-in-typescript
    // @see: https://github.com/Microsoft/TypeScript/issues/13254
    //
    // Ramda is no help here, as `keys()` returns `string[]`. It might be
    // worthwhile to look into an iterable object here, like a `Map`, as we
    // wouldn't need to use `Object.keys` and its ilk.
    // const _l = lookupKey as Shennanigans

    return selected[lookupKey as Shennanigans] ? acc.concat(lookup[lookupKey as Shennanigans]) : acc
  }

  const nextConfidence = lookupKeys.reduce(reducer, [])

  return {
    condition: 'OR',
    rules: nextConfidence,
    ui_id: 'confidence',
  }
}
