/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RedirectorForSource } from '@/codecs'

// ---------------------------------------------------------------------------

export type RedirectorState = {
  result: string[]
  totals: { unfiltered: number; unaffiliated: number }

  entities: {
    redirector_for_source: Record<string, RedirectorForSource>
  }
}

export type EntityName = 'redirector_for_source'
export const EntityName: EntityName = 'redirector_for_source'

export const initialState: RedirectorState = {
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },

  entities: {
    redirector_for_source: {},
  },
}
