/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

// @TODO: Refactor TypeKeys to be a string union
//
// This would help fix some of the import/export circular dependency nonsense.
export enum TypeKeys {
  ENTITY_GROUPING_FETCH = 'ENTITY_GROUPING_FETCH',
  FETCH_QUERY_AS_GROUPING = 'FETCH_QUERY_AS_GROUPING',
  RECON_ATTACK_STATISTICS_FETCH = 'RECON_ATTACK_STATISTICS_FETCH',
  RECON_DATA_FOR_ENTITY_FETCH = 'RECON_DATA_FOR_ENTITY_FETCH',
  RECON_DATA_FOR_HOSTNAME_FETCH = 'RECON_DATA_FOR_HOSTNAME_FETCH',
  RECON_ENTITY_PATCH = 'RECON_ENTITY_PATCH',
  RECON_HEADERS_FETCH = 'RECON_HEADERS_FETCH',
  RECON_HOSTNAMES_FETCH = 'RECON_HOSTNAMES_FETCH',
  RECON_HOSTNAME_FETCH = 'RECON_HOSTNAME_FETCH',
  RECON_IPS_FETCH = 'RECON_IPS_FETCH',
  RECON_IPS_FOR_HOSTNAME_FETCH = 'RECON_IPS_FOR_HOSTNAME_FETCH',
  RECON_IPS_FOR_NETWORK_FETCH = 'RECON_IPS_FOR_NETWORK_FETCH',
  RECON_MISSED_AFFILIATIONS_POST = 'RECON_MISSED_AFFILIATIONS_POST',
  RECON_NETWORKS_FETCH = 'RECON_NETWORKS_FETCH',
  RECON_NETWORK_FETCH = 'RECON_NETWORK_FETCH',
  RECON_SAVED_VIEWS_FETCH = 'RECON_SAVED_VIEWS_FETCH',
  RECON_SERVICES_FETCH = 'RECON_SERVICES_FETCH',
  RECON_SERVICE_FETCH = 'RECON_SERVICE_FETCH',
  RECON_STATS_PRIO_FETCH = 'RECON_STATS_PRIO_FETCH',
  RECON_TARGETS_FETCH = 'RECON_TARGETS_FETCH',
  RECON_TARGETS_FOR_ENTITY_FETCH = 'RECON_TARGETS_FOR_ENTITY_FETCH',
  RECON_TARGETS_FOR_ENTITY_RESET = 'RECON_TARGETS_FOR_ENTITY_RESET',
  RECON_TARGETS_FOR_ENTITY_SET = 'RECON_TARGETS_FOR_ENTITY_SET',
  RECON_TARGETS_PAGINATION_UPDATE = 'RECON_TARGETS_PAGINATION_UPDATE',
  RECON_TARGETS_TOTAL_FETCH = 'RECON_TARGETS_TOTAL_FETCH',
  RECON_TARGETS_UPDATE = 'RECON_TARGETS_UPDATE',
  RECON_TARGET_COUNT_FOR_ENTITY_FETCH = 'RECON_TARGET_COUNT_FOR_ENTITY_FETCH',
  RECON_TT_FETCH = 'RECON_TT_FETCH',
  RECON_TT_FOR_HOSTNAME_FETCH = 'RECON_TT_FOR_HOSTNAME_FETCH',

  // incremental refactor
  ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE = 'ALL_SAVED_VIEWS_STORE_SINGLE_UPDATE',
  ALL_SAVED_VIEWS_STORE_UPDATE = 'ALL_SAVED_VIEWS_STORE_UPDATE',
  AUTHORIZATION_POLICIES_FETCH = 'AUTHORIZATION_POLICIES_FETCH',
  AUTHORIZATION_POLICIES_PATCH = 'AUTHORIZATION_POLICIES_PATCH',
  AUTHORIZATION_POLICIES_POST = 'AUTHORIZATION_POLICIES_POST',
  AUTHORIZATION_POLICIES_STORE_UPDATE = 'AUTHORIZATION_POLICIES_STORE_UPDATE',
  AUTHORIZATION_POLICY_DELETE = 'AUTHORIZATION_POLICY_DELETE',
  AUTHORIZATION_POLICY_DELETE_STORE_UPDATE = 'AUTHORIZATION_POLICY_DELETE_STORE_UPDATE',
  AUTHORIZATION_POLICY_FETCH = 'AUTHORIZATION_POLICY_FETCH',
  AUTHORIZATION_POLICY_STORE_UPDATE = 'AUTHORIZATION_POLICY_STORE_UPDATE',
  AUTHORIZATION_POLICY_TOTALS_FETCH = 'AUTHORIZATION_POLICY_TOTALS_FETCH',
  AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE = 'AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE',
  CHARACTERISTICS_BY_PRIORITY_FETCH = 'CHARACTERISTICS_BY_PRIORITY_FETCH',
  CSV_DOWNLOAD = 'CSV_DOWNLOAD',
  DETECTIONS_AUTHORIZE = 'DETECTIONS_AUTHORIZE',
  DETECTIONS_FETCH = 'DETECTIONS_FETCH',
  DETECTIONS_FOR_TARGETS_FETCH = 'DETECTIONS_FOR_TARGETS_FETCH',
  DETECTIONS_FOR_TARGETS_FETCH_P = 'DETECTIONS_FOR_TARGETS_FETCH_P',
  DETECTIONS_STORE_RESET = 'DETECTIONS_STORE_RESET',
  DETECTIONS_STORE_UPDATE = 'DETECTIONS_STORE_UPDATE',
  DETECTIONS_STORE_UPDATE_P = 'DETECTIONS_STORE_UPDATE_P',
  ENTITY_TOTAL_COUNT_FETCH = 'ENTITY_TOTAL_COUNT_FETCH',
  HOSTNAMES_FETCH = 'HOSTNAMES_FETCH',
  HOSTNAMES_STORE_UPDATE = 'HOSTNAMES_STORE_UPDATE',
  HOSTNAME_FETCH = 'HOSTNAME_FETCH',
  HOSTNAME_STORE_UPDATE = 'HOSTNAME_STORE_UPDATE',
  HOSTNAME_TOTALS_FETCH = 'HOSTNAME_TOTALS_FETCH',
  HOSTNAME_TOTALS_STORE_UPDATE = 'HOSTNAME_TOTALS_STORE_UPDATE',
  IPS_FETCH = 'IPS_FETCH',
  IPS_STORE_UPDATE = 'IPS_STORE_UPDATE',
  IP_FETCH = 'IP_FETCH',
  IP_STORE_UPDATE = 'IP_STORE_UPDATE',
  IP_TOTALS_FETCH = 'IP_TOTALS_FETCH',
  IP_TOTALS_STORE_UPDATE = 'IP_TOTALS_STORE_UPDATE',
  NETWORKS_FETCH = 'NETWORKS_FETCH',
  NETWORKS_STORE_UPDATE = 'NETWORKS_STORE_UPDATE',
  NETWORK_FETCH = 'NETWORK_FETCH',
  NETWORK_STORE_UPDATE = 'NETWORK_STORE_UPDATE',
  NETWORK_TOTALS_FETCH = 'NETWORK_TOTALS_FETCH',
  NETWORK_TOTALS_STORE_UPDATE = 'NETWORK_TOTALS_STORE_UPDATE',
  PEER_GROUPS_FETCH = 'PEER_GROUPS_FETCH',
  PEER_GROUPS_POST = 'PEER_GROUPS_POST',
  PEER_GROUPS_STORE_UPDATE = 'PEER_GROUPS_STORE_UPDATE',
  PEER_GROUP_TOTALS_FETCH = 'PEER_GROUP_TOTALS_FETCH',
  PEER_GROUP_TOTALS_STORE_UPDATE = 'PEER_GROUP_TOTALS_STORE_UPDATE',
  PEER_MAPS_FETCH = 'PEER_MAPS_FETCH',
  PEER_MAPS_POST = 'PEER_MAPS_POST',
  PEER_MAPS_STORE_UPDATE = 'PEER_MAPS_STORE_UPDATE',
  PEER_MAP_FETCH = 'PEER_MAP_FETCH',
  PEER_MAP_PATCH = 'PEER_MAP_POST',
  PEER_MAP_STORE_UPDATE = 'PEER_MAP_STORE_UPDATE',
  PEER_MAP_TOTALS_FETCH = 'PEER_MAP_TOTALS_FETCH',
  PEER_MAP_TOTALS_STORE_UPDATE = 'PEER_MAP_TOTALS_STORE_UPDATE',
  PEER_MAP_UPSERT = 'PEER_MAP_UPSERT',
  POLICIES_FETCH = 'POLICIES_FETCH',
  POLICIES_PATCH = 'POLICIES_PATCH',
  POLICIES_POST = 'POLICIES_POST',
  POLICIES_STORE_UPDATE = 'POLICIES_STORE_UPDATE',
  POLICY_DELETE = 'POLICY_DELETE',
  POLICY_DELETE_STORE_UPDATE = 'POLICY_DELETE_STORE_UPDATE',
  POLICY_FETCH = 'POLICY_FETCH',
  POLICY_FETCH_MATCHING_ENTITIES = 'POLICY_FETCH_MATCHING_ENTITIES',
  POLICY_STORE_UPDATE = 'POLICY_STORE_UPDATE',
  POLICY_TOTALS_FETCH = 'POLICY_TOTALS_FETCH',
  POLICY_TOTALS_STORE_UPDATE = 'POLICY_TOTALS_STORE_UPDATE',
  SAVED_VIEWS_DELETE = 'SAVED_VIEWS_DELETE',
  SAVED_VIEWS_FETCH = 'SAVED_VIEWS_FETCH',
  SAVED_VIEWS_FETCH_ALL = 'SAVED_VIEWS_FETCH_ALL',
  SAVED_VIEWS_PATCH = 'SAVED_VIEWS_PATCH',
  SAVED_VIEWS_POST = 'SAVED_VIEWS_POST',
  SAVED_VIEWS_STORE_UPDATE = 'SAVED_VIEWS_STORE_UPDATE',
  SAVED_VIEWS_TOTALS_FETCH = 'SAVED_VIEWS_TOTALS_FETCH',
  SAVED_VIEWS_TOTALS_STORE_UPDATE = 'SAVED_VIEWS_TOTALS_STORE_UPDATE',
  SAVED_VIEW_FETCH = 'SAVED_VIEW_FETCH',
  SAVED_VIEW_STORE_UPDATE = 'SAVED_VIEW_STORE_UPDATE',
  SCREENSHOTS_FOR_TARGETS_FETCH = 'SCREENSHOTS_FOR_TARGETS_FETCH',
  SERVICES_FETCH = 'SERVICES_FETCH',
  SERVICES_STORE_UPDATE = 'SERVICES_STORE_UPDATE',
  SERVICE_STORE_UPDATE = 'SERVICE_STORE_UPDATE',
  SERVICE_TOTALS_FETCH = 'SERVICE_TOTALS_FETCH',
  SERVICE_TOTALS_STORE_UPDATE = 'SERVICE_TOTALS_STORE_UPDATE',
  SOCIAL_ENTITIES_FETCH = 'SOCIAL_ENTITIES_FETCH',
  SOCIAL_ENTITIES_STORE_UPDATE = 'SOCIAL_ENTITIES_STORE_UPDATE',
  SOCIAL_ENTITIES_TOTALS_STORE_UPDATE = 'SOCIAL_ENTITIES_TOTALS_STORE_UPDATE',
  SOCIAL_ENTITY_FETCH = 'SOCIAL_ENTITY_FETCH',
  SOCIAL_ENTITY_TOTALS_FETCH = 'SOCIAL_ENTITY_TOTALS_FETCH',
  TARGETS_FETCH = 'TARGETS_FETCH',
  TARGETS_FOR_ENTITY_FETCH = 'TARGETS_FOR_ENTITY_FETCH',
  TARGETS_FOR_ENTITY_STORE_UPDATE = 'TARGETS_FOR_ENTITY_STORE_UPDATE',
  TARGETS_FOR_NETWORK_FETCH = 'TARGETS_FOR_NETWORK_FETCH',
  TARGETS_STORE_UPDATE = 'TARGETS_STORE_UPDATE',
  TARGET_CONTEXT_FETCH = 'TARGET_CONTEXT_FETCH',
  TARGET_FETCH = 'TARGET_FETCH',
  TARGET_PATCH = 'TARGET_PATCH',
  TARGET_STORE_UPDATE = 'TARGET_STORE_UPDATE',
  TARGET_TOTALS_FETCH = 'TARGETS_TOTALS_FETCH',
  TARGET_TOTALS_STORE_UPDATE = 'TARGETS_TOTALS_STORE_UPDATE',
  TIME_SERIES_ENTITY_STATS_FETCH = 'TIME_SERIES_ENTITY_STATS_FETCH',
  TOP_LEVEL_DETECTIONS_FETCH = 'TOP_LEVEL_DETECTIONS_FETCH',
  TOP_LEVEL_DETECTIONS_STORE_UPDATE = 'TOP_LEVEL_DETECTIONS_STORE_UPDATE',
  TOP_LEVEL_DETECTION_STORE_UPDATE = 'TOP_LEVEL_DETECTION_STORE_UPDATE',
  TOP_LEVEL_DETECTION_TOTALS_FETCH = 'TOP_LEVEL_DETECTION_TOTALS_FETCH',
  TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE = 'TOP_LEVEL_DETECTION_TOTALS_STORE_UPDATE',
  TOP_SAVED_VIEW_STORE_UPDATE = 'TOP_SAVED_VIEW_STORE_UPDATE',
}
