/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

export type UrlScheme = t.TypeOf<typeof UrlScheme>
export const UrlScheme = t.keyof({
  https: null,
  http: null,
})
