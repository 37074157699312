/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import { Network as _Network, DateString, get, UUID } from '@/utilities/codec'

// -----------------------------------------------------------------------------

export const Network: Codecs.NetworkP = {
  affiliation_state: 'None',
  characteristic_tags: [],
  confidence: 75,
  deleted: false,
  first_seen: get('2022-12-10T01:05:26.615225+00:00', DateString),
  id: get('eb624c0e-3e66-479c-966c-82c17d9172fa', UUID),
  impact_score: Codecs.ImpactScoreEnum.none,
  ip_count: 6,
  last_seen: get('2023-04-09T06:22:21.043106+00:00', DateString),
  max_confidence: null,
  network: get('0.0.0.0/24', _Network),
  network_str: get('0.0.0.0/24', _Network),
  only_in_review_targets: true,
  open_port_count: 10,
  org_id: get('b28f017c-c015-4c11-87e0-3e886cdf1b05', UUID),
  perspective__name: 'Default External',
  perspective_id: get('f7627d26-456c-4340-bf12-af7c123cc4ae', UUID),
  perspective_is_targeted: false,
  perspective_type: 'external',
  priority_impact_factor: 0,
  priority_score: 67,
  priority_status_factor: 0,
  priority_tags_factor: 0,
  service_count: 5,
  status: Codecs.StatusEnum.none,
  target_count: 8,
  target_temptation: 67,
  user_tags: [],
}
