/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Target } from './target'

// ---------------------------------------------------------------------------
export type TargetsResponse = t.TypeOf<typeof TargetsResponse>
export const TargetsResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Target),
})
