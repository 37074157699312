/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store'
import * as EntityForActivityInstanceActions from '@/store/actions/activity/entity-for-activity-instance.actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

const EntityForActivityInstanceReducerInitialState = {
  byId: {} as Record<string, string[]>,
  ...Schemas.EntityForActivityInstanceSchemas.NormalizeEntitiesForActivityInstance([]),
}

export const EntityForActivityInstanceReducer = (
  state = EntityForActivityInstanceReducerInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case EntityForActivityInstanceActions.TypeKeys.ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE: {
      const { entities, result } = Schemas.EntityForActivityInstanceSchemas.NormalizeEntitiesForActivityInstance(
        action.payload.data,
      )

      return {
        result,
        byId: {
          ...state.byId,
          [action.meta.forActivityInstance]: result,
        },
        entities: {
          ...state.entities,
          entityForActivityInstance: {
            ...state.entities.entityForActivityInstance,
            ...entities.entityForActivityInstance,
          },
        },
      }
    }

    default:
      return state
  }
}

const EntityForActivityInstancePaginationReducerInitialState: Record<string, Codecs.EntityPagination> = {}

export const EntityForActivityInstancePaginationReducer = (
  state = EntityForActivityInstancePaginationReducerInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case EntityForActivityInstanceActions.TypeKeys.ENTITIES_FOR_ACTIVITY_INSTANCE_STORE_UPDATE: {
      const { data: _data, ...pagination } = action.payload

      return {
        [action.meta.forActivityInstance]: pagination,
      }
    }

    default:
      return state
  }
}
