/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const HostnamesReducer = (state = Schemas.Recon.NormalizeHostnames([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.HOSTNAMES_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeHostnames(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          hostname: {
            ...state.entities.hostname,
            ...entities.hostname,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.HOSTNAME_STORE_UPDATE: {
      return {
        result: state.result,
        entities: {
          ...state.entities,
          hostname: {
            ...state.entities.hostname,
            [action.payload.id]: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}

const HostnamesTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const HostnamesTotalsReducer = (state = HostnamesTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.HOSTNAME_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const HostnamesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const HostnamesPaginationReducer = (state = HostnamesPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.HOSTNAMES_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
