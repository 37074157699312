/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Button, Card, growler, Input, Logo } from '@randori/rootkit'
import debug from 'debug'
import { Form, Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'

import { SsoConfirm, SsoRecover } from '@/codecs'
import { InlineLoader } from '@/components/inline-loader'
import * as Store from '@/store'
import { AuthActions } from '@/store'
import { ErrorMessages } from '@/utilities/form'
import { isNotNil, isNotNilOrEmpty } from '@/utilities/is-not'
import { useKeyCloak } from '@/utilities/keycloak'
import * as Logger from '@/utilities/logger'
import { KeycloakError } from '@/utilities/r-error'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:login-sso')

type FormValues = {
  user_email: string
}

export const ValidationSchema = Yup.object({
  user_email: Yup.string().email().required(),
})

export const initialValues = {
  user_email: '',
}

type ForgotSSOLinkProps = {
  recoverSso: (body: SsoRecover, cb: () => void) => void
}

const ForgotSSOLink: React.FC<ForgotSSOLinkProps> = (props) => {
  const { t } = useTranslation('common')

  const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    props.recoverSso({ user_email: values.user_email }, () => helpers.setSubmitting(false))
  }

  return (
    <div className="auth">
      <Card addlClasses="auth-box" pad={false} flex>
        <div className={'auth-segment recover-sso logo'}>
          <div className="auth-segment-inner">
            <div className="auth-logo">
              <Logo />
            </div>

            <h1>{t('ssoHelp.forgot.title')}</h1>

            <p className="help-text">{t('ssoHelp.forgot.help')}</p>
          </div>
        </div>

        <div className={'auth-segment recover-sso-form'}>
          <div className="auth-segment-inner">
            <h1>{t('ssoHelp.forgot.enter-email')}</h1>

            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={ValidationSchema}>
              {(fProps) => {
                const ErrMsgs = ErrorMessages<FormValues>(fProps.errors, fProps.touched)

                return (
                  <>
                    <Form onSubmit={fProps.handleSubmit} className="sso-recovery-form">
                      <Input
                        name="user_email"
                        type="email"
                        onBlur={fProps.handleBlur}
                        onChange={fProps.handleChange}
                        value={fProps.values['user_email']}
                      />

                      <div>
                        <Button
                          disabled={!fProps.dirty || fProps.isSubmitting || !fProps.isValid}
                          modifier="secondary"
                          render={(bProps) => <button {...bProps} type="submit" />}
                        >
                          {fProps.isSubmitting ? <InlineLoader text={t('recover-url')} /> : <>{t('recover-url')}</>}
                        </Button>
                      </div>
                    </Form>

                    {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                    {isNotNil(ErrMsgs) && <div className="recovery-form-errors">{ErrMsgs}</div>}
                  </>
                )
              }}
            </Formik>
          </div>
        </div>
      </Card>
    </div>
  )
}

// ---------------------------------------------------------------------------

type RedirectToSSOProps = {
  id: string
  confirmSso: (body: SsoConfirm) => void
}

const RedirectToSSO: React.FC<RedirectToSSOProps> = (props) => {
  const { t } = useTranslation('common')

  // Sending a log message to deduce whether or not `onReady` ever gets called
  const delay = 5000
  const delayedLogMessage = setTimeout(() => {
    log('onReady', `exceeded ${delay}`)

    Logger.error(
      new KeycloakError({
        kind: 'onReady',
        addl_desc: `exceeded ${delay}`,
      }),
    )
  }, delay)

  const keycloak = useKeyCloak({
    config: {
      // add the /auth/ because the base URL is for keycloak SVC itself, not what this
      // will interact with.
      url:
        props.id !== window.__RANDORI__.KEYCLOAK_GLOBAL_SSO_REALM_NAME
          ? window.__RANDORI__.KEYCLOAK_SVC_URL
          : window.__RANDORI__.KEYCLOAK_GLOBAL_SVC_URL,
      realm: props.id,
      clientId:
        props.id !== window.__RANDORI__.KEYCLOAK_GLOBAL_SSO_REALM_NAME
          ? window.__RANDORI__.KEYCLOAK_CLIENT_ID
          : window.__RANDORI__.KEYCLOAK_GLOBAL_CLIENT_ID,
    },

    onReady: (authenticated: boolean) => {
      // Cancelling the log message
      clearTimeout(delayedLogMessage)

      if (authenticated && isNotNilOrEmpty(keycloak.idToken) && isNotNilOrEmpty(keycloak.token)) {
        log('onReady', 'calling confirmSso()')

        props.confirmSso({ access_token: keycloak.token })
      } else if (props.id === window.__RANDORI__.KEYCLOAK_GLOBAL_SSO_REALM_NAME) {
        log('onReady', 'calling keycloak.login()')

        // user is not yet authenticated with KeyCloak so force an SSO login
        keycloak.login().catch(Logger.error)
      } else {
        log('onReady', 'noop')

        Logger.log('Keycloak onReady noop')
      }
    },

    onAuthSuccess: () => {
      log('onAuthSuccess')
    },

    onAuthError: (errorData) => {
      log('onAuthError', errorData)

      Logger.error(
        new KeycloakError({
          kind: 'onAuthError',
          ...errorData,
        }),
      )

      growler.error(<span>{t('ssoHelp.redirectToSso.authError')}</span>)
    },

    onAuthRefreshSuccess: () => {
      log('onAuthRefreshSuccess')
    },

    onAuthRefreshError: () => {
      Logger.error(
        new KeycloakError({
          kind: 'onAuthRefreshError',
        }),
      )

      log('onAuthRefreshError')
    },

    onAuthLogout: () => {
      log('onAuthLogout')
    },

    onTokenExpired: () => {
      Logger.error(
        new KeycloakError({
          kind: 'onTokenExpired',
        }),
      )

      log('onTokenExpired')
    },

    onInitError: (errorData) => {
      log('onInitError', errorData)

      Logger.error(
        new KeycloakError({
          kind: 'onInitError',
          addl_desc: 'Keycloak failed to initialize.',
        }),
      )

      growler.error(<span>{t('ssoHelp.redirectToSso.initError')}</span>)
    },
  })

  return (
    <div className="redirect-sso">
      <div className="logo">
        <Logo />
      </div>

      <div className="redirect-text">
        <h1 className="redirect-title">{t('ssoHelp.redirectToSso.redirecting')}</h1>

        {isNotNilOrEmpty(keycloak.authenticated) ? (
          <p className="redirect-help-text">{t('ssoHelp.redirectToSso.toRandori')}</p>
        ) : (
          <p className="redirect-help-text">{t('ssoHelp.redirectToSso.toSso')}</p>
        )}
      </div>
    </div>
  )
}

// ---------------------------------------------------------------------------

type LoginSSOViewProps = Record<string, unknown> & RouteComponentProps<{ id: string }>

const useConfirmSso = () => {
  const dispatch = Store.useAppDispatch()
  const { t } = useTranslation('common')

  return (body: SsoConfirm) => {
    dispatch({
      type: AuthActions.TypeKeys.CONFIRM_SSO,
      payload: body,
      meta: {
        deferred: {
          success: () => {
            // we can't go straight to dashboard because we might need to accept ToS
            // redirect to login, and thus re-enter the flow which will check JWT state
            // and put the user on the right track.
            location.assign('/login')
          },
          failure: (err: Error) => {
            growler.error(
              <span>
                {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                {isNotNil(err.message) && (
                  <>
                    <b>{err.message}</b>:{' '}
                  </>
                )}{' '}
                <>{t('ssoHelp.redirectToSso.authError')}</>
              </span>,
            )
          },
        },
      },
    })
  }
}

const useRecoverSso = () => {
  const dispatch = Store.useAppDispatch()
  const { t } = useTranslation('common')

  return (body: SsoRecover, cb: () => void) => {
    dispatch({
      type: AuthActions.TypeKeys.RECOVER_SSO,
      payload: body,
      meta: {
        deferred: {
          success: () => {
            growler.success(<span>{t('ssoHelp.redirectToSso.email-notice')}</span>)
            cb()
          },
          failure: (err: Error) => {
            growler.error(
              <span>
                {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                {isNotNil(err.message) && (
                  <>
                    <b>{err.message}</b>:{' '}
                  </>
                )}{' '}
                {t('ssoHelp.redirectToSso.recoveryFail')}
              </span>,
            )
            cb()
          },
        },
      },
    })
  }
}

const LoginSSOView: React.FC<LoginSSOViewProps> = (props) => {
  const confirmSso = useConfirmSso()
  const recoverSso = useRecoverSso()

  if (props.match.params.id) {
    return <RedirectToSSO id={props.match.params.id} confirmSso={confirmSso} />
  } else {
    return <ForgotSSOLink recoverSso={recoverSso} />
  }
}

export default LoginSSOView
