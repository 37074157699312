/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from '@/store'
import { UUID } from '@/utilities/codec'

import { makeSelectPagination, makeSelectTotals } from '../utilities/make-selectors'
import { EntityName } from './hostname.constants'

// ---------------------------------------------------------------------------

export const selectHostnamePagination = makeSelectPagination(EntityName)
export const selectHostnameTotals = makeSelectTotals(EntityName)

export const selectHostnames = createSelector([(state: AppState) => state.hostname], (state) => {
  const orderedEntities = state.result.map((uuid) => state.entities.hostname[uuid])

  return orderedEntities
})

export const selectHostnameById = createSelector(
  [
    (state: AppState, _props: { id: UUID }) => state.hostname.entities,
    (_state: AppState, props: { id: UUID }) => props.id,
  ],
  (entities, uuid) => {
    const entity = entities.hostname[uuid]

    return entity
  },
)
