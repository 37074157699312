/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ServiceSuggestionsReducer = (
  state = Schemas.HocSchemas.NormalizeServiceSuggestions([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.SERVICE_SUGGESTIONS_STORE_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizeServiceSuggestions(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          serviceSuggestion: {
            ...state.entities.serviceSuggestion,
            ...entities.serviceSuggestion,
          },
        },
      }
    }

    default:
      return state
  }
}

export const ServiceSuggestionsTotalsInitialState: Codecs.HocEntityTotals = {
  total: 0,
  unfilteredTotal: 0,
}

export const ServiceSuggestionsTotalsReducer = (
  state = ServiceSuggestionsTotalsInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.SERVICE_SUGGESTION_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const ServiceSuggestionsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ServiceSuggestionsPaginationReducer = (
  state = ServiceSuggestionsPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.SERVICE_SUGGESTIONS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}

export const ServiceSuggestionStatsInitialState: Codecs.ServiceSuggestionStats = {
  existing_services: 0,
  orgs_impacted: 0,
}

export const ServiceSuggestionStatStatsReducer = (
  state = ServiceSuggestionStatsInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.SERVICE_SUGGESTION_STATS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}
