/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'

import { noop } from '@/utilities/noop'

// ---------------------------------------------------------------------------

const mixpanel = {
  disable: noop,
  has_opted_in_tracking: () => false,
  identify: noop,
  init: noop,
  opt_in_tracking: noop,
  opt_out_tracking: noop,
  people: { set: noop },
  register: noop,
  reset: noop,
  set_config: noop,
  track: noop,
}

const _log = debug('RANDORI:tracker')
const _logNoop = debug('RANDORI:tracker noop')

const isMixpanelEnabled = () => {
  return false
}

const _init = (mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) => {
  return () => {
    logNoop('deprecated Mixpanel')
  }
}

function _track(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const track: typeof mp.track = (...args) => {
    if (isMixpanelEnabled()) {
      mp.track(...args)

      log('event', args)
    } else {
      logNoop('event', args)
    }
  }

  return track
}

function _set(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  // const set: typeof mixpanel.people.set
  const set = (props: Record<string, any>) => {
    if (isMixpanelEnabled()) {
      mp.people.set(props)

      log('people.set', props)
    } else {
      logNoop('people.set', props)
    }
  }

  return set
}

function _identify(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const identify: typeof mixpanel.identify = (id) => {
    if (isMixpanelEnabled()) {
      mp.identify(id)

      log('identify', id)
    } else {
      logNoop('identify', id)
    }
  }

  return identify
}

// super properties
function _register(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const register: typeof mixpanel.register = (props, days) => {
    if (isMixpanelEnabled()) {
      mp.register(props, days)

      log('register', { props, days })
    } else {
      logNoop('register', { props, days })
    }
  }

  return register
}

function _reset(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const reset: typeof mixpanel.reset = () => {
    if (isMixpanelEnabled()) {
      mp.reset()

      log('reset')
    } else {
      logNoop('reset')
    }
  }

  return reset
}

function _disable(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const disable: typeof mixpanel.disable = () => {
    if (isMixpanelEnabled()) {
      mp.disable()

      log('disable')
    } else {
      logNoop('disable')
    }
  }

  return disable
}

function _optIn(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const track: typeof mp.opt_in_tracking = (...args) => {
    if (isMixpanelEnabled()) {
      mp.opt_in_tracking(...args)

      log('opt-in', args)
    } else {
      logNoop('opt-in', args)
    }
  }

  return track
}

function _optOut(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const track: typeof mp.opt_out_tracking = (...args) => {
    if (isMixpanelEnabled() && mp.has_opted_in_tracking()) {
      mp.opt_out_tracking(...args)

      log('opt-out', args)
    } else {
      logNoop('opt-out', args)
    }
  }

  return track
}

function _setConfig(mp: typeof mixpanel, log: typeof _log, logNoop: typeof _logNoop) {
  const setConfig: typeof mp.set_config = (...args) => {
    if (isMixpanelEnabled()) {
      mp.set_config(...args)

      log('set_config', args)
    } else {
      logNoop('set_config', args)
    }
  }

  return setConfig
}

export const disable = _disable(mixpanel, _log, _logNoop)
export const identify = _identify(mixpanel, _log, _logNoop)
export const init = _init(mixpanel, _log, _logNoop)
export const optIn = _optIn(mixpanel, _log, _logNoop)
export const optOut = _optOut(mixpanel, _log, _logNoop)
export const register = _register(mixpanel, _log, _logNoop)
export const reset = _reset(mixpanel, _log, _logNoop)
export const set = _set(mixpanel, _log, _logNoop)
export const setConfig = _setConfig(mixpanel, _log, _logNoop)
export const track = _track(mixpanel, _log, _logNoop)

// ---------------------------------------------------------------------------

export type PendoInit = {
  excludeAllText?: boolean

  htmlAttributeBlacklist?: string[] | RegExp

  account: Partial<{
    email: string
    full_name: string
    id: string
    role: string
  }> &
    Record<string, string | number | boolean | undefined>

  visitor: Partial<{
    creationDate: string
    id: string
    is_paying: boolean
    monthly_value: string
    name: string
    planLevel: string
    planPrice: string
  }> &
    Record<string, string | number | boolean | undefined>

  guides?: {
    delay?: boolean
    disable?: boolean
  }

  location?: {
    transforms?: Record<string, any>[]
  }
}

const isPendoEnabled = () => {
  try {
    const manager = window.klaro.getManager()

    return window.__RANDORI__.TRACK_ENV && manager.getConsent('pendo')
  } catch {
    return false
  }
}

function _pendo(log: typeof _log, logNoop: typeof _logNoop) {
  return (config: PendoInit) => {
    if (isPendoEnabled()) {
      log('pendo', config)

      window.pendo.initialize(config)
    } else {
      logNoop('pendo', config)
    }
  }
}

export const pendo = _pendo(_log, _logNoop)
