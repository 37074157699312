/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { makeFetchMany, makeFetchSingle, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './network.constants'

// ---------------------------------------------------------------------------

export const fetchNetwork = makeFetchSingle(EntityName)
export const fetchNetworkTotals = makeFetchTotals(EntityName)
export const fetchNetworks = makeFetchMany(EntityName)
