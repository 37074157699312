/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

type NavigationReducerState = { [i: string]: boolean }

const initialState: NavigationReducerState = {
  'Attack Ops': true,
  'Attack Surface': true,
  Settings: true,
}

export const NavigationReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.TOGGLE_NAVIGATION_SECTION: {
      const prevState = state[action.payload] ?? false

      return {
        ...state,
        [action.payload]: !prevState,
      }
    }

    default:
      return state
  }
}
