/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
// ---------------------------------------------------------------------------
import { User } from '@/codecs'
import { ActionTypes, AuthActions } from '@/store/actions'

// ---------------------------------------------------------------------------

const initialState: Partial<User> = {}

export const Reducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case AuthActions.TypeKeys.SET_USER:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export type State = ReturnType<typeof Reducer>

export const Test = {
  Reducer,
}
