/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Service } from './service'

// ---------------------------------------------------------------------------

export type ServicesResponse = t.TypeOf<typeof ServicesResponse>
export const ServicesResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Service),
})

export type ServiceResponse = t.TypeOf<typeof ServiceResponse>
export const ServiceResponse = t.type({ data: Service })
