/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const GlobalServicesReducer = (
  state = Schemas.HocSchemas.NormalizeGlobalServices([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.GLOBAL_SERVICES_STORE_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizeGlobalServices(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          globalService: {
            ...state.entities.globalService,
            ...entities.globalService,
          },
        },
      }
    }

    case Store.HocActions.TypeKeys.GLOBAL_SERVICE_STORE_UPDATE: {
      const prev = state.entities.globalService[action.payload.data.id]
      const next = { ...prev, ...action.payload.data }

      return {
        result: state.result,
        entities: {
          ...state.entities,
          globalService: {
            ...state.entities.globalService,
            ...{ [action.payload.data.id]: next },
          },
        },
      }
    }

    default:
      return state
  }
}

export const GlobalServicesTotalsInitialState: Codecs.HocEntityTotals = {
  total: 0,
  unfilteredTotal: 0,
}

export const GlobalServicesTotalsReducer = (state = GlobalServicesTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.GLOBAL_SERVICE_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const GlobalServicesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const GlobalServicesPaginationReducer = (
  state = GlobalServicesPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.GLOBAL_SERVICES_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}

export const GlobalServiceStatsInitialState: Codecs.GlobalServiceStats = {
  orgs_impacted: 0,
}

export const GlobalServiceStatsReducer = (state = GlobalServiceStatsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.GLOBAL_SERVICE_STATS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}
