/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import qs from 'query-string'

// ---------------------------------------------------------------------------
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as QueryFilterUtils from '@/utilities/query-filter-utils'
import type { EntityType } from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export const tagsInUnknowns: string[] = ['Shadow IT', 'Unknown Asset', 'Unknown - By Qualys', 'Unknown - By Tenable']

const taggedUnknown = {
  condition: 'OR' as const,
  rules: tagsInUnknowns.map((value) => {
    return {
      field: 'table.user_tags',
      id: 'table.user_tags',
      input: 'text',
      operator: 'contains_element',
      type: 'array',
      ui_id: 'user_tags',
      value,
    }
  }),
}

export const allQ = () =>
  CrudQueryUtils.serializeQ({
    condition: 'AND' as const,
    ui_id: QueryFilterUtils.UI_ID_STANDARD,
    rules: [...CrudQueryUtils.confidenceAndAffiliationFilterRules],
  })

export const UnknownQ = () =>
  CrudQueryUtils.serializeQ({
    condition: 'AND' as const,
    ui_id: QueryFilterUtils.UI_ID_STANDARD,
    rules: [
      taggedUnknown,
      ...CrudQueryUtils.confidenceAndAffiliationFilterRules,
      ...CrudQueryUtils.reconStatusFilterRules,
    ],
  })

export const UnknownQCategories = () =>
  CrudQueryUtils.serializeQ({
    condition: 'AND' as const,
    ui_id: QueryFilterUtils.UI_ID_STANDARD,
    rules: [taggedUnknown, ...CrudQueryUtils.confidenceAndAffiliationFilterRules],
  })

export const linkParams = (kind: 'known' | 'unknown', entityType: EntityType) => {
  const sort = entityType === 'target' ? ['-priority_score', 'hostname', '-id'] : ['-priority_score', '-id']

  return qs.stringify({
    limit: 100,
    offset: 0,
    q: kind === 'unknown' ? UnknownQCategories() : allQ(),
    sort: sort,
  })
}
