/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Entitlement, Usage } from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import { throwValidate } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type GetEntitlements = t.TypeOf<typeof GetEntitlements>
const GetEntitlements = Entitlement

export type GetEntitlementsUsage = t.TypeOf<typeof GetEntitlementsUsage>
const GetEntitlementsUsage = Usage

export type EntitlementApi = {
  getEntitlementsForOrg: (orgId: string) => Promise<GetEntitlements>
  getEntitlementsForSources: (orgId: string) => Promise<GetEntitlements>
  getEntitlementsUsageForOrg: (orgId: string) => Promise<GetEntitlementsUsage>
}

export function entitlementApiFactory(http: HttpClient): EntitlementApi {
  const baseURL = '/auth/api/v1'
  const usageBaseURL = '/scheduler/api/v1'

  return {
    getEntitlementsForOrg(orgId) {
      return http.get(`${baseURL}/entitlements/${orgId}`).then(throwValidate(GetEntitlements, 'GetEntitlements'))
    },
    // This is specifically for the 'Create Sources' button entitlement check as this endpoint allows users with manage-internal to access it
    getEntitlementsForSources(orgId) {
      return http
        .get(`${baseURL}/entitlements-sources/${orgId}`)
        .then(throwValidate(GetEntitlements, 'GetEntitlements'))
    },
    getEntitlementsUsageForOrg(orgId) {
      return http
        .get(`${usageBaseURL}/activity-consumption/${orgId}`)
        .then(throwValidate(GetEntitlementsUsage, 'GetEntitlementsUsage'))
    },
  }
}
