/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const Port = t.interface({
  port: t.number,
  state: t.string,

  // /ports-for-ip
  //
  // confidence: t.bumber,
  // deleted: t.boolean,
  // id: t.string,
  // ip_id: t.string,
  // last_seen: t.string,
  // lens_id: t.string,
  // lens_view:
  // org_id: t.string
  // perspective:
  // perspective_name:
  // port: t.number
  // protocol: t.number
  // seen_open: t.boolean
  // state: t.string
})

export type Port = t.TypeOf<typeof Port>

export const PortsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Port),
})
export type PortsResponse = t.TypeOf<typeof PortsResponse>
