/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export class RandoriComponentLoadError extends Error {
  constructor(message: unknown, ...rest: string[]) {
    super(...rest)

    this.message = `Could not lazy load component: ${message}`
    this.name = 'RandoriComponentLoadError'

    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (isNotNil(Error.captureStackTrace)) {
      Error.captureStackTrace(this, RandoriComponentLoadError)
    } else {
      this.stack = new Error(`Could not lazy load component: ${message}`).stack
    }
  }
}
