/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _ReportActions from '@/store/actions/report/report.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import * as CrudQueryUtils from '@/utilities/crud-query'

// ---------------------------------------------------------------------------

export function* watchReports(io: MiddlewaresIO) {
  yield takeEvery(Store.ReportActions.TypeKeys.RECON_REPORTS_FETCH, Catch.deferredAction(_RECON_REPORTS_FETCH, io))
  yield takeEvery(Store.ReportActions.TypeKeys.RECON_REPORT_FETCH, Catch.deferredAction(_RECON_REPORT_FETCH, io))

  yield takeEvery(
    Store.ReportActions.TypeKeys.RECON_REPORTS_TOTALS_FETCH,
    Catch.deferredAction(_RECON_REPORTS_TOTALS_FETCH, io),
  )

  yield takeEvery(
    Store.ReportActions.TypeKeys.RECON_ACTIVITY_REPORT_FETCH,
    Catch.deferredAction(_RECON_ACTIVITY_REPORT_FETCH, io),
  )

  yield takeEvery(
    Store.ReportActions.TypeKeys.RECON_ACTIVITY_CSV_FETCH,
    Catch.deferredAction(_RECON_ACTIVITY_CSV_FETCH, io),
  )
}

export function* _RECON_REPORTS_FETCH(io: MiddlewaresIO, action: _ReportActions.RECON_REPORTS_FETCH) {
  const filteredResponse = yield* call(io.api.report.getReports, action.payload)

  const unfilteredResponse = yield* call(io.api.report.getReports, CrudQueryUtils.createUnfilteredQuery())

  const totals = {
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.ReportActions.RECON_REPORTS_STORE_UPDATE(filteredResponse))
  yield* put(Store.ReportActions.RECON_REPORTS_TOTALS_STORE_UPDATE(totals))

  return {
    ...filteredResponse,
    unfilteredTotal: unfilteredResponse.total,
    unaffiliatedTotal: 0,
  }
}

export function* _RECON_REPORT_FETCH(io: MiddlewaresIO, action: _ReportActions.RECON_REPORT_FETCH) {
  const report = yield* call(io.api.report.getReport, action.payload)
  // here we can also start fetching all of the entities that have foreign key IDs on the report
  return report
}

export function* _RECON_REPORTS_TOTALS_FETCH(io: MiddlewaresIO, action: _ReportActions.RECON_REPORTS_TOTALS_FETCH) {
  const { total } = yield* call(io.api.report.getReports, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.ReportActions.RECON_REPORTS_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal: 0,
  }
}

export function* _RECON_ACTIVITY_REPORT_FETCH(io: MiddlewaresIO, action: _ReportActions.RECON_ACTIVITY_REPORT_FETCH) {
  const report = yield* call(io.api.report.getAttackOppoReport, action.payload.org_id)

  return report
}

export function* _RECON_ACTIVITY_CSV_FETCH(io: MiddlewaresIO, action: _ReportActions.RECON_ACTIVITY_CSV_FETCH) {
  const report = yield* call(io.api.report.getAttackOppoReportCSV, action.payload.org_id)

  return report
}
