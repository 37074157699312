/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type AccessApi = {
  getBars: (query: string) => Promise<Codecs.BarsResponse>
  getBlueprints: (query: string) => Promise<Codecs.BlueprintsResponse>
  patchBar: (id: string, payload: Codecs.BarPatchPayload) => Promise<Codecs.BarPatchResponse>
  ackBar: (payload: Codecs.AckEvent) => Promise<Codecs.AckEvents>
  patchBlueprint: (id: string, payload: Codecs.BlueprintPatchPayload) => Promise<Codecs.BlueprintPatchResponse>
  postBars: (payload: Codecs.BarsPostPayload) => Promise<Codecs.BarsPostResponse>
}

export function accessApiFactory(http: HttpClient): AccessApi {
  const baseUrl = '/access/api/v1'

  return {
    // blueprints
    // -----------------------------------------------------------------------

    getBlueprints(query) {
      return http
        .get(`${baseUrl}/platform-process-definition${query}`)
        .then(CodecUtils.throwValidate(Codecs.BlueprintsResponse, 'BlueprintsResponse'))
    },

    patchBlueprint(id, payload) {
      return http
        .patch(`${baseUrl}/platform-process-definition/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.BlueprintPatchResponse, 'BlueprintPatchResponse'))
    },

    // bars
    // -----------------------------------------------------------------------

    getBars(query) {
      return http
        .get(`${baseUrl}/platform-event-view${query}`)
        .then(CodecUtils.throwValidate(Codecs.BarsResponse, 'BarsResponse'))
    },

    patchBar(id, payload) {
      return http
        .patch(`${baseUrl}/platform-event/${id}`, payload)
        .then(CodecUtils.throwValidate(Codecs.BarPatchResponse, 'BarsResponse'))
    },

    ackBar(payload) {
      return http.post(`${baseUrl}/user/platform-event-response`, { ack_events: [payload] })
    },

    postBars(payload) {
      return http
        .post(`${baseUrl}/platform-event`, payload)
        .then(CodecUtils.throwValidate(Codecs.BarsPostResponse, 'BarsPostResponse'))
    },
  }
}
