/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Card, Loader, Logo, withEffect } from '@randori/rootkit'
import debug from 'debug'
import { pathOr } from 'ramda'
import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { DecodedSession } from '@/codecs'
import { ResetBoundary } from '@/components/boundary'
import * as Store from '@/store'
import { isNotNil } from '@/utilities/is-not'
import { lazyWithRetry } from '@/utilities/lazy'
import { error } from '@/utilities/logger'
import { compose as RCompose } from '@/utilities/recompose'

import { nextPage, Page, PageKeys } from './auth.utils'

const AddOTPForm = lazyWithRetry(() => import('./form-add-otp'))
const ConfirmMe = lazyWithRetry(() => import('./form-confirm-me'))
const OTP = lazyWithRetry(() => import('./form-otp'))
const Password = lazyWithRetry(() => import('./form-password'))
const ToS = lazyWithRetry(() => import('./form-tos'))

// ---------------------------------------------------------------------------

const log = debug('RANDORI:auth')

type LogInState = {
  page: Page
  redirectTo: string
}

type LogInProps = {
  handleLogout: () => void
  globalReset: () => void
  authorization: DecodedSession
} & RouteComponentProps

class LogIn extends React.Component<LogInProps, LogInState> {
  constructor(props: LogInProps) {
    super(props)

    this.getPage = this.getPage.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)

    const _redirectTo = isNotNil(props.authorization)
      ? pathOr(`/${props.authorization.shortname}/dashboard`, ['state', 'redirectTo'], this.props.location)
      : pathOr('/login', ['state', 'redirectTo'], this.props.location)

    this.state = {
      page: nextPage(props.authorization),
      // natural re-direct should be to an error page
      redirectTo: _redirectTo,
    }

    this.setRedirect = this.setRedirect.bind(this)
  }

  render() {
    const { page } = this.state
    log('LogIn.render', { props: this.props, state: this.state })

    return (
      <div className="auth">
        <Card addlClasses="auth-box" pad={false} flex>
          <div className={'auth-segment logo'}>
            <div className="auth-segment-inner">
              <div className="auth-logo">
                <Logo />
              </div>

              <h1>Welcome to Randori.</h1>
            </div>
          </div>

          <div className={`auth-segment ${page}`}>
            <div className="auth-segment-inner">
              <ResetBoundary>{this.getPage(page)}</ResetBoundary>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  // -------------------------------------------------------------------------

  setRedirect(path: string) {
    this.setState({ redirectTo: path })
  }

  getPage(page: LogInState['page']) {
    switch (page) {
      case PageKeys.acceptToS: {
        return (
          <React.Suspense fallback={<Loader />}>
            <ToS onPageChange={this.handlePageChange} setRedirect={this.setRedirect} />
          </React.Suspense>
        )
      }

      case PageKeys.addOTP: {
        return (
          <React.Suspense fallback={<Loader />}>
            <AddOTPForm onPageChange={this.handlePageChange} />
          </React.Suspense>
        )
      }

      case PageKeys.confirm: {
        return (
          <React.Suspense fallback={<Loader />}>
            <ConfirmMe activationToken={''} onPageChange={this.handlePageChange} onLogout={this.props.handleLogout} />
          </React.Suspense>
        )
      }

      case PageKeys.redirect: {
        return <Redirect to={this.state.redirectTo} />
      }

      case PageKeys.submitOTP: {
        return (
          <React.Suspense fallback={<Loader />}>
            <OTP onPageChange={this.handlePageChange} setRedirect={this.setRedirect} />
          </React.Suspense>
        )
      }

      case PageKeys.submitPassword: {
        return (
          <React.Suspense fallback={<Loader />}>
            <Password onPageChange={this.handlePageChange} />
          </React.Suspense>
        )
      }

      default: {
        return null
      }
    }
  }

  handlePageChange(page: LogInState['page']) {
    this.setState({ page })
  }
}

const withConnector = connect(
  (state: Store.AppState) => ({
    activeOrg: Store.AuthSelectors.selectUserCurrentOrg(state),
    authorization: state.authorization.authorization,
  }),
  (dispatch, ownProps: RouteComponentProps) => ({
    globalReset: () => dispatch(Store.GlobalActions.reset()),

    shouldEffectKey: 'login',

    handleLogout: () => {
      dispatch({
        type: Store.AuthActions.TypeKeys.LOG_OUT,
        meta: {
          deferred: {
            success: () => {
              ownProps.history.push('/login')
            },
            failure: (err: Error) => {
              error(err)

              dispatch(Store.GlobalActions.reset())
            },
          },
        },
      })
    },

    effect: () => {
      return new Promise((success, failure) => {
        dispatch(Store.AuthActions.validateJWT({ success, failure }))
      })
    },
  }),
)

export default RCompose<LogInProps, LogInState>(
  withConnector,
  withEffect({
    ErrorComponent: LogIn,

    // @TODO: Need a better way to infer why a user produces a 401
    //
    // When hitting this page. I *can't* know if there's a session cookie set,
    // therefore I do not think I can infer that I have an expired session if
    // I'm redirected to this page during app boot. See
    // http-factory:tryRenewal()
    //
    // When a request is dispatched that yields a 401 during typical
    // navigation, I can deterimine that a session is expired. This interacts
    // with `AuthProtected` however.
    //
    // I'm thinking that validateJWT() needs to enrich the *reason* a 401 is
    // produced. Something like:
    //
    // * There is not session
    // * User session expired
    // * ...etc
    //
    // Then we can decided what next screen to produce, possibly with
    // a growler message.
    //
    // ErrorComponent: LogIn
  }),
)(LogIn)
