/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { EntityPagination } from '@/codecs'
import { ExceptionPolicy } from '@/codecs/exception-policy.codecs'

// ---------------------------------------------------------------------------

export type ExceptionPolicyState = {
  pagination: EntityPagination
  result: string[]
  totals: { unfiltered: number; unaffiliated: number }

  entities: {
    exception_policy: Record<string, ExceptionPolicy>
  }
}

export type EntityName = 'exception_policy'
export const EntityName: EntityName = 'exception_policy'

export const initialState: ExceptionPolicyState = {
  pagination: { count: 0, offset: 0, total: 0 },
  result: [],
  totals: { unfiltered: 0, unaffiliated: 0 },

  entities: {
    exception_policy: {},
  },
}
