/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

type Deferred = Catch.DeferredAction['meta']['deferred']

// ---------------------------------------------------------------------------

export enum TypeKeys {
  CONNECTIONS_STORE_UPDATE = 'CONNECTIONS_STORE_UPDATE',
  GET_ACCOUNT_PROPERTIES = 'GET_ACCOUNT_PROPERTIES',
  GET_CONNECTIONS = 'GET_CONNECTIONS',
  GET_CONNECTIONS_CHECK = 'GET_CONNECTIONS_CHECK',
  GET_RECIPES = 'GET_RECIPES',
  RECIPES_STORE_UPDATE = 'RECIPES_STORE_UPDATE',
  GET_WORKATO_DETAILS = 'GET_WORKATO_DETAILS',
  POST_ACCOUNT_PROPERTIES = 'POST_ACCOUNT_PROPERTIES',
  POST_ACTIVATE_RECIPES = 'POST_ACTIVATE_RECIPES',
  POST_DEACTIVATE_RECIPES = 'POST_DEACTIVATE_RECIPES',
  VALIDATE_CONNECTION = 'VALIDATE_CONNECTION',
}

export type StateActionTypes =
  | CONNECTIONS_STORE_UPDATE
  | GET_ACCOUNT_PROPERTIES
  | GET_CONNECTIONS
  | GET_CONNECTIONS_CHECK
  | GET_RECIPES
  | GET_WORKATO_DETAILS
  | RECIPES_STORE_UPDATE
  | POST_ACCOUNT_PROPERTIES
  | POST_ACTIVATE_RECIPES
  | POST_DEACTIVATE_RECIPES
  | VALIDATE_CONNECTION

// ---------------------------------------------------------------------------

export type GET_ACCOUNT_PROPERTIES = {
  type: TypeKeys.GET_ACCOUNT_PROPERTIES
  meta: { deferred: Deferred }
  payload: { recipeName: Codecs.WorkatoRecipeName }
}

export const GET_ACCOUNT_PROPERTIES = (
  payload: GET_ACCOUNT_PROPERTIES['payload'],
  deferred: Deferred,
): GET_ACCOUNT_PROPERTIES => ({
  type: TypeKeys.GET_ACCOUNT_PROPERTIES,
  meta: { deferred },
  payload,
})

type GET_CONNECTIONS = {
  type: TypeKeys.GET_CONNECTIONS
  meta: { deferred: Deferred }
}

export const GET_CONNECTIONS = (deferred: Deferred): GET_CONNECTIONS => ({
  type: TypeKeys.GET_CONNECTIONS,
  meta: { deferred },
})

export type GET_CONNECTIONS_CHECK = {
  type: TypeKeys.GET_CONNECTIONS_CHECK
  meta: { deferred: Deferred }
  payload: { recipeName: Codecs.WorkatoRecipeName }
}

export const GET_CONNECTIONS_CHECK = (
  payload: GET_CONNECTIONS_CHECK['payload'],
  deferred: Deferred,
): GET_CONNECTIONS_CHECK => ({
  type: TypeKeys.GET_CONNECTIONS_CHECK,
  meta: { deferred },
  payload,
})

type GET_RECIPES = {
  type: TypeKeys.GET_RECIPES
  meta: { deferred: Deferred }
}

export const GET_RECIPES = (deferred: Deferred): GET_RECIPES => ({
  type: TypeKeys.GET_RECIPES,
  meta: { deferred },
})

type RECIPES_STORE_UPDATE = {
  type: TypeKeys.RECIPES_STORE_UPDATE
  payload: Codecs.WorkatoRecipes
}

export function RECIPES_STORE_UPDATE(payload: RECIPES_STORE_UPDATE['payload']): RECIPES_STORE_UPDATE {
  return {
    type: TypeKeys.RECIPES_STORE_UPDATE,
    payload,
  }
}

type GET_WORKATO_DETAILS = {
  type: TypeKeys.GET_WORKATO_DETAILS
  meta: { deferred: Deferred }
}

export const GET_WORKATO_DETAILS = (deferred: Deferred): GET_WORKATO_DETAILS => ({
  type: TypeKeys.GET_WORKATO_DETAILS,
  meta: { deferred },
})

export type CONNECTIONS_STORE_UPDATE = {
  type: TypeKeys.CONNECTIONS_STORE_UPDATE
  payload: Codecs.WorkatoConnections
}

export function CONNECTIONS_STORE_UPDATE(payload: CONNECTIONS_STORE_UPDATE['payload']): CONNECTIONS_STORE_UPDATE {
  return {
    type: TypeKeys.CONNECTIONS_STORE_UPDATE,
    payload,
  }
}

export type POST_ACCOUNT_PROPERTIES = {
  type: TypeKeys.POST_ACCOUNT_PROPERTIES
  meta: { deferred: Deferred }
  payload: Codecs.WorkatoPostAccountProperties
}

export const POST_ACCOUNT_PROPERTIES = (
  payload: POST_ACCOUNT_PROPERTIES['payload'],
  deferred: Deferred,
): POST_ACCOUNT_PROPERTIES => ({
  type: TypeKeys.POST_ACCOUNT_PROPERTIES,
  meta: { deferred },
  payload,
})

export type POST_ACTIVATE_RECIPES = {
  type: TypeKeys.POST_ACTIVATE_RECIPES
  meta: { deferred: Deferred }
  payload: {
    recipeId: number
    recipeName: Codecs.WorkatoRecipeName
  }
}

export const POST_ACTIVATE_RECIPES = (
  payload: POST_ACTIVATE_RECIPES['payload'],
  deferred: Deferred,
): POST_ACTIVATE_RECIPES => ({
  type: TypeKeys.POST_ACTIVATE_RECIPES,
  meta: { deferred },
  payload,
})

export type POST_DEACTIVATE_RECIPES = {
  type: TypeKeys.POST_DEACTIVATE_RECIPES
  meta: { deferred: Deferred }
  payload: {
    recipeId: number
  }
}

export const POST_DEACTIVATE_RECIPES = (
  recipeId: POST_ACTIVATE_RECIPES['payload']['recipeId'],
  deferred: Deferred,
): POST_DEACTIVATE_RECIPES => ({
  type: TypeKeys.POST_DEACTIVATE_RECIPES,
  meta: { deferred },
  payload: {
    recipeId,
  },
})

export type VALIDATE_CONNECTION = {
  type: TypeKeys.VALIDATE_CONNECTION
  meta: { deferred: Deferred }
  payload: {
    connectionId: number
    recipeName: Codecs.WorkatoRecipeName
  }
}

export const VALIDATE_CONNECTION = (
  payload: VALIDATE_CONNECTION['payload'],
  deferred: Deferred,
): VALIDATE_CONNECTION => ({
  type: TypeKeys.VALIDATE_CONNECTION,
  meta: { deferred },
  payload,
})
