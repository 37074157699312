/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'

import { UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export const makeNormalizer = <T extends string, U extends Record<string, unknown>>(entityName: T) => {
  return (records: U[]) => {
    const Single = new schema.Entity(entityName)
    const Plural = [Single]

    type NormalizedPlural = {
      result: UUID[]
      entities: { [K in T]: Record<UUID, U> }
    }

    const entities: NormalizedPlural = normalize(records, Plural)

    return entities
  }
}
