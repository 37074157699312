/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as CrudQueryUtils from '@/utilities/crud-query'

// ---------------------------------------------------------------------------

export interface FieldMap {
  [index: string]: CrudQueryUtils.Rule
}

export const FIELD_MAP: FieldMap = {
  description: {
    ui_id: 'description',
    id: 'table.description',
    field: 'table.description',
    type: 'string',
    input: 'text',
  },

  ruleCreateTime: {
    ui_id: 'create_time',
    id: 'table.first_time',
    field: 'table.first_time',
    type: 'datetime',
    input: 'text',
  },

  ruleDetections: {
    ui_id: 'detections',
    id: 'table.detections',
    field: 'table.detections',
    type: 'integer',
    input: 'number',
  },

  policyName: {
    ui_id: 'policy_name',
    id: 'table.name',
    field: 'table.name',
    type: 'string',
    input: 'text',
  },

  name: {
    ui_id: 'saved_view_name',
    id: 'table.name',
    field: 'table.name',
    type: 'string',
    input: 'text',
  },

  email: {
    ui_id: 'email',
    id: 'table.email',
    field: 'table.email',
    type: 'string',
    input: 'text',
  },

  cve: {
    ui_id: 'cve',
    id: 'table.cve',
    field: 'table.cve',
    type: 'string',
    input: 'text',
  },

  cpe_str: {
    ui_id: 'cpe_str',
    id: 'table.cpe',
    field: 'table.cpe',
    type: 'string',
    input: 'text',
  },

  cvss: {
    ui_id: 'cvss',
    id: 'table.base_score',
    field: 'table.base_score',
    type: 'string',
    input: 'text',
  },

  ips: {
    ui_id: 'ips',
    id: 'table.ips',
    field: 'table.ips',
    type: 'array',
    input: 'text',
  },

  ip_str: {
    ui_id: 'ip_str',
    id: 'table.ip_str',
    field: 'table.ip_str',
    type: 'string',
    input: 'text',
  },

  hostname: {
    ui_id: 'hostname',
    id: 'table.hostname',
    field: 'table.hostname',
    type: 'string',
    input: 'text',
  },

  hostnames: {
    ui_id: 'hostnames',
    id: 'table.hostnames',
    field: 'table.hostnames',
    type: 'array',
    input: 'text',
  },

  version: {
    ui_id: 'version',
    id: 'table.version',
    field: 'table.version',
    type: 'string',
    input: 'text',
  },

  vendor: {
    ui_id: 'vendor',
    id: 'table.vendor',
    field: 'table.vendor',
    type: 'string',
    input: 'text',
  },

  all_ports: {
    ui_id: 'open_port',
    id: 'table.all_ports',
    field: 'table.all_ports',
    type: 'array',
    input: 'text',
  },

  open_port_count: {
    ui_id: 'open_port_count',
    id: 'table.open_port_count',
    field: 'table.open_port_count',
    type: 'integer',
    input: 'number',
  },

  ip_count: {
    ui_id: 'ip_count',
    id: 'table.ip_count',
    field: 'table.ip_count',
    type: 'integer',
    input: 'number',
  },

  instance_count: {
    ui_id: 'instance_count',
    id: 'table.instance_count',
    field: 'table.instance_count',
    type: 'integer',
    input: 'number',
  },

  service_count: {
    ui_id: 'service_count',
    id: 'table.service_count',
    field: 'table.service_count',
    type: 'integer',
    input: 'number',
  },

  target_count: {
    ui_id: 'target_count',
    id: 'table.target_count',
    field: 'table.target_count',
    type: 'integer',
    input: 'number',
  },

  person_first_name: {
    ui_id: 'person_first_name',
    id: 'table.person_first_name',
    field: 'table.person_first_name',
    type: 'string',
    input: 'text',
  },

  first_seen: {
    ui_id: 'first_seen',
    id: 'table.first_seen',
    field: 'table.first_seen',
    type: 'datetime',
    input: 'text',
  },

  end_time: {
    ui_id: 'end_time',
    id: 'table.end_time',
    field: 'table.end_time',
    type: 'datetime',
    input: 'text',
  },

  target_first_seen: {
    ui_id: 'target_first_seen',
    id: 'table.target_first_seen',
    field: 'table.target_first_seen',
    type: 'datetime',
    input: 'text',
  },

  lastName: {
    ui_id: 'person_last_name',
    id: 'table.person_last_name',
    field: 'table.person_last_name',
    type: 'string',
    input: 'text',
  },

  last_seen: {
    ui_id: 'last_seen',
    id: 'table.last_seen',
    field: 'table.last_seen',
    type: 'datetime',
    input: 'text',
  },

  target_last_seen: {
    ui_id: 'target_last_seen',
    id: 'table.target_last_seen',
    field: 'table.target_last_seen',
    type: 'datetime',
    input: 'text',
  },

  serviceName: {
    ui_id: 'name',
    id: 'table.name',
    field: 'table.name',
    type: 'string',
    input: 'text',
  },

  end_of_life: {
    ui_id: 'end_of_life',
    id: 'table.end_of_life',
    field: 'table.end_of_life',
    type: 'boolean',
    input: 'boolean',
  },

  network_str: {
    ui_id: 'network_str',
    id: 'table.network_str',
    field: 'table.network_str',
    type: 'string',
    input: 'text',
  },

  network: {
    ui_id: 'network',
    id: 'table.network',
    field: 'table.network',
    type: 'string',
    input: 'text',
  },

  runbook_name: {
    ui_id: 'runbook_name',
    id: 'table.name',
    field: 'table.name',
    type: 'string',
    input: 'text',
  },

  nickname: {
    ui_id: 'nickname',
    id: 'table.nickname',
    field: 'table.nickname',
    type: 'string',
    input: 'text',
  },

  implant_origination: {
    ui_id: 'implant_origination',
    id: 'table.src_search',
    field: 'table.src_search',
    type: 'string',
    input: 'text',
  },

  implant_destination: {
    ui_id: 'implant_destination',
    id: 'table.dst_search',
    field: 'table.dst_search',
    type: 'string',
    input: 'text',
  },

  implant_nick: {
    ui_id: 'implant_nick',
    id: 'table.implant_nick',
    field: 'table.implant_nick',
    type: 'string',
    input: 'text',
  },

  implant_hostnames: {
    ui_id: 'implant_hostnames',
    id: 'table.hostnames',
    field: 'table.hostnames',
    type: 'string',
    input: 'text',
  },

  nick: {
    ui_id: 'nick',
    id: 'table.nick',
    field: 'table.nick',
    type: 'string',
    input: 'text',
  },

  last_checkin: {
    ui_id: 'last_checkin',
    id: 'table.last_checkin',
    field: 'table.last_checkin',
    type: 'datetime',
    input: 'text',
  },

  ostype: {
    ui_id: 'ostype',
    id: 'table.ostype',
    field: 'table.ostype',
    type: 'string',
    input: 'text',
  },

  implantUid: {
    ui_id: 'uid',
    id: 'table.uid',
    field: 'table.uid',
    type: 'string',
    input: 'text',
  },

  uid: {
    ui_id: 'runbook_uid',
    id: 'table.uid',
    field: 'table.uid',
    type: 'string',
    input: 'text',
  },

  external_ip_str: {
    ui_id: 'externalIp',
    id: 'table.external_ip_str',
    field: 'table.external_ip_str',
    type: 'string',
    input: 'text',
  },

  retired: {
    ui_id: 'retired',
    id: 'table.retired',
    field: 'table.retired',
    type: 'datetime',
    input: 'text',
  },

  activated: {
    ui_id: 'activated',
    id: 'table.created_on',
    field: 'table.created_on',
    type: 'datetime',
    input: 'text',
  },

  created_at: {
    ui_id: 'created_at',
    id: 'table.created_at',
    field: 'table.created_at',
    type: 'datetime',
    input: 'text',
  },

  host_ips: {
    field: 'table.host_ips',
    id: 'table.host_ips',
    input: 'text',
    type: 'array',
    ui_id: 'host_ips',
  },

  src_ip: {
    field: 'table.src_ip',
    id: 'table.src_ip',
    input: 'text',
    type: 'array',
    ui_id: 'src_ip',
  },

  implant_src_ip: {
    field: 'table.implant_src_ip',
    id: 'table.implant_src_ip',
    input: 'text',
    type: 'array',
    ui_id: 'implant_src_ip',
  },

  implant_src_host: {
    field: 'table.implant_src_host',
    id: 'table.implant_src_host',
    input: 'text',
    type: 'array',
    ui_id: 'implant_src_host',
  },

  dst_host: {
    field: 'table.dst_host',
    id: 'table.dst_host',
    input: 'text',
    type: 'array',
    ui_id: 'dst_host',
  },

  dst_ip: {
    field: 'table.dst_ip',
    id: 'table.dst_ip',
    input: 'text',
    type: 'array',
    ui_id: 'dst_ip',
  },

  src_host: {
    field: 'table.src_host',
    id: 'table.src_host',
    input: 'text',
    type: 'array',
    ui_id: 'src_host',
  },

  src_mac: {
    field: 'table.src_mac',
    id: 'table.src_mac',
    input: 'text',
    type: 'array',
    ui_id: 'src_mac',
  },

  technique_ids: {
    ui_id: 'technique_ids',
    field: 'table.technique_ids',
    id: 'table.technique_ids',
    input: 'text',
    type: 'array',
  },

  title: {
    ui_id: 'title',
    field: 'table.title',
    id: 'table.title',
    input: 'text',
    type: 'string',
  },

  ipRange: {
    field: 'table.ip',
    id: 'table.ip',
    input: 'text',
    type: 'string', // @TODO: Is this correct?
    ui_id: 'ip_range',
  },

  perspective: {
    field: 'table.perspective_name',
    id: 'table.perspective_name',
    input: 'text',
    type: 'string',
    ui_id: 'perspective',
  },

  perspectiveType: {
    field: 'table.perspective_type',
    id: 'table.perspective_type',
    input: 'text',
    type: 'string',
    ui_id: 'perspective_type',
  },

  startTime: {
    ui_id: 'start_time',
    field: 'table.start_time',
    id: 'table.start_time',
    input: 'text',
    type: 'datetime',
  },

  affected_orgs: {
    ui_id: 'affected_orgs',
    field: 'table.affected_orgs',
    id: 'table.affected_orgs',
    input: 'text',
    type: 'integer',
  },

  artifactLastSeen: {
    ui_id: 'artifact_last_seen',
    id: 'table.time',
    field: 'table.time',
    type: 'datetime',
    input: 'text',
  },

  artifactFirstSeen: {
    ui_id: 'artifact_first_seen',
    id: 'table.first_time',
    field: 'table.first_time',
    type: 'datetime',
    input: 'text',
  },

  data_hash: {
    ui_id: 'artifact_data_hash',
    id: 'table.data_hash',
    field: 'table.data_hash',
    type: 'object',
    input: 'text',
  },

  detection_criteria: {
    ui_id: 'artifact_detection_criteria',
    id: 'table.detection_criteria',
    field: 'table.detection_criteria',
    type: 'object',
    input: 'text',
  },

  socialId: {
    ui_id: 'poc_id',
    id: 'table.id',
    field: 'table.id',
    type: 'object',
    input: 'text',
  },

  dst_email: {
    field: 'table.dst_email',
    id: 'table.dst_email',
    input: 'text',
    type: 'array',
    ui_id: 'dst_email',
  },

  dst_mac: {
    field: 'table.dst_mac',
    id: 'table.dst_mac',
    input: 'text',
    type: 'array',
    ui_id: 'dst_mac',
  },

  dst_network: {
    field: 'table.dst_network',
    id: 'table.dst_network',
    input: 'text',
    type: 'array',
    ui_id: 'dst_network',
  },

  dst_path: {
    field: 'table.dst_path',
    id: 'table.dst_path',
    input: 'text',
    type: 'array',
    ui_id: 'dst_path',
  },

  dst_port: {
    field: 'table.dst_port',
    id: 'table.dst_port',
    input: 'text',
    type: 'array',
    ui_id: 'dst_port',
  },

  dst_misc: {
    field: 'table.dst_misc',
    id: 'table.dst_misc',
    input: 'text',
    type: 'array',
    ui_id: 'dst_misc',
  },

  detector: {
    field: 'table.detector',
    id: 'table.detector',
    input: 'text',
    type: 'string',
    ui_id: 'service_detector',
  },

  id: {
    field: 'table.id',
    id: 'table.id',
    input: 'text',
    type: 'string',
    ui_id: 'id',
  },

  shortname: {
    field: 'table.shortname',
    id: 'table.shortname',
    input: 'text',
    type: 'string',
    ui_id: 'org_shortname',
  },

  org_id: {
    field: 'table.org_id',
    id: 'table.org_id',
    input: 'text',
    type: 'string',
    ui_id: 'org_id',
  },

  term: {
    field: 'table.term',
    id: 'table.term',
    input: 'text',
    type: 'string',
    ui_id: 'term',
  },

  validated_vulnerabilities_target: {
    field: 'table.validated_vulnerabilities_target',
    id: 'table.validated_vulnerabilities_target',
    input: 'text',
    type: 'array',
    ui_id: 'validated_vulnerabilities_target',
  },

  validated_vulnerabilities_detection: {
    field: 'table.validated_vulnerabilities_detection',
    id: 'table.validated_vulnerabilities_detection',
    input: 'text',
    type: 'array',
    ui_id: 'validated_vulnerabilities_detection',
  },

  // Activities filters
  activity_class: {
    field: 'attributes.activity_class',
    id: 'attributes.activity_class',
    input: 'text',
    type: 'string',
    ui_id: 'activity_class',
  },

  activity_configuration_name: {
    field: 'attributes.name',
    id: 'attributes.name',
    input: 'text',
    type: 'string',
    ui_id: 'activity_configuration_name',
  },

  activity_type_name: {
    field: 'attributes.activity_type_name',
    id: 'attributes.activity_type_name',
    input: 'text',
    type: 'string',
    ui_id: 'activity_type_name',
  },

  configuration__description: {
    ui_id: 'configuration__description',
    id: 'table.configuration__description',
    field: 'table.configuration__description',
    type: 'string',
    input: 'text',
  },

  // the id unique to a configuration type
  configuration__id: {
    ui_id: 'configuration__id',
    id: 'table.configuration__id',
    field: 'table.configuration__id',
    input: 'text',
    type: 'string',
  },

  configuration__name: {
    ui_id: 'configuration__name',
    id: 'table.configuration__name',
    field: 'table.configuration__name',
    type: 'string',
    input: 'text',
  },

  // the id unique to a version of a configuration
  configuration__version_id: {
    ui_id: 'configuration__version_id',
    id: 'table.configuration__version_id',
    field: 'table.configuration__version_id',
    input: 'text',
    type: 'string',
  },

  count__artifacts: {
    ui_id: 'count__artifacts',
    id: 'table.count__artifacts',
    field: 'table.count__artifacts',
    type: 'integer',
    input: 'number',
  },

  count__relationships_deleted: {
    ui_id: 'count__relationships_deleted',
    id: 'table.count__relationships_deleted',
    field: 'table.count__relationships_deleted',
    type: 'integer',
    input: 'number',
  },

  count__entities_updated: {
    ui_id: 'count__entities_updated',
    id: 'table.count__entities_updated',
    field: 'table.count__entities_updated',
    type: 'integer',
    input: 'number',
  },

  created_on: {
    field: 'created_on',
    id: 'created_on',
    input: 'text',
    ui_id: 'created_on',
    type: 'datetime',
  },

  enabled: {
    ui_id: 'enabled',
    id: 'enabled',
    field: 'enabled',
    type: 'boolean',
    input: 'boolean',
  },

  instance__most_recent_instance_id: {
    ui_id: 'instance__most_recent_instance_id',
    id: 'table.instance__most_recent_instance_id',
    field: 'table.table.instance__most_recent_instance_id',
    type: 'string',
    input: 'text',
  },

  instance__perspective: {
    ui_id: 'instance__perspective',
    id: 'table.instance__perspective',
    field: 'table.instance__perspective',
    type: 'string',
    input: 'text',
  },

  instance__time_duration: {
    ui_id: 'instance__time_duration',
    id: 'table.instance__time_duration',
    field: 'table.instance__time_duration',
    type: 'integer',
    input: 'number',
  },

  instance__time_end: {
    ui_id: 'instance__time_end',
    id: 'table.instance__time_end',
    field: 'table.instance__time_end',
    type: 'datetime',
    input: 'text',
  },

  instance__time_start: {
    ui_id: 'instance__time_start',
    id: 'table.instance__time_start',
    field: 'table.instance__time_start',
    type: 'datetime',
    input: 'text',
  },

  matching_entity_types: {
    field: 'attributes.matching_entity_types',
    id: 'attributes.matching_entity_types',
    input: 'text',
    type: 'array',
    ui_id: 'matching_entity_types',
  },

  // Activity Library ACS mitre fields NOTE the single underscore

  mitre_mitigations: {
    field: 'attributes.mitre.mitigations',
    id: 'attributes.mitre.mitigations',
    input: 'text',
    type: 'array',
    ui_id: 'mitre_mitigations',
  },

  mitre_tactics: {
    field: 'attributes.mitre.tactics',
    id: 'attributes.mitre.tactics',
    input: 'text',
    type: 'array',
    ui_id: 'mitre_tactics',
  },

  mitre_techniques: {
    field: 'attributes.mitre.techniques',
    id: 'attributes.mitre.techniques',
    input: 'text',
    type: 'array',
    ui_id: 'mitre_techniques',
  },

  // Activity Log mitre fields NOTE the double underscore

  mitre__mitigations: {
    ui_id: 'mitre__mitigations',
    id: 'table.mitre__mitigations',
    field: 'table.mitre__mitigations',
    type: 'array',
    input: 'text',
  },

  mitre__tactics: {
    ui_id: 'mitre__tactics',
    condition: 'OR',
    rules: [],
  },

  mitre__techniques: {
    ui_id: 'mitre__techniques',
    id: 'table.mitre__techniques',
    field: 'table.mitre__techniques',
    type: 'array',
    input: 'text',
  },

  objective__attacker_perspective: {
    ui_id: 'objective__attacker_perspective',
    id: 'table.objective__attacker_perspective',
    field: 'table.objective__attacker_perspective',
    type: 'string',
    input: 'text',
  },

  objective__description: {
    ui_id: 'objective__description',
    id: 'table.objective__description',
    field: 'table.objective__description',
    type: 'string',
    input: 'text',
  },

  objective__implication: {
    ui_id: 'objective__implication',
    id: 'table.objective__implication',
    field: 'table.objective__implication',
    type: 'string',
    input: 'text',
  },

  objective__status: {
    ui_id: 'objective__status',
    condition: 'OR',
    rules: [],
  },

  period: {
    field: 'attributes.period',
    id: 'attributes.period',
    input: 'text',
    type: 'integer',
    ui_id: 'period',
  },

  stability: {
    field: 'attributes.stability',
    id: 'attributes.stability',
    input: 'text',
    type: 'integer',
    ui_id: 'stability',
  },

  stealth: {
    field: 'attributes.stealth',
    id: 'attributes.stealth',
    input: 'text',
    type: 'integer',
    ui_id: 'stealth',
  },

  traffic_source__ip_name: {
    ui_id: 'traffic_source__ip_name',
    id: 'table.traffic_source__ip_name',
    field: 'table.traffic_source__ip_name',
    type: 'string',
    input: 'text',
  },

  matching_entity__id: {
    ui_id: 'matching_entity__id',
    id: 'table.matching_entity__id',
    field: 'table.matching_entity__id',
    type: 'string',
    input: 'text',
  },

  trigger_criteria__authority_display: {
    ui_id: 'trigger_criteria__authority_display',
    id: 'table.trigger_criteria__authority_display',
    field: 'table.trigger_criteria__authority_display',
    type: 'boolean',
    input: 'boolean',
  },

  trigger_criteria__entity_match: {
    ui_id: 'trigger_criteria__entity_match',
    id: 'table.trigger_criteria__entity_match',
    field: 'table.trigger_criteria__entity_match',
    type: 'array',
    input: 'text',
  },

  trigger_criteria__time_lapsed: {
    ui_id: 'trigger_criteria__time_lapsed',
    id: 'table.trigger_criteria__time_lapsed',
    field: 'table.trigger_criteria__time_lapsed',
    type: 'string',
    input: 'text',
  },

  // Why do we have different fields for global search? this is the same as another field - not unique
  // changing this one - let's chat about what this will break

  tech_category_global_search: {
    field: 'table.tech_category',
    id: 'table.tech_category',
    input: 'text',
    type: 'array',
    ui_id: 'tech_category_global',
  },

  updated_on: {
    field: 'updated_on',
    id: 'updated_on',
    input: 'text',
    type: 'datetime',
    ui_id: 'updated_on',
  },

  authority: {
    ui_id: 'authority',
    id: 'table.authority',
    field: 'table.authority',
    type: 'boolean',
    input: 'boolean',
  },

  authority_distance: {
    ui_id: 'authority_distance',
    id: 'table.authority_distance',
    field: 'table.authority_distance',
    type: 'integer',
    input: 'number',
  },

  authority_override: {
    ui_id: 'authority_override',
    id: 'table.authority_override',
    field: 'table.authority_override',
    type: 'boolean',
    input: 'boolean',
  },

  // temptation factors

  applicability: {
    ui_id: Codecs.TemptationFactor.applicability,
    condition: 'OR',
    rules: [],
  },

  criticality: {
    ui_id: Codecs.TemptationFactor.criticality,
    condition: 'OR',
    rules: [],
  },

  enumerability: {
    ui_id: Codecs.TemptationFactor.enumerability,
    condition: 'OR',
    rules: [],
  },

  post_exploit: {
    ui_id: Codecs.TemptationFactor.post_exploit,
    condition: 'OR',
    rules: [],
  },

  private_weakness: {
    ui_id: Codecs.TemptationFactor.private_weakness,
    condition: 'OR',
    rules: [],
  },

  public_weakness: {
    ui_id: Codecs.TemptationFactor.public_weakness,
    condition: 'OR',
    rules: [],
  },

  research: {
    ui_id: Codecs.TemptationFactor.research,
    condition: 'OR',
    rules: [],
  },

  exploitability: {
    ui_id: 'exploitability',
    condition: 'OR',
    rules: [],
  },

  // new tag fields
  characteristic_tags: {
    field: 'table.characteristic_tags',
    id: 'table.characteristic_tags',
    input: 'text',
    type: 'array',
    ui_id: 'characteristic_tags',
  },

  user_tags: {
    field: 'table.user_tags',
    id: 'table.user_tags',
    input: 'text',
    type: 'array',
    ui_id: 'user_tags',
  },

  // Exception Policies

  activities_count: {
    field: 'activities_count',
    id: 'attributes.activities_count',
    input: 'number',
    type: 'integer',
    ui_id: 'activities_count',
  },

  exception_policy_description: {
    field: 'description',
    id: 'description',
    input: 'text',
    type: 'string',
    ui_id: 'exception_policy_description',
  },

  exception_policy_name: {
    field: 'name',
    id: 'name',
    input: 'text',
    type: 'string',
    ui_id: 'exception_policy_name',
  },

  // Assets
  display_name: {
    field: 'table.display_name',
    id: 'table.display_name',
    input: 'text',
    type: 'string',
    ui_id: 'display_name',
  },

  asset_first_seen: {
    field: 'first_seen',
    id: 'first_seen',
    input: 'text',
    type: 'datetime',
    ui_id: 'first_seen',
  },

  asset_last_seen: {
    field: 'last_seen',
    id: 'last_seen',
    input: 'text',
    type: 'datetime',
    ui_id: 'last_seen',
  },

  // RuleGroup
  // -----------------------------------------------------------------------

  unaffiliated: {
    ui_id: 'unaffiliated',
    condition: 'OR',
    rules: [],
  },

  target_temptation: {
    ui_id: 'target_temptation',
    condition: 'OR',
    rules: [],
  },

  confidence: {
    ui_id: 'confidence',
    condition: 'OR',
    rules: [],
  },

  impact_score: {
    ui_id: 'impact_score',
    condition: 'AND',
    rules: [],
  },

  location: {
    ui_id: 'location',
    condition: 'AND',
    rules: [],
  },

  promoted: {
    ui_id: 'promoted',
    condition: 'OR',
    rules: [],
  },

  status: {
    ui_id: 'recon_status',
    condition: 'OR',
    rules: [],
  },

  tech_category: {
    ui_id: 'tech_category',
    condition: 'OR',
    rules: [],
  },

  instance__state: {
    ui_id: 'instance__state',
    condition: 'OR',
    rules: [],
  },

  matching_entity__type: {
    ui_id: 'matching_entity__type',
    condition: 'OR',
    rules: [],
  },

  matching_entity__name: {
    ui_id: 'matching_entity__name',
    condition: 'OR',
    rules: [],
  },

  matching_entity__ip: {
    ui_id: 'matching_entity__ip',
    id: 'table.matching_entity__ip',
    field: 'table.matching_entity__ip',
    type: 'string',
    input: 'text',
  },

  matching_entity__detection: {
    ui_id: 'matching_entity__detection',
    id: 'table.matching_entity__detection',
    field: 'table.matching_entity__detection',
    type: 'string',
    input: 'text',
  },

  matching_entity__email: {
    ui_id: 'matching_entity__email',
    id: 'table.matching_entity__email',
    field: 'table.matching_entity__email',
    type: 'string',
    input: 'text',
  },

  matching_entity__hostname: {
    ui_id: 'matching_entity__hostname',
    id: 'table.matching_entity__hostname',
    field: 'table.matching_entity__hostname',
    type: 'string',
    input: 'text',
  },

  matching_entity__network: {
    ui_id: 'matching_entity__network',
    id: 'table.matching_entity__network',
    field: 'table.matching_entity__network',
    type: 'string',
    input: 'text',
  },

  matching_entity__target: {
    ui_id: 'matching_entity__target',
    id: 'table.matching_entity__target',
    field: 'table.matching_entity__target',
    type: 'string',
    input: 'text',
  },

  matching_entity__term: {
    ui_id: 'matching_entity__term',
    id: 'table.matching_entity__term',
    field: 'table.matching_entity__term',
    type: 'string',
    input: 'text',
  },

  matching_entity__asset: {
    ui_id: 'matching_entity__asset',
    id: 'table.matching_entity__asset',
    field: 'table.matching_entity__asset',
    type: 'string',
    input: 'text',
  },

  runbook_status: {
    ui_id: 'runbook_status',
    condition: 'OR',
    rules: [],
  },

  authorization_state: {
    ui_id: 'attack_authorization',
    condition: 'OR',
    rules: [],
  },

  detection_authorization: {
    ui_id: 'detection_authorization',
    condition: 'OR',
    rules: [],
  },

  redirector_status: {
    ui_id: 'redirector_status',
    condition: 'OR',
    rules: [],
  },

  implant_status: {
    ui_id: 'implant_status',
    condition: 'OR',
    rules: [],
  },

  blueprint_approval_status: {
    ui_id: 'blueprint_approval_status',
    condition: 'OR',
    rules: [],
  },

  usage: {
    ui_id: 'usage',
    condition: 'OR',
    rules: [],
  },

  state: {
    ui_id: 'bar_state',
    condition: 'OR',
    rules: [],
  },

  priority_score: {
    ui_id: 'priority_score',
    condition: 'OR',
    rules: [],
  },

  service_deleted: {
    ui_id: 'service_deleted',
    condition: 'OR',
    rules: [],
  },

  suggestion_bogus: {
    ui_id: 'suggestion_bogus',
    condition: 'OR',
    rules: [],
  },

  artifact_type: {
    ui_id: 'artifactType',
    condition: 'OR',
    rules: [],
  },

  target_artifact_type: {
    ui_id: 'target_artifact_type',
    condition: 'OR',
    rules: [],
  },

  deleted: {
    ui_id: 'deleted',
    condition: 'OR',
    rules: [],
  },

  src_ip_many: {
    ui_id: 'src_ip_many',
    condition: 'OR',
    rules: [],
  },

  src_host_many: {
    ui_id: 'src_host_many',
    condition: 'OR',
    rules: [],
  },

  src_mac_many: {
    ui_id: 'src_mac_many',
    condition: 'OR',
    rules: [],
  },

  src_email_many: {
    ui_id: 'src_email_many',
    condition: 'OR',
    rules: [],
  },

  src_misc_many: {
    ui_id: 'src_misc_many',
    condition: 'OR',
    rules: [],
  },

  dst_ip_many: {
    ui_id: 'dst_ip_many',
    condition: 'OR',
    rules: [],
  },

  dst_host_many: {
    ui_id: 'dst_host_many',
    condition: 'OR',
    rules: [],
  },

  dst_email_many: {
    ui_id: 'dst_email_many',
    condition: 'OR',
    rules: [],
  },

  dst_mac_many: {
    ui_id: 'dst_mac_many',
    condition: 'OR',
    rules: [],
  },

  dst_network_many: {
    ui_id: 'dst_network_many',
    condition: 'OR',
    rules: [],
  },

  dst_path_many: {
    ui_id: 'dst_path_many',
    condition: 'OR',
    rules: [],
  },

  dst_port_many: {
    ui_id: 'dst_port_many',
    condition: 'OR',
    rules: [],
  },

  dst_misc_many: {
    ui_id: 'dst_misc_many',
    condition: 'OR',
    rules: [],
  },

  report_type: {
    ui_id: 'report_type',
    condition: 'OR',
    rules: [],
  },

  authorized_detections: {
    ui_id: 'authorized_detections',
    condition: 'OR',
    rules: [],
  },

  detection_criteria__hostname: {
    ui_id: 'detection_criteria__hostname',
    id: 'table.detection_criteria__hostname',
    field: 'table.detection_criteria__hostname',
    type: 'string',
    input: 'text',
  },

  detection_criteria__ip: {
    ui_id: 'detection_criteria__ip',
    id: 'table.detection_criteria__ip',
    field: 'table.detection_criteria__ip',
    type: 'string',
    input: 'text',
  },

  detection_criteria__ip_str: {
    ui_id: 'detection_criteria__ip_str',
    id: 'table.detection_criteria__ip_str',
    field: 'table.detection_criteria__ip_str',
    type: 'string',
    input: 'text',
  },

  detection_criteria__path: {
    ui_id: 'detection_criteria__path',
    id: 'table.detection_criteria__path',
    field: 'table.detection_criteria__path',
    type: 'string',
    input: 'text',
  },

  detection_criteria__port: {
    ui_id: 'detection_criteria__port',
    id: 'table.detection_criteria__port',
    field: 'table.detection_criteria__port',
    type: 'integer',
    input: 'text',
  },

  detection_criteria__port_str: {
    ui_id: 'detection_criteria__port_str',
    id: 'table.detection_criteria__port_str',
    field: 'table.detection_criteria__port_str',
    type: 'string',
    input: 'text',
  },

  detection_criteria__protocols: {
    ui_id: 'detection_criteria__protocols',
    id: 'table.detection_criteria__protocols',
    field: 'table.detection_criteria__protocols',
    type: 'array',
    input: 'text',
  },

  discovered_service__names: {
    ui_id: 'discovered_service__names',
    id: 'table.discovered_service__names',
    field: 'table.discovered_service__names',
    type: 'array',
    input: 'text',
  },

  consolidated_target__names: {
    ui_id: 'consolidated_target__names',
    id: 'table.consolidated_target__names',
    field: 'table.consolidated_target__names',
    type: 'array',
    input: 'text',
  },
}
