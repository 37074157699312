/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Actions from '@/store/actions'

// ---------------------------------------------------------------------------

type OrganizationInfoReducerState = Codecs.Organization
export const OrganizationInfoReducer = (
  state = {} as Codecs.Organization,
  action: Actions.ActionTypes,
): OrganizationInfoReducerState => {
  switch (action.type) {
    case Actions.OrganizationActions.TypeKeys.ORGANIZATION_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}
