/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EntityPagination, IpP as Ip } from '@/codecs'

import { EntityName, initialState, State } from './ip.constants'
import { normalizeIps } from './ip.schema'

// ---------------------------------------------------------------------------

export const ipSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    // base
    // ------------------------------------------------------------------------

    replaceIps: (state, action: PayloadAction<ReturnType<typeof normalizeIps>>) => {
      state.result = action.payload.result

      state.entities = {
        ip: {
          ...state.entities.ip,
          ...action.payload.entities.ip,
        },
      }
    },

    replaceIpTotals: (state, action: PayloadAction<State['totals']>) => {
      state.totals = action.payload
    },

    replaceIpPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },

    upsertIps: (state, action: PayloadAction<ReturnType<typeof normalizeIps>>) => {
      state.entities = {
        ip: {
          ...state.entities.ip,
          ...action.payload.entities.ip,
        },
      }
    },

    upsertIp: (state, action: PayloadAction<Ip>) => {
      const id = action.payload.id

      state.entities.ip[id] = action.payload
    },
  },
})
