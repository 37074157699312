/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { EntityType } from '../entity-types'

export const supportsAddingAsAffiliation = (entityType: EntityType): boolean => {
  switch (entityType) {
    case 'globalHostname':
    case 'globalIps':
    case 'globalNetwork':
    case 'term':
    case 'poc':
      return true

    default:
      return false
  }
}
