/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

type Noop = {
  (...args: any[]): void
}

export const noop: Noop = () => undefined
