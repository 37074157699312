/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { createSelector } from 'reselect'

import * as WorkatoUtils from '@/pages/workato/utils'
import * as Store from '@/store'
import { i18n } from '@/utilities/i18n'

// ---------------------------------------------------------------------------

const _getConnections = (state: Store.AppState) => state.integration.connections
const _getRecipes = (state: Store.AppState) => state.integration.recipes
const _getDocumentation = (_state: Store.AppState) => undefined

export const selectConnections = createSelector([_getConnections], (_connections) => {
  return _connections.result
    .map((id: string) => _connections.entities.connection[id])
    .filter(Boolean)
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
})

export const selectAuthorizedConnections = createSelector([selectConnections], (connections) => {
  return connections
    .filter((connection) => {
      return isNotNil(connection.authorization_status) && connection.authorization_status === 'success'
    })
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
})

export const selectRecipes = createSelector([_getRecipes], (recipes) => {
  return recipes.result.sort((a, b) => {
    const AConnectionName = WorkatoUtils.extractConnectionNameFromRecipe(a.name)
    const BConnectionName = WorkatoUtils.extractConnectionNameFromRecipe(b.name)

    if (AConnectionName === BConnectionName) {
      return a.description < b.description ? -1 : 1
    }
    return AConnectionName < BConnectionName ? -1 : 1
  })
})

export const selectDocumentation = createSelector([_getDocumentation], () => {
  return [
    {
      desc: i18n.t('marketplace-docs.cloudpak.desc'),
      displayName: i18n.t('marketplace-docs.cloudpak.displayName'),
      link: i18n.t('marketplace-docs.cloudpak.link'),
      linkTitle: i18n.t('marketplace-docs.cloudpak.linkTitle'),
      name: i18n.t('marketplace-docs.cloudpak.name'),
    },
    {
      desc: i18n.t('marketplace-docs.qradarsiem.desc'),
      displayName: i18n.t('marketplace-docs.qradarsiem.displayName'),
      link: i18n.t('marketplace-docs.qradarsiem.link'),
      linkTitle: i18n.t('marketplace-docs.qradarsiem.linkTitle'),
      name: i18n.t('marketplace-docs.qradarsiem.name'),
    },
    {
      desc: i18n.t('marketplace-docs.qradarsoar.desc'),
      displayName: i18n.t('marketplace-docs.qradarsoar.displayName'),
      link: i18n.t('marketplace-docs.qradarsoar.link'),
      linkTitle: i18n.t('marketplace-docs.qradarsoar.linkTitle'),
      name: i18n.t('marketplace-docs.qradarsoar.name'),
    },
  ]
})
