/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const RunbooksReducer = (state = Schemas.AttackSchemas.NormalizeRunbooks([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.RUNBOOKS_STORE_UPDATE: {
      const { entities, result } = Schemas.AttackSchemas.NormalizeRunbooks(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          runbook: {
            ...state.entities.runbook,
            ...entities.runbook,
          },
        },
      }
    }

    case Store.AttackActions.TypeKeys.RUNBOOK_STORE_UPDATE: {
      const previous = state.entities.runbook[action.payload.id]

      return {
        result: state.result,
        entities: {
          ...state.entities,
          runbook: {
            ...state.entities.runbook,
            [action.payload.id]: {
              ...previous,
              ...action.payload,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export const RunbooksTotalsInitialState: Codecs.EntityTotals = {
  unfilteredTotal: 0,
  unaffiliatedTotal: 0,
}

export const RunbooksTotalsReducer = (state = RunbooksTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.RUNBOOK_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const RunbooksPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const RunbooksPaginationReducer = (state = RunbooksPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.RUNBOOKS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
