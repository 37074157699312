/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { propOr } from 'ramda'
import { createSelector } from 'reselect'

// ---------------------------------------------------------------------------
import { AppState } from '@/store'

// ---------------------------------------------------------------------------

const _getUser = (state: AppState) => state.user

// ---------------------------------------------------------------------------

export const selectUser = createSelector([_getUser], (user) => {
  return user
})

export const selectUserId = createSelector([_getUser], (user) => {
  const userId: string = propOr('', 'id', user)

  return userId
})

export const selectUserName = createSelector([_getUser], (user) => {
  const name: string = propOr('', 'name', user)

  return name
})

export const selectUserCurrentOrg = createSelector([_getUser], (user) => {
  const viewOrg: string = propOr('', 'view_org', user)

  return viewOrg
})
