/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createAction } from '@reduxjs/toolkit'
import { DeferredAction } from 'redux-saga-try-catch'

import { TripleUUID, UUID } from '@/utilities/codec'

import { makeFetchMany, makeFetchSingle, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './target.constants'

// ---------------------------------------------------------------------------

export const makeFetchTargetsForEntity = <T extends string, U extends string>(entityName: T, forEntityName: U) =>
  createAction(
    `${entityName}/fetch-many-for-${forEntityName}`,
    (
      payload: {
        entityId: TripleUUID | UUID
        limit: number
        offset: number
        perspectiveId: UUID | null
      },
      deferred: DeferredAction['meta']['deferred'],
      persist?: boolean,
    ) => {
      const _persist = persist ?? true

      return {
        meta: { deferred, persist: _persist },
        payload,
      }
    },
  )

export const fetchTarget = makeFetchSingle(EntityName)
export const fetchTargetTotals = makeFetchTotals(EntityName)
export const fetchTargets = makeFetchMany(EntityName)

export const fetchTargetByTargetId = createAction(
  `${EntityName}/fetch-target-by-target_id`,
  (payload: UUID, deferred: DeferredAction['meta']['deferred'], persist?: boolean) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)

export const fetchTargetsForHostname = makeFetchTargetsForEntity(EntityName, 'hostname')
export const fetchTargetsForIp = makeFetchTargetsForEntity(EntityName, 'ip')
export const fetchTargetsForService = makeFetchTargetsForEntity(EntityName, 'service')

export const fetchTargetsForNetwork = createAction(
  `${EntityName}/fetch-many-for-network`,
  (
    payload: {
      entityId: TripleUUID | UUID
      limit: number
      networkStr: string
      offset: number
      perspectiveId: UUID | null
    },
    deferred: DeferredAction['meta']['deferred'],
    persist?: boolean,
  ) => {
    const _persist = persist ?? true

    return {
      meta: { deferred, persist: _persist },
      payload,
    }
  },
)
