/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

export enum PeerGroupType {
  size = 'SIZE',
  industry = 'INDUSTRY',
}

// wat???
//
// @TODO: There is some severely cached stuff going on with webpack...
//
// ERROR in src/utilities/peer/peer-types.ts
// [unknown]: Parsing error: "parserOptions.project" has been set for @typescript-eslint/parser.
// The file does not match your project config: src/utilities/peer/peer-types.ts.
// The file must be included in at least one of the projects provided.
// export const getPeerGroupType = () => PeerGroupType
