/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

type Factor = keyof typeof Codecs.TemptationFactor

type TTFactorReducerState = { [factor in Factor]?: boolean }

const initialState = Object.keys(Codecs.TemptationFactor).reduce((acc, el) => {
  acc[el as Factor] = false

  return acc
}, {} as TTFactorReducerState)

export const TTFactorReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SHOW_FACTOR_DESC: {
      return {
        ...state,
        [action.payload]: true,
      }
    }

    case Store.UIActions.TypeKeys.HIDE_FACTOR_DESC: {
      return {
        ...state,
        [action.payload]: false,
      }
    }

    case Store.UIActions.TypeKeys.TOGGLE_FACTOR_DESC: {
      const prevState = state[action.payload] ?? false

      return {
        ...state,
        [action.payload]: !prevState,
      }
    }

    default:
      return state
  }
}
