/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import { PathActions } from '@/store'
import { FetchPathToNearestPrime } from '@/store/actions/path'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchPaths(io: MiddlewaresIO) {
  yield takeEvery(
    PathActions.TypeKeys.PATH_TO_NEAREST_PRIME_FETCH,
    Catch.deferredAction(_PATH_TO_NEAREST_PRIME_FETCH, io),
  )
}

export function* _PATH_TO_NEAREST_PRIME_FETCH(io: MiddlewaresIO, action: FetchPathToNearestPrime) {
  const response = yield* call(io.api.path.getPaths, action.payload.id)

  return response
}
