/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Perspective, PerspectiveName } from '../perspective.codecs'

// ---------------------------------------------------------------------------

export type IpForNetwork = t.TypeOf<typeof IpForNetwork>
export const IpForNetwork = t.type({
  characteristic_tags: t.array(t.string),
  confidence: t.union([t.number, t.null]),
  country: t.union([t.string, t.null]),
  hostname: t.union([t.string, t.null]),
  id: t.string,
  ip: t.union([t.string, t.null]),
  ip_id: t.union([t.string, t.null]),
  ip_str: t.union([t.string, t.null]),
  last_seen: t.union([t.string, t.null]),
  latitude: t.union([t.number, t.null]),
  longitude: t.union([t.number, t.null]),
  network: t.union([t.string, t.null]),
  network_id: t.union([t.string, t.null]),
  network_str: t.union([t.string, t.null]),
  open_port_count: t.union([t.number, t.null]),
  org_id: t.union([t.string, t.null]),
  perspective: Perspective,
  perspective_name: PerspectiveName,
  radius: t.union([t.number, t.null]),
  service_count: t.union([t.number, t.null]),
  target_count: t.union([t.number, t.null]),
  target_temptation: t.union([t.number, t.null]),
  user_tags: t.array(t.string),
})
