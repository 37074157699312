/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { Path } from '@/components/hdyft/hdyft.utils'
import { isNotNil } from '@/utilities/is-not'
import type { EntityType } from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export class RandoriPathError extends Error {
  path: Path

  constructor(message: { entityType: EntityType; entityId: string; path: Path }, ...rest: string[]) {
    super(...rest)

    this.message = `Encountered bad path for ${message.entityType}:${message.entityId}`
    this.name = 'RandoriPathError'
    this.path = message.path

    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (isNotNil(Error.captureStackTrace)) {
      Error.captureStackTrace(this, RandoriPathError)
    } else {
      this.stack = new Error(`Encountered bad path for ${message.entityType}:${message.entityId}`).stack
    }
  }
}
