/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { FormikErrors, FormikTouched } from 'formik'
import { keys } from 'ramda'
import * as React from 'react'

export function getErrorMessages<T>(errors: FormikErrors<T>, touched: Array<keyof T>) {
  const intersection = touched.reduce((acc, propName) => {
    const extractedError = errors[propName]

    return acc.concat(extractedError)
  }, [] as FormikErrors<T>[keyof T][])

  // shake out undefined
  return intersection.filter(Boolean)
}

export function ErrorMessages<T>(errors: FormikErrors<T>, touched: FormikTouched<T>) {
  const touchedKeys = keys(touched)
  const errorMessages = getErrorMessages<T>(errors, touchedKeys)

  // eslint-disable-next-line no-magic-numbers
  return errorMessages.length > 0 ? (
    <ul>
      {errorMessages.map((el, i) => {
        return <li key={i}>{el}</li>
      })}
    </ul>
  ) : null
}
