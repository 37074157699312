/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import type {
  ACTIVITY_CONFIGURATION_FETCH,
  ACTIVITY_CONFIGURATION_PATCH_ENABLED,
  ACTIVITY_CONFIGURATIONS_FETCH,
  ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH,
} from '@/store/actions/activity/activity-configuration.actions'
import type {
  ACTIVITIES_FETCH,
  ACTIVITY_FETCH,
  ACTIVITY_TOTAL_COUNT_FETCH,
} from '@/store/actions/activity/activity-instance.actions'
import type {
  APPLICABLE_ACTIVITIES_FETCH,
  VALIDATE_JOB_POST,
} from '@/store/actions/activity/applicable-activity.actions'
import type { APPLICABLE_ENTITIES_FETCH } from '@/store/actions/activity/applicable-entity.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export function* watchActivities(io: MiddlewaresIO) {
  // activity instance
  yield takeEvery(
    Store.ActivityActions.ActivityInstance.TypeKeys.ACTIVITY_FETCH,
    Catch.deferredAction(_ACTIVITY_FETCH, io),
  )
  yield takeEvery(
    Store.ActivityActions.ActivityInstance.TypeKeys.ACTIVITIES_FETCH,
    Catch.deferredAction(_ACTIVITIES_FETCH, io),
  )
  yield takeEvery(
    Store.ActivityActions.ActivityInstance.TypeKeys.ACTIVITIES_TOTAL_COUNT_FETCH,
    Catch.deferredAction(_ACTIVITY_TOTAL_COUNT_FETCH, io),
  )

  // activity configuration
  yield takeEvery(
    Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATION_FETCH,
    Catch.deferredAction(_ACTIVITY_CONFIGURATION_FETCH, io),
  )
  yield takeEvery(
    Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATION_PATCH_ENABLED,
    Catch.deferredAction(_ACTIVITY_CONFIGURATION_PATCH_ENABLED, io),
  )
  yield takeEvery(
    Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATIONS_FETCH,
    Catch.deferredAction(_ACTIVITY_CONFIGURATIONS_FETCH, io),
  )
  yield takeEvery(
    Store.ActivityActions.ActivityConfiguration.TypeKeys.ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH,
    Catch.deferredAction(_ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH, io),
  )

  // applicable activities
  yield takeEvery(
    Store.ActivityActions.ApplicableActivity.TypeKeys.APPLICABLE_ACTIVITIES_FETCH,
    Catch.deferredAction(_APPLICABLE_ACTIVITIES_FETCH, io),
  )
  yield takeEvery(
    Store.ActivityActions.ApplicableActivity.TypeKeys.VALIDATE_JOB_POST,
    Catch.deferredAction(_VALIDATE_JOB_POST, io),
  )

  // applicable entities (targets v0)
  yield takeEvery(
    Store.ActivityActions.ApplicableEntity.TypeKeys.APPLICABLE_ENTITIES_FETCH,
    Catch.deferredAction(_APPLICABLE_ENTITIES_FETCH, io),
  )
}

// Activity Log -- Activity Instance
// ---------------------------------------------------------------------------

export function* _ACTIVITY_TOTAL_COUNT_FETCH(io: MiddlewaresIO, action: ACTIVITY_TOTAL_COUNT_FETCH) {
  const {
    payload,
    meta: { updateStore = false },
  } = action

  const { total } = yield* call(io.api.activities.getActivityInstances, `?${payload}`)

  if (updateStore) {
    yield* put(
      Store.ActivityActions.ActivityInstance.ACTIVITIES_TOTAL_STORE_UPDATE({
        unaffiliatedTotal: 0,
        unfilteredTotal: total,
      }),
    )
  }

  return { total }
}

export function* _ACTIVITY_FETCH(io: MiddlewaresIO, action: ACTIVITY_FETCH) {
  const { data } = yield* call(io.api.activities.getActivityInstance, action.payload)

  yield* put(Store.ActivityActions.ActivityInstance.ACTIVITY_STORE_UPDATE(data))

  return data
}

export function* _ACTIVITIES_FETCH(io: MiddlewaresIO, action: ACTIVITIES_FETCH) {
  const data = yield* call(
    io.api.activities.getActivityInstances,
    CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(`${action.payload}&reversed_nulls=true`)),
  )

  yield* put(Store.ActivityActions.ActivityInstance.ACTIVITIES_STORE_UPDATE(data))

  return data
}

// Activity Library -- Activity Configuration
// ---------------------------------------------------------------------------

export function* _ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH(
  io: MiddlewaresIO,
  action: ACTIVITY_CONFIGURATIONS_TOTAL_COUNT_FETCH,
) {
  const {
    payload,
    meta: { updateStore = false },
  } = action

  const { meta } = yield* call(io.api.activities.getActivityConfigurations, `?${payload}`)

  if (updateStore) {
    yield* put(
      Store.ActivityActions.ActivityConfiguration.ACTIVITY_CONFIGURATIONS_TOTAL_STORE_UPDATE({
        unaffiliatedTotal: 0,
        unfilteredTotal: meta.total,
      }),
    )
  }

  return { total: meta.total }
}

export function* _ACTIVITY_CONFIGURATION_FETCH(io: MiddlewaresIO, action: ACTIVITY_CONFIGURATION_FETCH) {
  const response = yield* call(io.api.activities.getActivityConfiguration, action.payload.id)

  yield* put(Store.ActivityActions.ActivityConfiguration.ACTIVITY_CONFIGURATION_STORE_UPDATE(response))

  return response
}

export function* _ACTIVITY_CONFIGURATION_PATCH_ENABLED(
  io: MiddlewaresIO,
  action: ACTIVITY_CONFIGURATION_PATCH_ENABLED,
) {
  const response = yield* call(
    io.api.activities.patchActivityConfigurationEnabled,
    action.payload.id,
    action.payload.enabled,
  )

  yield* put(Store.ActivityActions.ActivityConfiguration.ACTIVITY_CONFIGURATION_PATCH_STORE_UPDATE(response))

  return response
}

export function* _ACTIVITY_CONFIGURATIONS_FETCH(io: MiddlewaresIO, action: ACTIVITY_CONFIGURATIONS_FETCH) {
  const response = yield* call(
    io.api.activities.getActivityConfigurations,
    CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(`${action.payload}&reversed_nulls=true`)), // need reversed nulls?
  )

  yield* put(Store.ActivityActions.ActivityConfiguration.ACTIVITY_CONFIGURATIONS_STORE_UPDATE(response))

  return response
}

// Applicable Activities
// ---------------------------------------------------------------------------

export function* _APPLICABLE_ACTIVITIES_FETCH(io: MiddlewaresIO, action: APPLICABLE_ACTIVITIES_FETCH) {
  const { entityType, entityId: id, query } = action.payload

  // convert to an authoritative entity type before passing to ACS
  const authoritativeEntityType = EntityUtils.mapEntityTypeToTriggerAsset(entityType)

  const config = {
    entityType: authoritativeEntityType,
    entityId: id,
    query: query,
  }

  // Note: for Applicable Activities v0, entityType is expected to only be 'org_target'
  const response = yield* call(io.api.activities.getApplicableActivities, config)

  // Note: revisit and remove store update if we do not want to store applicable activities
  yield* put(Store.ActivityActions.ApplicableActivity.APPLICABLE_ACTIVITIES_STORE_UPDATE(response))

  return response
}

export function* _VALIDATE_JOB_POST(io: MiddlewaresIO, action: VALIDATE_JOB_POST) {
  const { entityType, entityId, configurationId } = action.payload

  // convert to an authoritative entity type before passing to ACS
  const authoritativeEntityType = EntityUtils.mapEntityTypeToTriggerAsset(entityType)

  const payload = {
    entityType: authoritativeEntityType,
    entityId,
    configurationId,
  }

  const jobId = yield* call(io.api.activities.postValidateJob, payload)

  return jobId
}

// Applicable Entities
// ---------------------------------------------------------------------------

/*
 * Note: For the current implementation of Applicable Targets (as of 02/07/2024), we are utilizing the applicable-entities endpoint and specifying an entity type of org_target.
 * Once we get target ids back from that endpoint, we will construct an appropriate query to be used for a subsequent single-detection-for-target call which returns
 * targets matching any of the target ids from that query. Note that if we do not initially get any target ids, we will not make the single-detection-for-target
 * call, and, instead, return an empty response.
 */
export function* _APPLICABLE_ENTITIES_FETCH(io: MiddlewaresIO, action: APPLICABLE_ENTITIES_FETCH) {
  const { configurationId: id, entityType, query: applicableEntitiesQuery } = action.payload

  const config = {
    configurationId: id,
    entityType: entityType,
    query: applicableEntitiesQuery,
  }

  const applicableEntitiesResponse = yield* call(io.api.activities.getApplicableEntitiesForConfiguration, config)

  yield* put(Store.ActivityActions.ApplicableEntity.APPLICABLE_ENTITIES_STORE_UPDATE(applicableEntitiesResponse))

  const { data: applicableEntities, meta } = applicableEntitiesResponse
  const { total: applicableEntitiesTotal, offset: applicableEntitiesOffset } = meta

  const targetIds = applicableEntities.map((target) => target.id)

  // Only call single-detection-for-target if we have any target ids
  if (targetIds.length > 0) {
    const targetIdsMappedToRules = targetIds.map((id) => {
      return {
        id: 'table.target_id',
        field: 'table.target_id',
        type: 'object',
        input: 'text',
        operator: 'equal',
        value: id,
      }
    })

    const q = {
      condition: 'OR',
      rules: [...targetIdsMappedToRules],
    }

    const serializedQ = CrudQueryUtils.serializeQ(q)

    const applicableTargetsData = yield* call(io.api.target.getTargets, get(`?&q=${serializedQ}`, QueryString))

    // A response object comprised of data from the targets response but uses the offset and total of the applicable entities response in order to properly manage pagination
    const reconstructedApplicableTargetsData = {
      ...applicableTargetsData,
      offset: applicableEntitiesOffset,
      total: applicableEntitiesTotal,
    }

    return reconstructedApplicableTargetsData
  }

  // Return an empty response if we didn't get any target ids back from applicable-entities
  const emptyResponse = {
    data: [],
    count: 0,
    offset: 0,
    total: 0,
  }

  return emptyResponse
}
