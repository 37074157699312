/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import {
  __VERSION_1__,
  __VERSION_2__,
  __VERSION_3__,
  __VERSION_4__,
  ActiveEntityStat,
  DetectionTarget,
  PeerData,
  ReportCharacteristic,
  ReportType,
  RiskMatrixItem,
  TargetsByAgeItem,
  TopTargetCountItem,
} from './reports-codecs.utils'

// ---------------------------------------------------------------------------

export const v1ASAReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_1__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      active_assets: t.interface({
        hostname: ActiveEntityStat,
        ip: ActiveEntityStat,
        network: ActiveEntityStat,
        service: ActiveEntityStat,
      }),
      characteristics: t.interface({
        all: ActiveEntityStat,
        top: t.array(ReportCharacteristic),
      }),
      critical_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      peer_data: t.undefined, // @TODO: This is a goofy way to Union
      high_priority_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      targets_by_age: CodecUtils.nullable(t.array(t.array(TargetsByAgeItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
      work: t.interface({
        actions: CodecUtils.nullable(t.number),
        alerts: CodecUtils.nullable(t.number),
        artifacts: CodecUtils.nullable(t.number),
        targets: CodecUtils.nullable(t.number),
      }),
    }),
  }),
})
export type v1ASAReport = t.TypeOf<typeof v1ASAReport>

export const v2ASAReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_2__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      active_assets: t.interface({
        hostname: ActiveEntityStat,
        ip: ActiveEntityStat,
        network: ActiveEntityStat,
        service: ActiveEntityStat,
      }),
      characteristics: t.interface({
        all: ActiveEntityStat,
        top: t.array(ReportCharacteristic),
      }),
      critical_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      high_priority_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      peer_data: PeerData,
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      targets_by_age: CodecUtils.nullable(t.array(t.array(TargetsByAgeItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
      work: t.interface({
        actions: CodecUtils.nullable(t.number),
        alerts: CodecUtils.nullable(t.number),
        artifacts: CodecUtils.nullable(t.number),
        targets: CodecUtils.nullable(t.number),
      }),
    }),
  }),
})
export type v2ASAReport = t.TypeOf<typeof v2ASAReport>

export const v3ASAReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_3__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      active_assets: t.interface({
        hostname: ActiveEntityStat,
        ip: ActiveEntityStat,
        network: ActiveEntityStat,
        service: ActiveEntityStat,
      }),
      characteristics: t.interface({
        all: ActiveEntityStat,
        top: t.array(ReportCharacteristic),
      }),
      critical_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      high_priority_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      peer_data: PeerData,
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      targets_by_age: CodecUtils.nullable(t.array(t.array(TargetsByAgeItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
      work: t.interface({
        actions: CodecUtils.nullable(t.number),
        alerts: CodecUtils.nullable(t.number),
        artifacts: CodecUtils.nullable(t.number),
        targets: CodecUtils.nullable(t.number),
      }),
    }),
  }),
})
export type v3ASAReport = t.TypeOf<typeof v3ASAReport>

export const v4ASAReport = t.interface({
  created: t.string,
  id: t.string,
  org_id: t.string,
  type: ReportType,
  version: t.literal(__VERSION_4__),
  report_data: t.interface({
    peer_size: CodecUtils.nullable(t.string),
    peer_industry: CodecUtils.nullable(t.string),
    categories: t.interface({
      active_assets: t.interface({
        hostname: ActiveEntityStat,
        ip: ActiveEntityStat,
        network: ActiveEntityStat,
        service: ActiveEntityStat,
      }),
      characteristics: t.interface({
        all: ActiveEntityStat,
        top: t.array(ReportCharacteristic),
      }),
      critical_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      high_priority_targets: CodecUtils.nullable(t.array(DetectionTarget)),
      peer_data: PeerData,
      risk_matrix: CodecUtils.nullable(t.array(t.array(RiskMatrixItem))),
      targets_by_age: CodecUtils.nullable(t.array(t.array(TargetsByAgeItem))),
      top_targets: CodecUtils.nullable(
        t.interface({
          current: TopTargetCountItem,
          previous: CodecUtils.nullable(TopTargetCountItem),
        }),
      ),
      work: t.interface({
        actions: CodecUtils.nullable(t.number),
        alerts: CodecUtils.nullable(t.number),
        artifacts: CodecUtils.nullable(t.number),
        targets: CodecUtils.nullable(t.number),
      }),
    }),
  }),
})
export type v4ASAReport = t.TypeOf<typeof v4ASAReport>

export const ASAReport = t.union([v1ASAReport, v2ASAReport, v3ASAReport, v4ASAReport])
export type ASAReport = t.TypeOf<typeof ASAReport>

export const ASAReportResponse = t.interface({
  data: t.union([v1ASAReport, v2ASAReport, v3ASAReport, v4ASAReport]),
})
export type ASAReportResponse = t.TypeOf<typeof ASAReportResponse>
