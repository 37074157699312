/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'

// ---------------------------------------------------------------------------
import * as Actions from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:store')

export const OrganizationFeaturesReducer = (
  state = Schemas.OrganizationSchemas.NormalizeOrgFeatures([]),
  action: Actions.ActionTypes,
) => {
  switch (action.type) {
    case Actions.OrganizationActions.TypeKeys.FEATURES_UPDATE: {
      const features = Schemas.OrganizationSchemas.NormalizeOrgFeatures(action.payload)

      log('features: %O', features.result)

      return features
    }

    default:
      return state
  }
}
