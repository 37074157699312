/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { without } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const AuthorizationPoliciesReducer = (
  state = Schemas.Recon.NormalizePolicies([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.AUTHORIZATION_POLICIES_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizePolicies(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          policy: {
            ...state.entities.policy,
            ...entities.policy,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_STORE_UPDATE: {
      return {
        result: state.result,
        entities: {
          ...state.entities,
          policy: {
            ...state.entities.policy,
            [action.payload.id]: action.payload,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_DELETE_STORE_UPDATE: {
      return {
        ...state,
        result: without([action.payload], state.result),
      }
    }

    default:
      return state
  }
}

const PolicyTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const AuthorizationPolicyTotalsReducer = (state = PolicyTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    case Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_DELETE_STORE_UPDATE: {
      return {
        unfilteredTotal: state.unfilteredTotal - 1,
        unaffiliatedTotal: 0,
      }
    }

    default:
      return state
  }
}

const PoliciesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const AuthorizationPoliciesPaginationReducer = (
  state = PoliciesPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.AUTHORIZATION_POLICIES_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    case Store.ReconActions.TypeKeys.AUTHORIZATION_POLICY_DELETE_STORE_UPDATE: {
      return {
        count: state.count - 1,
        offset: state.offset,
        total: state.total - 1,
      }
    }

    default:
      return state
  }
}
