/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type MitreApi = {
  getMitreMitigation: (id: string) => Promise<Codecs.MitreMitigationResponse>
  getMitreTactic: (id: string) => Promise<Codecs.MitreTacticResponse>
  getMitreTechnique: (id: string) => Promise<Codecs.MitreTechniqueResponse>
}

export function mitreApiFactory(http: HttpClient): MitreApi {
  const baseUrl = '/recon/api/v1/mitre'

  return {
    getMitreMitigation(_id) {
      return http
        .get(`${baseUrl}/mitigation/${_id}`)
        .then(CodecUtils.throwValidate(Codecs.MitreMitigationResponse, 'MitreMitigationResponse'))
    },

    getMitreTactic(_id) {
      return http
        .get(`${baseUrl}/tactic/${_id}`)
        .then(CodecUtils.throwValidate(Codecs.MitreTacticResponse, 'MitreTacticResponse'))
    },

    getMitreTechnique(_id) {
      return http
        .get(`${baseUrl}/technique/${_id}`)
        .then(CodecUtils.throwValidate(Codecs.MitreTechniqueResponse, 'MitreTechniqueResponse'))
    },
  }
}
