/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { CPE } from './cpe.codecs'
import { TechCategoryList } from './tech-category.codecs'
import { TemptationFactorLevel } from './temptation.codecs'
import { TextSource } from './text-source.codecs'

// ---------------------------------------------------------------------------

export const GlobalService = t.interface({
  attack_note: t.union([t.string, t.null]),
  cpe: CodecUtils.nullable(CPE),
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.union([t.string, t.null]),
  deleted: t.boolean,
  description: t.union([t.string, t.null]),
  description_source: TextSource,
  end_of_life: t.boolean,
  first_time: t.string,
  id: t.string,
  name: t.union([t.string, t.null]),
  promoted: t.boolean,
  promoter: t.union([t.string, t.null]),
  promotion_time: t.union([t.string, t.null]), // iso-string
  randori_notes: t.union([t.string, t.null]),
  reference: t.union([t.string, t.null]),
  svc_type: t.union([t.string, t.null]),
  sys_period: t.string,
  tech_category: CodecUtils.nullable(TechCategoryList),
  time: t.string,
  type: t.union([t.string, t.null]),
  vendor: t.union([t.string, t.null]),
  version: t.union([t.string, t.null]),

  // @TODO: GlobalService does not have `exploitability`, so we won't use
  // `withTemptation`
  applicability: t.union([TemptationFactorLevel, t.null]),
  criticality: t.union([TemptationFactorLevel, t.null]),
  enumerability: t.union([TemptationFactorLevel, t.null]),
  post_exploit: t.union([TemptationFactorLevel, t.null]),
  private_weakness: t.union([TemptationFactorLevel, t.null]),
  public_weakness: t.union([TemptationFactorLevel, t.null]),
  research: t.union([TemptationFactorLevel, t.null]),
  target_temptation: t.union([t.number, t.null]),
})
export type GlobalService = t.TypeOf<typeof GlobalService>

export const GlobalServicesResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(GlobalService),
})
export type GlobalServicesResponse = t.TypeOf<typeof GlobalServicesResponse>

// ---------------------------------------------------------------------------

const _GlobalServiceMeta = t.interface({
  affected_org_count: t.number,
  affected_org_list: t.array(t.string),
  deleted_org_count: t.number,
  deleted_org_list: t.array(t.string),
  deleted_target_count: t.number,
  // @TODO: This is the codec that *has* exploitability. This is a bit of
  // a mess, but the API has two different ORMs for GET/PATCH
  exploitability: t.union([TemptationFactorLevel, t.null]),
  target_count: t.number,
})

export const GlobalServiceWithStats = t.intersection([GlobalService, _GlobalServiceMeta])
export type GlobalServiceWithStats = t.TypeOf<typeof GlobalServiceWithStats>

export const GlobalServicesWithStatsResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(GlobalServiceWithStats),
})
export type GlobalServicesWithStatsResponse = t.TypeOf<typeof GlobalServicesWithStatsResponse>

// ---------------------------------------------------------------------------

export const GlobalServiceStats = t.interface({
  orgs_impacted: t.number,
})
export type GlobalServiceStats = t.TypeOf<typeof GlobalServiceStats>

export const GlobalServiceStatsResponse = GlobalServiceStats
export type GlobalServiceStatsResponse = t.TypeOf<typeof GlobalServiceStatsResponse>

// ---------------------------------------------------------------------------

export const GlobalServicePatchPayload = t.interface({
  // TODO: This partial doesn't work
  // data: t.partial(GlobalService)

  data: t.partial({
    id: t.string,

    cpe: CodecUtils.nullableP(CPE),
    creator_type: CodecUtils.nullableP(t.string),
    creator_uuid: CodecUtils.nullableP(t.string),
    deleted: t.boolean,
    description: t.union([t.string, t.null]),
    description_source: t.string,
    end_of_life: t.boolean,
    first_time: t.string,
    name: CodecUtils.nullableP(t.string),
    promoted: t.boolean,
    promoter: CodecUtils.nullableP(t.string),
    promotion_time: CodecUtils.nullableP(t.string), // iso-string
    randori_notes: CodecUtils.nullableP(t.string),
    reference: CodecUtils.nullableP(t.string),
    svc_type: CodecUtils.nullableP(t.string),
    sys_period: t.string,
    target_temptation: CodecUtils.nullableP(t.number),
    time: t.string,
    type: CodecUtils.nullableP(t.string),
    vendor: CodecUtils.nullableP(t.string),
    version: CodecUtils.nullableP(t.string),

    applicability: CodecUtils.nullableP(t.number),
    criticality: CodecUtils.nullableP(t.number),
    enumerability: CodecUtils.nullableP(t.number),
    post_exploit: CodecUtils.nullableP(t.number),
    private_weakness: CodecUtils.nullableP(t.number),
    public_weakness: CodecUtils.nullableP(t.number),
    research: CodecUtils.nullableP(t.number),
  }),
})
export type GlobalServicePatchPayload = t.TypeOf<typeof GlobalServicePatchPayload>

export const GlobalServicePatchResponse = t.interface({
  data: GlobalService,
})
export type GlobalServicePatchResponse = t.TypeOf<typeof GlobalServicePatchResponse>

// ---------------------------------------------------------------------------

const MaybeTTValues = t.partial({
  applicability: t.number,
  criticality: t.number,
  enumerability: t.number,
  post_exploit: t.number,
  private_weakness: t.number,
  public_weakness: t.number,
  research: t.number,
})

export const GlobalServicesPostPayload = t.interface({
  data: t.array(
    t.intersection([
      MaybeTTValues,
      t.interface({
        deleted: t.boolean,
        promoted: t.boolean,

        description: t.string,
        description_source: t.string,
        end_of_life: t.boolean,
        name: t.string,
        randori_notes: t.string,
        reference: t.string,
        vendor: t.string,
        version: t.string,
      }),
    ]),
  ),
})
export type GlobalServicesPostPayload = t.TypeOf<typeof GlobalServicesPostPayload>

export const GlobalServicesPostResponse = t.interface({
  ids: t.array(t.string),
})
export type GlobalServicesPostResponse = t.TypeOf<typeof GlobalServicesPostResponse>

// ---------------------------------------------------------------------------

export const GlobalTagsPostPayload = t.interface({
  data: t.array(
    t.interface({
      deleted: t.boolean,
      dst: t.string,
      dst_type: t.literal('global_service'),
      tag: t.string,
    }),
  ),
})
export type GlobalTagsPostPayload = t.TypeOf<typeof GlobalTagsPostPayload>

export const GlobalTagsPostResponse = t.interface({
  ids: t.array(t.string),
})
export type GlobalTagsPostResponse = t.TypeOf<typeof GlobalTagsPostResponse>
