/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { isNil } from 'lodash/fp'

import type { Entity } from '@/codecs'
import * as CodecUtils from '@/utilities/codec'
import type { EntityType } from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export const getIdByEntityType = (config: { entity: Entity; entityType: EntityType }) => {
  switch (config.entityType) {
    case 'target': {
      const validator = t.type({ target_id: t.string })
      const entity = CodecUtils.throwValidate(validator, 'target_id')(config.entity)

      return entity.target_id
    }

    case 'hostnameForIp': {
      const validator = t.type({ hostname_id: t.string })
      const entity = CodecUtils.throwValidate(validator, 'hostname_id')(config.entity)

      return entity.hostname_id
    }

    case 'ipForHostname':
    case 'ipForNetwork': {
      const validator = t.type({ ip_id: t.union([t.string, t.null]) })
      const entity = CodecUtils.throwValidate(validator, 'ip_id')(config.entity)

      const id = entity.ip_id

      if (isNil(id)) {
        throw new Error('ip_id is nil')
      }

      return id
    }

    default: {
      return config.entity.id
    }
  }
}
