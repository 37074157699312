/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const PerspectiveType = t.keyof({
  PERSPECTIVE_TYPE_UNSPECIFIED: null,
  PERSPECTIVE_TYPE_INTERNAL: null,
  PERSPECTIVE_TYPE_EXTERNAL: null,
})
export type PerspectiveType = t.TypeOf<typeof PerspectiveType>

export const PerspectiveTypeRecon = t.keyof({
  external: null,
  internal: null,
})
export type PerspectiveTypeRecon = t.TypeOf<typeof PerspectiveTypeRecon>

export const PerspectiveTypeActivity = t.keyof({
  EXTERNAL: null,
  INTERNAL: null,
  external: null,
  internal: null,
})
export type PerspectiveTypeActivity = t.TypeOf<typeof PerspectiveTypeActivity>
