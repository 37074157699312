/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const GlobalNetwork = t.interface({
  id: t.string,
  affiliation: t.number,
  affiliation_display: t.number,
  affiliation_override: t.boolean,
  confidence: t.number,
  confidence_display: t.number,
  confidence_override: t.boolean,
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.union([t.string, t.null]),
  deleted: t.boolean,
  first_time: t.union([t.string, t.null]),
  network: t.string,
  lens_view: t.union([t.string, t.null]),
  // next_refresh: t.union([t.string, t.null]),
  org_id: t.string,
  perspective: t.union([t.string, t.null]),
  sys_period: t.union([t.string, t.null]),
  time: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
})

type _GlobalNetwork = t.TypeOf<typeof GlobalNetwork>
export type GlobalNetwork = _GlobalNetwork

export const GlobalNetworkResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(GlobalNetwork),
})
export type GlobalNetworkResponse = t.TypeOf<typeof GlobalNetworkResponse>

export const GlobalNetworkPostPayload = t.interface({
  data: t.array(
    t.interface({
      network: t.string,
      org_id: t.string,
      perspective: t.union([t.string, t.undefined]),
    }),
  ),
  parent_info: t.UnknownRecord, // @TODO
})

type _GlobalNetworkPostPayload = t.TypeOf<typeof GlobalNetworkPostPayload>
export type GlobalNetworkPostPayload = _GlobalNetworkPostPayload

export const GlobalNetworkPostResponse = t.interface({})

type _GlobalNetworkPostResponse = t.TypeOf<typeof GlobalNetworkPostResponse>
export type GlobalNetworkPostResponse = _GlobalNetworkPostResponse
