/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import { isNil } from 'lodash/fp'

import * as Store from '@/store/actions'
import { Flags } from '@/utilities/flags'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:FlagReducer')

type FlagReducerState = typeof Flags

const initialState: FlagReducerState = Flags

export const FlagReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.TOGGLE_FLAG: {
      if (isNil(state[action.payload])) {
        log(`${action.payload} is not a valid flag`)

        return state
      }

      const prevState = state[action.payload]
      const nextState = !prevState

      log(`setting flag ${action.payload} to ${nextState.toString()}`)

      return {
        ...state,
        [action.payload]: nextState,
      }
    }

    default:
      return state
  }
}
