/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ImplantsReducer = (state = Schemas.AttackSchemas.NormalizeImplants([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANTS_STORE_UPDATE: {
      const normalizdImplants = Schemas.AttackSchemas.NormalizeImplants(action.payload.data)

      return {
        result: normalizdImplants.result,
        entities: {
          ...state.entities,
          implant: {
            ...state.entities.implant,
            ...normalizdImplants.entities.implant,
          },
        },
      }
    }

    case Store.AttackActions.TypeKeys.IMPLANT_STORE_UPDATE: {
      const normalizdImplant = Schemas.AttackSchemas.NormalizeImplants([action.payload.data])

      return {
        ...state,
        entities: {
          ...state.entities,
          ...normalizdImplant.entities,
        },
      }
    }

    default:
      return state
  }
}

export const ImplantsTotalsInitialState: Codecs.EntityTotals = {
  unfilteredTotal: 0,
  unaffiliatedTotal: 0,
}

export const ImplantsTotalsReducer = (state = ImplantsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANT_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export const ImplantsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ImplantsPaginationReducer = (state = ImplantsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANTS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default: {
      return state
    }
  }
}

export const ImplantStatsInitialState: Codecs.ImplantStatsResponse = {
  count: 0,
  data: [],
  offset: 0,
  total: 0,
}

export const ImplantStatsReducer = (state = ImplantStatsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANT_STATS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export const ImplantCallbacksReducer = (
  state = Schemas.AttackSchemas.NormalizeImplantCallbacks([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANT_CALLBACKS_STORE_UPDATE: {
      const normalized = Schemas.AttackSchemas.NormalizeImplantCallbacks(action.payload.data)

      return {
        result: normalized.result,
        entities: {
          ...state.entities,
          callback: {
            ...state.entities.callback,
            ...normalized.entities.callback,
          },
        },
      }
    }

    default:
      return state
  }
}

export const ImplantCallbacksPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ImplantCallbacksPaginationReducer = (
  state = ImplantCallbacksPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANT_CALLBACKS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}

export const ImplantInterfacesReducer = (
  state = Schemas.AttackSchemas.NormalizeImplantInterfaces([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANT_INTERFACES_STORE_UPDATE: {
      const normalized = Schemas.AttackSchemas.NormalizeImplantInterfaces(action.payload.data)

      return {
        result: normalized.result,
        entities: {
          ...state.entities,
          interface: {
            ...state.entities.interface,
            ...normalized.entities.interface,
          },
        },
      }
    }

    default:
      return state
  }
}

export const ImplantInterfacesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const ImplantInterfacesPaginationReducer = (
  state = ImplantInterfacesPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.AttackActions.TypeKeys.IMPLANT_INTERFACES_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
