/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import * as t from 'io-ts'
import { Address4, Address6 } from 'ip-address'
import isCidr from 'is-cidr'
import { isEqual, isNil } from 'lodash/fp'

import { getSafe } from './codec-validators'

// ----------------------------------------------------------------------------

const log = debug('RANDORI:network')

export interface NetworkBrand {
  readonly Network: unique symbol
}
export type Network = t.Branded<string, NetworkBrand>
export const Network = t.brand(t.string, (s): s is Network => isNetwork(s), 'Network')

export interface ScannableInternalNetworkBrand {
  readonly ScannableInternalNetwork: unique symbol
}
export type ScannableInternalNetwork = t.Branded<string, ScannableInternalNetworkBrand>
export const ScannableInternalNetwork = t.brand(
  t.string,
  (s): s is ScannableInternalNetwork => isScannableInternalNetwork(s, true),
  'ScannableInternalNetwork',
)

// ----------------------------------------------------------------------------

// Assisted by WCA@IBM
// Latest GenAI contribution: ibm/granite-20b-code-instruct-v2
/**
 * Checks if a string is a valid IPv4 or IPv6 CIDR notation.
 *
 * @param network - The string to check.
 *
 * @returns A boolean indicating whether the string is a valid network.
 */
export const isNetwork = (network: string) => {
  switch (isCidr(network)) {
    case 4:
      return true

    case 6:
      return true

    default:
      return false
  }
}

// Assisted by WCA@IBM
// Latest GenAI contribution: ibm/granite-20b-code-instruct-v2
/**
 * Checks if a given network is scannable.
 *
 * @param network - The network address to check.
 *
 * @returns true if the network is scannable, false otherwise.
 */
export const isScannableInternalNetwork = (network: string, logging = false) => {
  const n = getSafe(network, Network, false)

  if (isNil(n)) {
    if (logging) {
      log(`${network} - not a CIDR`)
    }

    return false
  }

  switch (isCidr(network)) {
    case 4: {
      // const hasHostBits = isIpV4HostBits(n)
      const hasHostBits = _isIpV4HostBits(n, logging)

      return !hasHostBits
    }

    case 6: {
      // const hasHostBits = isIpV6HostBits(n)
      const hasHostBits = _isIpV6HostBits(n, logging)

      return !hasHostBits
    }

    default: {
      if (logging) {
        log(`${network} - not a CIDR`)
      }

      return false
    }
  }
}

// Assisted by WCA@IBM
// Latest GenAI contribution: ibm/granite-20b-code-instruct-v2
/**
 * Checks if the given network has host bits assigned to specific hosts.
 *
 * @deprecated
 *
 * @remarks
 * The function isIpV4HostBits takes in a network object as input. It converts
 * the network address into binary format using the Address4 class from the
 * ip-address library. It then calculates the subnet mask for the network using
 * the subnetMask property of the Address4 class. It then calculates the host
 * mask by inverting the subnet mask using bitwise operators. Finally, it
 * calculates the host bits by performing a bitwise AND operation between the
 * network address in binary format and the host mask. The function returns true
 * if the host bits are non-zero, indicating that the network has host bits
 * assigned to specific hosts.
 *
 * @param network - The network to check.
 *
 * @returns true if the network has host bits assigned to specific hosts, false otherwise.
 */
export const isIpV4HostBits = (network: Network) => {
  const address = new Address4(network)
  const subnetMask = address.subnetMask
  const hostMask = ~subnetMask >>> 0
  const addressBinary = parseInt(address.address.replace(/\./g, ''), 2)
  const hostBits = addressBinary & hostMask
  return hostBits !== 0
}

// Assisted by WCA@IBM
// Latest GenAI contribution: ibm/granite-20b-code-instruct-v2
/**
 * Checks if the host bits of an IPv6 network are set to all ones.
 *
 * @remarks
 *
 * @deprecated
 *
 * The function isIpV6HostBits takes in a network object as input. It converts
 * the network object into an IPv6 address using the Address6 class from the
 * ip-address library. It obtains the binary representation of the address using
 * the binaryZeroPad method. It slices off the host bits from the binary
 * representation based on the subnet mask of the network. Finally, it checks if
 * the host part includes a '1' character, indicating that the host bits are set
 * to all ones. So, in summary, the function returns true if the host bits of
 * the given network are set to all ones, and false otherwise.
 *
 * @param network - The network to check.
 *
 * @returns true if the host bits are set to all ones, false otherwise.
 */
export const isIpV6HostBits = (network: Network) => {
  const address = new Address6(network)
  const addressBinaryString = address.binaryZeroPad()
  const hostPart = addressBinaryString.slice(address.subnetMask)
  return hostPart.includes('1')
}

export function _isIpV4HostBits(cidr: Network, logging = false) {
  const address = new Address4(cidr)
  const network = address.startAddress()
  const hasHostBits = !isEqual(address.parsedAddress, network.parsedAddress)

  if (logging && hasHostBits) {
    log(`${cidr} - hostbits are set:`, {
      address: address.parsedAddress,
      network: network.parsedAddress,
    })
  }

  return hasHostBits
}

export function _isIpV6HostBits(cidr: Network, logging = false) {
  const address = new Address6(cidr)
  const network = address.startAddress()

  const hasHostBits = !isEqual(address.parsedAddress, network.parsedAddress)

  if (logging && hasHostBits) {
    log(`${cidr} - hostbits are set:`, {
      address: address.parsedAddress,
      network: network.parsedAddress,
    })
  }

  return hasHostBits
}
