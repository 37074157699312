/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { HttpClient } from '@/http/http.utils'

import * as access from './access'
import * as activities from './activity'
import * as affiliate from './affiliate'
import * as aggregator from './aggregator'
import * as artifacts from './artifact'
import * as asset from './asset'
import * as attack from './attack'
import * as auth from './auth'
import * as comment from './comment'
import * as detection from './detection'
import * as entitlement from './entitlement'
import * as exceptionPolicy from './exception-policy'
import * as hostname from './hostname'
import * as ip from './ip'
import * as mitre from './mitre'
import * as network from './network'
import * as org from './organization'
import * as path from './path'
import * as perspective from './perspective'
import * as preferences from './preferences'
import * as recon from './recon'
import * as report from './report'
import * as scheduler from './scheduler'
import * as service from './service'
import * as social from './social'
import * as spec from './spec'
import * as tags from './tags'
import * as target from './target'
import * as users from './user'
import * as workato from './workato'

// ---------------------------------------------------------------------------

export type Api = {
  access: access.AccessApi
  activities: activities.ActivityApi
  affiliate: affiliate.AffiliateApi
  aggregator: aggregator.AggregatorApi
  artifacts: artifacts.ArtifactsApi
  asset: asset.AssetApi
  attack: attack.AttackApi
  auth: auth.AuthApi
  comment: comment.CommentApi
  detection: detection.DetectionApi
  entitlement: entitlement.EntitlementApi
  exceptionPolicy: exceptionPolicy.ExceptionPolicyApi
  hostname: hostname.HostnameApi
  ip: ip.IpApi
  mitre: mitre.MitreApi
  network: network.NetworkApi
  org: org.OrgApi
  path: path.PathApi
  perspective: perspective.PerspectiveApi
  preferences: preferences.PreferencesApi
  recon: recon.ReconApi
  report: report.ReportApi
  scheduler: scheduler.SchedulerApi
  service: service.ServiceApi
  social: social.SocialApi
  spec: spec.SpecApi
  tags: tags.TagsApi
  target: target.TargetApi
  user: users.UsersApi
  workato: workato.WorkatoAPI
}

export function ApiFactory(http: HttpClient): Api {
  return {
    access: access.accessApiFactory(http),
    activities: activities.activityApiFactory(http),
    affiliate: affiliate.affiliateApiFactory(http),
    aggregator: aggregator.aggregatorApiFactory(http),
    artifacts: artifacts.artifactsApiFactory(http),
    asset: asset.assetApiFactory(http),
    attack: attack.attackApiFactory(http),
    auth: auth.authApiFactory(http),
    comment: comment.commentApiFactory(http),
    detection: detection.detectionApiFactory(http),
    entitlement: entitlement.entitlementApiFactory(http),
    exceptionPolicy: exceptionPolicy.exceptionPolicyApiFactory(http),
    hostname: hostname.hostnameApiFactory(http),
    ip: ip.ipApiFactory(http),
    mitre: mitre.mitreApiFactory(http),
    network: network.networkApiFactory(http),
    org: org.orgApiFactory(http),
    path: path.pathApiFactory(http),
    perspective: perspective.perspectiveApiFactory(http),
    preferences: preferences.preferencesApiFactory(http),
    recon: recon.reconApiFactory(http),
    report: report.reportApiFactory(http),
    scheduler: scheduler.schedulerApiFactory(http),
    service: service.serviceApiFactory(http),
    social: social.socialApiFactory(http),
    spec: spec.specApiFactory(http),
    tags: tags.tagsApiFactory(http),
    target: target.targetApiFactory(http),
    user: users.usersApiFactory(http),
    workato: workato.workatoApiFactory(http),
  }
}
