/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { capitalize } from 'lodash/fp'

import * as Codecs from '@/codecs'
import type { DetectionCriteria, TemptationFactorLevel } from '@/codecs'
import { TemptationFactor } from '@/codecs'
import { getFactorDisplayValues } from '@/components/entity-list/cells/temptation-factors'
import type { ConfidenceBoundaries, PriorityPreference } from '@/store/selectors/preferences/preferences.selectors'
import type { TTBoundaries } from '@/store/selectors/preferences/preferences.selectors.utils'
import { getConfidence } from '@/utilities/confidence'
import { getSchemaFromDetection } from '@/utilities/detection-schema'
import { priorityToDisplay } from '@/utilities/priority'
import type { EntityType } from '@/utilities/r-entity'
import { getTemptationStr } from '@/utilities/target-temptation'

// ---------------------------------------------------------------------------

/**
 * Conditionally prevents [object object] from appearing in CSV.
 *
 * @param record -
 * @returns a transformed record
 */
export const transformFields = (
  record: Record<string, unknown>,
  priorityBoundaries: PriorityPreference,
  temptationBoundaries: TTBoundaries,
  confidenceBoundaries: ConfidenceBoundaries,
  kind: EntityType,
) => {
  const keys = Object.keys(record)

  const hasAllPorts = keys.includes('all_ports')
  const hasApplicability = keys.includes(TemptationFactor.applicability)
  const hasConfidence = keys.includes('confidence')
  const hasCpe = keys.includes('cpe')
  const hasCriticality = keys.includes(TemptationFactor.criticality)
  const hasDetectionCriteria = keys.includes('detection_criteria')
  const hasEnumerability = keys.includes(TemptationFactor.enumerability)
  const hasExploitability = keys.includes(TemptationFactor.exploitability)
  const hasPostExploit = keys.includes(TemptationFactor.post_exploit)
  const hasPriorityScore = keys.includes('priority_score')
  const hasPrivateWeakness = keys.includes(TemptationFactor.private_weakness)
  const hasPublicWeakness = keys.includes(TemptationFactor.public_weakness)
  const hasResearch = keys.includes(TemptationFactor.research)
  const hasTemptation = keys.includes('target_temptation')

  if (hasAllPorts) {
    const _record = record as { all_ports: NonNullable<Codecs.Ip['all_ports']> }

    const allPorts = isNotNil(_record.all_ports)
      ? _record.all_ports
          .filter((port) => port.state !== 'closed')
          .sort((a, b) => a.port - b.port)
          .map(({ port, state }) => `${port}(${state})`)
          .join(',')
      : ''

    record = {
      ..._record,
      ...{ all_ports: allPorts },
    }
  }

  if (hasCpe) {
    const _record = record as { cpe: Codecs.CPE }

    const cpeStr = isNotNil(_record.cpe) ? _record.cpe.str : ''

    record = {
      ..._record,
      ...{ cpe: cpeStr },
    }
  }

  if (hasPriorityScore) {
    const _record = record as { priority_score: number }

    const priorityScore = isNotNil(_record.priority_score)
      ? priorityToDisplay(_record.priority_score, priorityBoundaries)
      : ''

    record = {
      ..._record,
      ...{ priority_score: capitalize(priorityScore) },
    }
  }

  if (hasTemptation) {
    const _record = record as { target_temptation: number }

    const temptationScore = isNotNil(_record.target_temptation)
      ? getTemptationStr(_record.target_temptation, temptationBoundaries)
      : ''

    record = {
      ..._record,
      ...{ target_temptation: capitalize(temptationScore) },
    }
  }

  if (hasConfidence) {
    const _record = record as { confidence: number }

    const confidenceScore = isNotNil(_record.confidence) ? getConfidence(_record.confidence, confidenceBoundaries) : ''

    record = {
      ..._record,
      ...{ confidence: confidenceScore },
    }
  }

  if (hasDetectionCriteria) {
    const _record = record as { detection_criteria: DetectionCriteria }

    const detectionCriteria = getSchemaFromDetection(_record.detection_criteria)

    record = {
      ..._record,
      ...{ detection_criteria: detectionCriteria.url },
    }
  }

  if (kind === 'target') {
    const _record = record as { id: string }

    const [_, id, ..._rest] = _record.id.split(',')

    record = {
      ..._record,
      ...{ id },
    }
  }

  if (hasApplicability) {
    const _record = record as { applicability: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ applicability: getFactorDisplayValues(_record.applicability).label },
    }
  }

  if (hasCriticality) {
    const _record = record as { criticality: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ criticality: getFactorDisplayValues(_record.criticality).label },
    }
  }

  if (hasEnumerability) {
    const _record = record as { enumerability: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ enumerability: getFactorDisplayValues(_record.enumerability).label },
    }
  }

  if (hasExploitability) {
    const _record = record as { exploitability: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ exploitability: getFactorDisplayValues(_record.exploitability).label },
    }
  }

  if (hasPostExploit) {
    const _record = record as { post_exploit: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ post_exploit: getFactorDisplayValues(_record.post_exploit).label },
    }
  }

  if (hasPrivateWeakness) {
    const _record = record as { private_weakness: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ private_weakness: getFactorDisplayValues(_record.private_weakness).label },
    }
  }

  if (hasPublicWeakness) {
    const _record = record as { public_weakness: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ public_weakness: getFactorDisplayValues(_record.public_weakness).label },
    }
  }

  if (hasResearch) {
    const _record = record as { research: TemptationFactorLevel }

    record = {
      ..._record,
      ...{ research: getFactorDisplayValues(_record.research).label },
    }
  }

  return record
}
