/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const BlueprintsReducer = (state = Schemas.HocSchemas.NormalizeBlueprints([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.BLUEPRINTS_STORE_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizeBlueprints(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          blueprint: {
            ...state.entities.blueprint,
            ...entities.blueprint,
          },
        },
      }
    }

    case Store.HocActions.TypeKeys.BLUEPRINTS_STORE_UPDATE_P: {
      const { entities } = Schemas.HocSchemas.NormalizeBlueprint(action.payload.data)

      return {
        result: state.result,
        entities: {
          ...state.entities,
          blueprint: {
            ...state.entities.blueprint,
            ...entities.blueprint,
          },
        },
      }
    }

    default:
      return state
  }
}

export const BlueprintsTotalsInitialState: Codecs.HocEntityTotals = {
  total: 0,
  unfilteredTotal: 0,
}

export const BlueprintsTotalsReducer = (state = BlueprintsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.BLUEPRINT_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const BlueprintsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const BlueprintsPaginationReducer = (state = BlueprintsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.BLUEPRINTS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
