/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Network, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type AffiliatedNetwork = t.TypeOf<typeof AffiliatedNetwork>
export const AffiliatedNetwork = t.type({
  id: UUID,
  network: Network,
  org_id: UUID,
  perspective: UUID,
})
