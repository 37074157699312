/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullable, nullableP, TripleUUID, UUID } from '@/utilities/codec'

import { CPE } from '../cpe.codecs'
import { WithPerspective } from '../perspective.codecs'
import { TechCategoryList } from '../tech-category.codecs'
import { WithTemptation } from '../temptation.codecs'
import { TextSource } from '../text-source.codecs'

// ---------------------------------------------------------------------------

export const _Service = t.type({
  attack_note: nullableP(t.string),
  cpe: nullable(CPE),
  description: nullableP(t.string),
  description_source: TextSource,
  first_seen: nullableP(DateString),
  id: TripleUUID,
  instance_count: nullableP(t.number),
  ip_count: nullableP(t.number),
  last_seen: nullableP(DateString),
  name: nullableP(t.string),
  org_id: nullableP(UUID),
  randori_notes: nullableP(t.string),
  reference: nullableP(t.string),
  service_id: nullableP(UUID),
  tech_category: nullable(TechCategoryList),
  vendor: nullableP(t.string),
  version: nullableP(t.string),

  // @TODO: EN-13080 swap undefined with null after materialization updates
  end_of_life: nullable(t.boolean),
})

export type ServiceP = t.TypeOf<typeof ServiceP>
export const ServiceP = t.intersection([_Service, WithPerspective, WithTemptation])
