/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'
import { DateString, DoubleUUID, nullableP, UUID } from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { ImpactScore } from '../impact-score.codecs'
import { WithPerspective } from '../perspective.codecs'
import { Status } from '../status.codecs'

// ---------------------------------------------------------------------------

export const _HostnameForIp = t.type({
  affiliation_state: Affiliation,
  confidence: CodecUtils.nullable(t.number),
  deleted: t.boolean,
  hostname: nullableP(t.string),
  hostname_id: UUID,
  id: DoubleUUID,
  impact_score: ImpactScore,
  ip_id: UUID,
  last_seen: nullableP(DateString),
  org_id: UUID,
  status: Status,
})

export type HostnameForIpP = t.TypeOf<typeof HostnameForIpP>
export const HostnameForIpP = t.intersection([_HostnameForIp, WithPerspective])
