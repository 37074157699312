/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const TextSource = t.keyof({
  default: null,
  ai: null,
})

export type TextSource = t.TypeOf<typeof TextSource>

export enum TextSourceDisplay {
  default = '',
  ai = 'AI-generated content',
}

export enum TextSourceFieldValue {
  default = 'default',
  ai = 'ai',
}
