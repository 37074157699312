/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export enum ImpactScoreEnum {
  none = 'None',
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}

export const ImpactScore = t.keyof({
  [ImpactScoreEnum.high]: null,
  [ImpactScoreEnum.low]: null,
  [ImpactScoreEnum.medium]: null,
  [ImpactScoreEnum.none]: null,
})

export type ImpactScore = t.TypeOf<typeof ImpactScore>

export const ImpactScoreResponse = t.type({
  impact_score_counts: t.array(
    t.type({
      impact_score: ImpactScore,
      total: t.number,
    }),
  ),
  total: t.number,
})

export type ImpactScoreResponse = t.TypeOf<typeof ImpactScoreResponse>

export interface ImpactScoreBody {
  entity_type: string
  min_first_seen: string
  tt_ranges: {
    tt_max: number
    tt_min: number
    tt_range_name: string
  }[]
}
