/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { path } from 'lodash/fp'
import { createSelector, defaultMemoize } from 'reselect'

import { AppState } from '@/store'
// ---------------------------------------------------------------------------
import * as Static from '@/store/static'

// ---------------------------------------------------------------------------

/*
  BE writes system tags as descriptor+tag, the + is our key to know if we're
  interacting with a system tag currently ( this may change to separate lists ).
*/

// ---------------------------------------------------------------------------

const _getTagCountsReducer = (state: AppState) => state.tags.tagCounts
const _getTags = (state: AppState) => state.tags

// ---------------------------------------------------------------------------

export const selectAvailableTags = createSelector([_getTags], (tags) => {
  return tags.userTags
})

export const selectTagCounts = createSelector([_getTagCountsReducer], (_tagCountsState) => {
  _tagCountsState.data.entities

  const _path = path(['data', 'entities', 'tagCounts'], _tagCountsState)

  const tagCounts = _tagCountsState.data.result.map((tagCount: string) => _path[tagCount])

  return tagCounts
})

export const selectInterestingTagCounts = createSelector([selectTagCounts], (tagCounts) => {
  return tagCounts.filter((tag) => tag.is_characteristic_tag)
})

export const selectUserTagCounts = createSelector([selectTagCounts], (tagCounts) => {
  return tagCounts.filter((tag) => !tag.is_characteristic_tag)
})

export const makeSelectAutoTagDescription = createSelector([() => Static.AUTO_TAG_DESCRIPTIONS], (TagDescriptions) => {
  return defaultMemoize((tagName: Static.TagDescriptionString) => TagDescriptions[tagName])
})

export const selectTagKindDescriptions = createSelector(
  [() => Static.TAG_KIND_DESCRIPTIONS],
  (descriptions) => descriptions,
)
