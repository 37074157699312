/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import classNames from 'classnames'
import debug from 'debug'

import * as Codecs from '@/codecs'
import { ExtDisplayFuncConfig, QInfo } from '@/utilities/actions'

// ---------------------------------------------------------------------------

export * from './create-tags-and-property-list'
export * from './get-detection-props'
export * from './get-guidance-content'
export * from './get-nav-order'
export * from './use-navigation-scroll'
export * from './use-navigation-state'

// ---------------------------------------------------------------------------

export type EntityDetailContext = {
  entity: Codecs.Entity
  entityId: string
  hydrate: (refetch?: boolean) => Promise<unknown> // this should be deducible from entityType
  qInfo: QInfo
} & ExtDisplayFuncConfig

export const log = debug('RANDORI:entity-detail')
export const pageClasses = classNames('entity-detail-grid', 'entity-page')
