/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Context, ValidationError } from 'io-ts'

import { getFilteredPath } from './get-filtered-path'

// ---------------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFilteredMessage(v: any, context: Context) {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `Invalid value "${v}" supplied to "${getFilteredPath(context)}"`
}

export function getFilteredMessages(es: ValidationError[]) {
  return es.map(function (e) {
    return getFilteredMessage(e.value, e.context)
  })
}
