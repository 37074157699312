/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { Affiliation } from '../affiliation.codecs'
import { ImpactScore } from '../impact-score.codecs'
import { LensView, Perspective } from '../perspective.codecs'
import { withTemptation } from '../temptation.codecs'

// ---------------------------------------------------------------------------

// @TODO: Temporary
//
// We can remove this mostly-duplication if we improve the marshalling of the
// target in the report representation of a target — this would have to occur
// at the backend.
export const DetectionTarget = withTemptation(
  t.interface({
    affiliation_state: Affiliation,
    authorization_state: t.union([t.string, t.null]),
    description: t.union([t.string, t.null]),
    first_seen: t.union([t.string, t.null]),
    id: t.string,
    impact_score: ImpactScore,
    last_seen: t.union([t.string, t.null]),
    lens_id: t.union([t.string, t.null]),
    lens_view: t.union([LensView, t.null]),
    name: t.union([t.string, t.null]),
    org_id: t.union([t.string, t.null]),
    perspective: t.union([t.string, t.null]),
    perspective_name: t.union([Perspective, t.null]), // @TODO: Remove nullability
    priority_impact_factor: t.union([t.number, t.null]),
    priority_score: t.union([t.number, t.null]),
    priority_status_factor: t.union([t.number, t.null]),
    priority_tags_factor: t.union([t.number, t.null]),
    randori_notes: t.union([t.string, t.null]),
    reference: t.union([t.string, t.null]),
    service_id: t.union([t.string, t.null]),
    status: t.union([t.string, t.null]),
    vendor: t.union([t.string, t.null]),
    version: t.union([t.string, t.null]),
  }),
)
type DetectionTarget = t.TypeOf<typeof DetectionTarget>

export const ReportType = t.keyof({
  EXECUTIVE_SUMMARY: null,
  ATTACK_SURFACE_ANALYSIS: null,
})

export const ReportCharacteristic = t.interface({
  characteristic: t.string,
  content: t.string,
  stats: t.interface({
    current: t.interface({
      total: t.number,
      peer_size: t.number,
      peer_industry: t.number,
      peer_all: t.number,
    }),
    previous: CodecUtils.nullable(
      t.interface({
        total: t.number,
        peer_size: t.number,
        peer_industry: t.number,
        peer_all: t.number,
      }),
    ),
  }),
})

export const ActiveEntityStat = t.interface({
  current: t.number,
  previous: CodecUtils.nullable(t.number),
})

export const RiskMatrixItem = t.interface({
  count: t.number,
  impact: t.string,
  target_temptation: t.string,
})

export const TargetsByAgeItem = t.interface({
  count: t.number,
  priority: t.string,
  range: t.string,
})

export const TopTargetCountItem = t.interface({
  high_priority_count: t.number,
  low_priority_count: t.number,
  medium_priority_count: t.number,
  total_count: t.number,
})

export const PeerData = t.interface({
  all_org_high_priority_targets: CodecUtils.nullable(t.number), // 0.0 to 1.0 - percentange
  all_org_high_tt_targets: CodecUtils.nullable(t.number),
  high_priority_targets: CodecUtils.nullable(t.number),
  high_tt_targets: CodecUtils.nullable(t.number),
  industry_peer_high_priority_targets: CodecUtils.nullable(t.number),
  industry_peer_high_tt_targets: CodecUtils.nullable(t.number),
  num_industry_peers: CodecUtils.nullable(t.number),
  num_size_peers: CodecUtils.nullable(t.number),
  size_peer_high_priority_targets: CodecUtils.nullable(t.number),
  size_peer_high_tt_targets: CodecUtils.nullable(t.number),
})

export const __VERSION_1__ = 1
export const __VERSION_2__ = 2
export const __VERSION_3__ = 3
export const __VERSION_4__ = 4
