/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'
import { propOr } from 'ramda'

// ---------------------------------------------------------------------------
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export const Recipe = new schema.Entity('recipe')
export const Recipes = [Recipe]

export function NormalizeRecipes(recipes: Codecs.WorkatoRecipe[]) {
  type _Recipes = {
    recipe: {
      [index: string]: Codecs.WorkatoRecipe
    }
  }

  type NormalizedRecipes = {
    entities: _Recipes
    result: string[]
  }

  const { result, entities } = normalize(recipes, Recipes)

  const _recipes = propOr({}, 'recipe', entities)

  return {
    result,
    entities: {
      recipe: _recipes,
    },
  } as NormalizedRecipes
}
