/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'

import { HttpError } from './types'

// ---------------------------------------------------------------------------

// @see: https://github.com/softonic/axios-retry/blob/f857c9d72b2e461657f8f117be091c1f0522a74c/es/index.mjs#L18
const SAFE_HTTP_METHODS = ['get', 'head', 'options']
export const IDEMPOTENT_HTTP_METHODS = [...SAFE_HTTP_METHODS, 'put', 'delete']

/**
 * @param error -
 * @returns whether or not the request is safe to retry
 */
export function is429(error: HttpError) {
  if (!isNotNil(error.config)) {
    return false
  }

  const _method = error.config.method ?? 'none'
  const method = _method.toLowerCase()

  return error.response?.status === 429 && IDEMPOTENT_HTTP_METHODS.includes(method)
}
