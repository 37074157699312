/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { PerspectiveType } from '@/codecs'

export const getDisplayablePerspectiveType = (type: PerspectiveType) => {
  switch (type) {
    case 'PERSPECTIVE_TYPE_EXTERNAL':
      return 'Public'
    case 'PERSPECTIVE_TYPE_INTERNAL':
      return 'Internal'
    default:
      throw new Error(`Unexpected perspective type ${type}`)
  }
}
