/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'

export const Social: Codecs.SocialEntity = {
  address: null,
  affiliation_state: 'None',
  authorization_state: 'None',
  characteristic_tags: [],
  city: null,
  company_name: null,
  country: null,
  details: null,
  domain: null,
  email: 'foobat@biz.com',
  first_seen: '2022-12-08T20:28:15.087980+00:00',
  id: 'fdc55721-a4fd-438d-b602-b82e593f8d81',
  impact_score: Codecs.ImpactScoreEnum.none,
  last_seen: '2023-04-10T14:01:35.339171+00:00',
  person_first_name: 'Foo',
  person_last_name: 'Bat',
  phone: '111-111-1111',
  postal_code: null,
  priority_impact_factor: 0.05,
  priority_score: 20,
  priority_status_factor: 0.05,
  priority_tags_factor: 0,
  role: null,
  state: null,
  status: Codecs.StatusEnum.none,
  sub_role: null,
  target_temptation: 0,
  title: null,
  user_tags: ['alon tag'],
}
