/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createServer, Server } from 'miragejs'

import affiliateNetwork from '../api/affiliate/__fixtures__/affiliated-network.get-plural.json'
import hostnameV2 from '../api/hostname/__fixtures__/hostname.get-many.json'
import activityConfigurations from './fixtures/activity-configuration.json'
import assets from './fixtures/asset.json'
import detectionP from './fixtures/detection.json'
import exceptionPolicies from './fixtures/exception-policy.json'
import perspectives from './fixtures/perspective.json'
import sources from './fixtures/source.json'
import suggestedServices from './fixtures/suggested-service.json'
import { activityRoutes } from './routes/activity'
import { affiliateRoutes } from './routes/affiliate'
import { assetRoutes } from './routes/asset'
import { detectionPRoutes } from './routes/detection'
import { exceptionPolicyRoutes } from './routes/exceptionPolicy'
import { hostnameV2Routes } from './routes/hostname'
import { perspectiveRoutes } from './routes/perspective'
import { sourceRoutes } from './routes/source'
import { suggestedServiceRoutes } from './routes/suggestedService'

export const makeMirageServer = ({ environment = 'development' } = {}): Server =>
  createServer({
    environment,

    seeds: (server) => {
      server.db.loadData({
        activityConfigurations: activityConfigurations.data,
        affiliate: affiliateNetwork.data,
        detectionP: detectionP.data,
        exceptionPolicies: exceptionPolicies.data,
        hostnameV2: hostnameV2.data,
        perspectives: perspectives.perspectives,
        sources: sources.sources,
        suggestedServices: suggestedServices.data,
        assets: assets.data,
      })
    },

    routes() {
      activityRoutes(this)
      affiliateRoutes(this)
      assetRoutes(this)
      detectionPRoutes(this)
      exceptionPolicyRoutes(this)
      hostnameV2Routes(this)
      perspectiveRoutes(this)
      sourceRoutes(this)
      suggestedServiceRoutes(this)

      // Let routes that we don't mock passthrough
      this.passthrough()
    },
  })
