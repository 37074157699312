/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import { ServiceFormUtils } from '@/utilities/entity-form'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  HOC_INIT = 'HOC_INIT',

  // @TODO: Name TypeKeys better
  BLUEPRINTS_FETCH = 'BLUEPRINTS_FETCH',
  BLUEPRINTS_STORE_UPDATE = 'BLUEPRINTS_STORE_UPDATE',
  BLUEPRINTS_STORE_UPDATE_P = 'BLUEPRINTS_STORE_UPDATE_P', // rename to SINGLE
  BLUEPRINT_PATCH = 'BLUEPRINT_PATCH',
  BLUEPRINT_TOTALS_FETCH = 'BLUEPRINT_TOTALS_FETCH',
  BLUEPRINT_TOTALS_STORE_UPDATE = 'BLUEPRINT_TOTALS_STORE_UPDATE',

  BARS_DROP = 'BARS_DROP',
  BARS_FETCH = 'BARS_FETCH',
  BARS_PATCH = 'BARS_PATCH',
  BARS_STORE_UPDATE = 'BARS_STORE_UPDATE',
  BARS_STORE_UPDATE_P = 'BARS_STORE_UPDATE_P', // rename to SINGLE
  BAR_MARK_RECEIVED = 'BAR_MARK_RECEIVED',
  BAR_TOTALS_FETCH = 'BAR_TOTALS_FETCH',
  BAR_TOTALS_STORE_UPDATE = 'BAR_TOTALS_STORE_UPDATE',

  GLOBAL_IPS_FETCH = 'GLOBAL_IPS_FETCH',
  GLOBAL_IPS_TOTALS_FETCH = 'GLOBAL_IPS_TOTALS_FETCH',
  GLOBAL_IPS_UPDATE = 'GLOBAL_IPS_UPDATE',
  GLOBAL_IPS_TOTALS_STORE_UPDATE = 'GLOBAL_IPS_TOTALS_STORE_UPDATE',
  GLOBAL_IPS_POST = 'GLOBAL_IPS_POST',

  GLOBAL_NETWORKS_FETCH = 'GLOBAL_NETWORKS_FETCH',
  GLOBAL_NETWORKS_TOTALS_FETCH = 'GLOBAL_NETWORKS_TOTALS_FETCH',
  GLOBAL_NETWORKS_UPDATE = 'GLOBAL_NETWORKS_UPDATE',
  GLOBAL_NETWORKS_TOTALS_STORE_UPDATE = 'GLOBAL_NETWORKS_TOTALS_STORE_UPDATE',
  GLOBAL_NETWORK_POST = 'GLOBAL_NETWORK_POST',

  GLOBAL_HOSTNAMES_FETCH = 'GLOBAL_HOSTNAMES_FETCH',
  GLOBAL_HOSTNAMES_TOTALS_FETCH = 'GLOBAL_HOSTNAMES_TOTALS_FETCH',
  GLOBAL_HOSTNAMES_UPDATE = 'GLOBAL_HOSTNAMES_UPDATE',
  GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE = 'GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE',
  GLOBAL_HOSTNAME_POST = 'GLOBAL_HOSTNAME_POST',

  GLOBAL_SERVICES_FETCH = 'GLOBAL_SERVICES_FETCH',
  GLOBAL_SERVICES_STORE_UPDATE = 'GLOBAL_SERVICES_STORE_UPDATE',
  GLOBAL_SERVICE_PATCH = 'GLOBAL_SERVICE_PATCH',
  GLOBAL_SERVICE_POST_MANY = 'GLOBAL_SERVICE_POST_MANY',
  GLOBAL_SERVICE_STATS_FETCH = 'GLOBAL_SERVICE_STATS_FETCH',
  GLOBAL_SERVICE_STATS_STORE_UPDATE = 'GLOBAL_SERVICE_STATS_STORE_UPDATE',
  GLOBAL_SERVICE_STORE_UPDATE = 'GLOBAL_SERVICE_STORE_UPDATE',
  GLOBAL_SERVICE_TOTALS_FETCH = 'GLOBAL_SERVICE_TOTALS_FETCH',
  GLOBAL_SERVICE_TOTALS_STORE_UPDATE = 'GLOBAL_SERVICE_TOTALS_STORE_UPDATE',

  SERVICE_PATCH = 'SERVICE_PATCH',

  SERVICE_SUGGESTIONS_BULK_POST = 'SERVICE_SUGGESTIONS_BULK_POST',
  SERVICE_SUGGESTIONS_FETCH = 'SERVICE_SUGGESTIONS_FETCH',
  SERVICE_SUGGESTIONS_STORE_UPDATE = 'SERVICE_SUGGESTIONS_STORE_UPDATE',
  SERVICE_SUGGESTION_PATCH = 'SERVICE_SUGGESTION_PATCH',
  SERVICE_SUGGESTION_PROMOTE = 'SERVICE_SUGGESTION_PROMOTE',
  SERVICE_SUGGESTION_STATS_FETCH = 'SERVICE_SUGGESTION_STATS_FETCH',
  SERVICE_SUGGESTION_STATS_STORE_UPDATE = 'SERVICE_SUGGESTION_STATS_STORE_UPDATE',
  SERVICE_SUGGESTION_TOTALS_FETCH = 'SERVICE_SUGGESTION_TOTALS_FETCH',
  SERVICE_SUGGESTION_TOTALS_STORE_UPDATE = 'SERVICE_SUGGESTION_TOTALS_STORE_UPDATE',

  SERVICE_RULES_FETCH = 'SERVICE_RULES_FETCH',
  SERVICE_RULES_METADATA_FETCH = 'SERVICE_RULES_METADATA_FETCH',
  SERVICE_RULES_METADATA_STORE_UPDATE = 'SERVICE_RULES_METADATA_STORE_UPDATE',
  SERVICE_RULES_STORE_UPDATE = 'SERVICE_RULES_STORE_UPDATE',
  SERVICE_RULE_PATCH = 'SERVICE_RULE_PATCH',
  SERVICE_RULE_POST = 'SERVICE_RULE_POST',
  SERVICE_RULE_STATS_STORE_UPDATE = 'SERVICE_RULE_STATS_STORE_UPDATE',
  SERVICE_RULE_STORE_UPDATE = 'SERVICE_RULE_STORE_UPDATE',
  SERVICE_RULE_TOTALS_STORE_UPDATE = 'SERVICE_RULE_TOTALS_STORE_UPDATE',

  CHARACTERISTIC_RULES_FETCH = 'CHARACTERISTIC_RULES_FETCH',
  CHARACTERISTIC_RULES_METADATA_FETCH = 'CHARACTERISTIC_RULES_METADATA_FETCH',
  CHARACTERISTIC_RULES_METADATA_STORE_UPDATE = 'CHARACTERISTIC_RULES_METADATA_STORE_UPDATE',
  CHARACTERISTIC_RULES_STORE_UPDATE = 'CHARACTERISTIC_RULES_STORE_UPDATE',
  CHARACTERISTIC_RULE_PATCH = 'CHARACTERISTIC_RULE_PATCH',
  CHARACTERISTIC_RULE_POST = 'CHARACTERISTIC_RULE_POST',
  CHARACTERISTIC_RULE_STATS_STORE_UPDATE = 'CHARACTERISTIC_RULE_STATS_STORE_UPDATE',
  CHARACTERISTIC_RULE_STORE_UPDATE = 'CHARACTERISTIC_RULE_STORE_UPDATE',
  CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE = 'CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE',

  ARTIFACTS_FETCH = 'ARTIFACTS_FETCH',
  ARTIFACTS_STORE_UPDATE = 'ARTIFACTS_STORE_UPDATE',
  ARTIFACT_TOTALS_FETCH = 'ARTIFACT_TOTALS_FETCH',
  ARTIFACT_TOTALS_STORE_UPDATE = 'ARTIFACT_TOTALS_STORE_UPDATE',

  // the actual artifact
  ARTIFACT_DOWNLOAD = 'ARTIFACT_DOWNLOAD',
  ARTIFACT_RETRIEVE = 'ARTIFACT_RETRIEVE',
  ARTIFACT_DIRECT_RETRIEVE = 'ARTIFACT_DIRECT_RETRIEVE',

  VALIDATE_CHARACTERISTIC_REGEX = 'VALIDATE_CHARACTERISTIC_REGEX',
  VALIDATE_SERVICE_REGEX = 'VALIDATE_SERVICE_REGEX',

  TERMS_FETCH = 'TERMS_FETCH',
  TERMS_TOTALS_FETCH = 'TERMS_TOTALS_FETCH',
  TERMS_UPDATE = 'TERMS_UPDATE',
  TERMS_TOTALS_STORE_UPDATE = 'TERMS_TOTALS_STORE_UPDATE',

  ORG_TERM_POST = 'ORG_TERM_POST',

  // aggregator side - poc
  POCS_FETCH = 'POCS_FETCH',
  POCS_TOTALS_FETCH = 'POCS_TOTALS_FETCH',
  POCS_STORE_UPDATE = 'POCS_STORE_UPDATE',
  POCS_TOTALS_STORE_UPDATE = 'POCS_TOTALS_STORE_UPDATE',
  POCS_POST = 'POCS_POST',

  PERIODIC_POST = 'PERIODIC_POST',
  POST_ORG_KEYVAL = 'POST_ORG_KEYVAL',

  GET_ORG_PRIMES = 'GET_ORG_PRIMES',
}

export type ActionTypes =
  | ARTIFACTS_FETCH
  | ARTIFACTS_STORE_UPDATE
  | ARTIFACT_RETRIEVE
  | ARTIFACT_DIRECT_RETRIEVE
  | ARTIFACT_TOTALS_FETCH
  | ARTIFACT_TOTALS_STORE_UPDATE
  | BARS_DROP
  | BARS_FETCH
  | BARS_PATCH
  | BARS_STORE_UPDATE
  | BARS_STORE_UPDATE_P
  | BAR_MARK_RECEIVED
  | BAR_TOTALS_STORE_UPDATE
  | BLUEPRINTS_FETCH
  | BLUEPRINTS_STORE_UPDATE
  | BLUEPRINTS_STORE_UPDATE_P
  | BLUEPRINT_PATCH
  | BLUEPRINT_TOTALS_STORE_UPDATE
  | CHARACTERISTIC_RULES_FETCH
  | CHARACTERISTIC_RULES_METADATA_FETCH
  | CHARACTERISTIC_RULES_METADATA_STORE_UPDATE
  | CHARACTERISTIC_RULES_STORE_UPDATE
  | CHARACTERISTIC_RULE_PATCH
  | CHARACTERISTIC_RULE_POST
  | CHARACTERISTIC_RULE_STATS_STORE_UPDATE
  | CHARACTERISTIC_RULE_STORE_UPDATE
  | CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE
  | GET_ORG_PRIMES
  | GLOBAL_HOSTNAMES_FETCH
  | GLOBAL_HOSTNAMES_TOTALS_FETCH
  | GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE
  | GLOBAL_HOSTNAME_POST
  | GLOBAL_HOSTNAMES_UPDATE
  | GLOBAL_IPS_FETCH
  | GLOBAL_IPS_TOTALS_FETCH
  | GLOBAL_IPS_POST
  | GLOBAL_NETWORK_POST
  | GLOBAL_NETWORKS_FETCH
  | GLOBAL_NETWORKS_TOTALS_FETCH
  | GLOBAL_NETWORKS_TOTALS_STORE_UPDATE
  | GLOBAL_NETWORKS_UPDATE
  | GLOBAL_IPS_UPDATE
  | GLOBAL_IPS_TOTALS_STORE_UPDATE
  | GLOBAL_SERVICES_FETCH
  | GLOBAL_SERVICES_STORE_UPDATE
  | GLOBAL_SERVICE_POST_MANY
  | GLOBAL_SERVICE_STATS_FETCH
  | GLOBAL_SERVICE_STATS_STORE_UPDATE
  | GLOBAL_SERVICE_STORE_UPDATE
  | GLOBAL_SERVICE_TOTALS_STORE_UPDATE
  | HOC_INIT
  | TERMS_FETCH
  | TERMS_TOTALS_FETCH
  | TERMS_TOTALS_STORE_UPDATE
  | TERMS_UPDATE
  | ORG_TERM_POST
  | PERIODIC_POST
  | POCS_FETCH
  | POCS_TOTALS_FETCH
  | POCS_STORE_UPDATE
  | POCS_TOTALS_STORE_UPDATE
  | POCS_POST
  | POST_ORG_KEYVAL
  | SERVICE_PATCH
  | SERVICE_RULES_FETCH
  | SERVICE_RULES_METADATA_FETCH
  | SERVICE_RULES_METADATA_STORE_UPDATE
  | SERVICE_RULES_STORE_UPDATE
  | SERVICE_RULE_PATCH
  | SERVICE_RULE_POST
  | SERVICE_RULE_STATS_STORE_UPDATE
  | SERVICE_RULE_STORE_UPDATE
  | SERVICE_RULE_TOTALS_STORE_UPDATE
  | SERVICE_SUGGESTIONS_BULK_POST
  | SERVICE_SUGGESTIONS_FETCH
  | SERVICE_SUGGESTIONS_STORE_UPDATE
  | SERVICE_SUGGESTION_PROMOTE
  | SERVICE_SUGGESTION_STATS_FETCH
  | SERVICE_SUGGESTION_STATS_STORE_UPDATE
  | SERVICE_SUGGESTION_TOTALS_STORE_UPDATE
  | VALIDATE_CHARACTERISTIC_REGEX
  | VALIDATE_SERVICE_REGEX

// init
// ---------------------------------------------------------------------------

export type HOC_INIT = {
  type: TypeKeys.HOC_INIT
  meta: { deferred: Deferred }
}

export function HOC_INIT(deferred: Deferred): HOC_INIT {
  return {
    type: TypeKeys.HOC_INIT,
    meta: { deferred },
  }
}

// org term
// ---------------------------------------------------------------------------

export type TERMS_UPDATE = {
  type: TypeKeys.TERMS_UPDATE
  payload: Codecs.TermResponse
}

export function TERMS_UPDATE(payload: TERMS_UPDATE['payload']): TERMS_UPDATE {
  return {
    type: TypeKeys.TERMS_UPDATE,
    payload,
  }
}

export type TERMS_FETCH = {
  type: TypeKeys.TERMS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function TERMS_FETCH(payload: TERMS_FETCH['payload'], deferred: Deferred): TERMS_FETCH {
  return {
    type: TypeKeys.TERMS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TERMS_TOTALS_FETCH = {
  type: TypeKeys.TERMS_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function TERMS_TOTALS_FETCH(payload: BARS_FETCH['payload'], deferred: Deferred): TERMS_TOTALS_FETCH {
  return {
    type: TypeKeys.TERMS_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type TERMS_TOTALS_STORE_UPDATE = {
  type: TypeKeys.TERMS_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function TERMS_TOTALS_STORE_UPDATE(payload: TERMS_TOTALS_STORE_UPDATE['payload']): TERMS_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.TERMS_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type ORG_TERM_POST = {
  type: TypeKeys.ORG_TERM_POST
  meta: { deferred: Deferred }
  payload: Codecs.TermPostPayload
}

export function ORG_TERM_POST(payload: ORG_TERM_POST['payload'], deferred: Deferred): ORG_TERM_POST {
  return {
    type: TypeKeys.ORG_TERM_POST,
    meta: { deferred },
    payload,
  }
}

// blueprints
// ---------------------------------------------------------------------------

export type BLUEPRINTS_STORE_UPDATE = {
  type: TypeKeys.BLUEPRINTS_STORE_UPDATE
  payload: Codecs.BlueprintsResponse
}

export function BLUEPRINTS_STORE_UPDATE(payload: BLUEPRINTS_STORE_UPDATE['payload']): BLUEPRINTS_STORE_UPDATE {
  return {
    type: TypeKeys.BLUEPRINTS_STORE_UPDATE,
    payload,
  }
}

export type BLUEPRINTS_STORE_UPDATE_P = {
  type: TypeKeys.BLUEPRINTS_STORE_UPDATE_P
  payload: Codecs.BlueprintPatchResponse
}

export function BLUEPRINTS_STORE_UPDATE_P(payload: BLUEPRINTS_STORE_UPDATE_P['payload']): BLUEPRINTS_STORE_UPDATE_P {
  return {
    type: TypeKeys.BLUEPRINTS_STORE_UPDATE_P,
    payload,
  }
}

export type BLUEPRINTS_FETCH = {
  type: TypeKeys.BLUEPRINTS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function BLUEPRINTS_FETCH(payload: BLUEPRINTS_FETCH['payload'], deferred: Deferred): BLUEPRINTS_FETCH {
  return {
    type: TypeKeys.BLUEPRINTS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type BLUEPRINT_TOTALS_FETCH = {
  type: TypeKeys.BLUEPRINT_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function BLUEPRINT_TOTALS_FETCH(
  payload: BLUEPRINTS_FETCH['payload'],
  deferred: Deferred,
): BLUEPRINT_TOTALS_FETCH {
  return {
    type: TypeKeys.BLUEPRINT_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type BLUEPRINT_TOTALS_STORE_UPDATE = {
  type: TypeKeys.BLUEPRINT_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function BLUEPRINT_TOTALS_STORE_UPDATE(
  payload: BLUEPRINT_TOTALS_STORE_UPDATE['payload'],
): BLUEPRINT_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.BLUEPRINT_TOTALS_STORE_UPDATE,
    payload,
  }
}

type WithRunbook = {
  type: 'runbook'
  process_blueprint_id: string
  runbook_description: string
  runbook_guidance: string
  runbook_id: string
  runbook_name: string
  runbook_objective: string
}

type WithOutRunbook = {
  type: 'bare'
  process_blueprint_id: string
}

export type BLUEPRINT_PATCH = {
  type: TypeKeys.BLUEPRINT_PATCH
  meta: { deferred: Deferred }
  payload: WithRunbook | WithOutRunbook
}

export function BLUEPRINT_PATCH(payload: BLUEPRINT_PATCH['payload'], deferred: Deferred): BLUEPRINT_PATCH {
  return {
    type: TypeKeys.BLUEPRINT_PATCH,
    meta: { deferred },
    payload,
  }
}

// bars
// ---------------------------------------------------------------------------

export type BARS_STORE_UPDATE = {
  type: TypeKeys.BARS_STORE_UPDATE
  payload: Codecs.BarsResponse
}

export function BARS_STORE_UPDATE(payload: BARS_STORE_UPDATE['payload']): BARS_STORE_UPDATE {
  return {
    type: TypeKeys.BARS_STORE_UPDATE,
    payload,
  }
}

export type BARS_FETCH = {
  type: TypeKeys.BARS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function BARS_FETCH(payload: BARS_FETCH['payload'], deferred: Deferred): BARS_FETCH {
  return {
    type: TypeKeys.BARS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type BAR_TOTALS_FETCH = {
  type: TypeKeys.BAR_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function BAR_TOTALS_FETCH(payload: BARS_FETCH['payload'], deferred: Deferred): BAR_TOTALS_FETCH {
  return {
    type: TypeKeys.BAR_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type BAR_TOTALS_STORE_UPDATE = {
  type: TypeKeys.BAR_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function BAR_TOTALS_STORE_UPDATE(payload: BAR_TOTALS_STORE_UPDATE['payload']): BAR_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.BAR_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type BARS_PATCH = {
  type: TypeKeys.BARS_PATCH
  meta: { deferred: Deferred }
  payload: {
    approved: boolean
    barIds: string[]
    nextState: Codecs.BarState
  }
}

export function BAR_PATCH(payload: BARS_PATCH['payload'], deferred: Deferred): BARS_PATCH {
  return {
    type: TypeKeys.BARS_PATCH,
    meta: { deferred },
    payload,
  }
}

export type BARS_STORE_UPDATE_P = {
  type: TypeKeys.BARS_STORE_UPDATE_P
  payload: Codecs.BarsResponse
}

export function BARS_STORE_UPDATE_P(payload: BARS_STORE_UPDATE_P['payload']): BARS_STORE_UPDATE_P {
  return {
    type: TypeKeys.BARS_STORE_UPDATE_P,
    payload,
  }
}

export type BARS_DROP = {
  type: TypeKeys.BARS_DROP
  payload: string[]
}

export function BARS_DROP(payload: BARS_DROP['payload']): BARS_DROP {
  return {
    type: TypeKeys.BARS_DROP,
    payload,
  }
}

export type BAR_MARK_RECEIVED = {
  type: TypeKeys.BAR_MARK_RECEIVED
  payload: string[]
}

export function BAR_MARK_RECEIVED(payload: BAR_MARK_RECEIVED['payload']): BAR_MARK_RECEIVED {
  return {
    type: TypeKeys.BAR_MARK_RECEIVED,
    payload,
  }
}

// services
// ---------------------------------------------------------------------------

type SERVICE_PATCH_PAYLOAD = {
  id: string
  cpe: Codecs.CPE | null
} & Omit<ServiceFormUtils.ServiceFormValues, 'cpe'>

export type SERVICE_PATCH = {
  type: TypeKeys.SERVICE_PATCH
  meta: { deferred: Deferred }
  payload: SERVICE_PATCH_PAYLOAD
}

export function SERVICE_PATCH(payload: SERVICE_PATCH['payload'], deferred: Deferred): SERVICE_PATCH {
  return {
    type: TypeKeys.SERVICE_PATCH,
    meta: { deferred },
    payload,
  }
}

// global ips
// ---------------------------------------------------------------------------

export type GLOBAL_IPS_FETCH = {
  type: TypeKeys.GLOBAL_IPS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_IPS_FETCH(payload: GLOBAL_IPS_FETCH['payload'], deferred: Deferred): GLOBAL_IPS_FETCH {
  return {
    type: TypeKeys.GLOBAL_IPS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_IPS_UPDATE = {
  type: TypeKeys.GLOBAL_IPS_UPDATE
  payload: Codecs.GlobalIpsResponse
}

export function GLOBAL_IPS_UPDATE(payload: GLOBAL_IPS_UPDATE['payload']): GLOBAL_IPS_UPDATE {
  return {
    type: TypeKeys.GLOBAL_IPS_UPDATE,
    payload,
  }
}

export type GLOBAL_IPS_TOTALS_FETCH = {
  type: TypeKeys.GLOBAL_IPS_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_IPS_TOTALS_FETCH(
  payload: GLOBAL_IPS_TOTALS_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_IPS_TOTALS_FETCH {
  return {
    type: TypeKeys.GLOBAL_IPS_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_IPS_POST = {
  type: TypeKeys.GLOBAL_IPS_POST
  meta: { deferred: Deferred }
  payload: Codecs.GlobalIpPostPayload
}

export function GLOBAL_IPS_POST(payload: GLOBAL_IPS_POST['payload'], deferred: Deferred): GLOBAL_IPS_POST {
  return {
    type: TypeKeys.GLOBAL_IPS_POST,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_IPS_TOTALS_STORE_UPDATE = {
  type: TypeKeys.GLOBAL_IPS_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function GLOBAL_IPS_TOTALS_STORE_UPDATE(
  payload: GLOBAL_IPS_TOTALS_STORE_UPDATE['payload'],
): GLOBAL_IPS_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.GLOBAL_IPS_TOTALS_STORE_UPDATE,
    payload,
  }
}

// global networks
// ---------------------------------------------------------------------------

export type GLOBAL_NETWORKS_FETCH = {
  type: TypeKeys.GLOBAL_NETWORKS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_NETWORKS_FETCH(
  payload: GLOBAL_NETWORKS_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_NETWORKS_FETCH {
  return {
    type: TypeKeys.GLOBAL_NETWORKS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_NETWORKS_UPDATE = {
  type: TypeKeys.GLOBAL_NETWORKS_UPDATE
  payload: Codecs.GlobalNetworkResponse
}

export function GLOBAL_NETWORKS_UPDATE(payload: GLOBAL_NETWORKS_UPDATE['payload']): GLOBAL_NETWORKS_UPDATE {
  return {
    type: TypeKeys.GLOBAL_NETWORKS_UPDATE,
    payload,
  }
}

export type GLOBAL_NETWORKS_TOTALS_FETCH = {
  type: TypeKeys.GLOBAL_NETWORKS_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_NETWORKS_TOTALS_FETCH(
  payload: GLOBAL_NETWORKS_TOTALS_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_NETWORKS_TOTALS_FETCH {
  return {
    type: TypeKeys.GLOBAL_NETWORKS_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_NETWORKS_TOTALS_STORE_UPDATE = {
  type: TypeKeys.GLOBAL_NETWORKS_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function GLOBAL_NETWORKS_TOTALS_STORE_UPDATE(
  payload: GLOBAL_NETWORKS_TOTALS_STORE_UPDATE['payload'],
): GLOBAL_NETWORKS_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.GLOBAL_NETWORKS_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type GLOBAL_NETWORK_POST = {
  type: TypeKeys.GLOBAL_NETWORK_POST
  meta: { deferred: Deferred }
  payload: Codecs.GlobalNetworkPostPayload
}

export function GLOBAL_NETWORK_POST(payload: GLOBAL_NETWORK_POST['payload'], deferred: Deferred): GLOBAL_NETWORK_POST {
  return {
    type: TypeKeys.GLOBAL_NETWORK_POST,
    meta: { deferred },
    payload,
  }
}

// global hostname
// ---------------------------------------------------------------------------

export type GLOBAL_HOSTNAMES_FETCH = {
  type: TypeKeys.GLOBAL_HOSTNAMES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_HOSTNAMES_FETCH(
  payload: GLOBAL_HOSTNAMES_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_HOSTNAMES_FETCH {
  return {
    type: TypeKeys.GLOBAL_HOSTNAMES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_HOSTNAMES_TOTALS_FETCH = {
  type: TypeKeys.GLOBAL_HOSTNAMES_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_HOSTNAMES_TOTALS_FETCH(
  payload: GLOBAL_HOSTNAMES_TOTALS_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_HOSTNAMES_TOTALS_FETCH {
  return {
    type: TypeKeys.GLOBAL_HOSTNAMES_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE = {
  type: TypeKeys.GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE(
  payload: GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE['payload'],
): GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type GLOBAL_HOSTNAMES_UPDATE = {
  type: TypeKeys.GLOBAL_HOSTNAMES_UPDATE
  payload: Codecs.GlobalHostnameResponse
}

export function GLOBAL_HOSTNAMES_UPDATE(payload: GLOBAL_HOSTNAMES_UPDATE['payload']): GLOBAL_HOSTNAMES_UPDATE {
  return {
    type: TypeKeys.GLOBAL_HOSTNAMES_UPDATE,
    payload,
  }
}

export type GLOBAL_HOSTNAME_POST = {
  type: TypeKeys.GLOBAL_HOSTNAME_POST
  meta: { deferred: Deferred }
  payload: Codecs.GlobalHostnamePostPayload
}

export function GLOBAL_HOSTNAME_POST(
  payload: GLOBAL_HOSTNAME_POST['payload'],
  deferred: Deferred,
): GLOBAL_HOSTNAME_POST {
  return {
    type: TypeKeys.GLOBAL_HOSTNAME_POST,
    meta: { deferred },
    payload,
  }
}

// global services
// ---------------------------------------------------------------------------

export type GLOBAL_SERVICES_STORE_UPDATE = {
  type: TypeKeys.GLOBAL_SERVICES_STORE_UPDATE
  payload: Codecs.GlobalServicesWithStatsResponse
}

export function GLOBAL_SERVICES_STORE_UPDATE(
  payload: GLOBAL_SERVICES_STORE_UPDATE['payload'],
): GLOBAL_SERVICES_STORE_UPDATE {
  return {
    type: TypeKeys.GLOBAL_SERVICES_STORE_UPDATE,
    payload,
  }
}

export type GLOBAL_SERVICES_FETCH = {
  type: TypeKeys.GLOBAL_SERVICES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_SERVICES_FETCH(
  payload: GLOBAL_SERVICES_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_SERVICES_FETCH {
  return {
    type: TypeKeys.GLOBAL_SERVICES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_SERVICE_TOTALS_FETCH = {
  type: TypeKeys.GLOBAL_SERVICE_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_SERVICE_TOTALS_FETCH(
  payload: GLOBAL_SERVICES_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_SERVICE_TOTALS_FETCH {
  return {
    type: TypeKeys.GLOBAL_SERVICE_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_SERVICE_TOTALS_STORE_UPDATE = {
  type: TypeKeys.GLOBAL_SERVICE_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function GLOBAL_SERVICE_TOTALS_STORE_UPDATE(
  payload: GLOBAL_SERVICE_TOTALS_STORE_UPDATE['payload'],
): GLOBAL_SERVICE_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.GLOBAL_SERVICE_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type GLOBAL_SERVICE_STATS_FETCH = {
  type: TypeKeys.GLOBAL_SERVICE_STATS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function GLOBAL_SERVICE_STATS_FETCH(
  payload: GLOBAL_SERVICE_STATS_FETCH['payload'],
  deferred: Deferred,
): GLOBAL_SERVICE_STATS_FETCH {
  return {
    type: TypeKeys.GLOBAL_SERVICE_STATS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_SERVICE_STATS_STORE_UPDATE = {
  type: TypeKeys.GLOBAL_SERVICE_STATS_STORE_UPDATE
  payload: Codecs.GlobalServiceStatsResponse
}

export function GLOBAL_SERVICE_STATS_STORE_UPDATE(
  payload: GLOBAL_SERVICE_STATS_STORE_UPDATE['payload'],
): GLOBAL_SERVICE_STATS_STORE_UPDATE {
  return {
    type: TypeKeys.GLOBAL_SERVICE_STATS_STORE_UPDATE,
    payload,
  }
}

export type GLOBAL_SERVICE_PATCH = {
  type: TypeKeys.GLOBAL_SERVICE_PATCH
  meta: { deferred: Deferred }
  payload: Codecs.GlobalServicePatchPayload['data'] & { id: string }
}

export function GLOBAL_SERVICE_PATCH(
  payload: GLOBAL_SERVICE_PATCH['payload'],
  deferred: Deferred,
): GLOBAL_SERVICE_PATCH {
  return {
    type: TypeKeys.GLOBAL_SERVICE_PATCH,
    meta: { deferred },
    payload,
  }
}

export type GLOBAL_SERVICE_STORE_UPDATE = {
  type: TypeKeys.GLOBAL_SERVICE_STORE_UPDATE
  payload: Codecs.GlobalServicePatchResponse
}

export function GLOBAL_SERVICE_STORE_UPDATE(
  payload: GLOBAL_SERVICE_STORE_UPDATE['payload'],
): GLOBAL_SERVICE_STORE_UPDATE {
  return {
    type: TypeKeys.GLOBAL_SERVICE_STORE_UPDATE,
    payload,
  }
}

type GLOBAL_SERVICE_POST_MANY_PAYLOAD_SERVICES = ServiceFormUtils.PasteFormValues['services'][number]

type GLOBAL_SERVICE_POST_MANY_PAYLOAD = {
  entityType: EntityUtils.EntityType
  nextValues: {
    services: Omit<GLOBAL_SERVICE_POST_MANY_PAYLOAD_SERVICES, 'cpe'>[]
  }
}

export type GLOBAL_SERVICE_POST_MANY = {
  type: TypeKeys.GLOBAL_SERVICE_POST_MANY
  meta: { deferred: Deferred }
  payload: GLOBAL_SERVICE_POST_MANY_PAYLOAD
}

export function GLOBAL_SERVICE_POST_MANY(
  payload: GLOBAL_SERVICE_POST_MANY['payload'],
  deferred: Deferred,
): GLOBAL_SERVICE_POST_MANY {
  return {
    type: TypeKeys.GLOBAL_SERVICE_POST_MANY,
    meta: { deferred },
    payload,
  }
}

// suggestions
// ---------------------------------------------------------------------------

export type SERVICE_SUGGESTIONS_BULK_POST = {
  type: TypeKeys.SERVICE_SUGGESTIONS_BULK_POST
  meta: { deferred: Deferred }
  payload: Codecs.ServiceSuggestionBulkPostPayload
}

export function SERVICE_SUGGESTIONS_BULK_POST(
  payload: SERVICE_SUGGESTIONS_BULK_POST['payload'],
  deferred: Deferred,
): SERVICE_SUGGESTIONS_BULK_POST {
  return {
    type: TypeKeys.SERVICE_SUGGESTIONS_BULK_POST,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_SUGGESTIONS_STORE_UPDATE = {
  type: TypeKeys.SERVICE_SUGGESTIONS_STORE_UPDATE
  payload: Codecs.ServiceSuggestionWithStatsResponse
}

export function SERVICE_SUGGESTIONS_STORE_UPDATE(
  payload: SERVICE_SUGGESTIONS_STORE_UPDATE['payload'],
): SERVICE_SUGGESTIONS_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_SUGGESTIONS_STORE_UPDATE,
    payload,
  }
}

export type SERVICE_SUGGESTIONS_FETCH = {
  type: TypeKeys.SERVICE_SUGGESTIONS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SERVICE_SUGGESTIONS_FETCH(
  payload: SERVICE_SUGGESTIONS_FETCH['payload'],
  deferred: Deferred,
): SERVICE_SUGGESTIONS_FETCH {
  return {
    type: TypeKeys.SERVICE_SUGGESTIONS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_SUGGESTION_TOTALS_FETCH = {
  type: TypeKeys.SERVICE_SUGGESTION_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SERVICE_SUGGESTION_TOTALS_FETCH(
  payload: SERVICE_SUGGESTIONS_FETCH['payload'],
  deferred: Deferred,
): SERVICE_SUGGESTION_TOTALS_FETCH {
  return {
    type: TypeKeys.SERVICE_SUGGESTION_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_SUGGESTION_TOTALS_STORE_UPDATE = {
  type: TypeKeys.SERVICE_SUGGESTION_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function SERVICE_SUGGESTION_TOTALS_STORE_UPDATE(
  payload: SERVICE_SUGGESTION_TOTALS_STORE_UPDATE['payload'],
): SERVICE_SUGGESTION_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_SUGGESTION_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type SERVICE_SUGGESTION_PATCH = {
  type: TypeKeys.SERVICE_SUGGESTION_PATCH
  meta: { deferred: Deferred }
  payload: Codecs.ServiceSuggestionPatchPayload['data'] & { id: string }
}

export function SERVICE_SUGGESTION_PATCH(
  payload: SERVICE_SUGGESTION_PATCH['payload'],
  deferred: Deferred,
): SERVICE_SUGGESTION_PATCH {
  return {
    type: TypeKeys.SERVICE_SUGGESTION_PATCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_SUGGESTION_STATS_FETCH = {
  type: TypeKeys.SERVICE_SUGGESTION_STATS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SERVICE_SUGGESTION_STATS_FETCH(
  payload: SERVICE_SUGGESTION_STATS_FETCH['payload'],
  deferred: Deferred,
): SERVICE_SUGGESTION_STATS_FETCH {
  return {
    type: TypeKeys.SERVICE_SUGGESTION_STATS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_SUGGESTION_STATS_STORE_UPDATE = {
  type: TypeKeys.SERVICE_SUGGESTION_STATS_STORE_UPDATE
  payload: Codecs.ServiceSuggestionStatsResponse
}

export function SERVICE_SUGGESTION_STATS_STORE_UPDATE(
  payload: SERVICE_SUGGESTION_STATS_STORE_UPDATE['payload'],
): SERVICE_SUGGESTION_STATS_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_SUGGESTION_STATS_STORE_UPDATE,
    payload,
  }
}

type SERVICE_SUGGESTION_PROMOTE_PAYLOAD = {
  id: string
  cpe: Codecs.CPE | null
} & Omit<ServiceFormUtils.SuggestionFormValues, 'cpe'>

export type SERVICE_SUGGESTION_PROMOTE = {
  type: TypeKeys.SERVICE_SUGGESTION_PROMOTE
  meta: { deferred: Deferred }
  payload: SERVICE_SUGGESTION_PROMOTE_PAYLOAD
}

export function SERVICE_SUGGESTION_PROMOTE(
  payload: SERVICE_SUGGESTION_PROMOTE['payload'],
  deferred: Deferred,
): SERVICE_SUGGESTION_PROMOTE {
  return {
    type: TypeKeys.SERVICE_SUGGESTION_PROMOTE,
    meta: { deferred },
    payload,
  }
}

// service rules
// ---------------------------------------------------------------------------

export type SERVICE_RULES_FETCH = {
  type: TypeKeys.SERVICE_RULES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SERVICE_RULES_FETCH(payload: SERVICE_RULES_FETCH['payload'], deferred: Deferred): SERVICE_RULES_FETCH {
  return {
    type: TypeKeys.SERVICE_RULES_FETCH,
    meta: { deferred },
    payload,
  }
}
export type SERVICE_RULES_STORE_UPDATE = {
  type: TypeKeys.SERVICE_RULES_STORE_UPDATE
  payload: Codecs.ServiceRuleResponse
}

export function SERVICE_RULES_STORE_UPDATE(payload: SERVICE_RULES_STORE_UPDATE['payload']): SERVICE_RULES_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_RULES_STORE_UPDATE,
    payload,
  }
}

export type SERVICE_RULE_STORE_UPDATE = {
  type: TypeKeys.SERVICE_RULE_STORE_UPDATE
  payload: Codecs.ServiceRuleResponse
}

export function SERVICE_RULE_STORE_UPDATE(payload: SERVICE_RULE_STORE_UPDATE['payload']): SERVICE_RULE_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_RULE_STORE_UPDATE,
    payload,
  }
}

export type SERVICE_RULE_TOTALS_STORE_UPDATE = {
  type: TypeKeys.SERVICE_RULE_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function SERVICE_RULE_TOTALS_STORE_UPDATE(
  payload: SERVICE_RULE_TOTALS_STORE_UPDATE['payload'],
): SERVICE_RULE_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_RULE_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type SERVICE_RULE_STATS_STORE_UPDATE = {
  type: TypeKeys.SERVICE_RULE_STATS_STORE_UPDATE
  payload: Codecs.ServiceRuleResponse
}

export function SERVICE_RULE_STATS_STORE_UPDATE(
  payload: SERVICE_RULE_STATS_STORE_UPDATE['payload'],
): SERVICE_RULE_STATS_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_RULE_STATS_STORE_UPDATE,
    payload,
  }
}

export type SERVICE_RULES_METADATA_FETCH = {
  type: TypeKeys.SERVICE_RULES_METADATA_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function SERVICE_RULES_METADATA_FETCH(
  payload: SERVICE_RULES_METADATA_FETCH['payload'],
  deferred: Deferred,
): SERVICE_RULES_METADATA_FETCH {
  return {
    type: TypeKeys.SERVICE_RULES_METADATA_FETCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_RULES_METADATA_STORE_UPDATE = {
  type: TypeKeys.SERVICE_RULES_METADATA_STORE_UPDATE
  payload: Codecs.ServiceRuleMetadataResponse
}

export function SERVICE_RULES_METADATA_STORE_UPDATE(
  payload: SERVICE_RULES_METADATA_STORE_UPDATE['payload'],
): SERVICE_RULES_METADATA_STORE_UPDATE {
  return {
    type: TypeKeys.SERVICE_RULES_METADATA_STORE_UPDATE,
    payload,
  }
}

export type SERVICE_RULE_PATCH = {
  type: TypeKeys.SERVICE_RULE_PATCH
  meta: { deferred: Deferred }
  payload: Codecs.ServiceRulePatchPayload['data'] & { id: string }
}

export function SERVICE_RULE_PATCH(payload: SERVICE_RULE_PATCH['payload'], deferred: Deferred): SERVICE_RULE_PATCH {
  return {
    type: TypeKeys.SERVICE_RULE_PATCH,
    meta: { deferred },
    payload,
  }
}

export type SERVICE_RULE_POST = {
  type: TypeKeys.SERVICE_RULE_POST
  meta: { deferred: Deferred }
  payload: {
    data: Codecs.ServiceRulePostPayload['data']
    currentQuery: string
  }
}

export function SERVICE_RULE_POST(
  payload: SERVICE_RULE_POST['payload']['data'],
  currentQuery: string,
  deferred: Deferred,
): SERVICE_RULE_POST {
  return {
    type: TypeKeys.SERVICE_RULE_POST,
    meta: { deferred },
    payload: {
      data: payload,
      currentQuery,
    },
  }
}

// characteristic rules
// ---------------------------------------------------------------------------

export type CHARACTERISTIC_RULES_FETCH = {
  type: TypeKeys.CHARACTERISTIC_RULES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function CHARACTERISTIC_RULES_FETCH(
  payload: CHARACTERISTIC_RULES_FETCH['payload'],
  deferred: Deferred,
): CHARACTERISTIC_RULES_FETCH {
  return {
    type: TypeKeys.CHARACTERISTIC_RULES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type CHARACTERISTIC_RULES_STORE_UPDATE = {
  type: TypeKeys.CHARACTERISTIC_RULES_STORE_UPDATE
  payload: Codecs.CharacteristicRuleResponse
}

export function CHARACTERISTIC_RULES_STORE_UPDATE(
  payload: CHARACTERISTIC_RULES_STORE_UPDATE['payload'],
): CHARACTERISTIC_RULES_STORE_UPDATE {
  return {
    type: TypeKeys.CHARACTERISTIC_RULES_STORE_UPDATE,
    payload,
  }
}

export type CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE = {
  type: TypeKeys.CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE(
  payload: CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE['payload'],
): CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.CHARACTERISTIC_RULE_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type CHARACTERISTIC_RULE_STATS_STORE_UPDATE = {
  type: TypeKeys.CHARACTERISTIC_RULE_STATS_STORE_UPDATE
  payload: Codecs.CharacteristicRuleResponse
}

export function CHARACTERISTIC_RULE_STATS_STORE_UPDATE(
  payload: CHARACTERISTIC_RULE_STATS_STORE_UPDATE['payload'],
): CHARACTERISTIC_RULE_STATS_STORE_UPDATE {
  return {
    type: TypeKeys.CHARACTERISTIC_RULE_STATS_STORE_UPDATE,
    payload,
  }
}

export type CHARACTERISTIC_RULES_METADATA_FETCH = {
  type: TypeKeys.CHARACTERISTIC_RULES_METADATA_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function CHARACTERISTIC_RULES_METADATA_FETCH(
  payload: CHARACTERISTIC_RULES_METADATA_FETCH['payload'],
  deferred: Deferred,
): CHARACTERISTIC_RULES_METADATA_FETCH {
  return {
    type: TypeKeys.CHARACTERISTIC_RULES_METADATA_FETCH,
    meta: { deferred },
    payload,
  }
}

export type CHARACTERISTIC_RULES_METADATA_STORE_UPDATE = {
  type: TypeKeys.CHARACTERISTIC_RULES_METADATA_STORE_UPDATE
  payload: Codecs.CharacteristicRuleMetadataResponse
}

export function CHARACTERISTIC_RULES_METADATA_STORE_UPDATE(
  payload: CHARACTERISTIC_RULES_METADATA_STORE_UPDATE['payload'],
): CHARACTERISTIC_RULES_METADATA_STORE_UPDATE {
  return {
    type: TypeKeys.CHARACTERISTIC_RULES_METADATA_STORE_UPDATE,
    payload,
  }
}

export type CHARACTERISTIC_RULE_PATCH = {
  type: TypeKeys.CHARACTERISTIC_RULE_PATCH
  meta: { deferred: Deferred }
  payload: Codecs.CharacteristicRulePatchPayload['data'] & { id: string }
}

export function CHARACTERISTIC_RULE_PATCH(
  payload: CHARACTERISTIC_RULE_PATCH['payload'],
  deferred: Deferred,
): CHARACTERISTIC_RULE_PATCH {
  return {
    type: TypeKeys.CHARACTERISTIC_RULE_PATCH,
    meta: { deferred },
    payload,
  }
}

export type CHARACTERISTIC_RULE_POST = {
  type: TypeKeys.CHARACTERISTIC_RULE_POST
  meta: { deferred: Deferred }
  payload: {
    data: Codecs.CharacteristicRulePostPayload['data']
    currentQuery: string
  }
}

export function CHARACTERISTIC_RULE_POST(
  payload: CHARACTERISTIC_RULE_POST['payload']['data'],
  currentQuery: string,
  deferred: Deferred,
): CHARACTERISTIC_RULE_POST {
  return {
    type: TypeKeys.CHARACTERISTIC_RULE_POST,
    meta: { deferred },
    payload: {
      data: payload,
      currentQuery,
    },
  }
}

export type CHARACTERISTIC_RULE_STORE_UPDATE = {
  type: TypeKeys.CHARACTERISTIC_RULE_STORE_UPDATE
  payload: Codecs.CharacteristicRuleResponse
}

export function CHARACTERISTIC_RULE_STORE_UPDATE(
  payload: CHARACTERISTIC_RULE_STORE_UPDATE['payload'],
): CHARACTERISTIC_RULE_STORE_UPDATE {
  return {
    type: TypeKeys.CHARACTERISTIC_RULE_STORE_UPDATE,
    payload,
  }
}

// artifacts
// ---------------------------------------------------------------------------

export type ARTIFACTS_FETCH = {
  type: TypeKeys.ARTIFACTS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function ARTIFACTS_FETCH(payload: ARTIFACTS_FETCH['payload'], deferred: Deferred): ARTIFACTS_FETCH {
  return {
    type: TypeKeys.ARTIFACTS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ARTIFACTS_STORE_UPDATE = {
  type: TypeKeys.ARTIFACTS_STORE_UPDATE
  payload: Codecs.ArtifactsResponse
}

export function ARTIFACTS_STORE_UPDATE(payload: ARTIFACTS_STORE_UPDATE['payload']): ARTIFACTS_STORE_UPDATE {
  return {
    type: TypeKeys.ARTIFACTS_STORE_UPDATE,
    payload,
  }
}

export type ARTIFACT_TOTALS_FETCH = {
  type: TypeKeys.ARTIFACT_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function ARTIFACT_TOTALS_FETCH(payload: ARTIFACTS_FETCH['payload'], deferred: Deferred): ARTIFACT_TOTALS_FETCH {
  return {
    type: TypeKeys.ARTIFACT_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ARTIFACT_TOTALS_STORE_UPDATE = {
  type: TypeKeys.ARTIFACT_TOTALS_STORE_UPDATE
  payload: Omit<Codecs.HocEntityTotals, 'unfilteredTotal'>
}

export function ARTIFACT_TOTALS_STORE_UPDATE(
  payload: ARTIFACT_TOTALS_STORE_UPDATE['payload'],
): ARTIFACT_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.ARTIFACT_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type ARTIFACT_RETRIEVE = {
  type: TypeKeys.ARTIFACT_RETRIEVE
  meta: { deferred: Deferred }
  payload: {
    orgId?: string
    shasum: string
  }
}

export function ARTIFACT_RETRIEVE(payload: ARTIFACT_RETRIEVE['payload'], deferred: Deferred): ARTIFACT_RETRIEVE {
  return {
    type: TypeKeys.ARTIFACT_RETRIEVE,
    meta: { deferred },
    payload,
  }
}

export type ARTIFACT_DIRECT_RETRIEVE = {
  type: TypeKeys.ARTIFACT_DIRECT_RETRIEVE
  meta: { deferred: Deferred }
  payload: {
    shasum: string
  }
}

export function ARTIFACT_DIRECT_RETRIEVE(
  payload: ARTIFACT_DIRECT_RETRIEVE['payload'],
  deferred: Deferred,
): ARTIFACT_DIRECT_RETRIEVE {
  return {
    type: TypeKeys.ARTIFACT_DIRECT_RETRIEVE,
    meta: { deferred },
    payload,
  }
}

export type ARTIFACT_DOWNLOAD = {
  type: TypeKeys.ARTIFACT_DOWNLOAD
  meta: { deferred: Deferred }
  payload: {
    artifactType: Codecs.Artifact['artifact_type']
    dataHash: Codecs.Artifact['data_hash']
  }
}

export function ARTIFACT_DOWNLOAD(payload: ARTIFACT_DOWNLOAD['payload'], deferred: Deferred): ARTIFACT_DOWNLOAD {
  return {
    type: TypeKeys.ARTIFACT_DOWNLOAD,
    meta: { deferred },
    payload,
  }
}

// ---------------------------------------------------------------------------

export type VALIDATE_SERVICE_REGEX = {
  type: TypeKeys.VALIDATE_SERVICE_REGEX
  meta: { deferred: Deferred }
  payload: {
    artifact_ids: string[]
    service_rule: Codecs.ServiceRulePostPayload['data']
  }
}

export function VALIDATE_SERVICE_REGEX(
  payload: VALIDATE_SERVICE_REGEX['payload'],
  deferred: Deferred,
): VALIDATE_SERVICE_REGEX {
  return {
    type: TypeKeys.VALIDATE_SERVICE_REGEX,
    meta: { deferred },
    payload,
  }
}

export type VALIDATE_CHARACTERISTIC_REGEX = {
  type: TypeKeys.VALIDATE_CHARACTERISTIC_REGEX
  meta: { deferred: Deferred }
  payload: {
    artifact_ids: string[]
    characteristic_rule: Codecs.CharacteristicRulePatchPayload['data']
  }
}

export function VALIDATE_CHARACTERISTIC_REGEX(
  payload: VALIDATE_CHARACTERISTIC_REGEX['payload'],
  deferred: Deferred,
): VALIDATE_CHARACTERISTIC_REGEX {
  return {
    type: TypeKeys.VALIDATE_CHARACTERISTIC_REGEX,
    meta: { deferred },
    payload,
  }
}

// ---------------------------------------------------------------------------`

export type POCS_FETCH = {
  type: TypeKeys.POCS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function POCS_FETCH(payload: POCS_FETCH['payload'], deferred: Deferred): POCS_FETCH {
  return {
    type: TypeKeys.POCS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type POCS_STORE_UPDATE = {
  type: TypeKeys.POCS_STORE_UPDATE
  payload: Codecs.PocResponse
}

export function POCS_STORE_UPDATE(payload: POCS_STORE_UPDATE['payload']): POCS_STORE_UPDATE {
  return {
    type: TypeKeys.POCS_STORE_UPDATE,
    payload,
  }
}

export type POCS_TOTALS_FETCH = {
  type: TypeKeys.POCS_TOTALS_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function POCS_TOTALS_FETCH(payload: ARTIFACTS_FETCH['payload'], deferred: Deferred): POCS_TOTALS_FETCH {
  return {
    type: TypeKeys.POCS_TOTALS_FETCH,
    meta: { deferred },
    payload,
  }
}

export type POCS_TOTALS_STORE_UPDATE = {
  type: TypeKeys.POCS_TOTALS_STORE_UPDATE
  payload: Codecs.HocEntityTotals
}

export function POCS_TOTALS_STORE_UPDATE(payload: POCS_TOTALS_STORE_UPDATE['payload']): POCS_TOTALS_STORE_UPDATE {
  return {
    type: TypeKeys.POCS_TOTALS_STORE_UPDATE,
    payload,
  }
}

export type POCS_POST = {
  type: TypeKeys.POCS_POST
  meta: { deferred: Deferred }
  payload: Codecs.PocPostPayload
}

export function POCS_POST(payload: POCS_POST['payload'], deferred: Deferred): POCS_POST {
  return {
    type: TypeKeys.POCS_POST,
    meta: { deferred },
    payload,
  }
}

// ---------------------------------------------------------------------------

export type PERIODIC_POST = {
  type: TypeKeys.PERIODIC_POST
  meta: { deferred: Deferred }
  payload: Codecs.PostPeriodicPayload
}

export function PERIODIC_POST(payload: PERIODIC_POST['payload'], deferred: Deferred): PERIODIC_POST {
  return {
    type: TypeKeys.PERIODIC_POST,
    meta: { deferred },
    payload,
  }
}

export type POST_ORG_KEYVAL = {
  type: TypeKeys.POST_ORG_KEYVAL
  meta: { deferred: Deferred }
  payload: Codecs.PostOrgKeyValPayload
}

export function POST_ORG_KEYVAL(payload: POST_ORG_KEYVAL['payload'], deferred: Deferred): POST_ORG_KEYVAL {
  return {
    type: TypeKeys.POST_ORG_KEYVAL,
    meta: { deferred },
    payload,
  }
}

// org primes
// ---------------------------------------------------------------------------

export type GET_ORG_PRIMES = {
  type: TypeKeys.GET_ORG_PRIMES
  meta: { deferred: Deferred }
  payload: {
    org_id: string
  }
}

export function GET_ORG_PRIMES(payload: GET_ORG_PRIMES['payload'], deferred: Deferred): GET_ORG_PRIMES {
  return {
    type: TypeKeys.GET_ORG_PRIMES,
    meta: { deferred },
    payload,
  }
}
