/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { ActivityOperator } from './activity-operator.codecs'
import * as FieldTypeCodecs from './field-type.codecs'
import { ValidationObject } from './validation.codecs'

// ---------------------------------------------------------------------------

const BaseTriggerCriteriaAttributes = t.type({
  description: t.string,
  field_label: t.string,
  field_type: FieldTypeCodecs.FieldType,
  display_value: t.string,
  input_variable_path: t.string,
  is_configurable: t.boolean,
  is_matching: t.boolean,
  is_standard: t.boolean,
  operator: ActivityOperator,
  trigger_identifier: t.string,
  validation: ValidationObject,
  value: t.unknown,
})

export const PeriodTriggerCriteria = t.intersection([
  t.type({
    operator: t.literal('greater_than_or_equal_to'),
  }),
  FieldTypeCodecs.PeriodFieldType,
  BaseTriggerCriteriaAttributes,
])

export const NumberTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([
      t.literal('equal'),
      t.literal('not_equal'),
      t.literal('less_than'),
      t.literal('less_than_or_equal_to'),
      t.literal('greater_than'),
      t.literal('greater_than_or_equal_to'),
    ]),
  }),
  FieldTypeCodecs.NumberFieldType,
  BaseTriggerCriteriaAttributes,
])

export const NumberListTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('in'), t.literal('not_in')]),
  }),
  FieldTypeCodecs.NumberListFieldType,
  BaseTriggerCriteriaAttributes,
])

export const NumberRangeTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('in'), t.literal('not_in')]),
  }),
  FieldTypeCodecs.NumberRangeFieldType,
  BaseTriggerCriteriaAttributes,
])

export const PortTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([
      t.literal('equal'),
      t.literal('not_equal'),
      t.literal('less_than'),
      t.literal('less_than_or_equal_to'),
      t.literal('greater_than'),
      t.literal('greater_than_or_equal_to'),
    ]),
  }),
  FieldTypeCodecs.PortFieldType,
  BaseTriggerCriteriaAttributes,
])

export const PortListTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('in'), t.literal('not_in')]),
  }),
  FieldTypeCodecs.PortListFieldType,
  BaseTriggerCriteriaAttributes,
])

export const PortRangeTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('in'), t.literal('not_in')]),
  }),
  FieldTypeCodecs.PortRangeFieldType,
  BaseTriggerCriteriaAttributes,
])

export const PortListofRangesTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('in'), t.literal('not_in')]),
  }),
  FieldTypeCodecs.PortListofRangesFieldType,
  BaseTriggerCriteriaAttributes,
])

export const StringTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([
      t.literal('equal'),
      t.literal('not_equal'),
      t.literal('contains'),
      t.literal('not_contains'),
    ]),
  }),
  FieldTypeCodecs.StringFieldType,
  BaseTriggerCriteriaAttributes,
])

export const StringListTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('in'), t.literal('not_in'), t.literal('not_match'), t.literal('match')]),
  }),
  FieldTypeCodecs.StringListFieldType,
  BaseTriggerCriteriaAttributes,
])

export const BooleanTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('equal'), t.literal('exists')]),
  }),
  FieldTypeCodecs.BooleanFieldType,
  BaseTriggerCriteriaAttributes,
])

export const ServiceTriggerCriteria = t.intersection([
  t.type({
    operator: t.union([t.literal('service_match'), t.literal('service_not_match')]),
  }),
  FieldTypeCodecs.ServiceFieldType,
  BaseTriggerCriteriaAttributes,
])

export const TriggerCriteria = t.type({
  type: t.literal('trigger_criteria'),
  attributes: t.union([
    BooleanTriggerCriteria,
    NumberListTriggerCriteria,
    NumberRangeTriggerCriteria,
    NumberTriggerCriteria,
    PeriodTriggerCriteria,
    PortListofRangesTriggerCriteria,
    PortListTriggerCriteria,
    PortRangeTriggerCriteria,
    PortTriggerCriteria,
    ServiceTriggerCriteria,
    StringListTriggerCriteria,
    StringTriggerCriteria,
  ]),
})

export type TriggerCriteria = t.TypeOf<typeof TriggerCriteria>
