/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// @TODO: Rename this function
//
// This is poorly named. nullable should not include undefined

/**
 * Make an io-ts validator accept `null` or `undefined`
 *
 * @remarks
 * This is poorly named. nullable should not include `undefined`
 *
 * @typeParam T - the type we're adding `null` to
 *
 * @param type - the type we're adding `null` to
 *
 * @returns the nullable type
 */
export function nullable<T extends t.Mixed>(type: T) {
  return t.union([type, t.undefined, t.null])
}

/**
 * Make an io-ts validator accept `null`
 *
 * @remarks
 * Differs from `nullable`, in that it excludes `undefined`.
 *
 * @typeParam T - the type we're adding `null` to
 *
 * @param type - the type we're adding `null` to
 *
 * @returns the nullable type
 */
export function nullableP<T extends t.Mixed>(type: T) {
  return t.union([type, t.null])
}
