/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const PaginationMeta = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
})
export type PaginationMeta = t.TypeOf<typeof PaginationMeta>

const _Link = t.union([
  t.string,
  t.type({
    href: t.string,
    meta: t.union([PaginationMeta, t.undefined]),
  }),
])

export const Link = t.type({
  self: t.union([_Link, t.undefined]),
})
export type Link = t.TypeOf<typeof Link>

export const PaginatedLink = t.type({
  self: t.union([_Link, t.undefined]),
  next: t.union([_Link, t.undefined]),
  last: t.union([_Link, t.undefined]),
})
export type PaginatedLink = t.TypeOf<typeof PaginatedLink>
