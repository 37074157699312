/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { capitalize, lowerCase } from 'lodash/fp'

import * as Codecs from '@/codecs'
import * as Store from '@/store'
import * as WorkatoUtils from '@/utilities/workato'

import AWSLogo from '../../static/images/svg-out/aws-logo.svg'
import AzureLogo from '../../static/images/svg-out/azure.svg'
import CloudpakLogo from '../../static/images/svg-out/cloudpak.svg'
import JiraLogo from '../../static/images/svg-out/jira-logo.svg'
import NoLogo from '../../static/images/svg-out/no-image.svg'
import QRadarSIEMLogo from '../../static/images/svg-out/qradar-siem.svg'
import QRadarSOARLogo from '../../static/images/svg-out/qradar-soar.svg'
import QualysLogo from '../../static/images/svg-out/qualys-logo.svg'
import RandoriLogo from '../../static/images/svg-out/randori-logo.svg'
import ServiceNowLogo from '../../static/images/svg-out/service-now-logo.svg'
import SplunkLogo from '../../static/images/svg-out/splunk-logo.svg'
import TenableLogo from '../../static/images/svg-out/tenable-logo.svg'

// ---------------------------------------------------------------------------

export const WORKATO_CONNECTOR_NAME_TO_IMAGE: Record<string, string> = {
  __default__: NoLogo,
  aws: AWSLogo,
  azure: AzureLogo,
  jira: JiraLogo,
  qualys: QualysLogo,
  randori: RandoriLogo,
  servicenow: ServiceNowLogo,
  splunk: SplunkLogo,
  tenable: TenableLogo,
}

export const DOCUMENTATION_NAME_TO_IMAGE: Record<string, string> = {
  __default__: NoLogo,
  cloudpak: CloudpakLogo,
  qradarsiem: QRadarSIEMLogo,
  qradarsoar: QRadarSOARLogo,
}

export const WORKATO_CONNECTOR_NAME_TO_DESCRIPTION: Record<string, string> = {
  aws: 'Integrate with AWS to discover cloud-hosted Targets',
  azure: 'Integrate with Azure to discover cloud-hosted Targets',
  jira: 'Create, assign, and track issues.',
  qualys: 'Discover, assess, prioritize, and patch critical vulnerabilities.',
  randori: 'Add your Randori API token to Workato in order to configure more integrations.',
  servicenow: 'Create, assign, and track incidents.',
  splunk: 'Monitor for security-related events.',
  tenable: 'Discover, assess, prioritize, and patch critical vulnerabilities.',
}

export const WORKATO_RECIPE_NAME_TO_IMAGE: Record<string, string> = WORKATO_CONNECTOR_NAME_TO_IMAGE

export const useWorkatoJWT = () => {
  const dispatch = Store.useAppDispatch()

  return new Promise<Codecs.LoginResponse>((resolve, reject) => {
    const deferred = {
      success: resolve,
      failure: reject,
    }

    dispatch(Store.AuthActions.GET_WORKATO_JWT(deferred))
  })
}

export function useWidgetURL(connectionId: number) {
  const workatoJWT = useWorkatoJWT()

  return async () => {
    try {
      const { Authorization } = await workatoJWT

      return WorkatoUtils.getConnectionsUrl(connectionId, Authorization)
    } catch (error) {
      throw new Error('Unable to build workato direct link', { cause: error as Error })
    }
  }
}

export const extractConnectionNameFromRecipe = (recipeName: Codecs.WorkatoRecipeName) => {
  const [_workatoType, _recipeIdentifier, connections] = recipeName.split('--')

  if (connections === undefined) {
    return '__default__'
  }

  const [connectionName, ..._rest] = connections.split('-')

  return lowerCase(connectionName)
}

export const getConnectionNameDisplayName = (connectionName: string) => {
  switch (connectionName) {
    case 'azure':
      return 'Microsoft Azure'

    case 'aws':
      return 'Amazon Web Services'

    case 'servicenow':
      return 'ServiceNow'

    default:
      return capitalize(connectionName)
  }
}
