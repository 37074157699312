/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { HostnameP as Hostname } from '@/codecs'

import { makeNormalizer } from '../utilities/make-normalizer'
import { EntityName } from './hostname.constants'

// ---------------------------------------------------------------------------

export const normalizeHostnames = makeNormalizer<EntityName, Hostname>(EntityName)
