/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ExhaustiveError, isNotNil } from '@randori/rootkit'
import { createSelector } from '@reduxjs/toolkit'

import * as Codecs from '@/codecs'
import type { AppState } from '@/store'
import { get, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

/**
 * Normalizes the structure of a perspective object for display in the summary table
 *
 * @param perspective - The perspective object to normalize
 *
 * @returns An object with varying fields due to implementation of Perspective parameters/properties
 *
 */
export const normalizePerspective = (perspective: Codecs.PerspectiveV2): Codecs.PerspectiveV2Normalized => {
  const {
    id,
    perspectiveType: perspectiveType,
    name,
    createTime: createTime,
    updateTime: updateTime,
    properties,
  } = perspective

  const normalizedPerspective = {
    id: get(id, UUID),
    perspectiveType: perspectiveType,
    name,
    createTime: createTime,
    updateTime: updateTime,
    // extract everything from the properties blackbox
    ...properties,
  }

  return normalizedPerspective
}

export const selectPerspectives = createSelector([(state: AppState) => state.perspective], (perspectivesState) => {
  return perspectivesState.result.map((uuid) => normalizePerspective(perspectivesState.entities.perspective[uuid]))
})

type WhichPerspectives = 'all' | 'internal' | 'external'

// @see: https://redux.js.org/usage/deriving-data-selectors#passing-input-parameters
export const selectConstrainedPerspectives = createSelector(
  [
    (state: AppState, _props: { whichPerspectives: WhichPerspectives }) => state.perspective,
    (_state: AppState, props: { whichPerspectives: WhichPerspectives }) => {
      return props.whichPerspectives
    },
  ],
  (reducer, which) => {
    const perspectives = reducer.result.map((uuid) => normalizePerspective(reducer.entities.perspective[uuid]))

    switch (which) {
      case 'all': {
        return perspectives
      }

      case 'internal': {
        return perspectives.filter(({ perspectiveType }) => perspectiveType === 'PERSPECTIVE_TYPE_INTERNAL')
      }

      case 'external': {
        return perspectives.filter(({ perspectiveType }) => perspectiveType === 'PERSPECTIVE_TYPE_EXTERNAL')
      }

      default:
        throw new ExhaustiveError(which)
    }
  },
)

export const _selectPerspectiveById = (state: AppState, props: { id: UUID }) => {
  const perspective = state.perspective.entities.perspective[props.id]

  return perspective
}

export const selectPerspectiveById = createSelector([_selectPerspectiveById], (perspective) => {
  if (isNotNil(perspective)) {
    return normalizePerspective(perspective)
  }
})
