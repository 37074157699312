/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, UUID } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'

import * as actions from './social.actions'
import { normalizeSocials } from './social.schema'
import { socialSlice } from './social.slice'

// ---------------------------------------------------------------------------

export function* socialSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchSocial.toString(), deferredAction(_fetchSocial, io))
  yield takeEvery(actions.fetchSocialTotals.toString(), deferredAction(_fetchSocialTotals, io))
  yield takeEvery(actions.fetchSocials.toString(), deferredAction(_fetchSocials, io))
}

export function* _fetchSocials(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchSocials>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.social.getSocials, queryString)

  if (action.meta.persist) {
    yield* put(socialSlice.actions.replaceSocials(normalizeSocials(response.data)))

    yield* put(
      socialSlice.actions.replaceSocialPagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchSocial(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchSocial>) {
  const id = get(action.payload, UUID)

  const { data } = yield* call(io.api.social.getSocial, id)

  yield* put(socialSlice.actions.upsertSocial(data[0]))

  return data[0]
}

export function* _fetchSocialTotals(io: MiddlewaresIO, _action: ReturnType<typeof actions.fetchSocialTotals>) {
  const { total: unfiltered } = yield* call(
    io.api.social.getSocials,
    get(CrudQueryUtils.createUnfilteredQuery(), QueryString),
  )

  const { total: unaffiliated } = yield* call(
    io.api.social.getSocials,
    get(CrudQueryUtils.createUnaffiliatedQuery(), QueryString),
  )

  const totals = {
    unfiltered: unfiltered,
    unaffiliated,
  }

  yield* put(socialSlice.actions.replaceSocialTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}
