/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable @typescript-eslint/no-use-before-define */
import { head } from 'lodash/fp'
import * as Catch from 'redux-saga-try-catch'
import { call, put, select, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _OrgActions from '@/store/actions/organization/organization.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import * as CrudQuery from '@/utilities/crud-query'
import { isNotNil } from '@/utilities/is-not'
import * as Logger from '@/utilities/logger'
import { GLOBAL_PUBLIC_PERSPECTIVE_ID } from '@/utilities/perspective'

// ---------------------------------------------------------------------------

export function* watchOrganization(io: MiddlewaresIO) {
  yield takeEvery(Store.OrganizationActions.TypeKeys.FEATURES_FETCH, Catch.deferredAction(_FEATURES_FETCH, io))
  yield takeEvery(
    Store.OrganizationActions.TypeKeys.LOGIN_OPTIONS_PATCH,
    Catch.deferredAction(_LOGIN_OPTIONS_PATCH, io),
  )
  yield takeEvery(
    Store.OrganizationActions.TypeKeys.ORGANIZATION_CREATE,
    Catch.standardAction(_ORGANIZATION_CREATE, io),
  )
  yield takeEvery(Store.OrganizationActions.TypeKeys.ORGANIZATIONS_GET, Catch.deferredAction(_ORGANIZATIONS_GET, io))
  yield takeEvery(Store.OrganizationActions.TypeKeys.ORGANIZATION_GET, Catch.deferredAction(_ORGANIZATION_GET, io))
  yield takeEvery(Store.OrganizationActions.TypeKeys.ORGANIZATION_PATCH, Catch.deferredAction(_ORGANIZATION_PATCH, io))
  yield takeEvery(
    Store.OrganizationActions.TypeKeys.ORGANIZATIONS_PATCH,
    Catch.deferredAction(_ORGANIZATIONS_PATCH, io),
  )
  yield takeEvery(Store.OrganizationActions.TypeKeys.SSO_CONFIG_FETCH, Catch.deferredAction(_SSO_CONFIG_FETCH, io))
  yield takeEvery(Store.OrganizationActions.TypeKeys.SSO_CONFIG_POST, Catch.deferredAction(_SSO_CONFIG_POST, io))
  yield takeEvery(
    Store.OrganizationActions.TypeKeys.SSO_CONFIG_XML_POST,
    Catch.deferredAction(_SSO_CONFIG_XML_POST, io),
  )
  yield takeEvery(Store.OrganizationActions.TypeKeys.USERS_FETCH, Catch.deferredAction(_USERS_FETCH, io))
  yield takeEvery(Store.OrganizationActions.TypeKeys.USER_ROLES_FETCH, Catch.deferredAction(_USER_ROLES_FETCH, io))
  yield takeEvery(Store.OrganizationActions.TypeKeys.USER_ROLES_POST, Catch.deferredAction(_USER_ROLES_POST, io))
  yield takeEvery(
    Store.OrganizationActions.TypeKeys.USER_ROLE_OPTIONS_FETCH,
    Catch.deferredAction(_USER_ROLE_OPTIONS_FETCH, io),
  )
}

// ---------------------------------------------------------------------------

export function* _ORGANIZATION_CREATE(io: MiddlewaresIO, action: _OrgActions.ORGANIZATION_CREATE) {
  const { poc, ...org } = action.payload.data
  const { deferred } = action.meta

  try {
    const orgRes = yield* call(io.api.org.createOrg, { data: org })

    const orgId = head(orgRes.ids) as string
    const pocBody = {
      email: poc,
      org_id: orgId,
      perspective: GLOBAL_PUBLIC_PERSPECTIVE_ID,
    }

    yield* call(io.api.aggregator.postPoc, { data: [pocBody] })

    const { email } = pocBody
    const hostname = email.substring(email.lastIndexOf('@') + 1)
    const hostnameBody = {
      hostname,
      org_id: orgId,
      perspective: GLOBAL_PUBLIC_PERSPECTIVE_ID,
    }

    yield* call(io.api.aggregator.postGlobalHostname, { data: [hostnameBody], parent_info: {} })
    yield* call(io.api.scheduler.postPeriodic, { org_id: orgId })
    yield* call(deferred.success)

    return orgRes
  } catch (e) {
    yield* call(deferred.failure, e)
  }
}

export function* _ORGANIZATION_GET(io: MiddlewaresIO) {
  const { authorization } = yield* select(Store.SessionSelectors.selectSession)

  if (isNotNil(authorization) && isNotNil(authorization.view_org)) {
    const { data } = yield* call(io.api.org.getOrg, authorization.view_org)

    yield* put(Store.OrganizationActions.ORGANIZATION_UPDATE(data))

    return data
  }
}

export function* _ORGANIZATIONS_GET(io: MiddlewaresIO, action: _OrgActions.ORGANIZATIONS_GET) {
  const response = yield* call(io.api.org.getOrgs, action.payload)

  const { data, ...rest } = response

  if (data.length === 2000) {
    // the backend hard caps the number of rows returned to 2000
    Logger.log('Max number of orgs reached in this env')
  }

  yield* put(Store.OrganizationActions.ORGANIZATIONS_UPDATE(data))
  yield* put(Store.OrganizationActions.ORGANIZATIONS_PAGINATION_UPDATE(rest))

  return response
}

export function* _FEATURES_FETCH(io: MiddlewaresIO) {
  const { org_features } = yield* call(io.api.auth.getOrgFeatures)

  yield* put(Store.OrganizationActions.FEATURES_UPDATE(org_features))

  return org_features
}

export function* _USERS_FETCH(io: MiddlewaresIO, action: _OrgActions.USERS_FETCH) {
  const query = {
    ...action.payload,
    current_org_only: true,
    sort: 'username',
  }

  const response = yield* call(io.api.auth.getOrgUsers, CrudQuery.createQuery(query))

  const { data, ...rest } = response

  if (action.updateStore) {
    yield* put(Store.OrganizationActions.USERS_UPDATE(data))
    yield* put(Store.OrganizationActions.USERS_PAGINATION_UPDATE(rest))
  }

  return response
}

export function* _USER_ROLES_FETCH(io: MiddlewaresIO, action: _OrgActions.USER_ROLES_FETCH) {
  const response = yield* call(io.api.auth.getPermissionGroups, action.payload)

  yield* put(Store.OrganizationActions.USER_ROLES_UPDATE(response))

  return response
}

export function* _USER_ROLES_POST(io: MiddlewaresIO, action: _OrgActions.USER_ROLES_POST) {
  const response = yield* call(io.api.auth.updatePermissionGroups, action.payload)

  if (!action.meta.cancelRefetch) {
    yield* put(Store.OrganizationActions.USER_ROLES_UPDATE(response))
  }

  return response
}

export function* _USER_ROLE_OPTIONS_FETCH(io: MiddlewaresIO, _action: _OrgActions.USER_ROLE_OPTIONS_FETCH) {
  const response = yield* call(io.api.auth.getPermissionGroupTypes)

  return response
}

export function* _ORGANIZATION_PATCH(io: MiddlewaresIO, action: _OrgActions.ORGANIZATION_PATCH) {
  const id = yield* select(Store.OrganizationSelectors.selectOrgId)

  const response = yield* call(io.api.auth.patchOrg, id, action.payload)

  yield* put(Store.OrganizationActions.ORGANIZATION_UPDATE(response.data))

  return response
}

export function* _LOGIN_OPTIONS_PATCH(io: MiddlewaresIO, action: _OrgActions.LOGIN_OPTIONS_PATCH) {
  yield* call(io.stdout, action.payload)
}

export function* _SSO_CONFIG_POST(io: MiddlewaresIO, action: _OrgActions.SSO_CONFIG_POST) {
  const response = yield* call(io.api.auth.postSso, action.payload)

  yield* put(Store.OrganizationActions.SSO_PROVIDERS_STORE_UPDATE_SINGLE(response))

  return response
}

export function* _SSO_CONFIG_FETCH(io: MiddlewaresIO, _action: _OrgActions.SSO_CONFIG_FETCH) {
  const response = yield* call(io.api.auth.getSso)

  yield* put(Store.OrganizationActions.SSO_PROVIDERS_STORE_UPDATE(response))

  return response
}

export function* _ORGANIZATIONS_PATCH(io: MiddlewaresIO, action: _OrgActions.ORGANIZATIONS_PATCH) {
  const response = yield* call(io.api.auth.patchOrg, action.payload.id, action.payload.body)

  return response
}

export function* _SSO_CONFIG_XML_POST(io: MiddlewaresIO, action: _OrgActions.SSO_CONFIG_XML_POST) {
  const { data } = yield* call(io.api.auth.parseIdpXml, action.payload)

  return data
}
