/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import type { Message } from '@/http/http.utils'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  DISPATCH_API_REQUEST = 'DISPATCH_API_REQUEST',
  INITIALIZE_APP = 'INITIALIZE_APP',
  OPENAPI_FETCH = 'OPENAPI_FETCH',
  OPENAPI_SET = 'OPENAPI_SET',
  RESET = 'RESET',
  TRACK = 'TRACK',
  UNHANDLED_ACTION = '__unhandled__',
}

export type ActionTypes =
  | DISPATCH_API_REQUEST
  | OPENAPI_FETCH
  | OPENAPI_SET
  | OtherAction
  | Reset
  | Track
  | INITIALIZE_APP

export type OtherAction = {
  type: TypeKeys.UNHANDLED_ACTION
}

export type Reset = {
  type: TypeKeys.RESET
}

export function reset(): Reset {
  return {
    type: TypeKeys.RESET,
  }
}

export type Track = {
  type: TypeKeys.TRACK
}

export function track(): Track {
  return {
    type: TypeKeys.TRACK,
  }
}

// ---------------------------------------------------------------------------

export type OPENAPI_FETCH = {
  type: TypeKeys.OPENAPI_FETCH
  meta: { deferred: Deferred }
}

export function OPENAPI_FETCH(deferred: Deferred): OPENAPI_FETCH {
  return {
    type: TypeKeys.OPENAPI_FETCH,
    meta: { deferred },
  }
}

export type OPENAPI_SET = {
  type: TypeKeys.OPENAPI_SET
  payload: Codecs.ApiSpecResponse
}

export function OPENAPI_SET(payload: OPENAPI_SET['payload']): OPENAPI_SET {
  return {
    type: TypeKeys.OPENAPI_SET,
    payload,
  }
}

export type DISPATCH_API_REQUEST = {
  type: TypeKeys.DISPATCH_API_REQUEST
  meta: { deferred: Deferred }
  payload: {
    data?: Message
    url: string
    verb: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT'
  }
}

/* @deprecated: Strictly for testing */
export function DISPATCH_API_REQUEST(
  payload: DISPATCH_API_REQUEST['payload'],
  deferred: Deferred,
): DISPATCH_API_REQUEST {
  return {
    type: TypeKeys.DISPATCH_API_REQUEST,
    meta: { deferred },
    payload,
  }
}

export type INITIALIZE_APP = {
  type: TypeKeys.INITIALIZE_APP
  meta: { deferred: Deferred }
}

export function INITIALIZE_APP(deferred: Deferred): INITIALIZE_APP {
  return {
    type: TypeKeys.INITIALIZE_APP,
    meta: { deferred },
  }
}
