/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-use-before-define */
import { isNil } from 'ramda'
import * as Catch from 'redux-saga-try-catch'
import { all, call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _ArtifactActions from '@/store/actions/artifact'
import * as HocActions from '@/store/actions/hoc'
import { MiddlewaresIO } from '@/store/store.utils'
import * as CrudQueryUtils from '@/utilities/crud-query'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export function* watchArtifact(io: MiddlewaresIO) {
  yield takeEvery(Store.ArtifactActions.TypeKeys.ARTIFACT_FETCH, Catch.deferredAction(_ARTIFACT_FETCH, io))
  yield takeEvery(
    Store.ArtifactActions.TypeKeys.RECON_ARTIFACTS_FETCH,
    Catch.deferredAction(_RECON_ARTIFACTS_FETCH, io),
  )
  yield takeEvery(
    Store.ArtifactActions.TypeKeys.RECON_ARTIFACTS_TOTALS_FETCH,
    Catch.deferredAction(_RECON_ARTIFACTS_TOTALS_FETCH, io),
  )
  yield takeEvery(
    Store.HocActions.TypeKeys.ARTIFACT_DIRECT_RETRIEVE,
    Catch.deferredAction(_ARTIFACT_DIRECT_RETRIEVE, io),
  )
  yield takeEvery(Store.HocActions.TypeKeys.ARTIFACT_DOWNLOAD, Catch.deferredAction(_ARTIFACT_DOWNLOAD, io))
  yield takeEvery(Store.HocActions.TypeKeys.ARTIFACT_RETRIEVE, Catch.deferredAction(_ARTIFACT_RETRIEVE, io))
}

// ---------------------------------------------------------------------------

export function* _ARTIFACT_FETCH(io: MiddlewaresIO, action: _ArtifactActions.ARTIFACT_FETCH) {
  // @TODO: `payload` should be extracted from `FetchArtifact['payload']`
  //
  // Right now, `FetchArtifact['payload']` is just an index type, so there's
  // no useful type information available without first revising it.
  type MappedEffect = { eff: (...args: any[]) => void; payload: any }

  // Aggregates effects under a key.
  //
  // This will need to be refactored once we'd like to retrieve multiple
  // artifacts per type of artifact, i.e. multiple certs. Since we'll need an
  // array of ids per, this aggregation will need to happen at the action
  // layer.
  //
  // This is just a temporary refactor to support returning an array results
  // under a key. Very likely it's over-engineered.
  const effects: { [index: string]: Array<MappedEffect> } = Object.keys(action.payload).reduce(
    (_effects: { [index: string]: any }, resultKey: string) => {
      const [nextResultKey] = resultKey.split('_uuid')

      _effects[nextResultKey] = isNil(action.payload[resultKey])
        ? (_effects[nextResultKey] || []).concat({ eff: () => [], payload: undefined })
        : (_effects[nextResultKey] || []).concat({
            eff: io.api.artifacts.getArtifactRaw,
            payload: action.payload[resultKey],
          })

      return _effects
    },
    {},
  )

  // Calls the grouped effects in the context of `all`. This was the most
  // straight-forward way I could see, at the moment, to both transform the
  // payload and then execute in parallel.
  //
  // yield all({
  //   aBatchOfEffects: all([call(() => null), call(() => null)]),
  //   moreEffects: all([call(() => null), call(() => null)]),
  // })

  // type _Eff = SagaGenerator<void[], AllEffect<SagaGenerator<void, CallEffect>>>
  type _Eff = any

  const results = Object.keys(effects).reduce((_dispatched: { [index: string]: _Eff }, resultKey: string) => {
    const _effects = effects[resultKey]

    const _eff = all(
      _effects.map((effect) => {
        return call(effect.eff, effect.payload)
      }),
    )

    _dispatched[resultKey] = _eff

    return _dispatched
  }, {})

  return yield* all(results)
}

export function* _ARTIFACT_RETRIEVE(io: MiddlewaresIO, action: HocActions.ARTIFACT_RETRIEVE) {
  const response = yield* call(io.api.artifacts.getArtifactByShasum, action.payload.shasum, action.payload.orgId)

  return response
}

export function* _ARTIFACT_DIRECT_RETRIEVE(io: MiddlewaresIO, action: HocActions.ARTIFACT_DIRECT_RETRIEVE) {
  const response = yield* call(io.api.artifacts.getArtifactRaw, action.payload.shasum)

  return response
}

export function* _ARTIFACT_DOWNLOAD(_io: MiddlewaresIO, action: HocActions.ARTIFACT_DOWNLOAD) {
  if (!isNotNil(action.payload.dataHash)) {
    throw new Error('Missing action.payload.dataHash')
  }

  if (!isNotNil(action.payload.artifactType)) {
    throw new Error('Missing action.payload.artifactType')
  }

  throw new Error('Not implemented')
}

export function* _RECON_ARTIFACTS_FETCH(io: MiddlewaresIO, action: _ArtifactActions.RECON_ARTIFACTS_FETCH) {
  const result = yield* call(
    io.api.recon.getArtifacts,
    CrudQueryUtils.createQuery(CrudQueryUtils.parseQuery(action.payload)),
  )

  yield* put(Store.ArtifactActions.RECON_ARTIFACTS_STORE_UPDATE(result))

  return result
}

export function* _RECON_ARTIFACTS_TOTALS_FETCH(
  io: MiddlewaresIO,
  action: _ArtifactActions.RECON_ARTIFACTS_TOTALS_FETCH,
) {
  const { total } = yield* call(io.api.recon.getArtifacts, `?${action.payload}`)

  const totals = {
    unfilteredTotal: total,
    unaffiliatedTotal: 0,
  }

  yield* put(Store.ArtifactActions.RECON_ARTIFACTS_TOTALS_STORE_UPDATE(totals))

  return {
    total,
    unaffiliatedTotal: 0,
  }
}
