/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from '@/store'
import { UUID } from '@/utilities/codec'

import { makeSelectPagination, makeSelectTotals } from '../utilities/make-selectors'
import { EntityName } from './ip.constants'

// ---------------------------------------------------------------------------

export const selectIpPagination = makeSelectPagination(EntityName)
export const selectIpTotals = makeSelectTotals(EntityName)

export const selectIps = createSelector([(state: AppState) => state.ip], (state) => {
  const orderedEntities = state.result.map((uuid) => state.entities.ip[uuid])

  return orderedEntities
})

export const selectIpById = createSelector(
  [(state: AppState, _props: { id: UUID }) => state.ip.entities, (_state: AppState, props: { id: UUID }) => props.id],
  (entities, uuid) => {
    const entity = entities.ip[uuid]

    return entity
  },
)
