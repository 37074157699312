/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

// ---------------------------------------------------------------------------
import * as ArtifactReducers from './artifact.reducers'
import * as BarReducers from './bar.reducers'
import * as BlueprintReducers from './blueprint.reducers'
import * as CharacteristicRulesReducers from './characteristicrule.reducers'
import * as GlobalHostnameReducers from './global-hostname.reducers'
import * as GlobalIpReducers from './global-ip.reducers'
import * as GlobalNetworkReducers from './global-network.reducers'
import * as GlobalServiceReducers from './global-service.reducers'
import * as PocReducer from './poc.reducers'
import * as ServiceRulesReducers from './servicerule.reducers'
import * as ServiceSuggestionReducers from './suggestion.reducers'
import * as TermReducers from './term.reducers'

// ---------------------------------------------------------------------------

export type State = ReturnType<typeof Reducer>
export const Reducer = combineReducers({
  blueprints: BlueprintReducers.BlueprintsReducer,
  blueprintsPagination: BlueprintReducers.BlueprintsPaginationReducer,
  blueprintsTotals: BlueprintReducers.BlueprintsTotalsReducer,

  bars: BarReducers.BarsReducer,
  barsPagination: BarReducers.BarsPaginationReducer,
  barsTotals: BarReducers.BarsTotalsReducer,

  globalServiceStats: GlobalServiceReducers.GlobalServiceStatsReducer,
  globalServices: GlobalServiceReducers.GlobalServicesReducer,
  globalServicesPagination: GlobalServiceReducers.GlobalServicesPaginationReducer,
  globalServicesTotals: GlobalServiceReducers.GlobalServicesTotalsReducer,

  globalIps: GlobalIpReducers.GlobalIpsReducer,
  globalIpsPagination: GlobalIpReducers.GlobalIpsPaginationReducer,
  globalIpsTotals: GlobalIpReducers.GlobalIpsTotalsReducer,

  globalHostnames: GlobalHostnameReducers.GlobalHostnameReducer,
  globalHostnamesPagination: GlobalHostnameReducers.GlobalHostnamesPaginationReducer,
  globalHostnamesTotals: GlobalHostnameReducers.GlobalHostnamesTotalsReducer,

  globalNetworks: GlobalNetworkReducers.GlobalNetworkReducer,
  globalNetworksPagination: GlobalNetworkReducers.GlobalNetworksPaginationReducer,
  globalNetworksTotals: GlobalNetworkReducers.GlobalNetworksTotalsReducer,

  pocs: PocReducer.PocReducer,
  pocsPagination: PocReducer.PocsPaginationReducer,
  pocsTotals: PocReducer.PocsTotalsReducer,

  terms: TermReducers.TermsReducer,
  termsPagination: TermReducers.TermsPaginationReducer,
  termsTotals: TermReducers.TermsTotalsReducer,

  serviceSuggestions: ServiceSuggestionReducers.ServiceSuggestionsReducer,
  serviceSuggestionsPagination: ServiceSuggestionReducers.ServiceSuggestionsPaginationReducer,
  serviceSuggestionsTotals: ServiceSuggestionReducers.ServiceSuggestionsTotalsReducer,

  serviceRules: ServiceRulesReducers.ServiceRulesReducer,
  serviceRulesPagination: ServiceRulesReducers.ServiceRulesPaginationReducer,
  serviceRulesTotals: ServiceRulesReducers.ServiceRulesTotalsReducer,

  characteristicRules: CharacteristicRulesReducers.CharacteristicRulesReducer,
  characteristicRulesPagination: CharacteristicRulesReducers.CharacteristicRulesPaginationReducer,
  characteristicRulesTotals: CharacteristicRulesReducers.CharacteristicRulesTotalsReducer,

  artifacts: ArtifactReducers.ArtifactsReducer,
  artifactsPagination: ArtifactReducers.ArtifactsPaginationReducer,
  artifactsTotals: ArtifactReducers.ArtifactsTotalsReducer,
})

export const Test = {}
