/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

const _CreatedLastUpdated = t.type({
  author_id: t.string,
  date: DateString,
})

export const _DayTime = t.type({
  day: t.string,
  time: t.string,
})

export const _DateTime = t.type({
  date: t.string,
  time: t.string,
})

export const _WeekSpan = t.type({
  start: _DayTime,
  end: _DayTime,
})

export const _TimeSpan = t.type({
  start: _DateTime,
  end: _DateTime,
})

export type DayOfWeek = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

export type WeekSpan = t.TypeOf<typeof _WeekSpan>
export type TimeSpan = t.TypeOf<typeof _TimeSpan>

export type ExceptionSchedule = WeekSpan[] | TimeSpan[] | null[]

export const _ExceptionPolicyAttributes = {
  name: t.string,
  description: t.string,
  activities_count: t.number,
  enabled: t.boolean,
  created: _CreatedLastUpdated,
  updated: _CreatedLastUpdated,
  activity_class: t.array(t.string),
  timezone: t.string,
  perspectives: t.array(t.string),
  week_spans: t.union([t.array(_WeekSpan), t.array(t.null)]),
  time_spans: t.union([t.array(_TimeSpan), t.array(t.null)]),
}

export const ExceptionPolicyAttributes = t.type(_ExceptionPolicyAttributes)

// ----------------------------------------------------------------------------

export const ExceptionPolicy = t.type({
  attributes: ExceptionPolicyAttributes,
  id: t.string, // uuid unique to the exception policy
  type: t.string, // exception_policy
})
export type ExceptionPolicy = t.TypeOf<typeof ExceptionPolicy>

export const ExceptionPolicyNormalized = t.type({
  id: UUID,
  type: t.string,
  // flattened attributes below
  name: t.string,
  description: t.string,
  activities_count: t.number,
  enabled: t.boolean,
  created: _CreatedLastUpdated,
  updated: _CreatedLastUpdated,
  activity_class: t.array(t.string),
  timezone: t.string,
  perspectives: t.array(t.string),
  week_spans: t.union([t.array(_WeekSpan), t.array(t.null)]),
  time_spans: t.union([t.array(_TimeSpan), t.array(t.null)]),
})
export type ExceptionPolicyNormalized = t.TypeOf<typeof ExceptionPolicyNormalized>

export const ExceptionPolicyNormalizedForSummary = t.type({
  id: UUID,
  type: t.string,
  name: t.string,
  description: t.string,
  activities_count: t.number,
  enabled: t.boolean,
  created: _CreatedLastUpdated,
  updated: _CreatedLastUpdated,
  activity_class: t.array(t.string),
  timezone: t.string,
  perspectives: t.array(t.string),
  week_spans: t.union([t.array(_WeekSpan), t.array(t.null)]),
  time_spans: t.union([t.array(_TimeSpan), t.array(t.null)]),
  created_on: DateString,
  created_by: t.string,
  updated_on: DateString,
  updated_by: t.string,
  exception_schedule: t.union([t.array(_WeekSpan), t.array(_TimeSpan), t.array(t.null)]),
})

export type ExceptionPolicyNormalizedForSummary = t.TypeOf<typeof ExceptionPolicyNormalizedForSummary>
