/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { makeFetchMany, makeFetchManyForEntity, makeFetchSingle, makeFetchTotals } from '../utilities/make-actions'
import { EntityName } from './ip.constants'

// ---------------------------------------------------------------------------

export const fetchIp = makeFetchSingle(EntityName)
export const fetchIpTotals = makeFetchTotals(EntityName)
export const fetchIps = makeFetchMany(EntityName)
export const fetchIpsForHostname = makeFetchManyForEntity(EntityName, 'hostname')
export const fetchIpsForNetwork = makeFetchManyForEntity(EntityName, 'network')
export const fetchIpsForService = makeFetchManyForEntity(EntityName, 'service')
