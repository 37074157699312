/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isEmpty, isNil } from 'lodash/fp'

import { DecodedSession } from '@/codecs'

// ---------------------------------------------------------------------------

export enum PageKeys {
  acceptToS = 'accept-tos',
  addOTP = 'add-otp',
  addPassword = 'add-password',
  confirm = 'confirm',
  redirect = 'redirect',
  submitOTP = 'submit-otp',
  submitPassword = 'submit-password',
}

export type Page =
  | PageKeys.acceptToS
  | PageKeys.addOTP
  | PageKeys.addPassword
  | PageKeys.confirm
  | PageKeys.redirect
  | PageKeys.submitOTP
  | PageKeys.submitPassword

export function nextPage(authorization: DecodedSession) {
  // If there is no token in memory, ask the user to log in.
  if (isNil(authorization) || isEmpty(authorization) || isNil(authorization.perms)) {
    return PageKeys.submitPassword
  }

  // OTP has already been offered, and the JWT cannot be used to retrieve
  // another one. In this case, we need to re-log-in and then fetch an OTP uri
  // which we can encode as a QR.
  if (authorization.perms.includes('confirm-otp-offer')) {
    return PageKeys.addOTP
  }

  // A new OTP uri can be offered, so we'll flip to the `new-otp` screen,
  // fetch it, and then render it as a QR code.
  if (authorization.perms.includes('new-otp-token')) {
    return PageKeys.addOTP
  }

  // If this is the *only* permission the current JWT has, then the user has
  // already set up OTP and needs to enter it to complete a fully logged in
  // session.
  if (authorization.perms.includes('need-otp')) {
    return PageKeys.submitOTP
  }

  // If this is the *only* permission the current JWT has, then the user needs
  // to accept the current version of ToS.
  if (authorization.perms.includes('need-tos')) {
    return PageKeys.acceptToS
  }

  if (authorization.perms.includes('authenticated')) {
    // hitting this redirect
    return PageKeys.redirect
  }

  if (authorization.perms.includes('reset-password')) {
    return PageKeys.confirm
  }

  // Otherwise, better show them the log in screen.
  return PageKeys.submitPassword
}
