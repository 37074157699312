/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EntityPagination, HostnameP as Hostname } from '@/codecs'

// import { UUID } from '@/utilities/codec'
import { EntityName, initialState, State } from './hostname.constants'
import { normalizeHostnames } from './hostname.schema'

// ---------------------------------------------------------------------------

export const hostnameSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    // base
    // ------------------------------------------------------------------------

    replaceHostnames: (state, action: PayloadAction<ReturnType<typeof normalizeHostnames>>) => {
      state.result = action.payload.result

      state.entities = {
        hostname: {
          ...state.entities.hostname,
          ...action.payload.entities.hostname,
        },
      }
    },

    replaceHostnameTotals: (state, action: PayloadAction<State['totals']>) => {
      state.totals = action.payload
    },

    replaceHostnamePagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },

    upsertHostnames: (state, action: PayloadAction<ReturnType<typeof normalizeHostnames>>) => {
      state.entities = {
        hostname: {
          ...state.entities.hostname,
          ...action.payload.entities.hostname,
        },
      }
    },

    upsertHostname: (state, action: PayloadAction<Hostname>) => {
      const id = action.payload.id

      state.entities.hostname[id] = action.payload
    },

    // extended
    // ------------------------------------------------------------------------

    // replaceHostnamesForEntity: (
    //   state,
    //   action: PayloadAction<{
    //     nextState: ReturnType<typeof normalizeHostnames>
    //     forEntity: {
    //       id: UUID
    //       type: 'ip'
    //     }
    //   }>,
    // ) => {
    //   state.entities = {
    //     hostname: {
    //       ...state.entities.hostname,
    //       ...action.payload.nextState.entities.hostname,
    //     },
    //   }
    //
    //   state.byEntity[action.payload.forEntity.type] = {
    //     ...state.byEntity[action.payload.forEntity.type],
    //     [action.payload.forEntity.id]: action.payload.nextState.result,
    //   }
    // },
  },
})
