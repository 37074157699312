/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { connect } from 'react-redux'

import { AppState } from '@/store'

// ---------------------------------------------------------------------------

type WithPredicateOwnProps = {
  selector: (state: AppState) => boolean
}

const _withPredicate = (state: AppState, ownProps: WithPredicateOwnProps) => {
  return {
    isEnabled: ownProps.selector(state),
  }
}

export const withPredicate = connect(_withPredicate)

export const Test = {
  _withPredicate,
}
