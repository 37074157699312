/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ExhaustiveError, Typography } from '@randori/rootkit'
import classNames from 'classnames'
import { isNil } from 'lodash/fp'
import * as React from 'react'

import * as Codecs from '@/codecs'
import * as EntityUtils from '@/utilities/r-entity'
import { TemptationColor } from '@/utilities/target-temptation'

import * as CellsUtils from '../cells.utils'
import { Empty } from './empty-cell'

// ---------------------------------------------------------------------------

export const getFactorDisplayValues = (value: Codecs.TemptationFactorLevel | null) => {
  /* eslint-disable no-magic-numbers */
  switch (value) {
    case null:
    case 0:
    case 1:
      return {
        label: 'Low',
        color: TemptationColor.grey,
      }
    case 2:
    case 3:
      return {
        label: 'Medium',
        color: TemptationColor.orange,
      }
    case 4:
    case 5:
      return {
        label: 'High',
        color: TemptationColor.red,
      }

    default:
      throw new ExhaustiveError(value)
  }
}

// ---------------------------------------------------------------------------

type TemptationFactorsProps = {
  entity: Codecs.Entity
  entityType: EntityUtils.EntityType
  factor: Codecs.TemptationFactor
}

export const TemptationFactorCell: React.FC<TemptationFactorsProps> = (props) => {
  const validated = CellsUtils.getRequiredProps(props.entity, Codecs.Temptation, false)

  if (isNil(validated)) {
    return <Empty />
  }

  const factorDisplayValues = getFactorDisplayValues(validated[props.factor])

  const classes = classNames('tt-renderer', factorDisplayValues.color)

  return (
    <Typography variant="caption" addlClasses={classes}>
      {factorDisplayValues.label}
    </Typography>
  )
}
