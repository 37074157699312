/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import { match } from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { fold } from 'fp-ts/Option'
import { Decoder } from 'io-ts'
import { PathReporter } from 'io-ts/PathReporter'

import * as Logger from '@/utilities/logger'
import { RandoriValidationError } from '@/utilities/r-error/validation-error'

import { extractIds, formatValidationError, formatValidationErrors } from './format-validation-errors'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:validation')
// @see: https://github.com/gcanti/io-ts/issues/200

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Validate a value using a {@link Decoder} and throw an error if the validation fails.
 *
 * @param decoder - The decoder to use for validation.
 * @param label - The label to use for the input value. Defaults to the name of the decoder.
 * @param shouldLog - Whether or not to log validation errors. Defaults to `true`.
 * @param perError - Whether or not to log each validation error separately. Defaults to `false`.
 *
 * @returns A function that validates a value using the provided decoder and throws an error if the validation fails.
 */
export const throwValidate =
  <I, A>(decoder: Decoder<I, A>, label = decoder.name, shouldLog = true, perError = false) =>
  (value: I): A => {
    return pipe(
      decoder.decode(value),
      match(
        (errors) => {
          if (shouldLog) {
            // eslint-disable-next-line no-console
            console.trace('RANDORI:validation:')

            const validationErrors = formatValidationErrors(errors)
            const validationIds = extractIds(errors)

            if (validationIds.length > 0) {
              log(validationIds)
            }

            log(validationErrors)

            if (perError) {
              errors.forEach((error) => {
                pipe(
                  formatValidationError(error),
                  fold(
                    () => null,
                    (msg) => log(msg),
                  ),
                )
              })
            }
          }

          const validationError = new RandoriValidationError({
            errors,
            label,

            // @TODO: Attach a label to the decoder
            //
            // Decoder.name is the literal shape of the validator. We can extend
            // our codecs by doing something like `Target._label = 'Target'`,
            // but this breaks types right now. Doable, by I don't want to spend
            // time on it yet.
          })

          throw validationError
        },
        (valid) => valid,
      ),
    )
  }

/**
 * @typeParam I -
 * @typeParam A -
 * @param decoder -
 */
export const pathValidate =
  <I, A>(decoder: Decoder<I, A>) =>
  (value: I) => {
    const result = decoder.decode(value)
    PathReporter.report(result)

    return result
  }

/**
 * Use to assert type information about a value, e.g:
 *
 * const uuid = getRequiredValue(id, UUID) // uuid is now of type UUID
 *
 * @param value - value to test
 * @param validator - validator (codec) to use for test
 * @param log - if validation fails, should we log it with Logger.error
 *
 * @returns value: Valid or null if validation fails
 */
export function getRequiredValue<Valid>(value: unknown, validator: Decoder<unknown, Valid>, log = true) {
  try {
    return throwValidate(validator, validator.name, log)(value)
  } catch (e) {
    if (log) {
      Logger.error(e)
    }

    return null
  }
}

/**
 * Similar to getRequiredValue, but throws an exception, instead of returning null.
 *
 * This really should be used where there are already guarantees about the value, and we really just want to simply
 * refine the type.
 *
 * @param value - value to test
 * @param validator - validator (codec) to use for test
 * @param log - if validation fails, should we log it with Logger.error
 *
 * @returns value: Valid
 */
export function getRequiredValueP<Valid>(value: unknown, validator: Decoder<unknown, Valid>, log = true) {
  try {
    return throwValidate(validator, validator.name, log)(value)
  } catch (e) {
    if (log) {
      Logger.error(e)
    }

    if (e instanceof Error) {
      throw e
    } else {
      throw new Error('getRequiredValueP')
    }
  }
}

/**
 * Alias for getRequiredValueP
 *
 * @see getRequiredValueP
 */
export const get = getRequiredValueP

/**
 * Alias for getRequiredValue
 *
 * @see getRequiredValue
 */
export const getSafe = getRequiredValue
