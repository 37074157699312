/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  COMMENT_CREATE = 'COMMENT_CREATE',
  COMMENT_FETCH_PAGE = 'COMMENT_FETCH_PAGE',
  COMMENT_EDIT = 'COMMENT_EDIT',
  COMMENT_DELETE = 'COMMENT_DELETE',
}

export type ActionTypes = COMMENT_CREATE | COMMENT_FETCH_PAGE | COMMENT_EDIT | COMMENT_DELETE

export type COMMENT_CREATE = {
  type: TypeKeys.COMMENT_CREATE
  meta: { deferred: Deferred }
  payload: {
    entityId: string
    data: Codecs.PostCommentPayload['data']
  }
}

export const COMMENT_CREATE = (payload: COMMENT_CREATE['payload'], deferred: Deferred): COMMENT_CREATE => {
  return {
    type: TypeKeys.COMMENT_CREATE,
    meta: { deferred },
    payload,
  } as COMMENT_CREATE
}

export type COMMENT_FETCH_PAGE = {
  type: TypeKeys.COMMENT_FETCH_PAGE
  meta: { deferred: Deferred }
  payload: {
    entityId: string
    offset: number
    limit: number
  }
}

export const COMMENT_FETCH_PAGE = (
  entityId: string,
  offset: number,
  limit: number,
  deferred: Deferred,
): COMMENT_FETCH_PAGE => {
  return {
    type: TypeKeys.COMMENT_FETCH_PAGE,
    meta: { deferred },
    payload: {
      entityId,
      offset,
      limit,
    },
  }
}

export type COMMENT_EDIT = {
  type: TypeKeys.COMMENT_EDIT
  meta: { deferred: Deferred }
  payload: {
    entityId: string
    commentId: string
    data: Codecs.PostCommentPayload['data']
  }
}

export const COMMENT_EDIT = (payload: COMMENT_EDIT['payload'], deferred: Deferred): COMMENT_EDIT => {
  return {
    type: TypeKeys.COMMENT_EDIT,
    meta: { deferred },
    payload,
  } as COMMENT_EDIT
}

export type COMMENT_DELETE = {
  type: TypeKeys.COMMENT_DELETE
  meta: { deferred: Deferred }
  payload: {
    entityId: string
    commentId: string
  }
}

export const COMMENT_DELETE = (payload: COMMENT_DELETE['payload'], deferred: Deferred): COMMENT_DELETE => {
  return {
    type: TypeKeys.COMMENT_DELETE,
    meta: { deferred },
    payload,
  } as COMMENT_DELETE
}
