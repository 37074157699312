/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const RegexValidationObject = t.type({
  kind: t.literal('regex'),
  value: t.string,
  error_text: t.string,
})

export const ListValidationObject = t.type({
  kind: t.literal('list'),
  value: t.array(t.unknown), // There are currently no defined constraints here.
  error_text: t.string,
})

export const RangeValidationObject = t.type({
  kind: t.literal('list'),
  value: t.tuple([t.number, t.number]),
  error_text: t.string,
})

export const ValidationObject = t.union([RegexValidationObject, ListValidationObject, RangeValidationObject])

export type ValidationObject = t.TypeOf<typeof ValidationObject>
