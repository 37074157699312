/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { without } from 'ramda'

import { EntityPagination } from '@/codecs'
import { ExceptionPolicy } from '@/codecs/exception-policy.codecs'

import { EntityName, ExceptionPolicyState, initialState } from './exception-policy.constants'
import { normalizeExceptionPolicies } from './exception-policy.schema'
import { normalizeExceptionPolicy } from './exception-policy.selectors'

// ---------------------------------------------------------------------------

export const exceptionPolicySlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    upsertExceptionPolicy: (state, action: PayloadAction<ExceptionPolicy>) => {
      const id = action.payload.id

      state.entities.exception_policy[id] = action.payload
    },

    deleteExceptionPolicy: (state, action: PayloadAction<ReturnType<typeof normalizeExceptionPolicy>>) => {
      const remainingEntities = { ...state.entities.exception_policy }
      delete remainingEntities[action.payload.id]

      state.entities.exception_policy = remainingEntities
      state.result = without([action.payload.id], state.result)
      state.totals = { ...state.totals, unfiltered: state.totals.unfiltered - 1 }
      state.pagination = { ...state.pagination, total: state.pagination.total - 1, count: state.pagination.count - 1 }
    },

    replaceExceptionPolicies: (state, action: PayloadAction<ReturnType<typeof normalizeExceptionPolicies>>) => {
      state.result = action.payload.result
      state.entities = {
        exception_policy: {
          ...state.entities.exception_policy,
          ...action.payload.entities.exception_policy,
        },
      }
    },

    replaceExceptionPolicyTotals: (state, action: PayloadAction<ExceptionPolicyState['totals']>) => {
      state.totals = action.payload
    },

    replaceExceptionPolicyPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },
  },
})
