/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { CPE } from '../cpe.codecs'
import { LensId, Perspective, PerspectiveName } from '../perspective.codecs'
import { TechCategoryList } from '../tech-category.codecs'
import { withTemptation } from '../temptation.codecs'
import { TextSource } from '../text-source.codecs'

// ---------------------------------------------------------------------------

export type Service = t.TypeOf<typeof Service>
export const Service = withTemptation(
  t.type({
    attack_note: t.union([t.string, t.null]),
    cpe: CodecUtils.nullable(CPE),
    description: t.union([t.string, t.null]),
    description_source: TextSource,
    // @TODO: EN-13080 swap undefined with null after materialization updates
    end_of_life: t.union([t.boolean, t.undefined]),
    first_seen: t.union([t.string, t.null]), // @TODO: iso string
    id: t.string,
    instance_count: t.union([t.number, t.null]),
    ip_count: t.union([t.number, t.null]),
    last_seen: t.union([t.string, t.null]), // @TODO: iso string
    lens_id: LensId,
    name: t.union([t.string, t.null]),
    org_id: t.union([t.string, t.null]),
    perspective: Perspective,
    perspective_name: PerspectiveName,
    randori_notes: t.union([t.string, t.null]),
    reference: t.union([t.string, t.null]),
    service_id: t.union([t.string, t.null]),
    tech_category: CodecUtils.nullable(TechCategoryList),
    vendor: t.union([t.string, t.null]),
    version: t.union([t.string, t.null]),
  }),
)
