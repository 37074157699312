/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const NetworksReducer = (state = Schemas.Recon.NormalizeNetworks([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.NETWORKS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeNetworks(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          network: {
            ...state.entities.network,
            ...entities.network,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.NETWORK_STORE_UPDATE: {
      return {
        result: state.result,
        entities: {
          ...state.entities,
          network: {
            ...state.entities.network,
            [action.payload.id]: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}

const NetworksTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const NetworksTotalsReducer = (state = NetworksTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.NETWORK_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const NetworksPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const NetworksPaginationReducer = (state = NetworksPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.NETWORKS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
