/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

const ActivityConsumption = t.type({
  attack_targeted: t.number,
  contractual_consumption: t.number,
  external: t.number,
  internal: t.number,
})

const ActivityHistory = t.type({
  license_activity_count: t.union([t.number, t.null]),
  pack_activity_count: t.union([t.number, t.null]),
  total_activity_count: t.union([t.number, t.null]),
})

const AvailableActivityHistory = t.record(t.string, ActivityHistory)

const ActivityPack = t.type({
  included_activities: t.number,
  included_sources: t.number,
  source_count_divisor: t.number,
})

const License = t.type({
  activities: t.number,
  name: t.string,
  sources: t.number,
})

const CurrentEntitlements = t.type({
  activities: t.union([t.number, t.null]),
  activity_pack_count: t.number,
  sources: t.number,
})

export const Entitlement = t.type({
  activity_pack: ActivityPack,
  administrative_sources: t.number,
  license: License,
  available_activity_history: t.union([AvailableActivityHistory, t.null]), // object of objects keyed by string
  current_entitlements: CurrentEntitlements,
  org_id: t.string,
})
export type Entitlement = t.TypeOf<typeof Entitlement>

export const Usage = t.type({
  activity_consumption: t.union([t.record(t.string, ActivityConsumption), t.null]),
  org_id: t.string,
})
export type Usage = t.TypeOf<typeof Usage>
