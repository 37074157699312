/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as CrudQueryUtils from '@/utilities/crud-query'
import { ExhaustiveError } from '@/utilities/r-error'

// ---------------------------------------------------------------------------

// max is the max of the range [0-max], each boundary(low,med,high) . is a percenatge of the max
export interface PriorityBoundaries {
  low: number
  medium: number
  high: number
  max: number
}

export type PriorityValues = {
  high: boolean
  low: boolean
  medium: boolean
}

export function priorityToDisplay(priority: number, boundaries: PriorityBoundaries) {
  const percentage = priority / boundaries.max
  if (percentage <= boundaries.low) {
    return 'low'
  } else if (percentage <= boundaries.medium) {
    return 'medium'
  } else {
    return 'high'
  }
}

export function priorityToPillModifier(priority: number, boundaries: PriorityBoundaries) {
  const percentage = priority / boundaries.max
  if (percentage <= boundaries.low) {
    return 'grey'
  } else if (percentage <= boundaries.medium) {
    return 'orange'
  } else {
    return 'red'
  }
}

export function priorityDisplayToModifier(priorityDisplay: keyof PriorityValues) {
  if (priorityDisplay == 'low') {
    return 'grey'
  } else if (priorityDisplay == 'medium') {
    return 'orange'
  } else {
    return 'red'
  }
}

export const createLowPriorityRule = (boundaries: PriorityBoundaries) => {
  return {
    label: 'low',
    condition: 'AND',
    rules: [
      {
        id: 'table.priority_score',
        field: 'table.priority_score',
        type: 'integer',
        input: 'number',
        operator: 'greater_or_equal',
        value: 0,
      },
      {
        id: 'table.priority_score',
        field: 'table.priority_score',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal',
        value: boundaries.low * boundaries.max,
      },
    ],
  }
}

export const createMediumPriorityRule = (boundaries: PriorityBoundaries) => {
  return {
    label: 'medium',
    condition: 'AND',
    rules: [
      {
        id: 'table.priority_score',
        field: 'table.priority_score',
        type: 'integer',
        input: 'number',
        operator: 'greater',
        value: boundaries.low * boundaries.max,
      },
      {
        id: 'table.priority_score',
        field: 'table.priority_score',
        type: 'integer',
        input: 'number',
        operator: 'less_or_equal',
        value: boundaries.medium * boundaries.max,
      },
    ],
  }
}

export const createHighPriorityRule = (boundaries: PriorityBoundaries) => {
  return {
    label: 'high',
    condition: 'AND',
    rules: [
      {
        id: 'table.priority_score',
        field: 'table.priority_score',
        type: 'integer',
        input: 'number',
        operator: 'greater',
        value: boundaries.medium * boundaries.max,
      },
    ],
  }
}

export const getPriorityRuleBuilder = (which: 'high' | 'medium' | 'low') => {
  switch (which) {
    case 'high':
      return createHighPriorityRule
    case 'medium':
      return createMediumPriorityRule
    case 'low':
      return createLowPriorityRule

    default:
      throw new ExhaustiveError(which)
  }
}

export const createPriorityFilter = (
  selected: PriorityValues,
  boundaries: PriorityBoundaries,
): CrudQueryUtils.CrudRuleGroup => {
  const high = createHighPriorityRule(boundaries)
  const low = createLowPriorityRule(boundaries)
  const medium = createMediumPriorityRule(boundaries)

  const rules = [selected.low ? low : null, selected.medium ? medium : null, selected.high ? high : null].filter(
    Boolean,
  ) as unknown[]

  return {
    condition: 'OR',
    rules: rules as CrudQueryUtils.CrudRule[],
    ui_id: 'priority_score',
  }
}
