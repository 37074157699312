/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

const BlueprintActionParameter = t.type({
  action_definition_id: t.string,
  cmd: t.array(t.string),
})

const BlueprintProcessParameters = t.type({
  attack_work: t.union([t.string, t.undefined, t.null]),
  cmdlist: t.union([t.array(BlueprintActionParameter), t.undefined, t.null]),
  hold_for_approval: t.union([t.boolean, t.undefined, t.null]),
})

const BlueprintTriggerData = t.record(t.string, t.any)

export const Blueprint = t.type({
  id: t.string,
  approved: t.union([t.string, t.null]), // @TODO: iso string
  approver: t.union([t.string, t.null]),
  bart_id: t.union([t.string, t.null]),
  created: t.union([t.string, t.null]), // @TODO: iso string
  creator: t.union([t.string, t.null]),
  name: t.string,
  parameters: t.union([BlueprintProcessParameters, t.null]),
  runbook_definition_id: t.union([t.string, t.null]),
  trigger_data: t.union([BlueprintTriggerData, t.null]),
  trigger_type: t.string, // @TODO: create trigger_type enum
  type: t.string, // @TODO: create type enum
  updated: t.union([t.string, t.null]), // @TODO: iso string
  updater: t.union([t.string, t.null]),
})

export type Blueprint = t.TypeOf<typeof Blueprint>

export const BlueprintsResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Blueprint),
})
export type BlueprintsResponse = t.TypeOf<typeof BlueprintsResponse>

export const BlueprintPatchPayload = t.type({
  data: t.type({
    approver: t.string,
    bart_id: t.null,
  }),
})
export type BlueprintPatchPayload = t.TypeOf<typeof BlueprintPatchPayload>

export const BlueprintPatchResponse = t.type({
  data: Blueprint,
})
export type BlueprintPatchResponse = t.TypeOf<typeof BlueprintPatchResponse>
