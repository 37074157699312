/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

// https://swagger.io/specification/
// this is extensible, should not be treated as a complete member list
export const OASFormat = t.keyof({
  binary: null,
  byte: null,
  date: null,
  'date-time': null,
  double: null,
  float: null,
  int32: null,
  int64: null,
  password: null,
  uuid: null,
})
export type OASFormat = t.TypeOf<typeof OASFormat>

export const OASType = t.keyof({
  array: null,
  boolean: null,
  integer: null,
  number: null,
  object: null,
  string: null,
})
export type OASType = t.TypeOf<typeof OASType>

export const SchemaProperty = t.interface({
  enum: t.union([t.array(t.string), t.undefined]),
  format: t.union([OASFormat, t.undefined]),
  items: t.union([t.UnknownRecord, t.undefined]),
  type: OASType,
})

export type SchemaProperty = t.TypeOf<typeof SchemaProperty>

export const ApiComponent = t.interface({
  components: t.interface({
    securitySchemes: t.UnknownRecord,
    schemas: t.UnknownRecord,

    // schemas: t.intersection([
    //   t.UnknownRecord,
    //   t.interface({
    //     ip: t.interface({
    //       properties: t.record(t.string, SchemaProperty),
    //       required: t.array(t.string),
    //       type: OASType,
    //     }),
    //   }),
    // ]),
  }),
})

export const ApiSpecResponse = t.intersection([t.UnknownRecord, ApiComponent])

export type ApiSpecResponse = t.TypeOf<typeof ApiSpecResponse>
