/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _CommentActions from '@/store/actions/comment/comment.actions'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* _COMMENT_CREATE(io: MiddlewaresIO, action: _CommentActions.COMMENT_CREATE) {
  const comment = action.payload.data
  const entityId = action.payload.entityId
  const commentRes = yield* call(io.api.comment.createComment, entityId, {
    data: comment,
  })

  return commentRes
}

export function* _COMMENT_FETCH_PAGE(io: MiddlewaresIO, action: _CommentActions.COMMENT_FETCH_PAGE) {
  const commentRes = yield* call(
    io.api.comment.fetchCommentPage,
    action.payload.entityId,
    action.payload.offset,
    action.payload.limit,
  )

  return commentRes
}

export function* _COMMENT_EDIT(io: MiddlewaresIO, action: _CommentActions.COMMENT_EDIT) {
  const comment = action.payload.data
  const entityId = action.payload.entityId
  const commentId = action.payload.commentId
  const commentRes = yield* call(io.api.comment.editComment, entityId, commentId, {
    data: comment,
  })

  return commentRes
}

export function* _COMMENT_DELETE(io: MiddlewaresIO, action: _CommentActions.COMMENT_DELETE) {
  const entityId = action.payload.entityId
  const commentId = action.payload.commentId
  const commentRes = yield* call(io.api.comment.deleteComment, entityId, commentId)

  return commentRes
}

export function* watchComment(io: MiddlewaresIO) {
  yield takeEvery(Store.CommentActions.TypeKeys.COMMENT_CREATE, Catch.deferredAction(_COMMENT_CREATE, io))
  yield takeEvery(Store.CommentActions.TypeKeys.COMMENT_FETCH_PAGE, Catch.deferredAction(_COMMENT_FETCH_PAGE, io))
  yield takeEvery(Store.CommentActions.TypeKeys.COMMENT_EDIT, Catch.deferredAction(_COMMENT_EDIT, io))
  yield takeEvery(Store.CommentActions.TypeKeys.COMMENT_DELETE, Catch.deferredAction(_COMMENT_DELETE, io))
}
