/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullable, UUID } from '@/utilities/codec'

import { PerspectiveType } from './perspective-type'

// ---------------------------------------------------------------------------

export type PerspectiveV2 = t.TypeOf<typeof PerspectiveV2>
export const PerspectiveV2 = t.type({
  createTime: nullable(DateString),
  id: UUID,
  name: t.string,
  perspectiveType: PerspectiveType,
  properties: t.union([t.record(t.string, t.string), t.null]),
  updateTime: nullable(DateString),
})

export type PerspectiveV2Normalized = t.TypeOf<typeof PerspectiveV2Normalized>
export const PerspectiveV2Normalized = t.type({
  createTime: nullable(DateString),
  id: UUID,
  name: t.string,
  perspectiveType: PerspectiveType,
  updateTime: nullable(DateString),
})
