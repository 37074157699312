/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Logger from '@/utilities/logger'
import { RandoriApiError } from '@/utilities/r-error'

import { handleExtractP } from './handle-extract-p'
import { tryRenewalP } from './try-renewal-p'

// ---------------------------------------------------------------------------

/**
 * Handle successful fetch requests
 *
 * @remarks
 * window.fetch() will only throw due to network issues. That means a 500 is
 * actually "successful", so we need to parse the body of the response and rethrow.
 *
 * @param response - Response to inspect
 *
 * @returns the Response body, setting Response bodyRead to true
 */
export const handleResponse =
  (rootUrl: string) =>
  async (response: Response, renew = tryRenewalP) => {
    // If we fail for 401, we want to attempt to renew our cookie
    if (response.status === 401) {
      renew(rootUrl)?.catch(Logger.error)
    }

    // Read the body of the response, despite whether or not it's okeay. This is
    // important to extract a ref and reason from a "normal" API error
    const body: unknown = await handleExtractP(response)

    // We should only create a RandoriApiError when handleExtractP succeeds.
    // We need to body of the response in order to get a "reason" and "ref",
    // which are Randori specific idioms with our current platform API.
    if (!response.ok) {
      throw makeApiError(response.status, body)
    }

    return body
  }

/**
 * @param status - HTTP status code
 * @param body - the parsed response body, necessarily "any"
 *
 * @returns RandoriApiError
 */
export const makeApiError = (status: number, body: any) => {
  const reason: string = body?.reason ?? 'Unknown RandoriApiError'
  const ref: string = body?.ref ?? 'none'

  return new RandoriApiError({ reason, ref }, status)
}
