/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil, isNotNilOrEmpty } from '@randori/rootkit'
import { head } from 'lodash/fp'
import * as Catch from 'redux-saga-try-catch'
import { all, call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Codecs from '@/codecs'
import { getEntityFromColumnOrderPreferenceName } from '@/components/edit-table-field-modal/edit-table-field.utils'
import * as EntityDetailUtils from '@/pages/entity-detail/entity-detail.utils'
import * as Store from '@/store'
import { PreferencesActions } from '@/store'
import { SetPreference } from '@/store/actions/preferences/preferences.actions'
import { EntityDetailNavId } from '@/store/reducers/ui/entity-detail.reducers'
import { Column } from '@/store/reducers/ui/entity-table.reducers'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchPreferences(io: MiddlewaresIO) {
  yield takeEvery(PreferencesActions.TypeKeys.PREFERENCES_GET, Catch.deferredAction(_PREFERENCES_GET, io))
  yield takeEvery(PreferencesActions.TypeKeys.PREFERENCES_SET, Catch.deferredAction(_PREFERENCES_SET, io))
}

// ---------------------------------------------------------------------------

type ActuallyRequired<T> = { [P in keyof T]-?: NonNullable<T[P]> }

const isValidPreference = (preference: Codecs.Preference): preference is ActuallyRequired<Codecs.Preference> => {
  return isNotNil(preference.preference)
}

const dispatchColumnUpdate = (preference: ActuallyRequired<Codecs.Preference>) => {
  const preferenceName = preference.preference
  const columns = preference.value as Column[]
  const tableId = getEntityFromColumnOrderPreferenceName(preferenceName)

  return [
    put(
      Store.UIActions.UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS({
        tableId,
        columns,
      }),
    ),
  ]
}

const dispatchNavUpdate = (preference: ActuallyRequired<Codecs.Preference>) => {
  const preferenceName = preference.preference
  const entityType = EntityDetailUtils.getEntityFromNavOrderPreferenceName(preferenceName)
  const values = preference.value as EntityDetailNavId[]

  return [
    put(
      Store.UIActions.UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES({
        entityType,
        navItemIds: values,
      }),
    ),
    put(
      Store.UIActions.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE({
        entityType,
        activeItems: {
          parentId: head(values) as EntityDetailNavId,
          childId: null,
        },
      }),
    ),
  ]
}

export function* _PREFERENCES_GET(io: MiddlewaresIO) {
  const preferencesResponse = yield* call(io.api.preferences.getPreferences)

  yield* put(PreferencesActions.updatePreferences(preferencesResponse.preferences))

  const navSuffix = '_details_nav_order'
  const columnSuffix = '_column_order'

  const updateNavEffects = preferencesResponse.preferences
    .filter(isValidPreference)
    .map((preference) => {
      const preferenceName = preference.preference

      const _navMatch = new RegExp(navSuffix)
      const isNavMatch = isNotNilOrEmpty(preferenceName.match(_navMatch))

      const _columnMatch = new RegExp(columnSuffix)
      const isColumnMatch = isNotNilOrEmpty(preferenceName.match(_columnMatch))

      if (isNavMatch) {
        return dispatchNavUpdate(preference)
      } else if (isColumnMatch) {
        return dispatchColumnUpdate(preference)
      } else {
        return []
      }
    })
    .flat(1)

  yield* all(updateNavEffects)

  return preferencesResponse
}

export function* _PREFERENCES_SET(io: MiddlewaresIO, action: SetPreference) {
  const prefs = yield* call(io.api.preferences.setPreference, action.payload)

  yield* call(_PREFERENCES_GET, io)

  return prefs
}
