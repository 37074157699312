/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

import { TypeKeys } from './recon.actions.typekeys'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export type DETECTIONS_AUTHORIZE = {
  type: TypeKeys.DETECTIONS_AUTHORIZE
  meta: { deferred: Deferred }
  payload: Codecs.AuthorizeDetectionsPayload
}

export function DETECTIONS_AUTHORIZE(
  payload: DETECTIONS_AUTHORIZE['payload'],
  deferred: Deferred,
): DETECTIONS_AUTHORIZE {
  return {
    type: TypeKeys.DETECTIONS_AUTHORIZE,
    meta: { deferred },
    payload,
  }
}
