/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { normalize, schema } from 'normalizr'
import { propOr } from 'ramda'

import * as Codecs from '@/codecs'

// bars
// ---------------------------------------------------------------------------

export const Bar = new schema.Entity('bar')
export const Bars = [Bar]

export function NormalizeBars(bars: Codecs.Bar[]) {
  type _Bars = {
    bar: {
      [index: string]: Codecs.Bar
    }
  }

  type NormalizedBars = {
    entities: _Bars
    result: string[]
  }

  const { result, entities } = normalize(bars, Bars)

  const _bars = propOr({}, 'bar', entities)

  return {
    result,
    entities: {
      bar: _bars,
    },
  } as NormalizedBars
}

export function NormalizeBarsPatch(barsDirty: Codecs.BarPatchResponse[]) {
  const bars = barsDirty.map((bar) => bar.data)

  type _Bars = {
    bar: {
      [index: string]: Codecs.Bar
    }
  }

  type NormalizedBars = {
    entities: _Bars
    result: string[]
  }

  const { result, entities } = normalize(bars, Bars)

  const _bars = propOr({}, 'bar', entities)

  return {
    result,
    entities: {
      bar: _bars,
    },
  } as NormalizedBars
}

// blueprints
// ---------------------------------------------------------------------------

export const Blueprint = new schema.Entity('blueprint')
export const Blueprints = [Blueprint]

export function NormalizeBlueprints(blueprints: Codecs.Blueprint[]) {
  type _Blueprints = {
    blueprint: {
      [index: string]: Codecs.Blueprint
    }
  }

  type NormalizedBlueprints = {
    entities: _Blueprints
    result: string[]
  }

  const { result, entities } = normalize(blueprints, Blueprints)

  const _blueprints = propOr({}, 'blueprint', entities)

  return {
    result,
    entities: {
      blueprint: _blueprints,
    },
  } as NormalizedBlueprints
}

export function NormalizeBlueprint(blueprint: Codecs.Blueprint) {
  type _Blueprints = {
    blueprint: {
      [index: string]: Codecs.Blueprint
    }
  }

  type NormalizedBlueprints = {
    entities: _Blueprints
    result: string[]
  }

  const { result, entities } = normalize(blueprint, Blueprint)

  const _blueprints = propOr({}, 'blueprint', entities)

  return {
    result,
    entities: {
      blueprint: _blueprints,
    },
  } as NormalizedBlueprints
}

// global services
// ---------------------------------------------------------------------------

export const GlobalService = new schema.Entity('globalService')
export const GlobalServices = [GlobalService]

export function NormalizeGlobalServices(globalServices: Codecs.GlobalServiceWithStats[]) {
  type _GlobalServices = {
    globalService: {
      [index: string]: Codecs.GlobalServiceWithStats
    }
  }

  type NormalizedGlobalServices = {
    entities: _GlobalServices
    result: string[]
  }

  const { result, entities } = normalize(globalServices, GlobalServices)

  const _globalServices = propOr({}, 'globalService', entities)

  return {
    result,
    entities: {
      globalService: _globalServices,
    },
  } as NormalizedGlobalServices
}

// global ips
// ---------------------------------------------------------------------------

export const GlobalIp = new schema.Entity('globalIps')
export const GlobalIps = [GlobalIp]

export function NormalizeGlobalIps(globalIps: Codecs.GlobalIp[]) {
  type _GlobalIps = {
    globalIp: {
      [index: string]: Codecs.GlobalIp
    }
  }

  type NormalizedGlobalIps = {
    entities: _GlobalIps
    result: string[]
  }

  const { result, entities } = normalize(globalIps, GlobalIps)

  const _globalIps = propOr({}, 'globalIps', entities)

  return {
    result,
    entities: {
      globalIp: _globalIps,
    },
  } as NormalizedGlobalIps
}

// global hostnames
// ---------------------------------------------------------------------------

export const GlobalHostname = new schema.Entity('globalHostnames')
export const GlobalHostnames = [GlobalHostname]

export function NormalizeGlobalHostnames(globalHostnames: Codecs.GlobalHostname[]) {
  type _GlobalHostnames = {
    globalHostname: {
      [index: string]: Codecs.GlobalHostname
    }
  }

  type NormalizedGlobalHostnames = {
    entities: _GlobalHostnames
    result: string[]
  }

  const { result, entities } = normalize(globalHostnames, GlobalHostnames)

  const _globalHostnames = propOr({}, 'globalHostnames', entities)

  return {
    result,
    entities: {
      globalHostname: _globalHostnames,
    },
  } as NormalizedGlobalHostnames
}

// pocs
// ---------------------------------------------------------------------------

export const Poc = new schema.Entity('pocs')
export const Pocs = [Poc]

export function NormalizePocs(pocs: Codecs.Poc[]) {
  type _Pocs = {
    poc: {
      [index: string]: Codecs.Poc
    }
  }

  type NormalizedPocs = {
    entities: _Pocs
    result: string[]
  }

  const { result, entities } = normalize(pocs, Pocs)

  const _pocs = propOr({}, 'pocs', entities)

  return {
    result,
    entities: {
      poc: _pocs,
    },
  } as NormalizedPocs
}

// global networks
// ---------------------------------------------------------------------------

export const GlobalNetwork = new schema.Entity('globalNetworks')
export const GlobalNetworks = [GlobalNetwork]

export function NormalizeGlobalNetworks(globalNetworks: Codecs.GlobalNetwork[]) {
  type _GlobalNetworks = {
    globalNetwork: {
      [index: string]: Codecs.GlobalNetwork
    }
  }

  type NormalizedGlobalNetworks = {
    entities: _GlobalNetworks
    result: string[]
  }

  const { result, entities } = normalize(globalNetworks, GlobalNetworks)

  const _globalNetworks = propOr({}, 'globalNetworks', entities)

  return {
    result,
    entities: {
      globalNetwork: _globalNetworks,
    },
  } as NormalizedGlobalNetworks
}

// org terms
// ---------------------------------------------------------------------------

export const Term = new schema.Entity('terms')
export const Terms = [Term]

export function NormalizeTerms(terms: Codecs.Term[]) {
  type _Terms = {
    term: {
      [index: string]: Codecs.Term
    }
  }

  type NormalizedTerms = {
    entities: _Terms
    result: string[]
  }

  const { result, entities } = normalize(terms, Terms)

  const _terms = propOr({}, 'terms', entities)

  return {
    result,
    entities: {
      term: _terms,
    },
  } as NormalizedTerms
}

// suggestions
// ---------------------------------------------------------------------------

export const ServiceSuggestion = new schema.Entity('serviceSuggestion')
export const ServiceSuggestions = [ServiceSuggestion]

export function NormalizeServiceSuggestions(serviceSuggestions: Codecs.ServiceSuggestionWithStats[]) {
  type _ServiceSuggestions = {
    serviceSuggestion: {
      [index: string]: Codecs.ServiceSuggestionWithStats
    }
  }

  type NormalizedServiceSuggestions = {
    entities: _ServiceSuggestions
    result: string[]
  }

  const { result, entities } = normalize(serviceSuggestions, ServiceSuggestions)

  const _serviceSuggestion = propOr({}, 'serviceSuggestion', entities)

  return {
    result,
    entities: {
      serviceSuggestion: _serviceSuggestion,
    },
  } as NormalizedServiceSuggestions
}

// service rules
// ---------------------------------------------------------------------------

export const ServiceRule = new schema.Entity('serviceRule')
export const ServiceRules = [ServiceRule]

export function NormalizeServiceRules(serviceRules: Codecs.ServiceRule[]) {
  type _ServiceRules = {
    serviceRule: {
      [index: string]: Codecs.ServiceRuleWithStats
    }
  }

  type NormalizedServiceRules = {
    entities: _ServiceRules
    result: string[]
  }

  const { result, entities } = normalize(serviceRules, ServiceRules)

  const _serviceRules = propOr({}, 'serviceRule', entities)

  return {
    result,
    entities: {
      serviceRule: _serviceRules,
    },
  } as NormalizedServiceRules
}

export const ServiceRuleMetadata = new schema.Entity('serviceRuleMetadata')
export const ServiceRulesMetadata = [ServiceRuleMetadata]

export function NormalizeServiceRulesMetadata(serviceRulesMetadata: Codecs.ServiceRuleMetadata[]) {
  type _ServiceRulesMetadata = {
    serviceRuleMetadata: {
      [index: string]: Codecs.ServiceRuleMetadata
    }
  }

  type NormalizedServiceRulesMetadata = {
    entities: _ServiceRulesMetadata
    result: string[]
  }

  const { result, entities } = normalize(serviceRulesMetadata, ServiceRulesMetadata)

  const _serviceRulesMetadata = propOr({}, 'serviceRuleMetadata', entities)

  return {
    result,
    entities: {
      serviceRuleMetadata: _serviceRulesMetadata,
    },
  } as NormalizedServiceRulesMetadata
}

// service rules
// ---------------------------------------------------------------------------

export const CharacteristicRule = new schema.Entity('characteristicRule')
export const CharacteristicRules = [CharacteristicRule]

export function NormalizeCharacteristicRules(characteristicRules: Codecs.CharacteristicRule[]) {
  type _CharacteristicRules = {
    characteristicRule: {
      [index: string]: Codecs.CharacteristicRule
    }
  }

  type NormalizedCharacteristicRules = {
    entities: _CharacteristicRules
    result: string[]
  }

  const { result, entities } = normalize(characteristicRules, CharacteristicRules)

  const _characteristicRules = propOr({}, 'characteristicRule', entities)

  return {
    result,
    entities: {
      characteristicRule: _characteristicRules,
    },
  } as NormalizedCharacteristicRules
}

export const CharacteristicRuleMetadata = new schema.Entity('characteristicRuleMetadata')
export const CharacteristicRulesMetadata = [CharacteristicRuleMetadata]

export function NormalizeCharacteristicRulesMetadata(characteristicRulesMetadata: Codecs.CharacteristicRuleMetadata[]) {
  type _CharacteristicRulesMetadata = {
    characteristicRuleMetadata: {
      [index: string]: Codecs.CharacteristicRuleMetadata
    }
  }

  type NormalizedCharacteristicRulesMetadata = {
    entities: _CharacteristicRulesMetadata
    result: string[]
  }

  const { result, entities } = normalize(characteristicRulesMetadata, CharacteristicRulesMetadata)

  const _characteristicRulesMetadata = propOr({}, 'characteristicRuleMetadata', entities)

  return {
    result,
    entities: {
      characteristicRuleMetadata: _characteristicRulesMetadata,
    },
  } as NormalizedCharacteristicRulesMetadata
}

// artifacts
// ---------------------------------------------------------------------------

export const Artifact = new schema.Entity('artifact')
export const Artifacts = [Artifact]

export function NormalizeArtifacts(artifacts: Codecs.Artifact[]) {
  type _Artifacts = {
    artifact: {
      [index: string]: Codecs.Artifact
    }
  }

  type NormalizedArtifacts = {
    entities: _Artifacts
    result: string[]
  }

  const { result, entities } = normalize(artifacts, Artifacts)

  const _artifacts = propOr({}, 'artifact', entities)

  return {
    result,
    entities: {
      artifact: _artifacts,
    },
  } as NormalizedArtifacts
}
