/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ---------------------------------------------------------------------------
import { ApiSpecReducer } from './api-spec.reducers'

export type State = ReturnType<typeof Reducer>
export const Reducer = combineReducers({
  apiSpec: persistReducer({ key: 'api-spec', storage }, ApiSpecReducer),
})
