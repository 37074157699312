/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { pathOr } from 'ramda'

import * as Codecs from '@/codecs'
// ---------------------------------------------------------------------------
import * as Actions from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const OrganizationUsersPaginationReducer = (
  state = { count: 0, offset: 0, total: 0 },
  action: Actions.ActionTypes,
) => {
  switch (action.type) {
    case Actions.OrganizationActions.TypeKeys.USERS_PAGINATION_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

export const OrganizationUsersReducer = (
  state = Schemas.OrganizationSchemas.NormalizeOrgUsers([]),
  action: Actions.ActionTypes,
) => {
  switch (action.type) {
    case Actions.OrganizationActions.TypeKeys.USERS_UPDATE:
      return Schemas.OrganizationSchemas.NormalizeOrgUsers(action.payload)

    case Actions.OrganizationActions.TypeKeys.USER_ROLES_UPDATE: {
      const prevUsers: { [index: string]: Codecs.User } = pathOr({}, ['entities', 'user'], state)

      return {
        result: state.result,
        entities: {
          user: {
            ...prevUsers,
            [action.payload.target_user_id]: {
              ...prevUsers[action.payload.target_user_id],
              perm_groups: action.payload.perm_groups,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export type UserOrganizationsReducerState = Codecs.Organization[]
export const UserOrganizationsReducer = (
  state = [] as Codecs.Organization[],
  action: Actions.ActionTypes,
): UserOrganizationsReducerState => {
  switch (action.type) {
    case Actions.OrganizationActions.TypeKeys.ORGANIZATIONS_UPDATE:
      return action.payload

    default:
      return state
  }
}

export const UserOrganizationsPaginationReducer = (
  state = { count: 0, offset: 0, total: 0 },
  action: Actions.ActionTypes,
) => {
  switch (action.type) {
    case Actions.OrganizationActions.TypeKeys.ORGANIZATIONS_PAGINATION_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}
