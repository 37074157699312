/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

type CharacteristicReducerState = { [i: string]: boolean }

const initialState: CharacteristicReducerState = {}

export const CharacteristicReducer = (state = initialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.SHOW_CHARACTERISTIC_DESC: {
      return {
        ...state,
        [action.payload]: true,
      }
    }

    case Store.UIActions.TypeKeys.HIDE_CHARACTERISTIC_DESC: {
      return {
        ...state,
        [action.payload]: false,
      }
    }

    case Store.UIActions.TypeKeys.TOGGLE_CHARACTERISTIC_DESC: {
      const prevState = state[action.payload] ?? false

      return {
        ...state,
        [action.payload]: !prevState,
      }
    }

    default:
      return state
  }
}
