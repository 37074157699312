/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DetectionCriteria } from './detection-criteria.codecs'
import { EnrichedData } from './enriched-data.codecs'
import { LensView, Perspective } from './perspective.codecs'

// ---------------------------------------------------------------------------

// -- Aggregator Artifacts

export const Artifact = t.type({
  id: t.string,

  affiliation: t.union([t.number, t.null]),
  affiliation_display: t.union([t.number, t.null]),
  affiliation_override: t.union([t.boolean, t.null]),
  artifact_type: t.union([t.number, t.null]),
  confidence: t.union([t.number, t.null]),
  confidence_display: t.union([t.number, t.null]),
  confidence_override: t.union([t.boolean, t.null]),
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.union([t.string, t.null]),
  data_hash: t.union([t.string, t.null]),
  deleted: t.boolean,
  detection_criteria: DetectionCriteria,
  enriched_data: EnrichedData,
  first_time: t.union([t.string, t.null]), // iso-string
  flags: t.union([t.number, t.null]),
  lens_view: t.union([LensView, t.null]),
  org_id: t.string,
  perspective: Perspective,
  src: t.union([t.string, t.null]),
  src_type: t.union([t.string, t.null]),
  sys_period: t.union([t.string, t.null]),
  time: t.union([t.string, t.null]), // iso-string
  type: t.union([t.string, t.null]),
  work_type: t.union([t.string, t.null]),
})
export type Artifact = t.TypeOf<typeof Artifact>

export const ArtifactsResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Artifact),
})
export type ArtifactsResponse = t.TypeOf<typeof ArtifactsResponse>

// -- Recon Artifacts

export const ReconArtifact = t.type({
  // @TODO: add `flags` field
  //
  // this field should contain the same data as HOC artifacts, but will
  // require a platform change to amend the reconsvc artifact records.
  id: t.string,
  artifact_type: t.union([t.number, t.null]),
  data_hash: t.union([t.string, t.null]),
  detection_criteria: DetectionCriteria,
  enriched_data: EnrichedData,
  first_seen: t.union([t.string, t.null]), // iso-string
  last_seen: t.union([t.string, t.null]), // iso-string
  org_id: t.string,
  src: t.union([t.string, t.null]),
  src_type: t.union([t.string, t.null]),
  work_type: t.union([t.string, t.null]),
})
export type ReconArtifact = t.TypeOf<typeof ReconArtifact>

export const ReconArtifactResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(ReconArtifact),
})
export type ReconArtifactResponse = t.TypeOf<typeof ReconArtifactResponse>
