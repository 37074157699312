/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

export type Flags = typeof Flags
export const Flags = {
  addInternalNetwork: true,
  carbonColors: false,
  carbonRefactor: false,
  eventLog: false,
  exceptionPolicy: true,
  assets: true,
  sources: true,
  targetRefactor: false,

  // quick-toggle for typical customer view
  hidePlatformSupport: false,
}

export const storageKey = 'flag'
