/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export type GlobalHostname = t.TypeOf<typeof GlobalHostname>
export const GlobalHostname = t.type({
  affiliation: t.number,
  affiliation_display: t.number,
  affiliation_override: t.boolean,
  confidence: t.number,
  confidence_display: t.number,
  confidence_override: t.boolean,
  creator_type: t.union([t.string, t.null]),
  creator_uuid: t.union([t.string, t.null]),
  deleted: t.boolean,
  first_time: t.union([t.string, t.null]),
  hostname: t.string,
  id: t.string,
  lens_view: t.union([t.string, t.null]),
  org_id: t.string,
  perspective: t.union([t.string, t.null]),
  sys_period: t.union([t.string, t.null]),
  time: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
})
