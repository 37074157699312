/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { KeycloakError as KCError } from 'keycloak-js'

import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

// @see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
export class KeycloakError extends Error {
  addl_desc: string | undefined
  kc_description: string | undefined
  kc_error: string | undefined

  constructor(
    message: {
      addl_desc?: string
      kc_description?: KCError['error_description']
      kc_error?: KCError['error']
      kind: string
    },
    ...rest: string[]
  ) {
    super(...rest)

    this.addl_desc = message.addl_desc
    this.kc_description = message.kc_description
    this.kc_error = message.kc_error
    this.message = message.kind
    this.name = 'KeycloakError'

    // eslint-disable-next-line @typescript-eslint/unbound-method
    if (isNotNil(Error.captureStackTrace)) {
      Error.captureStackTrace(this, KeycloakError)
    } else {
      this.stack = new Error(message.kind).stack
    }
  }
}
