/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
/*********************************************************************
 * Copyright © 2023 Randori https://randori.com - All Rights Reserved
 *********************************************************************/
import { i18n } from '@/utilities/i18n'

export const EDGE_DESCRIPTIONS = {
  'Cloud Integration: AWS': i18n.t('edge-descriptions.aws'),
  'Cloud Integration: GCP': i18n.t('edge-descriptions.gcp'),
  'Cloud Integration: Azure': i18n.t('edge-descriptions.azure'),
  'Cloud Integration: IBM': i18n.t('edge-descriptions.ibm'),
  'BGP Records': i18n.t('edge-descriptions.BGP'),
  'Bulk Public WHOIS': i18n.t('edge-descriptions.bulk-public-whois'),
  'Business Intelligence of Acquisition': i18n.t('edge-descriptions.acquisition'),
  'Business Intelligence of Subsidiary': i18n.t('edge-descriptions.subsidiary'),
  'Business Intelligence Resource': i18n.t('edge-descriptions.business-intelligence-resource'),
  'Certificate Data': i18n.t('edge-descriptions.certificate-data'),
  'Certificate Scraping': i18n.t('edge-descriptions.certificate-scraping'),
  'Customer Provided': i18n.t('edge-descriptions.customer-provided'),
  'Detection to Target Consolidation': i18n.t('edge-descriptions.detection-to-target-consolidation'),
  'Detection-Target connection': i18n.t('edge-descriptions.detection-to-target-connection'),
  'Directory Discovery': i18n.t('edge-descriptions.directory-discovery'),
  'DNS Record': i18n.t('edge-descriptions.dns-record'),
  'Logical Containment Relationship': i18n.t('edge-descriptions.containment-relationship'),
  'Manual Curation': i18n.t('edge-descriptions.manual-curation'),
  'Manual Entry': i18n.t('edge-descriptions.manual-entry'),
  'Marketing Resource': i18n.t('edge-descriptions.marketing-resource'),
  Natural: i18n.t('edge-descriptions.natural'),
  'Passive DNS Records': i18n.t('edge-descriptions.passive-dns'),
  'Port Scan': i18n.t('edge-descriptions.port-scan'),
  'Private DNS Email Domain Search': i18n.t('edge-descriptions.private-dns-email-domain'),
  'Private DNS Email Search': i18n.t('edge-descriptions.private-dns-email'),
  'Private DNS Nameserver Search': i18n.t('edge-descriptions.private-dns-nameserver'),
  'Private DNS POC Search': i18n.t('edge-descriptions.private-dns-poc'),
  'Private IP and Network Intelligence': i18n.t('edge-descriptions.ipinfo-ranges'),
  'Private Passive DNS Nameserver Search': i18n.t('edge-descriptions.passive-dns-nameserver'),
  'Private Passive DNS Records': i18n.t('edge-descriptions.passive-dns-record'),
  'Private Passive DNS Subdomain Search': i18n.t('edge-descriptions.passive-dns-subdomain'),
  'Private WHOIS Domain Record Search': i18n.t('edge-descriptions.private-whois'),
  'Private WHOIS Historical Record Search': i18n.t('edge-descriptions.private-whois-historical'),
  'Private WHOIS POC Record Search': i18n.t('edge-descriptions.private-whois-poc'),
  'Private Whois Record Search': i18n.t('edge-descriptions.private-whois-record'),
  'Private WHOIS Record Search': i18n.t('edge-descriptions.private-whois-record2'),
  'Private WHOIS Reverse Record Search': i18n.t('edge-descriptions.private-whois-reverse'),
  'Private WHOIS Term Record Search': i18n.t('edge-descriptions.private-whois-term'),
  'Public Certificate Search': i18n.t('edge-descriptions.public-whois-certificate'),
  'Public WHOIS Record Search': i18n.t('edge-descriptions.public-whois-record'),
  'Remote Desktop Protocol Probe': i18n.t('edge-descriptions.remote-desktop-protocol'),
  'SMB Protocol Probe': i18n.t('edge-descriptions.smb-probe'),
  'Social Reconnaissance Discovery': i18n.t('edge-descriptions.social-recon'),
  'Telnet Service Probe': i18n.t('edge-descriptions.telnet'),
  Unknown: i18n.t('edge-descriptions.unknown'),
  'Web Scraping': i18n.t('edge-descriptions.web-scraping'),
  'Whois Records': i18n.t('edge-descriptions.whois'),
}

export type EdgeDescriptionString = keyof typeof EDGE_DESCRIPTIONS
