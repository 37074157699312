/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { PaginatedLink, PaginationMeta } from '../json-api.codecs'

// ---------------------------------------------------------------------------

// Note: not currently using this because the numbers are being treated as strings instead of numbers...
export const RequiredAuthorization = t.keyof({
  0: null, // Recon
  1: null, // Validate
  2: null, // Attack
})
export type RequiredAuthorization = t.TypeOf<typeof RequiredAuthorization>

// this does not work either
// Type 'readonly [0, 1, 2]' is not assignable to type 'Mixed'.
export const RequiredAuthorization2 = [0, 1, 2] as const
export type RequiredAuthorization2 = (typeof RequiredAuthorization2)[number]

const _Attributes = {
  name: t.string,
  description: t.string, // might be nice as a tool tip
  last_planned_at: t.union([t.string, t.null]),
  period: t.number,
  needs_authorization: t.boolean,
  stability: t.number,
  // required_authorization: RequiredAuthorization,
  required_authorization: t.number,
}

export const ApplicableActivityAttributes = t.type(_Attributes)

// ----------------------------------------------------------------------------

export const ApplicableActivity = t.type({
  attributes: ApplicableActivityAttributes,
  id: t.string, // uuid
  type: t.literal('applicable_activity'),
})
export type ApplicableActivity = t.TypeOf<typeof ApplicableActivity>

export const ApplicableActivitiesResponse = t.type({
  data: t.array(ApplicableActivity),
  links: PaginatedLink,
  meta: PaginationMeta,
})
export type ApplicableActivitiesResponse = t.TypeOf<typeof ApplicableActivitiesResponse>

// ----------------------------------------------------------------------------

export const ValidateJobResponse = t.type({
  job_id: t.string,
})
export type ValidateJobResponse = t.TypeOf<typeof ValidateJobResponse>
