/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { Hostname } from './hostname'
import { HostnameForIp } from './hostname-for-ip'

// ---------------------------------------------------------------------------

export type HostnamesForIpResponse = t.TypeOf<typeof HostnamesForIpResponse>
export const HostnamesForIpResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(HostnameForIp),
})

export type HostnameResponse = t.TypeOf<typeof HostnameResponse>
export const HostnameResponse = t.type({
  data: Hostname,
})

export type HostnamesResponse = t.TypeOf<typeof HostnamesResponse>
export const HostnamesResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Hostname),
})
