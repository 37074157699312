/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

const isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

// @see: https://github.com/gcanti/io-ts-types/blob/master/src/UUID.ts
export interface UUIDBrand {
  readonly UUID: unique symbol
}
export type UUID = t.Branded<string, UUIDBrand>
export const UUID = t.brand(t.string, (s): s is UUID => isUuid.test(s), 'UUID')

export interface DoubleUUIDBrand {
  readonly DoubleUUID: unique symbol
}
export type DoubleUUID = t.Branded<string, DoubleUUIDBrand>
export const DoubleUUID = t.brand(
  t.string,
  (s): s is DoubleUUID => {
    const uuids = s.split(',')

    return (
      uuids.length === 2 &&
      uuids.reduce((_isUuid, uuid) => {
        return _isUuid && isUuid.test(uuid)
      }, true)
    )
  },
  'DoubleUUID',
)

export interface TripleUUIDBrand {
  readonly TripleUUID: unique symbol
}
export type TripleUUID = t.Branded<string, TripleUUIDBrand>
export const TripleUUID = t.brand(
  t.string,
  (s): s is TripleUUID => {
    const uuids = s.split(',')
    return (
      uuids.length === 3 &&
      uuids.reduce((_isUuid, uuid) => {
        return _isUuid && isUuid.test(uuid)
      }, true)
    )
  },
  'TripleUUID',
)
