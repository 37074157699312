/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { find, pathOr, propOr, sortBy } from 'ramda'
import { createSelector, defaultMemoize } from 'reselect'

// ---------------------------------------------------------------------------
import { ApiToken, Feature, User } from '@/codecs'
import { AppState } from '@/store'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

const _getApiTokens = (state: AppState) => state.organization.orgApiTokens
const _getFeatures = (state: AppState) => state.organization.orgFeatures
const _getOrganization = (state: AppState) => state.organization.orgInfo
const _getOrgsPagination = (state: AppState) => state.organization.organizationsPagination
const _getSsoProviders = (state: AppState) => state.organization.ssoProviders
const _getUserOrganizations = (state: AppState) => state.organization.userOrganizations
const _getUsers = (state: AppState) => state.organization.orgUsers
const _getUsersPagination = (state: AppState) => state.organization.orgUsersPagination

const _getUserOrganizationsByIds = (state: AppState, props: { ids: string[] }) => {
  return props.ids.map((id) => {
    const org = find((_org) => _org.id === id, state.organization.userOrganizations)

    if (isNotNil(org)) {
      return org
    } else {
      return id
    }
  })
}

// ---------------------------------------------------------------------------

export const selectOrgName = createSelector([_getOrganization], (org) => {
  const orgName: string = propOr('', 'name', org)
  return orgName
})

export const selectOrgId = createSelector([_getOrganization], (org) => {
  const orgId: string = propOr('', 'id', org)

  return orgId
})

export const selectOrgAllowedDomains = createSelector([_getOrganization], (org) => {
  const allowedDomains: string[] = propOr([], 'allowed_email_domains', org)

  return allowedDomains
})

export const selectUserOrgs = createSelector([_getUserOrganizations], (orgs) => orgs)

export const selectUserOrganizationsByIds = createSelector([_getUserOrganizationsByIds], (names) => names)

export const makeSelectOrgByShortname = createSelector([selectUserOrgs], (orgs) =>
  defaultMemoize((shortname: string) => {
    const org = find((org) => {
      return org.shortname === shortname
    }, orgs)
    return org
  }),
)

export const makeSelectOrgById = createSelector([selectUserOrgs], (orgs) =>
  defaultMemoize((id: string) => {
    return find((org) => {
      return org.id === id
    }, orgs)
  }),
)

export const selectFeatures = createSelector([_getFeatures], (orgFeatures) => {
  const features: Record<string, Feature> = pathOr({}, ['entities', 'feature'], orgFeatures)

  return features
})

export const selectUsersPagination = createSelector([_getUsersPagination], (pagination) => {
  return pagination
})

export const selectOrgsPagination = createSelector([_getOrgsPagination], (pagination) => {
  return pagination
})

// @TODO: Consider definitions of util functions from libs
//
// A lot of generic functions from utility libraries are typed such that their
// returns are `any`. pathOr seems to function this way, as does `normalize`.
//
// For those cases, we can write special cases ourselves. For instance, we can
// write our own version of pathOr:
//
// function pathOr<T, U>(defaultValue: T, path, data: F) {
//   // T: Type of default value
//   // U: Type of successfully extracted value
//   const value: T | U = _pathOr<T>(defaultValue, path, data)
//
//   return value
// }
//
// @see: organization.schemas.ts NormalizeOrgUsers
//
// Reproduced here for clarity
// export function NormalizeOrgUsers (users: UserCodec[]) {
//   type _Users = {
//     user: {
//       [index: string]: UserCodec
//     }
//   }
//
//   type Munged = {
//     entities: _Users | {},
//     result: string[]
//   }
//
//   const munged: Munged = normalize(users, Users)
//
//   return munged
// }

export const selectUsers = createSelector([_getUsers], (_usersState) => {
  const _usersEntities: { [index: string]: User } = pathOr({}, ['entities', 'user'], _usersState)

  const users = _usersState.result.map((user: string) => _usersEntities[user])

  return users
})

export const selectUserRoles = createSelector([_getUsers], (_usersState) => {
  return defaultMemoize((userId: string) => {
    const _usersEntities: { [index: string]: User & { perm_groups: string[] } } = pathOr(
      {},
      ['entities', 'user'],
      _usersState,
    )

    const _roles: string[] = propOr([], 'perm_groups', _usersEntities[userId])

    const roles = _roles.reduce((acc, el) => {
      acc[el] = true
      return acc
    }, {} as { [index: string]: boolean })

    return roles
  })
})

export const selectApiTokens = createSelector([_getApiTokens], (_tokensState) => {
  const _tokenEntities: { [index: string]: ApiToken } = pathOr({}, ['entities', 'api-token'], _tokensState)

  const tokens = sortBy<ApiToken>(propOr('', 'label'), Object.values(_tokenEntities))

  return tokens
})

export const selectOrgDomains = createSelector([_getOrganization], (orgInfo) => {
  return orgInfo.allowed_email_domains.sort()
})

export const selectUserOrgsAsMap = createSelector([_getUserOrganizations], (orgs) => {
  return orgs.reduce((acc, org) => {
    acc[org.id] = org
    return acc
  }, {} as { [index: string]: (typeof orgs)[number] })
})

export const selectOrgLoginConfig = createSelector([_getOrganization], (organizations) => {
  return organizations.login_methods
})

export const selectOrgSsoUri = createSelector([_getOrganization], (organizations) => {
  return organizations.sso_path
})

export const selectSsoProviders = createSelector([_getSsoProviders], (ssoProviders) => {
  return ssoProviders.result.map((id: string) => ssoProviders.entities.provider[id])
})

export const selectOrg = createSelector([_getOrganization], (org) => org)
