/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'
import * as EntityDetailStore from '@/store/reducers/ui/entity-detail.reducers'
import { AvailableColumns, Columns } from '@/store/reducers/ui/entity-table.reducers'
import { Orientation } from '@/store/reducers/ui/inspect-pane.reducers'
import { ActiveModal } from '@/store/reducers/ui/modal.reducers'
import { ActiveSlideout } from '@/store/reducers/ui/slideout.reducers'
import * as CodecUtils from '@/utilities/codec'
import { ServiceFormUtils } from '@/utilities/entity-form'
import { Flags } from '@/utilities/flags'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  CONFIGURE_GUIDANCE_PANE = 'CONFIGURE_GUIDANCE_PANE',
  CONFIGURE_INSPECT_PANE = 'CONFIGURE_INSPECT_PANE',
  DOCK_GUIDANCE_PANE = 'DOCK_GUIDANCE_PANE',
  DOCK_INSPECT_PANE = 'DOCK_INSPECT_PANE',
  HIDE_CHARACTERISTIC_DESC = 'HIDE_CHARACTERISTIC_DESC',
  HIDE_FACTOR_DESC = 'HIDE_FACTOR_DESC',
  HIDE_GUIDANCE_PANE = 'HIDE_GUIDANCE_PANE',
  HIDE_INSPECT_PANE = 'HIDE_INSPECT_PANE',
  HIDE_MODAL = 'HIDE_MODAL',
  HIDE_SLIDEOUT = 'HIDE_SLIDEOUT',
  MAP_API_SPEC_TO_TABLE_COLUMNS = 'MAP_API_SPEC_TO_TABLE_COLUMNS',
  RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE = 'RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE',
  RESET_HOC_TEMPLATE = 'SET_HOC_TEMPLATE',
  SET_ENTITY_DETAIL_ACTIVE_NAV_STATE = 'SET_ENTITY_DETAIL_ACTIVE_NAV_STATE',
  SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY = 'SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY',
  SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE = 'SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE',
  SET_ENTITY_DETAIL_NAV_ORDER_STATE = 'SET_ENTITY_DETAIL_NAV_ORDER_STATE',
  SET_HOC_TEMPLATE = 'SET_HOC_TEMPLATE',
  SHOW_CHARACTERISTIC_DESC = 'SHOW_CHARACTERISTIC_DESC',
  SHOW_FACTOR_DESC = 'SHOW_FACTOR_DESC',
  SHOW_GUIDANCE_PANE = 'SHOW_GUIDANCE_PANE',
  SHOW_INSPECT_PANE = 'SHOW_INSPECT_PANE',
  SHOW_MODAL = 'SHOW_MODAL',
  SHOW_SLIDEOUT = 'SHOW_SLIDEOUT',
  SHOW_WORKATO_EMBED = 'SHOW_WORKATO_EMBED',
  TOGGLE_CHARACTERISTIC_DESC = 'TOGGLE_CHARACTERISTIC_DESC',
  TOGGLE_FACTOR_DESC = 'TOGGLE_FACTOR_DESC',
  TOGGLE_FLAG = 'TOGGLE_FLAG',
  TOGGLE_NAVIGATION_SECTION = 'TOGGLE_NAVIGATION_SECTION',
  TOGGLE_TABLE_SUMMARY = 'TOGGLE_TABLE_SUMMARY',
  UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES = 'UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES',
  UPDATE_ENTITY_DETAIL_RENDER = 'UPDATE_ENTITY_DETAIL_RENDER',
  UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS = 'UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS',
  UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS = 'UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS',
}

export type StateActionTypes =
  | CONFIGURE_GUIDANCE_PANE
  | CONFIGURE_INSPECT_PANE
  | DOCK_GUIDANCE_PANE
  | DOCK_INSPECT_PANE
  | HIDE_CHARACTERISTIC_DESC
  | HIDE_FACTOR_DESC
  | HIDE_GUIDANCE_PANE
  | HIDE_INSPECT_PANE
  | HIDE_MODAL
  | HIDE_SLIDEOUT
  | MAP_API_SPEC_TO_TABLE_COLUMNS
  | RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE
  | RESET_HOC_TEMPLATE
  | SET_ENTITY_DETAIL_ACTIVE_NAV_STATE
  | SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY
  | SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE
  | SET_ENTITY_DETAIL_NAV_ORDER_STATE
  | SET_HOC_TEMPLATE
  | SHOW_CHARACTERISTIC_DESC
  | SHOW_FACTOR_DESC
  | SHOW_GUIDANCE_PANE
  | SHOW_INSPECT_PANE
  | SHOW_MODAL
  | SHOW_SLIDEOUT
  | TOGGLE_CHARACTERISTIC_DESC
  | TOGGLE_FACTOR_DESC
  | TOGGLE_FLAG
  | TOGGLE_NAVIGATION_SECTION
  | TOGGLE_TABLE_SUMMARY
  | UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES
  | UPDATE_ENTITY_DETAIL_RENDER
  | UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS
  | UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS

// modal
// ---------------------------------------------------------------------------

export type SHOW_MODAL = {
  type: TypeKeys.SHOW_MODAL
  payload: ActiveModal
}

export const SHOW_MODAL = (modal: SHOW_MODAL['payload']): SHOW_MODAL => ({
  type: TypeKeys.SHOW_MODAL,
  payload: modal,
})

export type HIDE_MODAL = {
  type: TypeKeys.HIDE_MODAL
}

export const HIDE_MODAL = (): HIDE_MODAL => ({
  type: TypeKeys.HIDE_MODAL,
})

// slideout
// ---------------------------------------------------------------------------

export type SHOW_SLIDEOUT = {
  type: TypeKeys.SHOW_SLIDEOUT
  payload: ActiveSlideout
}

export const SHOW_SLIDEOUT = (payload: SHOW_SLIDEOUT['payload']): SHOW_SLIDEOUT => ({
  type: TypeKeys.SHOW_SLIDEOUT,
  payload,
})

export type HIDE_SLIDEOUT = {
  type: TypeKeys.HIDE_SLIDEOUT
}

export const HIDE_SLIDEOUT = (): HIDE_SLIDEOUT => ({
  type: TypeKeys.HIDE_SLIDEOUT,
})

// HOC template
// ---------------------------------------------------------------------------

export type SET_HOC_TEMPLATE = {
  type: TypeKeys.SET_HOC_TEMPLATE
  payload: Partial<ServiceFormUtils.ServiceFormValues>
}

export const SET_HOC_TEMPLATE = (payload: SET_HOC_TEMPLATE['payload']): SET_HOC_TEMPLATE => {
  return {
    type: TypeKeys.SET_HOC_TEMPLATE,
    payload,
  }
}

export type RESET_HOC_TEMPLATE = {
  type: TypeKeys.RESET_HOC_TEMPLATE
  payload: null
}

export const RESET_HOC_TEMPLATE = (): RESET_HOC_TEMPLATE => {
  return {
    type: TypeKeys.RESET_HOC_TEMPLATE,
    payload: null,
  }
}

// factors
// ---------------------------------------------------------------------------

export type SHOW_FACTOR_DESC = {
  type: TypeKeys.SHOW_FACTOR_DESC
  payload: Codecs.TemptationFactor
}

export const SHOW_FACTOR_DESC = (payload: SHOW_FACTOR_DESC['payload']): SHOW_FACTOR_DESC => {
  return {
    type: TypeKeys.SHOW_FACTOR_DESC,
    payload,
  }
}

export type HIDE_FACTOR_DESC = {
  type: TypeKeys.HIDE_FACTOR_DESC
  payload: Codecs.TemptationFactor
}

export const HIDE_FACTOR_DESC = (payload: HIDE_FACTOR_DESC['payload']): HIDE_FACTOR_DESC => {
  return {
    type: TypeKeys.HIDE_FACTOR_DESC,
    payload,
  }
}

export type TOGGLE_FACTOR_DESC = {
  type: TypeKeys.TOGGLE_FACTOR_DESC
  payload: Codecs.TemptationFactor
}

export const TOGGLE_FACTOR_DESC = (payload: TOGGLE_FACTOR_DESC['payload']): TOGGLE_FACTOR_DESC => {
  return {
    type: TypeKeys.TOGGLE_FACTOR_DESC,
    payload,
  }
}

// characteristics
// ---------------------------------------------------------------------------

export type SHOW_CHARACTERISTIC_DESC = {
  type: TypeKeys.SHOW_CHARACTERISTIC_DESC
  payload: string
}

export const SHOW_CHARACTERISTIC_DESC = (payload: SHOW_CHARACTERISTIC_DESC['payload']): SHOW_CHARACTERISTIC_DESC => {
  return {
    type: TypeKeys.SHOW_CHARACTERISTIC_DESC,
    payload,
  }
}

export type HIDE_CHARACTERISTIC_DESC = {
  type: TypeKeys.HIDE_CHARACTERISTIC_DESC
  payload: string
}

export const HIDE_CHARACTERISTIC_DESC = (payload: HIDE_CHARACTERISTIC_DESC['payload']): HIDE_CHARACTERISTIC_DESC => {
  return {
    type: TypeKeys.HIDE_CHARACTERISTIC_DESC,
    payload,
  }
}

export type TOGGLE_CHARACTERISTIC_DESC = {
  type: TypeKeys.TOGGLE_CHARACTERISTIC_DESC
  payload: string
}

export const TOGGLE_CHARACTERISTIC_DESC = (
  payload: TOGGLE_CHARACTERISTIC_DESC['payload'],
): TOGGLE_CHARACTERISTIC_DESC => {
  return {
    type: TypeKeys.TOGGLE_CHARACTERISTIC_DESC,
    payload,
  }
}

export type TOGGLE_NAVIGATION_SECTION = {
  type: TypeKeys.TOGGLE_NAVIGATION_SECTION
  payload: string
}

export const TOGGLE_NAVIGATION_SECTION = (payload: TOGGLE_NAVIGATION_SECTION['payload']): TOGGLE_NAVIGATION_SECTION => {
  return {
    type: TypeKeys.TOGGLE_NAVIGATION_SECTION,
    payload,
  }
}

export type TOGGLE_FLAG = {
  type: TypeKeys.TOGGLE_FLAG
  payload: keyof typeof Flags
}

export const TOGGLE_FLAG = (payload: TOGGLE_FLAG['payload']): TOGGLE_FLAG => {
  return {
    type: TypeKeys.TOGGLE_FLAG,
    payload,
  }
}

export type SHOW_WORKATO_EMBED = {
  type: TypeKeys.SHOW_WORKATO_EMBED
  meta: { deferred: Deferred }
  payload: {
    connectionId: number
    connectionName: string
  }
}

export const SHOW_WORKATO_EMBED = (payload: SHOW_WORKATO_EMBED['payload'], deferred: Deferred): SHOW_WORKATO_EMBED => {
  return {
    type: TypeKeys.SHOW_WORKATO_EMBED,
    meta: { deferred },
    payload,
  }
}

export type NavUpdateActiveItems = {
  parentId: EntityDetailStore.EntityDetailNavId
  childId: string | null
}

export type SET_ENTITY_DETAIL_ACTIVE_NAV_STATE = {
  type: TypeKeys.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE
  payload: {
    entityType: EntityDetailStore.NarrowedEntityTypes
    activeItems: NavUpdateActiveItems
  }
}

export const SET_ENTITY_DETAIL_ACTIVE_NAV_STATE = (
  payload: SET_ENTITY_DETAIL_ACTIVE_NAV_STATE['payload'],
): SET_ENTITY_DETAIL_ACTIVE_NAV_STATE => {
  const { activeItems, entityType: _entityType } = payload

  const entityType = CodecUtils.throwValidate(EntityDetailStore.keyOfInitialState, 'keyOfInitialState')(_entityType)

  return {
    type: TypeKeys.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE,
    payload: {
      activeItems,
      entityType,
    },
  }
}

export enum entityDetailActiveNavPriority {
  scroll = 0,
  pageLoad = 1,
  userClick = 2,
  reorderNav = 3,
}

export type SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY = {
  type: TypeKeys.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY
  payload: {
    entityType: EntityDetailStore.NarrowedEntityTypes
    activeItems: {
      parentId: EntityDetailStore.EntityDetailNavId
      childId: string | null
    }
    priority: entityDetailActiveNavPriority
  }
}

export const SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY = (
  payload: SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY['payload'],
): SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY => {
  const { activeItems, entityType: _entityType, priority } = payload

  const entityType = CodecUtils.throwValidate(EntityDetailStore.keyOfInitialState, 'keyOfInitialState')(_entityType)

  return {
    type: TypeKeys.SET_ENTITY_DETAIL_ACTIVE_NAV_STATE_PRIORITY,
    payload: {
      activeItems,
      entityType,
      priority,
    },
  }
}

export type RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE = {
  type: TypeKeys.RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE
  payload: {
    entityType: EntityUtils.EntityType
  }
}

export const RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE = (
  payload: RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE['payload'],
): RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE => {
  return {
    type: TypeKeys.RESET_ENTITY_DETAIL_ACTIVE_NAV_STATE,
    payload,
  }
}

// @TODO: narrow guidance content type

export type SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE__payload = {
  entityType: EntityUtils.EntityType
  guidanceContent: EntityDetailStore.EntityDetailChildNavigationItem[]
}

export type SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE = {
  type: TypeKeys.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE
  payload: {
    entityType: EntityDetailStore.NarrowedEntityTypes
    guidanceContent: EntityDetailStore.EntityDetailChildNavigationItem[]
  }
}

export const SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE = (
  payload: SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE__payload,
): SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE => {
  const { guidanceContent, entityType: _entityType } = payload

  const entityType = CodecUtils.throwValidate(EntityDetailStore.keyOfInitialState, 'keyOfInitialState')(_entityType)

  return {
    type: TypeKeys.SET_ENTITY_DETAIL_NAV_GUIDANCE_STATE,
    payload: {
      entityType,
      guidanceContent,
    },
  }
}

export type HIDE_INSPECT_PANE = {
  type: TypeKeys.HIDE_INSPECT_PANE
}

export const HIDE_INSPECT_PANE = (): HIDE_INSPECT_PANE => {
  return {
    type: TypeKeys.HIDE_INSPECT_PANE,
  }
}

export type SHOW_INSPECT_PANE = {
  type: TypeKeys.SHOW_INSPECT_PANE
  payload: {
    entityId: string
    entityType: EntityUtils.EntityType
  }
}

export const SHOW_INSPECT_PANE = (payload: SHOW_INSPECT_PANE['payload']): SHOW_INSPECT_PANE => {
  return {
    type: TypeKeys.SHOW_INSPECT_PANE,
    payload,
  }
}

export type CONFIGURE_INSPECT_PANE = {
  type: TypeKeys.CONFIGURE_INSPECT_PANE
  payload: {
    orientation: Orientation
  }
}

export const CONFIGURE_INSPECT_PANE = (payload: CONFIGURE_INSPECT_PANE['payload']): CONFIGURE_INSPECT_PANE => {
  return {
    type: TypeKeys.CONFIGURE_INSPECT_PANE,
    payload,
  }
}

export type DOCK_INSPECT_PANE = {
  type: TypeKeys.DOCK_INSPECT_PANE
}

export const DOCK_INSPECT_PANE = (): DOCK_INSPECT_PANE => {
  return {
    type: TypeKeys.DOCK_INSPECT_PANE,
  }
}

export type UPDATE_ENTITY_DETAIL_RENDER = {
  type: TypeKeys.UPDATE_ENTITY_DETAIL_RENDER
  payload: null
}

export const UPDATE_ENTITY_DETAIL_RENDER = (): UPDATE_ENTITY_DETAIL_RENDER => {
  return {
    type: TypeKeys.UPDATE_ENTITY_DETAIL_RENDER,
    payload: null,
  }
}

export type SET_ENTITY_DETAIL_NAV_ORDER_STATE = {
  type: TypeKeys.SET_ENTITY_DETAIL_NAV_ORDER_STATE
  payload: {
    entityType: EntityDetailStore.NarrowedEntityTypes
    navItems: EntityDetailStore.EntityDetailNavigationItem[]
  }
}

export const SET_ENTITY_DETAIL_NAV_ORDER_STATE = (
  payload: SET_ENTITY_DETAIL_NAV_ORDER_STATE['payload'],
): SET_ENTITY_DETAIL_NAV_ORDER_STATE => {
  const { navItems, entityType: _entityType } = payload

  const entityType = CodecUtils.throwValidate(EntityDetailStore.keyOfInitialState, 'keyOfInitialState')(_entityType)

  return {
    type: TypeKeys.SET_ENTITY_DETAIL_NAV_ORDER_STATE,
    payload: {
      entityType,
      navItems,
    },
  }
}

export type UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES = {
  type: TypeKeys.UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES
  payload: {
    entityType: EntityDetailStore.NarrowedEntityTypes
    navItemIds: EntityDetailStore.EntityDetailNavId[]
  }
}

export const UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES = (
  payload: UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES['payload'],
): UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES => {
  const { navItemIds, entityType: _entityType } = payload

  const entityType = CodecUtils.throwValidate(EntityDetailStore.keyOfInitialState, 'keyOfInitialState')(_entityType)

  return {
    type: TypeKeys.UPDATE_ENTITY_DETAIL_NAV_ORDER_STATE_FROM_PREFERENCES,
    payload: {
      entityType,
      navItemIds,
    },
  }
}

export type HIDE_GUIDANCE_PANE = {
  type: TypeKeys.HIDE_GUIDANCE_PANE
}

export const HIDE_GUIDANCE_PANE = (): HIDE_GUIDANCE_PANE => {
  return {
    type: TypeKeys.HIDE_GUIDANCE_PANE,
  }
}

export type SHOW_GUIDANCE_PANE = {
  type: TypeKeys.SHOW_GUIDANCE_PANE
  payload: {
    savedViewSlug: string
  }
}

export const SHOW_GUIDANCE_PANE = (payload: SHOW_GUIDANCE_PANE['payload']): SHOW_GUIDANCE_PANE => {
  return {
    type: TypeKeys.SHOW_GUIDANCE_PANE,
    payload,
  }
}

export type CONFIGURE_GUIDANCE_PANE = {
  type: TypeKeys.CONFIGURE_GUIDANCE_PANE
  payload: {
    orientation: Orientation
  }
}

export const CONFIGURE_GUIDANCE_PANE = (payload: CONFIGURE_GUIDANCE_PANE['payload']): CONFIGURE_GUIDANCE_PANE => {
  return {
    type: TypeKeys.CONFIGURE_GUIDANCE_PANE,
    payload,
  }
}

export type DOCK_GUIDANCE_PANE = {
  type: TypeKeys.DOCK_GUIDANCE_PANE
}

export const DOCK_GUIDANCE_PANE = (): DOCK_GUIDANCE_PANE => {
  return {
    type: TypeKeys.DOCK_GUIDANCE_PANE,
  }
}

export type UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS = {
  type: TypeKeys.UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS
  payload: {
    // @TODO: validator
    //
    // columns needs to be a dependent-type on tableId. Only certain columns are available to certain types.
    columns: Columns
    tableId: EntityUtils.EntityTypesTableSummary
  }
}

export const UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS = (
  payload: UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS['payload'],
): UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS => {
  return {
    type: TypeKeys.UPDATE_ENTITY_TABLE_ACTIVE_COLUMNS,
    payload,
  }
}

export type UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS = {
  type: TypeKeys.UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS
  payload: {
    availableColumns: AvailableColumns
    tableId: EntityUtils.EntityTypesTableSummary
  }
}

export const UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS = (
  payload: UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS['payload'],
): UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS => {
  return {
    type: TypeKeys.UPDATE_ENTITY_TABLE_AVAILABLE_COLUMNS,
    payload,
  }
}

export type MAP_API_SPEC_TO_TABLE_COLUMNS = {
  type: TypeKeys.MAP_API_SPEC_TO_TABLE_COLUMNS
}

export const MAP_API_SPEC_TO_TABLE_COLUMNS = (): MAP_API_SPEC_TO_TABLE_COLUMNS => {
  return {
    type: TypeKeys.MAP_API_SPEC_TO_TABLE_COLUMNS,
  }
}

export type TOGGLE_TABLE_SUMMARY = {
  type: TypeKeys.TOGGLE_TABLE_SUMMARY
}

export const TOGGLE_TABLE_SUMMARY = (): TOGGLE_TABLE_SUMMARY => {
  return {
    type: TypeKeys.TOGGLE_TABLE_SUMMARY,
  }
}
