/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, nullableP, UUID } from '@/utilities/codec'

import { WithPerspective } from '../perspective.codecs'

// ---------------------------------------------------------------------------

const _GlobalHostname = t.type({
  affiliation: t.number,
  affiliation_display: t.number,
  affiliation_override: t.boolean,
  confidence: t.number,
  confidence_display: t.number,
  confidence_override: t.boolean,
  creator_type: nullableP(t.string),
  creator_uuid: nullableP(UUID),
  deleted: t.boolean,
  first_time: nullableP(DateString),
  hostname: t.string,
  id: UUID,
  org_id: UUID,
  sys_period: nullableP(t.string),
  time: nullableP(DateString),
  type: nullableP(t.string),
})

export type GlobalHostnameP = t.TypeOf<typeof GlobalHostnameP>
export const GlobalHostnameP = t.intersection([_GlobalHostname, WithPerspective])
