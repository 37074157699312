/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { DetectionV2 as Detection } from '@/codecs'
import { UUID } from '@/utilities/codec'

import { makeState } from '../utilities/make-state'

// ---------------------------------------------------------------------------

export type EntityName = 'bdo_detection'

export const EntityName: EntityName = 'bdo_detection'

export const initialState = {
  ...makeState<EntityName, Detection>(EntityName),
  byEntity: { target: {} as Record<UUID, UUID[]> },
}

export type State = typeof initialState
