/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { CrudRuleGroup } from '@randori/rootkit'

export const EMPTY_ROOT_RULE_GROUP = {
  ui_id: '_group_tla',
  condition: 'AND',
  rules: [
    {
      ui_id: '_group_nvd',
      condition: 'AND',
      rules: [],
    },
    {
      ui_id: '_group_standard',
      condition: 'AND',
      rules: [],
    },
    {
      ui_id: '_group_fts',
      condition: 'OR',
      rules: [],
    },
  ],
} as CrudRuleGroup

export const ROOT_RULE_GROUP = {
  ui_id: '_group_tla',
  condition: 'AND',
  rules: [
    { ui_id: '_group_nvd', condition: 'AND', rules: [] },
    {
      ui_id: '_group_standard',
      condition: 'AND',
      rules: [
        {
          condition: 'OR',
          rules: [
            {
              condition: 'OR',
              rules: [
                {
                  label: 'high',
                  condition: 'AND',
                  rules: [
                    {
                      id: 'table.priority_score',
                      field: 'table.priority_score',
                      type: 'integer',
                      input: 'number',
                      operator: 'greater',
                      value: 29.98,
                    },
                  ],
                },
              ],
              ui_id: 'priority_score',
            },
          ],
        },
      ],
    },
    { ui_id: '_group_fts', condition: 'OR', rules: [] },
  ],
} as CrudRuleGroup

export const ROOT_RULE_GROUP_OR_PRIORITY_SCORE = {
  ui_id: '_group_tla',
  condition: 'AND',
  rules: [
    { ui_id: '_group_nvd', condition: 'AND', rules: [] },
    {
      ui_id: '_group_standard',
      condition: 'AND',
      rules: [
        {
          condition: 'OR',
          rules: [
            {
              condition: 'OR',
              rules: [
                {
                  label: 'high',
                  condition: 'AND',
                  rules: [
                    {
                      id: 'table.priority_score',
                      field: 'table.priority_score',
                      type: 'integer',
                      input: 'number',
                      operator: 'greater',
                      value: 29.98,
                    },
                  ],
                },
              ],
              ui_id: 'priority_score',
            },
            {
              condition: 'OR',
              rules: [
                {
                  label: 'medium',
                  condition: 'AND',
                  rules: [
                    {
                      id: 'table.priority_score',
                      field: 'table.priority_score',
                      type: 'integer',
                      input: 'number',
                      operator: 'greater',
                      value: 20,
                    },
                    {
                      id: 'table.priority_score',
                      field: 'table.priority_score',
                      type: 'integer',
                      input: 'number',
                      operator: 'less_or_equal',
                      value: 29.98,
                    },
                  ],
                },
              ],
              ui_id: 'priority_score',
            },
          ],
        },
      ],
    },
    { ui_id: '_group_fts', condition: 'OR', rules: [] },
  ],
}

export const ROOT_RULE_GROUP_MISSING_STANDARD = {
  ui_id: '_group_tla',
  condition: 'AND',
  rules: [
    {
      ui_id: '_group_nvd',
      condition: 'AND',
      rules: [],
    },
    {
      ui_id: '_group_fts',
      condition: 'OR',
      rules: [],
    },
  ],
} as CrudRuleGroup
