/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { SocialP as Social } from '@/codecs'

import { makeState } from '../utilities/make-state'

// ---------------------------------------------------------------------------

export type EntityName = 'social'

export const EntityName: EntityName = 'social'

export type State = typeof initialState

export const initialState = { ...makeState<EntityName, Social>(EntityName) }
