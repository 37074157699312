/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Reducer } from 'redux'

import * as Store from '@/store/actions'

// ---------------------------------------------------------------------------

export type Orientation = 'bottom' | 'right'

export type GuidancePaneIsOpen = {
  isOpen: true
  orientation: Orientation
  savedViewSlug: string
}

type GuidancePaneIsClosed = {
  isOpen: false
  orientation: Orientation
}

type GuidancePaneState = GuidancePaneIsOpen | GuidancePaneIsClosed

const initialState = {
  isOpen: false as const,
  orientation: 'bottom' as const,
}

export const GuidancePaneReducer: Reducer<GuidancePaneState, Store.ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.HIDE_GUIDANCE_PANE: {
      const nextState = {
        isOpen: false as const,
        orientation: state.orientation,
      }

      return nextState
    }

    case Store.UIActions.TypeKeys.CONFIGURE_GUIDANCE_PANE: {
      const nextState = {
        ...state,
        orientation: action.payload.orientation,
      }

      return nextState
    }

    case Store.UIActions.TypeKeys.DOCK_GUIDANCE_PANE: {
      const nextState = {
        ...state,
        orientation: state.orientation === 'bottom' ? ('right' as const) : ('bottom' as const),
      }

      return nextState
    }

    case Store.UIActions.TypeKeys.SHOW_GUIDANCE_PANE: {
      const nextState = {
        ...state,
        savedViewSlug: action.payload.savedViewSlug,
        isOpen: true as const,
      }

      return nextState
    }

    default: {
      return state
    }
  }
}
