/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
// ---------------------------------------------------------------------------
import { Preference } from '@/codecs'
import { ActionTypes, PreferencesActions } from '@/store/actions'

// ---------------------------------------------------------------------------

const initialState: Preference[] = []

const PreferencesReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case PreferencesActions.TypeKeys.PREFERENCES_UPDATE:
      return action.payload

    default:
      return state
  }
}

export type State = ReturnType<typeof Reducer>
export const Reducer = PreferencesReducer
