/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Store from '@/store'

// ---------------------------------------------------------------------------

const initialState = {}

export type State = ReturnType<typeof Reducer>
export const Reducer = (state = initialState, _action: Store.ActionTypes) => {
  return state
}
