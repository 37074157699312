/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { __VERSION_1__, __VERSION_2__, __VERSION_3__, __VERSION_4__ } from './reports-codecs.utils'

export const __REPORT_VERSION_1__ = __VERSION_1__
export const __REPORT_VERSION_2__ = __VERSION_2__
export const __REPORT_VERSION_3__ = __VERSION_3__
export const __REPORT_VERSION_4__ = __VERSION_4__

export * from './asa-report.codecs'
export * from './exec-summary-report.codecs'
export * from './reports.codecs'
export { ReportType } from './reports-codecs.utils'
