/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'

import { withFeaturesAndPermissions, withFeaturesAndPermissionsAndState, withStaticFlags } from '@/hocs/gate'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

type ProtectedViewProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledRender?: (props: RouteComponentProps<any>) => React.ReactNode
  isEnabled: boolean
  redirect?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (props: RouteComponentProps<any>) => React.ReactNode
} & RouteProps

export const ProtectedView: React.FC<ProtectedViewProps> = (props) => {
  const { disabledRender, isEnabled, redirect, render, ...rest } = props

  return (
    <Route
      {...rest}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render={(props: RouteComponentProps<any>) => {
        if (isEnabled) {
          return render({ ...props })
        } else if (isNotNil(disabledRender)) {
          return disabledRender({ ...props })
        } else {
          return <Redirect to={isNotNil(redirect) ? redirect : '/dashboard'} />
        }
      }}
    />
  )
}

export const FPGateProtectedRoute = withFeaturesAndPermissions(ProtectedView)
export const FPStateProtectedRoute = withFeaturesAndPermissionsAndState(ProtectedView)
export const StaticFlagProtectedRoute = withStaticFlags(ProtectedView)
