/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/* eslint-disable @typescript-eslint/no-use-before-define */
import { isNotNilOrEmpty } from '@randori/rootkit'
import * as Catch from 'redux-saga-try-catch'
import { call, put, select, spawn, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import { MiddlewaresIO } from '@/store/store.utils'

// ---------------------------------------------------------------------------

export function* watchSpec(io: MiddlewaresIO) {
  yield* takeEvery(Store.GlobalActions.TypeKeys.OPENAPI_FETCH, Catch.deferredAction(_OPENAPI_FETCH, io))
}

export function* _OPENAPI_FETCH(io: MiddlewaresIO) {
  const cachedSpec = yield* select(Store.SessionSelectors.selectOpenApiSpec)

  if (isNotNilOrEmpty(cachedSpec)) {
    yield* call(io.stdout, 'RandApiSpec is cached')

    yield* spawn(refetchApiSpec, io)

    return cachedSpec
  } else {
    yield* call(io.stdout, 'RandApiSpec is not cached - fetching...')

    const freshSpec = yield* call(io.api.spec.getApiSpec)

    yield* put(Store.GlobalActions.OPENAPI_SET(freshSpec))

    yield* put(Store.UIActions.MAP_API_SPEC_TO_TABLE_COLUMNS())

    return freshSpec
  }
}

export function* refetchApiSpec(io: MiddlewaresIO) {
  try {
    yield* call(io.stdout, 'refreshing RandApiSpec')

    const result = yield* call(io.api.spec.getApiSpec)

    yield* put(Store.GlobalActions.OPENAPI_SET(result))

    yield* put(Store.UIActions.MAP_API_SPEC_TO_TABLE_COLUMNS())
  } catch (e) {
    yield* call(io.stdout, 'refetchApiSpec', e)
  }
}
