/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { combineReducers } from 'redux'

import * as ActivityConfiguration from './activity-configuration.reducers'
import * as ActivityInstance from './activity-instance.reducers'
import * as ApplicableActivity from './applicable-activity.reducers'
import * as ApplicableEntity from './applicable-entity.reducers'
import * as ArtifactForActivityInstance from './artifact-for-activity-instance.reducers'
import * as EntityForActivityInstance from './entity-for-activity-instance.reducers'

// ---------------------------------------------------------------------------

export type State = ReturnType<typeof Reducer>
export const Reducer = combineReducers({
  // activity configuration
  activityConfiguration: ActivityConfiguration.ActivityConfigurationReducer,
  activityConfigurationPagination: ActivityConfiguration.ActivityConfigurationsPaginationReducer,
  activityConfigurationTotals: ActivityConfiguration.ActivityConfigurationsTotalsReducer,

  // activity instance
  activityInstance: ActivityInstance.ActivityInstanceReducer,
  activityInstancePagination: ActivityInstance.ActivitiesPaginationReducer,
  activityInstanceTotals: ActivityInstance.ActivityInstancesTotalsReducer,
  entityForActivityInstance: EntityForActivityInstance.EntityForActivityInstanceReducer,
  entityForActivityInstancePagination: EntityForActivityInstance.EntityForActivityInstancePaginationReducer,
  artifactForActivityInstance: ArtifactForActivityInstance.ArtifactForActivityInstanceReducer,
  artifactForActivityInstancePagination: ArtifactForActivityInstance.ArtifactForActivityInstancePaginationReducer,

  // applicable activity
  applicableActivity: ApplicableActivity.ApplicableActivitiesReducer,
  applicableActivityPagination: ApplicableActivity.ApplicableActivitiesPaginationReducer,

  // applicable entity
  applicableEntity: ApplicableEntity.ApplicableEntitiesReducer,
})

export const Test = {}
