/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as _ActivityActions from './activity'
import * as _ArtifactActions from './artifact'
import * as _AttackActions from './attack'
import * as _AuthActions from './auth'
import * as _CategoryActions from './category'
import * as _CommentActions from './comment'
import * as _EntityActions from './entity'
import * as _GlobalActions from './global'
import * as _HocActions from './hoc'
import * as _MitreActions from './mitre'
import * as _OrganizationActions from './organization'
import * as _PathActions from './path'
import * as _PreferencesActions from './preferences'
import * as _ReconActions from './recon'
import * as _ReportActions from './report'
import * as _SessionActions from './session'
import * as _StatActions from './stat'
import * as _TagsActions from './tags'
import * as _UIActions from './ui'
import * as _UserActions from './user'
import * as _WorkatoActions from './workato'

export type ActionTypes =
  | _ActivityActions.StateActionTypes
  | _ArtifactActions.StateActionTypes
  | _AttackActions.StateActionTypes
  | _AuthActions.ActionTypes
  | _CategoryActions.StateActionTypes
  | _CommentActions.ActionTypes
  // | _EntityActions.StateActionTypes
  | _GlobalActions.ActionTypes
  | _HocActions.ActionTypes
  | _MitreActions.ActionTypes
  | _OrganizationActions.ActionTypes
  | _PathActions.ActionTypes
  | _PreferencesActions.ActionTypes
  | _ReconActions.StateActionTypes
  | _ReportActions.ActionTypes
  | _SessionActions.ActionTypes
  | _TagsActions.ActionTypes
  | _UIActions.StateActionTypes
  | _UserActions.ActionTypes
  | _WorkatoActions.StateActionTypes

export const ActivityActions = _ActivityActions
export const ArtifactActions = _ArtifactActions
export const AttackActions = _AttackActions
export const AuthActions = _AuthActions
export const CategoryActions = _CategoryActions
export const CommentActions = _CommentActions
export const EntityActions = _EntityActions
export const GlobalActions = _GlobalActions
export const HocActions = _HocActions
export const MitreActions = _MitreActions
export const OrganizationActions = _OrganizationActions
export const PathActions = _PathActions
export const PreferencesActions = _PreferencesActions
export const ReconActions = _ReconActions
export const ReportActions = _ReportActions
export const SessionActions = _SessionActions
export const StatActions = _StatActions
export const TagsActions = _TagsActions
export const UIActions = _UIActions
export const UserActions = _UserActions
export const WorkatoActions = _WorkatoActions
