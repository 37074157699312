/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { call, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _StatActions from '@/store/actions/stat/stat.actions'
import { MiddlewaresIO } from '@/store/store.utils'

// Root watcher
// ---------------------------------------------------------------------------

export function* watch(io: MiddlewaresIO) {
  yield takeEvery(Store.StatActions.TypeKeys.STAT_FETCH, Catch.deferredAction(_STAT_FETCH as any, io))
}

// ---------------------------------------------------------------------------

export function* _STAT_FETCH(io: MiddlewaresIO, action: _StatActions.STAT_FETCH) {
  const response = yield* call(io.api.recon.getStatistics, action.payload)

  return response
}
