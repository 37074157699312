/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { nth } from 'lodash/fp'

// ---------------------------------------------------------------------------
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

export function isRandori(username?: string) {
  if (isNotNil(username)) {
    const segments = username.split('@')

    // eslint-disable-next-line no-magic-numbers
    const isRandori = nth(1, segments) === 'randori.com'

    return isRandori
  } else {
    return false
  }
}
