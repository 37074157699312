/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'
import debug from 'debug'
import { find } from 'lodash/fp'

import { TargetP } from '@/codecs'
import type { AppState } from '@/store'
import { DoubleUUID, TripleUUID, UUID } from '@/utilities/codec'
import { EntityType } from '@/utilities/r-entity'

import { makeSelectPagination, makeSelectTotals } from '../utilities/make-selectors'
import { EntityName } from './target.constants'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:target.selectors')
export const selectTargetPagination = makeSelectPagination(EntityName)
export const selectTargetTotals = makeSelectTotals(EntityName)

export const selectTargets = createSelector([(state: AppState) => state.target], (state) => {
  const orderedEntities = state.result.map((uuid) => state.entities.target[uuid])

  return orderedEntities
})

export const selectTargetById = createSelector(
  [
    (state: AppState, _props: { id: DoubleUUID }) => state.target.entities,
    (_state: AppState, props: { id: DoubleUUID }) => props.id,
  ],
  (entities, uuid) => {
    const entity = entities.target[uuid]

    return entity
  },
)

export const selectTargetByTargetId = createSelector(
  [(state: AppState) => state.target, (_state: AppState, props: { id: UUID }) => props.id],
  (targets, uuid) => {
    const target = find((target) => target.target_id === uuid, targets.entities.target)

    // @NOTE: target is nullable, but we need to short-circuit this behavior to line up with other selectByIds
    return target as TargetP
  },
)

type Allowed = 'hostname' | 'ip' | 'network' | 'service'
const isAllowed = (type: EntityType): type is Allowed => {
  const allowed = ['hostname', 'ip', 'network', 'service']

  return allowed.includes(type)
}

export const selectTargetsForEntityById = createSelector(
  [
    (state: AppState) => state.target,
    (_state: AppState, props: { id: TripleUUID | UUID }) => props.id,
    (_state: AppState, props: { type: EntityType }) => props.type,
  ],
  (state, id, type) => {
    try {
      if (isAllowed(type)) {
        const orderedTargetsForEntity = state.byEntity[type][id].result.map((uuid) => state.entities.target[uuid])

        return orderedTargetsForEntity
      }

      log(`selectTargetsForEntityById - unsupported entity: ${type}`)

      return []
    } catch (err) {
      return []
    }
  },
)

export const selectTargetsPaginationForEntityById = createSelector(
  [
    (state: AppState) => state.target,
    (_state: AppState, props: { id: TripleUUID | UUID }) => props.id,
    (_state: AppState, props: { type: EntityType }) => props.type,
  ],
  (state, id, type) => {
    try {
      if (isAllowed(type)) {
        return state.byEntity[type][id].pagination
      }

      log(`selectTargetsForEntityById - unsupported entity: ${type}`)

      return { count: 0, offset: 0, total: 0 }
    } catch (err) {
      return { count: 0, offset: 0, total: 0 }
    }
  },
)
