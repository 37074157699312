/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const Preference = t.interface({
  can_override: t.union([t.boolean, t.null]),
  preference: t.union([t.string, t.null]),
  preference_source: t.union([t.string, t.null]),
  value: t.unknown,
})

type _Preference = t.TypeOf<typeof Preference>
export type Preference = _Preference

export const PreferencePostPayload = t.interface({
  can_override: t.union([t.boolean, t.null]),
  name: t.string,
  org_uuid: t.string,
  user_uuid: t.string,
  remove_preference: t.boolean,
  value: t.unknown,
})

type _PreferencePostPayload = t.TypeOf<typeof PreferencePostPayload>
export type PreferencePostPayload = _PreferencePostPayload
