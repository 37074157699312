/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNilOrEmpty } from '@randori/rootkit'

import * as Codecs from '@/codecs'
import { EntityDetailChildNavigationItem } from '@/store/reducers/ui/entity-detail.reducers'

// ---------------------------------------------------------------------------

export const getGuidanceContent = (entity: Codecs.Entity) => {
  const guidance: EntityDetailChildNavigationItem[] = []

  if ('tech_category' in entity && isNotNilOrEmpty(entity.tech_category)) {
    entity.tech_category.forEach((category) =>
      guidance.push({
        id: category.replaceAll(' ', '-'),
        label: category,
      }),
    )
  }

  if ('characteristic_tags' in entity && isNotNilOrEmpty(entity.characteristic_tags)) {
    entity.characteristic_tags.forEach((tag) => {
      guidance.push({
        id: tag.replaceAll(' ', '-'),
        label: tag,
      })
    })
  }

  return guidance
}
