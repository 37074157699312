/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { RouteComponentProps } from 'react-router-dom'
import * as Catch from 'redux-saga-try-catch'

import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export type StateActionTypes = ACTIVITY_STORE_UPDATE | ACTIVITIES_STORE_UPDATE | ACTIVITIES_TOTAL_STORE_UPDATE

type Deferred = Catch.DeferredAction['meta']['deferred']

export enum TypeKeys {
  ACTIVITIES_FETCH = 'ACTIVITIES_FETCH',
  ACTIVITIES_STORE_UPDATE = 'ACTIVITIES_STORE_UPDATE',
  ACTIVITIES_TOTAL_COUNT_FETCH = 'ACTIVITY_TOTAL_COUNT_FETCH',
  ACTIVITY_FETCH = 'ACTIVITY_FETCH',
  ACTIVITY_STORE_UPDATE = 'ACTIVITY_STORE_UPDATE',
  ACTIVITIES_TOTAL_STORE_UPDATE = 'ACTIVITIES_TOTAL_STORE_UPDATE',
}

export type ACTIVITY_FETCH = {
  type: TypeKeys.ACTIVITY_FETCH
  meta: { deferred: Deferred }
  payload: string
}

export function ACTIVITY_FETCH(payload: ACTIVITY_FETCH['payload'], deferred: Deferred): ACTIVITY_FETCH {
  return {
    type: TypeKeys.ACTIVITY_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ACTIVITIES_FETCH = {
  type: TypeKeys.ACTIVITIES_FETCH
  meta: { deferred: Deferred }
  payload: RouteComponentProps['location']['search']
}

export function ACTIVITIES_FETCH(payload: ACTIVITIES_FETCH['payload'], deferred: Deferred): ACTIVITIES_FETCH {
  return {
    type: TypeKeys.ACTIVITIES_FETCH,
    meta: { deferred },
    payload,
  }
}

export type ACTIVITY_TOTAL_COUNT_FETCH = {
  type: TypeKeys.ACTIVITIES_TOTAL_COUNT_FETCH
  meta: { deferred: Deferred; updateStore: boolean }
  payload: RouteComponentProps['location']['search']
}

export function ACTIVITY_TOTAL_COUNT_FETCH(
  payload: ACTIVITY_TOTAL_COUNT_FETCH['payload'],
  deferred: Deferred,
  updateStore = false,
): ACTIVITY_TOTAL_COUNT_FETCH {
  return {
    type: TypeKeys.ACTIVITIES_TOTAL_COUNT_FETCH,
    meta: { deferred, updateStore },
    payload,
  }
}

// ---------------------------------------------------------------------------

export type ACTIVITY_STORE_UPDATE = {
  type: TypeKeys.ACTIVITY_STORE_UPDATE
  payload: Codecs.ActivityInstance
}

export function ACTIVITY_STORE_UPDATE(payload: ACTIVITY_STORE_UPDATE['payload']): ACTIVITY_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIVITY_STORE_UPDATE,
    payload,
  }
}

export type ACTIVITIES_STORE_UPDATE = {
  type: TypeKeys.ACTIVITIES_STORE_UPDATE
  payload: Codecs.ActivityInstancesResponse
}

export function ACTIVITIES_STORE_UPDATE(payload: ACTIVITIES_STORE_UPDATE['payload']): ACTIVITIES_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIVITIES_STORE_UPDATE,
    payload,
  }
}

export type ACTIVITIES_TOTAL_STORE_UPDATE = {
  type: TypeKeys.ACTIVITIES_TOTAL_STORE_UPDATE
  payload: Codecs.EntityTotals
}
export function ACTIVITIES_TOTAL_STORE_UPDATE(
  payload: ACTIVITIES_TOTAL_STORE_UPDATE['payload'],
): ACTIVITIES_TOTAL_STORE_UPDATE {
  return {
    type: TypeKeys.ACTIVITIES_TOTAL_STORE_UPDATE,
    payload,
  }
}
