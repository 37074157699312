/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const IpsReducer = (state = Schemas.Recon.NormalizeIps([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.IPS_STORE_UPDATE: {
      const { entities, result } = Schemas.Recon.NormalizeIps(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          ip: {
            ...state.entities.ip,
            ...entities.ip,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.IP_STORE_UPDATE: {
      return {
        result: state.result,
        entities: {
          ...state.entities,
          ip: {
            ...state.entities.ip,
            [action.payload.id]: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}

const IpsTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const IpsTotalsReducer = (state = IpsTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.IP_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const IpsPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const IpsPaginationReducer = (state = IpsPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.IPS_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
