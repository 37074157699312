/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { nullableP } from '@/utilities/codec'

import { ImpactScore } from './impact-score.codecs'
import { Status } from './status.codecs'

// ---------------------------------------------------------------------------

export type PriorityGroupsResponse = t.TypeOf<typeof PriorityGroupsResponse>
export const PriorityGroupsResponse = t.type({
  prio_counts: t.array(t.type({ prio_range_name: t.string, total: t.number })),
  total_in_ranges: t.number,
  total_other: t.number,
})

export type PriorityGroupsBody = {
  entity_type: string
  min_first_seen: string
  prio_ranges: { prio_max: number; prio_min: number; prio_range_name: string }[]
}

export type Priortity = t.TypeOf<typeof Priortity>
export const Priortity = t.type({
  impact_score: ImpactScore,
  priority_impact_factor: nullableP(t.number),
  priority_score: nullableP(t.number),
  priority_status_factor: nullableP(t.number),
  priority_tags_factor: nullableP(t.number),
  status: nullableP(Status),
  target_temptation: nullableP(t.number),
})

export type WithPriority = t.TypeOf<typeof WithPriority>
export const WithPriority = Priortity

export function withPriority<T extends t.Mixed>(type: T) {
  return t.intersection([type, Priortity])
}
