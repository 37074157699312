/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { Blueprint } from './blueprint.codecs'

// ---------------------------------------------------------------------------

const _BarApprovalState = t.type({ approved: t.union([t.boolean, t.undefined]) })
// const _BarApprovalStateRequired = t.interface({ approval: t.boolean })
const _StringIndexedRecord = t.record(t.string, t.any)

const BarPayload = t.intersection([_StringIndexedRecord, _BarApprovalState])
type BarPayload = t.TypeOf<typeof BarPayload>

const BarDest = t.string
type BarDest = t.TypeOf<typeof BarDest>

export const BarState = t.keyof({
  new: null,
  received: null,
})
export type BarState = t.TypeOf<typeof BarState>

const BarType = t.keyof({
  bar: null,
  bar_resp: null,
  entity: null,
  status_request: null,
})
type BarType = t.TypeOf<typeof BarType>

export const ThinBar = t.type({
  id: t.string,
  created: t.string, // @TODO: iso string
  dest: BarDest,
  org_id: t.string,
  payload: BarPayload,
  src: t.string,
  state: BarState,
  type: BarType,
  updated: t.string, // @TODO: iso string
})
export type ThinBar = t.TypeOf<typeof ThinBar>

const BarAnnotations = t.type({
  process_definition_id: t.string,
  process_name: Blueprint.props.name,
  process_parameters: Blueprint.props.parameters,
  process_runbook_definition_id: Blueprint.props.runbook_definition_id,
  process_trigger_data: Blueprint.props.trigger_data,
  process_trigger_type: Blueprint.props.trigger_type,
  process_type: Blueprint.props.type,
})
export const Bar = t.intersection([BarAnnotations, ThinBar])
export type Bar = t.TypeOf<typeof Bar>

export const BarsResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(Bar),
})
export type BarsResponse = t.TypeOf<typeof BarsResponse>

export const BarPatchPayload = t.type({
  data: t.type({
    state: BarState, // enum
  }),
})
export type BarPatchPayload = t.TypeOf<typeof BarPatchPayload>

export const BarPatchResponse = t.type({
  data: ThinBar,
})
export type BarPatchResponse = t.TypeOf<typeof BarPatchResponse>

const NewEvent = t.type(CodecUtils.omit(ThinBar.props, 'id'))

export const BarsPostPayload = t.type({
  data: t.array(NewEvent),
})
export type BarsPostPayload = t.TypeOf<typeof BarsPostPayload>

export const BarsPostResponse = t.type({
  ids: t.array(t.string),
})
export type BarsPostResponse = t.TypeOf<typeof BarsPostResponse>

export const AckEvent = t.type({
  id: t.string,
  approved: t.boolean,
})
export type AckEvent = t.TypeOf<typeof AckEvent>

export const AckEvents = t.type({
  ack_events: t.array(AckEvent),
})
export type AckEvents = t.TypeOf<typeof AckEvents>
