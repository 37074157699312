/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DetectionV2 as Detection } from '@/codecs/detection.codecs'
import type { HttpClient } from '@/http/http.utils'
import { QueryString, rflaskGetMany, rflaskGetSingle, throwValidate, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type GetDetections = t.TypeOf<typeof GetDetections>
export const GetDetections = rflaskGetMany(Detection)

export type GetDetection = t.TypeOf<typeof GetDetection>
export const GetDetection = rflaskGetSingle(Detection)

export type DetectionApi = {
  getDetections: (query: QueryString | '') => Promise<GetDetections>
  getDetection: (id: UUID) => Promise<GetDetection>
}

export function detectionApiFactory(http: HttpClient): DetectionApi {
  const baseUrl = '/recon/api/v2/detection'

  return {
    getDetections(query) {
      return http.get(`${baseUrl}${query}`).then(throwValidate(GetDetections, 'GetDetections'))
    },

    getDetection(id) {
      return http.get(`${baseUrl}/${id}`).then(throwValidate(GetDetection, 'GetDetection'))
    },
  }
}
