/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Loader, RenderEffect } from '@randori/rootkit'
import debug from 'debug'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { match, Redirect, Route, RouteComponentProps, RouteProps, useLocation, useRouteMatch } from 'react-router-dom'

import * as Codecs from '@/codecs'
import * as Store from '@/store'
import { isNotNil } from '@/utilities/is-not'

// ---------------------------------------------------------------------------

const log = debug('RANDORI:org-protected')

type OrgProtectedRouteViewProps = {
  render: (props: RouteComponentProps) => React.ReactNode
} & RouteProps

const OrgProtectedRouteView: React.FunctionComponent<OrgProtectedRouteViewProps> = (props) => {
  const currAuth = useSelector(Store.SessionSelectors.selectAuthorization)
  const currMatch = useRouteMatch<{ orgname: string }>('/:orgname')

  const selectOrgByShortname = useSelector(Store.OrganizationSelectors.makeSelectOrgByShortname)

  if (!isNotNil(currMatch) || !isNotNil(currAuth)) {
    return <Redirect to="/login" />
  }

  const nextOrg = selectOrgByShortname(currMatch.params.orgname)

  if (!isNotNil(nextOrg)) {
    return <Redirect to="/login" />
  }

  const nextViewOrg = nextOrg.id

  const { render, ...restProps } = props

  const nextProps = {
    currAuth,
    currMatch,
    nextOrg,
    nextViewOrg,
    render,
    routeProps: restProps,
  }

  return <OrgProtectedRouteViewRender {...nextProps} />
}

export const OrgProtectedRoute = OrgProtectedRouteView

// ---------------------------------------------------------------------------

type OrgProtectedRouteViewRenderProps = {
  currAuth: NonNullable<ReturnType<typeof Store.SessionSelectors.selectAuthorization>>
  currMatch: match<{ orgname: string }>
  nextOrg: Codecs.Organization
  nextViewOrg: string
  render: OrgProtectedRouteViewProps['render']
  routeProps: RouteProps
}

const OrgProtectedRouteViewRender: React.FunctionComponent<OrgProtectedRouteViewRenderProps> = (props) => {
  const currLoc = useLocation()
  const dispatch = useDispatch()

  const _hydrateOrgAndUser = React.useCallback(() => {
    if (isNotNil(props.nextOrg.name)) {
      log(`hydrateOrgAndUser: retrieving org - ${props.nextOrg.name}`)
    } else {
      log('hydrateOrgAndUser: retrieving org - name is null')
    }

    return new Promise((resolve, reject) => {
      const payload = {
        currAuth: props.currAuth,
        nextOrg: props.nextViewOrg,
        nextPath: `${currLoc.pathname}${currLoc.search}`,
        renew: true,
      }

      dispatch(Store.AuthActions.VALIDATE_ORG_USER(payload, { success: resolve, failure: reject }))
    })
  }, [props.currAuth, currLoc.pathname, currLoc.search, dispatch, props.nextOrg.name, props.nextViewOrg])

  const _hydrateOrg = React.useCallback(() => {
    if (isNotNil(props.nextOrg.name)) {
      log(`hydrateOrg: retrieving org - ${props.nextOrg.name}`)
    } else {
      log('hydrateOrg: retrieving org - name is null')
    }

    return new Promise((resolve, reject) => {
      const payload = {
        org: props.nextViewOrg,
      }

      dispatch(Store.AuthActions.ORG_INIT(payload, { success: resolve, failure: reject }))
    })
  }, [dispatch, props.nextOrg.name, props.nextViewOrg])

  // @TODO: isPathDifferent is probably always false, making it useless
  const isPathDifferent = props.nextOrg.shortname !== props.currMatch.params.orgname
  const isAuthDifferent = props.nextOrg.shortname !== props.currAuth.shortname

  log('hydrate: isPathDifferent', isPathDifferent)
  log('hydrate: isAuthDifferent', isAuthDifferent)

  if (isPathDifferent || isAuthDifferent) {
    return (
      <RenderEffect
        effect={_hydrateOrgAndUser}
        renderLoading={() => <Loader addlClasses="page-loader" />}
        render={() => (
          <Route {...props.routeProps} render={(rProps: RouteComponentProps) => props.render({ ...rProps })} />
        )}
      />
    )
  } else {
    return (
      <RenderEffect
        effect={_hydrateOrg}
        renderLoading={() => <Loader addlClasses="page-loader" />}
        render={() => (
          <Route {...props.routeProps} render={(rProps: RouteComponentProps) => props.render({ ...rProps })} />
        )}
      />
    )
  }
}
