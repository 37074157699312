/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import * as t from 'io-ts'

import { get, nullable, UUID } from '@/utilities/codec'

// ---------------------------------------------------------------------------

type Mungable = {
  lens_id?: string | null
  org_id?: string | null
  perspective_id?: string | null
  service_id?: string | null
}

export const mungeServiceId = (record: Mungable) => {
  const validator = t.type({
    lens_id: nullable(UUID),
    org_id: nullable(UUID),
    perspective_id: nullable(UUID),
    service_id: nullable(UUID),
  })
  const validated = get(record, validator)

  if (isNotNil(validated.lens_id)) {
    return `${validated.service_id},${validated.org_id},${validated.lens_id}`
  } else {
    return `${validated.service_id},${validated.org_id},${validated.perspective_id}`
  }
}

export function getServiceId(id: string) {
  const [serviceId, _orgId, _lensId] = id.split(',')

  return serviceId
}

export function getLensId(id: string) {
  const [_serviceId, _orgId, _lensId] = id.split(',')

  const lensId = isNotNil(_lensId) ? _lensId : null

  return lensId
}
