/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import retry from 'fetch-retry'
import { isNil } from 'lodash/fp'

import * as Logger from '@/utilities/logger'

import { is429 } from './is-429'

// ---------------------------------------------------------------------------

export const retryFetch = (
  method: RequestInit['method'],
  fetch: typeof window.fetch,
  retries = 9,
  decorate = retry,
) => {
  return decorate(fetch, {
    retryDelay,
    retryOn: retryOn(method, retries),
  })
}

export const retryDelay = (attempt: number, _error: Error | null, _response: Response | null) => {
  return Math.pow(2, attempt) * 1000
}

export const retryOn =
  (method: RequestInit['method'], retries: number) =>
  (attempt: number, _error: Error | null, response: { status: number } | null) => {
    if (attempt >= retries) {
      return false
    }

    if (isNil(response)) {
      return false
    }

    const httpError = {
      response,

      config: {
        method,
      },
    }

    if (is429(httpError)) {
      Logger.log('429')
    }

    return is429(httpError)
  }
