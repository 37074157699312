/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import ipRegex from 'ip-regex'
import { isNil, isNumber } from 'lodash/fp'

import * as Codecs from '@/codecs'
import * as Logger from '@/utilities/logger'

// ---------------------------------------------------------------------------

type Bag = {
  domain: ReturnType<typeof getDomainP>
  ip: ReturnType<typeof getIpAddressP>
  path: ReturnType<typeof getPathP>
  port: ReturnType<typeof getTcpPortP>
  scheme: ReturnType<typeof getSchemeP>
}

function getSchemaBag(detectionCriteria: Codecs.DetectionTarget['detection_criteria']): Bag {
  return {
    domain: getDomainP(detectionCriteria),
    ip: getIpAddressP(detectionCriteria),
    port: getTcpPortP(detectionCriteria),
    scheme: getSchemeP(detectionCriteria),
    path: getPathP(detectionCriteria),
  }
}

export function getSchemeP(dc: Codecs.DetectionTarget['detection_criteria']) {
  if (isNotNil(dc.tls)) {
    return 'https'
  }

  if (isNotNil(dc.http)) {
    return 'http'
  }

  return null
}

export function getDomainP(dc: Codecs.DetectionTarget['detection_criteria']) {
  return dc.http?.host ?? null
}

export function getPathP(dc: Codecs.DetectionTarget['detection_criteria']) {
  return dc.http?.path ?? null
}

export function getIpAddressP(dc: Codecs.DetectionTarget['detection_criteria']) {
  return dc.ip?.address ?? null
}

export function getTcpPortP(dc: Codecs.DetectionTarget['detection_criteria']) {
  return dc.tcp?.port ?? null
}

const isDomain = ({ domain, path, port, scheme }: Bag) => {
  const hasDomain = isNotNil(domain)
  const hasPath = isNotNil(path)
  const hasPort = isNumber(port)
  const hasScheme = isNotNil(scheme)

  return hasDomain && hasPath && hasPort && hasScheme
}

const isIpv4 = ({ path, port, scheme, ip }: Bag) => {
  const hasIpv4 = ipRegex.v4({ exact: false }).test(ip ?? '')
  const hasPath = isNotNil(path)
  const hasPort = isNumber(port)
  const hasScheme = isNotNil(scheme)

  return hasIpv4 && hasPath && hasPort && hasScheme
}

const isIpv6 = ({ path, port, scheme, ip }: Bag) => {
  const hasIpv6 = ipRegex.v6({ exact: false }).test(ip ?? '')
  const hasPath = isNotNil(path)
  const hasPort = isNumber(port)
  const hasScheme = isNotNil(scheme)

  return hasIpv6 && hasPath && hasPort && hasScheme
}

export function getUrlP(bag: Bag) {
  const { scheme, domain, path, ip, port } = bag

  if (isDomain(bag)) {
    try {
      if (isNil(scheme) || isNil(domain) || isNil(path) || isNil(port)) {
        throw new Error('getUrlP missing values')
      }

      const url = new URL(`${scheme}${'://'}${domain}:${port}${path}`)

      return url
    } catch (e) {
      if (e instanceof Error) {
        Logger.error(e)
      }
      return null
    }
  }

  if (isIpv4(bag)) {
    try {
      if (isNil(scheme) || isNil(ip) || isNil(path) || isNil(port)) {
        throw new Error('getUrlP missing values')
      }

      const url = new URL(`${scheme}${'://'}${ip}:${port}${path}`)

      return url
    } catch (e) {
      if (e instanceof Error) {
        Logger.error(e)
      }
      return null
    }
  }

  if (isIpv6(bag)) {
    try {
      if (isNil(scheme) || isNil(ip) || isNil(path) || isNil(port)) {
        throw new Error('getUrlP missing values')
      }

      const url = new URL(`${scheme}${'://'}[${ip}]:${port}${path}`)

      return url
    } catch (e) {
      if (e instanceof Error) {
        Logger.error(e)
      }
      return null
    }
  }

  return null
}

export function getSchemaFromDetectionP(
  detectionCriteria: Codecs.DetectionTarget['detection_criteria'],
): Bag & { url: ReturnType<typeof getUrlP> } {
  const bag = getSchemaBag(detectionCriteria)

  const url = getUrlP(bag)

  return {
    ...bag,
    url,
  }
}
