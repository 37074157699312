/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { deferredAction } from 'redux-saga-try-catch'
import { call, put, takeEvery } from 'typed-redux-saga/macro'

import type { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString, TripleUUID } from '@/utilities/codec'

import * as actions from './service.actions'
import { normalizeServices } from './service.schema'
import { serviceSlice } from './service.slice'

// ---------------------------------------------------------------------------

export function* serviceSagasRoot(io: MiddlewaresIO) {
  yield takeEvery(actions.fetchService.toString(), deferredAction(_fetchService, io))
  yield takeEvery(actions.fetchServiceTotals.toString(), deferredAction(_fetchServiceTotals, io))
  yield takeEvery(actions.fetchServices.toString(), deferredAction(_fetchServices, io))
}

export function* _fetchServices(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchServices>) {
  const queryString = get(action.payload, t.union([QueryString, t.literal('')]))

  const response = yield* call(io.api.service.getServices, queryString)

  if (action.meta.persist) {
    yield* put(serviceSlice.actions.replaceServices(normalizeServices(response.data)))

    yield* put(
      serviceSlice.actions.replaceServicePagination({
        total: response.total,
        offset: response.offset,
        count: response.count,
      }),
    )
  }

  return response
}

export function* _fetchService(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchService>) {
  const id = get(action.payload, TripleUUID)

  const { data } = yield* call(io.api.service.getService, id)

  yield* put(serviceSlice.actions.upsertService(data))

  return data
}

export function* _fetchServiceTotals(io: MiddlewaresIO, action: ReturnType<typeof actions.fetchServiceTotals>) {
  const { total } = yield* call(io.api.service.getServices, get(`?${action.payload}`, QueryString))

  const totals = {
    unfiltered: total,
    unaffiliated: 0,
  }

  yield* put(serviceSlice.actions.replaceServiceTotals(totals))

  return {
    total: totals.unfiltered,
    unaffiliatedTotal: totals.unaffiliated,
  }
}
