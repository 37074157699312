/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

export const SavedViews = t.type({
  created_at: t.string,
  creator_user_name: t.string,
  description: t.string,
  edited_at: t.string,
  editor_user_name: t.string,
  entity_type: t.string,
  filter_data: t.type({ q: t.string }),
  id: t.string,
  is_favorite: t.boolean,
  is_global: t.boolean,
  name: t.string,
  // backend hacks sending org_id in GETs but not in POST/ PATCH responses.
  org_id: t.union([t.null, t.string]),
  sort_data: t.type({
    // @TODO: this should be t.unknown
    sort: t.union([t.undefined, t.string, t.array(t.string)]), // sort query param, string | string[] | null
  }),
})

type _SavedViews = t.TypeOf<typeof SavedViews>
export type SavedViews = _SavedViews

export const SavedViewsResponse = t.type({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(SavedViews),
})

export type SavedViewsResponse = t.TypeOf<typeof SavedViewsResponse>

export const SavedViewsPostPayload = t.type({
  data: t.array(
    t.type({
      description: t.string,
      entity_type: t.string,
      filter_data: t.type({}),
      name: t.string,
      sort_data: t.type({}),
    }),
  ),
})

export type SavedViewsPostPayload = t.TypeOf<typeof SavedViewsPostPayload>

export const SavedViewsPostResponse = t.type({
  ids: t.array(t.string),
})

export type SavedViewsPostResponse = t.TypeOf<typeof SavedViewsPostResponse>

export const SavedViewsUpdateResponse = t.type({
  data: SavedViews,
})

export type SavedViewsUpdateResponse = t.TypeOf<typeof SavedViewsUpdateResponse>

export const SavedViewsPatchPayload = t.type({
  data: t.type({
    filter_data: t.type({}),
    name: t.string,
    sort_data: t.type({}),
  }),
})

export type SavedViewsPatchPayload = t.TypeOf<typeof SavedViewsPatchPayload>
