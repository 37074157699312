/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Actions from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const OrganizationApiTokensReducer = (
  state = Schemas.OrganizationSchemas.NormalizeApiTokens([]),
  action: Actions.ActionTypes,
) => {
  switch (action.type) {
    case Actions.AuthActions.TypeKeys.API_TOKENS_UPDATE:
      return Schemas.OrganizationSchemas.NormalizeApiTokens(action.payload)

    default:
      return state
  }
}
