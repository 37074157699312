/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import ipRegex from 'ip-regex'

export interface IPv4Brand {
  readonly IPv4: unique symbol
}
export type IPv4 = t.Branded<string, IPv4Brand>
export const IPv4 = t.brand(t.string, (s): s is IPv4 => ipRegex.v4({ exact: true }).test(s), 'IPv4')

export interface IPv6Brand {
  readonly IPv6: unique symbol
}
export type IPv6 = t.Branded<string, IPv6Brand>
export const IPv6 = t.brand(t.string, (s): s is IPv6 => ipRegex.v6({ exact: true }).test(s), 'IPv6')

// @TODO: This should be a union
export interface IPBrand {
  readonly IP: unique symbol
}
export type IP = t.Branded<string, IPBrand>
export const IP = t.brand(t.string, (s): s is IP => ipRegex({ exact: true }).test(s), 'IP')
