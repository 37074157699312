/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { GateSelectors } from '@/store'
import { AvailableColumns, Spec } from '@/store/reducers/ui/entity-table.reducers'
import * as EntityUtils from '@/utilities/r-entity'
import * as RandApiSpecUtils from '@/utilities/randapispec'

// ---------------------------------------------------------------------------

export const getAvailableColumns = (config: {
  isInternalSupport: boolean
  schema: Record<string, Spec>
  staticFlags: ReturnType<typeof GateSelectors.staticFlags>
  tableId: EntityUtils.EntityTypesTableSummary
}) => {
  const { isInternalSupport, schema, staticFlags, tableId } = config

  const fields = Object.keys(schema)

  const _availableColumns = fields

  const getIsInternalField = RandApiSpecUtils.isInternalField(tableId, false, staticFlags)

  return _availableColumns.reduce((columns, fieldName) => {
    const isInternalField = getIsInternalField(fieldName)

    if (!isInternalField || isInternalSupport) {
      columns[fieldName] = {
        isEditable: isEditable(tableId, fieldName),
        isInternal: isInternalField,
        fieldName,
        spec: schema[fieldName],
      }
    }

    return columns
  }, {} as AvailableColumns)
}

/**
 * Seam to override some values being inherited from the DB
 *
 * @param tableId - the name of the table
 * @param fieldName - the name of the field
 * @returns whether or not the column should be configurable
 */
const isEditable = (_tableId: string, _fieldName: string) => {
  // @TODO: Add once we remove vendor-service-version column from /detections
  // if (tableId === 'detection_target' && fieldName === 'detection_criteria') {
  //   return false
  // }

  return true
}
