/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

/**
 * Converts a quantity of time in seconds to a string that represents
 * the time in a more readable format
 *
 * @param duration - a number representing seconds
 *
 * @returns a string that indicates the duration (s) in a relative, readable format
 */
export const convertSecondsToDuration = (duration: number) => {
  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const WEEK = DAY * 7
  const MONTH = DAY * 30
  const YEAR = DAY * 365

  // reasonably determine a displayable string going from smallest unit first
  if (duration < MINUTE) {
    return `${duration} seconds`
  } else if (duration < HOUR) {
    const minutes = Math.floor(duration / MINUTE)
    return minutes > 1 ? `${minutes} minutes` : `${minutes} minute`
  } else if (duration < DAY) {
    const hours = Math.floor(duration / HOUR)
    return hours > 1 ? `${hours} hours` : `${hours} hour`
  } else if (duration < WEEK) {
    const days = Math.floor(duration / DAY)
    return days > 1 ? `${days} days` : `${days} day`
  } else if (duration < MONTH) {
    const weeks = Math.floor(duration / WEEK)
    return weeks > 1 ? `${weeks} weeks` : `${weeks} week`
  } else if (duration < YEAR) {
    const months = Math.floor(duration / MONTH)
    return months > 1 ? `${months} months` : `${months} month`
  } else {
    // greater than a year
    const years = Math.floor(duration / YEAR)
    return years > 1 ? `${years} years` : `${years} year`
  }
}
