/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'
import { equals } from 'ramda'

import {
  ActivityConfigurationPatchResponse,
  ActivityConfigurationResponse,
  ActivityConfigurationsResponse,
  ActivityInstance,
  ApplicableActivitiesResponse,
  ApplicableEntitiesResponse,
  ArtifactForActivityInstanceResponse,
  AuthoritativeEntityType,
  EntityForActivityInstanceResponse,
  ValidateJobResponse,
} from '@/codecs'
import type { HttpClient } from '@/http/http.utils'
import * as CodecUtils from '@/utilities/codec'
import { rflaskGetMany, rflaskGetSingle } from '@/utilities/codec'
import { parseQuery, unserializeQ } from '@/utilities/crud-query'
import { EMPTY_ROOT_RULE_GROUP } from '@/utilities/query-filter-utils/__fixtures__/root-rule-group.fixture'

// ---------------------------------------------------------------------------

// prettier-ignore
export type ActivityApi = {
  getActivityConfiguration: (id: string) => Promise<ActivityConfigurationResponse>
  getActivityConfigurations: (query: string) => Promise<ActivityConfigurationsResponse>
  getActivityInstance: (id: string) => Promise<GetActivityInstance>
  getActivityInstances: (query: string) => Promise<GetActivityInstances>
  getApplicableActivities: (config: { entityType: AuthoritativeEntityType; entityId: string; query: string }) => Promise<ApplicableActivitiesResponse>
  getApplicableEntitiesForConfiguration: (config: { configurationId: string; entityType: AuthoritativeEntityType, query: string }) => Promise<ApplicableEntitiesResponse>
  getArtifactsForActivityInstance: (config: { id: string; query: string }) => Promise<ArtifactForActivityInstanceResponse>
  getEntitiesForActivityInstance: (config: { id: string; query: string }) => Promise<EntityForActivityInstanceResponse>
  patchActivityConfigurationEnabled: (id: string, enabled: boolean) => Promise<ActivityConfigurationPatchResponse>
  postValidateJob: (payload: { entityType: AuthoritativeEntityType; entityId: string; configurationId: string }) => Promise<ValidateJobResponse>
}

export function activityApiFactory(http: HttpClient): ActivityApi {
  const baseUrl = '/recon/api/v2'
  const artifactStoreUrl = '/artifactstore/api/v1'
  const activityContentServiceUrl = '/cms/api/v1/frontend'

  return {
    getActivityInstance(id) {
      return http
        .get(`${baseUrl}/activity-log/${id}`)
        .then(CodecUtils.throwValidate(GetActivityInstance, 'GetActivityInstance'))
    },

    getActivityInstances(query) {
      return http
        .get(`${baseUrl}/activity-log${query}`)
        .then(CodecUtils.throwValidate(GetActivityInstances, 'GetActivityInstances'))
    },

    getEntitiesForActivityInstance(config: { id: string; query: string }) {
      return http
        .get(`${baseUrl}/activity-log/${config.id}/entities${config.query}`)
        .then(CodecUtils.throwValidate(EntityForActivityInstanceResponse, 'EntityForActivityInstanceResponse'))
    },

    getArtifactsForActivityInstance({ id, query }) {
      const uri = `${artifactStoreUrl}/activity-log/${id}/artifacts?${query}`

      return http
        .get(uri)
        .then(CodecUtils.throwValidate(ArtifactForActivityInstanceResponse, 'ArtifactForActivityInstanceResponse'))
    },

    getActivityConfiguration(_id) {
      const retrieveConfigurationURI = `${activityContentServiceUrl}/activity-configurations/${_id}`

      return http
        .get(retrieveConfigurationURI)
        .then(CodecUtils.throwValidate(ActivityConfigurationResponse, 'ActivityConfigurationsResponse'))
    },

    getActivityConfigurations(_query) {
      let acsQuery = _query
      const { q } = parseQuery(acsQuery)
      const unserializedQ = unserializeQ(q ?? '')

      if (equals(unserializedQ, EMPTY_ROOT_RULE_GROUP)) {
        // ACS will error with an empty q string so if it's empty we need to remove it
        acsQuery = acsQuery.replace(/([&?])q=[^&]*&?/, '$1')
      }

      if (acsQuery.includes('reversed_nulls')) {
        // ACS does not support reversed_nulls so we must remove it
        const replaceCharacter = acsQuery.includes('sort') ? '&' : ''
        acsQuery = acsQuery.replace(/([&?])reversed_nulls=(true|false)&?/, replaceCharacter)
      }

      return http
        .get(`${activityContentServiceUrl}/activity-configurations${acsQuery}`)
        .then(CodecUtils.throwValidate(ActivityConfigurationsResponse, 'ActivityConfigurationsResponse'))
    },

    getApplicableActivities(config: { entityType: AuthoritativeEntityType; entityId: string; query: string }) {
      // GET /api/v1/frontend/applicable-activities/org_target/{target_id}
      const { entityType, entityId, query } = config
      const applicableActivitiesURI = `${activityContentServiceUrl}/applicable-activities/${entityType}/${entityId}?${query}`

      return http
        .get(applicableActivitiesURI)
        .then(CodecUtils.throwValidate(ApplicableActivitiesResponse, 'ApplicableActivitiesResponse'))
    },

    getApplicableEntitiesForConfiguration(config: {
      configurationId: string
      entityType: AuthoritativeEntityType
      query: string
    }) {
      const { configurationId, entityType, query } = config
      const applicableEntitiesURI = `${activityContentServiceUrl}/activity-configurations/${configurationId}/applicable-entities/${entityType}?${query}`

      return http
        .get(applicableEntitiesURI)
        .then(CodecUtils.throwValidate(ApplicableEntitiesResponse, 'ApplicableEntitiesResponse'))
    },

    patchActivityConfigurationEnabled(_id, _enabled) {
      const patchConfigurationURI = `${activityContentServiceUrl}/activity-configurations/${_id}`
      const patchConfigurationPayload = {
        enabled: _enabled,
        // this is something ACS expects it is an array with integers that correspond to the fields being changed. 2 corresponds to enabled
        changed_fields: [2],
      }
      return http
        .patch(patchConfigurationURI, patchConfigurationPayload)
        .then(CodecUtils.throwValidate(ActivityConfigurationPatchResponse, 'ActivityConfigurationPatchResponse'))
    },

    postValidateJob(payload: { entityType: AuthoritativeEntityType; entityId: string; configurationId: string }) {
      const { entityType, entityId, configurationId } = payload
      const postValidateURI = `${activityContentServiceUrl}/validate`
      const requestPayload = {
        entity_type: entityType,
        entity_id: entityId,
        configuration_id: configurationId,
      }
      return http
        .post(postValidateURI, requestPayload)
        .then(CodecUtils.throwValidate(ValidateJobResponse, 'ValidateJobResponse'))
    },
  }
}

export type GetActivityInstance = t.TypeOf<typeof GetActivityInstance>
export const GetActivityInstance = rflaskGetSingle(ActivityInstance)

export type GetActivityInstances = t.TypeOf<typeof GetActivityInstances>
export const GetActivityInstances = rflaskGetMany(ActivityInstance)
