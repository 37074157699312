/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Condition, Operator } from '@randori/rootkit'

import * as ConfidenceUtils from '@/utilities/confidence'

// ---------------------------------------------------------------------------

const _defaultConfidence = ConfidenceUtils.createConfidence({
  low: false,
  medium: true,
  high: true,
})

const _defaultAffiliation = {
  ui_id: 'unaffiliated',
  condition: 'OR' as Condition,
  rules: [
    {
      ui_id: 'show_no_affiliation',
      id: 'table.affiliation_state',
      field: 'table.affiliation_state',
      type: 'object',
      input: 'text',
      operator: 'equal' as Operator,
      value: 'None',
    },
  ],
}

const _defaultReconStatus = {
  ui_id: 'recon_status',
  condition: 'OR',
  rules: [
    {
      field: 'table.status',
      id: 'table.status',
      input: 'text',
      operator: 'equal',
      ui_id: 'status.None',
      value: 'None',
    },
    {
      field: 'table.status',
      id: 'table.status',
      input: 'text',
      operator: 'equal',
      ui_id: 'status.Needs Investigation',
      value: 'Needs Investigation',
    },
    {
      field: 'table.status',
      id: 'table.status',
      input: 'text',
      operator: 'equal',
      ui_id: 'status.Needs Resolution',
      value: 'Needs Resolution',
    },
    {
      field: 'table.status',
      id: 'table.status',
      input: 'text',
      operator: 'equal',
      ui_id: 'status.Needs Review',
      value: 'Needs Review',
    },
  ],
}

export const reconStatusFilterRules = [_defaultReconStatus]

export const _getConfidenceAndAffiliationFilterRules = () => {
  const rules = [_defaultConfidence, _defaultAffiliation]

  return rules
}

export const confidenceAndAffiliationFilterRules = _getConfidenceAndAffiliationFilterRules()
