/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

export function propsFromIntersection<T extends t.IntersectionType<Array<t.InterfaceType<t.Props>>>>(
  intersection: T,
): t.Props {
  const returnProps = (propObj: t.Props, type: t.InterfaceType<t.Props>) => {
    return { ...propObj, ...type.props }
  }
  const props = intersection.types.reduce(returnProps, {} as t.Props)

  return props
}
