/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

// ---------------------------------------------------------------------------

export type PeerType = t.TypeOf<typeof PeerType>
export const PeerType = t.keyof({
  SIZE: null,
  INDUSTRY: null,
})

export type PeerGroup = t.TypeOf<typeof PeerGroup>
export const PeerGroup = t.type({
  id: t.string,
  type: PeerType,
  value: t.string,
})

export const PeerGroupResponse = CodecUtils.rflaskGetMany(PeerGroup)
export type PeerGroupResponse = t.TypeOf<typeof PeerGroupResponse>

export type PeerGroupPostPayload = t.TypeOf<typeof PeerGroupPostPayload>
export const PeerGroupPostPayload = t.type({
  data: t.array(
    t.type({
      type: PeerType,
      value: t.string,
    }),
  ),
})

export const PeerGroupPostResponse = CodecUtils.rflaskPost()
export type PeerGroupPostResponse = t.TypeOf<typeof PeerGroupPostResponse>

export type PeerMap = t.TypeOf<typeof PeerMap>
export const PeerMap = t.type({
  id: t.string,
  org_id: t.string,
  org_peer_industry: CodecUtils.nullable(t.string),
  org_peer_size: CodecUtils.nullable(t.string),
})

export const PeerMapsResponse = CodecUtils.rflaskGetMany(PeerMap)
export type PeerMapsResponse = t.TypeOf<typeof PeerMapsResponse>
export const PeerMapResponse = CodecUtils.rflaskGetSingle(PeerMap)
export type PeerMapResponse = t.TypeOf<typeof PeerMapResponse>

export type PeerMapPostPayload = t.TypeOf<typeof PeerMapPostPayload>
export const PeerMapPostPayload = t.type({
  data: t.array(
    t.type({
      org_id: t.string,
      org_peer_industry: CodecUtils.nullable(t.string),
      org_peer_size: CodecUtils.nullable(t.string),
    }),
  ),
})

export const PeerMapPostResponse = CodecUtils.rflaskPost()
export type PeerMapPostResponse = t.TypeOf<typeof PeerMapPostResponse>

export type PeerMapPatchPayload = t.TypeOf<typeof PeerMapPatchPayload>
export const PeerMapPatchPayload = t.type({
  data: t.type({
    org_id: t.string,
    org_peer_industry: CodecUtils.nullable(t.string),
    org_peer_size: CodecUtils.nullable(t.string),
  }),
})

export const PeerMapPatchResponse = CodecUtils.rflaskPatch(PeerMap)
export type PeerMapPatchResponse = t.TypeOf<typeof PeerMapPatchResponse>
