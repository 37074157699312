/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { EntityPagination, SocialP as Social } from '@/codecs'

import { EntityName, initialState, State } from './social.constants'
import { normalizeSocials } from './social.schema'

// ---------------------------------------------------------------------------

export const socialSlice = createSlice({
  name: EntityName,
  initialState,
  reducers: {
    // base
    // ------------------------------------------------------------------------

    replaceSocials: (state, action: PayloadAction<ReturnType<typeof normalizeSocials>>) => {
      state.result = action.payload.result

      state.entities = {
        social: {
          ...state.entities.social,
          ...action.payload.entities.social,
        },
      }
    },

    replaceSocialTotals: (state, action: PayloadAction<State['totals']>) => {
      state.totals = action.payload
    },

    replaceSocialPagination: (state, action: PayloadAction<EntityPagination>) => {
      state.pagination = action.payload
    },

    upsertSocials: (state, action: PayloadAction<ReturnType<typeof normalizeSocials>>) => {
      state.entities = {
        social: {
          ...state.entities.social,
          ...action.payload.entities.social,
        },
      }
    },

    upsertSocial: (state, action: PayloadAction<Social>) => {
      const id = action.payload.id

      state.entities.social[id] = action.payload
    },
  },
})
