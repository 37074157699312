/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { connect } from 'react-redux'

import { AppState, GateSelectors } from '@/store'
import { isNotNil } from '@/utilities/is-not'

type WithFeaturesAndPermissionsOwnProps = {
  features?: string[]
  permissions?: string[]
}

const _withFeaturesAndPermissions = (state: AppState, ownProps: WithFeaturesAndPermissionsOwnProps) => {
  const _permissions = isNotNil(ownProps.permissions) ? ownProps.permissions : []
  const _features = isNotNil(ownProps.features) ? ownProps.features : []

  const permissions = _permissions.map(GateSelectors.hasPermission(state))
  const features = _features.map(GateSelectors.hasFeatureFlag(state))

  return {
    isEnabled: [...features, ...permissions].every(Boolean),
  }
}

export const withFeaturesAndPermissions = connect(_withFeaturesAndPermissions)

export const Test = {
  _withFeaturesAndPermissions,
}
