/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import qs from 'query-string'

// ---------------------------------------------------------------------------

// Assisted by WCA for GP
// Latest GenAI contribution: granite-20B-code-instruct-v2 model
/**
 * Convert a query object to a query string.
 *
 * @param queryObject - The query object to convert.
 * @param addQueryPrefix - Whether to add a "?" prefix to the beginning of the query string. Defaults to false.
 * @param qsOptions - Options for customizing the behavior of the qs.stringify function.
 *
 * @returns The query string.
 */
export const stringify = (
  queryObject: Record<string, unknown>,
  addQueryPrefix = false,
  qsOptions?: qs.StringifyOptions,
) => {
  const queryString = qs.stringify(queryObject, qsOptions)

  if (addQueryPrefix) {
    return `?${queryString}`
  } else {
    return queryString
  }
}
