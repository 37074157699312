/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Catch from 'redux-saga-try-catch'
import { all, call, put, takeEvery } from 'typed-redux-saga/macro'

import * as Store from '@/store'
import * as _CategoryActions from '@/store/actions/category/category.actions'
import { MiddlewaresIO } from '@/store/store.utils'
import { get, QueryString } from '@/utilities/codec'
import * as CrudQueryUtils from '@/utilities/crud-query'
import * as EntityUtils from '@/utilities/r-entity'
import { RandoriEntityTypeError } from '@/utilities/r-error'
import * as UnknownAssetUtils from '@/utilities/unknown'

// Root watcher
// ---------------------------------------------------------------------------

/* eslint-disable @typescript-eslint/no-use-before-define */
export function* watchCategory(io: MiddlewaresIO) {
  yield takeEvery(Store.CategoryActions.TypeKeys.UNKNOWNS_GET, Catch.deferredAction(_UNKNOWNS_GET as any, io))
  yield takeEvery(
    Store.CategoryActions.TypeKeys.UNKNOWN_GET_BY_ENTITY,
    Catch.deferredAction(_UNKNOWN_GET_BY_ENTITY, io),
  )
}

export function* _UNKNOWN_GET_BY_ENTITY(io: MiddlewaresIO, action: _CategoryActions.UNKNOWN_GET_BY_ENTITY) {
  const getEndpoint = (type: EntityUtils.EntityType) => {
    switch (type) {
      case 'hostname':
        return io.api.hostname.getHostnames
      case 'ip':
        return io.api.ip.getIps
      case 'network':
        return io.api.network.getNetworks
      case 'target':
        return io.api.target.getTargets

      default:
        throw new RandoriEntityTypeError({ entityType: type })
    }
  }

  const endpoint = yield* call(getEndpoint, action.payload)

  const { total: unknownTotal } = yield* call(
    endpoint,
    get(CrudQueryUtils.createQuery({ limit: 0, q: UnknownAssetUtils.UnknownQCategories() }), QueryString),
  )

  const { total: allTotal } = yield* call(
    endpoint,
    get(CrudQueryUtils.createQuery({ limit: 0, q: UnknownAssetUtils.allQ() }), QueryString),
  )

  const stats = {
    type: action.payload,
    total: allTotal,
    unknown: unknownTotal,
  }

  yield* put(Store.CategoryActions.UNKNOWNS_STORE_UPDATE(stats))

  return stats
}

export function* _UNKNOWNS_GET(io: MiddlewaresIO, _action: _CategoryActions.UNKNOWNS_GET) {
  const deferred = {
    failure: () => undefined,
    success: () => undefined,
  }

  const hostnames = Store.CategoryActions.UNKNOWN_GET_BY_ENTITY('hostname', deferred)
  const ips = Store.CategoryActions.UNKNOWN_GET_BY_ENTITY('ip', deferred)
  const networks = Store.CategoryActions.UNKNOWN_GET_BY_ENTITY('network', deferred)
  const targets = Store.CategoryActions.UNKNOWN_GET_BY_ENTITY('target', deferred)

  const results = yield* all([
    call(_UNKNOWN_GET_BY_ENTITY, io, hostnames),
    call(_UNKNOWN_GET_BY_ENTITY, io, ips),
    call(_UNKNOWN_GET_BY_ENTITY, io, networks),
    call(_UNKNOWN_GET_BY_ENTITY, io, targets),
  ])

  return results
}
