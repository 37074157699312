/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

const init: Codecs.ApplicableEntitiesResponse = {
  data: [],
  links: { self: undefined, next: undefined, last: undefined },
  meta: { count: 0, offset: 0, total: 0 },
}

export const ApplicableEntitiesReducer = (
  state = Schemas.Activity.NormalizeApplicableEntities(init),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.ActivityActions.ApplicableEntity.TypeKeys.APPLICABLE_ENTITIES_STORE_UPDATE: {
      const { entities, result } = Schemas.Activity.NormalizeApplicableEntities(action.payload)

      return {
        result,
        entities: {
          applicable_entity: {
            ...state.entities.applicable_entity,
            ...entities.applicable_entity,
          },
        },
      }
    }

    default: {
      return state
    }
  }
}
