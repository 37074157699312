/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const GlobalHostnameReducer = (
  state = Schemas.HocSchemas.NormalizeGlobalHostnames([]),
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.GLOBAL_HOSTNAMES_UPDATE: {
      const { entities, result } = Schemas.HocSchemas.NormalizeGlobalHostnames(action.payload.data)

      return {
        result,
        entities: {
          ...state.entities,
          globalHostname: {
            ...state.entities.globalHostname,
            ...entities.globalHostname,
          },
        },
      }
    }

    default:
      return state
  }
}

export const GlobalHostnamesTotalsInitialState: Codecs.HocEntityTotals = {
  total: 0,
  unfilteredTotal: 0,
}

export const GlobalHostnamesTotalsReducer = (state = GlobalHostnamesTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.GLOBAL_HOSTNAMES_TOTALS_STORE_UPDATE: {
      return {
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const GlobalHostnamesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}

export const GlobalHostnamesPaginationReducer = (
  state = GlobalHostnamesPaginationInitialState,
  action: Store.ActionTypes,
) => {
  switch (action.type) {
    case Store.HocActions.TypeKeys.GLOBAL_HOSTNAMES_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
