/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { ActiveTagState, isNotNil } from '@randori/rootkit'
import * as t from 'io-ts'

import * as Codecs from '@/codecs'
import { getSafe } from '@/utilities/codec'
import * as Logger from '@/utilities/logger'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export function getNextTagState(state: string, canBeIndeterminate = false) {
  // @TODO: Unscramble this nested ternary
  //
  // This is unreadable.
  return state === ActiveTagState.checked
    ? ActiveTagState.unchecked
    : state === ActiveTagState.unchecked
    ? canBeIndeterminate
      ? ActiveTagState.indeterminate
      : ActiveTagState.checked
    : ActiveTagState.checked
}

export function tagToString(_tagValue: unknown) {
  try {
    return (_tagValue as boolean).toString()
  } catch (err) {
    Logger.error(err)
  }
}

export const tagRegex = /^[a-zA-Z0-9 \-_:]{1,80}$/

export const __ALLOWED_CHARS__ = 'Alphanumeric up to 80 characters, spaces, hyphens, colons and underscores allowed.'

export const __TAG_ERROR_MESSAGE__ =
  'Our apologies. Randori could not modify tags at this time, please contact Randori Support if this persists.'

export enum BulkTagQuery {
  fromIds = 'from-ids',
  fromQ = 'from-q',
}

export type BulkPatchEntityType = {
  type: ReturnType<typeof EntityUtils.getDisplayByType>
  url: ReturnType<typeof EntityUtils.getEndpointByType>
}

type HasUserTags = {
  user_tags: string[]
} & Codecs.Entity

export const entityHasUserTags = (entity: Codecs.Entity): entity is HasUserTags => {
  const validator = t.type({ user_tags: t.array(t.string) })
  const validated = getSafe(entity, validator, false)

  return isNotNil(validated)
}

export const isUserTaggedEntity = entityHasUserTags
