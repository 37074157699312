/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { Response, Server } from 'miragejs'
import { v4 as uuidv4 } from 'uuid'

import { PerspectiveV2 } from '@/codecs'
import * as Codecs from '@/codecs'
import { get } from '@/utilities/codec'

import { PERSPECTIVES_URL } from '../constants'

export const perspectiveRoutes = (server: Server): void => {
  server.get(PERSPECTIVES_URL, (schema) => {
    const data = schema.db.perspectives

    const pagination = {
      offset: 0,
      count: data.length,
      total: data.length,
    }

    if (isNotNil(data)) {
      return {
        perspectives: data,
        ...pagination,
      }
    }

    return new Response(500, {}, { errors: ['yikes'] })
  })

  server.get(`${PERSPECTIVES_URL}/:id`, (schema, request) => {
    const id = request.params.id

    try {
      const perspective = get(schema.db.perspectives.find(id), PerspectiveV2)
      return perspective
    } catch {
      return new Response(404, {}, { errors: ['Resource not found'] })
    }
  })

  server.post(`${PERSPECTIVES_URL}`, (schema, request) => {
    const { name, perspectiveType } = JSON.parse(request.requestBody)

    const newPerspective = {
      id: uuidv4(),
      name: name as string,
      perspectiveType: perspectiveType as Codecs.PerspectiveType,
      createTime: '2024-04-10T19:58:30.867286+00:00',
      updateTime: '2024-04-10T19:58:30.867286+00:00',
      properties: {},
    }

    const perspective = schema.db.perspectives.firstOrCreate(newPerspective)

    return perspective
  })
}
