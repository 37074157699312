/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { EntityType } from '../entity-types'

export const isReconEntity = (entityType: EntityType): boolean => {
  switch (entityType) {
    // case "artifact":
    case 'bdo_detection':
    case 'hostname':
    case 'ip':
    case 'network':
    case 'service':
    case 'social':
    case 'target':
    case 'topLevelDetection':
      return true

    default:
      return false
  }
}
