/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'

// ---------------------------------------------------------------------------

export const ApplicableActivity: Codecs.ApplicableActivity = {
  type: 'applicable_activity',
  id: '682d34f9-bb40-467a-b657-20d491e90cac',
  attributes: {
    name: 'Config-0-d0ba1753-88bf-4439-a236-e08871d32e1d',
    description: 'Some description',
    last_planned_at: null,
    period: 500000000,
    stability: 5,
    needs_authorization: false,
    required_authorization: 0,
  },
}

export const ApplicableActivity2: Codecs.ApplicableActivity = {
  type: 'applicable_activity',
  id: '682d34f9-bb40-467a-b657-20d491e90cac',
  attributes: {
    name: 'Config--authorization-not-required',
    description: 'Some description',
    last_planned_at: null,
    period: 0,
    stability: 3,
    needs_authorization: false,
    required_authorization: 0,
  },
}

export const ApplicableActivity3: Codecs.ApplicableActivity = {
  type: 'applicable_activity',
  id: '682d34f9-bb40-467a-b657-20d491e90cac',
  attributes: {
    name: 'Config--pending-authorization',
    description: 'Some description',
    last_planned_at: null,
    period: 0,
    stability: 3,
    needs_authorization: true,
    required_authorization: 1,
  },
}

export const ApplicableActivity4: Codecs.ApplicableActivity = {
  type: 'applicable_activity',
  id: '682d34f9-bb40-467a-b657-20d491e90cac',
  attributes: {
    name: 'Config--authorized',
    description: 'Some description',
    last_planned_at: null,
    period: 0,
    stability: 3,
    needs_authorization: false,
    required_authorization: 2,
  },
}

export const ApplicableActivity5: Codecs.ApplicableActivity = {
  type: 'applicable_activity',
  id: '682d34f9-bb40-467a-b657-20d491e90cac',
  attributes: {
    name: 'ApplicableActivity5',
    description: 'Some description',
    last_planned_at: null,
    period: 0,
    stability: 3,
    needs_authorization: false,
    required_authorization: 0,
  },
}

export const ApplicableActivities: Codecs.ApplicableActivitiesResponse = {
  data: [ApplicableActivity, ApplicableActivity2, ApplicableActivity3, ApplicableActivity4, ApplicableActivity5],
  meta: { total: 5, count: 5, offset: 0 },
  links: {
    self: 'http://example.com/applicable-activity?{query-placeholder}',
    next: 'http://example.com/applicable-activity?{query-placeholder}',
    last: 'http://example.com/applicable-activity?{query-placeholder}',
  },
}

export const ValidateJob: Codecs.ValidateJobResponse = {
  job_id: '682d34f9-bb40-467a-b657-20d491e90cad',
}

/*
{
  "data": [
    {
      "type": "applicable_activity",
      "id": "682d34f9-bb40-467a-b657-20d491e90cac",
      "attributes": {
        "name": "Config-0-d0ba1753-88bf-4439-a236-e08871d32e1d",
        "description": "Some description",
        "last_planned_at": null,
        "period": 0,
        "needs_authorization": false,
        "required_authorization": 0
      }
    }
  ],
  "meta": {
      "total": 1,
      "count": 1,
      "offset": 0
  },
  "links": {
      "self": "http://example.com/applicable-activity?{query-placeholder}",
      "next": "http://example.com/applicable-activity?{query-placeholder}",
      "last": "http://example.com/applicable-activity?{query-placeholder}"
  }
}
*/
