/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as Codecs from '@/codecs'
import * as Store from '@/store/actions'
import * as Schemas from '@/store/schemas'

// ---------------------------------------------------------------------------

export const ServicesReducer = (state = Schemas.Recon.NormalizeServices([]), action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.SERVICES_STORE_UPDATE: {
      const { entities, result, byServiceId } = Schemas.Recon.NormalizeServices(action.payload.data)

      return {
        result,
        byServiceId: {
          ...state.byServiceId,
          ...byServiceId,
        },
        entities: {
          ...state.entities,
          service: {
            ...state.entities.service,
            ...entities.service,
          },
        },
      }
    }

    case Store.ReconActions.TypeKeys.SERVICE_STORE_UPDATE: {
      return {
        byServiceId: {
          ...state.byServiceId,
          [action.payload.id]: action.payload.id,
        },
        result: state.result,
        entities: {
          ...state.entities,
          service: {
            ...state.entities.service,
            [action.payload.id]: action.payload,
          },
        },
      }
    }

    default:
      return state
  }
}

const ServicesTotalsInitialState: Codecs.EntityTotals = { unfilteredTotal: 0, unaffiliatedTotal: 0 }
export const ServicesTotalsReducer = (state = ServicesTotalsInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.SERVICE_TOTALS_STORE_UPDATE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}

const ServicesPaginationInitialState: Codecs.EntityPagination = {
  count: 0,
  offset: 0,
  total: 0,
}
export const ServicesPaginationReducer = (state = ServicesPaginationInitialState, action: Store.ActionTypes) => {
  switch (action.type) {
    case Store.ReconActions.TypeKeys.SERVICES_STORE_UPDATE: {
      const { data, ...pagination } = action.payload

      return {
        ...pagination,
      }
    }

    default:
      return state
  }
}
