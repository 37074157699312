/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import type { AvailableColumns, Column } from '../../reducers/ui/entity-table.reducers/reducer-types'

export const removeAuthorizationStateColumn =
  ({
    hasAuthorization,
    hasAuthorizationOnDetection,
  }: {
    hasAuthorization: boolean
    hasAuthorizationOnDetection: boolean
  }) =>
  (column: Column) => {
    const showAuthorizationState = hasAuthorization && !hasAuthorizationOnDetection

    if (column.fieldName !== 'authorization_state') {
      return true
    }

    return showAuthorizationState
  }

export const markAuthorizationStateColumnAsInternal = (
  config: { hasAuthorization: boolean; hasAuthorizationOnDetection: boolean },
  availableColumns: AvailableColumns,
) => {
  const hideAuthorizationState = config.hasAuthorization && !config.hasAuthorizationOnDetection

  return {
    ...availableColumns,
    authorization_state: {
      ...availableColumns.authorization_state,
      isInternal: !hideAuthorizationState,
    },
  }
}

export const removeDetectionAuthorizationStateColumn =
  ({
    hasAuthorization,
    hasAuthorizationOnDetection,
  }: {
    hasAuthorization: boolean
    hasAuthorizationOnDetection: boolean
  }) =>
  (column: Column) => {
    const showDetectionAuthorizationState = hasAuthorization && hasAuthorizationOnDetection

    if (column.fieldName !== 'detection_authorization_state') {
      return true
    }

    return showDetectionAuthorizationState
  }

export const removeAuthorizationColumn =
  ({
    hasAuthorization,
    hasAuthorizationOnDetection,
  }: {
    hasAuthorization: boolean
    hasAuthorizationOnDetection: boolean
  }) =>
  (column: Column) => {
    const showAuthorizationState = hasAuthorization && hasAuthorizationOnDetection

    if (column.fieldName !== 'authorization') {
      return true
    }

    return showAuthorizationState
  }

export const removeDetectionAuthorizingPolicies =
  ({
    hasAuthorization,
    hasAuthorizationOnDetection,
  }: {
    hasAuthorization: boolean
    hasAuthorizationOnDetection: boolean
  }) =>
  (column: Column) => {
    const showAuthorizingPolicies = hasAuthorization && hasAuthorizationOnDetection

    if (column.fieldName !== 'authorizing_policies') {
      return true
    }

    return showAuthorizingPolicies
  }

export const removeVendorServiceVersionColumn = (tableId: string) => (column: Column) => {
  if (tableId === 'detection_target') {
    return column.fieldName !== 'vendor-service-version'
  } else {
    return true
  }
}
