/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { isNotNil } from '@randori/rootkit'
import { Reducer } from 'redux'

import * as Store from '@/store/actions'
import * as EntityUtils from '@/utilities/r-entity'

// ---------------------------------------------------------------------------

export type Orientation = 'bottom' | 'right'

export type InspectPaneIsOpen = {
  isOpen: true
  orientation: Orientation
  entity: {
    entityId: string // uuid
    entityType: EntityUtils.EntityType
  }
}

type InsepectPaneIsClosed = {
  isOpen: false
  orientation: Orientation
}

type InspectPaneState = InspectPaneIsOpen | InsepectPaneIsClosed

const initialState = {
  isOpen: false as const,
  orientation: 'right' as const,
}

export const isOpen = (state: InspectPaneState): state is InspectPaneIsOpen => {
  return isNotNil((state as InspectPaneIsOpen).entity)
}

export const InspectPaneReducer: Reducer<InspectPaneState, Store.ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Store.UIActions.TypeKeys.HIDE_INSPECT_PANE: {
      const nextState = {
        isOpen: false as const,
        orientation: state.orientation,
      }

      return nextState
    }

    case Store.UIActions.TypeKeys.CONFIGURE_INSPECT_PANE: {
      const nextState = {
        ...state,
        orientation: action.payload.orientation,
      }

      return nextState
    }

    case Store.UIActions.TypeKeys.DOCK_INSPECT_PANE: {
      const nextState = {
        ...state,
        orientation: state.orientation === 'bottom' ? ('right' as const) : ('bottom' as const),
      }

      return nextState
    }

    case Store.UIActions.TypeKeys.SHOW_INSPECT_PANE: {
      const nextState = {
        ...state,
        entity: action.payload,
        isOpen: true as const,
      }

      return nextState
    }

    case Store.UIActions.TypeKeys.SHOW_GUIDANCE_PANE: {
      return {
        ...state,
        isOpen: false as const,
      }
    }

    default: {
      return state
    }
  }
}
