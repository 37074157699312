/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

// ---------------------------------------------------------------------------

type _LensView = t.TypeOf<typeof _LensView>
const _LensView = t.keyof({
  public: null,
  internal: null,
})

export type LensView = t.TypeOf<typeof LensView>
export const LensView = t.union([_LensView, t.null])

export type LensId = t.TypeOf<typeof LensId>
export const LensId = t.union([t.string, t.null])
