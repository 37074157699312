/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from '@/store'
import { UUID } from '@/utilities/codec'

import { makeSelectPagination, makeSelectTotals } from '../utilities/make-selectors'
import { EntityName } from './detection.constants'

// ---------------------------------------------------------------------------

export const selectDetectionPagination = makeSelectPagination(EntityName)
export const selectDetectionTotals = makeSelectTotals(EntityName)

export const selectDetections = createSelector([(state: AppState) => state.bdo_detection], (detectionState) => {
  return detectionState.result.map((uuid) => detectionState.entities.bdo_detection[uuid])
})

export const selectDetectionById = createSelector(
  [(state: AppState, _props: { id: UUID }) => state.bdo_detection, (_state: AppState, props: { id: UUID }) => props.id],
  (detectionState, uuid) => {
    const detection = detectionState.entities.bdo_detection[uuid]

    return detection
  },
)

export const selectDetectionsForEntity = createSelector(
  [
    (state: AppState, _props: { forEntity: { id: UUID; type: 'target' } }) => state.bdo_detection,
    (_state: AppState, props: { forEntity: { id: UUID; type: 'target' } }) => {
      return props.forEntity
    },
  ],
  (detectionState, { id, type }) => {
    const detections = detectionState.byEntity[type][id]

    return detections.map((uuid) => {
      return detectionState.entities.bdo_detection[uuid]
    })
  },
)
