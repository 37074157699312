/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import * as CodecUtils from '@/utilities/codec'

import { PerspectiveMetadata } from './perspective.codecs'
import { Trigger } from './trigger.codecs'

// ---------------------------------------------------------------------------

export const AttackAction = t.interface({
  action_id: t.string,
  artifact_status: t.union([t.string, t.undefined]),
  bart_id: t.string,
  completed: t.union([t.string, t.null]),
  config_hash: t.union([t.string, t.null]),
  created: t.string, // @TODO: iso string
  description: t.union([t.string, t.null]),
  display_name: t.string,
  dst_email: t.union([t.array(t.string), t.null]),
  dst_host: t.union([t.array(t.string), t.null]),
  dst_ip: t.union([t.array(t.string), t.null]),
  dst_mac: t.union([t.array(t.string), t.null]),
  dst_misc: t.union([t.array(t.string), t.null]),
  dst_network: t.union([t.array(t.string), t.null]),
  dst_path: t.union([t.array(t.string), t.null]),
  dst_port: t.union([t.array(t.number), t.null]),
  deleted: CodecUtils.nullable(t.boolean),
  id: t.string,
  implant_id: t.union([t.string, t.null]),
  implant_nick: t.union([t.string, t.null]),
  implant_uid: t.union([t.string, t.null]),
  mitre_techniques: t.array(t.string),
  name: t.string,
  org_id: t.string,
  perspective_metadata: PerspectiveMetadata,
  randori_notes: CodecUtils.nullable(t.string),
  result: t.union([t.string, t.null]),
  result_hash: t.union([t.string, t.null]),
  runbook_instance_id: t.string,
  src_email: t.union([t.array(t.string), t.null]),
  src_host: t.union([t.array(t.string), t.null]),
  src_ip: t.union([t.array(t.string), t.null]),
  src_mac: t.union([t.array(t.string), t.null]),
  src_misc: t.union([t.array(t.string), t.null]),
  stability: t.number,
  started: t.string, // @TODO: iso string
  stealth: t.number,
  summary_sha: CodecUtils.nullable(t.string),
  template: t.union([t.string, t.null]),
  trigger: CodecUtils.nullable(Trigger),
  updated: t.string, // @TODO: iso string
})
export type AttackAction = t.TypeOf<typeof AttackAction>

export const ApAttackAction = t.interface({
  deleted: t.boolean,
  id: t.string,
  randori_notes: CodecUtils.nullable(t.string),
})
export type ApAttackAction = t.TypeOf<typeof ApAttackAction>

export const AttackActionResponse = t.interface({
  count: t.number,
  offset: t.number,
  total: t.number,
  data: t.array(AttackAction),
})
export type AttackActionResponse = t.TypeOf<typeof AttackActionResponse>

export const ApAttackActionPatchResponse = t.interface({
  data: ApAttackAction,
})
export type ApAttackActionPatchResponse = t.TypeOf<typeof ApAttackActionPatchResponse>

export const ApAttackActionPatchPayload = t.interface({
  data: t.partial({
    deleted: t.boolean,
    randori_notes: t.string,
  }),
})
export type ApAttackActionPatchPayload = t.TypeOf<typeof ApAttackActionPatchPayload>
