/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import debug from 'debug'
import { head } from 'lodash/fp'

// ---------------------------------------------------------------------------

const logTiming = debug('TIMING:http-factory')

/**
 * Log times from request dispatch to receiving response
 *
 * @param verb - the HTTP verb we're timing
 * @param url - the URL we're hitting
 * @param initialTime - the initial window.performance.now()
 * @param log - side-effecting debug function
 *
 * @returns a side-effecting timing function
 */
export function getTiming(
  verb: string | undefined,
  url: string | undefined,
  initialTime: number,
  log: (msg: string) => void = logTiming,
) {
  return () => {
    const _url = url ?? ''

    const endpoint = head(_url.split('?')) ?? 'unknown'
    const time = window.performance.now()

    log(`${verb ?? 'unknown'} ${endpoint}: ${(time - initialTime) / 1000} seconds`)
  }
}
