/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import * as t from 'io-ts'

import { DateString, DoubleUUID, IP, Network, nullableP, UUID } from '@/utilities/codec'

import { WithPerspective } from '../perspective.codecs'

// ---------------------------------------------------------------------------

export const _IpForNetwork = t.type({
  characteristic_tags: t.array(t.string),
  confidence: nullableP(t.number),
  country: nullableP(t.string),
  hostname: nullableP(t.string),
  id: DoubleUUID,
  ip: nullableP(IP),
  ip_id: nullableP(UUID),
  ip_str: nullableP(IP),
  last_seen: nullableP(DateString),
  latitude: nullableP(t.number),
  longitude: nullableP(t.number),
  network: nullableP(Network),
  network_id: nullableP(UUID),
  network_str: nullableP(Network),
  open_port_count: nullableP(t.number),
  org_id: nullableP(UUID),
  radius: nullableP(t.number),
  service_count: nullableP(t.number),
  target_count: nullableP(t.number),
  target_temptation: nullableP(t.number),
  user_tags: t.array(t.string),
})

export type IpForNetworkP = t.TypeOf<typeof IpForNetworkP>
export const IpForNetworkP = t.intersection([_IpForNetwork, WithPerspective])
