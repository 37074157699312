/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { find } from 'lodash/fp'
import { createSelector } from 'reselect'

import * as Codecs from '@/codecs'
import * as Store from '@/store'
import { isNotNil } from '@/utilities/is-not'
import * as Logger from '@/utilities/logger'
import { PeerGroupType } from '@/utilities/peer'

import OrganizationSelectors from '../organization'

// ---------------------------------------------------------------------------

const _getArtifacts = (state: Store.AppState) => state.recon.artifacts
const _getArtifactsPagination = (state: Store.AppState) => state.recon.artifactsPagination
const _getArtifactsTotals = (state: Store.AppState) => state.recon.artifactsTotals

const _getDetections = (state: Store.AppState) => state.recon.detections
const _getDetectionsP = (state: Store.AppState, props: { id: string }) => {
  return {
    result: state.recon.detectionsP.result[props.id],
    entities: state.recon.detectionsP.entities,
  }
}

const _getAllSavedViews = (state: Store.AppState) => state.recon.allSavedViews
const _getDetectionsById = (state: Store.AppState, props: { id: string }) =>
  state.recon.detections.entities.detection[props.id]
const _getTopLevelDetectionsById = (state: Store.AppState, props: { id: string }) =>
  state.recon.topLevelDetections.entities['top-level-detection'][props.id]
const _getDetectionsPagination = (state: Store.AppState) => state.recon.detectionsPagination
const _getDetectionsPaginationP = (state: Store.AppState, props: { id: string }) =>
  state.recon.detectionsPaginationP[props.id]
const _getHostnames = (state: Store.AppState) => state.recon.hostnames
const _getHostnameById = (state: Store.AppState, props: { id: string }) =>
  state.recon.hostnames.entities.hostname[props.id]
const _getHostnamesPagination = (state: Store.AppState) => state.recon.hostnamesPagination
const _getHostnamesTotals = (state: Store.AppState) => state.recon.hostnamesTotals
const _getIps = (state: Store.AppState) => state.recon.ips
const _getIpsPagination = (state: Store.AppState) => state.recon.ipsPagination
const _getIpsTotals = (state: Store.AppState) => state.recon.ipsTotals
const _getNetworks = (state: Store.AppState) => state.recon.networks
const _getNetworkById = (state: Store.AppState, props: { id: string }) =>
  state.recon.networks.entities.network[props.id]
const _getNetworksPagination = (state: Store.AppState) => state.recon.networksPagination
const _getNetworksTotals = (state: Store.AppState) => state.recon.networksTotals
const _getPeerGroupTotals = (state: Store.AppState) => state.recon.peerGroupTotals
const _getPeerGroups = (state: Store.AppState) => state.recon.peerGroups
const _getPeerGroupsPagination = (state: Store.AppState) => state.recon.peerGroupsPagination
const _getPeerMapById = (state: Store.AppState, props: { id: string }) =>
  state.recon.peerMaps.entities['peer-map'][props.id]
const _getPeerMaps = (state: Store.AppState) => state.recon.peerMaps
const _getPolicies = (state: Store.AppState) => state.recon.policies
const _getPoliciesPagination = (state: Store.AppState) => state.recon.policiesPagination
const _getPolicyTotals = (state: Store.AppState) => state.recon.policyTotals
const _getReportTotals = (state: Store.AppState) => state.recon.reportsTotals
const _getReports = (state: Store.AppState) => state.recon.reports
const _getReportsPagination = (state: Store.AppState) => state.recon.reportsPagination
const _getSavedViewById = (state: Store.AppState, props: { id: string }) =>
  state.recon.savedViews.entities.savedViews[props.id]
const _getSavedViews = (state: Store.AppState) => state.recon.savedViews
const _getSavedViewsPagination = (state: Store.AppState) => state.recon.savedViewsPagination
const _getSavedViewsTotals = (state: Store.AppState) => state.recon.savedViewsTotals
const _getServices = (state: Store.AppState) => state.recon.services
const _getServicesPagination = (state: Store.AppState) => state.recon.servicesPagination
const _getServicesTotals = (state: Store.AppState) => state.recon.servicesTotals
const _getSocialEntities = (state: Store.AppState) => state.recon.socialEntities
const _getSocialEntitiesById = (state: Store.AppState, props: { id: string }) =>
  state.recon.socialEntities.entities.socialEntity[props.id]
const _getSocialEntitiesPagination = (state: Store.AppState) => state.recon.socialEntitiesPagination
const _getSocialEntitiesTotals = (state: Store.AppState) => state.recon.socialEntitiesTotals
const _getTargets = (state: Store.AppState) => state.recon.targets
const _getTargetsForEntityPagination = (state: Store.AppState) => state.recon.targetsForEntityPagination
const _getTargetsPagination = (state: Store.AppState) => state.recon.targetsPagination
const _getTargetsTotals = (state: Store.AppState) => state.recon.targetsTotals
const _getTopLevelDetectionTotals = (state: Store.AppState) => state.recon.topLevelDetectionTotals
const _getTopLevelDetections = (state: Store.AppState) => state.recon.topLevelDetections
const _getTopLevelDetectionsPagination = (state: Store.AppState) => state.recon.topLevelDetectionsPagination

const _getAuthorizationPolicies = (state: Store.AppState) => state.recon.authorizationPolicies
const _getAuthorizationPoliciesPagination = (state: Store.AppState) => state.recon.authorizationPaginationPolicies
const _getAuthorizationPolicyTotals = (state: Store.AppState) => state.recon.authorizationPolicyTotals

const _getAuthorizationPolicyById = (state: Store.AppState, props: { id: string }) => {
  return state.recon.authorizationPolicies.entities.policy[props.id]
}

const _getTargetsForEntity = (state: Store.AppState) => {
  return state.recon.targetsForEntity.entities.target
}

const _getTargetsForEntityById = (state: Store.AppState, props: { id: string; lens_id: Codecs.LensId }) => {
  const localId = `${props.id},${props.lens_id}`

  return state.recon.targetsForEntity.byId[localId]
}

const _getTargetById = (state: Store.AppState, props: { id: string }) => {
  const mappedId = state.recon.targets.byTargetId[props.id]

  return state.recon.targets.entities.target[mappedId]
}

const _getServiceById = (state: Store.AppState, props: { id: string }) => {
  const mappedId = state.recon.services.byServiceId[props.id]

  return state.recon.services.entities.service[mappedId]
}

const _getIpById = (state: Store.AppState, props: { id: string }) => {
  return state.recon.ips.entities.ip[props.id]
}

const _getPolicyById = (state: Store.AppState, props: { id: string }) => {
  return state.recon.policies.entities.policy[props.id]
}

// ---------------------------------------------------------------------------

export const selectArtifacts = createSelector([_getArtifacts], (_artifacts) => {
  return _artifacts.result.map((id: string) => _artifacts.entities['recon-artifact'][id]).filter(Boolean)
})

export const selectArtifactsPagination = createSelector([_getArtifactsPagination], (pagination) => pagination)

export const selectArtifactsTotals = createSelector([_getArtifactsTotals], (totals) => totals)

export const selectIps = createSelector([_getIps], (_ips) => {
  return (
    _ips.result
      .map((id: string) => _ips.entities.ip[id])
      // @TODO: Fix reducer race
      //
      // When quickly switching tables, `this.props.records` is sometimes
      // something like `[undefined, record, undefined]`. This is due to
      // `entity.result` and `entity.records` being out of sync. Need to
      // investigate why...
      .filter(Boolean)
  )
})

export const selectIpsPagination = createSelector([_getIpsPagination], (pagination) => pagination)

export const selectIpsTotals = createSelector([_getIpsTotals], (totals) => totals)

export const selectHostnames = createSelector([_getHostnames], (_hostnames) => {
  return (
    _hostnames.result
      .map((id: string) => _hostnames.entities.hostname[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectHostnamesPagination = createSelector([_getHostnamesPagination], (pagination) => pagination)

export const selectHostnamesTotals = createSelector([_getHostnamesTotals], (totals) => totals)

export const selectNetworks = createSelector([_getNetworks], (_networks) => {
  return (
    _networks.result
      .map((id: string) => _networks.entities.network[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectNetworksPagination = createSelector([_getNetworksPagination], (pagination) => pagination)

export const selectNetworksTotals = createSelector([_getNetworksTotals], (totals) => totals)

export const selectReports = createSelector([_getReports], (_reports) => {
  return (
    _reports.result
      .map((id: string) => _reports.entities.report[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectReport = (reportId: string) =>
  createSelector([_getReports], (_reports) => {
    return _reports.entities.report[reportId]
  })

export const selectReportsPagination = createSelector([_getReportsPagination], (pagination) => pagination)

export const selectReportsTotals = createSelector([_getReportTotals], (totals) => {
  return totals
})

export const selectSavedViews = createSelector([_getSavedViews], (_savedViews) => {
  return (
    _savedViews.result
      .map((id: string) => _savedViews.entities.savedViews[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectAllSavedViews = createSelector([_getAllSavedViews], (_savedViews) => {
  return (
    _savedViews.result
      .map((id: string) => _savedViews.entities.savedViews[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectTopAllSavedViews = createSelector([_getAllSavedViews], (_savedViews) => {
  // eslint-disable-next-line no-magic-numbers
  const topSavedViews = _savedViews.result.slice(0, 5)

  return (
    topSavedViews
      .map((id: string) => _savedViews.entities.savedViews[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectSavedViewsPagination = createSelector([_getSavedViewsPagination], (pagination) => pagination)

export const selectSavedViewsTotals = createSelector([_getSavedViewsTotals], (totals) => totals)

export const selectServices = createSelector([_getServices], (_services) => {
  return (
    _services.result
      .map((id: string) => _services.entities.service[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectServicesPagination = createSelector([_getServicesPagination], (pagination) => pagination)

export const selectServicesTotals = createSelector([_getServicesTotals], (totals) => totals)

export const selectServiceById = createSelector([_getServiceById], (service) => {
  return service
})

export const selectSocialEntities = createSelector([_getSocialEntities], (_socialEntities) => {
  return _socialEntities.result.map((id: string) => _socialEntities.entities.socialEntity[id]).filter(Boolean)
})

export const selectSocialEntitiesById = createSelector([_getSocialEntitiesById], (socialEntity) => socialEntity)

export const selectSocialEntitiesPagination = createSelector([_getSocialEntitiesPagination], (pagination) => pagination)

export const selectSocialEntitiesTotals = createSelector([_getSocialEntitiesTotals], (totals) => totals)

export const selectTargets = createSelector([_getTargets], (_targets) => {
  return (
    _targets.result
      .map((id: string) => _targets.entities.target[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectTargetById = createSelector([_getTargetById], (target) => {
  return target
})

export const selectTargetsPagination = createSelector([_getTargetsPagination], (pagination) => pagination)

export const selectTargetsTotals = createSelector([_getTargetsTotals], (totals) => totals)

export const selectDetections = createSelector([_getDetections], (_detections) => {
  return (
    _detections.result
      .map((id: string) => _detections.entities.detection[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

// WARNING - make note where in the reducer your data is and consider `selectTopLevelDetectionById`
export const selectDetectionById = createSelector([_getDetectionsById], (detection) => {
  return detection
})

export const selectTopLevelDetectionById = createSelector([_getTopLevelDetectionsById], (detection) => {
  return detection
})

export const selectDetectionsP = createSelector([_getDetectionsP], (_detections) => {
  if (isNotNil(_detections.result)) {
    return (
      _detections.result
        .map((id: string) => _detections.entities.detection[id])
        // @TODO: Fix reducer race
        .filter(Boolean)
    )
  } else {
    return []
  }
})

export const selectDetectionsPagination = createSelector([_getDetectionsPagination], (pagination) => pagination)

export const selectDetectionsPaginationP = createSelector([_getDetectionsPaginationP], (pagination) =>
  isNotNil(pagination) ? pagination : { count: 0, offset: 0, total: 0 },
)

export const selectTargetsForEntityById = createSelector(
  [_getTargetsForEntityById, _getTargetsForEntity],
  (targetsForEntity, targets) => {
    // @TODO: Eliminate race
    //
    // This null check is due to a race, where targetsForEntity is still null.
    // Null checking here is a sloppy band-aid.
    if (isNotNil(targetsForEntity)) {
      return targetsForEntity.map((_id) => targets[_id])
    } else {
      return []
    }
  },
)

export const selectTargetsForEntityTotal = createSelector([_getTargetsForEntityPagination], ({ total }) => total)

export const selectTargetsForEntityPagination = createSelector(
  [_getTargetsForEntityPagination],
  (pagination) => pagination,
)

export const selectPolicies = createSelector([_getPolicies], (_policies) => {
  return (
    _policies.result
      .map((id: string) => _policies.entities.policy[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectPolicyById = createSelector([_getPolicyById], (policy) => policy)

export const selectPoliciesPagination = createSelector([_getPoliciesPagination], (pagination) => pagination)

export const selectPolicyTotals = createSelector([_getPolicyTotals], (totals) => totals)

export const selectTopLevelDetections = createSelector([_getTopLevelDetections], (_detections) => {
  return (
    _detections.result
      .map((id: string) => _detections.entities['top-level-detection'][id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectTopLevelDetectionsPagination = createSelector(
  [_getTopLevelDetectionsPagination],
  (pagination) => pagination,
)

export const selectTopLevelDetectionTotals = createSelector([_getTopLevelDetectionTotals], (totals) => totals)

export const selectPeerGroupsPagination = createSelector([_getPeerGroupsPagination], (pagination) => pagination)

export const selectPeerGroupTotals = createSelector([_getPeerGroupTotals], (totals) => totals)

export const selectPeerGroups = createSelector([_getPeerGroups], (_peerGroups) => {
  return (
    _peerGroups.result
      .map((id: string) => _peerGroups.entities['peer-group'][id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectPeerGroupsById = createSelector([_getPeerGroups], (_peerGroups) => {
  return (
    _peerGroups.result
      .map((id: string) => _peerGroups.entities['peer-group'][id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectPeerMaps = createSelector([_getPeerMaps], (_peerMaps) => {
  return (
    _peerMaps.result
      .map((id: string) => _peerMaps.entities['peer-map'][id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectPeerMapEntities = createSelector([_getPeerMaps], (_peerMaps) => _peerMaps.entities['peer-map'])

export const selectPeerGroupEntities = createSelector(
  [_getPeerGroups],
  (_peerGroups) => _peerGroups.entities['peer-group'],
)

export const selectPeerMapById = createSelector([_getPeerMapById], (peerMap) => peerMap)

export const selectPeerMapByOrgId = createSelector(
  [selectPeerMaps, OrganizationSelectors.selectOrgId],
  (peerMaps, orgId) => {
    const peerMap = find((peerMap) => peerMap.org_id === orgId, peerMaps)

    return peerMap
  },
)

export const selectCurrentPeerMapLabeledValues = createSelector(
  [selectPeerGroupEntities, selectPeerMapByOrgId],
  (peerGroups, peerMap) => {
    if (!isNotNil(peerMap)) {
      return {
        industry: undefined,
        size: undefined,
      }
    }

    try {
      const industry = isNotNil(peerMap.org_peer_industry)
        ? { label: peerGroups[peerMap.org_peer_industry].value, value: peerGroups[peerMap.org_peer_industry].id }
        : undefined

      const size = isNotNil(peerMap.org_peer_size)
        ? { label: peerGroups[peerMap.org_peer_size].value, value: peerGroups[peerMap.org_peer_size].id }
        : undefined

      return {
        industry,
        size,
      }
    } catch (e) {
      Logger.log('selectCurrentPeerMapLabeledValues: race with reducer')
      Logger.error(e)

      return {
        industry: undefined,
        size: undefined,
      }
    }
  },
)

export const selectSizePeerGroups = createSelector([selectPeerGroups], (_peerGroups) => {
  return _peerGroups.filter((group) => group.type === PeerGroupType.size)
})

export const selectIndustryPeerGroups = createSelector([selectPeerGroups], (_peerGroups) => {
  return _peerGroups.filter((group) => group.type === PeerGroupType.industry)
})

export const selectIpById = createSelector([_getIpById], (ip) => ip)

export const selectHostnameById = createSelector([_getHostnameById], (hostname) => hostname)

export const selectNetworkById = createSelector([_getNetworkById], (network) => network)

export const selectSavedViewById = createSelector([_getSavedViewById], (savedView) => savedView)

export const selectAuthorizationPolicies = createSelector([_getAuthorizationPolicies], (_policies) => {
  return (
    _policies.result
      .map((id: string) => _policies.entities.policy[id])
      // @TODO: Fix reducer race
      .filter(Boolean)
  )
})

export const selectAuthorizationPolicyById = createSelector([_getAuthorizationPolicyById], (policy) => policy)

export const selectAuthorizationPoliciesPagination = createSelector(
  [_getAuthorizationPoliciesPagination],
  (pagination) => pagination,
)

export const selectAuthorizationPolicyTotals = createSelector([_getAuthorizationPolicyTotals], (totals) => totals)
