/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { Loader, withEffect } from '@randori/rootkit'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, RouteProps } from 'react-router-dom'

import { ErrMessage } from '@/components/boundary'
import * as Store from '@/store'
import * as CrudQuery from '@/utilities/crud-query'
import { compose } from '@/utilities/recompose'

// ---------------------------------------------------------------------------

export type WithSessionInnerProps = {
  render: (props: RouteComponentProps) => React.ReactNode
}

// @TODO: Refactor withSession HOC using a more simple render component
//
// We can use useEffect and a render prop to simplify AuthProtected. We just
// need to defer deciding whether or not to redirect until we try an validate
// the JWT we store as a cookie. We cannot touch this cookie with JS,
// otherwise this would be much more simple. Sekuritay.
export const withSession = compose<RouteProps & WithSessionInnerProps, RouteProps & WithSessionInnerProps['render']>(
  connect(
    () => {
      return {
        shouldEffectKey: 'once',
      }
    },
    (dispatch) => {
      return {
        effect: async () => {
          const getOrgs = new Promise((resolve, reject) => {
            const query = {
              sort: 'name',
              limit: 2000,
              q: CrudQuery.serializeQ({
                condition: 'AND',
                rules: [
                  {
                    field: 'table.stasis',
                    operator: 'equal',
                    value: false,
                  },
                ],
              }),
            }

            dispatch(
              Store.OrganizationActions.getOrganizations(CrudQuery.createQuery(query), {
                success: resolve,
                failure: reject,
              }),
            )
          })

          const validateJWT = new Promise((resolve, reject) => {
            dispatch(Store.AuthActions.validateJWT({ success: resolve, failure: reject }))
          })

          return Promise.all([getOrgs, validateJWT]).catch(() => {
            // recover from 401
            //
            // If validateJWT produces a 401, we still want this wrapper to
            // succeed. It'll just return `false` for the wrapped route, due
            // to `SessionSelectors.selectIsAuthenticated(state)` returning
            // `false`.
            return
          })
        },
      }
    },
  ),
  withEffect({
    /* eslint-disable react/display-name */
    ErrorComponent: () => <ErrMessage />,
    LoadingComponent: () => <Loader addlClasses="page-loader" />,
    /* eslint-enable react/display-name */
  }),
)
