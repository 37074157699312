/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { capitalize } from 'lodash/fp'

import { AuthoritativeEntityTypes } from '../entity-types'

export const formatMatchingEntityName = (name: string, authoritativeEntityType: AuthoritativeEntityTypes) => {
  switch (authoritativeEntityType) {
    case 'org_detection':
      //expected format: "hostname: audi.demo.webernets.online, ip: 35.201.103.45/32, network: -, port: -"
      const nonNullCriteriaArray = name
        .split(', ') //make string into an array of criteria
        .filter((criteria) => !criteria.includes(': -')) //remove the criteria that does not have data
        .map((criteria) => {
          const [entityType, ...rest] = criteria.split(':')

          const formatedEntityType = entityType === 'ip' ? 'IP' : capitalize(entityType)

          return [formatedEntityType, ...rest].join(':')
        }) //format the entity type

      return nonNullCriteriaArray
    default:
      return [name]
  }
}
