/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from '@/store'
import { UUID } from '@/utilities/codec'

import { SelectableKey } from './constants'

// ---------------------------------------------------------------------------

/**
 * @deprecated: until entityName can be used to narrow. write a selector instead.
 */
export const makeSelectResults = <T extends SelectableKey>(entityName: T) => {
  return createSelector([(state: AppState) => state[entityName]], (state) => {
    // eslint-disable-next-line
    // @ts-ignore
    const orderedEntities = state.result.map((uuid) => state.entities[entityName][uuid])

    return orderedEntities
  })
}

/**
 * @deprecated: until entityName can be used to narrow. write a selector instead.
 */
export const makeSelectById = <T extends SelectableKey>(entityName: T) => {
  return createSelector(
    [
      (state: AppState, _props: { id: UUID }) => state[entityName].entities,
      (_state: AppState, props: { id: UUID }) => props.id,
    ],
    (entities, uuid) => {
      // eslint-disable-next-line
      // @ts-ignore
      const entity = entities[entityName][uuid]

      return entity
    },
  )
}

export const makeSelectPagination = <T extends SelectableKey>(entityName: T) => {
  return createSelector([(state: AppState) => state[entityName]], (state) => {
    return state.pagination
  })
}

export const makeSelectTotals = <T extends SelectableKey>(entityName: T) => {
  return createSelector([(state: AppState) => state[entityName]], (state) => {
    return state.totals
  })
}
