/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
import { HostnameP as Hostname } from '@/codecs'

// import { UUID } from '@/utilities/codec'
import { makeState } from '../utilities/make-state'

// ---------------------------------------------------------------------------

export type EntityName = 'hostname'

export const EntityName: EntityName = 'hostname'

export type State = typeof initialState

export const initialState = {
  ...makeState<EntityName, Hostname>(EntityName),
  // byEntity: { ip: {} as Record<UUID, UUID[]> },
}
